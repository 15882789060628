import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'

import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import DealDeliveryIcon from 'root/icons/DealDeliveryIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import {
  DOCUMENT_TYPE_INVOICE,
  STATE_INVOICE_CANCELED,
  STATE_INVOICE_CREATED,
  STATE_INVOICE_PAYMENT_AND_DELIVERY,
} from 'root/modules/orders/constants'
import i18n from 'root/i18n'
import ViewDeliveries from './components/ViewDeliveries'
import ViewAddress from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealDelivery/components/ViewAddress'

const CardDealDelivery = ({ order, asSeller = false, readOnly = false, doAction, children, help }) => {
  const allDelivered = order.allDelivered
  const allShipped = order.allShipped
  const allDone = allDelivered && allShipped
  const isCompleted = order.allDelivered > 0
  const invoice = order.documents.find((document) => document.type === DOCUMENT_TYPE_INVOICE)
  const invoicePaid = invoice?.specification.paymentReceived > 0
  const isCurrent =
    !allDone &&
    (!order.paymentBeforeDelivery || (order.paymentBeforeDelivery && invoicePaid)) &&
    order.state.key !== STATE_INVOICE_PAYMENT_AND_DELIVERY &&
    order.state.key !== STATE_INVOICE_CANCELED &&
    order.state.key !== STATE_INVOICE_CREATED
  const [opened, setOpened] = useState(false)
  const [address, setAddress] = useState(false)
  const showDeliveries = useCallback(() => {
    setOpened(true)
  }, [])
  const showDeliveryAddress = useCallback(() => {
    setAddress(true)
  }, [])
  const data = { allShipped, allDelivered, showDeliveries, showDeliveryAddress }
  return (
    <>
      <Card
        help={help}
        label="order.card.dealDelivery"
        Icon={DealDeliveryIcon}
        current={isCurrent}
        completed={isCompleted}
      >
        <CardRow label="order.card.allGoodsShipped">
          {allShipped ? <Date value={allShipped} /> : <span className="text-xs text-gray-500">&lt;pending&gt;</span>}
        </CardRow>
        <CardRow label="order.card.allGoodsDelivered">
          {allDelivered ? (
            <Date value={allDelivered} />
          ) : (
            <span className="text-xs text-gray-500">&lt;pending&gt;</span>
          )}
        </CardRow>
        {children && isCurrent ? <CardRow full>{children(data)}</CardRow> : null}
        {allShipped > 0 && allDelivered > 0 && (
          <CardRow full>
            <button onClick={showDeliveries} className="text-brown-dim">
              {i18n.value('order.card.viewDeliveries')}
            </button>
          </CardRow>
        )}
      </Card>
      {opened && (
        <ViewDeliveries
          doAction={doAction}
          order={order}
          asSender={asSeller}
          readOnly={readOnly}
          onClick={() => {
            setOpened(false)
          }}
        />
      )}
      {address && (
        <ViewAddress
          order={order}
          onClick={() => {
            setAddress(false)
          }}
        />
      )}
    </>
  )
}

CardDealDelivery.propTypes = {
  order: PropTypes.object.isRequired,
  children: PropTypes.func,
  asSeller: PropTypes.bool,
  readOnly: PropTypes.bool,
  help: PropTypes.string,
  doAction: PropTypes.func,
}
export default memo(CardDealDelivery)
