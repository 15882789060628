import { handleActions } from 'redux-actions'

import * as actions from '../../actions'
import { getLoadListReducer } from 'root/reducers/common'
import { getOrders } from 'root/api'

export const defaultState = {
  items: [],
  page: 0,
  hasNext: true,
  loadFunc: getOrders,
  filters: { dt: 'invoice', s: 'waiting', q: '' },
}

export default handleActions(
  {
    [actions.updateOrdersFilters]: (state, { payload: filters }) => ({
      ...state,
      filters: { ...state.filters, ...filters },
    }),
    [actions.loadOrderListSuccess]: getLoadListReducer(),
    //[actions.loadOrderSuccess]: getPropagateUpdateReducer(),
  },
  defaultState,
)
