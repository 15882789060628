import React, { memo } from 'react'
import Column from './Column'
import EditIcon from 'root/icons/EditIcon'
import { Link } from 'react-router-dom'

const EditColumn = ({ children, ...props }) => (
  <Column
    label="order.diamonds.edit"
    columnClass="w-16 text-center"
    className="w-16 text-center flex items-center justify-center"
    {...props}
  >
    {children ||
      (({ item }) => (
        <Link to={`/products/${item.id}`} className="text-blue-silver hover:text-blue-dark">
          <EditIcon />
        </Link>
      ))}
  </Column>
)

EditColumn.propTypes = Column.propTypes

export default memo(EditColumn)
