import { handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultState = {
  isInitialized: false,
}

export default handleActions(
  {
    [actions.appInitSuccess]: (state) => ({ ...state, isInitialized: true }),
  },
  defaultState,
)
