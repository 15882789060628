import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="20"
    height="21"
    className="stroke-current"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10.1315H16.8245C16.3756 10.1315 15.937 10.0027 15.5637 9.76147L15.3449 9.62051C14.9716 9.37934 14.533 9.25049 14.0841 9.25049H10.6387C10.312 9.25049 9.99604 9.36392 9.74889 9.57096L8.44718 10.8473C8.44718 10.8473 7.77559 11.5064 7.10229 12.1964C7.78979 12.8626 8.53298 12.905 9.17616 12.6919C9.81934 12.4788 10.6631 11.8935 10.6631 11.8935H11.8182L13.5148 13.4232"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M16.5533 16.3904C16.9744 15.9824 17.4982 15.8579 18.1818 15.8579"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M13.5147 13.4233C13.5147 13.4233 15.8721 15.8213 16.5533 16.3907C17.2346 16.9595 16.2971 18.4737 15.1999 17.6395C14.5454 17.0972 12.4431 15.1457 12.4431 15.1457"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5133 17.8179C15.3127 18.2788 14.5036 19.3618 13.4724 18.6064C12.8178 18.0646 11.4587 16.8174 11.4587 16.8174"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M13.8636 18.8198C13.3954 19.7355 12.5744 20.1055 11.8295 19.5372C11.3085 19.1397 10.5159 18.3545 10.5159 18.3545"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M12.074 19.687C11.7922 20.3654 10.9848 20.8048 10.3314 20.41C9.95409 20.182 9.27625 19.7002 9.27625 19.7002"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M0 10.1314H3.99489C4.35398 10.1314 4.70454 10.0285 5.00341 9.83575L5.45114 9.54667C5.75 9.35395 6.10057 9.25098 6.45966 9.25098H8.65909C8.86023 9.25098 9.05511 9.3154 9.2142 9.43434L9.60511 9.69148"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M5.13391 17.0102C5.45891 16.7046 5.40323 16.2674 5.08903 15.9519L5.08448 15.9475C4.76857 15.6304 4.24755 15.6221 3.92085 15.9288L3.45607 16.3605C3.12937 16.6677 3.12085 17.1727 3.43676 17.4904C3.75494 17.8103 4.28051 17.8186 4.60948 17.5097"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M7.02202 16.3523L7.01748 16.3479C6.70157 16.0307 6.18055 16.0225 5.85384 16.3292L4.50271 17.6067C4.176 17.9139 4.16748 18.4188 4.48339 18.7366C4.80157 19.0565 5.32714 19.0647 5.65611 18.7558L7.00214 17.4828C7.32771 17.1766 7.3368 16.6684 7.02202 16.3523Z"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M5.88186 18.5407C5.55288 18.8452 5.53981 19.3507 5.85288 19.67C6.16822 19.9921 6.69379 20.0048 7.02561 19.6981L8.2597 18.5297C8.58754 18.2268 8.60004 17.7186 8.28867 17.4003L8.28413 17.3959C7.97106 17.0765 7.45004 17.0639 7.12106 17.3684"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M7.60804 19.1458C7.28304 19.4547 7.27736 19.9596 7.59497 20.2757C7.91486 20.594 8.44042 20.5995 8.7677 20.2884L9.08077 19.9938C9.40406 19.6865 9.41031 19.1783 9.09383 18.8639L9.08929 18.8595C8.77167 18.544 8.25065 18.5385 7.92565 18.8468"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M15.4501 13.8253C14.3689 14.0412 14.0933 13.7741 13.5149 13.4233"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path d="M3.42096 16.3956C3.13402 16.1308 2.57039 15.877 1.81812 15.877" strokeWidth="0.8" strokeMiterlimit="10" />
    <path d="M16.3637 8.36953V0.44043H4.54548L2.72729 2.20245V8.81003" strokeWidth="0.8" strokeMiterlimit="10" />
    <path d="M5.45446 1.76221V3.08372H4.09082" strokeWidth="0.8" strokeMiterlimit="10" />
    <path d="M6.81812 5.28564H13.1817" strokeWidth="0.8" strokeMiterlimit="10" />
    <path d="M6.81812 7.04785H10.4545" strokeWidth="0.8" strokeMiterlimit="10" />
    <path d="M5.90918 9.25049H10.9092" strokeWidth="0.8" strokeMiterlimit="10" />
  </svg>
)

export default memo(SvgComponent)
