import React from 'react'
import './styles.css'
import { FacebookIcon, AppStoreIcon, GooglePlayIcon } from 'root/icons'
import { SITE_URL } from 'root/api/env'
import LinkedInIcon from 'root/icons/LinkedInIcon'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className="footer-container">
      <div className="footer-inner-container mb:flex-wrap">
        <div className="footer-block">
          <span className="footer-field">
            Phone number:{' '}
            <a href="tel:+1347394-0087" className="footer-field-value">
              +1 (347) 394-0087
            </a>
          </span>
          <span className="footer-field">
            E-mail:{' '}
            <a href="mailto:info@lgdeal.com" className="footer-field-value">
              info@lgdeal.com
            </a>
          </span>
          <span className="footer-field">
            Address: <span className="footer-field-value">228 Park Ave, New York, NY 10003-1502</span>
          </span>
          <div className="footer-networks items-center">
            <a href="http://www.jewelers.org" target="_blank" rel="nofollow noreferrer">
              <img
                style={{ height: '50px', width: 'auto' }}
                src={SITE_URL + '/static/img/v3/jalogo.png'}
                alt="Jewelers of America"
              />
            </a>

            <div className="flex flex-grow justify-center space-x-2 text-brown-dim-light">
              <a href="https://www.linkedin.com/company/lgdeal" rel="nofollow noreferrer" target="_blank">
                <LinkedInIcon />
              </a>
              <a href="https://www.facebook.com/lgdeal.us/" rel="nofollow noreferrer" target="_blank">
                <FacebookIcon />
              </a>
            </div>
          </div>
          <span className="footer-field footer-trademark">© LGDeal 2020&mdash;{currentYear}</span>
        </div>
        <div className="footer-block">
          <span className="footer-title">Products & Services</span>
          <a href={SITE_URL + '/how-it-works'}>How it Works</a>
          <a href={SITE_URL + '/catalog'}>Catalog</a>
          {/*
          <Link to={'/education'}>Education</Link>
          <Link to={'/pricing'}>Pricing</Link>
          <Link to={'/forCustomers'}>For Customers</Link>
          <Link to={'/forSuppliers'}>For Suppliers</Link>
          */}
        </div>
        <div className="footer-block">
          <span className="footer-title">LGDeal</span>
          <a href={SITE_URL + '/about'}>About Us</a>
          {/*
          <Link to={'/customerReviews'}>Customer Reviews</Link>
          <Link to={'/faqs'}>FAQs</Link>
          <Link to={'/contactUs'}>Contact Us</Link>
          */}
        </div>
        <div className="footer-block">
          <span>
            <a href={SITE_URL + '/static/lgdeal-docs/terms-of-use.pdf'}>Terms & Conditions</a>
            {' | '}
            <a href={SITE_URL + '/privacy-policy'}>Privacy Policy</a>
          </span>
          <div className="footer-stores">
            <a href="https://apps.apple.com/us/app/lgdeal/id1554736198" rel="noreferrer" target="_blank">
              <AppStoreIcon />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.lgdeal.mobile" rel="noreferrer" target="_blank">
              <GooglePlayIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
