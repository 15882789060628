import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import Spinner from 'root/components/Spinner'
import i18n from 'root/i18n'
import BulkUploadIcon from 'root/icons/BulkUploadIcon'
import Modal from 'root/components/Modal'
import Button from 'root/components/Buttons/Button'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import DownloadIcon from 'root/icons/DownloadIcon'
import { downloadXlsTemplate } from 'root/api'
import DiamondIcon from 'root/icons/DiamondIcon'

const baseStyle = {
  flex: 1,
  display: 'flex',
  padding: '0',
  outline: 'none',
}

const UploadXls = ({ label, accept, maxFiles = 1, uploadDocumentFunction, refreshFunction }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [result, setResult] = useState(undefined)
  const [updateStock, setUpdateStock] = useState(0)
  let settings = {
    noClick: true,
    noKeyboard: true,
    maxFiles: maxFiles,
    onDropAccepted: (files) => {
      setIsLoading(true)
      setResult(undefined)
      Promise.all(files.map((files) => uploadDocumentFunction(files, updateStock)))
        .then((response) => {
          if (response[0].data.status === 'success') {
            setResult({
              success: response[0].data.data.successCount || 0,
              skipped: response[0].data.data.failedCount || 0,
              path: response[0].data.data.resultFile,
            })
            const failedRows = response[0].data.data.failedRows
            if (failedRows.length > 0) {
              console.log('Failed rows ->')
              console.log(failedRows)
            }
          } else {
            setResult(false)
          }
        })
        .finally(() => {
          if (refreshFunction) refreshFunction()
          setIsLoading(false)
        })
    },
  }

  if (accept) {
    settings.accept = accept
  }
  const { getRootProps, getInputProps, open } = useDropzone(settings)
  const style = {
    ...baseStyle,
  }

  return (
    <>
      <div
        onClick={() => {
          setVisible(true)
        }}
        className="cursor-pointer flex items-center"
      >
        <BulkUploadIcon />
        <span className="ml-2">{i18n.value(label)}</span>
      </div>
      {visible && (
        <Modal
          onClick={() => {
            setVisible(false)
          }}
        >
          <div className="flex flex-col items-center">
            <OrderHeading label={i18n.value('import.title')} />
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="flex flex-col">
                <div className="flex items-center my-4">
                  <input
                    id="import-with-clear"
                    checked={updateStock === 1}
                    onChange={(e) => {
                      setUpdateStock(e.target.checked ? 1 : 0)
                    }}
                    type="checkbox"
                    className="hidden"
                  />
                  <label
                    htmlFor="import-with-clear"
                    className={
                      'cursor-pointer mx-2 border border-white-smoke min-w-12 rounded rounded-xl flex toggled:flex-row-reverse shadow ' +
                      (updateStock ? 'justify-begin' : 'justify-end')
                    }
                  >
                    <i className="text-white rounded rounded-full p-1 bg-brown-dim">
                      <DiamondIcon width={15} height={15} />
                    </i>
                  </label>
                  <label
                    htmlFor="import-with-clear"
                    className="select-none cursor-pointer order-first text-gray-500 toggled:text-brown-dim"
                  >
                    Update current stock
                  </label>
                  <label
                    htmlFor="import-with-clear"
                    className="select-none cursor-pointer order-last text-brown-dim toggled:text-gray-500"
                  >
                    Replace current stock
                  </label>
                </div>
                <div
                  className={`flex flex-col ${updateStock ? '' : 'ml-auto'}`}
                  {...getRootProps({ style })}
                  onClick={open}
                >
                  <input {...getInputProps()} />
                  <div>
                    <Button className="bg-brown-dim">
                      <BulkUploadIcon />
                      <span className="ml-2">{i18n.value('import.chooseFile')}</span>
                    </Button>
                  </div>
                </div>
                <div className="text-gray-500 flex mt-2">
                  <span>or</span>
                  <span
                    onClick={downloadXlsTemplate}
                    className="ml-2 text-blue-skyblue cursor-pointer flex items-center"
                  >
                    <DownloadIcon width={20} height={20} />
                    <span>Download Template</span>
                  </span>
                </div>
                {result !== undefined && (
                  <div className="mt-4 p-4 border border-gray-500 grid grid-cols-2 gap-x-2">
                    {result ? (
                      <>
                        <div className="text-gray-500">Imported:</div>
                        <div>{result.success}</div>
                        <div className="text-gray-500">Skipped:</div>
                        <div>{result.skipped}</div>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={result.path}
                          className="text-brown-dim text-lg hover:underline"
                        >
                          Download Report
                        </a>
                      </>
                    ) : (
                      <>
                        <div className="text-red-500">Error occurred during import.</div>
                        <div>Please contact us.</div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

UploadXls.propTypes = {
  label: PropTypes.string.isRequired,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  uploadDocumentFunction: PropTypes.func.isRequired,
  refreshFunction: PropTypes.func,
}

export default memo(UploadXls)
