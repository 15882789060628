import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DiamondIcon from 'root/icons/DiamondIcon'
import i18n from 'root/i18n'
import { Field } from 'formik'

const ResponsibleSelectorFormik = ({ label, itemKey, isSeller, isEditable }) => {
  const svgClass = isSeller ? 'justify-begin' : 'justify-end'
  return (
    <>
      {label && (
        <label className="w-56" htmlFor={itemKey} onClick={(e) => e.preventDefault()}>
          {i18n.value(label)}
        </label>
      )}
      <div className="flex ml-8 items-center">
        {isEditable ? (
          <>
            <Field
              type="checkbox"
              className="hidden mx-2 form-checkbox form-checkbox-diamond"
              checked={isSeller}
              id={itemKey}
              name={itemKey}
            />
            <label
              htmlFor={itemKey}
              className={
                'cursor-pointer mx-2 border border-white-smoke w-12 rounded rounded-xl flex toggled:flex-row-reverse shadow ' +
                svgClass
              }
            >
              <i className="text-white rounded rounded-full p-1 bg-brown-dim">
                <DiamondIcon width={15} height={15} />
              </i>
            </label>
            <label
              htmlFor={itemKey}
              className="select-none cursor-pointer order-first text-gray-500 toggled:text-brown-dim"
            >
              {i18n.value('common.seller')}
            </label>
            <label
              htmlFor={itemKey}
              className="select-none cursor-pointer order-last text-brown-dim toggled:text-gray-500"
            >
              {i18n.value('common.buyer')}
            </label>
          </>
        ) : (
          <label className="text-brown-dim ml-2">
            {isSeller ? i18n.value('common.seller') : i18n.value('common.buyer')}
          </label>
        )}
      </div>
    </>
  )
}

ResponsibleSelectorFormik.propTypes = {
  label: PropTypes.string,
  itemKey: PropTypes.string.isRequired,
  isSeller: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
}

export default memo(ResponsibleSelectorFormik)
