import React, { memo } from 'react'
import Column from './Column'
import { ExclaimIcon } from 'root/icons'

const ChangesColumn = ({ children, ...other }) => (
  <Column
    columnClass="w-6 text-center"
    className="w-6 text-center flex items-center justify-start cursor-pointer text-brown-dim-light hover:text-brown-dim"
    {...other}
  >
    {children || (({ item }) => item.unitChanges && <ExclaimIcon width={20} height={20} />)}
  </Column>
)

ChangesColumn.propTypes = Column.propTypes

export default memo(ChangesColumn)
