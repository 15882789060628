import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'root/components/Modal'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'

const AlertModal = ({ body, buttons, onClose }) => (
  <Modal onClick={onClose}>
    <div className="text-center max-w-xs text-blue-silver font-medium">{body}</div>
    <div className="flex-row flex -mx-3 justify-center">
      {buttons?.map(({ label, onClick, primary }) => {
        const ButtonComponent = primary ? BrownButton : BlueButton
        return <ButtonComponent className="mx-3 mt-5" key={label} label={label} onClick={onClick} />
      })}
    </div>
  </Modal>
)

AlertModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      primary: PropTypes.bool,
    }).isRequired,
  ),
  onClose: PropTypes.func.isRequired,
}

export default AlertModal
