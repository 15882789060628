import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SignInButton = ({ text, to = 'login', active = true }) => (
  <Link
    className={
      active
        ? 'inline-flex items-center justify-center h-10 px-4 text-xs font-bold text-white outline-none bg-brown-rosy transition duration-200 hover:bg-brown-dim hover:shadow-sm active:shadow-none mb:hidden'
        : 'text-sm text-brown-dim underline hover:text-brown-dim-light'
    }
    to={to}
  >
    {text || 'Login'}
  </Link>
)

SignInButton.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  active: PropTypes.bool,
}

export default SignInButton
