import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import Spinner from 'root/components/Spinner'
import MemoAgreementListTable from 'root/modules/memo-agreements/pages/MemoAgreementsList/components/MemoAgreementListTable'

const MemoAgreementsList = ({ data, loadData, isLoading, updateFilters }) => {
  const { items: memoAgreements, filters } = data

  useEffect(() => {
    loadData(true)
  }, [loadData, filters])

  return (
    <main className="mt-10">
      <div className="h-max-content container mx-auto mt-12 mb-5">
        <div className="mt-2">
          {isLoading ? (
            <div className="container mx-auto mt-8 flex justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="container mx-auto lg:w-screen-lg-min mt-12">
              <MemoAgreementListTable memoAgreements={memoAgreements} />
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

MemoAgreementsList.propTypes = {
  data: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
}

export default memo(connect(MemoAgreementsList))
