import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import OrderTimer from 'root/components/OrderTimer'
import { timestampToDate } from 'root/constants'

const MemoAgreementListRow = ({ item: memoAgreement }) => {
  const history = useHistory()

  const onClick = () => history.push(`/memo-agreements/${memoAgreement.id}`)

  return (
    <tr className={'group hover:bg-gray-200 cursor-pointer'} key={memoAgreement.id} onClick={onClick}>
      <td className="py-1 text-brown-dim">{timestampToDate(memoAgreement.created)}</td>
      <td className="py-1 text-blue-skyblue underline">#{memoAgreement.number}</td>
      <td className="py-1 text-brown-dim underline">{memoAgreement.sellerCompanyId.name}</td>
      <td className="py-1 text-brown-dim underline">{memoAgreement.buyerCompanyId.name}</td>
      {memoAgreement.order ? (
        <>
          <td className="py-1 text-blue-dark">{memoAgreement.order.state.name}</td>
          <td className="py-1 text-blue-dark">
            <OrderTimer timer={memoAgreement.order.timer} />{' '}
          </td>
          <td className="py-1 text-blue-dark">{memoAgreement.order.responsibleId.name}</td>
        </>
      ) : (
        <>
          <td className="py-1 text-blue-dark">{memoAgreement.state.name}</td>
          <td className="py-1 text-blue-dark"> --- </td>
          <td className="py-1 text-blue-dark"> --- </td>
        </>
      )}
    </tr>
  )
}

MemoAgreementListRow.propTypes = {
  item: PropTypes.object.isRequired,
}

export default memo(MemoAgreementListRow)
