import React, { memo } from 'react'

import Modal from 'root/components/Modal'
import connect from './connect'

import './styles.css'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import RequiredLabel from 'root/components/RequiredLabel'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import { Form, Formik } from 'formik'
import { ACTION_SAVE_USER_PASSWORD } from 'root/modules/profile/constants'
import * as Yup from 'yup'
import PasswordInputFormik from 'root/components/PasswordInput/formik'
import { infoAlert } from 'root/components/AlertWrapper'

const FIELD_LABELS = {
  oldPassword: i18n.value('profile.user.labels.oldPassword'),
  newPassword: i18n.value('profile.user.labels.newPassword'),
  newPasswordRepeat: i18n.value('profile.user.labels.newPasswordRepeat'),
}

const ChangePasswordModal = ({ user, canSupervise, savePassword }) => {
  let schemaOptions = {
    newPassword: Yup.string()
      .test({
        name: 'len',
        exclusive: false,
        params: {},
        message: FIELD_LABELS['newPassword'] + ' must be at least 8 characters long',
        test: function (value) {
          return value && value.length >= 8
        },
      })
      .required(FIELD_LABELS['newPassword'] + ' is required'),
    newPasswordRepeat: Yup.string()
      .required(FIELD_LABELS['newPasswordRepeat'] + ' is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  }
  if (user.isPasswordSet) {
    schemaOptions['oldPassword'] = Yup.string().required(FIELD_LABELS['oldPassword'] + ' is required')
  }
  const PasswordSchema = Yup.object().shape(schemaOptions)
  const submittingButtons = [
    { color: 'brown', label: 'common.save', action: ACTION_SAVE_USER_PASSWORD, className: 'profile-button' },
  ]
  return (
    <Modal>
      {canSupervise ? (
        <Formik
          enableReinitialize
          initialValues={{
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
          }}
          validationSchema={PasswordSchema}
          onSubmit={async (values) => {
            let serverData = { ...values }
            const result = await savePassword(serverData)
            if (result) {
              return infoAlert(i18n.value('profile.user.labels.passwordSaved'))
            }
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <div className="container w-128 mb:w-auto">
                <OrderHeading label={i18n.value('profile.user.labels.changePassword')} />
                {user.isPasswordSet ? (
                  <div className="flex mb:flex-col mb:items-start justify-between mt-4 items-center">
                    <RequiredLabel
                      label={i18n.value('profile.user.labels.oldPassword')}
                      className="w-48 mb:w-auto text-blue-skyblue"
                    />
                    <div className="w-128">
                      <PasswordInputFormik
                        itemKey={'oldPassword'}
                        placeHolder={i18n.value('profile.user.labels.password')}
                        handleChange={handleChange}
                        value={values.oldPassword}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="flex mb:flex-col mb:items-start justify-between mt-4 items-center">
                  <RequiredLabel
                    label={i18n.value('profile.user.labels.newPassword')}
                    className="w-48 mb:w-auto text-blue-skyblue"
                  />
                  <div className="w-128 mb:w-auto">
                    <PasswordInputFormik
                      value={values.newPassword}
                      itemKey={'newPassword'}
                      placeHolder={i18n.value('profile.user.labels.password')}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="text-gray-500 pr-10 pt-1 text-sm text-right mb:text-left">
                  {i18n.value('profile.user.labels.passwordHint')}
                </div>
                <div className="flex mb:flex-col mb:items-start justify-between mt-4 items-center">
                  <RequiredLabel
                    label={i18n.value('profile.user.labels.newPasswordRepeat')}
                    className="w-48 mb:w-auto text-blue-skyblue"
                  />
                  <div className="w-128 mb:w-auto">
                    <PasswordInputFormik
                      itemKey={'newPasswordRepeat'}
                      placeHolder={i18n.value('profile.user.labels.password')}
                      handleChange={handleChange}
                      value={values.newPasswordRepeat}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <FormikSubmittingButtons
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    buttons={submittingButtons}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="container w-128">
          <OrderHeading label={i18n.value('profile.user.labels.changePassword')} />
          {i18n.value('profile.user.labels.noAccessToChangePassword')}
        </div>
      )}
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  user: PropTypes.object.isRequired,
  savePassword: PropTypes.func.isRequired,
  canSupervise: PropTypes.bool,
}

export default memo(connect(ChangePasswordModal))
