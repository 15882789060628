import { connect } from 'react-redux'
import { loadMemoAgreement } from 'root/modules/memo-agreements/thunks'
import { getActiveMemoAgreementData, isActiveMemoAgreementLoading } from 'root/modules/memo-agreements/selectors'
import { getUserData } from 'root/selectors'

const mapStateToProps = (state) => ({
  user: getUserData(state),
  data: getActiveMemoAgreementData(state),
  isLoading: isActiveMemoAgreementLoading(state),
})

const mapDispatchToProps = {
  loadMemoAgreement,
}

export default connect(mapStateToProps, mapDispatchToProps)
