import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.2826 20.423C19.6444 20.423 20.8044 19.1144 20.8044 17.8698V12.1251C20.8044 10.0187 16.6876 8.93359 14.5 8.93359C12.3124 8.93359 8.19568 10.0187 8.19568 12.1251V17.8698C8.19568 19.1144 9.35568 20.423 10.7174 20.423"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.8479 21.6987C27.0773 21.6987 28.3697 21.0285 28.3697 19.7838V14.0391C28.3697 11.9328 24.669 10.8477 22.6957 10.8477C22.5003 10.8477 22.286 10.8604 22.0653 10.8796"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 7.0197C15.9689 7.0197 17.0217 5.97927 17.0217 4.46651V3.18991C17.0217 1.67714 15.9689 0.636719 14.5 0.636719C13.0311 0.636719 11.9783 1.67714 11.9783 3.18991V4.46651C11.9783 5.97927 13.0311 7.0197 14.5 7.0197Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.8479 29.9973V15.3164"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.2827 29.9981V13.4023"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10.7174 13.4023V29.9981"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 19.7852V29.9979"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M22.6957 8.93376C24.1646 8.93376 25.2174 7.89333 25.2174 6.38057V5.10397C25.2174 3.59121 24.1646 2.55078 22.6957 2.55078C21.2268 2.55078 20.174 3.59121 20.174 5.10397V6.38057C20.174 7.89333 21.2268 8.93376 22.6957 8.93376Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.15223 21.6987C1.92288 21.6987 0.630493 21.0285 0.630493 19.7838V14.0391C0.630493 11.9328 4.33115 10.8477 6.30441 10.8477C6.49984 10.8477 6.71419 10.8604 6.93484 10.8796"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.15222 29.9973V15.3164"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M6.30445 8.93376C4.83554 8.93376 3.78271 7.89333 3.78271 6.38057V5.10397C3.78271 3.59121 4.83554 2.55078 6.30445 2.55078C7.77337 2.55078 8.82619 3.59121 8.82619 5.10397V6.38057C8.82619 7.89333 7.77337 8.93376 6.30445 8.93376Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
