import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'
import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DealClosedIcon from 'root/icons/DealClosedIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import { STATE_COMPLETED } from 'root/modules/orders/constants'

const CardDealClosed = ({ order, help }) => {
  const fulfillDate = order.fulfillDate
  const completed = order.state.key === STATE_COMPLETED
  return (
    <Card help={help} label="order.card.dealClosed" Icon={DealClosedIcon} completed={completed} last>
      {completed && (
        <CardRow full>
          <Date value={fulfillDate} />
        </CardRow>
      )}
    </Card>
  )
}
CardDealClosed.propTypes = {
  order: PropTypes.object.isRequired,
  help: PropTypes.string,
}
export default memo(CardDealClosed)
