import React, { memo } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import i18n from 'root/i18n'
import { useEffectOnce, useToggle } from 'root/hooks'
import Modal from 'root/components/Modal'
import DataTable from 'root/components/DataTable'
import Spinner from 'root/components/Spinner'

import MyTeam from 'root/modules/profile/components/MyTeam'
import { ACTION_SAVE_COMPANY_TEAM } from 'root/modules/profile/constants'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import BlueButton from 'root/components/Buttons/BlueButton'

import connect from './connect'
import './styles.css'
import { phoneRegExp, ROLE_OWNER, ROLE_SELLER, ROLE_SUPERVISOR } from 'root/constants'

const FIELD_LABELS = {
  firstName: i18n.value('profile.company.team.labels.firstName'),
  lastName: i18n.value('profile.company.team.labels.lastName'),
  email: i18n.value('profile.company.team.labels.email'),
  phone: i18n.value('profile.company.team.labels.phone'),
  position: i18n.value('profile.company.team.labels.position'),
  role: i18n.value('profile.company.team.labels.role'),
  password: i18n.value('profile.company.team.labels.password'),
}

const tableHeaders = [
  {
    name: FIELD_LABELS['firstName'],
    id: 'firstName',
    className: 'profile-my-team-modal-table-big',
  },
  {
    name: FIELD_LABELS['lastName'],
    id: 'lastName',
    className: 'profile-my-team-modal-table-big',
  },
  { name: FIELD_LABELS['email'], id: 'email', className: 'profile-my-team-modal-table-big-grey' },
  { name: FIELD_LABELS['phone'], id: 'phone', className: 'profile-my-team-modal-table-big-grey' },
  { name: FIELD_LABELS['position'], id: 'position', className: 'profile-my-team-modal-table-big-grey' },
  {
    name: FIELD_LABELS['role'],
    id: 'mainRole',
    className: 'profile-my-team-modal-table-big-grey',
    // eslint-disable-next-line react/prop-types,react/display-name
    render: ({ value }) => <div>{i18n.value(`role.${value}`)}</div>,
    // eslint-disable-next-line react/prop-types,react/display-name
    edit: ({ field }) => {
      return (
        <select {...field} className="form-select text-left  shadow shadow-inset px-2 py-0">
          {[ROLE_SELLER, ROLE_SUPERVISOR, ROLE_OWNER].map((value) => (
            <option key={value} value={value}>
              {i18n.value(`role.${value}`)}
            </option>
          ))}
        </select>
      )
    },
  },
  {
    name: FIELD_LABELS['password'],
    id: 'password',
    onlyEdit: true,
  },
]

const submittingButtons = [
  { color: 'brown', label: 'common.save', action: ACTION_SAVE_COMPANY_TEAM, className: 'profile-button' },
]

const CompanyTeamSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .required(FIELD_LABELS['firstName'] + ' is required')
        .max(100),
      lastName: Yup.string()
        .required(FIELD_LABELS['lastName'] + ' is required')
        .max(100),
      email: Yup.string()
        .required(FIELD_LABELS['email'] + ' is required')
        .email('Enter a valid email like info@lgdeal.com')
        .max(100),
      phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      position: Yup.string().max(100, FIELD_LABELS['position'] + ' must be less than 100 characters'),
      password: Yup.string()
        .min(8, FIELD_LABELS['password'] + ' must be at least 8 characters')
        .max(100, FIELD_LABELS['password'] + ' must be less than 100 characters'),
    }),
  ),
})

const MyTeamModal = ({ data, loadCompanyTeam, saveCompanyTeamThunk, isLoading, canSupervise }) => {
  useEffectOnce(() => {
    loadCompanyTeam()
  }, [])
  const [editing, toggleEditing] = useToggle(false)

  if (!canSupervise) {
    return (
      <Modal>
        <MyTeam />
        <div>{i18n.value('no_access')}</div>
      </Modal>
    )
  }

  if (isLoading)
    return (
      <Modal>
        <div className="profile-my-team-modal flex flex-col">
          <MyTeam />
          <div className="flex flex-1 items-center justify-center">
            <Spinner />
          </div>
        </div>
      </Modal>
    )

  const users = [...data]

  // Replace null values with empty string
  for (let i = 0; i < users.length; i++) {
    let user = users[i]
    for (const [key, value] of Object.entries(user)) {
      if (value === null) users[i][key] = ''
    }
  }

  const initialValues = { users, action: '' }
  const onSubmit = (values) => {
    toggleEditing()
    const serverData = { ...values }
    delete serverData.action

    return saveCompanyTeamThunk(serverData)
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={CompanyTeamSchema} onSubmit={onSubmit}>
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit, resetForm, setSubmitting, setValues }) => (
        <Form onSubmit={handleSubmit}>
          <Modal>
            <div className="profile-my-team-modal">
              <MyTeam />
              {isLoading || isSubmitting ? (
                <Spinner />
              ) : (
                <>
                  {!!Object.keys(errors).length && (
                    <section className="my-4 bg-red-300 text-red-600">
                      {errors.users.map((item, index) => (
                        <div key={index}>
                          {item
                            ? Object.entries(item).map(([key, value]) => (
                                <div key={key + index}>
                                  Row {index + 1} -&gt; {value}
                                </div>
                              ))
                            : []}
                        </div>
                      ))}
                    </section>
                  )}
                  <DataTable
                    data={data}
                    headers={tableHeaders}
                    isEditing={editing}
                    rowsKey={'users'}
                    removeItem={(index) => {
                      const users = [...values.users]
                      users.splice(index, 1)
                      setValues({ ...values, users })
                    }}
                  />
                  <div className="flex flex-1 justify-between">
                    {editing ? (
                      <div className="flex items-center">
                        <FormikSubmittingButtons
                          handleSubmit={handleSubmit}
                          isSubmitting={isSubmitting}
                          setFieldValue={setFieldValue}
                          buttons={submittingButtons}
                        />
                        <BlueButton
                          onClick={() => {
                            toggleEditing()
                            //because they were dynamic and changed values..need to get to initial state
                            setValues({ ...values, users: [...data] })
                            setSubmitting(false)
                            resetForm()
                          }}
                          type={'button'}
                          label={'common.cancel'}
                          className={'profile-button ml-4'}
                        />
                      </div>
                    ) : (
                      <div className="flex">
                        <Link className="default-button button-space-right" to={'invite'}>
                          {i18n.value('profile.company.team.buttons.addManager')}
                        </Link>
                        <BlueButton
                          onClick={toggleEditing}
                          type={'button'}
                          label={'common.edit'}
                          className={'profile-button'}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

MyTeamModal.propTypes = {
  data: PropTypes.array.isRequired,
  loadCompanyTeam: PropTypes.func.isRequired,
  saveCompanyTeamThunk: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  canSupervise: PropTypes.bool,
}

export default memo(connect(MyTeamModal))
