import React, { memo } from 'react'
import Column from './Column'

const IndexColumn = ({ children, ...props }) => (
  <Column
    label="order.diamonds.number"
    columnClass="w-3 text-center"
    className="w-3 text-center text-blue-dark"
    {...props}
  >
    {children || (({ index }) => index + 1)}
  </Column>
)

IndexColumn.propTypes = Column.propTypes

export default memo(IndexColumn)
