import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = {}

export default handleActions(
  {
    [actions.loadKycInfoStart]: (
      state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadKycInfoSuccess]: (state, { payload: kycInfo }) => {
      return kycInfo
    },
  },
  defaultState,
)
