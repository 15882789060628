import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserData } from 'root/selectors'
import { toClassName } from 'root/constants'
import i18n from 'root/i18n'
import Step0 from 'root/modules/activation/pages/Activation/components/Step0'
import Step1 from 'root/modules/activation/pages/Activation/components/Step1'
import Step2 from 'root/modules/activation/pages/Activation/components/Step2'
import Step3 from 'root/modules/activation/pages/Activation/components/Step3'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import MessageBox from 'root/components/MessageBox'
import CheckIcon from 'root/icons/CheckIcon'
import EyeIcon from 'root/icons/EyeIcon'
import LogoIcon from 'root/icons/LogoIcon'
import TeamIcon from 'root/icons/TeamIcon'

const steps = [Step0, Step1, Step2, Step3]
const stepsCount = steps.length

function getInitialStep(user) {
  return 0
  /*
  if (!user.firstName || !user.firstName.trim().length > 0 || !user.phoneConfirmed || !user.emailConfirmed) {
    return 1
  }
  if (!user?.companyId?.name) {
    return 2
  }
  return stepsCount - 1
   */
}

const Activation = ({ user }) => {
  const initialStep = getInitialStep(user)
  const [currentStep, setStep] = useState(initialStep)
  const CurrentStep = steps[currentStep]
  const nextStep = useCallback(() => {
    if (currentStep < stepsCount - 1) {
      setStep(currentStep + 1)
    }
  }, [currentStep])
  const goBack = useCallback(() => {
    if (currentStep > 0) {
      setStep(currentStep - 1)
    }
  }, [currentStep])
  if (initialStep === 0) {
    return (
      <div className="container mx-auto sm:w-screen-lg-min">
        <OrderHeading label={i18n.value('profile.registrationSuccess')} />
        <MessageBox className="mt-8 w-1/2" icon={<TeamIcon />}>
          <div>{i18n.value('profile.registrationSuccessInfo1')}</div>
          <div>{i18n.value('profile.registrationSuccessInfo2')}</div>
        </MessageBox>
        <Step0 user={user} />
      </div>
    )
  }
  return (
    <div className="container mx-auto sm:w-screen-lg-min">
      <div className="flex mb:items-start mb:mt-8">
        <div className="text-brown-late text-3xl font-damascus">
          {i18n.value('activation.labels.step')} {currentStep + 1}
        </div>
        <div className="ml-auto relative flex items-center">
          <div className="z-10 flex items-center justify-between space-x-16 mb:space-x-8">
            {steps.map((step, index) => (
              <div
                className={toClassName([
                  'w-8 h-8  flex items-center border justify-center rounded-full',
                  index > currentStep
                    ? 'bg-white text-gray-500 border-gray-500'
                    : 'border-transparent bg-brown-dim text-white',
                ])}
                key={index}
              >
                {index + 1}
              </div>
            ))}
          </div>
          <div className="absolute left-0 h-1 w-full bg-brown-dim" />
        </div>
      </div>
      <div>
        <CurrentStep goBack={goBack} nextStep={nextStep} user={user} />
      </div>
    </div>
  )
}
Activation.propTypes = {
  user: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  user: getUserData(state),
})

export default memo(connect(mapStateToProps)(Activation))
