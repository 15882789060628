import React, { memo } from 'react'

import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import PropTypes from 'prop-types'
import connect from './connect'
import Spinner from 'root/components/Spinner'
import { Form, Formik } from 'formik'
import FormikSubmittingButtons
  from 'root/components/Buttons/FormikSubmittingButtons'
import { ACTION_SAVE_COMPANY_PROFILE } from 'root/modules/profile/constants'
import FormikToggle from 'root/components/FormikToggle'
import {
  clearLocalFiles,
  ensureBoolean,
  errorAlert,
  getLocalFiles,
} from 'root/constants'
import UploadDocument from 'root/modules/orders/pages/components/UploadDocument'
import { ACTION_INVOICE_CREATED } from 'root/modules/orders/constants'
import { uploadCompanyFile, uploadOrderDocument } from 'root/api'
import UploadDocumentInsideFormik
  from 'root/modules/orders/pages/components/UploadDocument/inside_formik'
import DownloadIcon from 'root/icons/DownloadIcon'

const submittingButtons = [
  {
    color: 'brown',
    label: 'common.save',
    action: ACTION_SAVE_COMPANY_PROFILE,
    className: 'profile-button',
  },
]
const KycModal = ({
  company,
  isLoading,
  loadCompanyCommonInfo,
  saveCompanyProfileThunk,
  canSupervise,
  isAdmin,
}) => {
  if (isLoading || !company.id) return <Spinner/>

  if (!canSupervise) {
    return (
      <Modal>
        <div>{i18n.value('no_access')}</div>
      </Modal>
    )
  }

  return (
    <Modal>
      <div>
        <OrderHeading label={i18n.value('profile.company.labels.KYC')}/>

        <Formik
          enableReinitialize
          initialValues={{
            requireKycOnInvoice: ensureBoolean(company.requireKycOnInvoice),
            requireKycOnMemo: ensureBoolean(company.requireKycOnMemo),
            allowKYC: ensureBoolean(company.allowKYC),
          }}
          onSubmit={async (values) => {
            const files = getLocalFiles()
            const serverData = {
              ...values,
              files: files.length ? files[0] : null,
            }
            return saveCompanyProfileThunk(serverData).then(() => {
              clearLocalFiles()
              return loadCompanyCommonInfo()
            }).catch(errorAlert)
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            setFieldValue,
            handleSubmit,
            resetForm,
            setSubmitting,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="container mb:p-4">
                {isSubmitting ? (
                  <Spinner/>
                ) : (
                  <div
                    className="flex-col mb:flex-col mb:space-x-0 space-y-4 my-10">
                    {isAdmin ?
                      <div
                        className="mt-2 flex items-left items-center text-brown-dim space-x-4">
                        <label className="text-gray-500 flex space-x-4">
                          <span>{i18n.value(
                            'profile.company.labels.allowKYC')}</span>
                          <FormikToggle itemKey="allowKYC" labelOn="common.yes"
                                        labelOff="common.no"/>
                        </label>
                      </div>
                      : null}

                    <div
                      className="mt-2 flex items-left items-center text-brown-dim space-x-4">
                      <label className="text-gray-500 flex space-x-4">
                        <span>{i18n.value(
                          'profile.company.labels.requireKycOnInvoice')}</span>
                        <FormikToggle itemKey="requireKycOnInvoice"
                                      labelOn="common.yes"
                                      labelOff="common.no"/>
                      </label>
                    </div>

                    <div
                      className="mt-2 flex items-left items-center text-brown-dim space-x-4">
                      <label className="text-gray-500 flex space-x-4">
                        <span>{i18n.value(
                          'profile.company.labels.requireKycOnMemo')}</span>
                        <FormikToggle itemKey="requireKycOnMemo"
                                      labelOn="common.yes"
                                      labelOff="common.no"/>
                      </label>
                    </div>
                    <UploadDocumentInsideFormik
                      buttons={[]}
                      accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      uploadDocumentFunction={(file) => uploadCompanyFile(
                        company.id, file).catch(errorAlert)}
                      isSubmitting={isSubmitting}
                      handleSubmit={() => {}}
                      setFieldValue={() => {}}
                    />

                    {company.kycTemplate ?
                      <a href={company.kycTemplate}
                         target="_blank"
                         className="flex space-x-2 items-center font-bold text-blue-skyblue">
                        <DownloadIcon width={20} height={20}/>
                        <span>{i18n.value(
                          'profile.company.labels.downloadKycTemplate')}</span>
                      </a> : null}

                    <div>
                      <FormikSubmittingButtons
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        buttons={submittingButtons}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

KycModal.propTypes = {
  company: PropTypes.object,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
  saveCompanyProfileThunk: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  canSupervise: PropTypes.bool,
  isAdmin: PropTypes.bool,
}

export default memo(connect(KycModal))
