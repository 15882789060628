import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import CloseButton from '../CloseButton'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'

const AttachedMedia = ({ images, removeImage }) => {
  const renderItem = useCallback(
    ({ uri, type }, index) => (
      <div key={uri} style={styles.imageBox}>
        {type === 'video' ? (
          <video style={styles.image} src={uri} autoPlay />
        ) : type === 'image' ? (
          <img style={styles.image} src={uri} alt="" />
        ) : (
          <div style={styles.image}>
            <DocumentRowIcon width={32} height={32} />
          </div>
        )}
        <CloseButton style={styles.removeImage} onClick={removeImage(index)} />
      </div>
    ),
    [removeImage],
  )

  return <div style={styles.container}>{images.map(renderItem)}</div>
}

AttachedMedia.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({ uri: PropTypes.string.isRequired })).isRequired,
  removeImage: PropTypes.func.isRequired,
}

export default memo(AttachedMedia)
