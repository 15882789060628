import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'

import CardRow from '../components/CardRow'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import DealTermsAgreedIcon from 'root/icons/DealTermsAgreedIcon'
import ViewTermsButton from './components/ViewTermsButton'

const CardDealAgreed = ({ order, help }) => (
  <Card help={help} label="order.card.dealTermsAgreed" Icon={DealTermsAgreedIcon} completed>
    <CardRow label="order.card.supplierConfirmation">
      <Date value={order.dealAgreedSeller} />
    </CardRow>
    <CardRow label="order.card.customerConfirmation">
      <Date value={order.dealAgreedBuyer} />
    </CardRow>
    <CardRow full>
      <ViewTermsButton order={order} />
    </CardRow>
  </Card>
)

CardDealAgreed.propTypes = {
  order: PropTypes.object.isRequired,
  help: PropTypes.string,
}

export default memo(CardDealAgreed)
