import { connect } from 'react-redux'

import { savePassword } from 'root/thunks'
import { canSupervise, getUserData } from 'root/selectors'

const mapStateToProps = (state) => ({
  user: getUserData(state),
  canSupervise: canSupervise(state),
})

const mapDispatchToProps = {
  savePassword,
}

export default connect(mapStateToProps, mapDispatchToProps)
