import TextLabel from 'root/components/TextLabel'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { calcAverageR, rapaportSchema } from 'root/modules/orders/constants'
import { trimNumber } from 'root/constants'
import { COLOR_FANCY } from '../../../../../products/constants'
import TextValue from 'root/modules/orders/pages/Order/components/components/TextValue'

let averageReferenceDiscount = 0
const validateAverageR = (averageR) => {
  let error
  if (averageR !== '' && averageR <= 0) {
    error = 'Average reference discount value must be greater than 0'
  }
  if (averageR >= 100) {
    error = `Average reference discount value must be less than 100`
  }
  return error
}
const AverageR = ({ isEditable, setFieldValue, items }) => (
  <div className="flex w-full">
    {isEditable ? (
      <>
        <div className="">
          <TextLabel label="orders.labels.averageR" className="text-brown-dim" />
          <span className="text-brown-dim">, %</span>
        </div>
        <div className="ml-10 pl-1">
          <InputTermFormik
            onChange={async (e) => {
              setFieldValue(`averageR`, trimNumber(e.target.value))

              averageReferenceDiscount = Number.parseFloat(e.target.value)
              if (await rapaportSchema.isValid(e.target.value)) {
                Object.values(items)
                  // eslint-disable-next-line eqeqeq
                  .filter(({ colorType }) => colorType.id != COLOR_FANCY)
                  .forEach(({ itemId, referencePrice }) => {
                    referencePrice = Number.parseFloat(referencePrice)
                    if (!referencePrice) return

                    const price = ((100 - averageReferenceDiscount) * referencePrice) / 100
                    setFieldValue(`orderItems['${itemId}'].price`, price)
                    setFieldValue(`orderItems['${itemId}'].referenceDiscount`, averageReferenceDiscount)
                  })
              }
            }}
            validate={() => {
              averageReferenceDiscount = !averageReferenceDiscount ? calcAverageR(items) : averageReferenceDiscount
              return validateAverageR(averageReferenceDiscount)
            }}
            inputClass="w-16"
            itemKey="averageR"
            isEditable
          />
        </div>
      </>
    ) : calcAverageR(items) ? (
      <div className="flex w-full">
        <div className="w-3/5" />
        <div className="w-2/5 flex justify-end">
          <TextLabel label="orders.labels.averageR" className="pr-4  whitespace-no-wrap" />
          <TextValue className="ml-2">{calcAverageR(items)}%</TextValue>
        </div>
      </div>
    ) : null}
  </div>
)

AverageR.propTypes = {
  setFieldValue: PropTypes.func,
  isEditable: PropTypes.bool.isRequired,
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default memo(AverageR)
