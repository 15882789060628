import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'

import Modal from 'root/components/Modal'

import './styles.css'
import PropTypes from 'prop-types'
import connect from './connect'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import RequiredLabel from 'root/components/RequiredLabel'
import { Form, Formik } from 'formik'
import { ACTION_SAVE_COMPANY_TEAM } from 'root/modules/profile/constants'
import * as Yup from 'yup'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import BlueButton from 'root/components/Buttons/BlueButton'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import OrderSubHeader from 'root/components/OrderSubHeader'
import { errorAlert, phoneRegExp, ROLE_OWNER, ROLE_SELLER, ROLE_SUPERVISOR } from 'root/constants'

const FIELD_LABELS = {
  firstName: i18n.value('profile.company.team.labels.firstName'),
  lastName: i18n.value('profile.company.team.labels.lastName'),
  email: i18n.value('profile.company.team.labels.email'),
  phone: i18n.value('profile.company.team.labels.phone'),
  position: i18n.value('profile.company.team.labels.position'),
  mainRole: i18n.value('profile.company.team.labels.role'),
  password: i18n.value('profile.company.team.labels.password'),
}

const InviteModal = ({ saveCompanyTeamThunk, canSupervise }) => {
  const history = useHistory()
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    position: '',
    action: '',
    mainRole: ROLE_SELLER,
  }

  const submittingButtons = [
    {
      color: 'brown',
      label: 'profile.company.team.buttons.inviteUser',
      action: ACTION_SAVE_COMPANY_TEAM,
      className: 'profile-button',
    },
  ]

  const CompanyTeamSchema = Yup.object().shape({
    firstName: Yup.string().required(FIELD_LABELS['firstName'] + ' is required'),
    lastName: Yup.string().max(100),
    email: Yup.string()
      .required(FIELD_LABELS['email'] + ' is required')
      .email('Enter a valid email like info@lgdeal.com')
      .max(100),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    position: Yup.string().max(100),
    password: Yup.string().min(8).max(100),
  })

  const fields = [
    {
      key: 'mainRole',
      isRequired: false,
      // eslint-disable-next-line react/prop-types,react/display-name
      render: ({ field }) => (
        <select {...field} className="form-select w-64 text-left  shadow shadow-inset px-2 py-0">
          {[ROLE_SELLER, ROLE_SUPERVISOR, ROLE_OWNER].map((value) => (
            <option key={value} value={value}>
              {i18n.value(`role.${value}`)}
            </option>
          ))}
        </select>
      ),
    },
    { key: 'firstName', isRequired: true },
    { key: 'lastName', isRequired: false },
    { key: 'email', isRequired: true },
    { key: 'phone', isRequired: true },
    { key: 'position', isRequired: false },
    { key: 'password', isRequired: false },
  ]

  const rights = ['owner', 'seniorManager', 'salesManager']

  if (!canSupervise) {
    return (
      <Modal>
        <OrderHeading label={i18n.value('profile.company.team.buttons.addManager')} />
        <div>{i18n.value('no_access')}</div>
      </Modal>
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={CompanyTeamSchema}
      onSubmit={async (values) => {
        let serverData = { ...values }
        delete serverData['action']

        await Promise.resolve(
          saveCompanyTeamThunk({
            users: [serverData],
          }),
        )
          .then(() => {
            history.push('/company/team')
          })
          .catch(errorAlert)
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit, resetForm, setSubmitting, setValues }) => (
        <Form onSubmit={handleSubmit}>
          <Modal>
            <div className="profile-invite-modal container w-max-content flex justify-between">
              <div className="p-4 w-1/2 border-r border-gray-300">
                <OrderHeading label={i18n.value('profile.company.team.buttons.addManager')} />
                {fields.map((field) => {
                  return (
                    <div className="mt-2" key={field.key}>
                      {field.isRequired ? (
                        <RequiredLabel label={FIELD_LABELS[field.key]} />
                      ) : (
                        <span className="text-gray-500">{FIELD_LABELS[field.key]}</span>
                      )}
                      <InputFormik isEditable itemKey={field.key} value={values[field.key]} inputClass={'w-64'}>
                        {field.render}
                      </InputFormik>
                    </div>
                  )
                })}
              </div>
              <div className="p-4 w-1/2">
                <div className="flex flex-col">
                  <div className="flex-1">
                    <OrderSubHeader label={'profile.company.team.label.userPermissions'} />
                    <div className="mt-10">
                      {rights.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="border-gray-300 border-b flex text-left items-center justify-between w-full my-4"
                          >
                            <div className="text-brown-dim">
                              {i18n.value('profile.company.team.labels.rights.' + item)}
                            </div>
                            <div className="text-gray-500">
                              {i18n.value('profile.company.team.labels.rightsDescription.' + item)}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <FormikSubmittingButtons
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      setFieldValue={setFieldValue}
                      buttons={submittingButtons}
                    />
                    <BlueButton
                      onClick={() => {
                        //because they were dynamic and changed values..need to get to initial state
                        setValues({ ...values, users: [] })
                        setSubmitting(false)
                        history.replace('/company/team')
                      }}
                      type={'button'}
                      label={'common.cancel'}
                      className={'profile-button ml-4'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

InviteModal.propTypes = {
  saveCompanyTeamThunk: PropTypes.func.isRequired,
  canSupervise: PropTypes.bool,
}

export default memo(connect(InviteModal))
