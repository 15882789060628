import { connect } from 'react-redux'
import { canSupervise, isAdmin } from 'root/selectors'
import { loadCompanyCommonInfo, saveCompanyProfileThunk } from 'root/modules/profile/thunks'
import { getProfileCompanyData, isProfileCompanyLoading } from 'root/modules/profile/selectors'

export default connect(
  (state) => ({
    isAdmin: isAdmin(state),
    canSupervise: canSupervise(state),
    data: getProfileCompanyData(state),
    isLoading: isProfileCompanyLoading(state),
  }),
  {
    loadCompanyCommonInfo,
    saveCompanyProfileThunk,
  },
)
