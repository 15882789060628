import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { toClassName } from 'root/constants'
import i18n from 'root/i18n'
import Balloon from 'root/components/Balloon'

const StatusTabItem = ({ item, isActive, labelPrefix, notifications, subtext, ...rest }) => (
  <span
    {...rest}
    className={toClassName([
      'p-4 hover:border-brown-dim-lighter cursor-pointer mx-3 py-3 border-t-2 flex items-center',
      isActive ? 'text-brown-dim border-brown-dim font-bold' : 'text-brown-dim border-transparent',
    ])}
  >
    {item.icon && <i className="pr-2">{item.icon}</i>}
    <span className="pt-1">{i18n.value(labelPrefix + '.' + (item.label || item.key))}</span>
    {notifications ? <Balloon>{notifications}</Balloon> : null}
    {subtext ? (
      <Balloon inverted color="brown-dim-light" round={false}>
        {subtext}
      </Balloon>
    ) : null}
  </span>
)

StatusTabItem.propTypes = {
  item: PropTypes.object.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  notifications: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subtext: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default memo(StatusTabItem)
