import React, { memo, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { ErrorMessage, Form, Formik } from 'formik'
import Input from 'root/modules/activation/pages/Activation/components/Input'
import Phone from 'root/modules/login/pages/Login/components/Phone'
import { COUNTRY_INFO_BY_CODE, DEVICE_CODE, errorAlert, isValidPhone } from 'root/constants'
import * as Yup from 'yup'
import BrownButton from 'root/components/Buttons/BrownButton'
import { activateFirstStep, askHelpWithLogin, confirmActivationCode, sendActivationCode } from 'root/api'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { infoAlert } from 'root/components/AlertWrapper'
import BlueButton from 'root/components/Buttons/BlueButton'

const Step = ({ nextStep, user }) => {
  const phoneConfirmed = user.phoneConfirmed
  const emailConfirmed = user.emailConfirmed
  const [oneTimeKey, setOneTimeKey] = useState()
  const [requestError, setRequestError] = useState()
  const [usedLogin, setUsedLogin] = useState()
  const requestCode = useCallback((login) => {
    setRequestError(undefined)
    setUsedLogin(login)
    return sendActivationCode(login)
      .then((response) => {
        setOneTimeKey(response.data.data.oneTimeKey)
      })
      .catch((error) => {
        setRequestError(error.response.data.message)
      })
  }, [])
  const phone = parsePhoneNumberFromString(user.phone, DEVICE_CODE)
  const [countryCode, setCountryCode] = useState(phone?.country || DEVICE_CODE)
  const [phoneValue, setPhoneValue] = useState(phone?.nationalNumber || '')
  const isPhoneValid = useMemo(
    () => (phoneValue ? isValidPhone(countryCode, phoneValue) : false),
    [countryCode, phoneValue],
  )
  const [code, setCode] = useState('')
  const [codeConfirmed, setCodeConfirmed] = useState(false)
  const [verifyError, setVerifyError] = useState()
  const [usedHelp, setUsedHelp] = useState(false)

  const askForHelpWithLogin = useCallback(() => askHelpWithLogin(usedLogin), [usedLogin])

  const doAskForHelp = useCallback(() => {
    return askForHelpWithLogin()
      .then(() => {
        setUsedHelp(true)
        return infoAlert(i18n.value('login.askForHelpConfirmation'))
      })
      .catch(errorAlert)
  }, [askForHelpWithLogin])

  const verifyCode = useCallback(() => {
    setVerifyError(undefined)
    if (code.trim() === '') {
      setVerifyError('Code can not be empty')
    } else {
      return confirmActivationCode(oneTimeKey, code.trim())
        .then(() => {
          setCodeConfirmed(true)
        })
        .catch((error) => {
          setVerifyError(error.response.data.message)
        })
    }
  }, [code, oneTimeKey])
  const schema = Yup.string().email().required()
  return (
    <div>
      <div className="mt-12">
        <div className="text-brown-dim text-lg">{i18n.value('activation.labels.tellUsAboutYou')}</div>
        <div className="mt-10">
          <Formik
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().trim().min(1).required(),
              email: Yup.string()
                .email()
                .required()
                .test('emailConfirmed', 'You need to verify your email', () => emailConfirmed || codeConfirmed),
              phone: Yup.string()
                .required()
                .test('phoneConfirmed', 'You need to verify your phone', () => phoneConfirmed || codeConfirmed),
            })}
            initialValues={{
              name: user.firstName || '',
              email: user.email || '',
              phone: phone?.nationalNumber || '',
            }}
            onSubmit={(values) => {
              return activateFirstStep(values.name).then(nextStep)
            }}
          >
            {({ values, isValid, submitCount, setFieldValue, isSubmitting, handleSubmit, errors }) => (
              <Form onSubmit={handleSubmit} className="flex flex-col space-y-20">
                <div className="w-1/2 mb:w-full space-y-10">
                  <Input itemKey="name" label="activation.labels.yourName" />
                  {!emailConfirmed && !codeConfirmed && (
                    <div>
                      <Input itemKey="email" label="activation.labels.yourEmail" type="email" />
                      {!oneTimeKey && (
                        <div className="mt-2">
                          <BrownButton
                            onClick={async () => {
                              const valid = await schema.isValid(values.email)
                              if (!valid) {
                                setRequestError('Email is invalid')
                              } else {
                                return requestCode(values.email.trim())
                              }
                            }}
                            type="button"
                            label="activation.labels.sendCode"
                          />
                          {requestError && <div className="text-red-500 mt-2">{requestError}</div>}
                        </div>
                      )}
                    </div>
                  )}
                  {!phoneConfirmed && !codeConfirmed && (
                    <div>
                      <Input itemKey="phone" label="activation.labels.yourPhone" type="email">
                        {({ field }) => (
                          <div className="flex mt-2">
                            <Phone
                              phone={field.value}
                              countryCode={countryCode}
                              setCountryCode={setCountryCode}
                              setPhone={(value) => {
                                setRequestError(undefined)
                                setPhoneValue(value)
                                setFieldValue(field.name, value)
                              }}
                              isPhoneValid={isPhoneValid}
                              disabled={isSubmitting}
                            />
                          </div>
                        )}
                      </Input>
                      {isPhoneValid && !oneTimeKey && (
                        <div className="mt-2">
                          <BrownButton
                            onClick={() => requestCode(`${COUNTRY_INFO_BY_CODE[countryCode].dialCode}-${values.phone}`)}
                            type="button"
                            label="activation.labels.sendCode"
                          />
                          {requestError && <div className="text-red-500 mt-2">{requestError}</div>}
                        </div>
                      )}
                    </div>
                  )}
                  {!codeConfirmed && oneTimeKey && (
                    <div className="flex space-x-8">
                      <div>
                        <div className="text-gray-500 text-sm">{i18n.value('activation.labels.codeIsSent')}</div>
                        <div className="flex space-x-4 mt-2">
                          <input
                            value={code}
                            type="number"
                            onChange={(e) => {
                              setVerifyError(undefined)
                              setCode(e.target.value.trim())
                            }}
                            className="form-input w-16 text-left text-brown-dim border-gray-300 shadow shadow-inset px-2 py-0 "
                          />
                          <BrownButton onClick={verifyCode} type="button" label="activation.labels.verify" />
                        </div>
                        {verifyError && <div className="mt-2 text-red-500 text-sm">{verifyError}</div>}
                      </div>

                      {!usedHelp && (
                        <div className="flex flex-col text-gray-500 text-sm space-y-2">
                          <div>{i18n.value('activation.labels.helpWithLoginText')}</div>
                          <div>
                            <BlueButton className="text-xs" onClick={doAskForHelp}>
                              {i18n.value('login.askForHelp')}
                            </BlueButton>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-32">
                  {isValid || submitCount === 0 ? (
                    ''
                  ) : (
                    <section className="w-1/2 mb:w-full my-4 bg-red-300 text-red-600">
                      {Object.keys(errors).map(
                        (key) =>
                          errors[key] && (
                            <div className="flex space-x-4" key={key}>
                              <div>{key}:</div>
                              <ErrorMessage name={key} component="div" />
                            </div>
                          ),
                      )}
                    </section>
                  )}
                  <div className="flex space-x-4 ">
                    <BrownButton disabled={isSubmitting} label="activation.labels.next" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
Step.propTypes = {
  nextStep: PropTypes.func,
  user: PropTypes.object.isRequired,
}
export default memo(Step)
