import React, { memo } from 'react'
import i18n from 'root/i18n'
import UploadedFilesList from 'root/modules/orders/pages/components/UploadedFilesList'
import PropTypes from 'prop-types'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import ViewMemoDocs from 'root/components/ViewMemoDocs'

const ViewOrderMemoDocs = ({ order }) => {
  let supplementaryDocs = null
  const { memoId, documents } = order
  if (documents?.length > 0) {
    const [{ files, id }] = documents
    if (files?.length > 0) {
      supplementaryDocs = (
        <div className={'mt-4'}>
          <OrderHeading label={i18n.value('orders.labels.memoSupplementaryFilesList')} />
          <UploadedFilesList files={files} documentId={id} />
        </div>
      )
    }
  }

  return <ViewMemoDocs memo={memoId} supplementaryDocs={supplementaryDocs} />
}

ViewOrderMemoDocs.propTypes = {
  order: PropTypes.object.isRequired,
}

export default memo(ViewOrderMemoDocs)
