import { createAction } from 'redux-actions'

const NAMESPACE = 'PRODUCTS::'

export const updateProductsFilters = createAction(NAMESPACE + 'UPDATE_PRODUCTS_FILTERS')
export const resetProductsFilters = createAction(NAMESPACE + 'RESET_PRODUCTS_FILTERS')

export const loadProductsListStart = createAction(NAMESPACE + 'LOAD_PRODUCTS_LIST_START')
export const loadProductsListSuccess = createAction(NAMESPACE + 'LOAD_PRODUCTS_LIST_SUCCESS')
export const loadProductsListFailure = createAction(NAMESPACE + 'LOAD_PRODUCTS_LIST_FAILURE')

export const loadProductStart = createAction(NAMESPACE + 'LOAD_PRODUCT_START')
export const loadProductSuccess = createAction(NAMESPACE + 'LOAD_PRODUCT_SUCCESS')
export const loadProductFailure = createAction(NAMESPACE + 'LOAD_PRODUCT_FAILURE')
