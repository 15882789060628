import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13 0.5H7.5V3H13V0.5Z" stroke="currentColor" strokeMiterlimit="10" />
    <path d="M2.5 17.5H3.5" stroke="currentColor" strokeMiterlimit="10" />
    <path d="M4.5 17.5H5.5" stroke="currentColor" strokeMiterlimit="10" />
    <path
      d="M20 8.5V0.5H0.5V19.5H15C18.04 19.5 20.5 17.04 20.5 14C20.5 10.96 18.04 8.5 15 8.5H12V7L7.5 10L12 12.5V11.5H15C16.38 11.5 17.5 12.62 17.5 14C17.5 15.38 16.38 16.5 15 16.5H11"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </svg>
)

export default memo(SvgComponent)
