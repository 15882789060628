import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import OrderTimer from 'root/components/OrderTimer'
import OrderSubHeader from 'root/components/OrderSubHeader'
import { useParams } from 'react-router-dom'
import Diamonds, {
  CertificateColumn,
  ChangesColumn,
  DeliveryColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  RemoveColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import AddProductButton
  from 'root/modules/orders/pages/Order/components/components/AddProductButton'
import AverageR
  from 'root/modules/orders/pages/Order/components/components/AverageR'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import ReadonlyMemoTerms
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ReadonlyMemoTerms'
import BrownButton from 'root/components/Buttons/BrownButton'
import { postOrderMessage } from 'root/api'
import { SITE_URL } from 'root/api/env'
import { infoAlert } from 'root/components/AlertWrapper'
import DeliveryPrice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/DeliveryPrice'
import AddressSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/AddressSection'
import SpecialTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/SpecialTermsSection'
import { SECTION_SALES } from 'root/modules/orders/constants'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'

const MemoNegotiationBody = ({
  order,
  isEditable = true,
  values,
  showTimer,
  performOrderAction,
  setFieldValue,
  asSeller,
  showKyc = true,
}) => {
  const { section } = useParams()
  const { buyerCompanyId, sellerCompanyId } = order
  const { name: buyerOrSupplierName } = section === SECTION_SALES
    ? buyerCompanyId
    : sellerCompanyId

  return (
    <div className="flex flex-col space-y-12">
      <section>
        <div
          className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
          <OrderSubHeader label={'orders.labels.generalTerms'}/>
          {order.timer.isStarted && showTimer && <OrderTimer timer={order.timer}
                                                             label={'orders.labels.replyTimer'}/>}
        </div>

        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={isEditable}
          validFor={values.memoTermDays}
          seller={!asSeller && order.sellerCompanyId.name}
          buyer={asSeller && order.buyerCompanyId.name}
          asSeller={asSeller}
          validDaysId="memoTermDays"
          validDaysLabel="orders.labels.memoTerm"
          showKYC={showKyc}
        >
          <ViewOrderFiles order={order} doAction={performOrderAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds
          showDeleted={false}
          isEditable={isEditable}
          items={values.orderItems}
          doAction={performOrderAction}
          className="mt-4"
        >
          <ChangesColumn/>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
          <RefPriceColumn isEditable={isEditable}/>
          <RapaportColumn isEditable={isEditable}/>
          <PriceColumn isEditable={isEditable}/>
          <DeliveryColumn isEditable={isEditable}/>
          {isEditable && <RemoveColumn/>}
        </Diamonds>

        <div className="flex mt-4">
          {isEditable ? (
            <BrownButton
              type="button"
              onClick={() => {
                return postOrderMessage(
                  order.id,
                  i18n.value('order.view_my_stock') +
                  '\n\n' +
                  `${SITE_URL}/catalog?s=${order.sellerCompanyId.catalogId}`,
                ).then(() => {
                  infoAlert(i18n.value('order.view_my_stock_success'))
                })
              }}
              slim
              className="bg-brown-dim-light mr-2"
            >
              {i18n.value('send_stones_from_supplier')}
            </BrownButton>
          ) : (
            <BrownButton
              type="button"
              onClick={() => {
                window.open(
                  `${SITE_URL}/catalog?s=${order.sellerCompanyId.catalogId}`)
              }}
              slim
              className="bg-brown-dim-light"
            >
              {i18n.value('view_stones_from_supplier')}
            </BrownButton>
          )}
          <div className="flex flex-grow">
            <div className="ml-48 w-64">
              <AverageR items={values.orderItems} setFieldValue={setFieldValue}
                        isEditable={isEditable}/>
            </div>
            <div className="ml-3 pl-6 mr-24">
              <DeliveryPrice isEditable={isEditable} order={order}/>
            </div>
            {isEditable && (
              <div className="ml-auto">
                <AddProductButton doAction={performOrderAction}/>
              </div>
            )}
          </div>
        </div>
      </section>

      {(values.specialTerms || isEditable) && (
        <section>
          <SpecialTermsSection isEditable={isEditable} values={values}/>
        </section>
      )}

      <section>
        <AddressSection isOpened={false} withoutNames={isEditable}
                        isEditable={isEditable} values={values}/>
      </section>

      <section className="mt-12">
        <ReadonlyMemoTerms order={order}/>
      </section>
    </div>
  )
}

MemoNegotiationBody.propTypes = {
  order: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  showTimer: PropTypes.bool,
  performOrderAction: PropTypes.func,
  setFieldValue: PropTypes.func,
  asSeller: PropTypes.bool,
  showKyc: PropTypes.bool,
}

export default memo(MemoNegotiationBody)
