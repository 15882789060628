import React, { memo } from 'react'
import Column from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds/Column'

const EmptyColumn = ({ children, ...other }) => (
  <Column
    columnClass="w-12 text-center"
    className="w-12 text-center text-brown-dim flex justify-center items-center"
    {...other}
  >
    {children ||
      (({ item }) => {
        return <div>&nbsp;</div>
      })}
  </Column>
)
EmptyColumn.propTypes = Column.propTypes
export default memo(EmptyColumn)
