import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'root/icons'
import { COUNTRY_INFO_BY_CODE, toClassName } from 'root/constants'

const AddressCard = ({ address, onDelete, onClick }) => {
  const { companyName, countryId, cityName, regionName, addressLine1, zipCode, recipientName, recipientPhone } = address
  const country = COUNTRY_INFO_BY_CODE[countryId].name

  return (
    <div
      onClick={onClick(address.id)}
      className={toClassName([
        'p-2 hover:bg-brown-dim-light hover:text-blue-smoke cursor-pointer border border-brown-dim-light mb-2 flex justify-between items-center',
      ])}
    >
      {[companyName, country, cityName, regionName, addressLine1, zipCode, recipientName, recipientPhone].join(' ')}
      <CloseIcon onClick={onDelete(address.id)} className="inline float-right fill-current" />
    </div>
  )
}

AddressCard.propTypes = {
  address: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(AddressCard)
