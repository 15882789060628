import PropTypes from 'prop-types'
import React, { memo, useCallback, useState } from 'react'
import Button from 'root/components/Buttons/Button'
import Modal from 'root/components/Modal'
import Spinner from 'root/components/Spinner'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import BrownButton from 'root/components/Buttons/BrownButton'
import { getOrderMemos } from 'root/api'
import { timestampToDate } from 'root/constants'
import { ACTION_EXISTING_MEMO_REQUEST } from 'root/modules/orders/constants'

const SelectMemoButton = ({ order, doAction }) => {
  const [opened, setOpened] = useState(false)
  const [memos, setMemos] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const requestMemo = useCallback(
    async (id) => {
      setSubmitting(true)
      try {
        await doAction(ACTION_EXISTING_MEMO_REQUEST, { memoId: id })
      } catch (e) {
        setSubmitting(false)
      }
    },
    [doAction],
  )
  const openDialog = useCallback(async () => {
    setOpened(true)
    if (memos.length === 0) {
      setLoading(true)
      try {
        const {
          data: { data },
        } = await getOrderMemos(order.id)
        setMemos(data)
      } catch (e) {
        console.warn(e)
      }
      setLoading(false)
    }
  }, [memos, order.id])
  return (
    <>
      <Button
        type="button"
        onClick={openDialog}
        className="bg-brown-dim-light mr-4"
        disabled={false}
        label="orders.buttons.selectMemo"
      />
      {opened && (
        <Modal
          onClick={() => {
            setOpened(false)
          }}
        >
          <OrderHeading label={i18n.value('orders.labels.chooseMemo')} />
          <div className="bg-gray-100 mt-5">
            <div className="h-10 mb-6 flex text-gray-500 items-center">
              <div className="w-32 pl-2">{i18n.value('memoList.date')}</div>
              <div className="w-32">{i18n.value('memoList.name')}</div>
              <div className="w-64">{i18n.value('memoList.customer')}</div>
              <div className="w-32" />
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            memos.map((memo) => (
              <div key={memo.id} className="flex items-center my-1 text-brown-dim">
                <div className="w-32 pl-2">{timestampToDate(memo.created)}</div>
                <div className="w-32">{memo.number}</div>
                <div className="w-64">{order.buyerCompanyId.name}</div>
                <div className="w-32">
                  <BrownButton disabled={submitting} label="memoList.choose" onClick={() => requestMemo(memo.id)} />
                </div>
              </div>
            ))
          )}
        </Modal>
      )}
    </>
  )
}
SelectMemoButton.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
}
export default memo(SelectMemoButton)
