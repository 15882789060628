export default {
  flex: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  paddingTop: {
    paddingTop: 3,
  },
  paddingBottom: {
    paddingBottom: 3,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    width: '100%',
    padding: 1,
    flex: 1,
    cursor: 'pointer',
  },
  topLeft: {
    borderTopLeftRadius: 4,
  },
  topRight: {
    borderTopRightRadius: 4,
  },
  bottomLeft: {
    borderBottomLeftRadius: 4,
  },
  bottomRight: {
    borderBottomRightRadius: 4,
  },
}
