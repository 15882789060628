import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    stroke="currentColor"
  >
    <path d="M7.09747 5.67635C7.09747 6.0666 6.77817 6.3859 6.38791 6.3859H0.711504C0.321251 6.3859 0.00195312 6.0666 0.00195312 5.67635C0.00195312 5.2861 0.321251 4.9668 0.711504 4.9668H6.38791C6.77817 4.9668 7.09747 5.2861 7.09747 5.67635Z" />
    <path d="M2.21557 3.16467L0.711325 4.67602L0.207544 5.1798C-0.0691812 5.45652 -0.0691812 5.90354 0.207544 6.18027L0.711325 6.67695L2.21557 8.1812C2.4923 8.45792 2.93931 8.45792 3.21604 8.1812C3.49276 7.90447 3.49276 7.45746 3.21604 7.18073L1.71179 5.67648L3.21604 4.17224C3.49276 3.89551 3.49276 3.44849 3.21604 3.17177C2.94641 2.88795 2.4923 2.88795 2.21557 3.16467Z" />
    <path d="M11.0001 10.6431C11.0001 11.0334 10.6808 11.3527 10.2905 11.3527H6.74275C6.3525 11.3527 6.0332 11.0334 6.0332 10.6431C6.0332 10.2529 6.3525 9.93359 6.74275 9.93359H10.2905C10.6808 9.93359 11.0001 10.2529 11.0001 10.6431Z" />
    <path d="M11.0001 0.709551C11.0001 1.0998 10.6808 1.4191 10.2905 1.4191H6.74275C6.3525 1.4191 6.0332 1.0998 6.0332 0.709551C6.0332 0.319298 6.3525 0 6.74275 0H10.2905C10.6808 0 11.0001 0.319298 11.0001 0.709551Z" />
    <path d="M11.0002 0.709551V10.6433C11.0002 11.0335 10.6809 11.3528 10.2906 11.3528C9.90035 11.3528 9.58105 11.0335 9.58105 10.6433V0.709551C9.58105 0.319298 9.90035 0 10.2906 0C10.6809 0 11.0002 0.319298 11.0002 0.709551Z" />
  </svg>
)

export default memo(SvgComponent)
