import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDiamondsContext } from './Table'
import { FormikContext } from 'formik'

const Column = ({
  className = '',
  columnClass = '',
  isEditable,
  label,
  item,
  index,
  children,
  doAction,
  isCheckBox,
  values,
  valuesKey,
  items,
  setFieldValue,
}) => {
  const context = { ...useDiamondsContext() }
  const formContext = React.useContext(FormikContext)
  if (isEditable !== undefined) {
    context.isEditable = isEditable
  }
  if (!formContext && context.isEditable) {
    console.warn('You try to use <Diamonds> table in edit mode outside of form context')
  }
  context.isEditable = formContext !== undefined && context.isEditable
  return (
    <div className={item ? className : columnClass}>
      {children({
        item,
        index,
        label,
        isCheckBox,
        values,
        items,
        valuesKey,
        setFieldValue,
        ...context,
        formContext,
        doAction,
      })}
    </div>
  )
}
Column.propTypes = {
  className: PropTypes.string,
  columnClass: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.func.isRequired,
  item: PropTypes.object,
  index: PropTypes.number,
  isEditable: PropTypes.bool,
  doAction: PropTypes.func,
  isCheckBox: PropTypes.bool,
  values: PropTypes.object,
  valuesKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  items: PropTypes.array,
}
export default memo(Column)
