import React, { memo, useState } from 'react'
import i18n from 'root/i18n'
import PropTypes from 'prop-types'
import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import MemoNegotiationBody from '../../../../components/MemoNegotiationBody'
import { RESPONSIBLE_SELLER } from 'root/modules/orders/constants'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'

const ViewMemoDealTerms = ({ order, asSeller }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const values = {
    memoTermDays: order.memoTermsDays,
    address: order.address,
    specialTerms: order.specialTerms,
    deliveryPrice: order.deliveryPrice.value,
    orderItems: order.products,

    //default memo fields
    memoPaymentTermDays: order.memoId.paymentTermDays,
    nonReturnPeriod: order.memoId.nonReturnDays,
    returnShipmentPeriod: order.memoId.returnShipmentDays,
    memoPenaltyDelayPerDay: order.memoId.dailyDelayedPaymentFee,
    isTaxesResponsibleSeller: order.memoId.taxesResponsible ===
      RESPONSIBLE_SELLER,
    isBankResponsibleSeller: order.memoId.bankCommissionResponsible ===
      RESPONSIBLE_SELLER,
    isDeliveryResponsibleSeller: order.memoId.deliveryPaymentsResponsible ===
      RESPONSIBLE_SELLER,
    isReturnResponsibleSeller: order.memoId.returnPaymentResponsible ===
      RESPONSIBLE_SELLER,
    specialTermsMemo: order.memoId.specialTerms,
  }

  return (
    <>
      <div onClick={openModal}
           className="text-blue-skyblue cursor-pointer flex items-center hover:underline">
        <DocumentRowIcon width={20} height={20}/>
        <span className={'ml-2'}>{i18n.value(
          'orders.labels.viewMemoDealTerms')}</span>
      </div>
      {modalIsOpen && (
        <Modal onClick={closeModal} isFull>
          <OrderHeading label={i18n.value('orders.labels.dealTerms')}/>
          <MemoNegotiationBody order={order} showTimer={false} values={values}
                               isEditable={false} asSeller={asSeller}
                               showKyc={false}/>
        </Modal>
      )}
    </>
  )
}

ViewMemoDealTerms.propTypes = {
  order: PropTypes.object.isRequired,
  asSeller: PropTypes.bool,
}

export default memo(ViewMemoDealTerms)
