import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = {}

export default handleActions(
  {
    [actions.loadMemoAgreementStart]: (state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadMemoAgreementSuccess]: (state, { payload: order }) => (order.id === state.id ? order : state),
  },
  defaultState,
)
