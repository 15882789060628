import instance from './instance'
import {
  transformCatalogFrame,
  transformCompany,
  transformCompanyTeam,
} from 'root/api/transformers'

export const downloadServiceAgreement = () => {
  return instance({
    method: 'get',
    downloadFile: true,
    url: `/company/download-service-agreement`,
    coreServer: true,
    auth: true,
  })
}

export const requestFtpAccess = (company) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/request-ftp-access`,
    auth: true,
    data: { id: company.id },
  })
}

export const getCompanyCommonInfo = () => {
  return instance({
    coreServer: true,
    method: 'get',
    url: `/company/common-info`,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const saveCompanyProfile = (data) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/common-info`,
    data,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const uploadCompanyAvatar = (file) => {
  return instance({
    method: 'post',
    url: `/company/upload-avatar`,
    upload: file,
    coreServer: true,
    auth: true,
  })
}

export const getCatalogFrameInfo = () => {
  return instance({
    coreServer: true,
    method: 'get',
    url: `/company/catalog-frame-info`,
    auth: true,
    transformResponse: transformCatalogFrame,
  })
}

export const requestStripeConnectLink = (data) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/stripe-connect-link`,
    data,
    auth: true,
  })
}
export const saveCompanyFrameInfo = (data) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/catalog-frame-info`,
    data,
    auth: true,
    transformResponse: transformCatalogFrame,
  })
}

export const getCompanyCreditInfo = () => {
  return instance({
    coreServer: true,
    method: 'get',
    url: `/company/credit-info`,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const saveCompanyCreditInfo = (data) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/credit-info`,
    data,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const getCompanyKycInfo = () => {
  return instance({
    coreServer: true,
    method: 'get',
    url: `/company/kyc-info`,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const saveCompanyKycInfo = (data) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/kyc-info`,
    data,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const getCompanyInvoiceTerms = () => {
  return instance({
    coreServer: true,
    method: 'get',
    url: `/company/invoice-terms`,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const saveCompanyInvoiceTerms = (data) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/invoice-terms`,
    data,
    auth: true,
    transformResponse: transformCompany,
  })
}

export const getCompanyTeam = () => {
  return instance({
    coreServer: true,
    method: 'get',
    url: `/company/users`,
    auth: true,
    transformResponse: transformCompanyTeam,
  })
}

export const saveCompanyTeam = (data) => {
  return instance({
    coreServer: true,
    method: 'post',
    url: `/company/users`,
    data,
    auth: true,
    transformResponse: transformCompanyTeam,
  })
}
