import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.1986 9.05185L9.54028 16.7102C8.60208 17.6484 7.3296 18.1755 6.00278 18.1755C4.67596 18.1755 3.40348 17.6484 2.46528 16.7102C1.52708 15.772 1 14.4995 1 13.1727C1 11.8459 1.52708 10.5734 2.46528 9.63519L10.1236 1.97685C10.7491 1.35138 11.5974 1 12.4819 1C13.3665 1 14.2148 1.35138 14.8403 1.97685C15.4657 2.60232 15.8171 3.45064 15.8171 4.33519C15.8171 5.21973 15.4657 6.06805 14.8403 6.69352L7.17361 14.3519C6.86088 14.6646 6.43672 14.8403 5.99445 14.8403C5.55217 14.8403 5.12801 14.6646 4.81528 14.3519C4.50255 14.0391 4.32685 13.615 4.32685 13.1727C4.32685 12.7304 4.50255 12.3063 4.81528 11.9935L11.8903 4.92685"
      stroke="#7F7F7F"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
