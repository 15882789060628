import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'

import connect from './connect'
import InvoiceOrder from './components/InvoiceOrder'
import MemoOrder from './components/MemoOrder'
import {
  DEAL_TYPE_INVOICE,
  SECTION_PURCHASES,
  SECTION_SALES,
  STAGE_REQUEST,
  FINAL_STATES,
} from 'root/modules/orders/constants'
import Spinner from 'root/components/Spinner'
import NotFound from 'root/components/NotFound'
import { UpDownSprite, DiamondShapeSprite } from 'root/icons'
import { OrderChat } from 'root/modules/chat'
import BrownButton from 'root/components/Buttons/BrownButton'
import i18n from 'root/i18n'
import { ROLE_OWNER, ROLE_SELLER, ROLE_SUPERVISOR } from 'root/constants'

const Order = ({
  data,
  loadOrder,
  isLoading,
  companyData,
  isProfileCompanyLoading,
  loadCompanyCommonInfo,
  isSeller,
  isBuyer,
  performOrderAction,
  user,
}) => {
  const { id, section } = useParams()

  useEffect(() => {
    if (!companyData.id && !companyData.hasFullCreditInfo) {
      loadCompanyCommonInfo()
    } else if (companyData.hasFullCreditInfo || user.isAdmin) {
      loadOrder({ id })
    }
  }, [companyData, loadOrder, id, loadCompanyCommonInfo, user.isAdmin])

  if (isLoading || isProfileCompanyLoading) return <Spinner />

  const isSales = section === SECTION_SALES

  if (companyData.id && !companyData.hasFullCreditInfo && !user.isAdmin) {
    return (
      <main className="mt-10 flex flex-col items-center">
        <div className="text-gray-500 text-lg my-8">{i18n.value('orders.labels.creditInfoRequired')}</div>
        <Link to={`/company/credit-info`}>
          <BrownButton type={'button'} label={'profile.buttons.creditInfo'} className={'profile-button'} />
        </Link>
      </main>
    )
  }

  if ((section === SECTION_PURCHASES && !isBuyer) || (isSales && !isSeller)) return <NotFound />
  const isInvoice = data.dealType === DEAL_TYPE_INVOICE
  const notMySale = isSales && data?.responsibleId?.id !== user.id
  const iAmSupervisor = user.mainRole === ROLE_SUPERVISOR
  const iAmOwner = user.mainRole === ROLE_OWNER
  const iAmManager = user.mainRole === ROLE_SELLER
  const dealIsOwnedByOwner = data?.responsibleId?.mainRole === ROLE_OWNER
  // const dealIsOwnedBySupervisor = data?.responsibleId?.mainRole === ROLE_SUPERVISOR
  // const dealIsOwnedByPoweredUser = /* dealIsOwnedBySupervisor || */ dealIsOwnedByOwner
  const readOnly =
    notMySale && (iAmManager || (iAmSupervisor && dealIsOwnedByOwner) || (iAmOwner && dealIsOwnedByOwner))

  const showChat = user.isAdmin || (!FINAL_STATES.includes(data.state.key) && data.stage !== STAGE_REQUEST)

  if (companyData.id && !companyData.hasFullCreditInfo && !user.isAdmin) {
    return (
      <main className="mt-10 flex flex-col items-center">
        <div className="text-gray-500 text-lg my-8">{i18n.value('orders.labels.creditInfoRequired')}</div>
        <Link to={`/company/credit-info`}>
          <BrownButton type={'button'} label={'profile.buttons.creditInfo'} className={'profile-button'} />
        </Link>
      </main>
    )
  }

  return (
    <>
      {showChat ? <OrderChat /> : null}
      <UpDownSprite />
      <DiamondShapeSprite />
      {isInvoice ? (
        <InvoiceOrder readOnly={readOnly} order={data} asSeller={isSales} doAction={performOrderAction} />
      ) : (
        <MemoOrder readOnly={readOnly} order={data} asSeller={isSales} />
      )}
    </>
  )
}

Order.propTypes = {
  data: PropTypes.object.isRequired,
  companyData: PropTypes.object.isRequired,
  isProfileCompanyLoading: PropTypes.bool.isRequired,
  loadOrder: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  isSeller: PropTypes.bool.isRequired,
  isBuyer: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
}

export default memo(connect(Order))
