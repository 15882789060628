import { connect } from 'react-redux'

import { getCreditInfoData, isCreditInfoLoading } from 'root/modules/profile/selectors'
import { loadCompanyCommonInfo, loadCreditInfo, saveCreditInfo } from 'root/modules/profile/thunks'
import { canSupervise } from 'root/selectors'

const mapStateToProps = (state) => ({
  data: getCreditInfoData(state),
  isLoading: isCreditInfoLoading(state),
  canSupervise: canSupervise(state),
})

const mapDispatchToProps = {
  loadCreditInfo,
  saveCreditInfo,
  loadCompanyCommonInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)
