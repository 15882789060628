import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  ACTION_MEMO_PAD_REQUEST_INVOICE_FROM_SELLER,
  ACTION_MEMO_PAD_RETURN_TO_SELLER,
} from 'root/modules/orders/constants'

import Diamonds, {
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import {
  RequestReturnColumn,
  TimerColumn,
  EmptyColumn,
  RequestPaymentColumn,
  CheckBoxColumn,
} from 'root/modules/orders/pages/Order/components/MemoOrder/components/MemoPaymentAndDelivery/Diamonds'

import CheckboxInput from 'root/modules/login/pages/Login/components/CheckboxInput'
import i18n from 'root/i18n'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import MessageBox from 'root/components/MessageBox'
import ClockIcon from 'root/icons/ClockIcon'

const StockItemsTab = ({ order, isSubmitting, handleSubmit, setFieldValue, values, isRequest, isSeller }) => {
  const requiredState = isRequest ? 'return_request' : 'customer_stock'
  const products = order.products.filter(({ state }) => state === requiredState)

  const { hasSelected, allSelected } = useMemo(() => {
    const selectedValues = Object.values(values.itemsSelectedInTable || {})

    let hasSelected = false
    let allSelected = selectedValues.length === products.length
    for (const isSelected of selectedValues) {
      if (isSelected) hasSelected = true
      else allSelected = false
      if (hasSelected && !allSelected) break
    }
    return { hasSelected, allSelected }
  }, [products.length, values.itemsSelectedInTable])

  if (!products.length)
    return (
      <div>
        <MessageBox icon={<ClockIcon />}>{i18n.value('order.no-diamonds-yet')}</MessageBox>
      </div>
    )

  const props = { setFieldValue, isSubmitting, handleSubmit }
  let buttons
  if (!isSeller) {
    buttons = [
      {
        color: 'brown',
        label: 'orders.memo.buttons.requestInvoice',
        action: ACTION_MEMO_PAD_REQUEST_INVOICE_FROM_SELLER,
        disabled: !hasSelected,
      },
      {
        color: 'blue',
        label: 'orders.memo.buttons.return',
        action: ACTION_MEMO_PAD_RETURN_TO_SELLER,
        disabled: !hasSelected,
      },
    ]
  }

  return (
    <div className="relative">
      {!isSeller && (
        <div className="absolute left-0 top-0 ml-5 mt-2">
          <CheckboxInput
            value={allSelected}
            labelAfter={i18n.value('common.all')}
            id={'toggleSelectAll'}
            onChange={() => {
              products.forEach(({ id }) => setFieldValue(`itemsSelectedInTable['${id}']`, !allSelected))
            }}
          />
        </div>
      )}
      <Diamonds showDeleted={false} items={products} className="mt-4">
        {!isSeller && (
          <CheckBoxColumn values={values} valuesKey={'itemsSelectedInTable'} setFieldValue={setFieldValue} />
        )}
        <ShapeColumn />
        <NameColumn />
        <EmptyColumn />
        <EmptyColumn />
        <EmptyColumn />
        {isRequest && <EmptyColumn />}
        {isSeller ? (
          <>
            {!isRequest && <RequestReturnColumn {...props} />}
            <RequestPaymentColumn {...props} />
          </>
        ) : (
          <>
            {!isRequest && <TimerColumn timerKey={'nonReturn'} timerLabel={'order.diamonds.timer.nonReturn'} />}
            <TimerColumn timerKey={'memoPeriod'} timerLabel={'order.diamonds.timer.memoPeriod'} />
          </>
        )}
      </Diamonds>
      {!isSeller && (
        <div className="flex items-start my-4">
          <FormikSubmittingButtons
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            buttons={buttons}
          />
        </div>
      )}
    </div>
  )
}

StockItemsTab.propTypes = {
  order: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isRequest: PropTypes.bool,
  isSeller: PropTypes.bool.isRequired,
}

export default memo(StockItemsTab)
