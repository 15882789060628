import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="10"
    height="10"
    className="fill-current"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 2.5H2.5V0H0V2.5ZM3.75 10H6.25V7.5H3.75V10ZM0 10H2.5V7.5H0V10ZM0 6.25H2.5V3.75H0V6.25ZM3.75 6.25H6.25V3.75H3.75V6.25ZM7.5 0V2.5H10V0H7.5ZM3.75 2.5H6.25V0H3.75V2.5ZM7.5 6.25H10V3.75H7.5V6.25ZM7.5 10H10V7.5H7.5V10Z" />
  </svg>
)

export default memo(SvgComponent)
