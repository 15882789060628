import React, { memo } from 'react'

const UpDownSprite = () => (
  <svg display="none" className="hidden">
    <defs>
      <g id="down">
        <circle cx="8" cy="8" r="8" fill="#90B9D7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.19829 11.5212L12.3966 7.00001H4L8.19829 11.5212Z"
          fill="white"
        />
      </g>
      <g id="up">
        <circle r="8" transform="matrix(1 0 0 -1 8 8)" fill="#90B9D7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.19829 4.47876L12.3966 8.99999H4L8.19829 4.47876Z"
          fill="white"
        />
      </g>
    </defs>
  </svg>
)
export default memo(UpDownSprite)
