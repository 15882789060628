import React, { memo, useState } from 'react'
import i18n from 'root/i18n'
import DocumentsIcon from 'root/icons/DocumentsIcon'
import PropTypes from 'prop-types'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import Modal from 'root/components/Modal'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'
import { getOrderFile, uploadOrderFile } from 'root/api'
import { ACTION_ORDER_FILE_UPLOAD } from 'root/modules/orders/constants'
import UploadDocument from 'root/modules/orders/pages/components/UploadDocument'

const ViewOrderFiles = ({ order, doAction }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const heading = i18n.value('orders.labels.orderFilesList') + ' #' + order.number
  return (
    <>
      <div className="flex">
        <div onClick={openModal} className="text-blue-skyblue cursor-pointer flex items-center hover:underline">
          <DocumentsIcon width={20} height={20} />
          <span className={'ml-2 pt-1'}>{i18n.value('orders.labels.viewOrderFiles')}</span>
        </div>
      </div>
      {modalIsOpen && (
        <Modal onClick={closeModal} isFull>
          <div className="container items-center mx-auto m-12">
            <OrderHeading label={heading} />
            <div className="mx-auto items-center">
              <div className="">
                <div className="bg-gray-100 mt-5">
                  <div className="h-10 mb-6 pl-4 flex text-center grid-cols-3 items-center text-gray-500 mx-auto">
                    <div className="w-8 text-center">&nbsp;</div>
                    <div className="w-64 text-center">
                      <span>{i18n.value('files.list.tableHead.created')}</span>
                    </div>
                    <div className="w-128 text-center">
                      <span>{i18n.value('files.list.tableHead.name')}</span>
                    </div>
                  </div>
                </div>
                {order.files.map((file, index) => {
                  const dt = new Date(file.created * 1000)
                  return (
                    <div
                      onClick={() => getOrderFile(order.id, file.id)}
                      key={index}
                      className="cursor-pointer h-max-content pl-4 pt-1 pb-1 flex text-center grid-cols-3 items-center text-brown-dim mx-auto hover:bg-gray-100"
                    >
                      <div className="w-8 text-center">
                        <DocumentRowIcon />
                      </div>
                      <div className="w-64 text-center">
                        {dt.toLocaleDateString('en-US') + ' ' + dt.toLocaleTimeString('en-US')}
                      </div>
                      <div className="w-128 text-right">{file.fileName}</div>
                    </div>
                  )
                })}
              </div>
              {doAction ? (
                <UploadDocument
                  accept={
                    'image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  }
                  maxFiles={10}
                  buttons={[
                    {
                      color: 'brown',
                      label: 'order.card.uploadOrderFile',
                      action: ACTION_ORDER_FILE_UPLOAD,
                      params: {},
                    },
                  ]}
                  uploadDocumentFunction={(file) => uploadOrderFile(order.id, file)}
                  performOrderAction={doAction}
                />
              ) : null}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

ViewOrderFiles.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func,
}

export default memo(ViewOrderFiles)
