import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = {}

export default handleActions(
  {
    [actions.loadCatalogFrameInfoStart]: (state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadCatalogFrameInfoSuccess]: (state, { payload: catalogInfo }) => {
      return catalogInfo
    },
  },
  defaultState,
)
