import MessageBox from 'root/components/MessageBox'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'

const ReadonlyNotice = ({ order }) => (
  <MessageBox>
    {i18n.value('order.labels.readonlyNotice')}
    {order?.responsibleId
      ? ` ${order.responsibleId.name} (${order.responsibleId.email}, ${order.responsibleId.phone})`
      : null}
  </MessageBox>
)
ReadonlyNotice.propTypes = {
  order: PropTypes.object.isRequired,
}
export default memo(ReadonlyNotice)
