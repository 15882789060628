import React, { memo, useEffect } from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import connect from './connect'
import Spinner from 'root/components/Spinner'
import { ensureBoolean, errorAlert } from 'root/constants'
import FormikToggle from 'root/components/FormikToggle'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import BlueButton from 'root/components/Buttons/BlueButton'
import { useHistory } from 'react-router-dom'
import MessageBox from 'root/components/MessageBox'
import BrownButton from 'root/components/Buttons/BrownButton'
import { requestStripeConnectLink } from 'root/api'
import { infoAlert } from 'root/components/AlertWrapper'
import * as Yup from 'yup'
import RequiredLabel from 'root/components/RequiredLabel'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import EyeIcon from 'root/icons/EyeIcon'
import DiamondIcon from 'root/icons/DiamondIcon'
import ClockIcon from 'root/icons/ClockIcon'
import PaidIcon from 'root/icons/PaidIcon'
import PaymentIcon from 'root/icons/PaymentIcon'

const StripeSettings = ({ isAdmin, canSupervise, data, loadCompanyCommonInfo, saveCompanyProfileThunk, isLoading }) => {
  const history = useHistory()

  useEffect(() => {
    loadCompanyCommonInfo()
  }, [loadCompanyCommonInfo])

  if (isLoading || !data.id) return <Spinner />

  const initialValues = {
    stripeEnabled: ensureBoolean(data.stripeEnabled),
    stripeAllowed: ensureBoolean(data.stripeAllowed),
    stripeFee: Number.parseFloat(data.stripeFee),
    stripeFeeFromBuyer: ensureBoolean(data.stripeFeeFromBuyer),
  }

  if (!data.stripeAllowed && !isAdmin) {
    return <MessageBox>{i18n.value('profile.company.labels.requestStripeAccess')}</MessageBox>
  }

  const requestLink = () => {
    requestStripeConnectLink()
      .then(async (answer) => {
        const { url, message, reload } = answer.data.data
        if (message) {
          await infoAlert(message)
        }
        if (url) {
          window.location.href = url
        }
        if (reload) {
          window.location.reload()
        }
      })
      .catch(errorAlert)
  }

  return (
    <div className="container mb:p-4 mb:mb-10 sm:w-screen-lg-min">
      {!canSupervise ? (
        <div>{i18n.value('no_access')}</div>
      ) : (
        <>
          <OrderHeading label={i18n.value('profile.company.labels.stripeStatusTitle')} />

          <MessageBox
            icon={data.canAcceptStripe ? <DiamondIcon /> : <ExclaimIcon width={32} height={32} />}
            className="mt-8"
          >
            {i18n.value(
              data.canAcceptStripe
                ? 'profile.company.labels.canAcceptStripeYes'
                : 'profile.company.labels.canAcceptStripeNo',
            )}
          </MessageBox>

          <MessageBox
            icon={data.stripeOnboarded ? <DiamondIcon /> : <ExclaimIcon width={32} height={32} />}
            className="mt-4 mb-16"
          >
            {data.stripeOnboarded ? (
              i18n.value('profile.company.labels.stripeOnboarded')
            ) : (
              <div>
                <div className="mb-4">{i18n.value('profile.company.labels.stripeOnboardedNo')}</div>
                <BrownButton onClick={requestLink} type="button">
                  {i18n.value('profile.company.labels.connectStripe')}
                </BrownButton>
                {data.stripeId ? (
                  <MessageBox className="mt-4" icon={<ClockIcon width={32} height={32} />}>
                    <pre>{i18n.value('profile.company.labels.connectStripeStarted')}</pre>
                  </MessageBox>
                ) : null}
              </div>
            )}
          </MessageBox>

          <OrderHeading label={i18n.value('profile.company.labels.stripeSettingsTitle')} />

          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              stripeFee: Yup.number().min(0).max(300),
            })}
            onSubmit={(values) => {
              return saveCompanyProfileThunk(values)
            }}
          >
            {({ values, isSubmitting, setFieldValue, handleSubmit, resetForm, setSubmitting }) => (
              <Form onSubmit={handleSubmit} className="mt-8 flex flex-col space-y-4 w-1/2">
                {isAdmin ? (
                  <label className="text-gray-500 flex space-x-4">
                    <span>{i18n.value('profile.company.labels.stripeAllowed')}</span>
                    <FormikToggle
                      disabled={isSubmitting}
                      itemKey="stripeAllowed"
                      labelOn="common.yes"
                      labelOff="common.no"
                    />
                  </label>
                ) : null}
                <div className="flex">
                  <RequiredLabel label={i18n.value('profile.company.labels.stripeFee')} isRequired={false} />
                  <InputFormik
                    isEditable={!isSubmitting && isAdmin}
                    itemKey="stripeFee"
                    value={values.stripeFee}
                    inputClass="w-16"
                  />
                </div>
                <label className="text-gray-500 flex space-x-4">
                  <span>{i18n.value('profile.company.labels.stripeEnabled')}</span>
                  <FormikToggle
                    disabled={isSubmitting}
                    itemKey="stripeEnabled"
                    labelOn="common.yes"
                    labelOff="common.no"
                  />
                </label>
                <MessageBox icon={<PaymentIcon width={20} height={20} />}>
                  <label className="text-gray-500 flex space-x-4">
                    <span>{i18n.value('profile.company.labels.stripeFeeFromBuyer')}</span>
                    <FormikToggle
                      disabled={isSubmitting}
                      itemKey="stripeFeeFromBuyer"
                      labelOn="common.yes"
                      labelOff="common.no"
                    />
                  </label>
                  {values.stripeFeeFromBuyer ? (
                    <div className="text-sm pt-4">
                      <div>
                        Note, we will increase payment sum for your customer. <br />
                        According to{' '}
                        <a
                          className="underline cursor-pointer text-brown-dim font-bold"
                          target="_blank"
                          href="https://stripe.com/pricing"
                          rel="noreferrer"
                        >
                          Stripe Pricing
                        </a>{' '}
                        depending on customer payment options Stripe will charge its fee from every transaction. So in
                        order for you to get full order sum we will increase payment sum for your customer. <br />
                        Since Stripe Fee depends on customer&apos;s payment options we will use maximal fee of{' '}
                        <strong>30¢ + 6.3%</strong>.
                      </div>
                      <div className="pt-4">
                        <div>For example:</div>
                        <ul className="list-inside list-disc">
                          <li>Order payment is $100</li>
                          <li>You have an USA Stripe account in USD</li>
                          <li>Your customer is from India and wants to pay with his credit card in rupee</li>
                        </ul>
                        <div className="pt-4">In this scenario:</div>
                        <ul className="list-inside list-disc">
                          <li>
                            Calculated Fee will be (30¢+100$)/(1-6.3%) = <strong>$7.04</strong>
                          </li>
                          <li>
                            Your customer will pay in rupee <strong>$107.04</strong>
                          </li>
                          <li>
                            You will be charged from Stripe 30¢ fix + 2.9% + 0.5% for manual card + 1.5% for
                            international card + 1% for currency conversion
                          </li>
                          <li>
                            Stripe fee will be 30¢+107.04$*5.9% = <strong>$6.61</strong>
                          </li>
                          <li>
                            You will get <strong>$100,42</strong> on your Stripe account
                          </li>
                          <li>
                            <strong>Note!</strong> Since real Stripe fee in this scenario was less, then maximal
                            possible you got extra 42¢ on your Stripe account
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <div className="text-sm pt-4">
                      <div>
                        Note, you will be charged from Stripe, according to{' '}
                        <a
                          className="underline cursor-pointer text-brown-dim font-bold"
                          target="_blank"
                          href="https://stripe.com/pricing"
                          rel="noreferrer"
                        >
                          Stripe Pricing
                        </a>
                        , but your customer will pay full order sum without additional fees.
                      </div>
                      <div className="pt-4">
                        <div>For example:</div>
                        <ul className="list-inside list-disc">
                          <li>Order payment is $100</li>
                          <li>You have an USA Stripe account in USD</li>
                          <li>Your customer is from India and wants to pay with his credit card in rupee</li>
                        </ul>
                        <div className="pt-4">In this scenario:</div>
                        <ul className="list-inside list-disc">
                          <li>
                            Your customer will pay in rupee <strong>$100</strong>
                          </li>
                          <li>
                            You will be charged from Stripe 30¢ fix + 2.9% + 0.5% for manual card + 1.5% for
                            international card + 1% for currency conversion
                          </li>
                          <li>
                            Stripe fee will be 30¢+100$*5.9% = <strong>$6.2</strong>
                          </li>
                          <li>
                            You will get <strong>$93.8</strong> on your Stripe account
                          </li>
                          <li>
                            <strong>Note.</strong> In this example we showed expensive option, chances are you will be
                            charged only 2%-3%, depending on customers paying options.
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </MessageBox>

                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <div className="flex space-x-2">
                    <FormikSubmittingButtons
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      setFieldValue={setFieldValue}
                      buttons={[
                        {
                          color: 'brown',
                          label: 'common.save',
                          className: 'profile-button',
                        },
                      ]}
                    />
                    <BlueButton type="button" onClick={() => history.goBack()}>
                      Back
                    </BlueButton>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  )
}

StripeSettings.propTypes = {
  isAdmin: PropTypes.bool,
  canSupervise: PropTypes.bool,
  data: PropTypes.object.isRequired,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
  saveCompanyProfileThunk: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default memo(connect(StripeSettings))
