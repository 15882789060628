import React, { memo } from 'react'
import PropTypes from 'prop-types'
import LocationIcon from 'root/icons/LocationIcon'
import './styles.css'
import { Field } from 'formik'
import RemoveIcon from 'root/icons/RemoveIcon'

const Exhibition = ({ details, location, disabled, index, removeItem }) => {
  return (
    <div className="exhibition-container">
      <div>
        <LocationIcon className="inline-block" fill="#AA8A9F" />
      </div>
      <span className="inline-block">
        {disabled ? (
          details
        ) : (
          <Field
            id={'exhibitions_details_' + index}
            placeholder="Location"
            name={'exhibitions[' + index + '][details]'}
            className="block form-input text-brown-dim border-gray-300 shadow shadow-inset px-2 py-0 w-96 mt-1 mb-3"
          />
        )}
        {disabled ? (
          <span>{location}</span>
        ) : (
          <Field
            as={'textarea'}
            className="mt-4 form-textarea text-brown-dim w-full block shadow shadow-inset border-gray-300"
            rows={3}
            placeholder="Description"
            id={'exhibitions_location_' + index}
            name={'exhibitions[' + index + '][location]'}
          />
        )}
      </span>
      {!disabled && (
        <button
          type="button"
          className="flex items-start pl-2 text-brown-dim hover:text-brown-dim-light"
          onClick={() => removeItem(index)}
        >
          <RemoveIcon />
        </button>
      )}
    </div>
  )
}

Exhibition.propTypes = {
  details: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  removeItem: PropTypes.func.isRequired,
}

export default memo(Exhibition)
