import CryptoJS from 'crypto-js'

import { bus, store } from 'root/store'
import * as actions from 'root/actions'
import * as wsActions from './actions'
import { WS_URL } from 'root/api/env'
import { getDeviceId } from 'root/constants'

let ws = null
let wsHeartbeat = null
let stopReconnect = false
let sessionId, token
export const setSession = (session) => ({ sessionId, token } = session)

function disconnect() {
  if (wsHeartbeat) {
    clearInterval(wsHeartbeat)
    wsHeartbeat = null
  }
  if (ws) {
    ws.close()
    ws = null
  }
}

function reconnect() {
  if (ws === null && !stopReconnect) connectToWs()
}

export function subscribeToSocket() {
  bus.take(actions.setUser, ({ payload: { activated, verified } }) => {
    if (activated && verified) connectToWs()
  })
  bus.take(actions.userLogout, disconnect)
}

function sendToRedux(action) {
  store.dispatch(action)
}

export const getHash = (token, ...args) => CryptoJS.HmacSHA256(args.join('_'), token).toString()

function connectToWs() {
  disconnect()
  const deviceId = getDeviceId()
  let params = ''
  if (sessionId && token) {
    const hash = getHash(token, sessionId, deviceId)
    params = `&sessionId=${sessionId}&hash=${hash}`
  }
  ws = new WebSocket(`${WS_URL}?deviceId=${deviceId}${params}`)

  ws.onopen = function (evt) {
    console.debug('opened connection', evt)
    wsHeartbeat = setInterval(function () {
      if (ws) ws.send('ok')
    }, 1000 * 30)
    sendToRedux(wsActions.connected())
  }

  ws.onclose = function (evt) {
    console.debug('closed connection', evt)
    ws = null
    disconnect()
    sendToRedux(wsActions.disconnected({ code: evt.code, reason: evt.reason }))
    if (evt.code !== 4000 && !stopReconnect) {
      // abnormal disconnect
      setTimeout(reconnect, 60 * 1000)
    }
  }

  ws.onmessage = function (evt) {
    console.debug('Retrieved data from server: ', evt)
    const data = JSON.parse(evt.data)
    sendToRedux(wsActions.message(data))
  }

  ws.onerror = function (evt, e) {
    stopReconnect = true
    console.debug('WS error occurred: ', evt, e)
    disconnect()
  }
}
