import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import {
  STAGE_EXECUTED,
  STAGE_NEGOTIATION,
  STAGE_PAYMENT_AND_DELIVERY,
  STAGE_REQUEST,
  STAGE_CANCELED,
} from 'root/modules/orders/constants'
import DealCanceled from './components/Canceled'
import DealCompleted from './components/DealCompleted'
import DealInProgress from './components/PaymentAndDelivery'
import DealRequest from './components/DealRequest'
import InitialTerms from './components/InitialTerms'
import DealNegotiation from './components/Negotiation'
import { OrderStageIcons } from 'root/modules/orders/pages/components/OrderStageIcons'

const stages = {
  [STAGE_REQUEST]: DealRequest,
  [STAGE_NEGOTIATION]: DealNegotiation,
  [STAGE_PAYMENT_AND_DELIVERY]: DealInProgress,
  [STAGE_EXECUTED]: DealCompleted,
  [STAGE_CANCELED]: DealCanceled,
}

const payment_hints = {
  payment_delivery: 'orders.stage.payment_and_delivery.invoice_upload',
  payment_delivery_received: 'orders.stage.payment_and_delivery.payment',
  payment_delivery_invoice_canceled: 'orders.stage.payment_and_delivery.invoice_upload',
  payment_delivery_invoice_created: 'orders.stage.payment_and_delivery.invoice_confirm',
  payment_delivery_invoice_confirmed: 'orders.stage.payment_and_delivery.payment',
  payment_delivery_invoice_paid: 'orders.stage.payment_and_delivery.shipping',
  payment_delivery_shipped: 'orders.stage.payment_and_delivery.shipping',
  payment_delivery_additional_invoice: 'orders.stage.payment_and_delivery.additional_invoice_upload',
  payment_delivery_additional_invoice_canceled: 'orders.stage.payment_and_delivery.additional_invoice_upload',
  payment_delivery_additional_invoice_created: 'orders.stage.payment_and_delivery.additional_invoice_confirm',
  payment_delivery_additional_invoice_confirmed: 'orders.stage.payment_and_delivery.payment',
}

const InvoiceOrder = ({ order, asSeller, doAction, readOnly }) => {
  const Stage = stages[order.stage]
  const stageSubHeader =
    order.stage !== STAGE_PAYMENT_AND_DELIVERY
      ? i18n.value(`orders.stage.${order.stage}.subheader.${asSeller ? 'seller' : 'buyer'}`)
      : i18n.value(
          order.allShipped && order.state.key === 'payment_delivery_shipped'
            ? `orders.stage.payment_and_delivery.shipped.${asSeller ? 'seller' : 'buyer'}`
            : `${payment_hints[order.state.key]}.${asSeller ? 'seller' : 'buyer'}`,
        )
  const stateSubHeader = i18n.value(
    `orders.subheader.${order.state.key}.` + (asSeller ? 'seller' : 'buyer'),
    null,
    null,
  )
  const subheader = stateSubHeader || stageSubHeader
  return (
    <>
      <main className="container lg:w-screen-lg-min mt-10 flex flex-col space-y-12">
        <section className="flex justify-between">
          <OrderHeading label={i18n.value(`orders.stage.${order.stage}`)} sublabel={subheader} />
          <OrderStageIcons stage={order.stage} order={order} asSeller={asSeller} />
        </section>
        {Stage && <Stage help={subheader} readOnly={readOnly} order={order} asSeller={asSeller} doAction={doAction} />}
      </main>
      <InitialTerms />
    </>
  )
}

InvoiceOrder.propTypes = {
  order: PropTypes.object.isRequired,
  asSeller: PropTypes.bool.isRequired,
  doAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
}

export default memo(InvoiceOrder)
