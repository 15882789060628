import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import CardDealAgreed
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealAgreed'
import CardDealPayment
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealPayment'
import CardDealDelivery
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealDelivery'
import CardDealClosed
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealClosed'
import OrderTimer from 'root/components/OrderTimer'
import TextLabel from 'root/components/TextLabel'
import BrownButton from 'root/components/Buttons/BrownButton'
import CardDealInvoice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealInvoice'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import CardDealAdditionalInvoice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealAdditionalInvoice'
import {
  STATE_ADDITIONAL_INVOICE_CANCELED,
  STATE_ADDITIONAL_INVOICE_CONFIRMED,
  STATE_ADDITIONAL_INVOICE_CREATED,
} from 'root/modules/orders/constants'
import CardDealAdditionalPayment
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealAdditionalPayment'
import BlueButton from 'root/components/Buttons/BlueButton'
import { ensureBoolean, errorAlert } from 'root/constants'
import i18n from 'root/i18n'
import PaymentIcon from 'root/icons/PaymentIcon'
import { payOrder } from 'root/api'
import Date
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'
import StripePaymentModal
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/PaymentAndDelivery/components/StripePaymentModal'
import StripePaymentStatus
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/PaymentAndDelivery/components/StripePaymentStatus'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'root/api/env'
import { Elements } from '@stripe/react-stripe-js'
import { useEffectOnce } from 'root/hooks'

let stripePromise = undefined
const DealInProgressBuyer = ({ order, doAction, help }) => {
  const canStripe = ensureBoolean(order.sellerCompanyId.canAcceptStripe)
  const stripeId = order.sellerCompanyId.stripeId
  useEffect(() => {
    if (!stripePromise && stripeId && canStripe) {
      stripePromise = loadStripe(STRIPE_KEY, {
        stripeAccount: stripeId,
      })
    }
  }, [canStripe, stripeId])
  const [showStripeModal, setShowStripeModal] = useState(false)
  const [stripeSecret, setStripeSecret] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  const [clientSecretFirst, setClientSecretFirst] = useState(null)
  const [sum, setSum] = useState({})
  useEffectOnce(() => {
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret')
    setClientSecretFirst(
      ensureBoolean(new URLSearchParams(window.location.search).get('first')))
    if (clientSecret && canStripe) {
      setClientSecret(clientSecret)
    }
  })

  const additionalInvoiceIssued = [
    STATE_ADDITIONAL_INVOICE_CONFIRMED,
    STATE_ADDITIONAL_INVOICE_CREATED,
    STATE_ADDITIONAL_INVOICE_CANCELED,
  ].includes(order.state.key)
  const closeStripeModal = useCallback(() => {
    setShowStripeModal(false)
  }, [setShowStripeModal])
  const payWithStripe = useCallback(() => {
    setClientSecret(undefined)
    if (stripeSecret) {
      setShowStripeModal(true)
      return
    }
    setStripeSecret(undefined)
    setSum({})
    setShowStripeModal(true)
    payOrder(order.id).then((answer) => {
      const result = answer.data.data
      if (result.reload) {
        window.location.reload()
        return
      }
      setStripeSecret(result.secret)
      if (result.sum) {
        setSum(result.sum)
      }
    }).catch((...args) => {
      setStripeSecret(undefined)
      setShowStripeModal(false)
      return errorAlert(...args)
    })
  }, [order.id, stripeSecret])
  const [successFromUrl, setSuccessFromUrl] = useState(false)
  const paymentStatusSucceeded = () => {
    setSuccessFromUrl(true)
  }
  const paymentStatusFailed = () => {}

  const renderPaymentCard = ({
    invoiceIssued,
    paidWithStripe,
    additionalInvoice,
    first,
    ...other
  }) => {
    return (
      <div>
        <TextLabel
          label={!invoiceIssued || paidWithStripe
            ? 'order.card.waitingForSeller'
            : 'order.card.waitingPayment'}
        />
        <OrderTimer timer={order.timer}/>
        {!additionalInvoice ? (
          <>
            {canStripe && stripePromise && (stripeSecret || clientSecret) ? (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: stripeSecret || clientSecret,
                  appearance: {
                    theme: 'flat',
                    variables: {
                      colorPrimary: '#80506F',
                      fontFamily: 'Inter, Ideal Sans, system-ui, sans-serif',
                    },
                  },
                }}
              >
                <StripePaymentModal
                  sum={sum}
                  secret={stripeSecret}
                  onClose={closeStripeModal}
                  visible={showStripeModal}
                  first={first}
                />
                {(clientSecret && clientSecretFirst && first) ||
                (clientSecret && !clientSecretFirst && !first) ? (
                  <StripePaymentStatus
                    secret={clientSecret}
                    onSuccess={paymentStatusSucceeded}
                    onError={paymentStatusFailed}
                  />
                ) : null}
              </Elements>
            ) : null}
            {paidWithStripe ? (
              <div
                className="border border-brown-dim p-2 mt-2 flex text-brown-dim items-center">
                <PaymentIcon/>
                <div className="flex flex-col items-center flex-grow">
                  <TextLabel label={'order.card.paidWithStripe'}/>
                  <Date value={paidWithStripe}/>
                </div>
              </div>
            ) : canStripe && !successFromUrl ? (
              <BlueButton onClick={payWithStripe} className="mt-2"
                          type="button">
                <div className="flex space-x-2">
                  <PaymentIcon/>
                  <span>{i18n.value('order.labels.payWithStripe')}</span>
                </div>
              </BlueButton>
            ) : null}
          </>
        ) : null}
      </div>
    )
  }
  const renderDeliveryCard = useCallback(({ allDelivered, showDeliveries }) => {
    return !allDelivered && <BrownButton onClick={showDeliveries} type="button"
                                         label="order.card.deliveryStatus"/>
  }, [])

  return (
    <>
      <section>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          seller={order.sellerCompanyId.name}
          asSeller={false}
        >
          <ViewOrderFiles order={order} doAction={doAction}/>
        </GeneralTerms>
      </section>
      <div className="flex flex-col items-center justify-center">
        <CardDealAgreed help={help} order={order}/>
        <CardDealInvoice help={help} order={order} doAction={doAction}/>
        {order.paymentBeforeDelivery > 0 && (
          <CardDealPayment help={help} order={order} beforeDelivery>
            {renderPaymentCard}
          </CardDealPayment>
        )}
        <CardDealDelivery help={help} doAction={doAction} order={order}>
          {renderDeliveryCard}
        </CardDealDelivery>
        {order.paymentAfterDelivery > 0 && (
          <CardDealPayment help={help} order={order} beforeDelivery={false}>
            {renderPaymentCard}
          </CardDealPayment>
        )}
        {order.hasAdditionalInvoice ? (
          <CardDealAdditionalInvoice help={help} order={order}
                                     doAction={doAction}/>
        ) : null}
        {additionalInvoiceIssued ? (
          <CardDealAdditionalPayment help={help} order={order}>
            {renderPaymentCard}
          </CardDealAdditionalPayment>
        ) : null}
        <CardDealClosed help={help} order={order}/>
      </div>
    </>
  )
}

DealInProgressBuyer.propTypes = {
  order: PropTypes.object.isRequired,
  help: PropTypes.string,
  doAction: PropTypes.func.isRequired,
}

export default memo(DealInProgressBuyer)
