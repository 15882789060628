import OrderSpecialTermsFormik from 'root/modules/orders/pages/components/OrderSpecialTerms/formik'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const SpecialTermsSection = ({ isEditable, values, isOpened = true }) => (
  <ToggleableSection labelTop="orders.labels.specialTerms" isOpened={isOpened}>
    {isEditable ? (
      <OrderSpecialTermsFormik itemKey="specialTerms" isEditable value={values.specialTerms} />
    ) : (
      <div className="flex">
        {values?.termsChanges?.specialTerms && (
          <i className="mr-1 text-brown-dim">
            <ExclaimIcon width={15} height={15} />
          </i>
        )}
        <pre className="text-gray-500 flex-grow break-all whitespace-pre-wrap">{values.specialTerms}</pre>
      </div>
    )}
  </ToggleableSection>
)

SpecialTermsSection.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isOpened: PropTypes.bool,
}

export default memo(SpecialTermsSection)
