import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  ACTION_EXISTING_MEMO_REQUEST,
  ACTION_FINAL_OFFER,
  ACTION_NEGOTIATION_UPDATE,
  ACTION_NEW_MEMO_REQUEST,
  ACTION_REJECT,
  STATE_NEGOTIATION_FINAL,
} from 'root/modules/orders/constants'
import UpdateTermsForm
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/UpdateTermsForm'
import { ErrorMessage, Form } from 'formik'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import Diamonds, {
  CertificateColumn,
  DeliveryColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  RemoveColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import PaymentTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/PaymentTermsSection'
import DeliveryPrice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/DeliveryPrice'
import AddressSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/AddressSection'
import ReturnTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/ReturnTermsSection'
import SpecialTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/SpecialTermsSection'
import BrownButton from 'root/components/Buttons/BrownButton'
import { useAsyncLoad } from 'root/hooks'
import i18n from 'root/i18n'
import BlueButton from 'root/components/Buttons/BlueButton'
import InitialTermsButton from '../components/InitialTermsButton'
import connect from './connect'
import OrderTimer from 'root/components/OrderTimer'
import Button from 'root/components/Buttons/Button'
import SelectMemoButton from './components/SelectMemoButton'
import AddProductButton
  from 'root/modules/orders/pages/Order/components/components/AddProductButton'
import AverageR
  from 'root/modules/orders/pages/Order/components/components/AverageR'
import RecalculateTotal from './components/RecalculateTotal'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import {
  confirmAlert,
  infoAlert,
  promptAlert,
} from 'root/components/AlertWrapper'
import {
  doNothing,
  errorAlert,
  formatNumberToPrice,
  getPriceFormatter,
  toClassName,
} from 'root/constants'
import { downloadKycFile, postOrderMessage } from 'root/api'
import { SITE_URL } from 'root/api/env'
import MessageBox from 'root/components/MessageBox'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const NegotiationSeller = ({
  order,
  doAction,
  showInitialTerms,
  readOnly = false,
  openChat,
}) => {
  const finalTermsAreMade = order.state.key === STATE_NEGOTIATION_FINAL
  const canConfirm = !readOnly &&
    order.actions.some((action) => action.url === ACTION_NEGOTIATION_UPDATE)
  const canMakeFinalOffer = !readOnly &&
    order.actions.some((action) => action.url === ACTION_FINAL_OFFER)
  const [loading, submitAction] = useAsyncLoad(doAction)
  const makeFinalOffer = useCallback(
    (e) => {
      e.stopPropagation()
      return confirmAlert(i18n.value('order.labels.confirmInitialTerms')).
        then(() => {
          submitAction(ACTION_FINAL_OFFER)
        }).
        catch(doNothing)
    },
    [submitAction],
  )
  const canReject = !readOnly &&
    order.actions.some((action) => action.url === ACTION_REJECT)
  const rejectOrder = useCallback(async () => {
    try {
      const reason = await promptAlert(
        i18n.value('order.labels.provideReasonForCancel'))
      if (reason) {
        submitAction(ACTION_REJECT, { reason })
      }
    } catch (e) {
      doNothing()
    }
  }, [submitAction])
  const canRequestMemo = !readOnly &&
    order.actions.some((action) => action.url === ACTION_NEW_MEMO_REQUEST)
  const hasMemo = order.actions.some(
    (action) => action.url === ACTION_EXISTING_MEMO_REQUEST)
  const requestMemo = useCallback(
    (e) => {
      e.stopPropagation()
      return confirmAlert(i18n.value('order.labels.areYouSureToStartNewMemo')).
        then(() => {
          doAction(ACTION_NEW_MEMO_REQUEST)
        }).
        catch(doNothing)
    },
    [doAction],
  )
  const requestKyc = useCallback(() => {
    return postOrderMessage(order.id, i18n.value('orders.requestKycMessage')).
      then(() => {
        openChat()
      })
  }, [openChat, order.id])
  const downloadKyc = useCallback(() => {
    downloadKycFile(order.id).catch(errorAlert)
  }, [order.id])

  return (
    <UpdateTermsForm order={order} doAction={doAction}
                     actionName={ACTION_NEGOTIATION_UPDATE}>
      {({
        values,
        isSubmitting,
        handleSubmit,
        setFieldValue,
        errors,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit} className="flex flex-col space-y-12">
          <RecalculateTotal/>
          <section>
            <OrderSubHeader label="order.labels.generalTerms"/>
          </section>

          <section>
            <GeneralTerms
              order={order}
              number={order.number}
              date={order.date}
              editable={canConfirm}
              validFor={values.dealValidDays}
              buyer={order.buyerCompanyId.name}
              asSeller
            >
              <ViewOrderFiles order={order} doAction={doAction}/>
            </GeneralTerms>
          </section>

          <section>
            <div className="flex">
              <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
              {canRequestMemo && <div className="ml-auto flex space-x-2 pb-4">
                <Button
                  type="button"
                  onClick={requestMemo}
                  className="bg-brown-dim-light"
                  disabled={loading || isSubmitting}
                  label="orders.buttons.newMemo"
                />
                {hasMemo ? <SelectMemoButton doAction={doAction}
                                             order={order}/> : null}
              </div>}
            </div>
            <Diamonds
              isEditable={canConfirm}
              highlightDeleted
              showDeleted={false}
              items={values.orderItems}
              doAction={doAction}
            >
              <IndexColumn/>
              <ShapeColumn/>
              <NameColumn/>
              <CertificateColumn/>
              <RefPriceColumn/>
              <RapaportColumn/>
              <PriceColumn/>
              <DeliveryColumn/>
              {canConfirm && <RemoveColumn/>}
            </Diamonds>
            <div className="flex mt-4">
              <BrownButton
                type="button"
                onClick={() => {
                  return postOrderMessage(
                    order.id,
                    i18n.value('order.view_my_stock') +
                    '\n\n' +
                    `${SITE_URL}/catalog?s=${order.sellerCompanyId.catalogId}`,
                  ).then(() => {
                    infoAlert(i18n.value('order.view_my_stock_success'))
                  })
                }}
                slim
                className="bg-brown-dim-light mr-2"
              >
                {i18n.value('send_stones_from_supplier')}
              </BrownButton>
              <div className="flex flex-grow">
                <div className="ml-48 w-64">
                  <AverageR items={values.orderItems}
                            setFieldValue={setFieldValue}
                            isEditable={canConfirm}/>
                </div>
                <div className="ml-3 pl-6 mr-24">
                  <DeliveryPrice isEditable={canConfirm} order={order}/>
                </div>
                {canConfirm && (
                  <div className="ml-auto">
                    <AddProductButton doAction={doAction}/>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section>
            <OrderSubHeader label="order.labels.additionalItems"
                            className="mb-4"/>
            <Diamonds isEditable={canConfirm} showDeleted={false}
                      items={values.additionalGoods}>
              <IndexColumn/>
              <NameColumn grow label="order.diamonds.goodsName"
                          itemKey="additionalGoods" isEditable={canConfirm}/>
              <PriceColumn itemKey="additionalGoods"/>
              {canConfirm &&
                <RemoveColumn allowDeleteAll itemKey="additionalGoods"
                              recalculateAverageR={false}/>}
            </Diamonds>

            <div className="flex mt-4">
              <div className="flex flex-grow">
                <div className={toClassName(
                  ['', canConfirm ? 'ml-176' : 'ml-102 w-64'])}/>
                <div className="ml-3 pl-6 mr-24">
                  <div className="flex">
                    {values.subtotalAdditional > 0 ? (
                      <>
                        <div className="text-right">
                          <div className={toClassName(
                            ['space-x-2', 'text-brown-dim'])}>
                            <span>{i18n.value('order.labels.subTotal')}</span>
                          </div>
                          <div className={toClassName(
                            ['space-x-2 font-bold', 'text-brown-dim'])}>
                            <span>{i18n.value('order.labels.total')}</span>
                          </div>
                        </div>
                        <div className="ml-10">
                          <div className={toClassName(
                            ['flex space-x-2', 'text-brown-dim'])}>
                            <span className="text-brown-dim">
                              {formatNumberToPrice(values.subtotalAdditional,
                                getPriceFormatter('usd'))}
                            </span>
                          </div>

                          <div className={toClassName(
                            ['flex space-x-2 font-bold', 'text-brown-dim'])}>
                            <span className="text-brown-dim">
                              {formatNumberToPrice(values.total,
                                getPriceFormatter('usd'))}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {canConfirm && (
                  <div className="ml-auto">
                    <BrownButton
                      type="button"
                      className="bg-brown-dim-light"
                      label="orders.buttons.addProduct"
                      onClick={() => {
                        const id = values.additionalGoods.length
                        setFieldValue(`additionalGoods[${id}]`,
                          { id, name: '', price: '' })
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>

          <section>
            <PaymentTermsSection isEditable={canConfirm} values={values}/>
          </section>

          <section>
            <ReturnTermsSection isEditable={canConfirm} values={values}/>
          </section>

          {(values.specialTerms || canConfirm) && (
            <section>
              <SpecialTermsSection isEditable={canConfirm} values={values}/>
            </section>
          )}

          <section>
            <AddressSection isOpened={false} withoutNames
                            isEditable={canConfirm} values={values}/>
          </section>

          {finalTermsAreMade && (
            <section>
              <MessageBox icon={<ExclaimIcon width={50} height={50}/>}>
                <OrderSubHeader label="order.labels.dealRequestStatus"/>
                <div className="text-gray-500">{i18n.value(
                  'orders.labels.waitForCustomerToConfirmFinalTerms')}</div>
                {order.timer.isStarted && (
                  <div className="mt-2">
                    <OrderTimer timer={order.timer}
                                label={'orders.labels.replyTimerCustomer'}/>
                  </div>
                )}
              </MessageBox>
            </section>
          )}
          {Object.keys(errors).length === 0 ? (
            ''
          ) : (
            <section className="my-4 bg-red-300 text-red-600">
              {Object.keys(errors).map((key) => (
                <div className="flex space-x-4" key={key}>
                  <div>{key}:</div>
                  {Array.isArray(errors[key]) && typeof errors[key][0] ===
                  'string' ? (
                    <ErrorMessage name={key} component="div"/>
                  ) : (
                    ' Error'
                  )}
                </div>
              ))}
            </section>
          )}
          <section className="flex">
            {canConfirm ? (
              <BrownButton
                className="mr-4"
                disabled={loading || isSubmitting || !dirty}
                label="orders.buttons.saveAndMakeOffer"
              />
            ) : null}
            {canReject && (
              <BlueButton
                disabled={loading || isSubmitting}
                onClick={rejectOrder}
                type="button"
                label="orders.buttons.cancelDeal"
              />
            )}
            {canMakeFinalOffer && (
              <div className="ml-auto flex space-x-4">
                <InitialTermsButton onClick={() => showInitialTerms(true)}/>
                <button
                  type="button"
                  onClick={makeFinalOffer}
                  className="cursor-pointer flex items-center justify-center text-blue-skyblue text-lg font-medium"
                  disabled={loading || isSubmitting}
                >
                    <span className="ml-4">{i18n.value(
                      'orders.buttons.startWithInitialTerms')}</span>
                </button>
              </div>
            )}
          </section>
        </Form>
      )}
    </UpdateTermsForm>
  )
}

NegotiationSeller.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  showInitialTerms: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  openChat: PropTypes.func.isRequired,
}

export default memo(connect(NegotiationSeller))
