import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'
import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DealPaymentIcon from 'root/icons/DealPaymentIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import i18n from 'root/i18n'
import { getDocument, getDocumentFile, uploadOrderDocument } from 'root/api'
import {
  ACTION_INVOICE_CREATED,
  DOCUMENT_TYPE_INVOICE,
  STATE_INVOICE_CANCELED,
  STATE_INVOICE_CONFIRMED,
  STATE_INVOICE_CREATED,
  STATE_INVOICE_PAYMENT_AND_DELIVERY,
} from 'root/modules/orders/constants'
import OrderTimer from 'root/components/OrderTimer'
import DownloadIcon from 'root/icons/DownloadIcon'
import UploadDocument from 'root/modules/orders/pages/components/UploadDocument'
import TextLabel from 'root/components/TextLabel'

const CardDealCreateInvoice = ({ order, help, doAction }) => {
  const invoice = order.documents.find((document) => document.type === DOCUMENT_TYPE_INVOICE)
  const invoiceFile = invoice?.files[0]
  const isCurrent = [STATE_INVOICE_CANCELED, STATE_INVOICE_PAYMENT_AND_DELIVERY, STATE_INVOICE_CREATED].includes(
    order.state.key,
  )
  const canUpload = order.actions.some((action) => action.url === ACTION_INVOICE_CREATED)
  const isConfirmed = order.state.key === STATE_INVOICE_CONFIRMED
  const isCancelled = order.state.key === STATE_INVOICE_CANCELED
  const invoiceIssuedDate = invoiceFile?.created
  const invoiceIssued = invoiceIssuedDate > 0
  const statusLabel = isConfirmed
    ? 'order.card.invoiceConfirmed'
    : isCancelled
    ? 'order.card.invoiceCanceled'
    : 'order.card.waitingForBuyer'
  return (
    <Card help={help} label="order.card.dealInvoice" Icon={DealPaymentIcon} current={isCurrent} completed={!isCurrent}>
      {invoiceIssued && (
        <CardRow label="order.card.invoiceIssued">
          <div>
            <Date value={invoiceIssuedDate} />
          </div>
        </CardRow>
      )}
      {order.state.key !== STATE_INVOICE_PAYMENT_AND_DELIVERY && isCurrent && (
        <CardRow label="order.card.invoiceStatus">
          <div>
            <TextLabel label={statusLabel} />
            {order.state.key === STATE_INVOICE_CREATED && <OrderTimer timer={order.timer} />}
          </div>
        </CardRow>
      )}
      {canUpload && (
        <>
          <div className="flex flex-col space-y-4">
            <OrderTimer label="orders.labels.replyTimer" timer={order.timer} />
            <div className="flex">
              <button
                onClick={() => {
                  getDocument(invoice.id)
                }}
                type="button"
                className="flex space-x-2 items-center font-bold text-blue-skyblue"
              >
                <DownloadIcon width={20} height={20} />
                <span>{i18n.value('order.card.download')}</span>
              </button>
              <button
                onClick={() => {
                  getDocument(invoice.id, 'word')
                }}
                type="button"
                className="flex space-x-2 items-center text-gray-500"
              >
                <span>&nbsp;/&nbsp;word</span>
              </button>
            </div>
          </div>
          <div className="w-full">
            <div className="text-sm text-brown-dim-light mt-4">
              Please, make sure the invoice is checked and signed:
            </div>
            <UploadDocument
              accept={'image/*, application/pdf'}
              buttons={[
                {
                  color: 'brown',
                  label: 'order.card.upload',
                  action: ACTION_INVOICE_CREATED,
                  params: {
                    documentId: invoice.id,
                  },
                },
              ]}
              uploadDocumentFunction={(file) => uploadOrderDocument(order.id, file)}
              performOrderAction={doAction}
            />
          </div>
        </>
      )}
      {invoiceFile && (
        <CardRow full>
          <button
            type="button"
            onClick={() => {
              getDocumentFile(invoice.id, invoiceFile.id)
            }}
            className="text-brown-dim"
          >
            {i18n.value('order.card.viewInvoice')}
          </button>
        </CardRow>
      )}
    </Card>
  )
}
CardDealCreateInvoice.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  help: PropTypes.string,
  children: PropTypes.func,
}
export default memo(CardDealCreateInvoice)
