import React, { memo } from 'react'
import Column from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds/Column'
import CheckBoxFormik from 'root/modules/login/pages/Login/components/CheckboxInput/formik'
import PropTypes from 'prop-types'

const CheckBoxColumn = ({ children, valuesKey, values, setFieldValue, ...other }) => (
  <Column
    columnClass="w-12 text-center"
    className="w-12 text-center text-brown-dim flex justify-center items-center"
    isCheckBox
    valuesKey={valuesKey}
    values={values}
    setFieldValue={setFieldValue}
    {...other}
  >
    {children ||
      (({ item }) => {
        return <CheckBoxFormik itemKey={valuesKey + '[' + item.id + ']'} isChecked={values[valuesKey][item.id]} />
      })}
  </Column>
)
CheckBoxColumn.propTypes = {
  ...Column.propTypes,
  values: PropTypes.object.isRequired,
  valuesKey: PropTypes.string.isRequired,
}
export default memo(CheckBoxColumn)
