import { COLORS } from 'root/constants'

export default {
  scrollDown: {
    cursor: 'pointer',
    transition: '.2s opacity ease',
    position: 'absolute',
    right: 20,
    bottom: 20,
    width: 40,
    height: 40,
    borderRadius: 100,
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.MOUSE,
    borderWidth: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counter: {
    minWidth: 20,
    height: 20,
    position: 'absolute',
    padding: '0 4px',
    top: -8,
    backgroundColor: COLORS.RED500,
    border: '2px solid white',
  },
  countStyle: {
    fontSize: 12,
  },
  scrollDownIcon: {
    position: 'relative',
    top: 2,
  },
}
