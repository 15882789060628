import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="#80506F" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 5.83333C11.6667 7.07128 11.281 8.21917 10.6234 9.16341H10.625L6.53591 15.5665C6.20819 16.0797 5.45866 16.0796 5.1311 15.5663L1.06146 9.18933C0.392729 8.24021 0 7.08263 0 5.83333C0 2.61167 2.61167 0 5.83333 0C9.055 0 11.6667 2.61167 11.6667 5.83333ZM5.83333 9.16667C7.67428 9.16667 9.16667 7.67428 9.16667 5.83333C9.16667 3.99238 7.67428 2.5 5.83333 2.5C3.99238 2.5 2.5 3.99238 2.5 5.83333C2.5 7.67428 3.99238 9.16667 5.83333 9.16667Z"
    />
  </svg>
)

export default memo(SvgComponent)
