import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import { CloseIcon, BackIcon } from 'root/icons'
import i18n from 'root/i18n'

const ChatHeader = ({ text, name, onClose, goBack }) => (
  <div className="chat-header">
    <div>
      {goBack && <BackIcon style={{ marginRight: 10, marginTop: 5 }} onClick={goBack} className="cursor-pointer" />}
      <span>
        {text || (
          <Fragment>
            {i18n.value('chat.with')}
            <span>{name}</span>
          </Fragment>
        )}
      </span>
    </div>
    <CloseIcon style={{ marginTop: 5 }} onClick={onClose} className="cursor-pointer" width={16} height={16} />
  </div>
)

ChatHeader.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  goBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

export default ChatHeader
