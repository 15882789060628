import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="19"
    height="23"
    viewBox="0 0 19 23"
    fill="none"
    className="stroke-current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.625 1H3.125C2.56141 1 2.02091 1.22125 1.6224 1.61508C1.22388 2.0089 1 2.54305 1 3.1V19.9C1 20.457 1.22388 20.9911 1.6224 21.3849C2.02091 21.7787 2.56141 22 3.125 22H15.875C16.4386 22 16.9791 21.7787 17.3776 21.3849C17.7761 20.9911 18 20.457 18 19.9V7.3L11.625 1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.625 1V7.3H18" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.75 12.5508H5.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.75 16.75H5.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.375 8.34961H6.3125H5.25" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default memo(SvgComponent)
