import React, { useCallback } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isActivated, isAuthorized } from 'root/selectors'
import { childrenPropType } from 'root/constants'
import Activation from 'root/modules/activation/pages/Activation'
import { Login, SignUp } from 'root/modules/login'

const PrivateRoute = ({ children, component, isAuthorized, isActivated, path, ...props }) => {
  const render = useCallback(
    ({ location }) => (isAuthorized ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />),
    [children, isAuthorized],
  )
  const routeComponent = isActivated ? component : Activation
  const loginComponent = path === '/signup' ? SignUp : Login
  return (
    <Route
      {...props}
      component={isAuthorized ? routeComponent : loginComponent}
      render={isAuthorized && routeComponent ? undefined : render}
    />
  )
}

PrivateRoute.propTypes = {
  children: childrenPropType,
  component: PropTypes.elementType,
  isAuthorized: PropTypes.bool.isRequired,
  isActivated: PropTypes.bool.isRequired,
  path: PropTypes.string,
}

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorized(state),
  isActivated: isActivated(state),
})

export default connect(mapStateToProps)(PrivateRoute)
