import React, { memo, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import BrownButton from 'root/components/Buttons/BrownButton'
import MessageBox from 'root/components/MessageBox'
import PropTypes from 'prop-types'

const StripeCheckout = ({ first }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + window.location.pathname + `?first=${first ? 1 : 0}`,
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="mt-4">
        <BrownButton disabled={!stripe}>Submit</BrownButton>
      </div>
      {errorMessage && <MessageBox className="mt-4">{errorMessage}</MessageBox>}
    </form>
  )
}

StripeCheckout.propTypes = {
  first: PropTypes.bool.isRequired,
}

export default memo(StripeCheckout)
