import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { debounce, flatMap } from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'

import i18n from 'root/i18n'
import { DiamondShapeSprite } from 'root/icons'
import { doNothing } from 'root/constants'

import { confirmAlert } from 'root/components/AlertWrapper'

import connect from './connect'
import ProductFilters from './components/ProductFilters'
import ProductsListTable from './components/ProductsListTable'
import CatalogTabs from './components/CatalogTabs'
import CatalogSellerButtons from './components/CatalogSellerButtons'
import SearchBar from './components/SearchBar'
import { getFilterValues } from 'root/modules/products/constants'
import { useEffectOnce } from 'root/hooks'

const getNextSortOrder = (sortOrder = 0) => (sortOrder + 1) % 3
const serializeParams = (params = {}) => {
  return `?${flatMap(Object.keys(params), (key) => {
    if (Array.isArray(params[key])) return params[key].map((item) => `${key}[]=${item}`)
    return `${key}=${params[key]}`
  }).join('&')}`
}
const parseParams = (search) => {
  const params = {}
  if (search.length > 1)
    search
      .slice(1)
      .split('&')
      .forEach((part) => {
        let [key, value] = part.split('=')
        const parsed = parseFloat(value)
        value = parsed.toString() === value ? parsed : value
        if (key.endsWith('[]')) {
          key = key.slice(0, -2)
          if (!params[key]) params[key] = []
          params[key].push(value)
        } else params[key] = value
      })
  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) params[key].sort()
  })
  return params
}

const ProductsList = ({
  data,
  isProductsListLoading,
  loadProducts,
  updateFilters,
  performProductsAction,
  resetFilters,
  isCatalog,
  company,
  loadCompanyCommonInfo,
}) => {
  const loadData = useMemo(() => debounce(loadProducts, 300, { leading: true }), [loadProducts])
  const { items: products, filters, page, total } = data
  const isReloading = page === 0

  const { pathname, search } = useLocation()
  const history = useHistory()
  const didUpdateFilters = useRef(false)

  useEffect(() => {
    if (didUpdateFilters.current) {
      history.replace({ pathname, search: serializeParams(getFilterValues(filters)) })
      loadData(true)
    }
  }, [loadData, filters, history, pathname])
  useEffectOnce(() => {
    updateFilters(getFilterValues(parseParams(search)))
    didUpdateFilters.current = true
  })
  useEffectOnce(() => {
    if (!company.ftp) loadCompanyCommonInfo()
  }, [loadCompanyCommonInfo, company])
  const { s: activeTab, q: query, so: sortOrder, sb: sortBy } = filters
  const setQuery = useCallback((q) => updateFilters({ q }), [updateFilters])
  const selectSortBy = useCallback(
    (sb) => updateFilters({ sb, so: sb === sortBy ? getNextSortOrder(sortOrder) : 1 }),
    [sortBy, sortOrder, updateFilters],
  )

  const performProductsActionCustom = useCallback(
    (action, data) => {
      return confirmAlert('Are you sure that you want to perform this operation?')
        .then(() => performProductsAction(action, data))
        .then(updateFilters)
        .catch(doNothing)
    },
    [performProductsAction, updateFilters],
  )

  return (
    <main className="mt-10">
      <div className="flex justify-between h-max-content items-left container mx-auto lg:w-screen-lg-min">
        {isCatalog ? (
          <label htmlFor="search" className="text-brown-late text-2xl pr-20 cursor-pointer">
            {i18n.value('common.search')}:
          </label>
        ) : (
          <CatalogSellerButtons
            isProductsListLoading={isProductsListLoading}
            products={products}
            updateFilters={updateFilters}
            company={company}
          />
        )}
        <SearchBar query={query} setQuery={setQuery} />
      </div>
      <div className="h-max-content container mx-auto mt-12 mb-5">
        {!isCatalog && <CatalogTabs total={total} updateFilters={updateFilters} activeTab={activeTab} />}
        <div className="mt-2">
          <DiamondShapeSprite />
          {isCatalog && <ProductFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />}
          <ProductsListTable
            sortBy={sortBy}
            sortOrder={sortOrder}
            isCatalog={isCatalog}
            isReloading={isReloading}
            loadData={loadData}
            products={products}
            performProductsAction={performProductsActionCustom}
            selectSortBy={selectSortBy}
          />
        </div>
      </div>
    </main>
  )
}

ProductsList.propTypes = {
  data: PropTypes.object.isRequired,
  loadProducts: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  performProductsAction: PropTypes.func.isRequired,
  isCatalog: PropTypes.bool,
  isProductsListLoading: PropTypes.bool,
  company: PropTypes.object,
  loadCompanyCommonInfo: PropTypes.func,
}

export default memo(connect(ProductsList))
