import React, { memo } from 'react'
import PropTypes from 'prop-types'
import OrderSubHeader from 'root/components/OrderSubHeader'
import i18n from 'root/i18n'
import { childrenPropType } from 'root/constants'
import { useToggle } from 'root/hooks'

const ToggleableSection = ({
  labelTop,
  className,
  withoutHeader = false,
  isOpened = true,
  children,
  renderTop,
  labelInside,
  classNameLine,
}) => {
  const [opened, toggle] = useToggle(isOpened)
  labelInside = labelInside ? labelInside : labelTop
  classNameLine = classNameLine ? classNameLine : ''
  return (
    <div className={className}>
      <div
        className={'relative flex cursor-pointer text-sm text-gray-500 select-none ' + classNameLine}
        onClick={toggle}
      >
        <div>{i18n.value(labelTop)}</div>
        <div className="mt-2 ml-4 z-10">
          <svg
            className="inline"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            preserveAspectRatio="xMinYMin"
          >
            <use href={`#${opened ? 'down' : 'up'}`} />
          </svg>
        </div>
        <hr className="absolute left-0 right-0 bottom-0 mb-2" />
        {renderTop && renderTop()}
      </div>
      {opened && (
        <div>
          {!withoutHeader && <OrderSubHeader className="mt-4 mb-6" label={labelInside} />}
          {children}
        </div>
      )}
    </div>
  )
}

ToggleableSection.propTypes = {
  labelTop: PropTypes.string.isRequired,
  labelInside: PropTypes.string,
  children: childrenPropType,
  className: PropTypes.string,
  classNameLine: PropTypes.string,
  isOpened: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  renderTop: PropTypes.func,
}

export default memo(ToggleableSection)
