import * as actions from '../actions'
import { getActiveOrderData, getInitialTermsData, getOrdersData, isOrderListLoading } from '../selectors'

import {
  getInitialTerms,
  getOrder,
  getOrderNotificationsSummary,
  getOrders,
  orderAction,
  removeOrderItems,
} from 'root/api'
import { errorAlert } from 'root/constants'
import { getUserId } from 'root/selectors'

const NavigationService = { navigate: () => undefined }

export const loadOrderNotificationsSummary = () => {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      const result = await getOrderNotificationsSummary(getUserId(state))
      dispatch(actions.loadNotificationsSummarySuccess({ ...result.data.data }))
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}

export const loadOrders = (refresh, section) => async (dispatch, getState) => {
  refresh = refresh === true
  const state = getState()
  const { page: lastPage, hasNext, filters } = getOrdersData(state)

  if (refresh || (hasNext && !isOrderListLoading(state))) {
    dispatch(actions.loadOrderListStart())

    const page = refresh ? 1 : lastPage + 1
    await getOrders(section, page, undefined, filters)
      .then(({ data: { data: rows, meta } }) => {
        const hasNext = !!meta.paging.next
        dispatch(actions.loadOrderListSuccess({ rows, page, hasNext }))
      })
      .catch((e) => {
        console.error(e)
        dispatch(actions.loadOrderListFailure())
      })
  }
}

export const hideInitialTerms = () => async (dispatch) => {
  await dispatch(actions.hideInitialTerms())
}

export const showInitialTerms =
  (withoutNames = false) =>
  async (dispatch, getState) => {
    const state = getState()
    const termsAreLoaded = getInitialTermsData(state)
    if (!termsAreLoaded) {
      const { id } = getActiveOrderData(state)
      await dispatch(actions.loadInitialTermsStart())
      getInitialTerms(id)
        .then(({ data: { data } }) => dispatch(actions.loadInitialTermsSuccess(data)))
        .catch((e) => {
          console.error(e)
          dispatch(actions.loadInitialTermsFailure())
        })
    }
    return await dispatch(actions.showInitialTerms(withoutNames))
  }

export const loadOrder = (order, refresh) => async (dispatch, getState) => {
  order = order || getActiveOrderData(getState())
  const { id } = order
  dispatch(actions.loadOrderStart({ item: order, refresh }))
  await getOrder(id)
    .then(({ data: { data } }) => dispatch(actions.loadOrderSuccess(data)))
    .catch((e) => {
      console.error(e)
      dispatch(actions.loadOrderFailure())
    })
    .then(() => dispatch(loadOrderNotificationsSummary()))
}

export const goToOrder = (order, refresh) => (dispatch, getState) => {
  dispatch(loadOrder(order, refresh && order.id === getActiveOrderData(getState()).id))
  NavigationService.navigate('OrderScreen')
}

const CANCEL_ORDER_ACTIONS = new Set(['canceled', 'rejected'])

export const performOrderAction = (action, data) => async (dispatch, getState) => {
  const { id } = getActiveOrderData(getState())

  return orderAction(id, action, data)
    .then(({ data: { data } }) => {
      dispatch(actions.loadOrderSuccess(data))
      if (CANCEL_ORDER_ACTIONS.has(action)) {
        const productIds = new Set(data.products.map(({ id }) => id))
        dispatch(actions.updateReservedProducts({ productIds, inReserve: false }))
      }
    })
    .catch(errorAlert)
}

export const removeOrderProducts = (selected) => async (dispatch, getState) => {
  const { id } = getActiveOrderData(getState())
  const items = Array.from(Object.values(selected)[0])
  removeOrderItems(id, items)
    .then(({ data: { data } }) => dispatch(actions.loadOrderSuccess(data)))
    .catch(errorAlert)
}
