import React, { memo } from 'react'
import PropTypes from 'prop-types'
import OrderSubHeader from 'root/components/OrderSubHeader'

const OrderSpecialTermsRead = ({ label, value }) => {
  return (
    <>
      {label ? <OrderSubHeader label={label} /> : ''}
      <textarea
        className="mt-4 form-textarea text-brown-dim w-full block shadow shadow-inset border-gray-300"
        disabled
        rows={5}
        defaultValue={value}
      />
    </>
  )
}

OrderSpecialTermsRead.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
}

export default memo(OrderSpecialTermsRead)
