export const COLORS = {
  EGGPLANT: '#4A2E4D',
  PLUM: '#80506F',
  ORCHID: '#AA8A9F',
  THISTLE: '#F2EEF1',
  GRAPHITE: '#404040',
  ASPHALT: '#7F7F7F',
  MOUSE: '#C6C6C6',
  ASH: '#EBE8E8',
  PEARL: '#F9F8F8',
  CLOUD: '#A4C8E2',
  SEA: '#7B94BF',
  BLUESTAR: '#E9EDF4',
  ROSE: '#F5AAAA',
  CRIMSON: '#E46464',
  RED500: '#f56565',

  WHITE: '#FFFFFF',
  BLACK: '#000000',
}
