import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Login, SignUp } from 'root/modules/login'
import { Order, OrderList } from 'root/modules/orders'
import {
  ChangePasswordModal,
  CompanyAddressesModal,
  CompanyProfile,
  InviteModal,
  MyTeamModal,
} from 'root/modules/profile'

import { initApp } from 'root/thunks'
import PrivateRoute from 'root/PrivateRoute'
import Header from 'root/components/Header'
import Footer from 'root/components/Footer'
import NotFound from 'root/components/NotFound'
import { isAppInitialized } from 'root/selectors'
import { MyStock, Product } from 'root/modules/products'
import MemoAgreement from 'root/modules/memo-agreements/pages/MemoAgreement'
import MemoAgreementsList
  from 'root/modules/memo-agreements/pages/MemoAgreementsList'
import InvoiceTerms from 'root/modules/profile/pages/InvoiceTerms'
import CreditInformation from 'root/modules/profile/pages/CreditInformation'
import AlertWrapper from 'root/components/AlertWrapper'
import CompanyAddressModal
  from 'root/modules/profile/pages/CompanyAddressesModal/components/CompanyAddressModal'
import CatalogIframeSettings
  from 'root/modules/profile/pages/CatalogIframeSettings'
import GoodsModal from 'root/modules/profile/pages/GoodsModal'
import StripeSettings from 'root/modules/profile/pages/StripeSettings'
import KycModal from 'root/modules/profile/pages/KycModal'
import KycInformation from 'root/modules/profile/pages/KycInformation'

const Router = ({ initApp, isAppInitialized }) => {
  useEffect(() => {
    initApp()
  }, [initApp])

  if (!isAppInitialized) return null
  return (
    <BrowserRouter>
      <AlertWrapper/>
      <Header/>
      <div className="body-container mb:p-0">
        <div className="body-inner-container">
          <Switch>
            <PrivateRoute path={'/login'} component={Login}/>
            <PrivateRoute path={'/signup'} component={SignUp}/>
            <PrivateRoute path={'/products/:id'} component={Product}/>
            <PrivateRoute path={'/products/'} component={MyStock}/>
            <PrivateRoute path={'/:section(sales|purchases)/:id'}
                          component={Order}/>
            <PrivateRoute path={'/:section(sales|purchases)'}
                          component={OrderList}/>
            <PrivateRoute path={'/memo-agreements/:id'}
                          component={MemoAgreement}/>
            <PrivateRoute path={'/memo-agreements/'}
                          component={MemoAgreementsList}/>
            <PrivateRoute path={'/password'} component={ChangePasswordModal}/>
            <PrivateRoute path={'/company/invoice-terms'}
                          component={InvoiceTerms}/>
            <PrivateRoute path={'/company/credit-info'}
                          component={CreditInformation}/>
            <PrivateRoute path={'/company/kyc-info'}
                          component={KycInformation}/>
            <PrivateRoute path={'/company/catalog'}
                          component={CatalogIframeSettings}/>
            <PrivateRoute path={'/company/stripe'} component={StripeSettings}/>
            <Route exact path="/">
              <Redirect to="/company"/>
            </Route>
            <PrivateRoute path={'/company'}>
              <CompanyProfile/>
              <Route path={'/company/goods'} component={GoodsModal}/>
              <Route path={'/company/kyc'} component={KycModal}/>
              <Route path={'/company/team'} component={MyTeamModal}/>
              <Route path={'/company/invite'} component={InviteModal}/>
              <Route path={'/company/addresses'} exact
                     component={CompanyAddressesModal}/>
              <Route path={'/company/addresses/:id'}
                     component={CompanyAddressModal}/>
            </PrivateRoute>
            <Route path="*" component={NotFound}/>
          </Switch>
        </div>
      </div>
      <Footer/>
    </BrowserRouter>
  )
}

Router.propTypes = {
  initApp: PropTypes.func.isRequired,
  isAppInitialized: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isAppInitialized: isAppInitialized(state),
})

const mapDispatchToProps = {
  initApp,
}

export default connect(mapStateToProps, mapDispatchToProps)(Router)
