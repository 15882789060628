import { COLORS } from 'root/constants'

const WINDOW_HEIGHT = 570

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
  },
  flex: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    flex: 1,
  },
  scroll: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'scroll',
    flex: 1,
  },
  empty: {
    flex: 1,
    justifyContent: 'center',
  },
  boldGray: {
    fontWeight: 'bold',
    color: COLORS.ASPHALT,
  },
  inputRow: {
    display: 'flex',
    padding: '16px',
    alignItems: 'flex-end',
    flexDirection: 'row',

    borderColor: COLORS.ASH,
    borderTopWidth: 1,
  },
  attach: {
    cursor: 'pointer',
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  input: {
    margin: '0 12px',
    maxHeight: WINDOW_HEIGHT * 0.2,
    whiteSpace: 'pre-wrap',
    fontSize: 16,
    outline: 'none',
    lineHeight: '1.1em',
    overflowY: 'auto',
    flex: 1,
  },
  placeholder: {
    pointerEvents: 'none',
    position: 'absolute',
    fontSize: 16,
    lineHeight: '1.1em',
    left: 12,
    bottom: 0,
    color: COLORS.MOUSE,
  },
  background: {
    backgroundColor: COLORS.PEARL,
  },
}
