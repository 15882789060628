import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'

const TextLabel = ({ label, className = '' }) => (
  <span className={`text-gray-500 ${className}`}>{i18n.value(label)}</span>
)

TextLabel.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default memo(TextLabel)
