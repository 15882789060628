import * as actions from '../actions'
import { getActiveMemoAgreementData, getMemoAgreementListData, isMemoAgreementListLoading } from '../selectors'

import { getMemoAgreement, getMemoAgreements } from 'root/api'
import { ITEMS_PER_PAGE } from '../constants'

export const loadMemoAgreementList = (refresh) => async (dispatch, getState) => {
  refresh = refresh === true
  const state = getState()
  const { page: lastPage, hasNext, filters } = getMemoAgreementListData(state)

  if (refresh || (hasNext && !isMemoAgreementListLoading(state))) {
    dispatch(actions.loadMemoAgreementListStart())

    filters.ipp = ITEMS_PER_PAGE
    const page = refresh ? 1 : lastPage + 1
    await getMemoAgreements(page, undefined, filters)
      .then(({ data: { data: rows, meta } }) => {
        const hasNext = !!meta.paging.next
        if (hasNext && rows.length !== ITEMS_PER_PAGE)
          console.error('MemoAgreementList items per page mismatch:', rows.length, ITEMS_PER_PAGE)
        dispatch(actions.loadMemoAgreementListSuccess({ rows, page, hasNext }))
      })
      .catch((e) => {
        console.error(e)
        dispatch(actions.loadMemoAgreementListFailure())
      })
  }
}

export const loadMemoAgreement = (memoAgreement, refresh) => async (dispatch, getState) => {
  memoAgreement = memoAgreement || getActiveMemoAgreementData(getState())
  const { id } = memoAgreement
  dispatch(actions.loadMemoAgreementStart({ item: memoAgreement, refresh }))
  await getMemoAgreement(id)
    .then(({ data: { data } }) => dispatch(actions.loadMemoAgreementSuccess(data)))
    .catch((e) => {
      console.error(e)
      dispatch(actions.loadMemoAgreementFailure())
    })
}
