import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { timestampToDate } from 'root/constants'
import TextValue from 'root/modules/orders/pages/Order/components/components/TextValue'
import CalendarIcon from 'root/icons/CalendarIcon'

const Date = ({ value }) => {
  return value ? (
    <span className="flex items-center space-x-2">
      <CalendarIcon />
      <TextValue>{typeof value === 'string' ? value : timestampToDate(value)}</TextValue>
    </span>
  ) : null
}
Date.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default memo(Date)
