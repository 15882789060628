import React, { memo } from 'react'
import PropTypes from 'prop-types'

import i18n from 'root/i18n'
import { SearchIcon } from 'root/icons'
import { handleChange } from 'root/constants'

import BrownButton from 'root/components/Buttons/BrownButton'

const SearchBar = ({ query, setQuery }) => (
  <div className="text-left flex-1 flex items-start">
    <div className="border-brown-dim border-b w-full h-10 flex items-center text-brown-dim text-lg">
      <SearchIcon />
      <input
        id="search"
        type="text"
        className="p-1 ml-2 outline-none flex-1"
        placeholder={i18n.value('common.search') + '...'}
        value={query}
        onChange={handleChange(setQuery)}
      />
    </div>
    <BrownButton label="common.search" />
  </div>
)

SearchBar.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func.isRequired,
}

export default memo(SearchBar)
