import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormikSubmittingButtons
  from 'root/components/Buttons/FormikSubmittingButtons'
import {
  ACTION_MEMO_NEGOTIATION_FINAL_TERMS,
  ACTION_MEMO_NEGOTIATION_UPDATE_TERMS,
  calcAverageR,
  calcTotals,
  RESPONSIBLE_SELLER,
} from 'root/modules/orders/constants'
import {
  getItemsErrorFields,
  getItemsValidationSchema,
  useDiamondsTableFormValues,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import {
  getAddressValidationSchema,
} from 'root/modules/orders/pages/components/DeliveryAddress'
import MemoNegotiationBody
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/MemoNegotiationBody'
import { infoAlert } from 'root/components/AlertWrapper'
import RecalculateTotal
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/Negotiation/components/NegotiationSeller/components/RecalculateTotal'
import OrderTimer from 'root/components/OrderTimer'
import MessageBox from 'root/components/MessageBox'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import { openChat } from 'root/modules/chat/actions'
import { connect } from 'react-redux'

const FIELD_LABELS = {
  memoTermDays: i18n.value('orders.labels.memoTerm'),
  deliveryPrice: i18n.value('orders.labels.deliveryPrice'),
  specialTerms: i18n.value('orders.labels.sitSpecialTerms'),
}

const getAddressErrorFields = ({ address }) =>
  Object.keys(address).
    map((key) => <ErrorMessage key={key} name={`address.${key}`}
                               component="div"/>)

const DealNegotiationMemoForm = ({
  order,
  performOrderAction,
  buttons,
  isEditable,
  asSeller,
  openChat,
}) => {
  const orderItems = useDiamondsTableFormValues(order)
  let dealNegotiationMemoSchema = Yup.object().shape({
    memoTermDays: Yup.number().
      min(
        parseInt(order.memoId.nonReturnDays),
        FIELD_LABELS['memoTermDays'] +
        ' too short, must be more than Non-return days',
      ).
      max(999, FIELD_LABELS['memoTermDays'] + ' too long').
      required(FIELD_LABELS['memoTermDays'] + ' value is required'),
    deliveryPrice: Yup.number().
      min(0, FIELD_LABELS['deliveryPrice'] + ' too low').
      max(10000000).
      required(FIELD_LABELS['deliveryPrice'] + ' value is required'),
    specialTermsMemo: Yup.string().max(1000),
    nonReturnPeriod: Yup.number().min(0).max(255),
    returnShipmentPeriod: Yup.number().min(0).max(255),
    memoPaymentTermDays: Yup.number().min(0).max(255),
    memoPenaltyDelayPerDay: Yup.number().min(0).max(255),
    address: getAddressValidationSchema(),
    orderItems: getItemsValidationSchema(orderItems.orderItems),
    specialTerms: Yup.string().
      max(1000, FIELD_LABELS['specialTerms'] + ' must be less then 1000'),
  })

  const errorFieldsAddress = getAddressErrorFields(order)
  const errorFieldsItems = getItemsErrorFields(orderItems.orderItems)
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...order,
        ...orderItems,
        memoTermDays: order.memoTermsDays > 0 ? order.memoTermsDays : 30,
        address: order.address,
        specialTerms: order.specialTerms || '',
        deliveryPrice: order.deliveryPrice.value || 100,

        //default memo fields
        memoPaymentTermDays: order.memoId ? order.memoId.paymentTermDays : 30,
        nonReturnPeriod: order.memoId ? order.memoId.nonReturnDays : 30,
        returnShipmentPeriod: order.memoId
          ? order.memoId.returnShipmentDays
          : 30,
        memoPenaltyDelayPerDay: order.memoId
          ? order.memoId.dailyDelayedPaymentFee
          : 0.03,
        isTaxesResponsibleSeller: order.memoId
          ? order.memoId.taxesResponsible === RESPONSIBLE_SELLER
          : true,
        isBankResponsibleSeller: order.memoId
          ? order.memoId.bankCommissionResponsible === RESPONSIBLE_SELLER
          : true,
        isDeliveryResponsibleSeller: order.memoId
          ? order.memoId.deliveryPaymentsResponsible === RESPONSIBLE_SELLER
          : true,
        isReturnResponsibleSeller: order.memoId
          ? order.memoId.returnPaymentResponsible === RESPONSIBLE_SELLER
          : true,
        specialTermsMemo: order.memoId.specialTerms || '',
        action: '',
        termsChanges: order?.termsChanges,

        // calcs
        additionalGoods: [],
        ...calcTotals(order),
        averageR: calcAverageR(orderItems.orderItems),
      }}
      validationSchema={dealNegotiationMemoSchema}
      onSubmit={async (values) => {
        let serverData = {}
        if (
          values.action === ACTION_MEMO_NEGOTIATION_UPDATE_TERMS ||
          values.action === ACTION_MEMO_NEGOTIATION_FINAL_TERMS
        ) {
          let orderItems = []
          for (const [key, value] of Object.entries(values.orderItems)) {
            orderItems.push({
              itemId: key,
              deliveryDaysFrom: value.deliveryDaysFrom,
              deliveryDaysTo: value.deliveryDaysTo,
              referencePrice: value.referencePrice,
              price: value.price,
              disabled: value.disabled,
            })
          }
          serverData = {
            memoTermsDays: values.memoTermDays,
            deliveryPrice: values.deliveryPrice,
            deliveryAddress: values.address,
            specialTerms: values.specialTerms,
            orderItems: orderItems,
          }
        }
        await performOrderAction(values.action, serverData)
        if (values.action === ACTION_MEMO_NEGOTIATION_UPDATE_TERMS) {
          await infoAlert(i18n.value('orders.labels.saveMemoSuccess'))
        }
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        setFieldValue,
        handleSubmit,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit}>
          <RecalculateTotal/>
          <MemoNegotiationBody
            order={order}
            values={values}
            setFieldValue={setFieldValue}
            isEditable={isEditable}
            showTimer
            performOrderAction={performOrderAction}
            asSeller={asSeller}
          />
          {order.state.key === 'deal_memo_final' ? (
            <section>
              <MessageBox className="mt-12"
                          icon={<ExclaimIcon width={50} height={50}/>}>
                <div dangerouslySetInnerHTML={{
                  __html: i18n.value('orders.labels.finalOfferNotice'),
                }}/>
              </MessageBox>
            </section>
          ) : null}
          {Object.keys(errors).length === 0 ? null : (
            <section className="my-4 bg-red-300 text-red-600">
              <ErrorMessage name="memoTermDays" component="div"/>
              <ErrorMessage name="deliveryPrice" component="div"/>
              <ErrorMessage name="specialTermsMemo" component="div"/>
              <ErrorMessage name="specialTerms" component="div"/>
              {errorFieldsAddress.map((item) => item)}
              {errorFieldsItems.map((item) => item)}
            </section>
          )}
          <div className="container mx-auto lg:w-screen-lg-min mt-12 ">
            {!asSeller && order.state.key !== 'deal_memo_final' &&
            buttons?.length ?
              <MessageBox className="font-bold mb-4">
            <span className="text-gray-500 ">{i18n.value(
              'order.labels.requestChangesViaChat')}</span>
                <span
                  className="text-brown-dim hover:underline hover:font-bold cursor-pointer"
                  onClick={openChat}>
              {i18n.value('order.labels.requestChangesViaChatButton')}
            </span>
              </MessageBox>
              : null}
            {order.timer.isStarted &&
            order.state.key !== 'memo_waiting_supplementary' &&
            order.state.key !== 'memo_supplementary_uploaded' ? (
              <OrderTimer
                timer={order.timer}
                label={
                  (order.state.key === 'deal_memo_final' || order.state.key ===
                    'deal_memo_update') && asSeller
                    ? 'orders.labels.replyTimerCustomer'
                    : 'orders.labels.replyTimer'
                }
              />
            ) : null}
            <div className="mt-2 flex justify-between">
              {buttons?.length > 0 && (
                <FormikSubmittingButtons
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  buttons={buttons}
                  dirty={dirty}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

DealNegotiationMemoForm.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func,
  buttons: PropTypes.array,
  isEditable: PropTypes.bool.isRequired,
  asSeller: PropTypes.bool.isRequired,
  openChat: PropTypes.func,
}

export default memo(connect(null, { openChat })(DealNegotiationMemoForm))
