import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.8457 3.57227L5.1387 6.66157L11.0784 1.08928"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
