import React, { memo } from 'react'
import PropTypes from 'prop-types'

import styles from './styles'
import { CloseIcon } from 'root/icons'
import { stylePropType, toStyle } from 'root/constants'

const CloseButton = ({ onClick, style }) => (
  <div onClick={onClick} style={toStyle([styles.remove, style])}>
    <div style={styles.removeInner} className="text-white">
      <CloseIcon width={5} height={5} />
    </div>
  </div>
)

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: stylePropType,
}

export default memo(CloseButton)
