import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ACTION_MEMO_NEW_CANCELED,
  ACTION_MEMO_NEW_CONFIRMED,
} from 'root/modules/orders/constants'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import Diamonds, {
  CertificateColumn,
  IndexColumn,
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import ReadonlyMemoTerms
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ReadonlyMemoTerms'
import OrderTimer from 'root/components/OrderTimer'
import EmptyFormWithActions
  from 'root/modules/orders/pages/components/EmptyOrderFormWithActions'
import MessageBox from 'root/components/MessageBox'
import i18n from 'root/i18n'
import { openChat } from 'root/modules/chat/actions'
import { connect } from 'react-redux'

const NewMemoCreated = ({
  order,
  performOrderAction,
  openChat,
  readOnly = false,
}) => {
  let buttons = [
    {
      color: 'brown',
      label: 'orders.buttons.confirmAndSignMemo',
      action: ACTION_MEMO_NEW_CONFIRMED,
    },
    {
      color: 'blue',
      label: 'orders.buttons.cancelMemoRequest',
      action: ACTION_MEMO_NEW_CANCELED,
    },
  ]
  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          seller={order.sellerCompanyId.name}
          asSeller={false}
        >
          <ViewOrderFiles order={order} doAction={performOrderAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds isEditable={false} showDeleted={false} items={order.products}>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
        </Diamonds>
      </section>

      <section>
        <ReadonlyMemoTerms order={order}/>
      </section>

      <section>
        <MessageBox className="font-bold mb-4">
            <span className="text-gray-500 ">{i18n.value(
              'order.labels.requestChangesViaChat')}</span>
          <span
            className="text-brown-dim hover:underline hover:font-bold cursor-pointer"
            onClick={openChat}>
              {i18n.value('order.labels.requestChangesViaChatButton')}
            </span>
        </MessageBox>
        <div className="mb-4">
          {order.timer.isStarted
            ? <OrderTimer timer={order.timer}
                          label={'orders.labels.replyTimer'}/>
            : ''}
        </div>
        <EmptyFormWithActions buttons={buttons}
                              performOrderAction={performOrderAction}/>
      </section>
    </>
  )
}

NewMemoCreated.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  openChat: PropTypes.func,
}

export default memo(connect(null, { openChat })(NewMemoCreated))
