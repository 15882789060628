import ResponsibleSelectorFormik from 'root/modules/orders/pages/components/ResponsibleSelector/formik'
import React, { memo } from 'react'
import PropTypes from 'prop-types'

const CommissionResponsiblesFormik = ({ values }) => {
  return (
    <div className="ml-auto text-gray-500 space-y-2">
      <div className="flex">
        <ResponsibleSelectorFormik
          label="orders.labels.taxResponsible"
          itemKey="isTaxesResponsibleSeller"
          isSeller={values.isTaxesResponsibleSeller}
          isEditable
        />
      </div>
      <div className="flex">
        <ResponsibleSelectorFormik
          label="orders.labels.bankResponsible"
          itemKey="isBankResponsibleSeller"
          isSeller={values.isBankResponsibleSeller}
          isEditable
        />
      </div>
    </div>
  )
}

CommissionResponsiblesFormik.propTypes = {
  values: PropTypes.object.isRequired,
}

export default memo(CommissionResponsiblesFormik)
