import { handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import { getIsLoadingReducer } from 'root/reducers/common'
import * as actions from '../../actions'

export default combineReducers({
  data: handleActions(
    {
      [actions.loadOrderStart]: () => false,
      [actions.loadInitialTermsStart]: () => false,
      [actions.loadInitialTermsSuccess]: (state, { payload: order }) => order,
    },
    false,
  ),
  isVisible: handleActions(
    {
      [actions.showInitialTerms]: () => true,
      [actions.hideInitialTerms]: () => false,
      [actions.loadOrderStart]: () => false,
    },
    false,
  ),
  withoutNames: handleActions(
    {
      [actions.showInitialTerms]: (state, { payload: withoutNames }) => withoutNames,
      [actions.hideInitialTerms]: () => false,
    },
    false,
  ),
  isLoading: getIsLoadingReducer(actions, 'loadInitialTerms'),
})
