import { connect } from 'react-redux'
import { showInitialTerms } from 'root/modules/orders/thunks'
import { openChat } from 'root/modules/chat/actions'

const mapDispatchToProps = {
  showInitialTerms,
  openChat,
}

export default connect(null, mapDispatchToProps)
