import { connect } from 'react-redux'

import { getActiveProductData, isActiveProductLoading } from 'root/modules/products/selectors'
import { loadProduct, performProductAction, saveProductThunk } from 'root/modules/products/thunks'

const mapStateToProps = (state) => ({
  data: getActiveProductData(state),
  isLoading: isActiveProductLoading(state),
})

const mapDispatchToProps = {
  loadProduct,
  saveProductThunk,
  performProductAction,
}

export default connect(mapStateToProps, mapDispatchToProps)
