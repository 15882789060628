import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = {}

export default handleActions(
  {
    [actions.loadCreditInfoStart]: (state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadCreditInfoSuccess]: (state, { payload: creditInfo }) => {
      return creditInfo
    },
  },
  defaultState,
)
