import React, { forwardRef, memo, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './styles'
import { ChevronDownTableIcon } from 'root/icons'
import AnimatedCounter from '../AnimatedCounter'

const ScrollDownButton = forwardRef(({ unreadCount, onPress }, ref) => {
  const [opacity, setOpacity] = useState(0)
  useImperativeHandle(ref, () => ({ show: () => setOpacity(1), hide: () => setOpacity(0) }), [])

  return (
    <div onClick={onPress} style={{ ...styles.scrollDown, opacity, pointerEvents: opacity ? undefined : 'none' }}>
      {unreadCount !== undefined && (
        <AnimatedCounter count={unreadCount} style={styles.counter} countStyle={styles.countStyle} />
      )}
      <ChevronDownTableIcon width={22} height={22} style={styles.scrollDownIcon} />
    </div>
  )
})

ScrollDownButton.displayName = 'ScrollDownButton'
ScrollDownButton.propTypes = {
  unreadCount: PropTypes.number,
  onPress: PropTypes.func.isRequired,
}

export default memo(ScrollDownButton)
