import { isAdmin } from 'root/selectors'

export const getState = (state) => state.data.profile

const getProfileCompany = (state) => getState(state).company
export const getProfileCompanyData = (state) => getProfileCompany(state).data
export const isProfileCompanyLoading = (state) => getProfileCompany(
  state).isLoading

const getInvoiceTerms = (state) => getState(state).invoiceTerms
export const getInvoiceTermsData = (state) => getInvoiceTerms(state).data
export const isInvoiceTermsLoading = (state) => getInvoiceTerms(state).isLoading

const getCreditInfo = (state) => getState(state).creditInfo
export const getCreditInfoData = (state) => getCreditInfo(state).data
export const isCreditInfoLoading = (state) => getCreditInfo(state).isLoading

const getKycInfo = (state) => getState(state).kycInfo
export const getKycInfoData = (state) => getKycInfo(state).data
export const isKycInfoLoading = (state) => getKycInfo(state).isLoading

const getCompanyTeam = (state) => getState(state).companyTeam
export const getCompanyTeamData = (state) => getCompanyTeam(state).data
export const isCompanyTeamLoading = (state) => getCompanyTeam(state).isLoading

const getCatalogFrameInfo = (state) => getState(state).catalogFrame
export const isCatalogIFrameEditable = (state) => isAdmin(state) ||
  getCatalogFrameInfoData(state).enabled
export const getCatalogFrameInfoData = (state) => getCatalogFrameInfo(
  state).data
export const isCatalogFrameInfoLoading = (state) => getCatalogFrameInfo(
  state).isLoading
