import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ProductRequiredLabel from 'root/modules/products/pages/Product/components/ProductRequiredLabel'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'

const TextInput = ({ label, labelBefore, isRequired = true, ...other }) => {
  return (
    <div>
      <ProductRequiredLabel label={label} isRequired={isRequired} />
      <div className="flex relative text-brown-dim">
        <div className="w-full mx-0">
          <InputFormik
            paddingClass={labelBefore && 'pl-6 pr-2'}
            inputClass="w-1/2 ml-0"
            textColor="text-blue-dark"
            {...other}
          />
        </div>
        {labelBefore && other.isEditable && <div className="absolute pl-2">{labelBefore}</div>}
      </div>
    </div>
  )
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelBefore: PropTypes.string,
  isRequired: PropTypes.bool,
}

export default memo(TextInput)
