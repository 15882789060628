import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ACTION_MEMO_PAD_CONFIRM_PAYMENT_SELLER,
  ACTION_MEMO_PAD_ISSUE_INVOICE_FOR_BUYER,
} from 'root/modules/orders/constants'
import InvoiceItems from './components/InvoiceItems'
import InvoiceList from './components/InvoiceList'

const PaymentItemsTab = ({ order, isSubmitting, handleSubmit, setFieldValue, values, isSeller, readOnly }) => {
  return (
    <>
      <InvoiceList
        order={order}
        action={ACTION_MEMO_PAD_CONFIRM_PAYMENT_SELLER}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        isSender={isSeller}
        readOnly={readOnly}
      />
      <div className="my-1">&nbsp;</div>
      <InvoiceItems
        order={order}
        action={ACTION_MEMO_PAD_ISSUE_INVOICE_FOR_BUYER}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        values={values}
        valuesKey={'orderItemsForInvoice'}
        isSender={isSeller}
        readOnly={readOnly}
      />
    </>
  )
}

PaymentItemsTab.propTypes = {
  order: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isSeller: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(PaymentItemsTab)
