import React, { memo } from 'react'
import Column from './Column'
import i18n from 'root/i18n'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import * as yup from 'yup'
import { getValidate, trimNumber } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const schema = yup.number().required().positive().integer().min(0).max(255)
const validate = getValidate(schema)

const DeliveryColumn = ({ children, ...props }) => (
  <Column
    label="order.diamonds.delivery"
    columnClass="w-32 text-center"
    className="w-32 text-center text-blue-dark flex items-center justify-center"
    {...props}
  >
    {children ||
      (({ item, isEditable, formContext }) => {
        const text = i18n.value('common.days')
        return isEditable ? (
          <div className="text-blue-dark flex whitespace-no-wrap items-center justify-center">
            <InputTermFormik
              inputClass="w-10 text-sm"
              itemKey={`orderItems['${item.id}'].deliveryDaysFrom`}
              isEditable
              onChange={(e) => {
                formContext.setFieldValue(`orderItems['${item.id}'].deliveryDaysFrom`, trimNumber(e.target.value))
              }}
              validate={validate}
            />
            <span className="ml-2">-</span>
            <InputTermFormik
              inputClass="w-10 text-sm"
              itemKey={`orderItems['${item.id}'].deliveryDaysTo`}
              isEditable
              onChange={(e) => {
                formContext.setFieldValue(`orderItems['${item.id}'].deliveryDaysTo`, trimNumber(e.target.value))
              }}
              validate={validate}
            />
            <span className="ml-2">{text}</span>
          </div>
        ) : (
          <div className="flex">
            <div>{`${item.deliveryDaysFrom} - ${item.deliveryDaysTo} ${text}`}</div>
            {(item?.termsChanges?.deliveryDaysTo || item?.termsChanges?.deliveryDaysFrom) && (
              <i className="ml-1 text-brown-dim">
                <ExclaimIcon width={12} height={12} />
              </i>
            )}
          </div>
        )
      })}
  </Column>
)

DeliveryColumn.propTypes = Column.propTypes

export default memo(DeliveryColumn)
