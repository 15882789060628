import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="28" height="28" viewBox="0 0 28 28" className="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3438 3.5C12.6189 3.5 12.0312 4.08763 12.0312 4.8125L11.8737 5.91544C11.7326 6.90299 10.5849 7.37837 9.78688 6.77982L8.89557 6.11134C8.38301 5.59878 7.55198 5.59878 7.03942 6.11134L6.11134 7.03942C5.59878 7.55198 5.59878 8.38301 6.11134 8.89558L6.77982 9.78688C7.37836 10.5849 6.90298 11.7326 5.91543 11.8737L4.8125 12.0312C4.08763 12.0312 3.5 12.6189 3.5 13.3437V14.6562C3.5 15.3811 4.08763 15.9687 4.8125 15.9687L5.91544 16.1263C6.90299 16.2674 7.37837 17.4151 6.77982 18.2131L6.11134 19.1044C5.59878 19.617 5.59878 20.448 6.11134 20.9606L7.03942 21.8887C7.55198 22.4012 8.38301 22.4012 8.89557 21.8887L9.78688 21.2202C10.5849 20.6216 11.7326 21.097 11.8737 22.0846L12.0312 23.1875C12.0312 23.9124 12.6189 24.5 13.3438 24.5H14.6562C15.3811 24.5 15.9688 23.9124 15.9688 23.1875L16.1263 22.0846C16.2674 21.097 17.4151 20.6216 18.2131 21.2202L19.1044 21.8887C19.617 22.4012 20.448 22.4012 20.9606 21.8887L21.8887 20.9606C22.4012 20.448 22.4012 19.617 21.8887 19.1044L21.2202 18.2131C20.6216 17.4151 21.097 16.2674 22.0846 16.1263L23.1875 15.9688C23.9124 15.9688 24.5 15.3811 24.5 14.6562V13.3438C24.5 12.6189 23.9124 12.0312 23.1875 12.0312L22.0846 11.8737C21.097 11.7326 20.6216 10.5849 21.2202 9.78688L21.8887 8.89558C22.4012 8.38301 22.4012 7.55198 21.8887 7.03942L20.9606 6.11134C20.448 5.59878 19.617 5.59878 19.1044 6.11134L18.2131 6.77982C17.4151 7.37837 16.2674 6.90299 16.1263 5.91544L15.9688 4.8125C15.9688 4.08763 15.3811 3.5 14.6562 3.5H13.3438ZM14 16.625C15.4497 16.625 16.625 15.4497 16.625 14C16.625 12.5503 15.4497 11.375 14 11.375C12.5503 11.375 11.375 12.5503 11.375 14C11.375 15.4497 12.5503 16.625 14 16.625Z"
    />
  </svg>
)

export default memo(SvgComponent)
