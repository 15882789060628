import React, { memo } from 'react'
import PropTypes from 'prop-types'
import CheckBoxIcon from 'root/icons/CheckBoxIcon'
import CheckBoxCheckedIcon from 'root/icons/CheckBoxCheckedIcon'
import { Field } from 'formik'
import './styles.css'
import { toClassName } from 'root/constants'

const CheckBoxFormik = ({ labelBefore, labelAfter, itemKey, isChecked, isEditable = true }) => {
  const Icon = isChecked ? CheckBoxCheckedIcon : CheckBoxIcon
  return (
    <div className={'checkbox-input'}>
      {isEditable && (
        <Field type="checkbox" className="hidden form-checkbox" checked={isChecked} id={itemKey} name={itemKey} />
      )}
      <label
        htmlFor={itemKey}
        className={toClassName([
          'space-x-2 toggled:text-brown-dim text-brown-dim',
          isEditable && 'hover:text-brown-dim',
        ])}
      >
        {labelBefore ? <span>{labelAfter}</span> : ''}
        {isEditable && <Icon />}
        {labelAfter ? <span>{labelAfter}</span> : ''}
      </label>
    </div>
  )
}

CheckBoxFormik.propTypes = {
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  itemKey: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isEditable: PropTypes.bool,
}

export default memo(CheckBoxFormik)
