import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.87723L7.33975 0.101562H14.1602L11.9995 2.09229H8.34125L3.04625 6.98116L15.1014 20.0688L18.5232 16.3512L9.79251 6.87723L17.1369 0.101562H32.6603L40 6.87723L24.9032 23.2707L21.3979 19.4572L22.8724 17.8542L24.9032 20.0688L36.9537 6.98116L31.6587 2.09229H25.0238L30.2028 6.87723L15.1014 23.2707L0 6.87723ZM12.8434 6.98116L19.9977 14.7482L27.152 6.98116L21.8616 2.09229H18.143L12.8434 6.98116Z"
      fill="#AA8A9F"
    />
  </svg>
)

export default memo(SvgComponent)
