import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Button from 'root/components/Buttons/Button'

const BlueButton = ({ className = '', ...props }) => <Button className={'bg-blue-skyblue ' + className} {...props} />

BlueButton.propTypes = {
  className: PropTypes.string,
}

export default memo(BlueButton)
