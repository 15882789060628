import { initAuthData } from 'root/thunks'
import { sessionSign, smsConfirm } from 'root/api'
import { doOrDie, isAuthError } from 'root/constants'
import { setSession } from 'root/modules/ws'

export const loginByPassword = (login, password) => async (dispatch) =>
  sessionSign(login, password).then(({ data: { data: session } }) => dispatch(onAuthConfirm(session)))

export const confirmSms = (oneTimeKey, oneTimeCode) => async (dispatch) =>
  doOrDie(() => smsConfirm(oneTimeKey, oneTimeCode), isAuthError, true).then(({ data: { data: session } }) => {
    if (session?.firstActivated) {
      window.dataLayer.push({
        event: 'GAEvent',
        eventCategory: 'registration',
        eventAction: 'success',
        eventLabel: 'first',
      })
    }
    return dispatch(onAuthConfirm(session))
  })

export const onAuthConfirm = (session) => async (dispatch) => {
  window.dataLayer.push({
    event: 'GAEvent',
    eventCategory: 'login',
    eventAction: 'success',
    eventLabel: 'lk',
  })
  setSession(session)
  return dispatch(initAuthData(session))
}
