import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { STATE_EXISTING_MEMO_REQUEST, STATE_NEW_MEMO_REQUEST } from 'root/modules/orders/constants'
import NegotiationBuyer from './components/NegotiationBuyer'
import NegotiationSeller from './components/NegotiationSeller'
import MemoRequestBuyer from './components/MemoRequestBuyer'
import MemoRequestSeller from './components/MemoRequestSeller'
import ReadonlyNotice from 'root/modules/orders/pages/Order/components/components/ReadonlyNotice'

const components = {
  [true]: {
    [true]: MemoRequestSeller,
    [false]: MemoRequestBuyer,
  },
  [false]: {
    [true]: NegotiationSeller,
    [false]: NegotiationBuyer,
  },
}

const Negotiation = ({ asSeller, ...props }) => {
  const isMemoRequest = [STATE_NEW_MEMO_REQUEST, STATE_EXISTING_MEMO_REQUEST].includes(props.order.state.key)
  const Component = components[isMemoRequest][asSeller]
  return (
    <>
      {props.readOnly ? <ReadonlyNotice order={props.order} /> : null}
      <Component {...props} />
      {props.readOnly ? <ReadonlyNotice order={props.order} /> : null}
    </>
  )
}

Negotiation.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  asSeller: PropTypes.bool.isRequired,
}

export default memo(Negotiation)
