import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="#90B9D7"
    className="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 8.99286L8.99286 10L5 6.00714L1.00714 10L0 8.99286L3.99286 5L0 1.00714L1.00714 0L5 3.99286L8.99286 0L10 1.00714L6.00714 5L10 8.99286Z" />
  </svg>
)

export default memo(SvgComponent)
