import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="current-color"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    stroke="none"
  >
    <path d="M9.66932 16.4239L10.0231 16.7051L10.3768 16.4239C17.9826 10.518 20.0462 8.43693 20.0462 5.06216C20.0462 2.24985 17.6878 0 14.7398 0C12.3225 0 10.9664 1.29366 10.0231 2.3061C9.07973 1.29366 7.72367 0 5.30633 0C2.35837 0 0 2.24985 0 5.06216C0 8.43693 2.06357 10.518 9.66932 16.4239Z" />
  </svg>
)

export default memo(SvgComponent)
