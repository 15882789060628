import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import WaitResponseScreen from 'root/modules/orders/pages/components/WaitResponseScreen'
import UploadedFilesList from 'root/modules/orders/pages/components/UploadedFilesList'
import { SECTION_SALES } from 'root/modules/orders/constants'
import EmptyFormWithActions from 'root/modules/orders/pages/components/EmptyOrderFormWithActions'
import DealNegotiationMemoForm from 'root/modules/orders/pages/Order/components/MemoOrder/components/DealNegotiationMemo/DealNegotiationMemoForm'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'
import OrderTimer from 'root/components/OrderTimer'

const MemoSupplementaryUploaded = ({ order, performOrderAction, readOnly = false, asSeller }) => {
  const { section } = useParams()
  if (section === SECTION_SALES) {
    const labels = {
      heading: 'Supplementary Agreement uploaded',
      headingBeforeTimer: 'orders.labels.waitCustomerApproveDoc',
      timer: 'orders.labels.customerReplyTimer',
    }
    let buttons = order.timer.isExpired
      ? [{ color: 'blue', label: 'orders.buttons.backToInvoice', action: 'return_to_deal_invoice' }]
      : []
    if (readOnly) buttons = []
    return (
      <div className="container mx-auto items-center">
        <DealNegotiationMemoForm order={order} isEditable={false} asSeller={asSeller} />
        <WaitResponseScreen order={order} performOrderAction={performOrderAction} labels={labels} buttons={buttons} />
      </div>
    )
  } else {
    let buttons = [
      { color: 'brown', label: 'orders.buttons.confirm', action: 'memo_payment_and_delivery' },
      { color: 'blue', label: 'orders.buttons.reject', action: 'memo_supplementary_refused' },
    ]

    return (
      <div className="flex flex-col space-y-12">
        <DealNegotiationMemoForm order={order} isEditable={false} asSeller={asSeller} />

        <section>
          <div className="flex space-x-4">
            <div className="text-brown-dim">
              <DocumentRowIcon width={50} height={50} />
            </div>
            <div className="text-lg text-brown-dim">
              <b>Please, review uploaded Supplementary Agreement</b>
              <br />
              If you need to apply any changes, you can Reject the document and wait for supplier to upload a new
              version.
            </div>
          </div>
          <UploadedFilesList files={order.documents[0].files} documentId={order.documents[0].id} />
        </section>

        <section>
          <div className="mb-4">
            {order.timer.isStarted ? <OrderTimer timer={order.timer} label={'orders.labels.replyTimer'} /> : ''}
          </div>
          <EmptyFormWithActions buttons={buttons} performOrderAction={performOrderAction} />
        </section>
      </div>
    )
  }
}

MemoSupplementaryUploaded.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  asSeller: PropTypes.bool.isRequired,
}

export default memo(MemoSupplementaryUploaded)
