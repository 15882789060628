import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useDiamondsTableFormValues } from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import { calcAverageR, calcTotals, RESPONSIBLE_BUYER, RESPONSIBLE_SELLER } from 'root/modules/orders/constants'
import i18n from 'root/i18n'
import { confirmAlert, infoAlert } from 'root/components/AlertWrapper'

const UpdateTermsForm = ({ order, doAction, actionName, children }) => {
  const items = useDiamondsTableFormValues(order)
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...order,
        ...items,
        ...calcTotals(order),
        averageR: calcAverageR(items.orderItems),
        specialTerms: order.specialTerms || '',
        deliveryPrice: order.state.key === 'deal_init' ? undefined : order.deliveryPrice.value,
      }}
      onSubmit={async (values) => {
        // eslint-disable-next-line no-restricted-globals
        if (!(await confirmAlert(i18n.value('orders.labels.confirmDiamondsAreCorrect')))) {
          return
        }
        const serverData = {
          dealValidDays: values.dealValidDays,
          paymentBeforeDelivery: values.paymentBeforeDelivery,
          paymentAfterDelivery: values.paymentAfterDelivery,
          orderItems: Object.values(values.orderItems),
          additionalGoods: values.additionalGoods.filter((e) => !e.disabled && e.price > 0),
          deliveryAddress: values.address,
          specialTerms: values.specialTerms,
          taxesResponsible: values.isTaxesResponsibleSeller ? RESPONSIBLE_SELLER : RESPONSIBLE_BUYER,
          bankCommissionResponsible: values.isBankResponsibleSeller ? RESPONSIBLE_SELLER : RESPONSIBLE_BUYER,
          returnDeliveryResponsible: values.isDeliveryResponsibleSeller ? RESPONSIBLE_SELLER : RESPONSIBLE_BUYER,
          returnPeriodDays: values.returnPeriodDays,
          returnMoneyDays: values.returnMoneyDays,
          returnAvailability: values.returnAvailability ? 1 : 0,
          deliveryPrice: values.deliveryPrice,
          hasAdditionalInvoice: values.hasAdditionalInvoice ? 1 : 0,
        }
        return doAction(actionName, serverData).then(() => {
          return infoAlert(i18n.value('orders.labels.saveSuccess'))
        })
      }}
    >
      {children}
    </Formik>
  )
}

UpdateTermsForm.propTypes = {
  children: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
}

export default memo(UpdateTermsForm)
