import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'
import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DealPaymentIcon from 'root/icons/DealPaymentIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import i18n from 'root/i18n'
import { getDocument, getDocumentFile, uploadOrderDocument } from 'root/api'
import {
  ACTION_ADDITIONAL_INVOICE_CREATED,
  ACTION_COMPLETE,
  DOCUMENT_TYPE_ADDITIONAL_INVOICE,
  STATE_ADDITIONAL_INVOICE,
  STATE_ADDITIONAL_INVOICE_CANCELED,
  STATE_ADDITIONAL_INVOICE_CONFIRMED,
  STATE_ADDITIONAL_INVOICE_CREATED,
} from 'root/modules/orders/constants'
import OrderTimer from 'root/components/OrderTimer'
import DownloadIcon from 'root/icons/DownloadIcon'
import UploadDocument from 'root/modules/orders/pages/components/UploadDocument'
import TextLabel from 'root/components/TextLabel'
import BrownButton from 'root/components/Buttons/BrownButton'
import { confirmAlert } from 'root/components/AlertWrapper'
import { doNothing } from 'root/constants'
import { useAsyncLoad } from 'root/hooks'

const CardDealCreateAdditionalInvoice = ({ order, help, doAction }) => {
  const [loading, submitAction] = useAsyncLoad(doAction)
  const invoice = order.documents.find((document) => document.type === DOCUMENT_TYPE_ADDITIONAL_INVOICE)
  const invoiceFile = invoice?.files[0]
  const isCurrent = [
    STATE_ADDITIONAL_INVOICE,
    STATE_ADDITIONAL_INVOICE_CREATED,
    STATE_ADDITIONAL_INVOICE_CANCELED,
  ].includes(order.state.key)
  const isCompleted =
    ![STATE_ADDITIONAL_INVOICE, STATE_ADDITIONAL_INVOICE_CREATED, STATE_ADDITIONAL_INVOICE_CANCELED].includes(
      order.state.key,
    ) && invoiceFile
  const canUpload = order.actions.some((action) => action.url === ACTION_ADDITIONAL_INVOICE_CREATED)
  const canComplete = order.actions.some((action) => action.url === ACTION_COMPLETE)
  const isConfirmed = order.state.key === STATE_ADDITIONAL_INVOICE_CONFIRMED
  const isCancelled = order.state.key === STATE_ADDITIONAL_INVOICE_CANCELED
  const invoiceIssuedDate = invoiceFile?.created
  const invoiceIssued = invoiceIssuedDate > 0
  const statusLabel = isConfirmed
    ? 'order.card.invoiceConfirmed'
    : isCancelled
    ? 'order.card.invoiceCanceled'
    : invoiceIssued
    ? 'order.card.waitingForBuyer'
    : ''
  return (
    <Card
      help={help}
      label="order.card.dealAdditionalInvoice"
      Icon={DealPaymentIcon}
      current={isCurrent}
      completed={isCompleted}
    >
      {invoiceIssued && (
        <CardRow label="order.card.invoiceIssued">
          <div>
            <Date value={invoiceIssuedDate} />
          </div>
        </CardRow>
      )}
      {order.state.key !== STATE_ADDITIONAL_INVOICE && isCurrent ? (
        <CardRow label="order.card.invoiceStatus">
          <div>
            <TextLabel label={statusLabel} />
            {order.state.key === STATE_ADDITIONAL_INVOICE_CREATED && <OrderTimer timer={order.timer} />}
          </div>
        </CardRow>
      ) : null}
      {canUpload && (
        <>
          <div className="flex flex-col space-y-4">
            <OrderTimer label="orders.labels.replyTimer" timer={order.timer} />
            <button
              onClick={() => {
                getDocument(invoice.id)
              }}
              type="button"
              className="flex space-x-2 items-center font-bold text-blue-skyblue"
            >
              <DownloadIcon width={20} height={20} />
              <span>{i18n.value('order.card.download')}</span>
            </button>
          </div>
          <div className="w-full">
            <div className="text-sm text-brown-dim-light mt-4">
              Please, make sure the invoice is checked and signed:
            </div>
            <UploadDocument
              accept={'image/*, application/pdf'}
              buttons={[
                {
                  color: 'brown',
                  label: 'order.card.upload',
                  action: ACTION_ADDITIONAL_INVOICE_CREATED,
                  params: {
                    documentId: invoice.id,
                  },
                },
              ]}
              uploadDocumentFunction={(file) => uploadOrderDocument(order.id, file)}
              performOrderAction={doAction}
            />
          </div>
        </>
      )}
      {invoiceFile && (
        <CardRow full>
          <button
            type="button"
            onClick={() => {
              getDocumentFile(invoice.id, invoiceFile.id)
            }}
            className="text-brown-dim"
          >
            {i18n.value('order.card.viewInvoice')}
          </button>
        </CardRow>
      )}
      {canComplete && (
        <CardRow full>
          <BrownButton
            className="mt-2"
            type="button"
            disabled={loading}
            label="orders.buttons.complete"
            onClick={(e) => {
              e.stopPropagation()
              return confirmAlert(i18n.value('order.card.confirmComplete'))
                .then(() => {
                  submitAction(ACTION_COMPLETE)
                })
                .catch(doNothing)
            }}
          />
        </CardRow>
      )}
    </Card>
  )
}
CardDealCreateAdditionalInvoice.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  help: PropTypes.string,
  children: PropTypes.func,
}
export default memo(CardDealCreateAdditionalInvoice)
