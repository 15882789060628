import React, { memo } from 'react'
import Column from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds/Column'
import NotShippedIcon from 'root/icons/NotShippedIcon'
import i18n from 'root/i18n'
import DeliveryIcon from 'root/icons/DeliveryIcon'
import StockIcon from 'root/icons/StockIcon'
import ReturnRequestIcon from 'root/icons/ReturnRequestIcon'
import ReturnedDiamondIcon from 'root/icons/ReturnedDiamondIcon'
import PaymentIcon from 'root/icons/PaymentIcon'
import PaidIcon from 'root/icons/PaidIcon'
import ReturnIcon from 'root/icons/ReturnIcon'
import ReturnShippedIcon from 'root/icons/ReturnShippedIcon'

const StatusColumn = ({ children, onClick, ...other }) => (
  <Column label="order.diamonds.status" columnClass="w-48" className="w-48 text-brown-dim flex items-center" {...other}>
    {children ||
      (({ item }) => {
        let label = 'order.diamonds.notShipped'
        let icon = <NotShippedIcon />
        let tab = 'delivery'
        if (item.metaState.fullyReceivedBySeller) {
          label = 'order.diamonds.returned'
          icon = <ReturnedDiamondIcon width={27} height={24} />
          tab = 'return'
        } else if (item.metaState.fullyShippedByBuyer) {
          label = 'order.diamonds.shippedToSeller'
          icon = <ReturnShippedIcon />
          tab = 'return'
        } else if (item.metaState.toBeReturned) {
          label = 'order.diamonds.toBeReturned'
          icon = <ReturnIcon width={23} height={26} />
          tab = 'return'
        } else if (item.metaState.returnRequest) {
          label = 'order.diamonds.returnRequest'
          icon = <ReturnRequestIcon width={20} height={20} />
          tab = 'returnRequest'
        } else if (item.metaState.paid) {
          label = 'order.diamonds.paid'
          icon = <PaidIcon />
          tab = 'payment'
        } else if (item.metaState.inPayment) {
          label = 'order.diamonds.inPayment'
          icon = <PaymentIcon width={17.84} height={19.69} />
          tab = 'payment'
        } else if (item.metaState.inCustomerStock) {
          label = 'order.diamonds.inCustomerStock'
          icon = <StockIcon width={24.19} height={17.87} />
          tab = 'stock'
        } else if (item.metaState.fullyShippedBySeller) {
          label = 'order.diamonds.shippedToBuyer'
          icon = <DeliveryIcon width={27.5} height={18.33} />
          tab = 'delivery'
        }
        return (
          <div
            onClick={() => (onClick ? onClick(tab) : null)}
            className="text-blue-skyblue flex items-center cursor-pointer hover:underline"
          >
            {icon}
            <label className="ml-2 cursor-pointer">{i18n.value(label)}</label>
          </div>
        )
      })}
  </Column>
)
StatusColumn.propTypes = Column.propTypes
export default memo(StatusColumn)
