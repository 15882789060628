import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'root/components/Spinner'
import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import connect from './connect'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import Diamonds, {
  CertificateColumn,
  DeliveryColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import DeliveryPrice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/DeliveryPrice'
import PaymentTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/PaymentTermsSection'
import AddressSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/AddressSection'
import ReturnTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/ReturnTermsSection'
import SpecialTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/SpecialTermsSection'

const InitialTerms = ({
  terms,
  isVisible,
  isLoading,
  close,
  withoutNames = false,
}) => {
  if (isVisible) {
    return (
      <Modal onClick={close} isFull>
        <div className="container flex flex-col space-y-8">
          <section>
            <OrderHeading
              label={i18n.value('order.labels.initialDealTermsHeader')}/>
          </section>
          {isLoading || !terms ? (
            <section className="flex items-center justify-center">
              <Spinner/>
            </section>
          ) : (
            <>
              <section>
                <GeneralTerms
                  order={terms}
                  withoutManager
                  number={terms.number}
                  date={terms.date}
                  editable={false}
                  validFor={terms.dealValidDays}
                  showKYC={false}
                />
              </section>

              <section>
                <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
                <Diamonds isEditable={false} showDeleted={false}
                          items={terms.products}>
                  <IndexColumn/>
                  <ShapeColumn/>
                  <NameColumn/>
                  <CertificateColumn/>
                  <RefPriceColumn/>
                  <RapaportColumn/>
                  <PriceColumn/>
                  <DeliveryColumn/>
                </Diamonds>
                <div className="flex mt-4">
                  <div className="flex mr-40 pr-2 ml-auto">
                    <DeliveryPrice isEditable={false} order={terms}/>
                  </div>
                </div>
              </section>

              <section>
                <PaymentTermsSection isOpened={false} isEditable={false}
                                     values={terms}/>
              </section>

              <section>
                <AddressSection withoutNames={withoutNames} isOpened={false}
                                values={terms}/>
              </section>

              <section>
                <ReturnTermsSection isOpened={false} isEditable={false}
                                    values={terms}/>
              </section>

              {terms.specialTerms && (
                <section>
                  <SpecialTermsSection isOpened={false} isEditable={false}
                                       values={terms}/>
                </section>
              )}
            </>
          )}
        </div>
      </Modal>
    )
  }
  return null
}

InitialTerms.propTypes = {
  terms: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  withoutNames: PropTypes.bool,
}

export default memo(connect(InitialTerms))
