import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'

import i18n from 'root/i18n'
import { DocumentEditIcon } from 'root/icons'

import TemplateDoc from 'root/modules/orders/pages/components/TemplateDoc'
import { downloadStock, uploadXlsProducts } from 'root/api/Products'

import UploadXls from './components/UploadXls'
import { infoAlert } from 'root/components/AlertWrapper'
import { requestFtpAccess } from 'root/api'
import { errorAlert } from 'root/constants'
import ClockIcon from 'root/icons/ClockIcon'

const CatalogSellerButtons = ({
  updateFilters,
  products,
  isProductsListLoading,
  company,
}) => {
  const history = useHistory()

  const doRequestFtpAccess = useCallback(() => {
    requestFtpAccess(company).then(() => {
      return infoAlert(
        i18n.value('profile.company.labels.requestFtpAccessSent'))
    }).catch(errorAlert)
  }, [company])

  let lastStockUpdate = null
  if (company?.lastStockUpdate) {
    const d = new Date(company.lastStockUpdate * 1000)
    lastStockUpdate = d.toLocaleDateString()
  }

  return (
    <div className="w-1/2 flex flex-col space-y-2">
      <div className="flex text-left">
        <div className="flex items-center text-blue-skyblue cursor-pointer">
          <UploadXls
            label={'products.labels.bulkUpload'}
            uploadDocumentFunction={uploadXlsProducts}
            refreshFunction={updateFilters}
            accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
          />
        </div>
        <div
          className="flex items-center text-blue-skyblue cursor-pointer ml-3"
          onClick={() => history.push(`/products/new`)}
        >
          <DocumentEditIcon/>
          <span className="ml-2">{i18n.value(
            'products.labels.manualEdit')}</span>
        </div>
        <div
          className="flex items-center text-blue-skyblue cursor-pointer ml-3">
          <TemplateDoc label={'products.labels.downloadMyStock'}
                       onClick={downloadStock}/>
        </div>
      </div>
      <div>
        <Link to={`/company/goods`}
              className="flex items-center text-blue-skyblue cursor-pointer">
          {i18n.value('profile.company.labels.viewAndEditGoods')}
        </Link>
      </div>
      {!company?.ftp?.enabled ? (
        <div className="flex items-center text-blue-skyblue cursor-pointer"
             onClick={doRequestFtpAccess}>
          {i18n.value('profile.company.labels.requestFtpAccess')}
        </div>
      ) : null}
      {lastStockUpdate ? (
        <div className="text-xs text-brown-dim flex space-x-1">
          <ClockIcon width={16} height={16}/>
          <span>Last updated:</span>
          <span>{lastStockUpdate}</span>
        </div>
      ) : null}
    </div>
  )
}

CatalogSellerButtons.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  products: PropTypes.array,
  isProductsListLoading: PropTypes.bool,
  company: PropTypes.object,
}

export default memo(CatalogSellerButtons)
