import React, { useState, useEffect, memo } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import PaymentIcon from 'root/icons/PaymentIcon'

const PaymentStatus = ({ secret, onSuccess, onError }) => {
  const stripe = useStripe()
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!secret) return null

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(secret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment successful')
          onSuccess(paymentIntent)
          break

        case 'processing':
          setMessage('Payment processing')
          onSuccess(paymentIntent)
          break

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage('Payment failed. Please try another payment method.')
          onError(paymentIntent)
          break

        default:
          setMessage('Something went wrong.')
          onError(paymentIntent)
          break
      }
    })
  }, [onError, onSuccess, secret, stripe])

  return message ? (
    <div className="border border-brown-dim p-2 mt-2 space-x-2 flex text-brown-dim items-center">
      <div className="flex-0">
        <PaymentIcon />
      </div>
      <div className="flex flex-col items-center flex-grow">{message}</div>
    </div>
  ) : null
}
PaymentStatus.propTypes = {
  secret: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default memo(PaymentStatus)
