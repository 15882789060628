export const getState = (state) => state.data.products

const getProductsList = (state) => getState(state).productsList
export const isProductsListLoading = (state) => getProductsList(state).isLoading
export const getProductsData = (state) => getProductsList(state).data
export const getActiveProductsState = (state) => getProductsData(state).filters.s

const getActiveProduct = (state) => getState(state).product
export const getActiveProductData = (state) => getActiveProduct(state).data
export const getActiveProductCompany = (state) => getActiveProductData(state).company
export const isActiveProductLoading = (state) => getActiveProduct(state).isLoading
