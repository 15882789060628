import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="current-color"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    stroke="none"
  >
    <path d="M0 2.75H2.75V0H0V2.75ZM4.125 11H6.875V8.25H4.125V11ZM0 11H2.75V8.25H0V11ZM0 6.875H2.75V4.125H0V6.875ZM4.125 6.875H6.875V4.125H4.125V6.875ZM8.25 0V2.75H11V0H8.25ZM4.125 2.75H6.875V0H4.125V2.75ZM8.25 6.875H11V4.125H8.25V6.875ZM8.25 11H11V8.25H8.25V11Z" />
  </svg>
)

export default memo(SvgComponent)
