import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { COUNTRY_INFO_BY_CODE } from 'root/constants'

const addressKeys = ['countryId', 'addressLine1', 'addressLine2', 'cityName', 'regionName', 'zipCode']
const personKeys = ['companyName', 'recipientName', 'recipientPhone']

const DeliveryAddressInfo = ({ address, withoutNames = false }) => {
  const addressValues = { ...address, countryId: COUNTRY_INFO_BY_CODE[address.countryId].name || address.countryId }
  const keys = withoutNames ? addressKeys : addressKeys.concat(personKeys)

  return (
    <div className="flex space-x-8 whitespace-no-wrap">
      <div className="space-y-2 text-gray-500">
        {keys.map((label) => (
          <div key={label}>{i18n.value(`order.labels.address.${label}`)}</div>
        ))}
      </div>
      <div className="space-y-2 text-brown-dim">
        {keys.map((index) => (
          <div key={index}>{addressValues[index] || '・'}</div>
        ))}
      </div>
    </div>
  )
}

DeliveryAddressInfo.propTypes = {
  address: PropTypes.object.isRequired,
  withoutNames: PropTypes.bool,
}

export default memo(DeliveryAddressInfo)
