import { handleActions } from 'redux-actions'

import * as actions from '../../../memo-agreements/actions'
import { getLoadListReducer } from 'root/reducers/common'
import { getMemoAgreements } from 'root/api'

export const defaultState = {
  items: [],
  page: 0,
  hasNext: true,
  loadFunc: getMemoAgreements,
  filters: { s: '', q: '' },
}

export default handleActions(
  {
    [actions.updateMemoAgreementFilters]: (state, { payload: filters }) => ({
      ...state,
      filters: { ...state.filters, ...filters },
    }),
    [actions.loadMemoAgreementListSuccess]: getLoadListReducer(),
  },
  defaultState,
)
