import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { useToggle } from 'root/hooks'
import FormikSubmittingButtons
  from 'root/components/Buttons/FormikSubmittingButtons'
import {
  ACTION_MEMO_PAD_UPLOAD_INVOICE_FOR_BUYER,
} from 'root/modules/orders/constants'
import { getDocument, uploadOrderDocument } from 'root/api'
import TemplateDoc from 'root/modules/orders/pages/components/TemplateDoc'
import UploadDocumentInsideFormik
  from 'root/modules/orders/pages/components/UploadDocument/inside_formik'
import UploadedFilesList
  from 'root/modules/orders/pages/components/UploadedFilesList'
import Progress from '../../../../../Progress'

const InvoiceRow = ({
  order,
  document,
  action,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  isSender,
  readOnly,
}) => {
  const [opened, toggle] = useToggle(true)
  let rows = []
  let buttons = []
  let buttonsForUpload = []
  const isUploaded = document.files.length > 0
  const isPaid = document.state === 'finished'

  if (isSender && isUploaded && !isPaid && !readOnly) {
    buttons = [
      {
        color: 'brown',
        label: 'orders.buttons.paymentReceived',
        action: action,
        params: {
          documentId: [document.id],
        },
      },
    ]
  }

  const uploadInvoice = (file) => uploadOrderDocument(order.id, file)

  if (isSender && !isUploaded && !readOnly) {
    buttonsForUpload = [
      {
        color: 'brown',
        label: 'orders.buttons.confirmUploadInvoice',
        action: ACTION_MEMO_PAD_UPLOAD_INVOICE_FOR_BUYER,
        params: {
          documentId: document.id,
        },
      },
    ]
  }

  if (opened) {
    for (let i = 0; i < document.specification.connectedProducts.length; i++) {
      const itemId = document.specification.connectedProducts[i]
      let itemNameField = ''
      for (let i = 0; i < order.products.length; i++) {
        const product = order.products[i]
        if (product.id !== itemId) {
          continue
        }
        itemNameField = <label className="text-brown-dim">{product.name}</label>
      }
      rows.push(
        <div key={document.id + itemId} className="flex">
          <div className="ml-32">{itemNameField}</div>
        </div>,
      )
    }

    if (isSender && !isUploaded && !readOnly) {
      rows.push(
        <div className="" key={'upload_doc_' + document.id}>
          <hr className="my-6"/>
          <div className="text-blue-skyblue text-center">{i18n.value(
            'orders.labels.supplierUploadNewInvoice')}</div>
          <UploadDocumentInsideFormik
            accept={'image/*, application/pdf'}
            buttons={buttonsForUpload}
            uploadDocumentFunction={uploadInvoice}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
          />
          <div className="flex items-center">
            <TemplateDoc
              label={'orders.labels.lgDealInvoiceTemplate'}
              onClick={() => {
                getDocument(document.id)
              }}
            />
          </div>
          <hr className="my-6"/>
        </div>,
      )
    }

    if (isUploaded) {
      rows.push(
        <div key={'uploaded_files_' + document.id}>
          <UploadedFilesList files={document.files} documentId={document.id}/>
        </div>,
      )
    }
  }
  const colSpan = isSender && !isPaid && isUploaded
    ? 'col-span-5'
    : 'col-span-5'
  return (
    <div className="pb-4 mb-20 border-b border-brown-dim">
      <div className="grid grid-cols-6">
        <div className={colSpan}>
          <div className={'relative flex cursor-pointer text-sm'}
               onClick={toggle}>
            <label
              className="text-blue-skyblue font-bold uppercase">{i18n.value(
              'orders.labels.invoice')}</label>
            <label
              className={'text-brown-dim ml-4 font-bold'}>${document.specification.totalSum}</label>
            <div className="mt-2 ml-4 z-10">
              <svg
                className="inline"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                preserveAspectRatio="xMinYMin"
              >
                <use href={`#${opened ? 'down' : 'up'}`}/>
              </svg>
            </div>
            <Progress
              values={[true, isUploaded, isPaid]}
              labels={['Issued', 'Uploaded', 'Paid']}
              className="absolute right-0 bottom-0 -mb-6 z-10"
            />
            <hr className="absolute left-0 right-0 bottom-0 mb-2"/>
          </div>
          {opened && <div>{rows}</div>}
        </div>
        {isSender && !isPaid && isUploaded && !readOnly ? (
          <div className="col-span-1 right-0 absolute">
            <FormikSubmittingButtons
              slim
              setFieldValue={setFieldValue}
              buttons={buttons}
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
            />
          </div>
        ) : (
          <div className="col-span-1 relative mt-1">
            <hr className="absolute left-0 right-0 top-0 mt-4"/>
          </div>
        )}
      </div>
    </div>
  )
}

InvoiceRow.propTypes = {
  order: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSender: PropTypes.bool,
  readOnly: PropTypes.bool,
}

export default memo(InvoiceRow)
