import React, { memo } from 'react'
import { childrenPropType } from 'root/constants'
import PropTypes from 'prop-types'

const Balloon = ({ children, inverted = false, className = '', color = 'red-500', round = true }) => {
  //do not remove: bg-red-500 border-red-500 text-red-500
  return (
    <sup
      className={
        (round ? 'w-5 ' : 'px-2 ') +
        'rounded-full h-5 flex items-center justify-center ml-1 text-xxs ' +
        (inverted ? `bg-white text-${color} border-${color} border-2 font-bold` : `bg-${color} text-white `) +
        className
      }
    >
      {children}
    </sup>
  )
}
Balloon.propTypes = {
  children: childrenPropType,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  color: PropTypes.string,
  round: PropTypes.bool,
}

export default memo(Balloon)
