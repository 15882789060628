import React, { memo, useCallback, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import i18n from 'root/i18n'
import { useEffectOnce } from 'root/hooks'
import Modal from 'root/components/Modal'
import Spinner from 'root/components/Spinner'

import LocationIcon from 'root/icons/LocationIcon'
import DeliveryAddress, { getAddressValidationSchema } from 'root/modules/orders/pages/components/DeliveryAddress'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { getAddress, postAddress } from 'root/api'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'

const schema = Yup.object().shape({
  address: getAddressValidationSchema(),
})

const CompanyAddressModal = () => {
  const { id } = useParams()
  const history = useHistory()
  const isNew = id === 'create'
  const submittingButtons = [{ color: 'brown', label: isNew ? 'common.create' : 'common.save' }]

  const [address, setAddress] = useState(isNew ? {} : undefined)

  useEffectOnce(() => {
    if (!isNew) getAddress(id).then(({ data: { data } }) => setAddress(data))
  })

  const onSubmit = useCallback(
    ({ address }) => {
      postAddress(address).then(({ data: { data } }) => {
        setAddress(data)
        history.replace(`/company/addresses`)
      })
    },
    [history],
  )

  return (
    <Formik enableReinitialize initialValues={{ address }} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, isSubmitting, setFieldValue, handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <Modal isFull confirm={dirty}>
            <div className="profile-my-team-modal flex flex-1 flex-col">
              <div className="profile-my-team items-baseline">
                <LocationIcon />
                <span>{i18n.value('profile.address.title')}</span>
              </div>
              {!values.address ? (
                <div className="flex flex-1 items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <div className="mb-6">
                  <DeliveryAddress address={values.address} isEditable />
                </div>
              )}
              <FormikSubmittingButtons
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                buttons={submittingButtons}
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

CompanyAddressModal.propTypes = {}

export default memo(CompanyAddressModal)
