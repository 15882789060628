import { COLORS } from 'root/constants'

export default {
  remove: {
    borderRadius: 20,
    backgroundColor: COLORS.PEARL,
    padding: 2,
    cursor: 'pointer',
  },
  removeInner: {
    borderRadius: 20,
    padding: 5,
    backgroundColor: COLORS.ASPHALT,
  },
}
