import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { CartIcon, LogoIcon } from 'root/icons'
import i18n from 'root/i18n'
import { SITE_URL } from 'root/api/env'

import './styles.css'
import HeaderUserMenu from './components/HeaderUserMenu'
import connect from './connect'
import LogoMobileIcon from 'root/icons/LogoMobileIcon'
import Balloon from 'root/components/Balloon'
import HeartIcon from 'root/icons/HeartIcon'

const SELLER_TABS = [
  { key: 'products', labelKey: 'stock', baseUrl: '/' },
  { key: 'sales', labelKey: 'sales', baseUrl: '/' },
]

const BUYER_TABS = [
  { key: 'purchases', labelKey: 'purchases', baseUrl: '/' },
  { key: 'memo-agreements', labelKey: 'memoAgreements', baseUrl: '/' },
  { key: 'catalog', labelKey: 'catalog', baseUrl: SITE_URL + '/', external: true },
]

const FRONT_OFFICE_TABS = [
  { key: 'how-it-works', labelKey: 'howItWorks', baseUrl: SITE_URL + '/' },
  { key: 'catalog', labelKey: 'catalog', baseUrl: SITE_URL + '/' },
  // { key: 'education', labelKey: 'education', baseUrl: SITE_URL + '/' },
  { key: 'about', labelKey: 'about', baseUrl: SITE_URL + '/' },
]

const Header = ({ isAuthorized, user, userLogout, notificationSummary }) => {
  const isBackOffice = isAuthorized
  const BACK_OFFICE_TABS = []
  if (isAuthorized) {
    if (user.activated) {
      if (user.companyId?.roles?.isSeller) BACK_OFFICE_TABS.push(...SELLER_TABS)
      BACK_OFFICE_TABS.push(...BUYER_TABS)
    } else {
      BACK_OFFICE_TABS.push({ key: 'company', labelKey: 'activation', baseUrl: '/' })
      BACK_OFFICE_TABS.push({ key: 'catalog', labelKey: 'catalog', baseUrl: SITE_URL + '/', external: true })
    }
  }

  const tabs = isBackOffice ? BACK_OFFICE_TABS : FRONT_OFFICE_TABS

  const TabComponent = isBackOffice ? NavLink : 'a'
  const tabProps = isBackOffice ? { activeClassName: 'header-tab-active' } : undefined

  return (
    <header className="container mx-auto sm:w-screen-lg-min transition duration-200">
      <div className="flex mb:flex-wrap justify-between py-6 border-b border-brown-dim-light">
        <div className="w-24 mb:flex mb:flex-grow mb:justify-center mb:items-center">
          <a href={SITE_URL}>
            <LogoIcon className="mb:hidden" />
            <LogoMobileIcon className="sm:hidden" />
          </a>
        </div>
        <nav className="flex items-center justify-center mb:my-2 mb:flex-grow">
          <ul className="flex mb:flex-wrap mb:justify-around">
            {tabs.map(({ key, baseUrl, labelKey, external }) => (
              <li
                key={key}
                className="mx-2 text-center text-black w-max-content transition duration-200 hover:text-blue-light"
              >
                {external ? (
                  <a href={baseUrl + key} className="px-3 py-1 text-base uppercase flex">
                    {i18n.value(`menu.${labelKey}`)}
                  </a>
                ) : (
                  <TabComponent
                    href={baseUrl + key}
                    to={baseUrl + key}
                    className="relative px-3 py-1 text-base uppercase flex"
                    {...tabProps}
                  >
                    <span>{i18n.value(`menu.${labelKey}`)}</span>
                    <div className="absolute right-0 top-0 -mr-2">
                      {notificationSummary[key]?.all?.all ? (
                        <Balloon className="border-white border-2">{notificationSummary[key].all.all}</Balloon>
                      ) : null}
                    </div>
                  </TabComponent>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex items-center justify-center mb:flex-grow">
          <div className="mr-10 relative inline-flex align-middle items-center justify-center space-x-2 mb:hidden">
            <a
              href={SITE_URL + '/catalog?fav=1'}
              className="cursor-pointer text-blue-light fill-current hover:text-brown-dim transition duration-200"
            >
              <HeartIcon />
            </a>
            <a
              href={SITE_URL + '/cart'}
              className="cursor-pointer text-blue-silver hover:text-blue-dark transition duration-200"
            >
              <CartIcon />
            </a>
          </div>
          <HeaderUserMenu
            notificationSummary={notificationSummary}
            user={user}
            userLogout={userLogout}
            isAuthorized={isAuthorized}
          />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  user: PropTypes.object,
  isAuthorized: PropTypes.bool.isRequired,
  userLogout: PropTypes.func.isRequired,
  notificationSummary: PropTypes.object.isRequired,
}

export default connect(Header)
