import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Seller from './seller'
import Buyer from './buyer'

const Component = ({ asSeller, ...other }) => {
  return asSeller ? <Seller {...other} /> : <Buyer {...other} />
}

Component.propTypes = {
  asSeller: PropTypes.bool,
}

export default memo(Component)
