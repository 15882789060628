import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { ErrorMessage, Form, Formik } from 'formik'
import Input from 'root/modules/activation/pages/Activation/components/Input'
import { COUNTRY_INFO, DEVICE_CODE, errorAlert, toClassName } from 'root/constants'
import BlueButton from 'root/components/Buttons/BlueButton'
import * as Yup from 'yup'
import { activateSecondStep } from 'root/api'
import FormikToggle from 'root/components/FormikToggle'
import BrownButton from 'root/components/Buttons/BrownButton'

const Step = ({ nextStep, goBack, user }) => (
  <div className="mt-12">
    <div className="text-brown-dim text-lg">{i18n.value('activation.labels.tellUsAboutYou')}</div>
    <div className="mt-10">
      <Formik
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required(),
          tin: Yup.string().trim(),
          isSeller: Yup.boolean().required('Please, tell us if you are Seller or Buyer'),
        })}
        onSubmit={(values) => {
          return activateSecondStep(values.name, values.country, values.tin, values.isSeller)
            .then(nextStep)
            .catch(errorAlert)
        }}
        initialValues={{
          name: user?.companyId?.name || '',
          tin: user?.companyId?.taxId || '',
          country: DEVICE_CODE,
          isSeller: undefined,
        }}
      >
        {({ values, isValid, submitCount, setFieldValue, isSubmitting, handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit} className="flex flex-col space-y-20">
            <div className="w-1/2 mb:w-full space-y-10">
              <Input itemKey="name" label="activation.labels.companyName" />
              <Input itemKey="tin" placeholder="Optional" label="activation.labels.tin" />
              <Input itemKey="country" label="activation.labels.country">
                {({ field, textColor, inputClass }) => (
                  <select
                    {...field}
                    className={toClassName([
                      'form-select w-full mb:w-64 text-left  shadow shadow-inset px-2 py-0',
                      inputClass,
                      textColor,
                    ])}
                  >
                    {COUNTRY_INFO.map(({ code, name }) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </select>
                )}
              </Input>
              <div className="flex">
                <span className="text-gray-700 mr-4">{i18n.value('activation.labels.Iam')}</span>
                <FormikToggle itemKey="isSeller" labelOn="common.seller" labelOff="common.buyer" />
              </div>
            </div>
            <div className="mt-32">
              {isValid || submitCount === 0 ? (
                ''
              ) : (
                <section className="w-1/2 mb:w-full my-4 bg-red-300 text-red-600">
                  {Object.keys(errors).map(
                    (key) =>
                      errors[key] && (
                        <div className="flex space-x-4" key={key}>
                          <div>{key}:</div>
                          <ErrorMessage name={key} component="div" />
                        </div>
                      ),
                  )}
                </section>
              )}
              <div className="flex space-x-4 ">
                <BlueButton type="button" label="activation.labels.back" onClick={goBack} />
                <BrownButton disabled={isSubmitting} label="activation.labels.next" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
)
Step.propTypes = {
  nextStep: PropTypes.func,
  goBack: PropTypes.func,
  user: PropTypes.object.isRequired,
}
export default memo(Step)
