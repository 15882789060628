import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ClockIcon from 'root/icons/ClockIcon'
import OrderTimer from 'root/components/OrderTimer'
import i18n from 'root/i18n'
import EmptyFormWithActions from 'root/modules/orders/pages/components/EmptyOrderFormWithActions'

const WaitResponseScreen = ({ order, buttons, performOrderAction, labels }) => {
  return (
    <div className="border border-brown-dim-light shadow container mx-auto flex space-x-4 px-2 py-4 pr-8">
      <div className="p-4 text-brown-dim">
        <ClockIcon width={50} height={50} />
      </div>
      <div className="flex flex-col space-y-2">
        {labels.heading ? (
          <div
            className="text-brown-dim text-lg font-bold pb-2"
            dangerouslySetInnerHTML={{ __html: i18n.value(labels.heading, null, labels.heading) }}
          />
        ) : null}
        {labels.headingBeforeTimer ? (
          <div
            className="text-brown-dim"
            dangerouslySetInnerHTML={{ __html: i18n.value(labels.headingBeforeTimer, null, labels.headingBeforeTimer) }}
          />
        ) : null}
        <div className={'text-brown-dim flex'}>
          {labels.timer ? <label className="mr-2 text-gray-600">{i18n.value(labels.timer)}</label> : ''}
          <OrderTimer timer={order.timer} />
        </div>
        {buttons.length > 0 ? <EmptyFormWithActions buttons={buttons} performOrderAction={performOrderAction} /> : ''}
      </div>
    </div>
  )
}

WaitResponseScreen.propTypes = {
  order: PropTypes.object.isRequired,
  buttons: PropTypes.array.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  labels: PropTypes.any.isRequired,
}

export default memo(WaitResponseScreen)
