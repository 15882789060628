import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import Spinner from 'root/components/Spinner'
import i18n from 'root/i18n'
import { storeLocalFiles } from 'root/constants'

const baseStyle = {
  flex: 1,
  display: 'flex',
  padding: '0',
  outline: 'none',
}

const thumb = {
  width: 300,
  height: 70,
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  margin: 'auto',
}

const UploadAvatar = ({ currentImg, alt, accept, maxFiles = 1, uploadAvatarFunction, isEditing }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt={'preview'} />
      </div>
    </div>
  ))

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files],
  )

  let settings = {
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: maxFiles,
    onDropAccepted: (files) => {
      setIsLoading(true)
      Promise.all(files.map(uploadAvatarFunction))
        .then(storeLocalFiles)
        .finally(() => {
          setIsLoading(false)
        })
    },
  }

  if (accept) {
    settings.accept = accept
  }
  const { getRootProps, getInputProps, open } = useDropzone(settings)
  const style = {
    ...baseStyle,
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isEditing ? (
        <>
          <div {...getRootProps({ style })} onClick={open}>
            <input {...getInputProps()} />
            <div className="cursor-pointer flex items-center">
              {files.length > 0 ? (
                <div>
                  {thumbs}
                  <p className="mt-2 text-blue-skyblue block">
                    {i18n.value('profile.company.labels.changeAvatarHint')}
                  </p>
                </div>
              ) : (
                <div className={'profile-avatar'}>
                  <img src={currentImg} alt={alt} />
                  <p className="mt-2 text-blue-skyblue block">
                    {i18n.value('profile.company.labels.changeAvatarHint')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={'profile-avatar'}>
          <img src={currentImg} alt={alt} />
        </div>
      )}
    </>
  )
}

UploadAvatar.propTypes = {
  currentImg: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  uploadAvatarFunction: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
}

export default memo(UploadAvatar)
