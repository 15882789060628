import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { RemoveIcon, SortIcon } from 'root/icons'
import ProductsListRow from './components/ProductsListRow'
import { ACTION_REMOVE } from 'root/modules/products/constants'
import Spinner from 'root/components/Spinner'
import { toClassName } from 'root/constants'

import './styles.css'
import connect from './connect'

const sortKeys = {
  cert: 'certificate',
  color: 'colorValue',
  location: 'locationCountryId',
}

const getSortKey = (key) => sortKeys[key] ?? key

const headerColumns = ['shape', 'carat', 'color', 'clarity', 'cut', 'certificate', 'price', 'status', 'select']
const catalogHeaderColumns = ['shape', 'carat', 'color', 'clarity', 'cut', 'cert', 'location', 'price']
const catalogUnauthorizedHeaderColumns = ['shape', 'carat', 'color', 'clarity', 'cut', 'cert', 'location']

const columnSizes = ['8%', '8%', '9%', '9%', '6%', '25%', '15%', '15%', '5%']
const catalogColumnSizes = ['10%', '9%', '9%', '10%', '7%', '8%', '33%', '14%']
const catalogUnauthorizedColumnSizes = ['11%', '10%', '10%', '12%', '8%', '10%', '39%']

const TableWithDiamonds = ({ isReloading, products, selectSortBy, onMouseOverFunc, onEndReached, ...props }) => {
  const { selectedProductId, performProductsAction, isCatalog, sortBy, sortOrder, isAuthorized } = props
  const [selectedItems, setSelectedItems] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const hasSelected = Object.keys(selectedItems).length > 0

  const removeItems = useCallback(() => {
    setIsLoading(true)
    performProductsAction(ACTION_REMOVE, { items: Object.keys(selectedItems) }).finally(() => setIsLoading(false))
    setIsLoading(false)
  }, [performProductsAction, selectedItems])

  const onItemCheck = useCallback((itemId) => {
    setSelectedItems((selectedItems) => {
      selectedItems = { ...selectedItems }
      if (!selectedItems[itemId]) selectedItems[itemId] = itemId
      else delete selectedItems[itemId]
      return selectedItems
    })
  }, [])

  const onScroll = useCallback(
    ({ target: { scrollHeight, offsetHeight, scrollTop } }) => {
      const fromEnd = scrollHeight - offsetHeight - scrollTop
      if (fromEnd < 100) onEndReached()
    },
    [onEndReached],
  )

  const columns = !isCatalog ? headerColumns : isAuthorized ? catalogHeaderColumns : catalogUnauthorizedHeaderColumns
  const sizes = !isCatalog ? columnSizes : isAuthorized ? catalogColumnSizes : catalogUnauthorizedColumnSizes

  return (
    <div className="w-full">
      <div className="bg-gray-100 text-gray-500 flex flex-1 flex-row text-center catalog-header mb-4">
        {columns.map((headerKey, index) => {
          const sortKey = getSortKey(headerKey)
          const isSortedBy = sortBy === sortKey
          return (
            <div key={headerKey} style={{ width: sizes[index] }}>
              {headerKey === 'select' ? (
                <div className="text-blue-silver" onClick={hasSelected ? removeItems : undefined}>
                  <RemoveIcon className={toClassName(['inline', hasSelected && 'text-blue-dark cursor-pointer'])} />
                </div>
              ) : (
                <div
                  className={toClassName([
                    'font-normal hover:text-brown-dim cursor-pointer',
                    isSortedBy && !!sortOrder && 'text-brown-dim',
                  ])}
                  onClick={() => selectSortBy(sortKey)}
                >
                  {i18n.value('products.labels.table.header.' + headerKey)}
                  <SortIcon className="inline ml-1" value={isSortedBy ? sortOrder : 0} />
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div onScroll={onScroll} className="flex-grow overflow-x-hidden overflow-y-scroll h-176 catalog-scroll">
        <table className="w-full text-center table-auto">
          <tbody className="text-sm text-blue-dark">
            {isLoading || isReloading ? (
              <tr>
                <td colSpan={columns.length + 1}>
                  <Spinner />
                </td>
              </tr>
            ) : (
              products.map((item) => (
                <ProductsListRow
                  isCatalog={isCatalog}
                  columnSizes={sizes}
                  columns={columns}
                  key={item.id}
                  item={item}
                  isProductSelected={!!selectedItems[item.id]}
                  isRowSelected={selectedProductId === item.id}
                  onMouseOverFunc={onMouseOverFunc}
                  onItemCheck={onItemCheck}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

TableWithDiamonds.propTypes = {
  isReloading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  selectSortBy: PropTypes.func.isRequired,
  onMouseOverFunc: PropTypes.func.isRequired,
  performProductsAction: PropTypes.func.isRequired,
  onEndReached: PropTypes.func.isRequired,
  selectedProductId: PropTypes.string,
  isCatalog: PropTypes.bool,
  isAuthorized: PropTypes.bool.isRequired,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.oneOf([0, 1, 2]),
}

export default memo(connect(TableWithDiamonds))
