import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width={35} height={35} viewBox="0 0 512 512" fill={'#ffffff'} {...props}>
    <path d="M467 150.991H362v-75c0-24.813-20.187-45-45-45H45c-24.813 0-45 20.187-45 45v180c0 24.813 20.187 45 45 45h15v45c0 12.013 13.418 19.081 23.32 12.481L150 314.019v61.972c0 24.813 20.187 45 45 45h147.458l86.221 57.481c9.916 6.611 23.32-.465 23.32-12.481v-45h15c24.813 0 45-20.187 45-45v-180c.001-24.813-20.186-45-44.999-45zM156.638 273.538l-.043.028L90 317.963v-31.972c0-8.284-6.716-15-15-15H45c-8.271 0-15-6.729-15-15v-180c0-8.271 6.729-15 15-15h272c8.271 0 15 6.729 15 15v180c0 8.271-6.729 15-15 15H165c-2.903 0-5.903.898-8.362 2.547zM482 375.991c0 8.271-6.729 15-15 15h-30c-8.284 0-15 6.716-15 15v31.972l-66.68-44.453a14.996 14.996 0 00-8.32-2.519H195c-8.271 0-15-6.729-15-15v-75h137c24.813 0 45-20.187 45-45v-75h105c8.271 0 15 6.729 15 15v180z" />
    <path d="M195 180.991H75c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15zM287 120.991H75c-8.284 0-15 6.716-15 15s6.716 15 15 15h212c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
  </svg>
)

export default memo(SvgComponent)
