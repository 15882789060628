import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ACTION_MEMO_NEW_DOCS_UPLOADED } from 'root/modules/orders/constants'
import UploadDocument from 'root/modules/orders/pages/components/UploadDocument'
import i18n from 'root/i18n'
import { getMemoDocument, uploadMemoDocument } from 'root/api'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import Diamonds, {
  CertificateColumn,
  IndexColumn,
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import MessageBox from 'root/components/MessageBox'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'
import ReadonlyMemoTerms
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ReadonlyMemoTerms'
import OrderTimer from 'root/components/OrderTimer'
import TemplateDoc from 'root/modules/orders/pages/components/TemplateDoc'
import { connect } from 'react-redux'
import { openChat } from 'root/modules/chat/actions'

const NewMemoConfirmed = ({
  order,
  performOrderAction,
  openChat,
  readOnly = false,
}) => {
  const buttons = readOnly
    ? []
    : [
      {
        color: 'brown',
        label: 'orders.buttons.confirmUploadMemo',
        action: ACTION_MEMO_NEW_DOCS_UPLOADED,
        params: {
          memoId: order.memoId.id,
        },
      },
    ]

  const uploadMemo = (file) => uploadMemoDocument(order.memoId.id, file)

  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          buyer={order.buyerCompanyId.name}
          asSeller
        >
          <ViewOrderFiles order={order} doAction={performOrderAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds
          isEditable={false}
          highlightDeleted
          showDeleted={false}
          items={order.products}
          doAction={performOrderAction}
        >
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
        </Diamonds>
      </section>

      <section>
        <ReadonlyMemoTerms order={order}/>
      </section>

      {order.state.key === 'new_memo_docs_refused' ? (
        <section>
          <MessageBox>
            Customer rejected uploaded document. Please, contact him <span
            className="text-brown-dim underline font-bold cursor-pointer"
            onClick={openChat}>via Chat </span>
            and upload new version.
          </MessageBox>
        </section>
      ) : null}

      <section>
        <div className="flex space-x-4">
          <div className="text-brown-dim">
            <DocumentRowIcon width={50} height={50}/>
          </div>
          <div>
            <div className="text-lg text-brown-dim">
              <b>Memo is confirmed by customer</b>
            </div>
            <div
              className="text-brown-dim"
              dangerouslySetInnerHTML={{
                __html: i18n.value('orders.labels.supplierUploadNewMemo'),
              }}
            />
            <TemplateDoc
              label={'orders.labels.lgDealMemoTemplate'}
              onClick={() => {
                getMemoDocument(order.memoId.id)
              }}
            />
            <div className="mt-4">
              {order.timer.isStarted
                ? <OrderTimer timer={order.timer}
                              label={'orders.labels.replyTimer'}/>
                : ''}
            </div>
            {readOnly ? null : (
              <UploadDocument
                accept={'image/*, application/pdf'}
                buttons={buttons}
                uploadDocumentFunction={uploadMemo}
                performOrderAction={performOrderAction}
              />
            )}
          </div>
        </div>
      </section>
    </>
  )
}

NewMemoConfirmed.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  openChat: PropTypes.func,
}

export default memo(connect(null, { openChat })(NewMemoConfirmed))
