import { BASE_DOMAIN } from 'root/api/env'

export const addressFields = [
  'companyName',
  'countryId',
  'addressLine1',
  'addressLine2',
  'cityName',
  'regionName',
  'zipCode',
  'recipientName',
  'countryCode',
  'recipientPhone',
]

export const LGDEAL_COOKIE_INFO = { name: 'D_S', path: '/', domain: BASE_DOMAIN }
export const DEVICE_ID_KEY = 'LGD_UCLID'

export const ROLE_SELLER = 'seller'
export const ROLE_SUPERVISOR = 'supervisor'
export const ROLE_OWNER = 'owner'

// source: https://github.com/dockwa/simple-react-validator/blob/master/src/simple-react-validator.js#L42
export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
