import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const MessageBox = ({ children, className, icon = <ExclaimIcon /> }) => (
  <div className={`flex p-2 text-brown-dim space-x-4 border border-brown-dim-lighter items-center ${className}`}>
    <div className="flex-0">{icon}</div>
    <div>{children}</div>
  </div>
)

MessageBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.node,
}

export default memo(MessageBox)
