import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Diamonds, {
  IndexColumn,
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import {
  StatusColumn,
  TimerColumn,
  EmptyColumn,
} from 'root/modules/orders/pages/Order/components/MemoOrder/components/MemoPaymentAndDelivery/Diamonds'

const AllItemsTab = ({ order, onDiamondClick }) => {
  return (
    <Diamonds isEditable={false} showDeleted={false} items={order.products} className="mt-4">
      <IndexColumn />
      <ShapeColumn />
      <NameColumn />
      <EmptyColumn />
      <EmptyColumn />
      <StatusColumn onClick={onDiamondClick} />
      <TimerColumn timerKey={'memoPeriod'} />
    </Diamonds>
  )
}

AllItemsTab.propTypes = {
  order: PropTypes.object.isRequired,
  onDiamondClick: PropTypes.func.isRequired,
}

export default memo(AllItemsTab)
