import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ProductRequiredLabel from 'root/modules/products/pages/Product/components/ProductRequiredLabel'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import { toClassName } from 'root/constants'

const SelectInput = ({ options = [], label, labelBefore, isRequired = true, ...other }) => {
  return (
    <div>
      <ProductRequiredLabel label={label} isRequired={isRequired} />
      <div className="flex text-brown-dim space-x-2">
        {labelBefore && <div>{labelBefore}</div>}
        <div className="w-full">
          <InputFormik inputClass="w-1/2 ml-0" textColor="text-blue-dark" {...other}>
            {({ field, form, meta, textColor, inputClass }) => (
              <select
                {...field}
                className={toClassName(['form-select text-left  shadow shadow-inset px-2 py-0', inputClass, textColor])}
              >
                {options.map(({ id, text }) => (
                  <option key={id} value={id}>
                    {text}
                  </option>
                ))}
              </select>
            )}
          </InputFormik>
        </div>
      </div>
    </div>
  )
}

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelBefore: PropTypes.string,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default memo(SelectInput)
