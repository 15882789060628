import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import { COLORS, isAuthError } from 'root/constants'

const getEmptyCode = (count) => new Array(count).fill('')

const DIGIT_CODE = /^(Digit|Numpad)\d$/
const DIGIT = /^\d$/

const CodeInput = ({ disabled, count, onValid }) => {
  const [error, setError] = useState(false)
  const [code, setCode] = useState(getEmptyCode(count))
  const inputRefs = useRef(code.map(() => ({ current: null })))

  useEffect(() => {
    setTimeout(() => inputRefs.current[0].current.focus(), 100)
  }, [])

  const goLeft = useCallback((index) => {
    if (index > 0) inputRefs.current[index - 1].current.focus()
  }, [])
  const goRight = useCallback(
    (index) => {
      if (index < count - 1) inputRefs.current[index + 1].current.focus()
      else inputRefs.current[index].current.blur()
    },
    [count],
  )

  const isFilledRef = useRef(false)
  const codeString = code.join('')

  useEffect(() => {
    if (isFilledRef.current) {
      onValid(codeString).catch((e) => {
        setError(true)
        inputRefs.current[0].current.focus()
        if (isAuthError(e)) setCode(getEmptyCode(count))
        else throw e
      })
      isFilledRef.current = false
    }
  }, [codeString, count, onValid])

  const setValue = useCallback((index, newValue) => {
    setError(false)
    setCode((code) => {
      const valueChanged = code[index] !== newValue
      if (valueChanged || (newValue === '' && index > 0)) {
        code = [...code]
        code[valueChanged ? index : index - 1] = newValue
        isFilledRef.current = code.every((digit) => digit.length)
      }
      return code
    })
  }, [])

  const onKeyDown = useCallback(
    (e) => {
      e.preventDefault()
      const { code, key } = e
      const index = parseInt(e.target.getAttribute('datakey'))
      if (code === 'ArrowLeft') goLeft(index)
      else if (code === 'ArrowRight') goRight(index)
      else if (code === 'Backspace') {
        setValue(index, '')
        goLeft(index)
      } else if (code ? DIGIT_CODE.test(code) : DIGIT.test(key)) {
        setValue(index, code ? code.charAt(code.length - 1) : key)
        goRight(index)
      }
    },
    [goLeft, goRight, setValue],
  )

  return (
    <div className="login-code-input-row">
      {inputRefs.current.map((ref, index) => (
        <input
          autoComplete="chrome-off"
          value={code[index]}
          datakey={index}
          key={index}
          ref={ref}
          style={error ? { borderColor: COLORS.CRIMSON } : undefined}
          className="input-form login-code-input-row-tab"
          maxLength={1}
          disabled={disabled}
          type="number"
          onKeyUp={onKeyDown}
        />
      ))}
    </div>
  )
}

CodeInput.propTypes = {
  disabled: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onValid: PropTypes.func.isRequired,
}

export default CodeInput
