import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import { stylePropType, toStyle } from 'root/constants'

const AnimatedCounter = ({ count, style, countStyle }) => (
  <div style={toStyle([styles.badge, style, { opacity: count ? 1 : 0 }])}>
    <div style={toStyle([styles.count, countStyle])}>{count}</div>
  </div>
)

AnimatedCounter.propTypes = {
  count: PropTypes.number,
  style: stylePropType,
  countStyle: stylePropType,
}

export default memo(AnimatedCounter)
