import { connect } from 'react-redux'

import { getUserData, isAuthorized } from 'root/selectors'
import { userLogout } from 'root/thunks'
import { getNotificationsSummary } from 'root/modules/orders/selectors'

const mapStateToProps = (state) => ({
  user: getUserData(state),
  isAuthorized: isAuthorized(state),
  notificationSummary: getNotificationsSummary(state),
})
const mapDispatchToProps = {
  userLogout,
}

export default connect(mapStateToProps, mapDispatchToProps)
