import React, { memo } from 'react'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import i18n from 'root/i18n'

const EmptyRows = () => (
  <div className="container flex-none">
    <div className="mb-6 font-normal text-center text-gray-500">{i18n.value('products.labels.noDiamonds')}</div>
    <div className={'flex flex-col items-center'}>
      <div className="self-center mb-12 text-brown-dim">
        <ExclaimIcon width={50} height={50} />
      </div>
      <div className="w-56 text-sm text-center text-gray-600">
        <p className="">{i18n.value('products.labels.changeRequestOrCreate')}</p>
      </div>
    </div>
  </div>
)

export default memo(EmptyRows)
