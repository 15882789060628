import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="110" height="37" viewBox="0 0 110 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M109.946 31.3171C109.94 34.0019 107.76 36.173 105.077 36.1687L4.8516 35.9666C2.16678 35.9602 -0.0064344 33.7784 1.43147e-05 31.0936L0.0537536 4.84945C0.0580527 2.16678 2.23987 -0.0064344 4.92253 1.43147e-05L105.148 0.202074C107.831 0.208523 110.002 2.38819 109.998 5.073L109.946 31.3171Z"
      fill="black"
    />
    <path
      d="M8.29517 6.92969L8.27152 19.107L8.24573 31.2865L20.552 19.1328L8.29517 6.92969Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M23.8581 15.8678L18.9054 12.996L8.29517 6.8418V6.92993L20.552 19.133L23.8581 15.8678Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M8.24573 31.2865V31.3746L18.8818 25.2612L23.8452 22.4109L20.552 19.1328L8.24573 31.2865Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M23.858 15.8682L20.552 19.1334L23.8451 22.4115L29.5157 19.1506L23.858 15.8682Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M85.5808 24.0254C85.1186 24.0254 84.6995 23.9394 84.319 23.7674C83.9385 23.5955 83.6182 23.3332 83.3603 22.9807H83.2829C83.3345 23.3934 83.3581 23.7868 83.3581 24.1565L83.3517 27.0713L82.2791 27.0692L82.3005 16.8071L83.1733 16.8093L83.3194 17.7787H83.371C83.6483 17.3918 83.9686 17.1124 84.3362 16.9404C84.7016 16.7684 85.1229 16.6825 85.5958 16.6846C86.5352 16.6868 87.2596 17.0092 87.7691 17.6519C88.2785 18.2946 88.5322 19.1953 88.53 20.3539C88.5279 21.5168 88.2656 22.4197 87.7454 23.0645C87.2231 23.7073 86.503 24.0275 85.5808 24.0254ZM85.4368 17.5939C84.7124 17.5917 84.19 17.7916 83.8654 18.1915C83.5409 18.5913 83.3732 19.2297 83.3646 20.1046V20.3432C83.3624 21.3384 83.528 22.0499 83.859 22.4799C84.19 22.9098 84.721 23.1247 85.454 23.1269C86.0666 23.129 86.546 22.8818 86.8942 22.3874C87.2424 21.893 87.4165 21.2095 87.4187 20.3389C87.4208 19.4554 87.2489 18.7783 86.9028 18.3054C86.5546 17.8325 86.0666 17.596 85.4368 17.5939Z"
      fill="#F9F9F9"
    />
    <path d="M91.004 23.9075L89.9314 23.9053L89.9507 13.8496L91.0234 13.8518L91.004 23.9075Z" fill="#F9F9F9" />
    <path
      d="M97.1712 23.9206L96.9606 22.9124H96.909C96.5543 23.3553 96.2018 23.6562 95.8514 23.811C95.501 23.9679 95.0604 24.0453 94.5359 24.0453C93.8329 24.0431 93.2827 23.8626 92.885 23.4993C92.4873 23.136 92.2896 22.6223 92.2896 21.9537C92.2917 20.5243 93.4374 19.7762 95.7246 19.7117L96.9262 19.6752V19.2367C96.9283 18.6799 96.808 18.2694 96.5694 18.005C96.3308 17.7384 95.9481 17.6052 95.4236 17.6052C94.8325 17.603 94.164 17.7836 93.4181 18.1447L93.0892 17.3236C93.4396 17.1344 93.8222 16.9861 94.2371 16.8807C94.6519 16.7733 95.069 16.7217 95.4881 16.7217C96.3329 16.7238 96.9584 16.9108 97.3647 17.287C97.771 17.6632 97.973 18.2651 97.9709 19.0905L97.9601 23.9249L97.1712 23.9206ZM94.7508 23.1596C95.4193 23.1618 95.9438 22.9791 96.3265 22.6137C96.7091 22.2482 96.9004 21.7366 96.9025 21.0767L96.9047 20.4361L95.8321 20.4791C94.9787 20.5071 94.3639 20.6382 93.9856 20.8725C93.6072 21.1068 93.4181 21.4722 93.4181 21.9666C93.4181 22.3536 93.5342 22.6502 93.7685 22.8523C94.0006 23.0565 94.3274 23.1575 94.7508 23.1596Z"
      fill="#F9F9F9"
    />
    <path
      d="M98.6051 16.8398L99.7551 16.842L101.299 20.8853C101.636 21.8075 101.847 22.4739 101.928 22.8844H101.98C102.036 22.6652 102.154 22.289 102.335 21.7559C102.515 21.225 103.102 19.5891 104.099 16.8506L105.249 16.8527L102.188 24.9115C101.885 25.709 101.533 26.2722 101.129 26.6053C100.725 26.9385 100.23 27.1041 99.6455 27.1019C99.3188 27.1019 98.9942 27.0632 98.676 26.9901L98.6782 26.1303C98.9146 26.1819 99.1812 26.2098 99.4735 26.2098C100.211 26.212 100.735 25.7993 101.053 24.9717L101.449 23.9635L98.6051 16.8398Z"
      fill="#F9F9F9"
    />
    <path
      d="M65.7209 17.4311C66.0605 17.7127 66.7699 18.3082 66.7677 19.4345C66.7656 20.5308 66.1422 21.0489 65.5188 21.5347C65.3254 21.726 65.1039 21.9345 65.1018 22.2612C65.1018 22.5858 65.3232 22.7642 65.4866 22.8997L66.0197 23.3145C66.671 23.8648 67.2621 24.37 67.26 25.391C67.2578 26.7839 65.9057 28.1876 63.3585 28.1833C61.2089 28.179 60.175 27.1558 60.1771 26.0574C60.1793 25.5243 60.4458 24.7698 61.3207 24.2517C62.2386 23.6907 63.4853 23.6198 64.1517 23.5746C63.9432 23.3081 63.7089 23.0265 63.7089 22.5665C63.7089 22.315 63.7841 22.1666 63.8572 21.9882C63.6938 22.0033 63.5305 22.0183 63.3821 22.0162C61.8129 22.014 60.9252 20.8404 60.9273 19.686C60.9295 19.0046 61.2412 18.2501 61.8796 17.702C62.7265 17.0077 63.7325 16.8916 64.5343 16.8916L67.5846 16.8981L66.6344 17.429L65.7209 17.4311ZM64.6547 24.0067C64.5365 23.9916 64.4612 23.9916 64.3151 23.9895C64.1818 23.9895 63.38 24.0174 62.7588 24.2238C62.432 24.3399 61.4841 24.6945 61.4819 25.7457C61.4798 26.7968 62.5008 27.5556 64.0872 27.5599C65.5081 27.5621 66.2669 26.8807 66.269 25.9628C66.2669 25.2104 65.7789 24.8106 64.6547 24.0067ZM65.091 21.1929C65.4328 20.8533 65.4629 20.3782 65.4629 20.1117C65.4651 19.0455 64.831 17.3839 63.6035 17.3817C63.2188 17.3817 62.8039 17.573 62.5653 17.8697C62.3138 18.1792 62.2386 18.579 62.2364 18.9659C62.2343 19.959 62.8082 21.6035 64.0829 21.6056C64.4526 21.6078 64.8524 21.4293 65.091 21.1929Z"
      fill="#F9F9F9"
    />
    <path
      d="M56.3811 23.753C54.0295 23.7487 52.7763 21.9108 52.7784 20.2535C52.7827 18.3167 54.3691 16.6637 56.6176 16.668C58.7908 16.6723 60.1493 18.3747 60.145 20.1653C60.1407 21.9108 58.7929 23.7594 56.3811 23.753ZM58.2319 22.5599C58.5887 22.087 58.679 21.4959 58.679 20.9177C58.6812 19.615 58.0664 17.1301 56.2307 17.1258C55.7427 17.1258 55.2548 17.3171 54.8979 17.6267C54.3197 18.1426 54.2144 18.7939 54.2144 19.4302C54.2122 20.894 54.9323 23.3058 56.7208 23.308C57.299 23.3101 57.8923 23.0307 58.2319 22.5599Z"
      fill="#F9F9F9"
    />
    <path
      d="M48.3632 23.7383C46.0116 23.734 44.7584 21.8961 44.7605 20.2388C44.7648 18.3021 46.3512 16.649 48.5996 16.6533C50.7729 16.6576 52.1314 18.3601 52.1271 20.1507C52.1249 21.8961 50.775 23.7426 48.3632 23.7383ZM50.2161 22.5432C50.5729 22.0703 50.6632 21.4791 50.6632 20.9009C50.6654 19.5982 50.0484 17.1133 48.2149 17.109C47.7269 17.109 47.239 17.3003 46.8821 17.6099C46.3039 18.1258 46.1986 18.7771 46.1986 19.4134C46.1964 20.8772 46.9144 23.2891 48.705 23.2912C49.2832 23.2955 49.8743 23.0161 50.2161 22.5432Z"
      fill="#F9F9F9"
    />
    <path
      d="M43.6255 23.4976L41.4974 23.9834C40.6333 24.1167 39.8616 24.2327 39.0426 24.2306C34.9369 24.222 33.3828 21.1997 33.3871 18.8352C33.3935 15.9504 35.6141 13.2785 39.4059 13.2871C40.2098 13.2893 40.9815 13.4097 41.6801 13.6031C42.7958 13.917 43.3138 14.306 43.6406 14.5296L42.4046 15.7011L41.8844 15.8193L42.2562 15.226C41.7511 14.7338 40.8311 13.8267 39.077 13.8224C36.7275 13.8181 34.9541 15.6001 34.9477 18.201C34.9412 20.9955 36.9597 23.633 40.2012 23.6395C41.1535 23.6416 41.6436 23.4503 42.0907 23.2719L42.095 20.8773L39.8487 20.9912L41.0396 20.3528L44.193 20.3592L43.8061 20.7289C43.7007 20.8192 43.6857 20.8493 43.6556 20.9654C43.6406 21.0987 43.6255 21.5307 43.6255 21.6791V23.4976Z"
      fill="#F9F9F9"
    />
    <path
      d="M70.4091 23.1211C69.919 23.076 69.8158 22.9857 69.8158 22.4053V22.242L69.833 13.9898C69.8351 13.9575 69.8373 13.9253 69.8416 13.893C69.9018 13.3728 70.0523 13.2847 70.5144 13.016L68.3863 13.0117L67.2686 13.5448L68.4057 13.547V13.5534V13.547L68.3885 22.2355V22.7278C68.3885 23.0244 68.3283 23.0696 67.9865 23.5145L70.6197 23.5188L71.17 23.1942C70.9142 23.1663 70.6627 23.1512 70.4091 23.1211Z"
      fill="#F9F9F9"
    />
    <path
      d="M76.4602 23.2716C76.299 23.3598 76.1356 23.4629 75.9744 23.536C75.4865 23.7574 74.9835 23.8155 74.5407 23.8133C74.0699 23.8133 73.3305 23.7811 72.5781 23.2329C71.5313 22.4913 71.0756 21.2209 71.0799 20.1139C71.0842 17.8246 72.9478 16.7068 74.4676 16.709C74.9985 16.709 75.5467 16.8444 75.9895 17.126C76.7268 17.614 76.9159 18.2502 77.0191 18.5899L73.5476 19.9871L72.4104 20.0731C72.7759 21.9496 74.0441 23.0438 75.4456 23.0459C76.198 23.0481 76.7461 22.7837 77.2491 22.5343L76.4602 23.2716ZM75.0802 18.8672C75.3596 18.764 75.508 18.6758 75.508 18.4695C75.5101 17.8784 74.8459 17.1991 74.0484 17.1969C73.4573 17.1948 72.3503 17.6505 72.346 19.2455C72.346 19.497 72.3739 19.7614 72.3889 20.0279L75.0802 18.8672Z"
      fill="#F9F9F9"
    />
    <path
      d="M77.8983 16.8188L77.8962 17.7926H77.7779L77.7801 16.8188H77.4576V16.7178L78.2207 16.7199V16.821L77.8983 16.8188Z"
      fill="#F9F9F9"
    />
    <path
      d="M79.2246 17.7947L79.2268 16.8102H79.2203L78.9172 17.7926H78.8248L78.5238 16.8081H78.5195L78.5174 17.7926H78.4099L78.4121 16.7178H78.5969L78.8699 17.5883H78.8742L79.1451 16.7199H79.3321L79.3299 17.7947H79.2246Z"
      fill="#F9F9F9"
    />
    <path
      d="M36.36 10.3846L35.6205 10.3824C35.6205 10.2814 35.5517 9.99337 35.412 9.51832L34.3458 9.51617C34.1996 9.98263 34.1266 10.2707 34.1266 10.3803L33.4323 10.3781C33.4323 10.318 33.6128 9.82571 33.9739 8.90139C34.3351 7.97708 34.5156 7.46763 34.5156 7.37305L35.3884 7.3752C35.3884 7.46333 35.5496 7.96633 35.8742 8.88205C36.1987 9.80206 36.36 10.3008 36.36 10.3846ZM35.2959 9.07766C35.0337 8.28232 34.9026 7.8524 34.9047 7.78791H34.8682C34.8682 7.8481 34.7306 8.27802 34.4576 9.07551L35.2959 9.07766Z"
      fill="#FCFCFD"
    />
    <path
      d="M39.3372 10.3908L38.7676 10.3887C38.7676 10.3264 38.5569 9.99102 38.1356 9.387C37.6928 8.75072 37.4413 8.31436 37.3811 8.08006H37.3445C37.3832 8.41754 37.4004 8.72278 37.4004 8.99362C37.4004 9.3741 37.409 9.83626 37.4305 10.3844L36.863 10.3822C36.8867 9.90504 36.8996 9.38055 36.9017 8.80876C36.9039 8.24772 36.8931 7.76837 36.8695 7.375L37.5552 7.37715C37.5552 7.45883 37.7444 7.78557 38.1227 8.3552C38.5268 8.96138 38.759 9.37195 38.817 9.58691H38.8514C38.8148 9.24297 38.7977 8.93559 38.7977 8.66259C38.7977 8.24557 38.7891 7.81781 38.7676 7.3793L39.3458 7.38145C39.3243 7.76407 39.3114 8.24128 39.3114 8.81521C39.3071 9.39559 39.3157 9.92009 39.3372 10.3908Z"
      fill="#FCFCFD"
    />
    <path
      d="M42.6883 8.78981C42.6883 9.22617 42.5465 9.6088 42.2649 9.93338C41.9833 10.2601 41.592 10.4213 41.0869 10.4213C40.859 10.4213 40.5452 10.4106 40.1454 10.3912C40.1669 9.92693 40.1776 9.40244 40.1776 8.81775C40.1798 8.25672 40.169 7.77951 40.1497 7.38399H40.5538C40.6204 7.38399 40.7193 7.38184 40.8504 7.37754C40.9816 7.37324 41.0676 7.37109 41.1062 7.37109C41.6608 7.37324 42.065 7.51082 42.3143 7.78811C42.5637 8.0697 42.6883 8.40289 42.6883 8.78981ZM42.0564 8.88869C42.0564 8.5942 41.9704 8.34485 41.7984 8.14064C41.6264 7.93858 41.3642 7.8354 41.0095 7.8354C40.9429 7.8354 40.8526 7.84185 40.7365 7.85475C40.7516 8.16214 40.758 8.48457 40.758 8.8199C40.758 9.18748 40.7645 9.55291 40.7795 9.91404C40.8805 9.93338 40.9794 9.94413 41.0805 9.94413C41.4115 9.94413 41.6565 9.8431 41.8156 9.63674C41.9768 9.43468 42.0564 9.18318 42.0564 8.88869Z"
      fill="#FCFCFD"
    />
    <path
      d="M45.7707 10.4035L45.0872 10.4014C44.9087 9.85541 44.7733 9.52007 44.6787 9.3911C44.5842 9.26213 44.4294 9.19979 44.2166 9.19764C44.1263 9.19764 44.0425 9.19764 43.9608 9.19979C43.9608 9.59531 43.9651 9.99513 43.9801 10.3992L43.3374 10.3971C43.3589 9.93279 43.3696 9.4083 43.3696 8.82361C43.3718 8.26258 43.361 7.78537 43.3417 7.38985H43.7415C43.7867 7.38985 43.8834 7.3877 44.0317 7.3834C44.1779 7.3791 44.3155 7.37695 44.4401 7.37695C45.1473 7.3791 45.4999 7.61985 45.4977 8.10351C45.4977 8.48613 45.2957 8.75268 44.8915 8.90315V8.93754C45.0463 8.98053 45.1839 9.10306 45.3 9.30297C45.416 9.50718 45.573 9.8726 45.7707 10.4035ZM44.9087 8.27762C44.9087 7.97238 44.7131 7.81761 44.3176 7.81761C44.1736 7.81761 44.0446 7.82836 43.935 7.84986C43.9522 8.08846 43.9586 8.40015 43.9586 8.78277C44.0425 8.78707 44.1155 8.78707 44.1736 8.78707C44.6637 8.78707 44.9087 8.61725 44.9087 8.27762Z"
      fill="#FCFCFD"
    />
    <path
      d="M49.0833 8.85178C49.0833 9.32468 48.9371 9.71591 48.6469 10.0254C48.3567 10.335 47.9977 10.4898 47.5721 10.4898C47.1658 10.4898 46.8262 10.3457 46.5532 10.0598C46.2802 9.77394 46.1427 9.41067 46.1448 8.97C46.1448 8.4971 46.291 8.10588 46.5812 7.79634C46.8714 7.4868 47.2303 7.33203 47.6559 7.33203C48.0622 7.33203 48.4018 7.47605 48.6748 7.76195C48.9478 8.04569 49.0833 8.40897 49.0833 8.85178ZM48.4448 8.91626C48.4448 8.59383 48.3632 8.33373 48.1998 8.13382C48.0364 7.93391 47.8322 7.83288 47.5915 7.83288C47.3658 7.83288 47.1766 7.93176 47.0175 8.13382C46.8606 8.33588 46.7811 8.58953 46.7811 8.89692C46.7811 9.2172 46.8628 9.4773 47.0283 9.67936C47.1938 9.88142 47.3958 9.98245 47.6366 9.98245C47.8623 9.98245 48.0515 9.88142 48.2105 9.67936C48.3653 9.4773 48.4448 9.22365 48.4448 8.91626Z"
      fill="#FCFCFD"
    />
    <path
      d="M50.3859 10.4127L49.7324 10.4106C49.7539 9.93337 49.7647 9.40887 49.7647 8.83708C49.7668 8.27605 49.7561 7.79669 49.7367 7.40332L50.3902 7.40547C50.3687 7.79239 50.3579 8.27175 50.3579 8.83923C50.3579 9.42177 50.3665 9.94411 50.3859 10.4127Z"
      fill="#FCFCFD"
    />
    <path
      d="M53.7351 8.81325C53.7351 9.24961 53.5932 9.63223 53.3116 9.95682C53.03 10.2836 52.6388 10.4448 52.1337 10.4448C51.9058 10.4448 51.592 10.434 51.1921 10.4147C51.2136 9.95037 51.2244 9.42588 51.2244 8.84119C51.2265 8.28015 51.2158 7.80295 51.1964 7.40743H51.6006C51.6672 7.40743 51.7661 7.40528 51.8972 7.40098C52.0283 7.39668 52.1143 7.39453 52.153 7.39453C52.7076 7.39668 53.1117 7.53425 53.3611 7.81155C53.6126 8.09099 53.7372 8.42418 53.7351 8.81325ZM53.1053 8.90998C53.1053 8.61334 53.0193 8.36614 52.8473 8.16193C52.6753 7.95987 52.4131 7.85669 52.0584 7.85669C51.9918 7.85669 51.9015 7.86314 51.7854 7.87603C51.8005 8.18342 51.8069 8.50586 51.8069 8.84119C51.8069 9.20877 51.8134 9.5742 51.8284 9.93532C51.9294 9.95467 52.0283 9.96542 52.1294 9.96542C52.4604 9.96542 52.7054 9.86439 52.8645 9.65803C53.0257 9.45597 53.1053 9.20662 53.1053 8.90998Z"
      fill="#FCFCFD"
    />
    <path
      d="M58.1717 10.4295L57.4323 10.4274C57.4323 10.3263 57.3635 10.0383 57.2238 9.56324L56.1576 9.56109C56.0114 10.0276 55.9383 10.3156 55.9383 10.4252L55.244 10.4231C55.244 10.3629 55.4246 9.87063 55.7857 8.94632C56.1468 8.022 56.3274 7.51255 56.3274 7.41797L57.2001 7.42012C57.2001 7.50825 57.3613 8.01125 57.6859 8.92697C58.0105 9.84484 58.1739 10.3457 58.1717 10.4295ZM57.1077 9.12258C56.8455 8.32724 56.7143 7.89732 56.7143 7.83284H56.6778C56.6778 7.89302 56.5402 8.32294 56.2672 9.12043L57.1077 9.12258Z"
      fill="#FCFCFD"
    />
    <path
      d="M60.8715 8.21507C60.8715 8.58265 60.734 8.85349 60.461 9.02976C60.188 9.20387 59.8526 9.29201 59.455 9.29201C59.3819 9.29201 59.3303 9.28986 59.3002 9.28771C59.3002 9.5779 59.3067 9.95837 59.3239 10.4334L58.6747 10.4313C58.6962 9.98632 58.7069 9.46182 58.7069 8.85779C58.7091 8.3032 58.6983 7.826 58.679 7.42403H59.081C59.1368 7.42403 59.2465 7.42188 59.4098 7.41758C59.5711 7.41328 59.7108 7.41113 59.8312 7.41113C60.1235 7.41113 60.3707 7.47777 60.5728 7.61104C60.7705 7.74432 60.8715 7.94423 60.8715 8.21507ZM60.274 8.33975C60.274 8.01086 60.0569 7.84535 59.6183 7.84535C59.5087 7.84535 59.3926 7.85609 59.2723 7.87974C59.2895 8.16993 59.2959 8.50096 59.2959 8.86854C59.3346 8.87284 59.3797 8.87284 59.4292 8.87284C59.9924 8.87284 60.274 8.69658 60.274 8.33975Z"
      fill="#FCFCFD"
    />
    <path
      d="M63.6401 8.21995C63.6401 8.58753 63.5025 8.85838 63.2295 9.03464C62.9565 9.20876 62.6212 9.29689 62.2235 9.29689C62.1504 9.29689 62.0989 9.29474 62.0688 9.29259C62.0688 9.58278 62.0752 9.96325 62.0924 10.4383L61.4432 10.4362C61.4647 9.9912 61.4755 9.4667 61.4755 8.86268C61.4776 8.30809 61.4669 7.83088 61.4475 7.42891H61.8495C61.9054 7.42891 62.015 7.42676 62.1784 7.42246C62.3396 7.41816 62.4793 7.41602 62.5997 7.41602C62.892 7.41602 63.1392 7.48265 63.3413 7.61593C63.5412 7.7492 63.6401 7.95126 63.6401 8.21995ZM63.0447 8.34678C63.0447 8.0179 62.8276 7.85238 62.389 7.85238C62.2794 7.85238 62.1633 7.86313 62.043 7.88677C62.0602 8.17696 62.0666 8.508 62.0666 8.87557C62.1053 8.87987 62.1504 8.87987 62.1999 8.87987C62.7631 8.87987 63.0425 8.70146 63.0447 8.34678Z"
      fill="#FCFCFD"
    />
    <path
      d="M68.1671 8.89084C68.1671 9.36375 68.021 9.75497 67.7308 10.0645C67.4406 10.374 67.0816 10.5288 66.656 10.5288C66.2497 10.5288 65.9101 10.3848 65.6371 10.0989C65.3641 9.81301 65.2265 9.44973 65.2287 9.00907C65.2287 8.53616 65.3748 8.14494 65.665 7.8354C65.9552 7.52586 66.3142 7.37109 66.7398 7.37109C67.1461 7.37109 67.4857 7.51511 67.7587 7.80101C68.0317 8.08475 68.1671 8.44803 68.1671 8.89084ZM67.5287 8.95533C67.5287 8.63289 67.447 8.37279 67.2837 8.17288C67.1203 7.97297 66.9161 7.87194 66.6753 7.87194C66.4496 7.87194 66.2605 7.97082 66.1014 8.17288C65.9445 8.37494 65.8649 8.62859 65.8649 8.93598C65.8649 9.25627 65.9466 9.51637 66.1121 9.71842C66.2777 9.92048 66.4797 10.0215 66.7205 10.0215C66.9462 10.0215 67.1353 9.92048 67.2944 9.71842C67.4492 9.51637 67.5287 9.26272 67.5287 8.95533Z"
      fill="#FCFCFD"
    />
    <path
      d="M71.2928 10.4553L70.7231 10.4532C70.7231 10.3908 70.5125 10.0555 70.0912 9.45145C69.6483 8.81518 69.3968 8.37882 69.3367 8.14451H69.3001C69.3388 8.482 69.356 8.78723 69.356 9.05808C69.356 9.43855 69.3646 9.90071 69.3861 10.4489L68.8186 10.4467C68.8422 9.9695 68.8551 9.445 68.8573 8.87322C68.8594 8.31218 68.8487 7.83282 68.8251 7.43945L69.5108 7.4416C69.5108 7.52329 69.6999 7.85002 70.0783 8.41966C70.4824 9.02584 70.7145 9.43641 70.7726 9.65136H70.807C70.7704 9.30743 70.7532 9.00004 70.7532 8.72705C70.7532 8.31003 70.7446 7.88226 70.7231 7.44375L71.3014 7.4459C71.2799 7.82853 71.267 8.30573 71.267 8.87967C71.2627 9.4622 71.2734 9.98455 71.2928 10.4553Z"
      fill="#FCFCFD"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="14.4362"
        y1="6.9427"
        x2="14.387"
        y2="31.2979"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2079B0" />
        <stop offset="0.2863" stopColor="#4A93B8" />
        <stop offset="0.767" stopColor="#83BEBF" />
        <stop offset="1" stopColor="#97CFBF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="8.28339"
        y1="12.9751"
        x2="23.8633"
        y2="13.0066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5ABCAB" />
        <stop offset="1" stopColor="#E6E99A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="16.0609"
        y1="19.1238"
        x2="16.0361"
        y2="31.3898"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E53432" />
        <stop offset="0.1668" stopColor="#D73948" />
        <stop offset="0.5748" stopColor="#B33F72" />
        <stop offset="0.8619" stopColor="#9A4189" />
        <stop offset="1" stopColor="#904192" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="25.0409"
        y1="15.8715"
        x2="25.0277"
        y2="22.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F18779" />
        <stop offset="0.1194" stopColor="#F2917A" />
        <stop offset="0.7128" stopColor="#FABC77" />
        <stop offset="1" stopColor="#FDCB72" />
      </linearGradient>
    </defs>
  </svg>
)

export default memo(SvgComponent)
