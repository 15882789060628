import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { SHIPMENT_PROVIDERS } from 'root/modules/orders/constants'
import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import i18n from 'root/i18n'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import CheckboxInput from 'root/modules/login/pages/Login/components/CheckboxInput'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import RadioGroupFormik from 'root/modules/orders/pages/components/RadioGroup/formik'
import ShipmentItem from './components/ShipmentItem'

const ShipmentItems = ({
  isSender,
  order,
  action,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  values,
  valuesKey,
  readOnly,
}) => {
  const [isSelectAllChecked, setSelectAll] = useState(true)
  const buttons =
    isSender && !readOnly ? [{ color: 'brown', label: 'orders.buttons.createShipment', action: action }] : []

  const itemsToShip = order.metaActions[action]

  const shipmentProviders = SHIPMENT_PROVIDERS.map((provider) => ({
    key: provider,
    label: i18n.value('orders.labels.shipmentProvider.' + provider),
  }))

  return (
    Object.keys(itemsToShip).length > 0 && (
      <div className={'my-8'}>
        <ToggleableSection
          classNameLine="text-blue-skyblue"
          labelTop={'order.diamonds.notShipped'}
          labelInside={'common.empty'}
        >
          {Object.entries(itemsToShip).map(([id, { hasStone, hasCertificate }]) => {
            const product = order.products.find((item) => item.id === id)
            return (
              <ShipmentItem
                key={id}
                hasStone={hasStone}
                hasCertificate={hasCertificate}
                isSender={isSender}
                product={product}
                values={values}
                valuesKey={valuesKey}
              />
            )
          })}
          {isSender && (
            <div className="mt-8">
              <div className="my-3">
                <CheckboxInput
                  value={isSelectAllChecked}
                  labelBefore={i18n.value('common.selectAll')}
                  id={'toggleSelectAll'}
                  onChange={() => {
                    Object.values(itemsToShip).forEach(({ itemId, hasStone, hasCertificate }) => {
                      if (hasStone) setFieldValue(`${valuesKey}[${itemId}].hasStone`, !isSelectAllChecked)
                      if (hasCertificate) setFieldValue(`${valuesKey}[${itemId}].hasCertificate`, !isSelectAllChecked)
                    })
                    setSelectAll(!isSelectAllChecked)
                  }}
                />
              </div>
              <div className="my-3 flex items-center text-brown-dim">
                <InputTermFormik
                  labelBefore={'orders.labels.trackingNumber'}
                  labelBeforeClass={'text-brown-dim'}
                  itemKey={'trackingNumber'}
                  isEditable
                  value={values.trackingNumber}
                  inputClass={'w-64'}
                />
              </div>
              <div className="my-5 text-brown-dim flex items-center">
                <RadioGroupFormik
                  value={values.shipmentProvider}
                  itemKey={'shipmentProvider'}
                  options={shipmentProviders}
                />
                {values.shipmentProvider === 'other' && (
                  <InputTermFormik itemKey={'shipmentProviderInput'} value={values.shipmentProviderInput} />
                )}
              </div>
              {!readOnly ? (
                <div className="my-3">
                  <FormikSubmittingButtons
                    buttons={buttons}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                  />
                </div>
              ) : null}
            </div>
          )}
        </ToggleableSection>
      </div>
    )
  )
}

ShipmentItems.propTypes = {
  order: PropTypes.object.isRequired,
  isSender: PropTypes.bool,
  values: PropTypes.object.isRequired,
  valuesKey: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(ShipmentItems)
