import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = {}

export default handleActions(
  {
    [actions.loadInvoiceTermsStart]: (state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadInvoiceTermsSuccess]: (state, { payload: invoiceTerms }) => {
      return invoiceTerms
    },
  },
  defaultState,
)
