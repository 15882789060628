import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { ROLE_OWNER, ROLE_SUPERVISOR } from 'root/constants'
import { changeOrderManager, getOrderManagers } from 'root/api'
import { FINAL_STATES } from '../../../../../constants'
import i18n from '../../../../../../../i18n'

const Responsible = ({ isSeller, responsible, user, order, loadOrder }) => {
  const [managers, setManagers] = useState([])
  const orderId = order.id

  const changeManager = useCallback(
    async (id) => {
      if (id) {
        await changeOrderManager(orderId, id)
        await loadOrder(null, true)
      }
    },
    [loadOrder, orderId],
  )

  useEffect(() => {
    async function fetchManagers() {
      const response = await getOrderManagers()
      const managers = response.data.data.map((m) => ({
        name: [m.firstName, m.lastName].join(' ') + ` (${i18n.value('common.role.' + m.mainRole)})`,
        id: m.id,
      }))
      setManagers(managers)
    }

    fetchManagers()
  }, [])

  if (!isSeller) {
    return null
  }

  const canChangeResponsible =
    !FINAL_STATES.includes(order.state.key) &&
    (user.mainRole === ROLE_OWNER ||
      //(user.mainRole === ROLE_SUPERVISOR && !responsible?.id) ||
      //(user.mainRole === ROLE_SUPERVISOR && responsible?.id === user.id) ||
      //(user.mainRole === ROLE_SUPERVISOR && responsible?.mainRole === ROLE_SELLER)
      user.mainRole === ROLE_SUPERVISOR)

  return (
    <div>
      {canChangeResponsible && managers ? (
        <select
          value={responsible?.id}
          onChange={(e) => changeManager(e.target.value)}
          className="form-select text-sm text-left  shadow shadow-inset px-2 pr-8 py-0 ml-0 text-brown-dim"
        >
          <option>Select Responsible</option>
          {managers.map((m) => (
            <option key={m.id} value={m.id}>
              {m.name}
            </option>
          ))}
        </select>
      ) : (
        <div className="text-sm text-brown-dim">{responsible?.name}</div>
      )}
    </div>
  )
}

Responsible.propTypes = {
  isSeller: PropTypes.bool.isRequired,
  responsible: PropTypes.object,
  user: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  loadOrder: PropTypes.func.isRequired,
}

export default memo(connect(Responsible))
