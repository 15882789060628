import React, { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SelectedRowItem from './components/SelectedRowItem'
import TableWithDiamonds from './components/TableWithDiamonds'
import EmptyRows from './components/EmptyRows'

const ProductsListTable = ({ isReloading, products, performProductsAction, selectSortBy, loadData, ...props }) => {
  const { sortBy, sortOrder, isCatalog } = props
  const [selectedProductId, setSelectedProductId] = useState()

  const selectedProduct = useMemo(() => {
    if (selectedProductId) return products.find(({ id }) => id === selectedProductId)
  }, [products, selectedProductId])

  return (
    <>
      <div className="relative py-2 mt-6 text-sm font-normal">
        <div className="absolute w-full h-8 bg-gray-100 -z-1" />
        <div className="container relative flex mx-auto mt-1">
          {products.length || isReloading ? (
            <>
              <TableWithDiamonds
                sortBy={sortBy}
                sortOrder={sortOrder}
                isCatalog={isCatalog}
                isReloading={isReloading}
                onEndReached={loadData}
                selectSortBy={selectSortBy}
                products={products}
                onMouseOverFunc={setSelectedProductId}
                selectedProductId={selectedProductId}
                performProductsAction={performProductsAction}
              />
              <SelectedRowItem
                isCatalog={isCatalog}
                product={selectedProduct}
                performProductsAction={performProductsAction}
              />
            </>
          ) : (
            <EmptyRows />
          )}
        </div>
      </div>
    </>
  )
}

ProductsListTable.propTypes = {
  isReloading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  performProductsAction: PropTypes.func.isRequired,
  selectSortBy: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  isCatalog: PropTypes.bool,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.oneOf([0, 1, 2]),
}

export default memo(ProductsListTable)
