import React, { memo } from 'react'
import i18n from 'root/i18n'
import PropTypes from 'prop-types'
import { childrenPropType } from 'root/constants'

const SubSection = ({ label, Icon, children }) => (
  <section>
    <div className="flex space-x-8 mb-4">
      <i className="w-10">
        <Icon />
      </i>
      <div className="text-brown-late font-damascus text-3xl">{i18n.value(label)}</div>
    </div>
    {children}
  </section>
)

SubSection.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  children: childrenPropType,
}

export default memo(SubSection)
