import instance from './instance'
import {
  transformAddresses,
  transformChatManagers,
  transformDocuments,
  transformNotificationSummary,
  transformOrderChat,
  transformOrderChatMessage,
  transformOrderMemos,
  transformOrders,
  transformProducts,
} from 'root/api/transformers'

export const postOrders = (data) => {
  return instance({
    method: 'post',
    url: '/orders',
    data,
    auth: true,
    transformResponse: transformOrders,
  })
}

export const payOrder = (uid) => {
  return instance({
    method: 'post',
    url: `/orders/${uid}/pay-stripe`,
    auth: true,
  })
}

export const getOrderManagers = () => {
  return instance({
    method: 'get',
    url: '/managers',
  })
}

export const changeOrderManager = (orderId, managerId) => {
  return instance({
    method: 'post',
    url: `/orders/${orderId}/change-manager`,
    data: {
      userId: managerId,
    },
    auth: true,
    transformResponse: transformOrders,
  })
}

export const getOrders = (section, p, q, filters, cancelToken) => {
  const autocomplete = p === undefined
  return instance({
    method: 'get',
    url: `/orders/${section}`,
    params: {
      p,
      q,
      attr: autocomplete ? 'name' : undefined, ...filters,
      ipp: 20,
    },
    auth: true,
    transformResponse: autocomplete ? undefined : transformOrders,
    cancelToken,
  })
}

export const getOrderNotificationsSummary = (userId) => {
  return instance({
    method: 'get',
    url: `/orders/user-notifications-summary/${userId}`,
    auth: true,
    transformResponse: transformNotificationSummary,
  })
}

export const getInitialTerms = (uid) => {
  return instance({
    method: 'get',
    url: `/orders/${uid}/initial-terms`,
    auth: true,
    transformResponse: transformOrders,
  })
}
export const getOrderMemos = (uid) => {
  return instance({
    method: 'get',
    url: `/orders/${uid}/memo`,
    auth: true,
    transformResponse: transformOrderMemos,
  })
}
export const findDiamondByCert = (certificate) => {
  return instance({
    method: 'get',
    url: `/products?ir=0&q=${certificate}`,
    auth: true,
    transformResponse: transformProducts,
  })
}
export const getOrder = (uid) => {
  return instance({
    method: 'get',
    url: `/orders/${uid}`,
    auth: true,
    transformResponse: transformOrders,
  })
}
export const getOrderInitialTerms = (uid) => {
  return instance({
    method: 'get',
    url: `/orders/${uid}/initial-terms`,
    auth: true,
    transformResponse: transformOrders,
  })
}

export const getDocument = (documentUid, format = '') => {
  return instance({
    method: 'get',
    coreServer: true,
    downloadFile: true,
    url: `/orders-documents/${documentUid}/download${format
      ? '/' + format
      : ''}`,
    auth: true,
  })
}

export const downloadKycFile = (orderId) => {
  return instance({
    method: 'get',
    downloadFile: true,
    url: `/orders/${orderId}/download-kyc`,
    auth: true,
  })
}

export const getDocumentFile = (documentId, fileId) => {
  return instance({
    method: 'get',
    coreServer: true,
    downloadFile: true,
    url: `/orders-documents/${documentId}/file/${fileId}`,
    auth: true,
  })
}

export const getOrderFile = (orderId, fileId) => {
  return instance({
    method: 'get',
    downloadFile: true,
    url: `/orders/${orderId}/file/${fileId}`,
    auth: true,
  })
}

export const getMemoFile = (memoId, fileId) => {
  return instance({
    method: 'get',
    coreServer: true,
    downloadFile: true,
    url: `/memo/${memoId}/file/${fileId}`,
    auth: true,
  })
}

export const documentAction = (uid, type, action, data) => {
  return instance({
    method: 'post',
    url: `/orders/${uid}/documents/${type}/${action}`,
    data,
    auth: true,
    transformResponse: transformDocuments,
  })
}

export const orderAction = (uid, action, data) => {
  return instance({
    method: 'post',
    url: `/orders/${uid}/${action}`,
    data,
    auth: true,
    transformResponse: transformOrders,
  })
}

export const postOrderMessage = (uid, text, images, videos, documents) => {
  return instance({
    method: 'post',
    url: `/order-chat/${uid}/messages`,
    data: { text, videos, images, documents },
    auth: true,
    coreServer: true,
    transformResponse: transformOrderChatMessage,
  })
}

export const uploadOrderChatImage = (uid, file) => {
  return instance({
    method: 'post',
    url: `/order-chat/${uid}/upload-image`,
    upload: file,
    coreServer: true,
    auth: true,
  })
}

export const uploadOrderChatVideo = (uid, file) => {
  return instance({
    method: 'post',
    url: `/order-chat/${uid}/upload-video`,
    upload: file,
    coreServer: true,
    auth: true,
  })
}

export const uploadOrderChatDocument = (uid, file) => {
  return instance({
    method: 'post',
    url: `/order-chat/${uid}/upload-document`,
    upload: file,
    coreServer: true,
    auth: true,
  })
}

export const setOrderChatLastRead = (uid, before) => {
  return instance({
    method: 'post',
    url: `/order-chat/${uid}/read`,
    data: { before },
    coreServer: true,
    auth: true,
  })
}

export const getOrderMessages = (uid) => {
  return instance({
    method: 'get',
    url: `/order-chat/${uid}`,
    auth: true,
    coreServer: true,
    transformResponse: transformOrderChat,
  })
}

export const getOrderRecipients = () => {
  return instance({
    method: 'get',
    url: '/orders/users',
    auth: true,
    transformResponse: transformChatManagers,
  })
}

export const getAddresses = () => {
  return instance({
    method: 'get',
    url: '/addresses',
    auth: true,
    coreServer: true,
    transformResponse: transformAddresses,
  })
}

export const getAddress = (uid) => {
  return instance({
    method: 'get',
    url: `/addresses/${uid}`,
    auth: true,
    coreServer: true,
    transformResponse: transformAddresses,
  })
}

export const postAddress = (data) => {
  return instance({
    method: 'post',
    url: '/addresses/save',
    data,
    auth: true,
    coreServer: true,
    transformResponse: transformAddresses,
  })
}

export const removeAddress = (uid) => {
  return instance({
    method: 'post',
    url: '/addresses/delete',
    params: { id: uid },
    auth: true,
    coreServer: true,
  })
}

export const removeOrderItems = (uid, items) => {
  return instance({
    method: 'post',
    url: `/orders/${uid}/remove-product`,
    data: { items },
    auth: true,
    transformResponse: transformOrders,
  })
}

export const uploadOrderDocument = (uid, file) => {
  return instance({
    method: 'post',
    url: `/orders-documents/${uid}/upload`,
    auth: true,
    upload: file,
  })
}

export const saveOrderFile = (uid, originalName, fileName) => {
  return instance({
    method: 'post',
    url: `/orders/${uid}/file`,
    auth: true,
    data: { originalName, fileName },
  })
}

export const uploadOrderFile = (uid, file) => {
  return instance({
    method: 'post',
    url: `/orders/${uid}/upload`,
    auth: true,
    upload: file,
  })
}

export const uploadMemoDocument = (uid, file) => {
  return instance({
    method: 'post',
    coreServer: true,
    url: `/memo/${uid}/upload`,
    auth: true,
    upload: file,
  })
}

export const saveOrderDocument = (uid, originalName, fileName) => {
  return instance({
    method: 'post',
    url: `/orders-documents/${uid}/file`,
    auth: true,
    data: { originalName, fileName },
  })
}
