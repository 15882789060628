import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ProfileIcon, TriangleDownIcon, CompanyProfileIcon, LogOutIcon, CogIcon } from 'root/icons'

import SignInButton from 'root/components/SignInButton'
import { NavLink, useLocation } from 'react-router-dom'
import i18n from 'root/i18n'
import { useToggle } from 'root/hooks'
import { ensureBoolean } from 'root/constants'

const HeaderUserMenu = ({ isAuthorized, user, userLogout }) => {
  const [menuOpen, toggleMenuOpen] = useToggle()
  const { pathname } = useLocation()
  const isLoginActive = pathname !== '/signup'

  const onLogout = useCallback(() => {
    toggleMenuOpen()
    setTimeout(userLogout)
  }, [toggleMenuOpen, userLogout])

  if (!isAuthorized)
    return (
      <div className="flex items-center space-x-4">
        <SignInButton active={isLoginActive} />
        <SignInButton to="signup" text="Sign up" active={!isLoginActive} />
      </div>
    )

  const menuItems = [
    ...(ensureBoolean(user.activated)
      ? [
          { key: 'company', Icon: CompanyProfileIcon },
          // eslint-disable-next-line react/display-name
          { key: 'password', Icon: () => <CogIcon width={12} height={12} /> },
        ]
      : []),
    { key: 'logout', Icon: LogOutIcon, onClick: onLogout },
  ]

  return (
    <div className="flex items-center ml-2">
      <div className="relative">
        <div className="flex items-center cursor-pointer select-none" onClick={toggleMenuOpen}>
          {user.firstName && (
            <span className="flex items-center mr-6 text-sm">
              <span className="text-gray-500">{'Hi,'}</span>
              <span className="ml-1 text-brown-dim">{user.firstName}</span>
            </span>
          )}
          <span className="flex items-center transition duration-200">
            <i className="text-brown-dim">
              <ProfileIcon />
            </i>
            <TriangleDownIcon className="ml-1" style={{ transform: menuOpen && 'rotate(180deg)' }} />
          </span>
        </div>
        {menuOpen && (
          <div className="header-menu-modal z-50">
            {menuItems.map(({ key, Icon, onClick }) => {
              const Component = onClick ? 'div' : NavLink
              const props = onClick ? {} : { to: `/${key}`, activeClassName: 'icon-active' }
              return (
                <Component
                  key={key}
                  {...props}
                  onClick={onClick ?? toggleMenuOpen}
                  className="header-menu-modal-option icon-inactive hover:text-blue-300"
                >
                  <Icon />
                  <span className="header-menu-modal-option-text">{i18n.value(`menu.${key}`)}</span>
                </Component>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
HeaderUserMenu.propTypes = {
  user: PropTypes.object,
  isAuthorized: PropTypes.bool.isRequired,
  userLogout: PropTypes.func.isRequired,
}

export default HeaderUserMenu
