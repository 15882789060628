import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'
import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DealPaymentIcon from 'root/icons/DealPaymentIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import i18n from 'root/i18n'
import { getDocumentFile } from 'root/api'
import { DOCUMENT_TYPE_INVOICE, STATE_INVOICE_CANCELED, STATE_INVOICE_CREATED } from 'root/modules/orders/constants'

const CardDealPayment = ({ help, order, beforeDelivery, children }) => {
  const twoPayments = order.paymentBeforeDelivery && order.paymentAfterDelivery
  const onePayment = !twoPayments
  const invoice = order.documents.find((document) => document.type === DOCUMENT_TYPE_INVOICE)
  const first = onePayment || beforeDelivery
  const paymentPart = twoPayments && (first ? order.paymentBeforeDelivery : order.paymentAfterDelivery) + '%'
  const invoicePaidDate = first ? invoice?.specification.paymentReceived : invoice?.specification.paymentReceivedAgain
  const invoicePaid = invoicePaidDate > 0
  const delivered = order.allDelivered > 0
  const invoiceFile = invoice?.files[0]
  const invoiceIssuedDate = invoiceFile?.created
  const invoiceIssued = invoiceIssuedDate > 0
  const paidWithStripe = first ? invoice.specification.paidWithStripe1 : invoice.specification.paidWithStripe2
  const isCurrent =
    order.state.key !== STATE_INVOICE_CANCELED &&
    order.state.key !== STATE_INVOICE_CREATED &&
    invoiceIssued &&
    !invoicePaid &&
    ((beforeDelivery && !delivered) || (!beforeDelivery && delivered))
  const data = { invoice, invoiceIssued, paidWithStripe, first, isCurrent, onePayment, twoPayments }
  return (
    <Card
      subheader={paymentPart}
      help={help}
      label="order.card.dealPayment"
      Icon={DealPaymentIcon}
      current={isCurrent}
      completed={invoicePaid}
    >
      <CardRow label="order.card.invoiceIssued">
        {invoiceIssued ? <Date value={invoiceIssuedDate} /> : children && isCurrent && children(data)}
      </CardRow>
      <CardRow label="order.card.paymentReceived">
        {invoicePaid || !invoiceIssued ? <Date value={invoicePaidDate} /> : children && isCurrent && children(data)}
      </CardRow>
      {invoiceFile && (
        <CardRow full>
          <button
            type="button"
            onClick={() => {
              getDocumentFile(invoice.id, invoiceFile.id)
            }}
            className="text-brown-dim"
          >
            {i18n.value('order.card.viewInvoice')}
          </button>
        </CardRow>
      )}
    </Card>
  )
}
CardDealPayment.propTypes = {
  order: PropTypes.object.isRequired,
  beforeDelivery: PropTypes.bool.isRequired,
  help: PropTypes.string,
  children: PropTypes.func,
}
export default memo(CardDealPayment)
