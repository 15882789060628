import * as Sentry from '@sentry/react'
import createSentryMiddleware from 'redux-sentry-middleware'

let crashlyticsEnabled = false

export const setCrashStrings = (config) => {
  if (crashlyticsEnabled) Object.entries(config).forEach((entry) => Sentry.setTag(...entry))
}

export const setCrashUserInfo = ({ id, username }) => {
  if (crashlyticsEnabled) Sentry.setUser({ id, username })
}

export const enableCrashCollection = () => {
  crashlyticsEnabled = true
  Sentry.init({ dsn: 'https://aaf26b684649404abac160c2a4182abd@o443159.ingest.sentry.io/5432847' })
}

export const sentryMiddleware = createSentryMiddleware(Sentry)
