import i18n from 'root/i18n'
import moment from 'moment/min/moment-with-locales'
import { CURRENCY_CODES } from './currency-codes'
import numeral from 'numeral'
import axios from 'axios'
import React from 'react'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { DEVICE_ID_KEY } from 'root/constants/app'
import { v4 as uuid } from 'uuid'
import { infoAlert } from '../components/AlertWrapper'

export const getNameScoreFunc = (value) => (name) => name.toLowerCase().
  indexOf(value)

export const getNewCancelToken = () => axios.CancelToken.source()
export const ensureBoolean = (value) => Boolean(
  typeof value == 'string' ? Number.parseInt(value) : value)
export const getResponse = (e) => e?.response
export const getResponseData = (e) => getResponse(e)?.data
export const getStatusCode = (e) => getResponse(e)?.status
export const getErrorMessage = (e) => getResponseData(e)?.message
export const getErrors = (e) => (Array.isArray(getResponseData(e)?.errors)
  ? getResponseData(e)?.errors
  : [] || [])
export const getErrorsString = (e) => {
  const errors = []
  const message = getErrorMessage(e)
  if (message) {
    if (Array.isArray(message)) errors.push(
      ...message.map(({ message }) => message))
    else errors.push(message)
  }
  errors.push(...getErrors(e).map(({ errorMessage }) => errorMessage))
  return errors.join('\n') || e?.message
}
export const isAuthError = (e) => [401, 403].includes(getStatusCode(e))
export const isUpgradeError = (e) => getStatusCode(e) === 426

export const percents = (value) => `${value}%`

export const roundPrice = (value) => parseFloat(value.toFixed(3))

const digitsWithDotRegexp = /^\d+\.0*$/

export const emptyOrNumber = (text) => {
  text = text.toString().replace(',', '.')
  return text === '' || digitsWithDotRegexp.test(text) ? text : parseFloat(
    text) || 0
}

export const inputToInt = (text) => Math.abs(parseInt(text)) || 0
export const formatPriceValue = (value) => numeral(value).format('0,000.[00]')
export const formatPrice = (currency, value) => `${formatPriceValue(
  value)} ${CURRENCY_CODES[currency]}`

const digitRegexp = /\d/
export const isDigit = (key) => digitRegexp.test(key)

export const clamp = (num, { min = num, max = num } = {}) => (num < min
  ? min
  : num > max ? max : num)

const emailRegexp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const validateEmail = (email) => emailRegexp.test(email.toLowerCase())

export const dateToDateString = (date) => date.toISOString().split('T')[0]

export const isSameDay = (time1, time2) => timestampToDate(time1) ===
  timestampToDate(time2)

export const getMessageTime = (created) => {
  const date = moment.unix(created)
  let format = 'D.MM.YY'

  const isSameYear = date.year() === moment().year()
  if (isSameYear) {
    const daysBetween = moment().dayOfYear() - date.dayOfYear()
    format = !daysBetween ? 'H:mm' : daysBetween < 7 ? 'ddd' : 'D.MM'
  }
  return date.format(format)
}

export const timestampToDateString = (seconds, hideTime) => {
  const date = moment.unix(seconds)
  const showYear = date.format('Y') !== moment().format('Y')
  return date.format(`MMM D${showYear ? ' Y' : ''}${hideTime ? '' : ', H:mm'}`)
}
export const timestampToDate = (seconds) => moment.unix(seconds).
  format('MMM DD, Y')
export const timestampToTime = (seconds) => moment.unix(seconds).format('H:mm')
export const isToday = (seconds) => moment.unix(seconds).
  isSame(new Date(), 'day')

const secondsInMinute = 60
export const secondsInHour = 60 * secondsInMinute
export const secondsInDay = 24 * secondsInHour

export const timestampToCountdown = (seconds) => {
  // TODO translate this
  seconds -= Math.floor(new Date().getTime() / 1000)
  if (seconds > 0) {
    const days = Math.floor(seconds / secondsInDay) || 0
    const hours = Math.floor((seconds % secondsInDay) / secondsInHour) || 0
    const minutes = Math.floor((seconds % secondsInHour) / secondsInMinute)

    const daysPart = days ? `${days} days` : ''
    const hoursPart = days || hours ? `${hours} hrs` : ''
    const minutesPart = minutes ? `${minutes} mins` : ''
    const secondsPart = !hoursPart ? `${seconds % secondsInMinute} secs` : ''
    return `${daysPart} ${hoursPart} ${minutesPart} ${secondsPart}`
  }
}

const cancelToken = 'Cancel'

const alert = (title, message, buttons) => {
  const confirmed = window.confirm(message)
  const button = buttons?.find(
    ({ onPress, style }) => (confirmed ? onPress : style === 'cancel' &&
      onPress))
  if (button) button.onPress()
}

export const errorAlert = async (e) => {
  if (e === cancelToken) return
  if (axios.isAxiosError(e)) {
    const response = e?.response
    if (response) {
      const data = JSON.parse(await response.data.text())
      return infoAlert(data.message)
    }
  }
  const message = typeof e === 'string' ? e : getErrorsString(e) ||
    i18n.value('login.error.occurred')
  return infoAlert(message)
}

export const reconnectAlert = (message, cancellable = false) =>
  new Promise((resolve, reject) => {
    const buttons = [{ text: i18n.value('login.reconnect'), onPress: resolve }]
    if (cancellable)
      buttons.unshift({
        text: i18n.value('login.cancel'),
        onPress: () => reject(cancelToken),
        style: 'cancel',
      })
    message = message || i18n.value('login.noNetworkText')
    alert(i18n.value('login.error'), message, buttons, { cancellable })
  })

export const doOrDie = async (getTaskPromise, rejectCondition, cancellable) => {
  //TODO: please, someone do here the right thing for not having infinite connection loop...and waiting between attempts is needed 1 sec
  //TODO: and while waiting some global spinner or something...
  let attempts = 0
  while (attempts < 5) {
    attempts++
    try {
      return await getTaskPromise()
    } catch (e) {
      if (rejectCondition?.(e)) throw e
      await reconnectAlert(getErrorsString(e), cancellable)
    }
  }
}
export const confirmAlert = (message, onPress) => {
  alert(i18n.value('login.confirm'), message, [
    { text: i18n.value('login.cancel'), style: 'cancel' },
    { text: i18n.value('login.confirm'), onPress },
  ])
}

export const doNothing = () => undefined

export const deleteArrayIndex = (array, index) => {
  array = [...array]
  array.splice(index, 1)
  return array
}

export const textToComponents = (globalRegexp, group, MatchComponent, text) => {
  const result = []
  let lastIndex = 0
  while (true) {
    const match = globalRegexp.exec(text)
    if (!match) break
    if (match.index !== lastIndex)
      result.push(<span key={result.length}>{text.substring(lastIndex,
        match.index)}</span>)

    result.push(<MatchComponent
      key={result.length}>{match[group]}</MatchComponent>)
    lastIndex = match.index + match[group].length
  }
  if (!result.length) return text
  if (lastIndex !== text.length) result.push(<span
    key={result.length}>{text.substring(lastIndex)}</span>)

  return result
}

export const isValidPhone = (countryCode, phone) => {
  if (countryCode && phone.length > 1) {
    try {
      return parsePhoneNumberWithError(phone, countryCode).isValid()
    } catch (e) {
      console.error(e)
    }
  }
  return false
}

export const calcTotal = (items) => {
  const totalByCurrency = {}
  items.forEach(({ currency, value, price, disabled }) => {
    if (disabled) value = price = 0
    if (totalByCurrency[currency] === undefined) totalByCurrency[currency] = 0
    totalByCurrency[currency] += price || value
  })
  const currencies = Object.keys(totalByCurrency).sort()
  return currencies.map(
    (currency) => ({ currency, value: roundPrice(totalByCurrency[currency]) }))
}

export const storeLocalFiles = (responses) => {
  localStorage.files = JSON.stringify(
    responses.map((response) => ({
      originalName: response.data.data.originalName,
      fileName: response.data.data.fileName,
    })),
  )
}

export const getLocalFiles = () => (localStorage.files ? JSON.parse(
  localStorage.files) : [])
export const clearLocalFiles = () => delete localStorage.files

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0
}

export const deleteCookie = ({ name, path = '', domain = '' }) => {
  if (cookieExists(name))
    document.cookie = `${name}=;path=${path};domain=${domain};expires=Thu, 01 Jan 1970 00:00:00 UTC;`
}

export const cookieExists = (name) => document.cookie.split(';').
  some((c) => c.trim().startsWith(`${name}=`))

export const getDeviceId = () => {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY)
  if (!deviceId) {
    deviceId = uuid()
    localStorage.setItem(DEVICE_ID_KEY, deviceId)
  }
  return deviceId
}

export const getValidate = (schema) => (value) =>
  schema.validate(value).then(() => undefined).catch((err) => err.errors)

const formatterByCurrency = {}

export const getPriceFormatter = (currency) => {
  if (!formatterByCurrency[currency])
    formatterByCurrency[currency] = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  return formatterByCurrency[currency]
}

export const formatNumberToPrice = (number, formatter) => {
  return formatter.formatToParts(number).map(({ type, value }) => {
    switch (type) {
      case 'currency':
        return `${value} `
      default:
        return value
    }
  }).reduce((string, part) => string + part)
}

export const stopPropagation = (e) => e.stopPropagation()

export const toClassName = (classNames) => classNames.filter((cls) => cls).
  join(' ')
export const toStyle = (styles) => styles.reduce(
  (acc, style) => (style ? Object.assign(acc, style) : acc), {})
export const handleChange =
  (setValue) =>
    ({ target }) => {
      const checkbox = target.type === 'checkbox'
      return setValue?.(target[checkbox ? 'checked' : 'value'])
    }

export const trimNumber = (input) =>
  input.trim().replace(/[^0-9,.]/g, '').replace(',', '.')

export const trimInteger = (input) => trimNumber(input).
  replace('.', '').
  replace(',', '')
