import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import MemoAgreementListRow from './components/MemoAgreementListRow'
import EmptyResult from 'root/components/EmptyResult'

const headerColumns = ['date', 'number', 'seller', 'buyer', 'status', 'timer', 'manager']

const MemoAgreementListTable = ({ memoAgreements }) => {
  return (
    <>
      <table className="w-full text-center table-auto">
        <thead className="sticky top-0 text-gray-500 z-1">
          <tr className="bg-gray-100">
            {headerColumns.map((headerKey) => {
              return (
                <th key={headerKey} className="font-normal hover:text-brown-dim cursor-pointer">
                  {i18n.value('memoAgreements.labels.table.header.' + headerKey)}
                </th>
              )
            })}
          </tr>
          <tr>
            <td colSpan={headerColumns.length} className="my-1">
              &nbsp;
            </td>
          </tr>
        </thead>
        <tbody className="text-sm text-blue-dark">
          {memoAgreements.length > 0 ? (
            memoAgreements.map((item) => <MemoAgreementListRow key={item.id} item={item} />)
          ) : (
            <tr>
              <td colSpan={7}>
                <EmptyResult />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

MemoAgreementListTable.propTypes = {
  memoAgreements: PropTypes.array.isRequired,
}

export default memo(MemoAgreementListTable)
