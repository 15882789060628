import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import './styles.css'
import connect from './connect'
import Chat from './components/Chat'
import { useEffectOnce } from 'root/hooks'
import { COLORS, errorAlert } from 'root/constants'
import {
  getOrderMessages,
  postOrderMessage,
  setOrderChatLastRead,
  uploadOrderChatDocument,
  uploadOrderChatImage,
  uploadOrderChatVideo,
} from 'root/api'
import { ChatIcon } from 'root/icons'
import AnimatedCounter from './components/Chat/components/AnimatedCounter'

const OrderChat = ({ companyName, orderId, userId, opened, toggleIsOpen }) => {
  const [{ events: messages, unreadCount, lastRead }, setChatData] = useState({})

  const loadMessages = useCallback(() => {
    return getOrderMessages(orderId)
      .then(({ data: { data } }) => {
        if (!data) {
          return
        }
        const lastReadByOtherCompany = data.participants.reduce((acc, participant) => {
          if (participant.userId.id !== userId && participant.lastRead > acc) return participant.lastRead
          else return acc
        }, 0)
        setChatData({ ...data, lastReadByOtherCompany })
      })
      .catch(errorAlert)
  }, [orderId, userId])

  const sendMessage = useCallback(
    (message, images = []) => {
      return postOrderMessage(
        orderId,
        message,
        images.filter((v) => v.type === 'image'),
        images.filter((v) => v.type === 'video'),
        images.filter((v) => v.type !== 'video' && v.type !== 'image'),
      )
        .then(({ data: { data } }) => {
          setChatData((chatData) => ({ ...chatData, events: [data, ...chatData.events] }))
          return [data.id]
        })
        .catch(errorAlert)
    },
    [orderId],
  )

  useEffectOnce(() => {
    loadMessages()
    const interval = setInterval(loadMessages, 10000)
    return () => clearInterval(interval)
  })

  const setLastRead = useCallback(
    (lastRead) => {
      return setOrderChatLastRead(orderId, lastRead)
        .then((data) => {
          setChatData((chatData) => {
            let unreadCount = 0
            for (const message of chatData.events) {
              if (message.created <= lastRead) break
              if (message.userId !== userId) unreadCount++
            }
            return { ...chatData, lastRead, unreadCount }
          })
        })
        .catch(errorAlert)
    },
    [orderId, userId],
  )
  const uploadImage = useCallback((file) => uploadOrderChatImage(orderId, file), [orderId])
  const uploadVideo = useCallback((file) => uploadOrderChatVideo(orderId, file), [orderId])
  const uploadDocument = useCallback((file) => uploadOrderChatDocument(orderId, file), [orderId])

  return opened ? (
    <div className="chat-container">
      <div className="chat-body">
        <Chat
          headerProps={{ name: companyName, onClose: toggleIsOpen }}
          messages={messages}
          lastRead={lastRead}
          setLastRead={setLastRead}
          unreadCount={unreadCount}
          uploadImage={uploadImage}
          uploadVideo={uploadVideo}
          uploadDocument={uploadDocument}
          loadedAllBefore
          hasNext={messages === undefined}
          sendMessage={sendMessage}
          loadMessages={loadMessages}
        />
      </div>
    </div>
  ) : (
    <div onClick={toggleIsOpen} className="chat-button">
      <ChatIcon />
      <AnimatedCounter
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: COLORS.RED500,
          border: '2px solid white',
          transform: 'scale(1.3)',
        }}
        count={unreadCount}
      />
    </div>
  )
}

OrderChat.propTypes = {
  companyName: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  toggleIsOpen: PropTypes.func,
  opened: PropTypes.bool,
}

export default memo(connect(OrderChat))
