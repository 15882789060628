import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import OrderSubHeader from 'root/components/OrderSubHeader'
import Diamonds, {
  CertificateColumn,
  DeliveryColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import i18n from 'root/i18n'
import BrownButton from 'root/components/Buttons/BrownButton'
import { useAsyncLoad } from 'root/hooks'
import {
  ACTION_CANCEL,
  ACTION_CONFIRM_CURRENT_TERMS,
  ACTION_START_WITH_INITIAL_TERMS,
  calcTotals,
  STATE_NEGOTIATION_FINAL,
} from 'root/modules/orders/constants'
import BlueButton from 'root/components/Buttons/BlueButton'
import PaymentTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/PaymentTermsSection'
import DeliveryPrice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/DeliveryPrice'
import ReturnTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/ReturnTermsSection'
import SpecialTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/SpecialTermsSection'
import AddressSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/AddressSection'
import InitialTermsButton from '../components/InitialTermsButton'
import AverageR
  from 'root/modules/orders/pages/Order/components/components/AverageR'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import { postOrderMessage } from 'root/api'
import {
  confirmAlert,
  infoAlert,
  promptAlert,
} from 'root/components/AlertWrapper'
import {
  doNothing,
  ensureBoolean,
  formatNumberToPrice,
  getPriceFormatter,
  toClassName,
} from 'root/constants'
import { SITE_URL } from 'root/api/env'
import MessageBox from 'root/components/MessageBox'
import OrderTimer from 'root/components/OrderTimer'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const NegotiationBuyer = ({ order, doAction, showInitialTerms, openChat }) => {
  const finalTermsAreMade = order.state.key === STATE_NEGOTIATION_FINAL
  const [loading, submitAction] = useAsyncLoad(doAction)
  const canConfirm = order.actions.some(
    (action) => action.url === ACTION_CONFIRM_CURRENT_TERMS)
  const requestChanges = openChat
  const confirmOrder = useCallback(
    (e) => {
      e.stopPropagation()
      return confirmAlert(i18n.value('order.labels.doYouConfirmCurrentTerms')).
        then(() => {
          submitAction(ACTION_CONFIRM_CURRENT_TERMS)
        }).
        catch(doNothing)
    },
    [submitAction],
  )
  const canStartWithInitialTerms = order.actions.some(
    (action) => action.url === ACTION_START_WITH_INITIAL_TERMS)
  const startWithInitialTerms = useCallback(
    (e) => {
      e.stopPropagation()
      return confirmAlert(i18n.value('order.labels.confirmInitialTerms')).
        then(() => {
          submitAction(ACTION_START_WITH_INITIAL_TERMS)
        }).
        catch(doNothing)
    },
    [submitAction],
  )
  const canCancel = order.actions.some((action) => action.url === ACTION_CANCEL)
  const cancelOrder = useCallback(async () => {
    try {
      const reason = await promptAlert(
        i18n.value('order.labels.provideReasonForCancel'))
      if (reason) {
        submitAction(ACTION_CANCEL, { reason })
      }
    } catch (e) {
      doNothing()
    }
  }, [submitAction])
  const [canRequestMemo, setCanRequestMemo] = useState(true)
  const requestMemo = useCallback(() => {
    return postOrderMessage(order.id, i18n.value('orders.requestMemoMessage')).
      then(() => {
        setCanRequestMemo(false)
        openChat()
        return infoAlert(i18n.value('orders.requestMemoSuccess'))
      })
  }, [openChat, order.id])

  const { total, subtotalAdditional } = calcTotals(order)
  const additionalGoods = order.additionalGoods
  const hasAdditionalGoods = additionalGoods && additionalGoods.length > 0
  const canRequestMelee = ensureBoolean(order.sellerCompanyId.saleMelee)
  const canRequestNotCert = ensureBoolean(order.sellerCompanyId.saleNotCert)
  const canRequestRelated = ensureBoolean(order.sellerCompanyId.saleRelated)
  const canRequestAdditionalItems = canRequestMelee || canRequestNotCert ||
    canRequestRelated

  const requestMelee = useCallback(() => {
    return postOrderMessage(order.id, i18n.value('orders.requestMeleeMessage')).
      then(() => {
        openChat()
        return infoAlert(i18n.value('orders.requestMeleeSuccess'))
      })
  }, [openChat, order.id])
  const requestNotCert = useCallback(() => {
    return postOrderMessage(order.id,
      i18n.value('orders.requestNotCertMessage')).then(() => {
      openChat()
      return infoAlert(i18n.value('orders.requestNotCertSuccess'))
    })
  }, [openChat, order.id])
  const requestRelated = useCallback(() => {
    return postOrderMessage(order.id,
      i18n.value('orders.requestRelatedMessage')).then(() => {
      openChat()
      return infoAlert(i18n.value('orders.requestRelatedSuccess'))
    })
  }, [openChat, order.id])

  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms"/>
      </section>

      <section>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          seller={order.sellerCompanyId.name}
          termsChanges={order.termsChanges}
          asSeller={false}
        >
          <ViewOrderFiles order={order} doAction={doAction}/>
        </GeneralTerms>
        {canRequestMemo && (
          <section className="flex">
            <div className="ml-auto flex space-x-2">
              <span
                onClick={requestMemo}
                className="text-blue-skyblue cursor-pointer hover:underline flex items-center"
              >
                {i18n.value('orders.buttons.requestMemo')}
              </span>
            </div>
          </section>
        )}
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds isEditable={false} showDeleted={false} items={order.products}>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
          <RefPriceColumn/>
          <RapaportColumn/>
          <PriceColumn/>
          <DeliveryColumn/>
        </Diamonds>
        <div className="flex mt-4">
          <BrownButton
            type="button"
            onClick={() => {
              window.open(
                `${SITE_URL}/catalog?s=${order.sellerCompanyId.catalogId}`)
            }}
            slim
            className="bg-brown-dim-light"
          >
            {i18n.value('view_stones_from_supplier')}
          </BrownButton>
          <div className="flex flex-grow justify-between">
            <div className="flex w-7/12">
              <AverageR items={order.products} isEditable={false}/>
            </div>
            <div className="flex w-5/12 pl-20">
              <DeliveryPrice isEditable={false} order={order}/>
            </div>
          </div>
        </div>
      </section>

      {canRequestAdditionalItems && (
        <section>
          {canRequestNotCert ? (
            <div>
              <span onClick={requestNotCert}
                    className="text-blue-skyblue cursor-pointer hover:underline">
                {i18n.value('orders.buttons.requestNotCert')}
              </span>
            </div>
          ) : null}
          {canRequestMelee ? (
            <div>
              <span onClick={requestMelee}
                    className="text-blue-skyblue cursor-pointer hover:underline">
                {i18n.value('orders.buttons.requestMelee')}
              </span>
            </div>
          ) : null}
          {canRequestRelated ? (
            <div>
              <span onClick={requestRelated}
                    className="text-blue-skyblue cursor-pointer hover:underline">
                {i18n.value('orders.buttons.requestRelated')}
              </span>
            </div>
          ) : null}
        </section>
      )}

      {hasAdditionalGoods ? (
        <section>
          <OrderSubHeader label="order.labels.additionalItems"
                          className="mb-4"/>
          <Diamonds isEditable={false} showDeleted={false}
                    items={order.additionalGoods}>
            <IndexColumn/>
            <NameColumn label="order.diamonds.goodsName" grow/>
            <PriceColumn itemKey="additionalGoods"/>
          </Diamonds>
          <div className="flex mt-4">
            <div className="ml-auto mr-6">
              <div className="flex">
                <div className="text-right">
                  <div className={toClassName(['space-x-2', 'text-gray-500'])}>
                    <span>{i18n.value('order.labels.subTotal')}</span>
                  </div>
                  <div className={toClassName(
                    ['space-x-2 font-bold', 'text-brown-dim'])}>
                    <span>{i18n.value('order.labels.total')}</span>
                  </div>
                </div>
                <div className="ml-10">
                  <div className={toClassName(
                    ['flex space-x-2', 'text-brown-dim'])}>
                    <span className="text-brown-dim">
                      {formatNumberToPrice(subtotalAdditional,
                        getPriceFormatter('usd'))}
                    </span>
                  </div>

                  <div className={toClassName(
                    ['flex space-x-2 font-bold', 'text-brown-dim'])}>
                    <span className="text-brown-dim">{formatNumberToPrice(total,
                      getPriceFormatter('usd'))}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <section>
        <PaymentTermsSection isEditable={false} values={order}/>
      </section>

      <section>
        <ReturnTermsSection isEditable={false} values={order}/>
      </section>

      {order.specialTerms && (
        <section>
          <SpecialTermsSection isEditable={false} values={order}/>
        </section>
      )}

      <section>
        <AddressSection isOpened={false} values={order}/>
      </section>

      <section>
        {finalTermsAreMade ? (
          <MessageBox icon={<ExclaimIcon width={50} height={50}/>}>
            <OrderSubHeader label="order.labels.dealRequestStatus"/>
            <div className="text-gray-500 font-bold">{i18n.value(
              'order.labels.thisIsFinalTerms')}</div>
            {order.timer.isStarted && (
              <div className="mt-2">
                <OrderTimer timer={order.timer}
                            label={'orders.labels.replyTimer'}/>
              </div>
            )}
          </MessageBox>
        ) : (
          <MessageBox className="font-bold">
            <span className="text-gray-500 ">{i18n.value(
              'order.labels.requestChangesViaChat')}</span>
            <span
              className="text-brown-dim hover:underline hover:font-bold cursor-pointer"
              onClick={requestChanges}>
              {i18n.value('order.labels.requestChangesViaChatButton')}
            </span>
          </MessageBox>
        )}
      </section>

      <section className="flex items-center">
        {canConfirm && (
          <BrownButton
            className="mr-4"
            disabled={loading}
            onClick={confirmOrder}
            type="button"
            label="orders.buttons.confirmCurrentTerms"
          />
        )}
        {canCancel && (
          <BlueButton
            className="ml-4"
            disabled={loading}
            onClick={cancelOrder}
            type="button"
            label="orders.buttons.cancelDeal"
          />
        )}
        {canStartWithInitialTerms && (
          <div className="ml-auto flex space-x-4">
            <InitialTermsButton onClick={showInitialTerms}/>
            <button
              type="button"
              onClick={startWithInitialTerms}
              className="cursor-pointer flex items-center justify-center text-blue-skyblue text-lg font-medium"
              disabled={loading}
            >
              <span className="ml-4">{i18n.value(
                'orders.buttons.startWithInitialTerms')}</span>
            </button>
          </div>
        )}
      </section>
    </>
  )
}

NegotiationBuyer.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired,
  showInitialTerms: PropTypes.func.isRequired,
}

export default memo(connect(NegotiationBuyer))
