import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'
import { toClassName } from 'root/constants'
import i18n from 'root/i18n'

const LoginTabs = ({ changeType, isPhone }) => (
  <div className="login-tabs unselectable">
    <div
      className={toClassName(['login-tab', isPhone ? 'login-tab-active' : 'login-tab-inactive'])}
      onClick={() => changeType(true)}
    >
      {i18n.value('login.phone')}
    </div>
    <div
      className={toClassName(['login-tab', !isPhone ? 'login-tab-active' : 'login-tab-inactive'])}
      onClick={() => changeType(false)}
    >
      {i18n.value('login.email')}
    </div>
  </div>
)

LoginTabs.propTypes = {
  changeType: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
}

export default LoginTabs
