import { COLORS } from 'root/constants'

export default {
  row: {
    display: 'flex',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  container: {
    position: 'relative',
    marginTop: 5,
    borderRadius: 4,
    borderColor: COLORS.ASH,
    backgroundColor: COLORS.PEARL,
    borderWidth: 1,
    maxWidth: '80%',
  },
  owned: {
    backgroundColor: COLORS.THISTLE,
  },
  flex: {
    flex: 1,
  },
  username: {
    padding: '0 10px',
    paddingTop: 5,
    fontSize: 12,
    color: COLORS.ORCHID,
  },
  timeContainer: {
    position: 'absolute',
    bottom: 5,
    right: 10,
  },
  timeContainerOnImage: {
    backgroundColor: COLORS.ORCHID + 'AA',
    borderRadius: 8,
    padding: '2px 6px',
  },
  time: {
    fontSize: 10,
    color: COLORS.MOUSE,
  },
  timeOwned: {
    color: COLORS.MOUSE,
  },
  timeOnImage: {
    color: COLORS.WHITE,
    fontWeight: '500',
  },
}
