import React, { memo } from 'react'
import { Diamonds } from './Table'
import * as Yup from 'yup'
import i18n from 'root/i18n'
import { ErrorMessage } from 'formik'

export IndexColumn from './IndexColumn'
export ShapeColumn from './ShapeColumn'
export NameColumn from './NameColumn'
export CertificateColumn from './CertificateColumn'
export RefPriceColumn from './RefPriceColumn'
export PriceColumn from './PriceColumn'
export RapaportColumn from './RapaportColumn'
export LocationColumn from './LocationColumn'
export EditColumn from './EditColumn'
export RemoveColumn from './RemoveColumn'
export DeliveryColumn from './DeliveryColumn'
export ChangesColumn from './ChangesColumn'

export function useDiamondsTableFormValues(order) {
  const orderItems = {}
  for (const product of order.products) {
    orderItems[product.id] = {
      ...product,
      itemId: product.id,
      disabled: product.disabled,
      deliveryDaysFrom: product.deliveryDaysFrom,
      deliveryDaysTo: product.deliveryDaysTo,
      price: product.price,
      referencePrice: product.unitReferencePrice.value,
      referenceDiscount: product.referenceDiscount,
    }
  }
  return { orderItems }
}

const FIELD_LABELS = {
  referencePrice: i18n.value('order.diamonds.referencePrice'),
  price: i18n.value('order.diamonds.price'),
  deliveryDaysFrom: i18n.value('order.diamonds.deliveryDaysFrom'),
  deliveryDaysTo: i18n.value('order.diamonds.deliveryDaysTo'),
}

export const getItemsValidationSchema = (orderItems) => {
  const innerSchemas = {}
  for (const key of Object.keys(orderItems)) {
    innerSchemas[key] = Yup.object().shape({
      referencePrice: Yup.number()
        .min(0, FIELD_LABELS['referencePrice'] + ' is too low')
        .max(10000000)
        .required(FIELD_LABELS['referencePrice'] + ' value is required'),
      price: Yup.number()
        .min(0, FIELD_LABELS['price'] + ' is too low')
        .max(10000000)
        .required(FIELD_LABELS['price'] + ' value is required'),
      deliveryDaysFrom: Yup.number()
        .min(0, FIELD_LABELS['deliveryDaysFrom'] + ' is too low')
        .max(255)
        .required(FIELD_LABELS['deliveryDaysFrom'] + ' value is required'),
      deliveryDaysTo: Yup.number()
        .min(0, FIELD_LABELS['deliveryDaysTo'] + ' is too low')
        .max(255)
        .required(FIELD_LABELS['deliveryDaysTo'] + ' value is required'),
    })
  }

  return Yup.object().shape(innerSchemas)
}

export const getItemsErrorFields = (orderItems) => {
  const errorFieldsItems = []
  for (const [key, itemRow] of Object.entries(orderItems)) {
    for (const itemRowKey of Object.keys(itemRow)) {
      errorFieldsItems.push(
        <ErrorMessage key={`${key}.${itemRowKey}`} name={`orderItems["${key}"].${itemRowKey}`} component="div" />,
      )
    }
  }

  return errorFieldsItems
}

export default memo(Diamonds)
