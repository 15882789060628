import React, { memo, Fragment, useCallback, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ContentEditable from 'react-contenteditable'
import { SRLWrapper } from 'simple-react-lightbox'

import i18n from 'root/i18n'
import { ClipIcon, SendIcon } from 'root/icons'
import { isSameDay, isToday, timestampToDate } from 'root/constants'

import connect from './connect'
import styles from './styles'
import './styles.css'
import ChatMessage from './components/ChatMessage'
import NewMessages from './components/NewMessages'
import ScrollDownButton from './components/ScrollDownButton'
import AttachedMedia from './components/AttachedMedia'
import ChatHeader from './components/ChatHeader'

const itemVisiblePercentThreshold = 0.6
const MESSAGE_MAX_LENGTH = 300

const getVisibleSize = (containerStart, containerEnd, elementStart, elementSize) => {
  const elementEnd = elementStart + elementSize
  const start = Math.min(Math.max(elementStart, containerStart), containerEnd)
  const end = Math.max(Math.min(elementEnd, containerEnd), containerStart)
  return (end - start) / elementSize
}

const Chat = ({ messages, userId, lastRead, setLastRead, lastReadByOtherCompany, ...props }) => {
  const {
    sendMessage,
    loadMessages,
    hasNext,
    unreadCount,
    uploadImage,
    uploadVideo,
    uploadDocument,
    headerProps,
    loadedAllBefore,
  } = props

  const [{ inputText }, setInputText] = useState({ inputText: '' })
  const [images, setImages] = useState()
  const [{ newMessageIds }, setMarginTop] = useState({})
  const [firstUnreadId, setFirstUnreadId] = useState()
  const inputLine = useRef()

  useEffect(() => {
    if (lastRead !== undefined) {
      let oldestUnreadId = null
      for (const message of messages) {
        if (message.created <= lastRead) break
        if (message.userId !== userId) oldestUnreadId = message.id
      }
      setFirstUnreadId(oldestUnreadId)
    }
    if (inputLine.current) inputLine.current.el.current.focus()
  }, [lastRead, messages, userId, inputLine])

  const isSendingRef = useRef(false)

  const resetInput = useCallback(() => {
    setImages(undefined)
    setInputText({ inputText: '' })
  }, [])

  const scrollRef = useRef()

  const scrollToBottom = useCallback(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight
  }, [])

  const send = useCallback(() => {
    if (isSendingRef.current) return
    const text = inputText.trim()
    if (text || images) {
      isSendingRef.current = true
      sendMessage(text, images)
        .then((newMessageIds) => {
          resetInput()
          if (newMessageIds?.length) {
            newMessageIds = new Set(newMessageIds)
            setMarginTop({ newMessageIds })
            setTimeout(() => {
              setMarginTop({})
            })
          }
          scrollToBottom()
        })
        .finally(() => (isSendingRef.current = false))
    }
  }, [images, inputText, scrollToBottom, resetInput, sendMessage])

  // ContentEditable does not seem to allow changing onKeyDown dynamically
  const sendRef = useRef(send)
  useEffect(() => {
    sendRef.current = send
  }, [send])

  const onKeyDown = useCallback((e) => {
    const { code, shiftKey } = e
    if (code === 'Enter' && !shiftKey) {
      e.preventDefault()
      sendRef.current()
    }
  }, [])

  const renderItem = useCallback(
    (item, index) => {
      let result
      const { id, userName, connectedName, type, created } = item
      const isOldestMessage = !hasNext && index === messages.length - 1
      const prevMessage = messages[index + 1]

      const showDay = isOldestMessage || (prevMessage && !isSameDay(created, prevMessage.created))

      const isOwned = item.userId === userId
      if (['message', undefined].includes(type)) {
        const isFirstUnread = id === firstUnreadId
        const sameUserAsPrevMsg = prevMessage && prevMessage?.userId === item.userId
        const prevMsgIsNotText = prevMessage && !['message', undefined].includes(prevMessage.type)
        const displayName = !isOwned && !(sameUserAsPrevMsg && !prevMsgIsNotText) ? userName : undefined
        const isRead = isOwned && created <= lastReadByOtherCompany

        result = (
          <Fragment>
            {isFirstUnread && <NewMessages />}
            <ChatMessage {...item} isOwned={isOwned} isRead={isRead} userName={displayName} />
          </Fragment>
        )
      } else if (['join', 'leave', 'self_join'].includes(type)) {
        const isJoin = type === 'join'
        const isSelfJoin = type === 'self_join'
        const displayName = item.userId === userId ? i18n.value('chat.you') : userName
        const text = i18n.value(`chat.${isJoin ? 'added' : isSelfJoin ? 'joined' : 'removed'}`).toLowerCase()

        result = (
          <NewMessages
            text={
              <Fragment>
                <span style={styles.boldGray}>{`${displayName} `}</span>
                {text}
                {!isSelfJoin && <span style={styles.boldGray}>{` ${connectedName}`}</span>}
              </Fragment>
            }
          />
        )
      } else console.error(type)

      if (showDay)
        result = (
          <Fragment>
            <NewMessages text={isToday(created) ? 'Today' : timestampToDate(created)} />
            {result}
          </Fragment>
        )
      let extraProps = {}
      if (newMessageIds?.has(id)) extraProps = { style: { bottom: '100%' } }
      if (!isOwned && created > lastRead) extraProps = { created }
      return (
        <div key={id} {...extraProps}>
          {result}
        </div>
      )
    },
    [firstUnreadId, hasNext, lastRead, messages, newMessageIds, userId, lastReadByOtherCompany],
  )

  const removeImage = useCallback(
    (index) => () => {
      setImages((images) => {
        if (images.length > 1) {
          images = [...images]
          images.splice(index, 1)
          return images
        }
      })
    },
    [],
  )

  const fileInputRef = useRef()
  const scrollDownRef = useRef()
  const openFileInput = useCallback(() => fileInputRef.current.click(), [])
  const onFileOpen = useCallback(
    ({ target: { files } }) => {
      if (!files.length) return
      const allFiles = Array.from(files)
      const images = allFiles.filter((v) => v.type.includes('image')).map(uploadImage)
      const videos = allFiles.filter((v) => v.type.includes('video')).map(uploadVideo)
      const docs = allFiles.filter((v) => !v.type.includes('video') && !v.type.includes('image')).map(uploadDocument)
      return Promise.all([...images, ...videos, ...docs])
        .then((responses) =>
          setImages((images) =>
            (images || []).concat(
              responses.map(
                ({
                  data: {
                    data: { originalName, fileName, host, urlPath, type },
                  },
                }) => ({
                  uri: host + urlPath + fileName,
                  originalName,
                  fileName,
                  type,
                }),
              ),
            ),
          ),
        )
        .catch(console.error)
    },
    [uploadDocument, uploadImage, uploadVideo],
  )

  const onChange = useCallback((e) => {
    const { target } = e.nativeEvent
    let { innerText } = target
    if (innerText.length > MESSAGE_MAX_LENGTH) innerText = innerText.slice(0, MESSAGE_MAX_LENGTH)
    setInputText({ inputText: innerText })
  }, [])

  const awaitingLastReadRef = useRef(lastRead || 0)
  const didCheckUnreadRef = useRef(false)

  const checkUnread = useCallback(() => {
    if (didCheckUnreadRef.current) return
    didCheckUnreadRef.current = true
    const { offsetHeight, scrollTop, children } = scrollRef.current

    const containerEnd = offsetHeight + scrollTop
    for (const item of children) {
      let created = item.getAttribute('created')
      created = created && parseInt(created)
      if (created > awaitingLastReadRef.current) {
        const { offsetTop, offsetHeight } = item
        const isVisible = getVisibleSize(scrollTop, containerEnd, offsetTop, offsetHeight) > itemVisiblePercentThreshold
        if (isVisible) {
          awaitingLastReadRef.current = created
          setLastRead(created)
        }
      } else if (created) break
    }
  }, [setLastRead])

  useEffect(() => {
    didCheckUnreadRef.current = !firstUnreadId
  }, [firstUnreadId])

  const onScroll = useCallback(
    ({ target: { scrollHeight, offsetHeight, scrollTop } }) => {
      const fromEnd = scrollHeight + scrollTop - offsetHeight

      if (!loadedAllBefore && fromEnd < 100) loadMessages()
      didCheckUnreadRef.current = !firstUnreadId
      checkUnread()

      if (scrollTop < -100) scrollDownRef.current.show()
      else scrollDownRef.current.hide()
    },
    [checkUnread, firstUnreadId, loadMessages, loadedAllBefore],
  )

  const onPaste = useCallback(
    (event) => {
      const { items } = event.clipboardData || event.originalEvent.clipboardData
      const files = Object.values(items)
        .filter(({ kind, type }) => kind === 'file' && type === 'image/png')
        .map((item) => item.getAsFile())
      return onFileOpen({ target: { files } })
    },
    [onFileOpen],
  )

  return (
    <>
      <ChatHeader {...headerProps} />
      <div style={styles.container} onMouseMove={checkUnread}>
        <input
          ref={fileInputRef}
          multiple
          type="file"
          style={{ display: 'none' }}
          onChange={onFileOpen}
          accept="image/jpeg,image/png,image/gif,image/bmp,video/mp4,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />

        <div className="chat-messages" style={styles.flex}>
          <SRLWrapper>
            <div ref={scrollRef} style={styles.scroll} onScroll={onScroll}>
              {messages?.map(renderItem)}
            </div>
          </SRLWrapper>
          <ScrollDownButton ref={scrollDownRef} unreadCount={unreadCount} onPress={scrollToBottom} />
        </div>
        <div style={styles.inputRow}>
          {!!uploadImage && <ClipIcon className="cursor-pointer" onClick={openFileInput} />}
          <div style={styles.inputContainer}>
            {!!images && <AttachedMedia images={images} removeImage={removeImage} />}
            {!inputText && <div style={styles.placeholder}>{i18n.value('chat.placeholder')}</div>}
            <ContentEditable
              onKeyDown={onKeyDown}
              html={inputText}
              style={styles.input}
              onChange={onChange}
              onPaste={onPaste}
              tabIndex={1}
              ref={inputLine}
            />
          </div>
          <SendIcon className="cursor-pointer" onClick={send} />
        </div>
      </div>
    </>
  )
}

Chat.propTypes = {
  headerProps: PropTypes.object.isRequired,
  sendMessage: PropTypes.func.isRequired,
  uploadImage: PropTypes.func,
  uploadVideo: PropTypes.func,
  uploadDocument: PropTypes.func,
  loadMessages: PropTypes.func,
  messages: PropTypes.array,
  userId: PropTypes.string.isRequired,
  setLastRead: PropTypes.func,
  lastRead: PropTypes.number,
  lastReadByOtherCompany: PropTypes.number,
  hasNext: PropTypes.bool,
  loadedAllBefore: PropTypes.bool,
  unreadCount: PropTypes.number,
}

export default memo(connect(Chat))
