import { connect } from 'react-redux'
import { canSupervise, isAdmin } from 'root/selectors'
import {
  getCatalogFrameInfoData,
  isCatalogFrameInfoLoading,
  isCatalogIFrameEditable,
} from 'root/modules/profile/selectors'
import { loadCatalogFrameInfo, saveCatalogFrameInfo } from 'root/modules/profile/thunks'

export default connect(
  (state) => ({
    isAdmin: isAdmin(state),
    isCatalogIFrameEditable: isCatalogIFrameEditable(state),
    isLoading: isCatalogFrameInfoLoading(state),
    data: getCatalogFrameInfoData(state),
    canSupervise: canSupervise(state),
  }),
  {
    loadCatalogFrameInfo,
    saveCatalogFrameInfo,
  },
)
