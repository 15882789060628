import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ACTION_MEMO_PAD_RECEIVE_BY_SELLER, ACTION_MEMO_PAD_SHIP_TO_SELLER } from 'root/modules/orders/constants'
import TrackingItems from '../components/TrackingItems'
import ShipmentItems from '../components/ShipmentItems'

const ReturnItemsTab = ({ order, isSubmitting, handleSubmit, setFieldValue, values, isSeller, readOnly }) => {
  return (
    <>
      <TrackingItems
        shipments={order.shipments}
        action={ACTION_MEMO_PAD_RECEIVE_BY_SELLER}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        isReceiver={isSeller}
        senderId={order.buyerCompanyId.id}
      />
      <div className="my-1">&nbsp;</div>
      <ShipmentItems
        order={order}
        action={ACTION_MEMO_PAD_SHIP_TO_SELLER}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        values={values}
        valuesKey={'orderItemsForShipToSeller'}
        isSender={!isSeller}
        readOnly={readOnly}
      />
    </>
  )
}

ReturnItemsTab.propTypes = {
  order: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isSeller: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(ReturnItemsTab)
