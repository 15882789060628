import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.7604 4.58594H24.3093C24.6875 4.58594 25.0427 4.775 25.2546 5.08437L27.2083 8.59635H27.7812C28.4229 8.59635 28.927 9.02031 28.927 9.8625V13.7526C28.927 14.3828 28.4114 14.8984 27.7812 14.8984H26.6354"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path d="M10.0209 0.574219H19.7605V14.8971H11.1667" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
    <path d="M6.58333 14.8984H3.71875" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
    <path
      d="M26.6354 15.4773C26.6354 16.7378 25.6099 17.7633 24.338 17.7633C23.0719 17.7633 22.0406 16.7378 22.0406 15.4773C22.0406 14.2169 23.0662 13.1914 24.338 13.1914C25.6099 13.1914 26.6354 14.2112 26.6354 15.4773Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M11.1782 15.4773C11.1782 16.7378 10.1526 17.7633 8.88077 17.7633C7.6089 17.7633 6.58337 16.7378 6.58337 15.4773C6.58337 14.2169 7.6089 13.1914 8.88077 13.1914C10.1526 13.1914 11.1782 14.2112 11.1782 15.4773Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path d="M19.7604 14.8984H22.052" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
    <path
      d="M20.9062 9.16667H24.9167V8.59375L23.7708 6.875"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path d="M6.58337 4.01172H15.1771" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
    <path d="M5.43746 4.01172H4.29163" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
    <path d="M8.87496 0.574219H7.72913" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
    <circle cx="7" cy="13" r="7" fill="white" />
    <path
      d="M7 18C9.75711 18 12 15.7571 12 13C12 10.2429 9.75711 8 7 8C4.24289 8 2 10.2429 2 13C2 15.7571 4.24289 18 7 18ZM4.94044 11.7262C4.72356 11.5093 4.72356 11.1576 4.94044 10.9407C5.15733 10.7238 5.50911 10.7238 5.726 10.9407L7 12.2144L8.27378 10.9407C8.49067 10.7238 8.84222 10.7238 9.05933 10.9407C9.27644 11.1576 9.27644 11.5093 9.05933 11.7262L7.78556 13L9.05933 14.274C9.27644 14.4909 9.27644 14.8424 9.05933 15.0596C8.95089 15.1682 8.80867 15.2224 8.66667 15.2224C8.52444 15.2224 8.38222 15.1682 8.27378 15.0596L7 13.7856L5.726 15.0593C5.61756 15.168 5.47533 15.2222 5.33333 15.2222C5.19111 15.2222 5.04889 15.168 4.94044 15.0593C4.72356 14.8424 4.72356 14.4909 4.94044 14.2738L6.21444 13L4.94044 11.7262Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(SvgComponent)
