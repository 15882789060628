import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from '@sentry/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import SimpleReactLightbox from 'simple-react-lightbox'
import WebFont from 'webfontloader'

import { enableCrashCollection } from 'root/sentry'
import { store, persistor } from 'root/store'
import Router from 'root/router'
import { subscribeToSocket } from 'root/modules/ws'

import 'rc-slider/assets/index.css'
import './index.css'

WebFont.load({
  google: {
    api: 'https://fonts.googleapis.com/css2',
    families: ['Inter:wght@300;400;500;600;700;800&display=swap'],
  },
})

subscribeToSocket()

if (process.env.NODE_ENV === 'production') enableCrashCollection()

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <SimpleReactLightbox>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {(bootstrapped) => (bootstrapped ? <Router /> : null)}
          </PersistGate>
        </Provider>
      </SimpleReactLightbox>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
)
