import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.3068 29.269C18.3068 28.902 18.2728 28.5434 18.2243 28.191L20.1122 26.419L17.7826 22.3629L15.3135 23.1203C14.7505 22.6776 14.1268 22.3105 13.4534 22.0361L12.871 19.5117H8.21186L7.62946 22.0361C6.95728 22.3105 6.33362 22.6776 5.76943 23.1203L3.3003 22.3629L0.970703 26.419L2.85865 28.191C2.81011 28.5446 2.77614 28.902 2.77614 29.269C2.77614 29.6361 2.81011 29.9946 2.85865 30.3471L0.970703 32.119L3.3003 36.1751L5.76943 35.4178C6.33241 35.8605 6.95606 36.2276 7.62946 36.502L8.21186 39.0264H12.871L13.4534 36.502C14.1256 36.2276 14.7493 35.8605 15.3135 35.4178L17.7826 36.1751L20.1122 32.119L18.2243 30.3471C18.2728 29.9946 18.3068 29.6361 18.3068 29.269Z"
      stroke="#80506F"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10.5409 32.1955C12.1491 32.1955 13.4529 30.8851 13.4529 29.2686C13.4529 27.6522 12.1491 26.3418 10.5409 26.3418C8.93265 26.3418 7.62891 27.6522 7.62891 29.2686C7.62891 30.8851 8.93265 32.1955 10.5409 32.1955Z"
      stroke="#80506F"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.7039 30.2449H44.0291V0.976562H3.26123V19.5131"
      stroke="#80506F"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.8208 17.5616L20.7328 14.6348L29.4688 23.4153"
      stroke="#80506F"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5278 21.4649L31.4105 17.5625L40.1465 26.343"
      stroke="#80506F"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3221 12.6837C35.9304 12.6837 37.2341 11.3733 37.2341 9.75691C37.2341 8.14046 35.9304 6.83008 34.3221 6.83008C32.7139 6.83008 31.4102 8.14046 31.4102 9.75691C31.4102 11.3733 32.7139 12.6837 34.3221 12.6837Z"
      stroke="#80506F"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
