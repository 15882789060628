import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ value, ...props }) => (
  <svg width="9" height="15" viewBox="0 0 9 15" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path d="M1 10.5371L4.293 13.8301L8.07178 10.5371" stroke={value === 1 ? '#80506F' : '#DADADA'} />
    <path d="M8.07178 4.53613L4.77878 1.24314L1 4.53613" stroke={value === 2 ? '#80506F' : '#DADADA'} />
  </svg>
)

SvgComponent.propTypes = {
  value: PropTypes.oneOf([0, 1, 2]).isRequired,
}

export default memo(SvgComponent)
