import React, { memo } from 'react'
import PropTypes from 'prop-types'
import StatusTabItem from 'root/components/StatusTabItem'
import { PRODUCT_STATES } from 'root/modules/products/constants'

const CatalogTabs = ({ activeTab = '', updateFilters, total }) => (
  <div className="bg-white rounded-md border-b border-gray-300 shadow justify-between flex items-center">
    {PRODUCT_STATES.map((item) => (
      <StatusTabItem
        onClick={() => updateFilters({ s: item.key })}
        isActive={item.key === activeTab}
        key={item.key}
        item={item}
        labelPrefix="products.list.status"
        subtext={item.key === activeTab ? total : ''}
      />
    ))}
  </div>
)

CatalogTabs.propTypes = {
  activeTab: PropTypes.string,
  updateFilters: PropTypes.func.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default memo(CatalogTabs)
