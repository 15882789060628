import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import { OrderStageNegotiationIcon, OrderStagePaymentIcon, OrderStageRequestIcon } from 'root/icons'
import {
  STAGE_CANCELED,
  STAGE_EXECUTED,
  STAGE_NEGOTIATION,
  STAGE_PAYMENT_AND_DELIVERY,
  STAGE_REQUEST,
} from 'root/modules/orders/constants'
import { toClassName } from 'root/constants'
import BrownButton from 'root/components/Buttons/BrownButton'
import { SITE_URL } from 'root/api/env'
import i18n from 'root/i18n'

export const OrderStageIcons = ({ stage, order, asSeller }) => {
  const icons = [OrderStageRequestIcon, OrderStageNegotiationIcon, OrderStagePaymentIcon]
  const stages = [STAGE_REQUEST, STAGE_NEGOTIATION, STAGE_PAYMENT_AND_DELIVERY, STAGE_EXECUTED]
  const currentIndex = stages.indexOf(stage)
  if ([STAGE_EXECUTED, STAGE_CANCELED].includes(stage)) {
    return order?.sellerCompanyId?.catalogId && !asSeller ? (
      <BrownButton
        type="button"
        onClick={() => {
          window.open(`${SITE_URL}/catalog?s=${order.sellerCompanyId.catalogId}`)
        }}
        slim
        className="bg-brown-dim-light self-center"
      >
        {i18n.value('view_stones_from_supplier')}
      </BrownButton>
    ) : null
  }
  return (
    <div className="flex items-center">
      {icons.map((Icon, index) => {
        return (
          <div className="flex items-center" key={index}>
            <div className="relative">
              <i
                className={toClassName([
                  'border flex items-center justify-center rounded-full h-10 w-10 border-blue-skyblue',
                  index <= currentIndex ? 'bg-blue-skyblue text-white' : 'bg-white text-blue-skyblue',
                ])}
              >
                <Icon />
              </i>
              <span className={toClassName(['absolute left-1/2 transform -translate-x-1/2 text-xs text-blue-skyblue'])}>
                {i18n.value(`order.icon.stage.${stages[index]}`)}
              </span>
            </div>
            {index < 2 && (
              <div className={toClassName(['h-px w-32', index > currentIndex ? 'bg-gray-300' : 'bg-blue-skyblue'])} />
            )}
          </div>
        )
      })}
    </div>
  )
}

OrderStageIcons.propTypes = {
  stage: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  asSeller: PropTypes.bool.isRequired,
}

export default memo(OrderStageIcons)
