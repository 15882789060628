import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import SliderHandleLabel from './components/SliderHandleLabel'
import Slider, { Range } from 'rc-slider'

const getMarks = (steps) => {
  const marginLeft = -100 / steps.length / 2 + '%'
  return [' '].concat(steps).reduce(
    (acc, label, index) =>
      Object.assign(acc, {
        [index]: { style: { marginLeft }, label },
      }),
    {},
  )
}

// eslint-disable-next-line react/display-name,react/prop-types
const getLabelHandle =
  (prefix, postfix, onChange) =>
  // eslint-disable-next-line react/display-name,react/prop-types
  ({ value, dragging, ...props }) => {
    // eslint-disable-next-line react/prop-types
    const { index } = props
    const labelProps = { index, value, prefix, postfix, onChange }
    return (
      <Slider.Handle key={index} {...props}>
        <SliderHandleLabel {...labelProps} />
        <div className="rc-slider-handle-inner" />
      </Slider.Handle>
    )
  }

const CatalogSlider = ({
  steps,
  prefix,
  postfix,
  min = 0,
  max = 100,
  filterKey,
  updateFilters,
  from,
  to,
  ...props
}) => {
  if (steps) max = steps.length
  const onChange = useCallback(
    ([from, to]) => {
      let update
      if (steps)
        update = {
          [filterKey]:
            from === 0 && to === steps.length
              ? undefined
              : Array(to - from)
                  .fill()
                  .map((item, index) => 1 + from + index),
        }
      else {
        update = {}
        if (from !== undefined) update[`${filterKey}fr`] = from !== min ? from : undefined
        if (to !== undefined) update[`${filterKey}to`] = to !== max ? to : undefined
      }
      return updateFilters(update)
    },
    [filterKey, max, min, steps, updateFilters],
  )

  const handle = useMemo(
    () => (steps ? undefined : getLabelHandle(prefix, postfix, onChange)),
    [postfix, prefix, steps, onChange],
  )
  const marks = useMemo(() => steps && getMarks(steps), [steps])

  return (
    <div style={{ padding: '1px 21px' }}>
      <Range
        pushable={steps ? 1 : props.step ?? true}
        allowCross={!!steps}
        min={min}
        max={max}
        step={steps ? null : undefined}
        handle={handle}
        defaultValue={[min, max]}
        marks={marks}
        {...props}
        value={[from, to]}
        onChange={onChange}
      />
    </div>
  )
}

CatalogSlider.propTypes = {
  steps: PropTypes.array,
  step: PropTypes.number,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  min: PropTypes.number,
  from: PropTypes.number,
  max: PropTypes.number,
  to: PropTypes.number,
  filterKey: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
}

export default memo(CatalogSlider)
