import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { childrenPropType } from 'root/constants/propTypes'

const Button = ({ label, slim, className = '', type, children, ...props }) => (
  <button
    type={type || 'submit'}
    className={
      'flex items-center justify-center font-bold text-white transition duration-200 hover:shadow-sm active:shadow-none disabled:opacity-50 focus:outline-none ' +
      (slim ? ' px-4 h-8 text-xs ' : ' h-10 px-8 ') +
      className
    }
    {...props}
  >
    {children || i18n.value(label)}
  </button>
)

Button.propTypes = {
  label: PropTypes.string,
  slim: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  children: childrenPropType,
}

export default memo(Button)
