import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="30" height="33" viewBox="0 0 30 33" className="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9089 33H1.99338C0.892393 33 -0.00012207 32.0807 -0.00012207 30.9467V10.4145V8.3612L25.9154 0.148244C26.3829 0.0198368 26.7669 -0.0763781 27.1615 0.0840404C27.6737 0.292278 27.9089 0.607629 27.9089 1.17463V8.3612C29.0102 8.3612 29.9027 9.28048 29.9027 10.4145V30.9467C29.9027 32.0807 29.0102 33 27.9089 33ZM25.9154 2.20156L6.47876 8.3612H25.9154V2.20156ZM27.9089 21.7071C27.9089 21.1404 27.9089 21.4505 27.9089 20.6805H23.9219C23.9219 21.1401 23.9219 21.1404 23.9219 21.7071C23.9219 22.2741 23.9219 22.07 23.9219 22.7338H27.9089C27.9089 22.0279 27.9089 22.2739 27.9089 21.7071ZM1.99338 10.4142L26.9124 10.4145C27.4628 10.4145 27.9089 10.8739 27.9089 11.4409V18.6274H22.9252C22.3748 18.6274 21.9284 19.0871 21.9284 19.6538V23.7604C21.9284 24.3274 22.3748 24.7871 22.9252 24.7871H27.9089V29.92C27.9089 30.487 27.4628 30.9467 26.9124 30.9467H2.99013C2.43952 30.9467 1.99338 30.487 1.99338 29.92C1.99338 29.92 1.99338 11.1201 1.99338 10.4142Z"
    />
  </svg>
)

export default memo(SvgComponent)
