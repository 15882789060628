import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ width = 32, height = 32, ...props }) => (
  <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.7678 21.68C10.627 22.2225 10.5443 22.7875 10.5339 23.37H5.21011C4.72812 23.37 4.33659 22.9925 4.33659 22.525C4.33659 22.0588 4.72812 21.68 5.21011 21.68H10.7678ZM14.4286 30H2.14245C0.961389 30 0 29.0712 0 27.9275V2.0725C0 0.93 0.961389 0 2.14245 0H15.8022L21.8987 6.40875V16.8713C21.3379 16.5825 20.7332 16.365 20.1 16.2162V7.65H15.4094C14.9132 7.65 14.5087 7.26125 14.5087 6.78125V1.74H2.14245C1.9525 1.74 1.79873 1.88875 1.79873 2.07125V27.9263C1.79873 28.1088 1.9525 28.2588 2.14245 28.2588H12.2939C12.8883 28.9588 13.6145 29.5462 14.4286 30ZM16.3074 5.91125H18.9797L16.3074 3.10125V5.91125ZM16.08 14.0712H5.21916C4.73717 14.0712 4.34563 14.45 4.34563 14.9163C4.34563 15.3813 4.73588 15.76 5.21916 15.76H16.08C16.562 15.76 16.9535 15.3813 16.9535 14.9163C16.9522 14.45 16.562 14.0712 16.08 14.0712ZM5.2114 19.5625H11.6943C12.1001 18.93 12.5976 18.3612 13.1726 17.8725H5.2114C4.72942 17.8725 4.33788 18.25 4.33788 18.7162C4.33788 19.1838 4.72942 19.5625 5.2114 19.5625ZM16.0839 10.1263H5.21528C4.73329 10.1263 4.34176 10.5037 4.34176 10.9712C4.34176 11.4375 4.73329 11.8162 5.21528 11.8162H16.0839C16.5672 11.8162 16.9574 11.4375 16.9574 10.9712C16.9574 10.505 16.5659 10.1263 16.0839 10.1263ZM25 23.5C25 27.0887 21.9918 30 18.2806 30C14.5694 30 11.5612 27.0887 11.5612 23.5C11.5612 19.91 14.5694 17 18.2806 17C21.9918 17 25 19.91 25 23.5ZM22.112 26.4412H14.4493V27.5H22.112V26.4412ZM22.112 22.2062L18.2806 18.5L14.4493 22.2062H16.6395V25.3825H19.923V22.2062H22.112Z"
      fill="currentColor"
    />
  </svg>
)
SvgComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default memo(SvgComponent)
