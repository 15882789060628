import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="17"
    height="22"
    className={'stroke-current'}
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8811 3.17383H15.5974C16.0715 3.17383 16.4555 3.56296 16.4555 4.04339V20.1304C16.4555 20.6108 16.0715 20.9999 15.5974 20.9999H1.8674C1.39329 20.9999 1.00928 20.6108 1.00928 20.1304V4.04339C1.00928 3.56296 1.39329 3.17383 1.8674 3.17383H3.58365"
      strokeMiterlimit="10"
    />
    <path d="M13.881 4.91357H14.7391V19.2614H2.72534V4.91357H3.58347" strokeMiterlimit="10" />
    <path
      d="M11.736 2.73913H10.4488C10.4488 1.77881 9.68021 1 8.73252 1C7.78483 1 7.01627 1.77881 7.01627 2.73913H5.72908C5.01791 2.73913 4.44189 3.32283 4.44189 4.04348V5.34783H13.0231V4.04348C13.0231 3.32283 12.4471 2.73913 11.736 2.73913Z"
      strokeMiterlimit="10"
    />
    <path d="M8.73254 2.30469V3.17425" strokeMiterlimit="10" />
    <path
      d="M4.87073 8.82611L5.96269 9.77448L7.4451 7.95654"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.87073 12.3046L5.96269 13.253L7.4451 11.4351"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.87073 15.7831L5.96269 16.7315L7.4451 14.9136"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.594 8.82568H9.59058H12.594Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.594 12.3042H9.59058H12.594Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.594 15.7827H9.59058H12.594Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default memo(SvgComponent)
