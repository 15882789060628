import React, { memo } from 'react'
import DealRequestSeller from './components/DealRequestSeller'
import DealRequestBuyer from './components/DealRequestBuyer'
import PropTypes from 'prop-types'
import ReadonlyNotice from 'root/modules/orders/pages/Order/components/components/ReadonlyNotice'

const DealRequest = ({ asSeller, ...other }) => (
  <>
    {other.readOnly ? <ReadonlyNotice order={other.order} /> : null}
    {asSeller ? <DealRequestSeller {...other} /> : <DealRequestBuyer {...other} />}
    {other.readOnly ? <ReadonlyNotice order={other.order} /> : null}
  </>
)

DealRequest.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  asSeller: PropTypes.bool.isRequired,
}

export default memo(DealRequest)
