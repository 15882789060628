import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal from 'root/components/Modal'
import { Spinner } from 'react-awesome-spinners'
import StripeCheckout from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/PaymentAndDelivery/components/StripeCheckout'
import MessageBox from 'root/components/MessageBox'
import PaymentIcon from 'root/icons/PaymentIcon'
import Price from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Price'

const StripePaymentModal = ({ visible, onClose, secret, sum, first }) => {
  const doClose = useCallback(() => {
    if (secret) return onClose()
  }, [onClose, secret])
  if (!visible) return null
  return (
    <Modal isFull onClick={doClose}>
      {!secret ? (
        <Spinner />
      ) : (
        <div>
          <StripeCheckout first={first} />
          {sum.amount ? (
            <MessageBox icon={<PaymentIcon />} className="mt-4">
              <div className="flex flex-col text-xs text-brown-dim">
                <div className="font-bold">{sum.description}</div>
                <div>
                  Payment: <Price value={sum.amount} currency="usd" />
                </div>
                <div>
                  Fee: <Price value={sum.fee} currency="usd" />
                </div>
                <div>
                  <span className="font-bold">
                    Total: <Price currency="usd" value={sum.total} />
                  </span>
                </div>
              </div>
            </MessageBox>
          ) : null}
        </div>
      )}
    </Modal>
  )
}

StripePaymentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  secret: PropTypes.string,
  sum: PropTypes.object,
  first: PropTypes.bool.isRequired,
}

export default memo(StripePaymentModal)
