import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import {
  ACTION_MEMO_BACK_TO_INVOICE,
  SECTION_SALES,
  ACTION_MEMO_NEGOTIATION_CONFIRM_AND_SIGN,
} from 'root/modules/orders/constants'
import DealNegotiationMemoForm from '../DealNegotiationMemoForm'

const MemoNegotiationFinal = ({ order, performOrderAction, readOnly = false, asSeller }) => {
  const { section } = useParams()
  if (section === SECTION_SALES) {
    let buttons = order.timer.isExpired
      ? [{ color: 'blue', label: 'orders.buttons.backToInvoice', action: ACTION_MEMO_BACK_TO_INVOICE }]
      : []
    if (readOnly) buttons = []
    return (
      <DealNegotiationMemoForm
        order={order}
        performOrderAction={performOrderAction}
        buttons={buttons}
        isEditable={false}
        asSeller={asSeller}
      />
    )
  } else {
    const buttons = [
      { color: 'brown', label: 'orders.buttons.confirmAndSign', action: ACTION_MEMO_NEGOTIATION_CONFIRM_AND_SIGN },
      { color: 'blue', label: 'orders.buttons.backToInvoice', action: ACTION_MEMO_BACK_TO_INVOICE },
    ]
    return (
      <DealNegotiationMemoForm
        order={order}
        performOrderAction={performOrderAction}
        buttons={buttons}
        isEditable={false}
        asSeller={asSeller}
      />
    )
  }
}

MemoNegotiationFinal.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  asSeller: PropTypes.bool.isRequired,
}

export default memo(MemoNegotiationFinal)
