import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import NotFound from 'root/components/NotFound'
import connect from './connect'
import { Spinner } from 'react-awesome-spinners'
import { ensureBoolean } from 'root/constants'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormikToggle from 'root/components/FormikToggle'
import RequiredLabel from 'root/components/RequiredLabel'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import { ACTION_SAVE_CATALOG_INFO } from 'root/modules/profile/constants'
import BlueButton from 'root/components/Buttons/BlueButton'
import { useHistory } from 'react-router-dom'
import { SITE_URL } from 'root/api/env'

const CatalogIframeSettings = ({
  isCatalogIFrameEditable,
  isAdmin,
  loadCatalogFrameInfo,
  saveCatalogFrameInfo,
  data,
  isLoading,
  canSupervise,
}) => {
  useEffect(loadCatalogFrameInfo, [loadCatalogFrameInfo])

  const history = useHistory()

  if (!isCatalogIFrameEditable) {
    return NotFound
  }

  if (isLoading) return <Spinner />

  if (!canSupervise)
    return (
      <div className="container mb:p-4 mb:mb-10 sm:w-screen-lg-min">
        <div>{i18n.value('no_access')}</div>
      </div>
    )

  const initialValues = {
    ...data,
    enabled: ensureBoolean(data.enabled),
    showPrices: ensureBoolean(data.showPrices),
    showCertificate: ensureBoolean(data.showCertificate),
    allowOrders: ensureBoolean(data.allowOrders),
    autoOrder: ensureBoolean(data.autoOrder),
    priceExtra: Number.parseFloat(data.priceExtra),
  }

  const iframeUrl = `<iframe src="${SITE_URL}/catalog-frame/${data.hash}" width="1130" height="1200" scrolling="no"></iframe>`

  return (
    <div className="container mb:p-4 mb:mb-10 sm:w-screen-lg-min">
      <div className="container mx-auto lg:w-screen-lg-min mb-16 flex">
        <OrderHeading label={i18n.value('profile.company.labels.catalogIFrameSettingsTitle')} />
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
          domain: Yup.string().max(100).default(''),
          priceExtra: Yup.number().min(0).max(300),
        })}
        onSubmit={(values) => {
          return saveCatalogFrameInfo(values)
        }}
      >
        {({ values, isSubmitting, setFieldValue, handleSubmit, resetForm, setSubmitting }) => (
          <Form onSubmit={handleSubmit} className="flex flex-col space-y-8 w-1/2">
            {isAdmin ? (
              <label className="text-gray-500 flex space-x-4">
                <span>{i18n.value('profile.company.labels.catalogFrameEnabled')}</span>
                <FormikToggle itemKey="enabled" labelOn="common.yes" labelOff="common.no" />
              </label>
            ) : null}
            <div>
              <RequiredLabel label={i18n.value('profile.company.labels.catalogFrameEmail')} isRequired />
              <InputFormik isEditable={!isSubmitting} itemKey="email" value={values.email} inputClass="w-full" />
            </div>
            <div>
              <RequiredLabel label={i18n.value('profile.company.labels.catalogFrameDomain')} isRequired={false} />
              <InputFormik isEditable={!isSubmitting} itemKey="domain" value={values.domain} inputClass="w-full" />
            </div>
            <div>
              <RequiredLabel label={i18n.value('profile.company.labels.catalogFramePriceExtra')} isRequired />
              <InputFormik
                isEditable={!isSubmitting}
                itemKey="priceExtra"
                value={values.priceExtra}
                inputClass="w-full"
              />
            </div>
            <label className="text-gray-500 flex space-x-4">
              <span>{i18n.value('profile.company.labels.catalogFrameShowPrices')}</span>
              <FormikToggle itemKey="showPrices" labelOn="common.yes" labelOff="common.no" />
            </label>
            <label className="text-gray-500 flex space-x-4">
              <span>{i18n.value('profile.company.labels.catalogFrameShowCertificate')}</span>
              <FormikToggle itemKey="showCertificate" labelOn="common.yes" labelOff="common.no" />
            </label>
            <label className="text-gray-500 flex space-x-4">
              <span>{i18n.value('profile.company.labels.catalogFrameAllowOrders')}</span>
              <FormikToggle itemKey="allowOrders" labelOn="common.yes" labelOff="common.no" />
            </label>
            <label className="text-gray-500 flex space-x-4">
              <span>{i18n.value('profile.company.labels.catalogFrameAutoOrder')}</span>
              <FormikToggle itemKey="autoOrder" labelOn="common.yes" labelOff="common.no" />
            </label>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <div className="flex space-x-2">
                <FormikSubmittingButtons
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  buttons={[
                    {
                      color: 'brown',
                      label: 'common.save',
                      action: ACTION_SAVE_CATALOG_INFO,
                      className: 'profile-button',
                    },
                  ]}
                />
                <BlueButton type="button" onClick={() => history.goBack()}>
                  Back
                </BlueButton>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <div className="mt-4">
        <h2 className="text-2xl">Use this code to add catalog to your site:</h2>
        <pre className="p-4 border">{iframeUrl}</pre>
      </div>
    </div>
  )
}

CatalogIframeSettings.propTypes = {
  isCatalogIFrameEditable: PropTypes.bool,
  isAdmin: PropTypes.bool,
  loadCatalogFrameInfo: PropTypes.func,
  saveCatalogFrameInfo: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  canSupervise: PropTypes.bool,
}

export default memo(connect(CatalogIframeSettings))
