import React, { memo } from 'react'
import { useFormikContext } from 'formik'
import { roundTwo } from 'root/modules/orders/constants'

// TODO: replace this with a hook

const RecalculateTotal = () => {
  const context = useFormikContext()
  const {
    values: { orderItems, deliveryPrice, additionalGoods },
    setFieldValue,
  } = context

  React.useEffect(() => {
    const subtotal = roundTwo(
      Object.values(orderItems)
        .filter((v) => !v.disabled)
        .reduce((acc, item) => acc + (Number.parseFloat(item.price) || 0), 0),
    )
    const subtotalAdditional = roundTwo(
      additionalGoods.filter((e) => !e.disabled).reduce((acc, e) => acc + (Number.parseFloat(e.price) || 0), 0),
    )
    setFieldValue('subtotal', subtotal)
    setFieldValue('subtotalAdditional', subtotalAdditional)
    setFieldValue('total', roundTwo(subtotal + (Number.parseFloat(deliveryPrice) || 0) + subtotalAdditional))
  }, [orderItems, deliveryPrice, additionalGoods, setFieldValue])

  return null
}

export default memo(RecalculateTotal)
