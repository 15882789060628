import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ACTION_MEMO_NEW_CANCELED,
  ACTION_MEMO_NEW_CREATED,
} from 'root/modules/orders/constants'
import NewMemoForm
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/NewMemoCreation/NewMemoForm'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import OrderSubHeader from 'root/components/OrderSubHeader'
import Diamonds, {
  CertificateColumn,
  IndexColumn,
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'

const Seller = ({ order, performOrderAction, readOnly = false }) => {
  let buttons = [
    {
      color: 'brown',
      label: 'orders.buttons.saveAndOfferMemo',
      action: ACTION_MEMO_NEW_CREATED,
      default: true,
    },
    {
      color: 'blue',
      label: 'orders.buttons.cancelMemoRequest',
      action: ACTION_MEMO_NEW_CANCELED,
    },
  ]
  if (readOnly) buttons = []
  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          buyer={order.buyerCompanyId.name}
          asSeller
        >
          <ViewOrderFiles order={order} doAction={performOrderAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds
          isEditable={false}
          highlightDeleted
          showDeleted={false}
          items={order.products}
          doAction={performOrderAction}
        >
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
        </Diamonds>
      </section>

      <section>
        <NewMemoForm isEditable={!readOnly} order={order}
                     performOrderAction={performOrderAction} buttons={buttons}/>
      </section>
    </>
  )
}

Seller.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(Seller)
