import { createAction } from 'redux-actions'

const NAMESPACE = 'APP::'

export const appInitStart = createAction(NAMESPACE + 'APP_INIT_START')
export const appInitSuccess = createAction(NAMESPACE + 'APP_INIT_SUCCESS')
export const appInitFailure = createAction(NAMESPACE + 'APP_INIT_FAILURE')
export const setProductsListMode = createAction(NAMESPACE + 'SET_PRODUCTS_LIST_MODE')

export const setUser = createAction(NAMESPACE + 'SET_USER')
export const updateUser = createAction(NAMESPACE + 'UPDATE_USER')
export const userLogout = createAction(NAMESPACE + 'USER_LOGOUT')
