import { connect } from 'react-redux'

import { loadProducts, performProductsAction } from 'root/modules/products/thunks'
import { getProductsData, isProductsListLoading } from 'root/modules/products/selectors'
import { resetProductsFilters, updateProductsFilters } from 'root/modules/products/actions'
import { getProfileCompanyData } from 'root/modules/profile/selectors'
import { loadCompanyCommonInfo } from 'root/modules/profile/thunks'

const mapStateToProps = (state) => ({
  data: getProductsData(state),
  company: getProfileCompanyData(state),
  isProductsListLoading: isProductsListLoading(state),
})

const mapDispatchToProps = {
  loadProducts,
  performProductsAction,
  updateFilters: updateProductsFilters,
  resetFilters: resetProductsFilters,
  loadCompanyCommonInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)
