import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import OrderListRow from 'root/components/OrderListRow'
import { DEAL_TYPE_MEMO, SECTION_SALES } from 'root/modules/orders/constants'
import EmptyResult from 'root/components/EmptyResult'
import { connect } from 'react-redux'
import { isAdmin, isMiddleman } from 'root/selectors'

const OrderListTable = ({ orders, section, activeDealType, isAdmin }) => {
  const showSupplier = isAdmin
  return (
    <>
      <div className="bg-gray-100 mt-5">
        <div className="h-10 mb-6 pl-4 flex items-center justify-between text-gray-500 container mx-auto lg:w-screen-lg-min">
          <div className="w-16 text-center">
            <span>{i18n.value('orders.list.tableHead.date')}</span>
          </div>
          <div className="w-32 text-center">
            <span>{i18n.value('orders.list.tableHead.number')}</span>
          </div>
          <div className="w-32 text-center">
            <span>{i18n.value('orders.list.tableHead.company.' + section)}</span>
          </div>
          <div className="w-32 text-center">
            <span>{i18n.value('orders.list.tableHead.status')}</span>
          </div>
          <div className="w-32 text-center">
            <span>{i18n.value('orders.list.tableHead.totalUsd')}</span>
          </div>
          {activeDealType !== DEAL_TYPE_MEMO && (
            <div className="w-24 text-center">
              <span>{i18n.value('orders.list.tableHead.timer')}</span>
            </div>
          )}
          <div className="w-24 text-center" />
        </div>
      </div>
      {orders.length > 0 ? (
        orders.map((item) => (
          <OrderListRow withCounterPart={showSupplier} key={item.id} item={item} section={section} />
        ))
      ) : (
        <EmptyResult />
      )}
    </>
  )
}

OrderListTable.propTypes = {
  orders: PropTypes.array.isRequired,
  section: PropTypes.string.isRequired,
  activeDealType: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
}

export default memo(connect((state) => ({ isAdmin: isAdmin(state) || isMiddleman(state) }), null)(OrderListTable))
