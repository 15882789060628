import { ROLE_OWNER, ROLE_SUPERVISOR } from 'root/constants'

export const isAppInitialized = (state) => state.app.isInitialized
export const getProductsListMode = (state) => state.settings.productsListMode

const getState = (state) => state.data

export const getUserData = (state) => getState(state).user
export const getUserCompanyId = (state) => getUserData(state)?.companyId?.id
export const getUserId = (state) => getUserData(state)?.id
export const getUserName = (state) => getUserData(state)?.firstName
export const isAuthorized = (state) => !!getUserData(state)
export const isActivated = (state) => !!getUserData(state)?.activated
export const canSupervise = (state) => [ROLE_SUPERVISOR, ROLE_OWNER].includes(getUserData(state)?.mainRole)
export const isOwner = (state) => getUserData(state)?.mainRole === ROLE_OWNER
export const onlyManager = (state) => !canSupervise(state)
export const isAdmin = (state) => getUserData(state)?.isAdmin
export const isMiddleman = (state) =>
  getUserData(state)?.companyId?.id === '4f589a92-b820-4d1c-beb9-2770c8539530' || // takuya
  getUserData(state)?.companyId?.id === '36728dd8-5761-4eb7-b446-18fd2cd12dcf' // lgdeal
