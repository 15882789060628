import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.3214 7.19635L0.845617 0.0536672C0.599194 -0.0582349 0.305154 0.0084302 0.133729 0.22033C-0.0388859 0.43223 -0.0448381 0.733413 0.119444 0.951265L5.20861 7.73682L0.119444 14.5224C-0.0448381 14.7402 -0.0388859 15.0426 0.132539 15.2533C0.248012 15.3973 0.420627 15.4747 0.595623 15.4747C0.680145 15.4747 0.764666 15.4569 0.844426 15.42L16.3202 8.27728C16.5321 8.17966 16.6667 7.96895 16.6667 7.73682C16.6667 7.50468 16.5321 7.29397 16.3214 7.19635Z"
      fill="#90B9D7"
    />
  </svg>
)

export default memo(SvgComponent)
