import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'root/components/Spinner'
import BlueButton from 'root/components/Buttons/BlueButton'
import BrownButton from 'root/components/Buttons/BrownButton'

const FormikSubmittingButtons = ({
  buttons,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  slim = false,
  dirty = true,
}) => {
  return isSubmitting ? (
    <Spinner/>
  ) : (
    <div className="flex space-x-4">
      {buttons.map(
        ({ color, label, className, params, action = '', ...props }, index) => {
          const ButtonComponent = color === 'blue' ? BlueButton : BrownButton
          return (
            <ButtonComponent
              slim={slim}
              key={index}
              label={label}
              className={className}
              disabled={isSubmitting || (props.default && !dirty)}
              onClick={async () => {
                if (params) setFieldValue('buttonSubmitParams', params, false)
                await setFieldValue('action', action, false)
                handleSubmit()
              }}
              {...props}
            />
          )
        })}
    </div>
  )
}

FormikSubmittingButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      params: PropTypes.object,
      action: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  slim: PropTypes.bool,
  dirty: PropTypes.bool,
}

export default memo(FormikSubmittingButtons)
