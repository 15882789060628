import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'
import { toClassName, toStyle } from 'root/constants'
import styles from 'root/modules/chat/components/OrderChat/components/Chat/components/ChatMessage/components/ChatMessageImages/styles'

const ChatMessageDocuments = ({ documents, hasUserName, hasMessage }) => (
  <div
    style={toStyle([styles.flex, hasUserName && styles.paddingTop])}
    className={toClassName([!hasMessage && 'pb-8'])}
  >
    {documents.map((doc) => (
      <a
        href={doc.url}
        className="flex text-blue-dark break-all leading-tight items-center space-x-2 underline"
        target="_blank"
        key={doc.id}
        rel="noreferrer"
      >
        <div className="flex-0">
          <DocumentRowIcon width={18} height={18} />
        </div>
        <span>{doc.originalName}</span>
      </a>
    ))}
  </div>
)

ChatMessageDocuments.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasUserName: PropTypes.bool.isRequired,
  hasMessage: PropTypes.bool.isRequired,
}

export default memo(ChatMessageDocuments)
