import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M19.375 5H24.3375C24.75 5 25.1375 5.20625 25.3688 5.54375L27.5 9.375H28.125C28.825 9.375 29.375 9.8375 29.375 10.7562V15C29.375 15.6875 28.8125 16.25 28.125 16.25H26.875"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path d="M8.75 0.625H19.375V16.25H10" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M5 16.25H1.875" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path
        d="M26.8748 16.8805C26.8748 18.2555 25.7561 19.3742 24.3686 19.3742C22.9873 19.3742 21.8623 18.2555 21.8623 16.8805C21.8623 15.5055 22.9811 14.3867 24.3686 14.3867C25.7561 14.3867 26.8748 15.4992 26.8748 16.8805Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M10.0125 16.8805C10.0125 18.2555 8.89375 19.3742 7.50625 19.3742C6.11875 19.3742 5 18.2555 5 16.8805C5 15.5055 6.11875 14.3867 7.50625 14.3867C8.89375 14.3867 10.0125 15.4992 10.0125 16.8805Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path d="M19.375 16.25H21.875" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M20.625 10H25V9.375L23.75 7.5" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M-2 8.125H8.625" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M5 4.375H14.375" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M3.75 4.375H2.5" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path d="M7.5 0.625H6.25" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" />
      <path
        d="M11 9.00266L12.101 8H13.124L12.7999 8.29459H12.2512L11.4569 9.01804L13.2652 10.9547L13.7785 10.4046L12.4689 9.00266L13.5705 8H15.899L17 9.00266L14.7355 11.4286L14.2097 10.8642L14.4309 10.627L14.7355 10.9547L16.5431 9.01804L15.7488 8.29459H14.7536L15.5304 9.00266L13.2652 11.4286L11 9.00266ZM12.9265 9.01804L13.9997 10.1674L15.0728 9.01804L14.2792 8.29459H13.7215L12.9265 9.01804Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="30" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(SvgComponent)
