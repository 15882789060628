import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.928897 15.4835C2.41511 20.0922 6.76925 23.4336 11.9013 23.4336C18.2642 23.4336 23.4195 18.3179 23.4195 12.0039C23.4195 5.68992 18.2642 0.574219 11.9013 0.574219C8.85923 0.574219 5.91003 1.5651 3.85488 3.47772L2.22933 1.89923L0.580566 10.3217L9.05662 8.54733L7.43107 6.95732C8.32512 5.9434 10.0203 5.13687 11.9129 5.13687C16.36 5.13687 19.971 8.72016 19.971 13.133C19.971 17.5459 16.36 21.1292 11.9129 21.1292"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.60205 11.9943L11.901 14.2871L15.9301 10.2891"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
