import { connect } from 'react-redux'

import { getActiveOrderData, isSeller } from 'root/modules/orders/selectors'
import { getUserId } from 'root/selectors'
import { isChatOpened } from 'root/modules/chat/selectors'
import { toggleIsOpen } from 'root/modules/chat/actions'

const mapStateToProps = (state) => {
  const { sellerCompanyId, buyerCompanyId, id } = getActiveOrderData(state)
  const { name } = isSeller(state) ? buyerCompanyId : sellerCompanyId
  return {
    companyName: name,
    orderId: id,
    userId: getUserId(state),
    opened: isChatOpened(state),
  }
}

export default connect(mapStateToProps, {
  toggleIsOpen,
})
