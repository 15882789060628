import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = []

export default handleActions(
  {
    [actions.loadCompanyTeamStart]: (state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadCompanyTeamSuccess]: (state, { payload: companyTeam }) => companyTeam,
  },
  defaultState,
)
