import { combineReducers } from 'redux'

import app from './app'
import settings from './settings'
import user, { userPersistKey } from './user'

import { userLogout } from 'root/actions'

import orders from '../modules/orders/reducers'
import products from '../modules/products/reducers'
import memoAgreements from '../modules/memo-agreements/reducers'
import profile from '../modules/profile/reducers'
import chat from '../modules/chat/reducers'
import { deleteCookie, LGDEAL_COOKIE_INFO } from 'root/constants'

const dataReducer = combineReducers({ user, orders, products, memoAgreements, profile })

const persistKeys = [userPersistKey]

const getLocalStorageKey = (key) => `persist:${key}`

const data = (state, action) => {
  if (action.type === userLogout.toString()) {
    state = undefined
    persistKeys.forEach((key) => localStorage.removeItem(getLocalStorageKey(key)))
    deleteCookie(LGDEAL_COOKIE_INFO)
  }
  return dataReducer(state, action)
}

export default combineReducers({ app, settings, data, chat })
