import React, { memo } from 'react'
import PropTypes from 'prop-types'
import WaitResponseScreen
  from 'root/modules/orders/pages/components/WaitResponseScreen'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import Diamonds, {
  CertificateColumn,
  IndexColumn,
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import ReadonlyMemoTerms
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ReadonlyMemoTerms'

const NewMemoConfirmed = ({ order, performOrderAction, readOnly = false }) => {
  let buttons = order.timer.isExpired
    ? [
      {
        color: 'blue',
        label: 'orders.buttons.cancelMemoRequest',
        action: 'new_memo_canceled',
      }]
    : []
  const labels = {
    heading: 'MEMO is confirmed',
    headingBeforeTimer: 'orders.labels.waitSupplierUploadDoc',
    timer: 'orders.labels.supplierReplyTimer',
  }
  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          seller={order.sellerCompanyId.name}
          asSeller={false}
        >
          <ViewOrderFiles order={order} doAction={performOrderAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds isEditable={false} showDeleted={false} items={order.products}>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
        </Diamonds>
      </section>

      <section>
        <ReadonlyMemoTerms order={order}/>
      </section>

      <section>
        <WaitResponseScreen order={order}
                            performOrderAction={performOrderAction}
                            buttons={buttons} labels={labels}/>
      </section>
    </>
  )
}

NewMemoConfirmed.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(NewMemoConfirmed)
