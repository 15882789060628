import React, { memo } from 'react'
import Column from './Column'

const CertificateColumn = ({ children, ...other }) => (
  <Column
    label="order.diamonds.certificate"
    columnClass="w-32 text-center"
    className="w-32 text-center text-blue-dark"
    {...other}
  >
    {children ||
      (({ item }) => (
        <a target="_blank" className="hover:underline" href={item.certificateUrl} rel="noreferrer">
          {item.certificateNumber}
        </a>
      ))}
  </Column>
)

CertificateColumn.propTypes = Column.propTypes

export default memo(CertificateColumn)
