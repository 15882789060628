import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.19829 4.52124L8.39658 1.14441e-05H0L4.19829 4.52124Z"
      fill="#AFBEC4"
    />
  </svg>
)

export default memo(SvgComponent)
