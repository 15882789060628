import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import i18n from 'root/i18n'
import ProductPreview from './components/ProductPreview'
import Spinner from 'root/components/Spinner'

const SelectedRowItem = ({ product, performProductsAction, isCatalog }) => {
  const [isLoading, setIsLoading] = useState(false)

  const performItemAction = useCallback(
    (action, itemId) => {
      setIsLoading(true)
      performProductsAction(action, { items: [itemId] }).finally(() => setIsLoading(false))
    },
    [performProductsAction],
  )

  return (
    <>
      <div className="flex-none ml-3 w-102">
        <div className="mb-6 font-normal text-center text-gray-500">{i18n.value('products.labels.diamondInfo')}</div>
        <div className="flex flex-col items-center">
          {!product ? (
            <>
              <div className="mt-20 mb-12 text-brown-dim">
                <ExclaimIcon width={50} height={50} />
              </div>
              <div className="w-56 text-sm text-center text-gray-600">
                <p>{i18n.value('products.labels.hoverTableInfo')}</p>
              </div>
            </>
          ) : isLoading ? (
            <Spinner />
          ) : (
            <ProductPreview isCatalog={isCatalog} product={product} performItemAction={performItemAction} />
          )}
        </div>
      </div>
    </>
  )
}

SelectedRowItem.propTypes = {
  product: PropTypes.object,
  performProductsAction: PropTypes.func.isRequired,
  isCatalog: PropTypes.bool,
}

export default memo(SelectedRowItem)
