import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="29"
    height="37"
    viewBox="0 0 29 37"
    fill="none"
    className="stroke-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5 4.79883H26.5C27.3288 4.79883 28 5.47903 28 6.31883V34.4388C28 35.2786 27.3288 35.9588 26.5 35.9588H2.5C1.67125 35.9588 1 35.2786 1 34.4388V6.31883C1 5.47903 1.67125 4.79883 2.5 4.79883H5.5"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M23.5001 7.83984H25.0001V32.9198H4.00012V7.83984H5.50012"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M19.7502 4.04H17.5002C17.5002 2.36135 16.1568 1 14.5002 1C12.8437 1 11.5002 2.36135 11.5002 4.04H9.25024C8.00712 4.04 7.00024 5.0603 7.00024 6.32V8.6H22.0002V6.32C22.0002 5.0603 20.9934 4.04 19.7502 4.04Z"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M14.5005 3.2793V4.7993" stroke="current-color" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M7.74988 14.6802L9.65863 16.3379L12.2499 13.1602"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.74988 20.7602L9.65863 22.418L12.2499 19.2402"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.74988 26.8403L9.65863 28.4981L12.2499 25.3203"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2505 14.6797H16.0005H21.2505Z"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2505 20.7598H16.0005H21.2505Z"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2505 26.8398H16.0005H21.2505Z"
      stroke="current-color"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
