import React, { memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType, toClassName } from 'root/constants'
import i18n from 'root/i18n'
import { useEffectOnce } from 'root/hooks'

const Card = ({ help, label, subheader, completed, current = false, last = false, Icon, children }) => {
  const self = useRef()

  useEffectOnce(() => {
    if (current && self && self.current) {
      window.scrollTo({ top: self.current.offsetTop, behavior: 'smooth' })
    }
  })

  return (
    <div className="flex" ref={self}>
      <div className="flex flex-col items-center">
        <i
          className={toClassName([
            'flex-shrink-0 border-2 rounded-full block w-16 h-16 flex items-center justify-center',
            completed || current ? 'text-white bg-brown-dim border-brown-dim' : 'text-brown-dim border-white-smoke ',
          ])}
        >
          {Icon && <Icon />}
        </i>
        {!last && <div className={toClassName(['w-1 h-full', completed ? 'bg-brown-dim' : 'bg-white-smoke'])} />}
      </div>
      <div className="relative ml-32 mb-8 shadow shadow-lg border border-gray-100 w-128 px-8 py-10 flex flex-col space-y-4">
        {current && help ? (
          <div className="group absolute right-0 top-0 mr-4 mt-4 flex flex-col">
            <span className="bg-brown-dim-light cursor-pointer text-white font-bold w-6 h-6 text-center rounded-full ml-auto z-10">
              ?
            </span>
            <div className="hidden text-brown-dim group-hover:block w-84 bg-white p-4 border rounded mr-2 -mt-2 shadow-lg">
              {help}
            </div>
          </div>
        ) : null}
        <div
          className={toClassName([
            'text-3xl font-damascus flex space-x-4',
            completed || current ? 'text-brown-late' : 'text-gray-500',
          ])}
        >
          <span>{i18n.value(label)}</span>
          {subheader ? <span>{subheader}</span> : null}
        </div>
        {!current && !completed ? null : children}
      </div>
    </div>
  )
}

Card.propTypes = {
  label: PropTypes.string.isRequired,
  subheader: PropTypes.any,
  Icon: PropTypes.elementType,
  completed: PropTypes.bool.isRequired,
  children: childrenPropType,
  last: PropTypes.bool,
  current: PropTypes.bool,
  help: PropTypes.string,
}

export default memo(Card)
