import React, { memo } from 'react'
import PropTypes from 'prop-types'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import OrderSubHeader from 'root/components/OrderSubHeader'
import Diamonds, {
  CertificateColumn,
  DeliveryColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import WaitResponseScreen
  from 'root/modules/orders/pages/components/WaitResponseScreen'
import {
  ACTION_CANCEL_MEMO_REQUEST,
  STATE_NEW_MEMO_REQUEST,
} from 'root/modules/orders/constants'
import ViewOrderFiles from 'root/components/ViewOrderFiles'

const MemoRequestSeller = ({ order, doAction }) => {
  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          buyer={order.buyerCompanyId.name}
          asSeller
        >
          <ViewOrderFiles order={order} doAction={doAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds isEditable={false} highlightDeleted showDeleted={false}
                  items={order.products} doAction={doAction}>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
          <RefPriceColumn/>
          <RapaportColumn/>
          <PriceColumn/>
          <DeliveryColumn/>
        </Diamonds>
      </section>

      <WaitResponseScreen
        order={order}
        performOrderAction={doAction}
        buttons={[
          order.actions.some(
            (action) => action.url === ACTION_CANCEL_MEMO_REQUEST) && {
            label: 'orders.buttons.cancelMemoRequest',
            color: 'blue',
            action: ACTION_CANCEL_MEMO_REQUEST,
          },
        ].filter((v) => v)}
        labels={{
          heading: 'New MEMO request sent to customer',
          headingBeforeTimer:
            order.state.key === STATE_NEW_MEMO_REQUEST
              ? 'orders.labels.waitCustomerConfirmMemo'
              : 'orders.labels.waitCustomerConfirmMemoSelection',
          timer: 'orders.labels.customerReplyTimer',
        }}
      />
    </>
  )
}
MemoRequestSeller.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
}
export default memo(MemoRequestSeller)
