import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ width = 32, height = 32, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 5.33398C15.2636 5.33398 14.6666 5.93094 14.6666 6.66732V19.3727L9.04216 13.742C8.49873 13.198 7.61764 13.198 7.0742 13.742C6.53077 14.286 6.53077 15.1681 7.0742 15.7121L14.946 23.5926C15.2356 23.8825 15.6209 24.0179 16 23.9989C16.3791 24.0179 16.7644 23.8825 17.0539 23.5926L24.9258 15.7121C25.4692 15.1681 25.4692 14.286 24.9258 13.742C24.3823 13.198 23.5012 13.198 22.9578 13.742L17.3333 19.3727V6.66732C17.3333 5.93094 16.7363 5.33398 16 5.33398Z"
      fill="#90B9D7"
    />
    <path
      d="M6.66663 28.0007C6.66663 27.2643 7.26358 26.6673 7.99996 26.6673H24C24.7363 26.6673 25.3333 27.2643 25.3333 28.0007C25.3333 28.737 24.7363 29.334 24 29.334H7.99996C7.26358 29.334 6.66663 28.737 6.66663 28.0007Z"
      fill="#90B9D7"
    />
  </svg>
)
SvgComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default memo(SvgComponent)
