import { useState, useEffect, useCallback } from 'react'

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useEffectOnce = (effect) => useEffect(effect, [])

export const useRefreshing = (loadData) => {
  const [refreshing, setRefreshing] = useState(false)
  const onRefresh = useCallback(() => {
    setRefreshing(true)
    loadData(true)
      .finally(() => setRefreshing(false))
      .catch(console.warn)
  }, [loadData])
  return [refreshing, onRefresh]
}

export const useAsyncLoad = (callback) => {
  const [loading, setLoading] = useState(false)
  const doLoad = useCallback(
    (...args) => {
      setLoading(true)
      return callback(...args)
        .finally(() => setLoading(false))
        .catch(console.warn)
    },
    [callback],
  )
  return [loading, doLoad]
}

export const useToggle = (initialState) => {
  const [value, setValue] = useState(initialState)
  const toggleValue = useCallback(() => setValue((value) => !value), [])
  return [value, toggleValue, setValue]
}
