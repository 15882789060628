import React, { memo, useState } from 'react'
import i18n from 'root/i18n'
import DocumentsIcon from 'root/icons/DocumentsIcon'
import UploadedFilesList from 'root/modules/orders/pages/components/UploadedFilesList'
import PropTypes from 'prop-types'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import Modal from 'root/components/Modal'
import { childrenPropType } from 'root/constants'

const ViewMemoDocs = ({ memo, supplementaryDocs }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const heading = i18n.value('orders.labels.memoFilesList') + ' #' + memo.number

  return (
    <>
      <div onClick={openModal} className="text-blue-skyblue cursor-pointer flex items-center hover:underline">
        <DocumentsIcon width={20} height={20} />
        <span className={'ml-2 pt-1'}>{i18n.value('orders.labels.viewMemoDocs')}</span>
      </div>
      {modalIsOpen && (
        <Modal onClick={closeModal}>
          <div className="container items-center mx-auto m-12">
            <OrderHeading label={heading} />
            <UploadedFilesList files={memo.files} documentId={memo.id} isMemo />
            {supplementaryDocs}
          </div>
        </Modal>
      )}
    </>
  )
}

ViewMemoDocs.propTypes = {
  memo: PropTypes.object.isRequired,
  supplementaryDocs: childrenPropType,
}

export default memo(ViewMemoDocs)
