import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6334 24C20.9307 24 19.3325 23.3135 18.1302 22.0655C16.928 20.8174 16.2668 19.1569 16.2668 17.3905C16.2668 15.6229 16.928 13.9637 18.1302 12.7156C19.3325 11.4675 20.9307 10.7811 22.6334 10.7811C24.3361 10.7811 25.9343 11.4675 27.1365 12.7156C28.3388 13.9637 29 15.6229 29 17.3905C29 19.1582 28.3388 20.8187 27.1365 22.0655C25.9356 23.3122 24.3361 24 22.6334 24ZM6.39668 23.901C6.00203 23.901 5.62306 23.7612 5.31466 23.4981C4.96445 23.1996 4.74883 22.775 4.7057 22.3042C4.66258 21.8335 4.79849 21.3749 5.08598 21.0114L21.2783 0.763778C21.5657 0.40156 21.9748 0.177717 22.4282 0.132949C22.8817 0.0895371 23.3234 0.229269 23.6736 0.529083C24.0238 0.827539 24.2394 1.25216 24.2825 1.72291C24.3257 2.19366 24.1898 2.6522 23.901 3.01577L7.70868 23.262C7.42119 23.6242 7.01217 23.8494 6.55871 23.8928C6.50514 23.8983 6.45025 23.901 6.39668 23.901ZM6.36662 13.2189C4.66519 13.2189 3.0657 12.5298 1.86346 11.279C0.661229 10.0295 0 8.37171 0 6.60946C0 4.84178 0.662536 3.18128 1.86346 1.93454C3.0657 0.686451 4.66519 0 6.36662 0C8.06935 0 9.66754 0.686451 10.8698 1.93454C12.072 3.18264 12.7332 4.84178 12.7332 6.60946C12.7332 8.37714 12.072 10.0363 10.8698 11.2844C9.66885 12.5325 8.06935 13.2189 6.36662 13.2189Z"
      fill="#AA8A9F"
    />
    <path
      d="M8.93863 6.60939C8.93863 5.87546 8.65245 5.21207 8.18854 4.73183C7.72594 4.25158 7.08562 3.95312 6.37996 3.95312C5.67299 3.95312 5.03398 4.25023 4.57138 4.73183C4.10878 5.21207 3.82129 5.87682 3.82129 6.60939C3.82129 7.34332 4.10747 8.00671 4.57138 8.48696C5.03398 8.9672 5.6743 9.26566 6.37996 9.26566C7.08693 9.26566 7.72594 8.96856 8.18854 8.48696C8.65245 8.00671 8.93863 7.34332 8.93863 6.60939Z"
      fill="white"
    />
    <path
      d="M6.37904 9.46816C5.64332 9.46816 4.95204 9.17106 4.43063 8.62977C3.91054 8.08983 3.62305 7.37218 3.62305 6.6084C3.62305 5.84462 3.90923 5.12697 4.43063 4.58704C4.95073 4.0471 5.64202 3.75 6.37904 3.75C7.11475 3.75 7.80604 4.0471 8.32613 4.58704C8.84623 5.12697 9.13372 5.84462 9.13372 6.6084C9.13372 7.37218 8.84754 8.08983 8.32613 8.62977C7.80604 9.17106 7.11475 9.46816 6.37904 9.46816ZM6.37904 4.15563C5.74787 4.15563 5.15459 4.41067 4.70898 4.87464C4.26206 5.3386 4.01639 5.95451 4.01639 6.6084C4.01639 7.26365 4.26206 7.87956 4.70898 8.34217C5.1559 8.80613 5.74917 9.06118 6.37904 9.06118C7.01021 9.06118 7.60349 8.80613 8.0491 8.34217C8.49602 7.8782 8.74169 7.2623 8.74169 6.6084C8.74169 5.95315 8.49602 5.33725 8.0491 4.87464C7.60349 4.41067 7.01021 4.15563 6.37904 4.15563Z"
      fill="white"
    />
    <path
      d="M22.6309 14.7344C21.924 14.7344 21.285 15.0315 20.8224 15.5131C20.3598 15.9933 20.0723 16.6581 20.0723 17.3906C20.0723 18.1246 20.3585 18.788 20.8224 19.2696C21.285 19.7498 21.9253 20.0469 22.6309 20.0469C23.3379 20.0469 23.9769 19.7498 24.4408 19.2696C24.9034 18.7893 25.1896 18.1246 25.1896 17.3906C25.1896 16.6567 24.9034 15.9933 24.4408 15.5131C23.9782 15.0328 23.3379 14.7344 22.6309 14.7344Z"
      fill="white"
    />
    <path
      d="M22.6323 20.2508C21.8966 20.2508 21.2053 19.9537 20.6852 19.4137C20.1651 18.8738 19.8789 18.1561 19.8789 17.391C19.8789 16.6272 20.1651 15.9096 20.6852 15.3696C21.2053 14.8297 21.8966 14.5312 22.6323 14.5312C23.368 14.5312 24.0593 14.8284 24.5807 15.3696C25.1008 15.9096 25.387 16.6272 25.387 17.391C25.387 18.1548 25.1008 18.8738 24.5807 19.4137C24.0606 19.9537 23.368 20.2508 22.6323 20.2508ZM22.6323 14.9396C22.0011 14.9396 21.4078 15.1946 20.9622 15.6586C20.5153 16.1212 20.2696 16.7371 20.2696 17.3924C20.2696 18.0476 20.5153 18.6635 20.9622 19.1275C21.4078 19.5901 22.0011 19.8451 22.6323 19.8451C23.2635 19.8451 23.8567 19.5901 24.3037 19.1275C24.7493 18.6649 24.9949 18.049 24.9949 17.3924C24.9949 16.7371 24.7493 16.1212 24.3037 15.6586C23.8567 15.1946 23.2635 14.9396 22.6323 14.9396Z"
      fill="white"
    />
  </svg>
)

export default memo(SvgComponent)
