import instance from './instance'
import { transformProducts, transformProductsWithMeta } from 'root/api/transformers'

export const downloadStock = () => {
  return instance({
    method: 'get',
    downloadFile: true,
    url: `/products/export-xls`,
    auth: true,
  })
}

export const downloadXlsTemplate = () => {
  return instance({
    method: 'get',
    downloadFile: true,
    url: `/products/import-xls-template`,
    auth: true,
  })
}

export const uploadXlsProducts = (file, update) => {
  return instance({
    method: 'post',
    url: `/products/import-xls?update=${update}`,
    upload: file,
    auth: true,
  })
}

export const getProduct = (uid) => {
  uid = uid === 'new' ? '' : uid
  return instance({
    method: 'get',
    url: `/products/edit?id=${uid}`,
    auth: true,
    transformResponse: transformProductsWithMeta,
  })
}

export const getProducts = (p, q, filters, cancelToken) => {
  const autocomplete = p === undefined
  return instance({
    method: 'get',
    url: `/products`,
    params: { p, q, attr: autocomplete ? 'name' : undefined, ...filters, ipp: 25 },
    auth: true,
    transformResponse: autocomplete ? undefined : transformProducts,
    cancelToken,
  })
}

export const productSave = (data) => {
  return instance({
    method: 'post',
    url: `/products/save`,
    data,
    auth: true,
    transformResponse: transformProductsWithMeta,
  })
}

export const uploadProductImage = (uid, file) => {
  return instance({
    method: 'post',
    url: `/files/upload?type=image&id=${uid}&modelClass=\\App\\Core\\Models\\Products\\Product`,
    auth: true,
    upload: file,
    coreServer: true,
  })
}

export const uploadCompanyFile = (uid, file) => {
  return instance({
    method: 'post',
    url: `/files/upload?type=document&id=${uid}&modelClass=\\App\\Core\\Models\\System\\Company`,
    auth: true,
    upload: file,
    coreServer: true,
  })
}

export const productsAction = (action, data) => {
  return instance({
    method: 'post',
    url: `/products/${action}`,
    data: data,
    auth: true,
    transformResponse: transformProducts,
  })
}

export const productAction = (uid, action, data) => {
  return instance({
    method: 'post',
    url: `/products/${uid}/${action}`,
    data,
    auth: true,
    transformResponse: transformProductsWithMeta,
  })
}
