import { COLORS } from 'root/constants'

export default {
  container: {
    borderBottomColor: COLORS.MOUSE,
    borderBottomWidth: 1,
    margin: '0 10px 15px',
    padding: '5px 0',
    overflow: 'hidden',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  imageBox: {
    display: 'inline-block',
    position: 'relative',
  },
  image: {
    marginRight: 5,
    borderRadius: 5,
    height: 40,
    width: 40,
  },
  removeImage: {
    cursor: 'pointer',
    position: 'absolute',
    right: 3,
    top: -5,
    transform: [{ scale: 0.8 }],
  },
}
