import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 50 50" className="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 50.0007C38.8071 50.0007 50 38.8076 50 25.0003C50 11.193 38.8071 0 25 0C11.1929 0 0 11.193 0 25.0003C0 38.8076 11.1929 50.0007 25 50.0007Z"
      fillOpacity="0.7"
      fill="currentColor"
    />
    <path
      d="M27.4095 37.7027C27.607 36.372 26.6884 35.1332 25.3578 34.9357C24.0272 34.7383 22.7884 35.6569 22.5909 36.9876C22.3935 38.3182 23.3121 39.5571 24.6427 39.7545C25.9733 39.952 27.2121 39.0334 27.4095 37.7027Z"
      fill="#F9F8F7"
    />
    <path
      d="M28.4561 13.6403L26.6493 30.9095C26.5618 31.746 25.8556 32.382 25.0147 32.382H24.9844C24.1435 32.382 23.4373 31.746 23.3498 30.9095L21.543 13.6403C21.543 11.7502 23.0748 10.2183 24.9648 10.2183H25.0343C26.9243 10.2183 28.4561 11.7494 28.4561 13.6403Z"
      fill="#F9F8F7"
    />
  </svg>
)
SvgComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default memo(SvgComponent)
