import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import OrderSubHeader from 'root/components/OrderSubHeader'
import i18n from 'root/i18n'
import FormikToggle from 'root/components/FormikToggle'
import ResponsibleSelectorFormik from 'root/modules/orders/pages/components/ResponsibleSelector/formik'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TextLabel from 'root/components/TextLabel'
import CheckIcon from 'root/icons/CheckIcon'
import * as yup from 'yup'
import { getValidate, trimInteger } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import { FormikContext } from 'formik'

const schema = yup.number().required().positive().integer().min(0).max(255)
const validate = getValidate(schema)

const ReturnTermsSection = ({ values, isEditable, isOpened = true }) => {
  const formContext = React.useContext(FormikContext)
  return (
    <ToggleableSection labelTop="order.labels.returnTerms" isOpened={isOpened} withoutHeader>
      <div className="flex mt-4 mb-6">
        <OrderSubHeader label="order.labels.returnTerms" />
        <div className="ml-auto flex items-center justify-center text-brown-dim space-x-4">
          {isEditable ? (
            <label className="text-gray-500 flex space-x-4">
              <span>{i18n.value('orders.labels.returnAvailability')}</span>
              <FormikToggle itemKey="returnAvailability" labelOn="common.yes" labelOff="common.no" />
            </label>
          ) : (
            <>
              <div className="flex">
                {!isEditable && values?.termsChanges?.returnAvailability && (
                  <i className="mr-1 text-brown-dim">
                    <ExclaimIcon width={15} height={15} />
                  </i>
                )}
                <TextLabel label="orders.labels.returnAvailability" className="mr-4" />
              </div>
              {values.returnAvailability && <CheckIcon />}
              <span>{i18n.value(values.returnAvailability ? 'common.yes' : 'common.no')}</span>
            </>
          )}
        </div>
      </div>
      {values.returnAvailability && (
        <div className="flex text-gray-500 space-x-4">
          <div className="space-y-2">
            <div className="flex">
              {i18n.value('orders.labels.deliveryPaymentResponsible')}
              {!isEditable && values?.termsChanges?.returnDeliveryResponsible && (
                <i className="ml-1 text-brown-dim">
                  <ExclaimIcon width={15} height={15} />
                </i>
              )}
            </div>
            <div className="flex">
              {i18n.value('orders.labels.returnPeriod')}
              {!isEditable && values?.termsChanges?.returnPeriodDays && (
                <i className="ml-1 text-brown-dim">
                  <ExclaimIcon width={15} height={15} />
                </i>
              )}
            </div>
            <div className="flex">
              {i18n.value('orders.labels.moneyReturnAfterGoodsReturnPeriod')}
              {!isEditable && values?.termsChanges?.returnMoneyDays && (
                <i className="ml-1 text-brown-dim">
                  <ExclaimIcon width={15} height={15} />
                </i>
              )}
            </div>
          </div>
          <div className="space-y-2">
            <div className="-ml-8">
              <ResponsibleSelectorFormik
                itemKey="isDeliveryResponsibleSeller"
                isSeller={values.isDeliveryResponsibleSeller}
                isEditable={isEditable}
              />
            </div>
            <InputTermFormik
              inputClass="w-16"
              labelAfter="common.days"
              itemKey="returnPeriodDays"
              value={values.returnPeriodDays}
              validate={validate}
              isEditable={isEditable}
              onChange={(e) => {
                formContext.setFieldValue(`returnPeriodDays`, trimInteger(e.target.value))
              }}
            />
            <InputTermFormik
              inputClass="w-16"
              labelAfter="common.days"
              itemKey="returnMoneyDays"
              validate={validate}
              value={values.returnMoneyDays}
              isEditable={isEditable}
              onChange={(e) => {
                formContext.setFieldValue(`returnMoneyDays`, trimInteger(e.target.value))
              }}
            />
          </div>
        </div>
      )}
    </ToggleableSection>
  )
}
ReturnTermsSection.propTypes = {
  values: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool,
}

export default memo(ReturnTermsSection)
