import React, { memo, useCallback, useEffect, useState } from 'react'
import connect from './connect'
import PropTypes from 'prop-types'
import Spinner from 'root/components/Spinner'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'
import * as Yup from 'yup'
import i18n from 'root/i18n'
import { ErrorMessage, Form, Formik } from 'formik'
import { ACTION_SAVE_INVOICE_TERMS } from 'root/modules/profile/constants'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import { UpDownSprite } from 'root/icons'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import ResponsibleSelectorFormik from 'root/modules/orders/pages/components/ResponsibleSelector/formik'
import { getDeliveryPaymentOnChange, RESPONSIBLE_BUYER, RESPONSIBLE_SELLER } from 'root/modules/orders/constants'
import OrderSubHeader from 'root/components/OrderSubHeader'
import FormikToggle from 'root/components/FormikToggle'
import TextLabel from 'root/components/TextLabel'
import CheckIcon from 'root/icons/CheckIcon'
import OrderSpecialTermsFormik from 'root/modules/orders/pages/components/OrderSpecialTerms/formik'
import { useHistory } from 'react-router-dom'
import { ensureBoolean } from 'root/constants'

const FIELD_LABELS = {
  sitValidity: i18n.value('order.labels.validFor'),
  sitBeforeShipment: i18n.value('orders.labels.paymentBeforeDelivery'),
  sitAfterDelivery: i18n.value('orders.labels.paymentAfterDelivery'),
  sitTaxesResponsible: i18n.value('orders.labels.taxResponsible'),
  sitCommissionsResponsible: i18n.value('orders.labels.bankResponsible'),
  sitReturnAvailability: i18n.value('orders.labels.returnAvailability'),
  sitReturnPaymentResponsible: i18n.value('orders.labels.returnPaymentResponsible'),
  sitReturnPeriodDays: i18n.value('orders.labels.returnPeriod'),
  sitSpecialTerms: i18n.value('orders.labels.sitSpecialTerms'),
}

const InvoiceTermsSchema = Yup.object().shape({
  sitSpecialTerms: Yup.string().max(1000, FIELD_LABELS['sitSpecialTerms'] + ' must be less then 1000'),
  sitValidity: Yup.number()
    .min(1, FIELD_LABELS['sitValidity'] + ' must be more than or equal to 1')
    .max(366, FIELD_LABELS['sitValidity'] + ' must be less than or equal to 366')
    .required(FIELD_LABELS['sitValidity'] + ' is required'),
  sitBeforeShipment: Yup.number()
    .min(0, FIELD_LABELS['sitBeforeShipment'] + ' must be more than or equal to 0')
    .max(100, FIELD_LABELS['sitBeforeShipment'] + ' must be less than or equal to 100')
    .required(FIELD_LABELS['sitBeforeShipment'] + ' is required'),
  sitAfterDelivery: Yup.number()
    .min(0, FIELD_LABELS['sitAfterDelivery'] + ' must be more than or equal to 0')
    .max(100, FIELD_LABELS['sitAfterDelivery'] + ' must be less than or equal to  100')
    .required(FIELD_LABELS['sitAfterDelivery'] + ' is required'),
  sitReturnAvailability: Yup.boolean(),
  sitReturnPeriodDays: Yup.string().when('sitReturnAvailability', {
    is: true,
    then: Yup.string()
      .min(1, FIELD_LABELS['sitReturnPeriodDays'] + ' must be more than or equal to 1')
      .max(255, FIELD_LABELS['sitReturnPeriodDays'] + ' must be less than or equal to 255')
      .required(FIELD_LABELS['sitReturnPeriodDays'] + ' is required'),
  }),
})

const onChange = getDeliveryPaymentOnChange('sitBeforeShipment', 'sitAfterDelivery')

const InvoiceTerms = ({ data, isLoading, loadInvoiceTerms, saveInvoiceTerms, canSupervise }) => {
  useEffect(() => {
    loadInvoiceTerms()
  }, [loadInvoiceTerms])

  const history = useHistory()

  const [editing, setEditing] = useState(false)
  const toggleEditing = useCallback(() => setEditing((editing) => !editing), [])

  if (isLoading || !data.id) {
    return <Spinner />
  }

  const initialValues = {
    ...data,
    sitSpecialTerms: data.sitSpecialTerms
      ? data.sitSpecialTerms
      : i18n.value('profile.company.invoiceTerms.specialTermsDefault'),
    isSitTaxesResponsibleSeller: data.sitTaxesResponsible === RESPONSIBLE_SELLER,
    isSitCommissionsResponsibleSeller: data.sitCommissionsResponsible === RESPONSIBLE_SELLER,
    isSitReturnPaymentResponsibleSeller: data.sitReturnPaymentResponsible === RESPONSIBLE_SELLER,
    sitReturnAvailability: ensureBoolean(data.sitReturnAvailability), //yes I need here cast typing for string/int from server
    action: '',
  }

  const submittingButtons = [
    { color: 'brown', label: 'common.save', action: ACTION_SAVE_INVOICE_TERMS, className: 'profile-button' },
  ]

  return (
    <>
      <UpDownSprite />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={InvoiceTermsSchema}
        onSubmit={async (values) => {
          let serverValues = { ...values }
          toggleEditing()
          serverValues['sitTaxesResponsible'] = values.isSitTaxesResponsibleSeller
            ? RESPONSIBLE_SELLER
            : RESPONSIBLE_BUYER
          serverValues['sitCommissionsResponsible'] = values.isSitCommissionsResponsibleSeller
            ? RESPONSIBLE_SELLER
            : RESPONSIBLE_BUYER
          serverValues['sitReturnPaymentResponsible'] = values.isSitReturnPaymentResponsibleSeller
            ? RESPONSIBLE_SELLER
            : RESPONSIBLE_BUYER
          delete serverValues['action']
          delete serverValues['isSitTaxesResponsibleSeller']
          delete serverValues['isSitCommissionsResponsibleSeller']
          delete serverValues['isSitReturnPaymentResponsibleSeller']
          await saveInvoiceTerms(serverValues)
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleSubmit, resetForm, setSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className="container lg:w-screen-lg-min">
              <div className="container mx-auto lg:w-screen-lg-min mb-2 flex">
                <OrderHeading label={i18n.value('profile.company.labels.invoiceTerms')} />
              </div>
              <div className="mt-6 flex">
                <InputTermFormik
                  value={values.sitValidity}
                  isEditable={editing}
                  labelAfter={'common.days'}
                  itemKey={'sitValidity'}
                  labelBefore={'order.labels.validFor'}
                  inputClass={'w-16'}
                />
              </div>
              <ToggleableSection
                className={'mt-6'}
                labelTop={'order.labels.paymentTerms'}
                labelInside={'order.labels.paymentTerms'}
              >
                <div className="flex justify-between">
                  <div className="flex">
                    <InputTermFormik
                      onChange={onChange}
                      value={values.sitBeforeShipment}
                      isEditable={editing}
                      labelAfter={'common.percent'}
                      itemKey={'sitBeforeShipment'}
                      labelBefore={'orders.labels.paymentBeforeDelivery'}
                      inputClass={'w-16'}
                    />
                    <InputTermFormik
                      onChange={onChange}
                      value={values.sitAfterDelivery}
                      isEditable={editing}
                      labelAfter={'common.percent'}
                      itemKey={'sitAfterDelivery'}
                      labelBefore={'orders.labels.paymentAfterDelivery'}
                      labelBeforeClass={'ml-6 text-gray-500'}
                      inputClass={'w-16'}
                    />
                  </div>
                  <div className="text-left text-gray-500">
                    <div className="flex">
                      <ResponsibleSelectorFormik
                        itemKey={'isSitTaxesResponsibleSeller'}
                        isEditable={editing}
                        label={'orders.labels.taxResponsible'}
                        isSeller={values.isSitTaxesResponsibleSeller}
                      />
                    </div>
                    <div className="flex">
                      <ResponsibleSelectorFormik
                        itemKey={'isSitCommissionsResponsibleSeller'}
                        isEditable={editing}
                        label={'orders.labels.bankResponsible'}
                        isSeller={values.isSitCommissionsResponsibleSeller}
                      />
                    </div>
                  </div>
                </div>
              </ToggleableSection>
              <ToggleableSection className={'mt-6'} labelTop={'order.labels.returnTerms'} withoutHeader>
                <div className="flex mt-4 mb-6">
                  <OrderSubHeader label="order.labels.returnTerms" />
                  <div className="ml-auto flex items-center justify-center text-brown-dim space-x-4">
                    {editing ? (
                      <label className="text-gray-500 flex space-x-4">
                        <span>{i18n.value('orders.labels.returnAvailability')}</span>
                        <FormikToggle itemKey="sitReturnAvailability" labelOn="common.yes" labelOff="common.no" />
                      </label>
                    ) : (
                      <>
                        <TextLabel label="orders.labels.returnAvailability" className="mr-4" />
                        {values.sitReturnAvailability && <CheckIcon />}
                        <span>{i18n.value(values.sitReturnAvailability ? 'common.yes' : 'common.no')}</span>
                      </>
                    )}
                  </div>
                </div>
                {!values.sitReturnAvailability ? (
                  ''
                ) : (
                  <div className="">
                    <div className="text-gray-500 flex">
                      <ResponsibleSelectorFormik
                        itemKey="isSitReturnPaymentResponsibleSeller"
                        isSeller={values.isSitReturnPaymentResponsibleSeller}
                        isEditable={editing}
                        label={'orders.labels.returnPaymentResponsible'}
                      />
                    </div>
                    <div className="flex">
                      <InputTermFormik
                        inputClass="w-16"
                        labelAfter="common.days"
                        itemKey="sitReturnPeriodDays"
                        value={values.sitReturnPeriodDays}
                        isEditable={editing}
                        labelBefore={'orders.labels.returnPeriod'}
                      />
                    </div>
                  </div>
                )}
              </ToggleableSection>
              <ToggleableSection labelTop="orders.labels.specialTerms" className={'mt-6'}>
                {editing ? (
                  <>
                    {errors['sitSpecialTerms'] && (
                      <div className="text-sm text-red-500 mb-2">{errors['sitSpecialTerms']}</div>
                    )}
                    <OrderSpecialTermsFormik itemKey="sitSpecialTerms" isEditable value={values.sitSpecialTerms} />
                  </>
                ) : (
                  <pre className="text-gray-500 whitespace-pre-wrap break-all">{values.sitSpecialTerms}</pre>
                )}
              </ToggleableSection>
              {Object.keys(errors).length === 0 ? (
                ''
              ) : (
                <section className="my-4 bg-red-300 text-red-600">
                  <ErrorMessage name="sitValidity" component="div" />
                  <ErrorMessage name="sitBeforeShipment" component="div" />
                  <ErrorMessage name="sitAfterDelivery" component="div" />
                  <ErrorMessage name="sitReturnPeriodDays" component="div" />
                  <ErrorMessage name="sitSpecialTerms" component="div" />
                </section>
              )}
              {isSubmitting ? (
                <Spinner />
              ) : (
                <div className="flex mt-12">
                  {editing ? (
                    <div className="flex">
                      <FormikSubmittingButtons
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        buttons={submittingButtons}
                      />
                      <BlueButton
                        onClick={() => {
                          toggleEditing()
                          setSubmitting(false)
                          resetForm()
                        }}
                        type={'button'}
                        label={'common.cancel'}
                        className={'profile-button ml-12'}
                      />
                    </div>
                  ) : (
                    <div className="flex space-x-4">
                      <BlueButton type="button" onClick={() => history.goBack()}>
                        Back
                      </BlueButton>
                      {canSupervise && (
                        <BrownButton
                          onClick={toggleEditing}
                          type={'button'}
                          label={'common.edit'}
                          className={'profile-button'}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

InvoiceTerms.propTypes = {
  data: PropTypes.object.isRequired,
  loadInvoiceTerms: PropTypes.func.isRequired,
  saveInvoiceTerms: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  canSupervise: PropTypes.bool,
}

export default memo(connect(InvoiceTerms))
