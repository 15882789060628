import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'
import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DealPaymentIcon from 'root/icons/DealPaymentIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import i18n from 'root/i18n'
import { getDocumentFile } from 'root/api'
import {
  ACTION_INVOICE_CANCELED,
  ACTION_INVOICE_CONFIRMED,
  DOCUMENT_TYPE_INVOICE,
  STATE_INVOICE_CANCELED,
  STATE_INVOICE_CREATED,
  STATE_INVOICE_PAYMENT_AND_DELIVERY,
} from 'root/modules/orders/constants'
import TextLabel from 'root/components/TextLabel'
import OrderTimer from 'root/components/OrderTimer'
import { useAsyncLoad } from 'root/hooks'
import BlueButton from 'root/components/Buttons/BlueButton'
import BrownButton from 'root/components/Buttons/BrownButton'
import { doNothing } from 'root/constants'
import { confirmAlert } from 'root/components/AlertWrapper'

const CardDealInvoice = ({ order, help, doAction }) => {
  const [loading, submitAction] = useAsyncLoad(doAction)
  const invoice = order.documents.find((document) => document.type === DOCUMENT_TYPE_INVOICE)
  const invoiceFile = invoice?.files[0]
  const isCurrent = [STATE_INVOICE_CANCELED, STATE_INVOICE_PAYMENT_AND_DELIVERY, STATE_INVOICE_CREATED].includes(
    order.state.key,
  )
  const invoiceIssuedDate = invoiceFile?.created
  const invoiceIssued = invoiceIssuedDate > 0
  const canConfirm = order.actions.some((action) => action.url === ACTION_INVOICE_CONFIRMED)
  const canCancel = order.actions.some((action) => action.url === ACTION_INVOICE_CANCELED)
  return (
    <Card help={help} label="order.card.dealInvoice" Icon={DealPaymentIcon} current={isCurrent} completed={!isCurrent}>
      <CardRow label="order.card.invoiceIssued">
        <div>
          {invoiceIssued ? <Date value={invoiceIssuedDate} /> : <TextLabel label={'order.card.waitingForSeller'} />}
          {isCurrent && <OrderTimer timer={order.timer} />}
        </div>
      </CardRow>
      {invoiceFile && (
        <CardRow full>
          <div className="w-full">
            <button
              type="button"
              onClick={() => {
                getDocumentFile(invoice.id, invoiceFile.id)
              }}
              className="text-brown-dim"
            >
              {i18n.value('order.card.viewInvoice')}
            </button>
          </div>
          <div className="flex space-x-4">
            {canConfirm && (
              <BrownButton
                className="mt-2"
                type="button"
                disabled={loading}
                label="orders.buttons.confirmInvoice"
                onClick={(e) => {
                  e.stopPropagation()
                  return confirmAlert(i18n.value('order.card.confirmInvoiceConfirm'))
                    .then(() => {
                      submitAction(ACTION_INVOICE_CONFIRMED)
                    })
                    .catch(doNothing)
                }}
              />
            )}
            {canCancel && (
              <BlueButton
                className="mt-2"
                type="button"
                disabled={loading}
                label="orders.buttons.cancelInvoice"
                onClick={(e) => {
                  e.stopPropagation()
                  return confirmAlert(i18n.value('order.card.cancelInvoiceConfirm'))
                    .then(() => {
                      submitAction(ACTION_INVOICE_CANCELED)
                    })
                    .catch(doNothing)
                }}
              />
            )}
          </div>
        </CardRow>
      )}
    </Card>
  )
}
CardDealInvoice.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  help: PropTypes.string,
  children: PropTypes.func,
}
export default memo(CardDealInvoice)
