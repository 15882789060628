import React, { memo } from 'react'
import PropTypes from 'prop-types'

import connect from './connect'

import NewMemoRequestConfirmed from './components/NewMemoCreation/NewMemoRequestConfirmed'
import NewMemoCreated from './components/NewMemoCreation/NewMemoCreated'
import NewMemoConfirmed from './components/NewMemoCreation/NewMemoConfirmed'
import NewMemoUploaded from './components/NewMemoCreation/NewMemoUploaded'
import NewNegotiationStarted from './components/DealNegotiationMemo/NewNegotiationStarted'
import MemoNegotiationUpdated from './components/DealNegotiationMemo/MemoNegotiationUpdated'
import MemoNegotiationFinal from './components/DealNegotiationMemo/MemoNegotiationFinal'
import WaitingMemoSupplementary from './components/DealNegotiationMemo/WaitingMemoSupplementary'
import MemoSupplementaryUploaded from './components/DealNegotiationMemo/MemoSupplementaryUploaded'
import FormMemoPaymentAndDelivery from './components/MemoPaymentAndDelivery/FormMemoPaymentAndDelivery'
import ReadonlyNotice from 'root/modules/orders/pages/Order/components/components/ReadonlyNotice'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import { OrderStageIcons } from 'root/modules/orders/pages/components/OrderStageIcons'

const components = {
  new_memo_request_confirmed: NewMemoRequestConfirmed,
  new_memo_created: NewMemoCreated,
  new_memo_confirmed: NewMemoConfirmed,
  new_memo_docs_refused: NewMemoConfirmed,
  new_memo_docs_uploaded: NewMemoUploaded,
  deal_memo_negotiation: NewNegotiationStarted,
  deal_memo_update: MemoNegotiationUpdated,
  deal_memo_final: MemoNegotiationFinal,
  memo_waiting_supplementary: WaitingMemoSupplementary,
  memo_supplementary_uploaded: MemoSupplementaryUploaded,
  memo_payment_and_delivery: FormMemoPaymentAndDelivery,
  completed: FormMemoPaymentAndDelivery,
}

const MemoOrder = ({ order, performOrderAction, readOnly = false, asSeller }) => {
  const Component = components[order.state.key]
  const extraProps = {
    completed: { isEditable: false },
    memo_payment_and_delivery: { isEditable: !readOnly },
  }

  return Component ? (
    <>
      <main className="container lg:w-screen-lg-min mt-10 flex flex-col space-y-12">
        {readOnly ? (
          <div className="mb-8">
            <ReadonlyNotice order={order} />
          </div>
        ) : null}
        <section className="flex justify-between">
          <OrderHeading label={i18n.value(`orders.stage.${order.stage}`)} />
          <OrderStageIcons stage={order.stage} order={order} asSeller={asSeller} />
        </section>
        <Component
          order={order}
          performOrderAction={performOrderAction}
          {...extraProps[order.state.key]}
          readOnly={readOnly}
          asSeller={asSeller}
        />
      </main>
    </>
  ) : (
    <div>
      Memo order: {order.id} on state <b>{order.state.name}</b>
    </div>
  )
}

MemoOrder.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  asSeller: PropTypes.bool,
}

export default memo(connect(MemoOrder))
