import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'

const InputTermRead = ({ labelBefore, labelAfter = '', labelBeforeClass, inputClass = 'w-32', value, ...other }) => {
  labelBeforeClass = labelBeforeClass ? labelBeforeClass : 'text-gray-500'
  return (
    <>
      {labelBefore && <label className={'col-span-2 ' + labelBeforeClass}>{i18n.value(labelBefore)}</label>}
      <div>
        <label className="text-brown-dim ml-2">{value}</label>
        {labelAfter && <label className="ml-1 text-brown-dim">{i18n.value(labelAfter)}</label>}
      </div>
    </>
  )
}

InputTermRead.propTypes = {
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  labelBeforeClass: PropTypes.string,
  inputClass: PropTypes.string,
  value: PropTypes.any,
}

export default memo(InputTermRead)
