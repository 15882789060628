import React, { memo, useCallback, useEffect, useState } from 'react'
import ProfileInfoField
  from 'root/modules/profile/pages/CompanyProfile/ProfileInfoField'
import { Link, useLocation } from 'react-router-dom'
import Exhibition from 'root/modules/profile/pages/CompanyProfile/Exhibition'
import {
  CogIcon,
  GlobeIcon,
  LocationIcon,
  PhoneIcon,
  TeamIcon,
} from 'root/icons'
import './styles.css'
import connect from './connect'
import PropTypes from 'prop-types'
import Spinner from 'root/components/Spinner'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'
import * as Yup from 'yup'
import i18n from 'root/i18n'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { ACTION_SAVE_COMPANY_PROFILE } from 'root/modules/profile/constants'
import FormikSubmittingButtons
  from 'root/components/Buttons/FormikSubmittingButtons'
import UploadAvatar
  from 'root/modules/profile/pages/CompanyProfile/UploadAvatar'
import { downloadServiceAgreement, uploadCompanyAvatar } from 'root/api'
import {
  clearLocalFiles,
  COUNTRY_INFO,
  COUNTRY_INFO_BY_CODE,
  getLocalFiles,
  toClassName,
} from 'root/constants'
import { SITE_URL } from 'root/api/env'
import FormikToggle from 'root/components/FormikToggle'
import PaidIcon from 'root/icons/PaidIcon'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const FIELD_LABELS = {
  name: i18n.value('profile.company.labels.name'),
  phone: i18n.value('profile.company.labels.phone'),
  technology: i18n.value('profile.company.labels.technology'),
  site: i18n.value('profile.company.labels.site'),
  countryId: i18n.value('profile.company.labels.country'),
  exhibitions: i18n.value('profile.company.labels.exhibitions'),
  fullInfo: i18n.value('profile.company.labels.fullInfo'),
  imageLogo: i18n.value('profile.company.labels.imageLogo'),
}

const CompanyProfileSchema = Yup.object().shape({
  phone: Yup.string().max(100),
  technology: Yup.string().max(50),
  site: Yup.string().url().max(100),
  countryId: Yup.string().required(FIELD_LABELS['countryId'] + ' is required'),
  fullInfo: Yup.string().max(1000),
  imageLogo: Yup.string().required(FIELD_LABELS['imageLogo'] + ' is required'),
})

const submittingButtons = [
  {
    color: 'brown',
    label: 'common.save',
    action: ACTION_SAVE_COMPANY_PROFILE,
    className: 'profile-button',
  },
]

const CompanyProfile = ({
  data,
  isLoading,
  loadCompanyCommonInfo,
  loadCatalogFrameInfo,
  saveCompanyProfileThunk,
  canSupervise,
  user,
  isCatalogIFrameEditable,
}) => {
  useEffect(() => {
    clearLocalFiles()
    loadCatalogFrameInfo(true)
    loadCompanyCommonInfo(true)
  }, [loadCompanyCommonInfo, loadCatalogFrameInfo])

  const isAdmin = user.isAdmin

  const [editing, setEditing] = useState(false)
  const toggleEditing = useCallback(() => setEditing((editing) => !editing), [])
  const { search } = useLocation()

  const isSeller = user.companyId?.roles?.isSeller
  const canEditStock = isSeller

  if (isLoading || !data.id) return <Spinner/>

  const params = new URLSearchParams(search)
  if (params.has('return') && user?.activated) {
    window.location = SITE_URL + decodeURI(params.get('return'))
  }

  const initialValues = {
    phone: data.phone || '',
    technology: data.technology || '',
    site: data.site || '',
    countryId: data.countryId?.code || '',
    fullInfo: data.fullInfo || '',
    imageLogo: data.imageLogo,
    exhibitions: data.exhibitions ? [...data.exhibitions] : [],
    isSeller: data.isSeller || false,
    action: '',
    paymentAmount: data.paymentAmount || 0,
    paymentLink: data.paymentLink || '',
  }

  /*const rating = '4'
  const ratingsNum = 14*/

  const onSubmit = (values) => {
    toggleEditing()
    const serverData = { ...values }
    const localFiles = getLocalFiles()
    clearLocalFiles()
    if (localFiles?.length === 1) serverData.imageLogo = localFiles[0]
    else delete serverData.imageLogo

    return saveCompanyProfileThunk(serverData).then(() => {
      window.location.reload()
    })
  }

  const doDownloadServiceAgreement = async () => {
    return await downloadServiceAgreement()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={CompanyProfileSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        isSubmitting,
        setFieldValue,
        handleSubmit,
        resetForm,
        setSubmitting,
        setValues,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="container sm:w-screen-lg-min mb:p-4">
            <div className="grid grid-cols-2 mb:grid-cols-1">
              <div>
                <div className="profile-header mb:flex mb:justify-center">
                  <div>{data.name}</div>
                </div>
                <div className="profile-pre-header">
                  <UploadAvatar
                    accept="image/jpeg,image/png,image/gif,image/bmp"
                    uploadAvatarFunction={uploadCompanyAvatar}
                    currentImg={data.imageLogo}
                    alt={data.name}
                    isEditing={editing}
                  />
                  {/*<div>
                    <span className="profile-rating-title">RATING</span>
                    <Rating rating={rating} ratingsNum={ratingsNum} />
                  </div>*/}
                </div>
                {editing ? (
                  <>
                    <div
                      className="text-blue-silver font-bold">{FIELD_LABELS['fullInfo']}</div>
                    <Field
                      as={'textarea'}
                      name={'fullInfo'}
                      id={'fullInfo'}
                      className="mt-4 form-textarea text-brown-dim w-full block shadow shadow-inset border-gray-300"
                      rows={8}
                    />
                  </>
                ) : (
                  <div
                    className="profile-description mb:break-all mb:mb-4">{values.fullInfo}</div>
                )}
              </div>
              <div className="ml-12 mb:ml-0">
                {/*{canSupervise && (*/}
                {/*  <div className="profile-header">*/}
                {/*    <Link to={`/company/team`}>*/}
                {/*      <MyTeam className="cursor-pointer " />*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*)}*/}
                <div className="profile-info">
                  {isAdmin ? (
                    <div>
                      <ProfileInfoField
                        Icon={PaidIcon}
                        label={'Payment'}
                        value={values.paymentLink}
                        itemKey={'paymentLink'}
                        isEditing={editing}
                      />
                      <ProfileInfoField
                        Icon={PaidIcon}
                        label={'Debt'}
                        value={values.paymentAmount}
                        itemKey={'paymentAmount'}
                        isEditing={editing}
                      />
                    </div>
                  ) : null}
                  <ProfileInfoField
                    Icon={PhoneIcon}
                    label={FIELD_LABELS['phone']}
                    value={values.phone}
                    itemKey={'phone'}
                    isEditing={editing}
                  />
                  <ProfileInfoField
                    Icon={CogIcon}
                    label={FIELD_LABELS['technology']}
                    value={values.technology}
                    itemKey={'technology'}
                    isEditing={editing}
                  />
                  <ProfileInfoField
                    Icon={GlobeIcon}
                    label={FIELD_LABELS['site']}
                    value={values.site}
                    itemKey={'site'}
                    isEditing={editing}
                  />
                  <ProfileInfoField
                    Icon={LocationIcon}
                    label={FIELD_LABELS['countryId']}
                    value={COUNTRY_INFO_BY_CODE[values.countryId]?.name ||
                      values.countryId}
                    itemKey={'countryId'}
                    isEditing={editing}
                  >
                    {({ field, form, meta, textColor, inputClass }) => (
                      <select
                        {...field}
                        className={toClassName([
                          'mb:w-64 form-select text-left  shadow shadow-inset px-2 py-0',
                          inputClass,
                          textColor,
                        ])}
                      >
                        <option key={''} value={''}>
                          Please, Select Country
                        </option>
                        {COUNTRY_INFO.map(({ code, name }) => (
                          <option key={code} value={code}>
                            {name}
                          </option>
                        ))}
                      </select>
                    )}
                  </ProfileInfoField>
                  <label className="text-gray-500 flex space-x-4 mt-4">
                    <span>{i18n.value('profile.company.labels.isSeller')}</span>
                    <FormikToggle disabled={!editing} itemKey="isSeller"
                                  labelOn="common.yes" labelOff="common.no"/>
                  </label>
                </div>
                {canSupervise ? (
                  <div className="flex flex-col space-y-2">
                    <Link to={`/company/addresses`} className="profile-link">
                      {i18n.value('profile.company.labels.viewAndEditAddress')}
                    </Link>
                    {canEditStock ? (
                      <Link to={`/company/goods`} className="profile-link">
                        {i18n.value('profile.company.labels.viewAndEditGoods')}
                      </Link>
                    ) : null}
                    {isCatalogIFrameEditable ? (
                      <Link to={`/company/catalog`} className="profile-link">
                        {i18n.value(
                          'profile.company.labels.viewAndEditCatalogIFrameSettings')}
                      </Link>
                    ) : null}
                    {isSeller ? (
                      <>
                        <Link to={`/company/stripe`} className="profile-link">
                          {i18n.value(
                            'profile.company.labels.viewAndEditStripeSettings')}
                        </Link>
                        <Link to={`/company/kyc`} className="profile-link">
                          {i18n.value(
                            'profile.company.labels.viewAndEditKycSettings')}
                        </Link>
                      </>
                    ) : null}
                    <Link to={`/company/kyc-info`} className="profile-link">
                      {i18n.value(
                        'profile.company.labels.viewAndEditKycInformation')}
                    </Link>
                  </div>
                ) : null}
                <div>
                  {(editing || values.exhibitions.length > 0) && (
                    <div className="profile-exhibitions">
                      <span className="uppercase">{i18n.value(
                        'profile.company.labels.exhibitions')}</span>:
                    </div>
                  )}
                  {values.exhibitions.map((item, index) => (
                    <Exhibition
                      key={index}
                      index={index}
                      disabled={!editing}
                      removeItem={(index) => {
                        const exhibitions = [...values.exhibitions]
                        exhibitions.splice(index, 1)
                        setValues({ ...values, exhibitions })
                      }}
                      {...item}
                    />
                  ))}
                  {editing && (
                    <div
                      className="profile-add-exhibition"
                      onClick={() => {
                        setValues({
                          ...values,
                          exhibitions: [
                            ...values.exhibitions,
                            { details: '', location: '' }],
                        })
                      }}
                    >
                      <BrownButton type="button" label="common.add"/>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {Object.keys(errors).length !== 0 && (
              <section className="my-4 bg-red-300 text-red-600">
                <ErrorMessage name="phone" component="div"/>
                <ErrorMessage name="technology" component="div"/>
                <ErrorMessage name="site" component="div"/>
                <ErrorMessage name="countryId" component="div"/>
                <ErrorMessage name="fullInfo" component="div"/>
              </section>
            )}
            {isSubmitting ? (
              <Spinner/>
            ) : (
              <>
                <div
                  className="flex space-x-4 mb:flex-col mb:space-x-0 mb:space-y-4 my-10">
                  {editing ? (
                    <>
                      <FormikSubmittingButtons
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        buttons={submittingButtons}
                      />
                      <div>
                        <BlueButton
                          onClick={() => {
                            toggleEditing()
                            //because they were dynamic and changed values..need to get to initial state
                            setValues(
                              { ...values, exhibitions: [...data.exhibitions] })
                            setSubmitting(false)
                            resetForm()
                          }}
                          type={'button'}
                          label={'common.cancel'}
                          className={'profile-button'}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {canSupervise && (
                        <BrownButton
                          onClick={toggleEditing}
                          type={'button'}
                          label={'common.editProfile'}
                          className={'profile-button'}
                        />
                      )}
                      {canEditStock ? (
                        <div>
                          <Link to={`/products`}>
                            <BrownButton type={'button'} label={'menu.stock'}
                                         className={'profile-button'}/>
                          </Link>
                        </div>
                      ) : null}
                      <div>
                        <Link to={`/company/credit-info`}>
                          <BlueButton type={'button'}
                                      className={'profile-button'}>
                            {i18n.value('profile.buttons.creditInfo')}
                            {data.hasFullCreditInfo ? null : (
                              <div className="pl-4 text-red-500">
                                <ExclaimIcon/>
                              </div>
                            )}
                          </BlueButton>
                        </Link>
                      </div>
                      {isSeller ? (
                        <div>
                          <Link to={`/company/invoice-terms`}>
                            <BlueButton
                              type={'button'}
                              label={'profile.buttons.invoiceTerms'}
                              className={'profile-button'}
                            />
                          </Link>
                        </div>
                      ) : null}
                      {canSupervise ? (
                        <div>
                          <Link
                            to={`/company/team`}
                            className="bg-blue-skyblue space-x-4 px-8 flex items-center justify-center h-10 font-bold text-white transition duration-200 hover:shadow-sm active:shadow-none disabled:opacity-50 focus:outline-none "
                          >
                            <TeamIcon width={20} height={20}
                                      className="text-white"/>
                            <span>My team</span>
                          </Link>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                {isAdmin ? (
                  <div
                    className="flex space-x-4 mb:flex-col mb:space-x-0 mb:space-y-4 my-10">
                    <BlueButton type="button"
                                onClick={doDownloadServiceAgreement}>
                      Download Service Agreement
                    </BlueButton>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

CompanyProfile.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object.isRequired,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
  saveCompanyProfileThunk: PropTypes.func.isRequired,
  loadCatalogFrameInfo: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  canSupervise: PropTypes.bool,
  isCatalogIFrameEditable: PropTypes.bool,
}

export default memo(connect(CompanyProfile))
