import { connect } from 'react-redux'
import { getResponsible, isSeller, getActiveOrderData } from 'root/modules/orders/selectors'
import { getUserData } from '../../../../../../../selectors'
import { loadOrder } from 'root/modules/orders/thunks'

const mapStateToProps = (state) => ({
  isSeller: isSeller(state),
  responsible: getResponsible(state),
  user: getUserData(state),
  order: getActiveOrderData(state),
})

export default connect(mapStateToProps, {
  loadOrder,
})
