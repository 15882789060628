import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import DiamondIcon from 'root/icons/DiamondIcon'
import i18n from 'root/i18n'

const FormikToggle = ({ itemKey, labelOn, labelOff, labelInside = false, ...other }) => (
  <Field type="checkbox" id={itemKey} name={itemKey} {...other}>
    {({ field, meta }) => (
      <div className="flex items-center">
        <input id={itemKey} type="checkbox" {...field} multiple disabled={other.disabled} className="hidden" />
        <label
          htmlFor={itemKey}
          className={
            'cursor-pointer mx-2 border border-white-smoke min-w-12 rounded rounded-xl flex toggled:flex-row-reverse shadow ' +
            (field.value ? 'justify-begin' : 'justify-end')
          }
        >
          {!field.value && labelInside && <span className="px-2">{i18n.value(labelOff)}</span>}
          <i
            className={
              'text-white rounded rounded-full p-1 ' +
              ((meta.initialValue === undefined && !meta.touched) || (meta.error && meta.touched) || other.disabled
                ? 'bg-gray-400'
                : 'bg-brown-dim')
            }
          >
            <DiamondIcon width={15} height={15} />
          </i>
          {field.value && labelInside && <span className="px-2">{i18n.value(labelOn)}</span>}
        </label>
        {!labelInside && (
          <>
            <label
              htmlFor={itemKey}
              className="select-none cursor-pointer order-first text-gray-500 toggled:text-brown-dim"
            >
              {i18n.value(labelOn)}
            </label>
            <label
              htmlFor={itemKey}
              className="select-none cursor-pointer order-last text-brown-dim toggled:text-gray-500"
            >
              {i18n.value(labelOff)}
            </label>
          </>
        )}
      </div>
    )}
  </Field>
)

FormikToggle.propTypes = {
  itemKey: PropTypes.string.isRequired,
  labelOn: PropTypes.string.isRequired,
  labelOff: PropTypes.string.isRequired,
  labelInside: PropTypes.bool,
}

export default memo(FormikToggle)
