import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Button from 'root/components/Buttons/Button'

const BrownButton = ({ className = '', ...props }) => <Button className={'bg-brown-dim ' + className} {...props} />

BrownButton.propTypes = {
  className: PropTypes.string,
}

export default memo(BrownButton)
