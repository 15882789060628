import { connect } from 'react-redux'

import { getInvoiceTermsData, isInvoiceTermsLoading } from 'root/modules/profile/selectors'
import { loadInvoiceTerms, saveInvoiceTerms } from 'root/modules/profile/thunks'
import { canSupervise } from 'root/selectors'

const mapStateToProps = (state) => ({
  data: getInvoiceTermsData(state),
  isLoading: isInvoiceTermsLoading(state),
  canSupervise: canSupervise(state),
})

const mapDispatchToProps = {
  loadInvoiceTerms,
  saveInvoiceTerms,
}

export default connect(mapStateToProps, mapDispatchToProps)
