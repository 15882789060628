import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

// import { useHistory } from 'react-router-dom'
import CheckboxInput from 'root/modules/login/pages/Login/components/CheckboxInput'
import { getPriceFormatter, formatNumberToPrice, stopPropagation, toClassName } from 'root/constants'

/* eslint-disable react/display-name */
const cellRenderFunctions = {
  shape: (product) => (
    <svg className="inline text-brown-dim" width="25" height="25" viewBox="0 0 25 25">
      <use href={`#img-shape-${product.shape.id}`} />
    </svg>
  ),
  certificate: (product) => (
    <a
      onClick={stopPropagation}
      className="text-blue-skyblue underline"
      href={product.certificateNumber.url}
      target="_blank"
      rel="noreferrer"
    >
      {`${product.certificateNumber.value} (${product.certificate.text})`}
    </a>
  ),
  cert: (product) => product.certificate.text,
  color: (product) => product.colorValue.text,
  location: (product) => product.zipCodeId?.countryId?.name || product.originCountryId.name || '-',
  price: (product) => formatNumberToPrice(product.price, getPriceFormatter(product.currency)),
  status: (product) => <span className="text-blue-skyblue">{product.status.name}</span>,
  select: (product, { onCheckboxClick, isProductSelected }) => (
    <div onClick={stopPropagation} className="flex justify-center text-blue-500">
      {!product.inReserve ? (
        <CheckboxInput onChange={onCheckboxClick} id={`check_${product.id}`} value={isProductSelected} />
      ) : (
        '---'
      )}
    </div>
  ),
}
/* eslint-disable react/display-name */

const renderCellByKey = (key, product, props) =>
  cellRenderFunctions[key]?.(product, props) ?? product[key]?.text ?? product[key]

const ProductsListRow = ({
  item: product,
  onMouseOverFunc,
  onItemCheck,
  isProductSelected,
  isRowSelected,
  columns,
  columnSizes,
  isCatalog,
}) => {
  // const history = useHistory()

  const { id } = product
  // const onClick = useCallback(() => history.push(`/products/${id}`), [history, id])
  const onCheckboxClick = useCallback(() => onItemCheck(id), [onItemCheck, id])
  const onMouseEnter = useCallback(() => onMouseOverFunc(id), [onMouseOverFunc, id])
  const props = { onCheckboxClick, isProductSelected }

  return (
    <tr
      className={toClassName([
        'hover:bg-gray-200 cursor-pointer',
        (isProductSelected || isRowSelected) && 'bg-gray-100',
      ])}
      onMouseEnter={onMouseEnter}
      // onClick={isCatalog ? undefined : onClick}
    >
      {columns.map((key, index) => (
        <td key={key} style={{ width: columnSizes[index] }} className="py-1 text-blue-dark">
          <a className="block" href={product.url || `/products/${product.id}`}>
            {renderCellByKey(key, product, props)}
          </a>
        </td>
      ))}
    </tr>
  )
}

ProductsListRow.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  item: PropTypes.object.isRequired,
  onMouseOverFunc: PropTypes.func.isRequired,
  onItemCheck: PropTypes.func.isRequired,
  isProductSelected: PropTypes.bool.isRequired,
  isRowSelected: PropTypes.bool.isRequired,
  isCatalog: PropTypes.bool,
}

export default memo(ProductsListRow)
