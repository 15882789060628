import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { Field } from 'formik'
import { toClassName } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const InputTermFormik = ({
  labelBefore,
  labelAfter = '',
  labelBeforeClass,
  inputClass = 'w-32',
  itemKey,
  value,
  isEditable = true,
  ...other
}) => {
  labelBeforeClass = labelBeforeClass ? labelBeforeClass : 'text-gray-500'
  return (
    <>
      {labelBefore && (
        <label htmlFor={itemKey} className={'col-span-2 ' + labelBeforeClass}>
          {i18n.value(labelBefore)}
        </label>
      )}
      <div>
        {isEditable ? (
          <Field
            id={itemKey}
            name={itemKey}
            className={
              'form-input ml-2 text-center text-brown-dim border-gray-300 shadow shadow-inset px-2 py-0 ' + inputClass
            }
            {...other}
          >
            {(data) => {
              const { field, meta } = data
              const onChange = other.onChange
                ? (...args) => {
                    field.onChange(...args)
                    other.onChange?.(...args, data)
                  }
                : field.onChange
              return (
                <span className="group relative flex-inline flex-col">
                  {meta.touched && meta.error && (
                    <div className="z-10 bg-red-300 text-white p-1 text-xs absolute hidden group-hover:block top-full left-0 whitespace-no-wrap ml-2 mt-2">
                      {meta.error}
                    </div>
                  )}
                  <input
                    type="text"
                    {...field}
                    onChange={onChange}
                    onBlur={other.onBlur || field.onBlur}
                    className={toClassName([
                      'form-input ml-2 text-center text-brown-dim  shadow shadow-inset px-2 py-0 ',
                      inputClass,
                      meta.touched && meta.error ? 'border-red-500' : 'border-gray-300',
                    ])}
                  />
                  {meta.touched && meta.error && (
                    <i className="text-red-700 absolute right-0 transform translate-x-1/2 -translate-y-1/2">
                      <ExclaimIcon width={15} height={15} />
                    </i>
                  )}
                </span>
              )
            }}
          </Field>
        ) : (
          <label className="text-brown-dim ml-2">{value}</label>
        )}
        {labelAfter && <label className="ml-1 text-brown-dim">{i18n.value(labelAfter)}</label>}
      </div>
    </>
  )
}

InputTermFormik.propTypes = {
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  labelBeforeClass: PropTypes.string,
  inputClass: PropTypes.string,
  isEditable: PropTypes.bool,
  value: PropTypes.any,
  itemKey: PropTypes.string.isRequired,
}

export default memo(InputTermFormik)
