import { createAction } from 'redux-actions'

const NAMESPACE = 'ORDERS::'

export const loadNotificationsSummarySuccess = createAction(NAMESPACE + 'LOAD_NOTIFICATIONS_SUMMARY_SUCCESS')

export const loadOrderListStart = createAction(NAMESPACE + 'LOAD_ORDER_LIST_START')
export const loadOrderListSuccess = createAction(NAMESPACE + 'LOAD_ORDER_LIST_SUCCESS')
export const loadOrderListFailure = createAction(NAMESPACE + 'LOAD_ORDER_LIST_FAILURE')

export const loadOrderStart = createAction(NAMESPACE + 'LOAD_ORDER_START')
export const loadOrderSuccess = createAction(NAMESPACE + 'LOAD_ORDER_SUCCESS')
export const loadOrderFailure = createAction(NAMESPACE + 'LOAD_ORDER_FAILURE')

export const sendOrderStart = createAction(NAMESPACE + 'SEND_ORDER_START')
export const sendOrderSuccess = createAction(NAMESPACE + 'SEND_ORDER_SUCCESS')
export const sendOrderFailure = createAction(NAMESPACE + 'SEND_ORDER_FAILURE')

export const updateOrdersFilters = createAction(NAMESPACE + 'UPDATE_ORDERS_FILTERS')

export const updateReservedProducts = createAction(NAMESPACE + 'UPDATE_RESERVED_PRODUCTS')
export const uploadOrderDocumentSuccess = createAction(NAMESPACE + 'UPLOAD_ORDER_DOCUMENT_SUCCESS')

export const loadInitialTermsStart = createAction(NAMESPACE + 'LOAD_INITIAL_TERMS_START')
export const loadInitialTermsSuccess = createAction(NAMESPACE + 'LOAD_INITIAL_TERMS_SUCCESS')
export const loadInitialTermsFailure = createAction(NAMESPACE + 'LOAD_INITIAL_TERMS_FAILURE')

export const showInitialTerms = createAction(NAMESPACE + 'SHOW_INITIAL_TERMS')
export const hideInitialTerms = createAction(NAMESPACE + 'HIDE_INITIAL_TERMS')
