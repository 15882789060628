import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'
import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DealPaymentIcon from 'root/icons/DealPaymentIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import i18n from 'root/i18n'
import { getDocumentFile } from 'root/api'
import {
  ACTION_ADDITIONAL_INVOICE_CANCELED,
  ACTION_ADDITIONAL_INVOICE_CONFIRMED,
  DOCUMENT_TYPE_ADDITIONAL_INVOICE,
  STATE_ADDITIONAL_INVOICE,
  STATE_ADDITIONAL_INVOICE_CANCELED,
  STATE_ADDITIONAL_INVOICE_CREATED,
} from 'root/modules/orders/constants'
import TextLabel from 'root/components/TextLabel'
import OrderTimer from 'root/components/OrderTimer'
import { useAsyncLoad } from 'root/hooks'
import BlueButton from 'root/components/Buttons/BlueButton'
import BrownButton from 'root/components/Buttons/BrownButton'
import { doNothing } from 'root/constants'
import { confirmAlert } from 'root/components/AlertWrapper'

const CardDealAdditionalInvoice = ({ order, doAction, help }) => {
  const [loading, submitAction] = useAsyncLoad(doAction)
  const invoice = order.documents.find((document) => document.type === DOCUMENT_TYPE_ADDITIONAL_INVOICE)
  const invoiceFile = invoice?.files[0] || false
  const isCurrent = [
    STATE_ADDITIONAL_INVOICE,
    STATE_ADDITIONAL_INVOICE_CREATED,
    STATE_ADDITIONAL_INVOICE_CANCELED,
  ].includes(order.state.key)
  const isCompleted =
    ![STATE_ADDITIONAL_INVOICE, STATE_ADDITIONAL_INVOICE_CREATED, STATE_ADDITIONAL_INVOICE_CANCELED].includes(
      order.state.key,
    ) && invoiceFile
  const invoiceIssuedDate = invoiceFile?.created
  const invoiceIssued = invoiceIssuedDate > 0
  const canConfirm = order.actions.some((action) => action.url === ACTION_ADDITIONAL_INVOICE_CONFIRMED)
  const canCancel = order.actions.some((action) => action.url === ACTION_ADDITIONAL_INVOICE_CANCELED)
  return (
    <Card
      help={help}
      label="order.card.dealAdditionalInvoice"
      Icon={DealPaymentIcon}
      current={isCurrent}
      completed={isCompleted}
    >
      <CardRow label="order.card.invoiceIssued">
        <div>
          {invoiceIssued ? (
            <Date value={invoiceIssuedDate} />
          ) : isCurrent ? (
            <TextLabel label={'order.card.waitingForSeller'} />
          ) : (
            ''
          )}
          {isCurrent && <OrderTimer timer={order.timer} />}
        </div>
      </CardRow>
      {invoiceFile && (
        <>
          <CardRow full>
            <div className="w-full">
              <button
                type="button"
                onClick={() => {
                  getDocumentFile(invoice.id, invoiceFile.id)
                }}
                className="text-brown-dim"
              >
                {i18n.value('order.card.viewInvoice')}
              </button>
            </div>
          </CardRow>

          <CardRow full>
            <div className="flex space-x-4">
              {canConfirm && (
                <BrownButton
                  className="mt-2"
                  type="button"
                  disabled={loading}
                  label="orders.buttons.confirmInvoice"
                  onClick={(e) => {
                    e.stopPropagation()
                    return confirmAlert(i18n.value('order.card.confirmInvoiceConfirm'))
                      .then(() => {
                        submitAction(ACTION_ADDITIONAL_INVOICE_CONFIRMED)
                      })
                      .catch(doNothing)
                  }}
                />
              )}
              {canCancel && (
                <BlueButton
                  className="mt-2"
                  type="button"
                  disabled={loading}
                  label="orders.buttons.cancelInvoice"
                  onClick={(e) => {
                    e.stopPropagation()
                    return confirmAlert(i18n.value('order.card.cancelInvoiceConfirm'))
                      .then(() => {
                        submitAction(ACTION_ADDITIONAL_INVOICE_CANCELED)
                      })
                      .catch(doNothing)
                  }}
                />
              )}
            </div>
          </CardRow>
        </>
      )}
    </Card>
  )
}
CardDealAdditionalInvoice.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  help: PropTypes.string,
}
export default memo(CardDealAdditionalInvoice)
