import OrderList from './pages/OrderList'
import Order from './pages/Order'
import { bus, store } from 'root/store'
import * as wsActions from 'root/modules/ws/actions'
import { getUserData } from 'root/selectors'
import { loadOrder, loadOrderNotificationsSummary } from 'root/modules/orders/thunks'
import { getDeviceId } from 'root/constants'

bus.take(wsActions.message, ({ payload: { type, payload } }) => {
  if (type === 'order-notification') {
    store.dispatch(loadOrderNotificationsSummary())
  }
  if (type === 'order-changed') {
    const state = store.getState()
    const { id } = payload
    const user = getUserData(state)

    if (
      window.location.pathname.indexOf(id) >= 0 &&
      (user?.id !== payload.user.id || payload.user.clientId !== getDeviceId())
    ) {
      console.debug('order changed by someone else, reloading')
      store.dispatch(loadOrder({ id }, true))
      window.scrollTo(0, 0)
    }
  }
})

export { OrderList, Order }
