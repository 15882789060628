import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = {}

export default handleActions(
  {
    [actions.loadCompanyStart]: (state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadCompanySuccess]: (state, { payload: company }) => {
      return company
    },
  },
  defaultState,
)
