import instance from './instance'
import { transformMemoAgreements } from 'root/api/transformers'

export const getMemoAgreement = (uid) => {
  uid = uid === 'new' ? '' : uid
  return instance({
    coreServer: true,
    method: 'get',
    url: `/memo/${uid}/full`,
    auth: true,
    transformResponse: transformMemoAgreements,
  })
}

export const getMemoAgreements = (p, q, filters, cancelToken) => {
  const autocomplete = p === undefined
  return instance({
    coreServer: true,
    method: 'get',
    url: `/memo`,
    params: { p, q, attr: autocomplete ? 'name' : undefined, ...filters },
    auth: true,
    transformResponse: autocomplete ? undefined : transformMemoAgreements,
    cancelToken,
  })
}

export const getMemoDocument = (memoUid) => {
  return instance({
    method: 'get',
    coreServer: true,
    downloadFile: true,
    url: `/memo/${memoUid}/download`,
    auth: true,
  })
}
