import { handleActions } from 'redux-actions'

import * as actions from '../actions'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const userPersistKey = 'user'

const persistConfig = {
  key: userPersistKey,
  storage,
}

const defaultState = null

const user = handleActions(
  {
    [actions.setUser]: (state, { payload: user }) => user,
    [actions.updateUser]: (state, { payload: attributes }) => ({
      ...state,
      ...attributes,
    }),
  },
  defaultState,
)

export default persistReducer(persistConfig, user)
