import React, { memo } from 'react'
import PropTypes from 'prop-types'
import WaitResponseScreen
  from 'root/modules/orders/pages/components/WaitResponseScreen'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import Diamonds, {
  CertificateColumn,
  IndexColumn,
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import ReadonlyMemoTerms
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ReadonlyMemoTerms'

const NewMemoUploaded = ({ order, performOrderAction, readOnly = false }) => {
  const labels = {
    heading: 'Consignment Agreement uploaded',
    headingBeforeTimer: 'orders.labels.waitCustomerApproveDoc',
    timer: 'orders.labels.customerReplyTimer',
  }
  let buttons = order.timer.isExpired
    ? [
      {
        color: 'blue',
        label: 'orders.buttons.cancelMemoRequest',
        action: 'new_memo_canceled',
      }]
    : []
  if (readOnly) buttons = []
  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          buyer={order.buyerCompanyId.name}
          asSeller
        >
          <ViewOrderFiles order={order} doAction={performOrderAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds
          isEditable={false}
          highlightDeleted
          showDeleted={false}
          items={order.products}
          doAction={performOrderAction}
        >
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
        </Diamonds>
      </section>

      <section>
        <ReadonlyMemoTerms order={order}/>
      </section>

      <section>
        <WaitResponseScreen order={order}
                            performOrderAction={performOrderAction}
                            labels={labels} buttons={buttons}/>
      </section>
    </>
  )
}

NewMemoUploaded.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(NewMemoUploaded)
