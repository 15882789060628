import React, { memo } from 'react'
import * as PropTypes from 'prop-types'

const OrderHeading = ({ label, sublabel }) => {
  return (
    <div>
      <h1 className="text-3xl font-damascus text-brown-late">{label}</h1>
      {sublabel ? <div className="font-damascus text-brown-dim mr-48 font-semibold">{sublabel}</div> : null}
    </div>
  )
}

OrderHeading.propTypes = {
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.string,
}

export default memo(OrderHeading)
