import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { processOrder } from 'root/api/transformers'
import OrderListRow from 'root/components/OrderListRow'
import { SECTION_PURCHASES, SECTION_SALES } from 'root/modules/orders/constants'

const DealsTable = ({ orders, user }) => {
  return (
    <div>
      <div className="bg-gray-100 mt-5">
        <div className="h-10 mb-6 pl-4 flex items-center justify-between text-gray-500 container mx-auto lg:w-screen-lg-min">
          <div className="w-16 text-center">
            <span>{i18n.value('orders.list.tableHead.date')}</span>
          </div>
          <div className="w-32 text-center">
            <span>{i18n.value('orders.list.tableHead.number')}</span>
          </div>
          <div className="w-32 text-center">
            <span>{i18n.value('orders.list.tableHead.company.contractor')}</span>
          </div>
          <div className="w-32 text-center">
            <span>{i18n.value('orders.list.tableHead.status')}</span>
          </div>
          <div className="w-24 text-center">
            <span>{i18n.value('orders.list.tableHead.totalUsd')}</span>
          </div>
          <div className="w-24 text-center">
            <span>{i18n.value('orders.list.tableHead.timer')}</span>
          </div>
          <div className="w-24 text-center" />
        </div>
      </div>
      {orders.map((item) => {
        item = processOrder(item)
        const section = user.companyId.id === item.sellerCompanyId.id ? SECTION_SALES : SECTION_PURCHASES
        return <OrderListRow key={item.id} item={item} section={section} />
      })}
    </div>
  )
}

DealsTable.propTypes = {
  orders: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}

export default memo(DealsTable)
