import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="#FFFFFF"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.32812 0C5.86213 0 5.48438 0.37776 5.48438 0.84375L5.38308 1.55278C5.29239 2.18763 4.5546 2.49324 4.04156 2.10846L3.46858 1.67872C3.13908 1.34922 2.60485 1.34922 2.27534 1.67872L1.67872 2.27534C1.34921 2.60485 1.34921 3.13908 1.67872 3.46858L2.10845 4.04157C2.49323 4.5546 2.18763 5.29239 1.55278 5.38308L0.84375 5.48437C0.37776 5.48437 0 5.86213 0 6.32812V7.17187C0 7.63787 0.37776 8.01562 0.84375 8.01562L1.55278 8.11691C2.18763 8.20761 2.49324 8.9454 2.10846 9.45843L1.67872 10.0314C1.34921 10.3609 1.34921 10.8952 1.67872 11.2247L2.27534 11.8213C2.60484 12.1508 3.13908 12.1508 3.46858 11.8213L4.04156 11.3915C4.5546 11.0068 5.29239 11.3124 5.38309 11.9472L5.48438 12.6562C5.48438 13.1222 5.86213 13.5 6.32812 13.5H7.17188C7.63787 13.5 8.01562 13.1222 8.01562 12.6562L8.11691 11.9472C8.20761 11.3124 8.9454 11.0068 9.45843 11.3915L10.0314 11.8213C10.3609 12.1508 10.8952 12.1508 11.2247 11.8213L11.8213 11.2247C12.1508 10.8952 12.1508 10.3609 11.8213 10.0314L11.3915 9.45844C11.0068 8.9454 11.3124 8.20761 11.9472 8.11691L12.6562 8.01562C13.1222 8.01562 13.5 7.63787 13.5 7.17188V6.32812C13.5 5.86213 13.1222 5.48438 12.6562 5.48438L11.9472 5.38309C11.3124 5.29239 11.0068 4.5546 11.3915 4.04157L11.8213 3.46858C12.1508 3.13908 12.1508 2.60485 11.8213 2.27534L11.2247 1.67872C10.8952 1.34922 10.3609 1.34922 10.0314 1.67872L9.45844 2.10846C8.9454 2.49324 8.20761 2.18763 8.11691 1.55278L8.01562 0.84375C8.01562 0.37776 7.63787 0 7.17188 0H6.32812ZM6.75 8.4375C7.68198 8.4375 8.4375 7.68198 8.4375 6.75C8.4375 5.81802 7.68198 5.0625 6.75 5.0625C5.81802 5.0625 5.0625 5.81802 5.0625 6.75C5.0625 7.68198 5.81802 8.4375 6.75 8.4375Z"
    />
  </svg>
)

export default memo(SvgComponent)
