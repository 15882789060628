import React, { memo } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from 'root/icons/CheckIcon'
import { toClassName } from 'root/constants'

const Progress = ({ values, labels, className }) => {
  return (
    <div className={'flex items-center pt-8 ' + className} style={{ bottom: 20, right: 99 }}>
      {values.map((value, index) => (
        <>
          <div
            key={index}
            className={toClassName([
              'flex items-center justify-center rounded-full',
              value ? 'bg-brown-dim border-white text-white' : 'bg-white border-brown-dim-light text-brown-dim-light',
            ])}
            style={{
              width: 27,
              height: 27,
              borderWidth: 1,
              boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
            }}
          >
            <CheckIcon />
            {labels && <div className="absolute text-brown-dim font-bold top-0">{labels[index]}</div>}
          </div>
          {index !== values.length - 1 && <div key={'_' + index} className="w-32 h-1 bg-brown-dim" />}
        </>
      ))}
    </div>
  )
}

Progress.propTypes = {
  values: PropTypes.arrayOf(PropTypes.bool).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
}

export default memo(Progress)
