import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { childrenPropType } from 'root/constants'

const OrderSubHeader = ({ label, children, className = '' }) => {
  const value = label && i18n.value(label)
  return <h2 className={`text-lg font-medium text-brown-dim ${className}`}>{value || children}</h2>
}

OrderSubHeader.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: childrenPropType,
}

export default memo(OrderSubHeader)
