import { connect } from 'react-redux'

import { loadMemoAgreementList } from '../../thunks'
import { isMemoAgreementListLoading, getMemoAgreementListData } from '../../selectors'
import { updateMemoAgreementFilters } from '../../actions'

const mapStateToProps = (state) => ({
  isLoading: isMemoAgreementListLoading(state),
  data: getMemoAgreementListData(state),
})

const mapDispatchToProps = {
  loadData: loadMemoAgreementList,
  updateFilters: updateMemoAgreementFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)
