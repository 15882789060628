import Card from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/Card'
import CardRow from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/components/CardRow'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DealPaymentIcon from 'root/icons/DealPaymentIcon'
import Date from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import i18n from 'root/i18n'
import { getDocumentFile } from 'root/api'
import { DOCUMENT_TYPE_ADDITIONAL_INVOICE, STATE_ADDITIONAL_INVOICE_CONFIRMED } from 'root/modules/orders/constants'

const CardDealAdditionalPayment = ({ order, children, help }) => {
  const invoice = order.documents.find((document) => document.type === DOCUMENT_TYPE_ADDITIONAL_INVOICE)
  const invoicePaidDate = invoice?.specification.paymentReceived
  const invoicePaid = invoicePaidDate > 0
  const invoiceFile = invoice?.files[0]
  const invoiceIssuedDate = invoiceFile?.created
  const invoiceIssued = invoiceIssuedDate > 0
  const isCurrent = order.state.key === STATE_ADDITIONAL_INVOICE_CONFIRMED && invoiceIssued && !invoicePaid
  const data = { invoice, invoiceIssued, additionalInvoice: true }
  return (
    <Card help={help} label="order.card.dealPayment" Icon={DealPaymentIcon} current={isCurrent} completed={invoicePaid}>
      <CardRow label="order.card.invoiceIssued">
        {invoiceIssued ? <Date value={invoiceIssuedDate} /> : children && isCurrent && children(data)}
      </CardRow>
      <CardRow label="order.card.paymentReceived">
        {invoicePaid || !invoiceIssued ? <Date value={invoicePaidDate} /> : children && isCurrent && children(data)}
      </CardRow>
      {invoiceFile && (
        <CardRow full>
          <button
            type="button"
            onClick={() => {
              getDocumentFile(invoice.id, invoiceFile.id)
            }}
            className="text-brown-dim"
          >
            {i18n.value('order.card.viewInvoice')}
          </button>
        </CardRow>
      )}
    </Card>
  )
}
CardDealAdditionalPayment.propTypes = {
  order: PropTypes.object.isRequired,
  help: PropTypes.string,
  children: PropTypes.func,
}
export default memo(CardDealAdditionalPayment)
