import GridIcon from 'root/icons/GridIcon'
import React from 'react'
import { pick } from 'lodash'

export const PRODUCT_STATES = [
  { key: '', icon: <GridIcon width={15} height={15} /> },
  { key: 'deal' },
  { key: 'in_stock' },
  { key: 'moderation' },
  { key: 'archive' },
]

export const ACTION_REMOVE = 'remove'
export const ACTION_ARCHIVE = 'archive'
export const ACTION_ACTIVATE = 'activate' //unarchive

export const COLOR_WHITE = 1
export const COLOR_FANCY = 2
export const CURRENCY_DOLLAR = 1

export const COLOR_STEPS = ['J', 'I', 'H', 'G', 'F', 'E', 'D']
export const QUALITY_STEPS = ['ID', 'EX', 'VG', 'GD', 'FR', 'PR']
export const CLARITY_STEPS = ['SI2', 'SI1', 'VS2', 'VS1', 'VVS2', 'VVS1', 'IF', 'FL']
export const FLUORESCENCE_STEPS = ['Very strong', 'Strong', 'Medium', 'Faint', 'NONE']
export const CERTIFICATE_TYPES = ['GIA', 'IGI', 'HRD', 'GCAL', 'AGS']
export const TECHNOLOGY_TYPES = ['CVD', 'HPHT']

const setIndex = (item, index) => (item.index = index)

export const INTENSITY_OPTIONS = [
  { title: 'Faint' },
  { title: 'Very light' },
  { title: 'Light' },
  { title: 'Fancy light' },
  { title: 'Fancy' },
  { title: 'Fancy dark' },
  { title: 'Fancy intense' },
  { title: 'Fancy vivid' },
  { title: 'Fancy deep' },
]
INTENSITY_OPTIONS.forEach(setIndex)

export const OVERTONE_OPTIONS = [
  { title: 'Red', color: '#EB5757' },
  { title: 'Orangey Red', color: '#FEBAAD' },
  { title: 'Reddish Orange', color: '#FF5349' },
  { title: 'Pink', color: '#FFBAEC' },
  { title: 'Pinkish Orange', color: '#FA8072' },
  { title: 'Orange', color: '#F2A64C' },
  { title: 'Yellowish Orange', color: '#FFC476' },
  { title: 'Yellow Orange', color: '#FFAE42' },
  { title: 'Orangey Yellow', color: '#F8D568' },
  { title: 'Yellow', color: '#F2D74C' },
  { title: 'Yellow Brown', color: '#B79400' },
  { title: 'Greenish Yellow', color: '#E2F665' },
  { title: 'Green Yellow', color: '#D9E650' },
  { title: 'Yellow Green', color: '#9ACD32' },
  { title: 'Yellowish Green', color: '#9ACD32' },
  { title: 'Brown Greenish Yellow', color: '#989A48' },
  { title: 'Gray Greenish Yellow', color: '#C6C193' },
  { title: 'Gray Yellowish Green', color: '#A3AF89' },
  { title: 'Gray Green', color: '#75816B' },
  { title: 'Green', color: '#27AE60' },
  { title: 'Bluish Green', color: '#0DB0BA' },
  { title: 'Blue Green', color: '#0D98BA' },
  { title: 'Green Blue', color: '#1179B4' },
  { title: 'Greenish Blue', color: '#1164B4' },
  { title: 'Blue', color: '#4C7AF2' },
  { title: 'Violetish Blue', color: '#324AB2' },
  { title: 'Bluish Violet', color: '#7C2BE2' },
  { title: 'Violet', color: '#AD0469' },
  { title: 'Purple', color: '#BB6BD9' },
  { title: 'Reddish Purple', color: '#960955' },
  { title: 'Red Purple', color: '#953553' },
  { title: 'Purple red', color: '#6B1C23' },
  { title: 'Purplish Red', color: '#89232C' },
  { title: 'Pinkish Purple', color: '#D982B5' },
  { title: 'Pink Purple', color: '#B95B92' },
  { title: 'Purple Pink', color: '#E03FD8' },
  { title: 'Purplish Pink', color: '#E03FA0' },
  { title: 'Brown Pink', color: '#DBA4C5' },
  { title: 'Pink Brown', color: '#DBA8A4' },
  { title: 'Pinkish Brown', color: '#BB8784' },
  { title: 'Light Brown', color: '#AE7351' },
  { title: 'Champagne', color: '#F7E7CE' },
  { title: 'Cognac', color: '#9A463D' },
  { title: 'Chameleon', color: 'red' },
  { title: 'Gray', color: '#808080' },
  { title: 'Grayish', color: '#B6B4B4' },
  { title: 'Black', color: '#1B1A1A' },
  { title: 'White', color: '#F9F8F8', colorBorder: true },
]
OVERTONE_OPTIONS.forEach(setIndex)

export const COLOR_OPTIONS = [
  { title: 'Yellow', color: '#F2D74C' },
  { title: 'Pink', color: '#FFBAEC' },
  { title: 'Blue', color: '#4C7AF2' },
  { title: 'Green', color: '#27AE60' },
  { title: 'Red', color: '#EB5757' },
  { title: 'Purple', color: '#BB6BD9' },
  { title: 'Orange', color: '#F2A64C' },
  { title: 'Violet', color: '#AD0469' },
  { title: 'Gray', color: '#808080' },
  { title: 'Black', color: '#1B1A1A' },
  { title: 'Brown', color: '#884D2C' },
  { title: 'Champagne', color: '#F7E7CE' },
  { title: 'Cognac', color: '#9A463D' },
  { title: 'Chameleon', color: 'red' },
]
COLOR_OPTIONS.forEach(setIndex)

// so - sort order
// ipp - items per page

const FILTER_KEY_LIST = [
  's', // tab
  'q', // query

  'sh', // shape
  // p - price
  'pfr',
  'pto',
  'cot', // show fancy
  // c - carat
  'cfr',
  'cto',
  'covw', // color white
  'fin', // fancy intensity
  'fov', // fancy overtone
  'covf', // color fancy
  'cl', // clarity
  'cu', // cut
  'loc', // location
  'ph', // has photo

  'ce', // certificate
  'po', // polish
  'sy', // symmetry
  // x - measurement x
  'xfr',
  'xto',
  // y - measurement y
  'yfr',
  'yto',
  // z - measurement z
  'zfr',
  'zto',
  // tb - table size
  'tbfr',
  'tbto',
  // dp - total depth
  'dpfr',
  'dpto',
  'fl', // fluorescence
  'haa', // hearts & arrows
  'gt', // grown technology
  's', // supplier
]

export const getFilterValues = (filters) => pick(filters, FILTER_KEY_LIST)
