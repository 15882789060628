import { COLORS } from 'root/constants'

export default {
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 0',
    marginTop: 5,
  },
  flex: {
    flex: 1,
  },
  divider: {
    borderBottomColor: COLORS.MOUSE,
    borderBottomWidth: 1,
  },
  text: {
    maxWidth: '60%',
    marginLeft: 16,
    marginRight: 16,
    fontSize: 12,
    fontWeight: '500',
    color: COLORS.ASPHALT,
    textAlign: 'center',
  },
}
