import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { EyeIcon } from 'root/icons'
import './styles.css'
import { Field } from 'formik'
import { toClassName } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const PasswordInputFormik = ({ itemKey, placeHolder, ...other }) => {
  const [visible, setVisible] = useState(false)
  const toggleVisible = useCallback(() => setVisible((visible) => !visible), [])
  return (
    <div className="flex items-center w-full">
      <Field id={itemKey} name={itemKey} className={'input-form w-full'}>
        {({ field, form, meta }) => (
          <div className="flex items-center w-full">
            <span className="group relative flex-inline flex-col w-full">
              {meta.touched && meta.error && (
                <div className="z-10 bg-red-300 text-white p-1 text-xs absolute hidden group-hover:block top-full left-0 whitespace-no-wrap ml-2 mt-2">
                  {meta.error}
                </div>
              )}
              <input
                type={visible ? 'text' : 'password'}
                placeholder={placeHolder}
                {...field}
                className={toClassName([
                  'input-form w-full',
                  meta.touched && meta.error ? 'border-red-500' : 'border-gray-300',
                ])}
              />
              {meta.touched && meta.error && (
                <i className="text-red-700 absolute right-0 transform translate-x-1/2 -translate-y-1/2">
                  <ExclaimIcon width={15} height={15} />
                </i>
              )}
            </span>
            <EyeIcon onClick={toggleVisible} className="ml-1 cursor-pointer" />
          </div>
        )}
      </Field>
    </div>
  )
}

PasswordInputFormik.propTypes = {
  placeHolder: PropTypes.string,
  itemKey: PropTypes.string.isRequired,
}

export default PasswordInputFormik
