import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'
import { Field } from 'formik'

const DataTable = ({ headers, data, isEditing, rowsKey }) => {
  return (
    <table className="data-table">
      <thead>
        <tr>
          <th key={'index'}>#</th>
          {headers.map(
            ({ id, name, onlyEdit }) =>
              ((onlyEdit && isEditing) || !onlyEdit) && (
                <th className="whitespace-no-wrap" key={id}>
                  {name}
                </th>
              ),
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td key={index}>{index + 1}</td>
            {headers.map(({ id, className, edit, render, onlyEdit }) => (
              <td key={id} className={className}>
                {isEditing ? (
                  <Field
                    id={rowsKey + '[' + index + ']["' + id + '"]'}
                    name={rowsKey + '[' + index + ']["' + id + '"]'}
                    className="block form-input text-brown-dim border-gray-300 shadow shadow-inset px-2 py-0 w-full mt-1 mb-3"
                  >
                    {edit}
                  </Field>
                ) : (
                  !onlyEdit && (render ? render({ row, index, id, className, value: row[id] }) : <div>{row[id]}</div>)
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

DataTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isEditing: PropTypes.bool,
  rowsKey: PropTypes.string.isRequired,
}

export default DataTable
