import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="110" height="36" viewBox="0 0 110 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M110 30.8756C110 33.5407 107.824 35.6997 105.138 35.6997H4.86209C2.17803 35.7018 0 33.5407 0 30.8756V4.82411C0 2.16102 2.17803 0 4.86209 0H105.138C107.822 0 110 2.16102 110 4.82411V30.8756Z"
      fill="black"
    />
    <path
      d="M28.6855 23.5977C28.1036 24.878 27.8253 25.4491 27.0747 26.5809C26.0289 28.1603 24.5529 30.1289 22.727 30.1456C21.1035 30.1603 20.686 29.0975 18.4827 29.1101C16.2794 29.1226 15.8197 30.1644 14.1962 30.1498C12.3703 30.1331 10.9724 28.357 9.92662 26.7754C7.00009 22.3572 6.69648 17.1712 8.4992 14.416C9.78114 12.4579 11.8031 11.3115 13.705 11.3115C15.6405 11.3115 16.8592 12.3659 18.4595 12.3659C20.0135 12.3659 20.9601 11.3115 23.1972 11.3115C24.8903 11.3115 26.6825 12.2257 27.9602 13.8073C23.7749 16.0812 24.456 22.012 28.6855 23.5977Z"
      fill="white"
    />
    <path
      d="M21.5 9.5434C22.3138 8.50787 22.9316 7.04348 22.706 5.5498C21.3777 5.63976 19.8237 6.47864 18.915 7.57275C18.0906 8.56644 17.4117 10.0371 17.6752 11.468C19.128 11.514 20.6292 10.6542 21.5 9.5434Z"
      fill="white"
    />
    <path
      d="M37.5979 14.3486L33.4062 26.5867H35.3249L36.5753 22.8295H40.6319L41.9181 26.5867H43.8895L39.681 14.3486H37.5979ZM38.5909 16.3611C38.7322 16.9071 38.8861 17.4741 39.0822 18.087L40.2123 21.3317H36.9991L38.1123 18.0661C38.281 17.5368 38.4412 16.9657 38.5909 16.3611Z"
      fill="white"
    />
    <path
      d="M49.3145 17.5225C48.0431 17.5225 47.0247 18.0099 46.3479 18.9345L46.2741 17.717H44.5663L44.5768 17.8718C44.6274 18.6291 44.6485 19.4659 44.6485 20.6625V30.0953H46.5145V25.6937C47.0943 26.382 48.0178 26.7816 49.0657 26.7816C50.1558 26.7816 51.1615 26.3569 51.8931 25.5891C52.7112 24.7314 53.1413 23.4993 53.1413 22.0265C53.1413 20.7253 52.766 19.5998 52.0576 18.7735C51.3639 17.9681 50.3898 17.5225 49.3145 17.5225ZM48.8507 19.0559C50.5142 19.0559 51.2585 20.5851 51.2585 22.0997C51.2585 24.0536 50.3076 25.2691 48.779 25.2691C47.7353 25.2691 46.8329 24.5641 46.5799 23.5516C46.5356 23.3947 46.5145 23.1875 46.5145 22.9512V21.4617C46.5145 21.3278 46.544 21.1625 46.5735 21.0056C46.582 20.9554 46.5925 20.9052 46.6009 20.855C46.8793 19.7944 47.8028 19.0559 48.8507 19.0559Z"
      fill="white"
    />
    <path
      d="M58.9248 17.5225C57.6534 17.5225 56.6351 18.0099 55.9582 18.9345L55.8844 17.717H54.1787L54.1871 17.8718C54.2377 18.6312 54.2588 19.4659 54.2588 20.6625V30.0953H56.1248V25.6937C56.7046 26.382 57.6281 26.7816 58.676 26.7816C59.7661 26.7816 60.7718 26.3569 61.5056 25.5891C62.3216 24.7314 62.7538 23.4993 62.7538 22.0265C62.7538 20.7253 62.3785 19.5998 61.6679 18.7735C60.9743 17.9681 60.0001 17.5225 58.9248 17.5225ZM58.461 19.0559C60.1245 19.0559 60.8688 20.5851 60.8688 22.0997C60.8688 24.0536 59.9179 25.2691 58.3893 25.2691C57.3456 25.2691 56.4411 24.5641 56.1902 23.5516C56.1459 23.3947 56.1248 23.1875 56.1248 22.9512V21.4617C56.1248 21.3257 56.1543 21.1625 56.1839 21.0035C56.1923 20.9533 56.2028 20.9031 56.2113 20.855C56.4896 19.7944 57.4131 19.0559 58.461 19.0559Z"
      fill="white"
    />
    <path
      d="M70.9662 19.5349C69.2246 18.8654 68.6659 18.3403 68.6659 17.3676C68.6659 16.5496 69.3216 15.7233 70.787 15.7233C71.9951 15.7233 72.7689 16.1396 72.9777 16.2693L73.1358 16.3655L73.6629 14.8195L73.5596 14.7589C73.2475 14.5769 72.3557 14.1543 70.8418 14.1543C68.4613 14.1543 66.7999 15.5476 66.7999 17.5433C66.7999 19.152 67.8415 20.2775 70.0764 21.0829C71.6599 21.6708 72.2502 22.2712 72.2502 23.3025C72.2502 24.4699 71.3415 25.1937 69.8782 25.1937C68.9252 25.1937 67.9448 24.9175 67.1878 24.4364L67.0255 24.3339L66.5237 25.9154L66.6165 25.9761C67.3207 26.4426 68.6469 26.7815 69.7707 26.7815C72.9777 26.7815 74.1162 24.8401 74.1162 23.1791C74.1141 21.4679 73.2011 20.4114 70.9662 19.5349Z"
      fill="white"
    />
    <path
      d="M77.4729 15.6084L75.6427 16.1795V17.7171H74.3018V19.1961H75.6427V23.7295C75.6427 24.8257 75.8536 25.5934 76.2879 26.0788C76.7054 26.5327 77.3464 26.7817 78.0928 26.7817C78.7991 26.7817 79.2988 26.6436 79.5349 26.5599L79.6404 26.5223L79.5518 25.039L79.3726 25.0934C79.1364 25.1646 78.894 25.1939 78.5039 25.1939C77.7807 25.1939 77.4729 24.7399 77.4729 23.6751V19.1982H79.7247V17.7192H77.4729V15.6084Z"
      fill="white"
    />
    <path
      d="M84.3485 17.5225C81.7382 17.5225 79.9144 19.4555 79.9144 22.2231C79.9144 24.9072 81.6792 26.7837 84.2072 26.7837H84.2241C86.36 26.7837 88.6582 25.3067 88.6582 22.0642C88.6582 20.7462 88.247 19.6144 87.469 18.7881C86.6868 17.9618 85.6073 17.5225 84.3485 17.5225ZM84.2958 18.9848C85.9741 18.9848 86.7395 20.6081 86.7395 22.1185C86.7395 23.9741 85.6958 25.3214 84.26 25.3214H84.241C82.8262 25.3214 81.7973 23.9971 81.7973 22.1708C81.7994 20.5851 82.5711 18.9848 84.2958 18.9848Z"
      fill="white"
    />
    <path
      d="M94.3743 17.6082L94.2625 17.581C94.0981 17.5413 93.9631 17.5225 93.7987 17.5225C92.8225 17.5225 91.9453 18.0936 91.4583 19.014L91.4035 17.7191H89.7294L89.7399 17.8739C89.7905 18.6061 89.8095 19.4303 89.8095 20.5412L89.7926 26.5912H91.6586V21.8717C91.6586 21.6144 91.6755 21.3717 91.7092 21.1541C91.92 20.0203 92.6896 19.2881 93.6722 19.2881C93.864 19.2881 94.0137 19.3027 94.174 19.3195L94.3743 19.3404V17.6082Z"
      fill="white"
    />
    <path
      d="M98.5701 17.5059C96.0863 17.5059 94.3511 19.4744 94.3511 22.2965C94.3511 25.0119 96.0779 26.7671 98.7493 26.7671C100.154 26.7671 101.132 26.4616 101.703 26.2043L101.815 26.1541L101.457 24.7734L101.301 24.8362C100.792 25.0412 100.116 25.2504 98.9622 25.2504C98.1505 25.2504 96.2824 24.9847 96.1665 22.5664L102.19 22.5852L102.216 22.4659C102.254 22.2777 102.273 22.0245 102.273 21.694C102.273 19.761 101.303 17.5059 98.5701 17.5059ZM98.4457 18.9138C99.015 18.9138 99.4746 19.0874 99.8162 19.4326C100.354 19.9744 100.447 20.7735 100.459 21.1564H96.2023C96.3773 20.0874 97.0499 18.9138 98.4457 18.9138Z"
      fill="white"
    />
    <path
      d="M33.4063 5.98936C33.9271 5.92032 34.5322 5.87012 35.1964 5.87012C36.4003 5.87012 37.2563 6.11069 37.8235 6.58348C38.4096 7.05627 38.747 7.73407 38.747 8.67128C38.747 9.61686 38.4096 10.3888 37.8129 10.9223C37.1973 11.4641 36.1958 11.757 34.9349 11.757C34.3277 11.757 33.8385 11.7402 33.4042 11.6879V5.98936H33.4063ZM34.2539 11.121C34.4647 11.1545 34.7726 11.1545 35.1015 11.1545C36.8916 11.1628 37.8636 10.2612 37.8636 8.69638C37.8741 7.31358 37.0075 6.46215 35.2364 6.46215C34.8042 6.46215 34.4753 6.49771 34.2539 6.53955V11.121Z"
      fill="white"
    />
    <path
      d="M41.7495 11.7994C40.497 11.7994 39.5166 10.9752 39.5166 9.65933C39.5166 8.26607 40.5455 7.4502 41.8254 7.4502C43.1642 7.4502 44.0688 8.31837 44.0688 9.58192C44.0688 11.1279 42.8648 11.7994 41.76 11.7994H41.7495ZM41.779 11.2325C42.5865 11.2325 43.1937 10.5526 43.1937 9.60912C43.1937 8.90412 42.7995 8.01921 41.798 8.01921C40.807 8.01921 40.3726 8.84346 40.3726 9.63422C40.3726 10.5442 40.9504 11.2325 41.7684 11.2325H41.779Z"
      fill="white"
    />
    <path
      d="M45.4096 7.5459L46.0358 9.65252C46.1602 10.1169 46.2867 10.5542 46.3732 10.9851H46.4027C46.5081 10.5646 46.662 10.1169 46.816 9.66089L47.5771 7.5459H48.2898L49.0109 9.62533C49.1838 10.1149 49.3187 10.5625 49.4241 10.983H49.4536C49.5317 10.5625 49.6561 10.1232 49.81 9.6337L50.4741 7.5459H51.3112L49.81 11.7048H49.0404L48.3277 9.71947C48.1633 9.24668 48.0283 8.83456 47.9145 8.34504H47.8955C47.7795 8.84293 47.6362 9.28224 47.4717 9.72784L46.7211 11.7048H45.9515L44.5452 7.5459H45.4096Z"
      fill="white"
    />
    <path
      d="M52.1568 8.67177C52.1568 8.23246 52.1463 7.88937 52.1189 7.54629H52.8695L52.918 8.22618H52.937C53.1689 7.83917 53.7066 7.45215 54.4761 7.45215C55.1213 7.45215 56.1228 7.79523 56.1228 9.22196V11.7051H55.2774V9.30774C55.2774 8.6383 54.9969 8.07138 54.1999 8.07138C53.6517 8.07138 53.2174 8.42283 53.0635 8.84541C53.0255 8.93955 53.0066 9.07762 53.0066 9.19686V11.7072H52.159V8.67177H52.1568Z"
      fill="white"
    />
    <path d="M57.4974 5.60254H58.345V11.7048H57.4974V5.60254Z" fill="white" />
    <path
      d="M61.6469 11.7994C60.3966 11.7994 59.4141 10.9752 59.4141 9.65933C59.4141 8.26607 60.443 7.4502 61.7249 7.4502C63.0617 7.4502 63.9662 8.31837 63.9662 9.58192C63.9662 11.1279 62.7623 11.7994 61.6553 11.7994H61.6469ZM61.6764 11.2325C62.484 11.2325 63.0912 10.5526 63.0912 9.60912C63.0912 8.90412 62.6969 8.01921 61.6954 8.01921C60.7044 8.01921 60.2701 8.84346 60.2701 9.63422C60.2701 10.5442 60.8478 11.2325 61.6659 11.2325H61.6764Z"
      fill="white"
    />
    <path
      d="M68.3179 10.7073C68.3179 11.0671 68.3369 11.4206 68.3854 11.7031H67.6243L67.5462 11.1801H67.5188C67.2679 11.5064 66.7577 11.7993 66.0935 11.7993C65.151 11.7993 64.6682 11.2073 64.6682 10.6048C64.6682 9.59852 65.6697 9.04833 67.4682 9.05879V8.97302C67.4682 8.62993 67.3628 8.00234 66.4098 8.01071C65.967 8.01071 65.5137 8.12158 65.1869 8.32032L64.995 7.81406C65.3809 7.59859 65.948 7.45215 66.5342 7.45215C67.9679 7.45215 68.3137 8.32032 68.3137 9.15293V10.7073H68.3179ZM67.4893 9.58179C66.5658 9.56505 65.5158 9.71149 65.5158 10.519C65.5158 11.0169 65.8806 11.2407 66.3044 11.2407C66.92 11.2407 67.3143 10.8976 67.4492 10.5441C67.4788 10.4667 67.4872 10.3809 67.4872 10.3035V9.58179H67.4893Z"
      fill="white"
    />
    <path
      d="M73.6966 5.60254V10.6296C73.6966 10.9999 73.7071 11.4203 73.7345 11.7048H72.9734L72.9354 10.9831H72.9164C72.6571 11.4475 72.0984 11.799 71.3372 11.799C70.2113 11.799 69.3342 10.9476 69.3342 9.684C69.3236 8.29074 70.2978 7.44976 71.4237 7.44976C72.1469 7.44976 72.6276 7.751 72.83 8.07735H72.849V5.60254H73.6966ZM72.8511 9.2384C72.8511 9.1359 72.8427 9.01456 72.8131 8.91205C72.6887 8.43927 72.227 8.04388 71.5902 8.04388C70.7152 8.04388 70.1944 8.73214 70.1944 9.64216C70.1944 10.4936 70.6667 11.1881 71.5713 11.1881C72.1384 11.1881 72.6592 10.845 72.8131 10.2865C72.8427 10.184 72.8511 10.0794 72.8511 9.96014V9.2384Z"
      fill="white"
    />
    <path
      d="M79.0395 11.7994C77.7892 11.7994 76.8066 10.9752 76.8066 9.65933C76.8066 8.26607 77.8377 7.4502 79.1175 7.4502C80.4564 7.4502 81.3609 8.31837 81.3609 9.58192C81.3609 11.1279 80.157 11.7994 79.0521 11.7994H79.0395ZM79.069 11.2325C79.8765 11.2325 80.4838 10.5526 80.4838 9.60912C80.4838 8.90412 80.0895 8.01921 79.088 8.01921C78.097 8.01921 77.6627 8.84346 77.6627 9.63422C77.6627 10.5442 78.2404 11.2325 79.0585 11.2325H79.069Z"
      fill="white"
    />
    <path
      d="M82.4277 8.67177C82.4277 8.23246 82.4172 7.88937 82.3898 7.54629H83.1404L83.1889 8.22618H83.2078C83.4398 7.83917 83.9774 7.45215 84.747 7.45215C85.3922 7.45215 86.3937 7.79523 86.3937 9.22196V11.7051H85.5461V9.30774C85.5461 8.6383 85.2678 8.07138 84.4687 8.07138C83.9205 8.07138 83.4862 8.42283 83.3322 8.84541C83.2943 8.93955 83.2753 9.07762 83.2753 9.19686V11.7072H82.4277V8.67177Z"
      fill="white"
    />
    <path
      d="M90.83 6.54785V7.54364H92.0423V8.11893H90.83V10.3615C90.83 10.8762 90.9944 11.169 91.4646 11.169C91.6944 11.169 91.8294 11.1523 91.9559 11.1167L91.9938 11.692C91.8294 11.7443 91.57 11.7945 91.2432 11.7945C90.849 11.7945 90.5306 11.6732 90.3282 11.4766C90.0962 11.2443 90.0014 10.8741 90.0014 10.3845V8.12102H89.2803V7.54573H90.0014V6.78006L90.83 6.54785Z"
      fill="white"
    />
    <path
      d="M92.9954 5.60254H93.843V8.19869H93.862C93.9969 7.98321 94.2078 7.78657 94.4692 7.66523C94.7201 7.52716 95.0174 7.44976 95.3358 7.44976C95.9599 7.44976 96.9614 7.79284 96.9614 9.22794V11.7028H96.1138V9.3158C96.1138 8.63591 95.8355 8.07735 95.0364 8.07735C94.4882 8.07735 94.0644 8.42044 93.8999 8.83465C93.8514 8.93716 93.8409 9.05012 93.8409 9.19656V11.7069H92.9933V5.60254H92.9954Z"
      fill="white"
    />
    <path
      d="M98.8187 9.75304C98.8377 10.776 99.5609 11.1965 100.406 11.1965C101.014 11.1965 101.389 11.1024 101.697 10.9894L101.851 11.5229C101.551 11.6442 101.033 11.7885 100.29 11.7885C98.8651 11.7885 97.9985 10.9371 97.9985 9.69238C97.9985 8.42882 98.8356 7.44141 100.193 7.44141C101.714 7.44141 102.108 8.63593 102.108 9.40159C102.108 9.5564 102.099 9.67564 102.081 9.76141L98.8187 9.75304ZM101.294 9.21331C101.302 8.74053 101.073 7.98323 100.128 7.98323C99.2615 7.98323 98.8967 8.68823 98.8293 9.21331H101.294Z"
      fill="white"
    />
  </svg>
)

export default memo(SvgComponent)
