import { connect } from 'react-redux'

import { getProfileCompanyData, isCatalogIFrameEditable, isProfileCompanyLoading } from 'root/modules/profile/selectors'
import { loadCatalogFrameInfo, loadCompanyCommonInfo, saveCompanyProfileThunk } from 'root/modules/profile/thunks'
import { canSupervise, getUserData } from 'root/selectors'

const mapStateToProps = (state) => ({
  data: getProfileCompanyData(state),
  isLoading: isProfileCompanyLoading(state),
  canSupervise: canSupervise(state),
  user: getUserData(state),
  isCatalogIFrameEditable: isCatalogIFrameEditable(state),
})

const mapDispatchToProps = {
  loadCompanyCommonInfo,
  saveCompanyProfileThunk,
  loadCatalogFrameInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)
