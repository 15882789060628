import React, { memo } from 'react'
import PropTypes from 'prop-types'

import i18n from 'root/i18n'
import TextLabel from 'root/components/TextLabel'
import CheckIcon from 'root/icons/CheckIcon'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const CommissionResponsiblesReadonly = ({ values }) => {
  return (
    <div className="ml-auto p-8 border border-blue-skyblue">
      <div className="flex text-brown-dim">
        <div className="w-64" />
        <div className="w-20">{i18n.value('common.seller')}</div>
        <div className="w-20">{i18n.value('common.buyer')}</div>
      </div>
      <div className="flex">
        <div className="w-64 flex">
          <TextLabel label="orders.labels.taxResponsible" />
          {values?.termsChanges?.taxesResponsible && (
            <i className="ml-1 text-brown-dim">
              <ExclaimIcon width={15} height={15} />
            </i>
          )}
        </div>
        <div className="w-20 flex items-center justify-center">{values.isTaxesResponsibleSeller && <CheckIcon />}</div>
        <div className="w-20 flex items-center justify-center">{!values.isTaxesResponsibleSeller && <CheckIcon />}</div>
      </div>
      <div className="flex">
        <div className="w-64 flex">
          <TextLabel label="orders.labels.bankResponsible" />
          {values?.termsChanges?.bankCommissionResponsible && (
            <i className="ml-1 text-brown-dim">
              <ExclaimIcon width={15} height={15} />
            </i>
          )}
        </div>
        <div className="w-20 flex items-center justify-center">{values.isBankResponsibleSeller && <CheckIcon />}</div>
        <div className="w-20 flex items-center justify-center">{!values.isBankResponsibleSeller && <CheckIcon />}</div>
      </div>
    </div>
  )
}

CommissionResponsiblesReadonly.propTypes = {
  values: PropTypes.object.isRequired,
}

export default memo(CommissionResponsiblesReadonly)
