import React, { memo } from 'react'
import Column from './Column'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import { calcAverageR, rapaportSchema, roundTwo } from 'root/modules/orders/constants'
import { trimNumber } from 'root/constants'
import { COLOR_FANCY } from '../../../../../../../../products/constants'

const validateRefDiscount = (referenceDiscount) => {
  let error
  if (!referenceDiscount && referenceDiscount <= 0) {
    error = 'Reference discount value must be greater than 0'
  }
  if (referenceDiscount >= 100) {
    error = `Reference discount value must be less than 100`
  }

  return error
}

const RapaportColumn = ({ children, ...props }) => (
  <Column
    label="order.diamonds.rapaport"
    columnClass="w-16 text-center"
    className="w-16 text-center text-blue-dark flex items-center justify-center"
    {...props}
  >
    {children ||
      (({ item, isEditable, formContext }) => {
        if (item.colorType.id == COLOR_FANCY) {
          return '-'
        }
        return isEditable ? (
          <div className="flex items-center">
            <InputTermFormik
              itemKey={`orderItems['${item.id}'].referenceDiscount`}
              value={item.referenceDiscount}
              isEditable={isEditable}
              inputClass="w-16 text-sm"
              onChange={async ({ target }) => {
                const { setFieldValue, values } = formContext
                setFieldValue(`orderItems['${item.id}'].referenceDiscount`, trimNumber(target.value))

                const referenceDiscount = Number.parseFloat(target.value)
                if (await rapaportSchema.isValid(referenceDiscount)) {
                  const { orderItems } = values
                  const referencePrice = Number.parseFloat(orderItems[item.id].referencePrice)
                  if (!referencePrice) return

                  const price = roundTwo(((100 - referenceDiscount) * referencePrice) / 100)
                  orderItems[item.id].price = price
                  setFieldValue(`orderItems['${item.id}'].price`, price)
                  setFieldValue(`averageR`, calcAverageR(orderItems))
                }
              }}
              validate={() => {
                const { values } = formContext
                const { orderItems } = values
                const referenceDiscount = Number.parseFloat(orderItems[item.id].referenceDiscount)

                return validateRefDiscount(referenceDiscount)
              }}
            />
            <span>%</span>
          </div>
        ) : item.referenceDiscount > 0 ? (
          `${item.referenceDiscount}%`
        ) : (
          'N/A'
        )
      })}
  </Column>
)
RapaportColumn.propTypes = Column.propTypes
export default memo(RapaportColumn)
