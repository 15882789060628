import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M29.9414 0H1.05856C0.474118 0 0 0.475916 0 1.06257V25.9383C0 26.525 0.474118 27.0009 1.05856 27.0009H29.9424C30.5268 27.0009 31.0009 26.525 31.0009 25.9383V1.06257C31 0.475916 30.5259 0 29.9414 0ZM29.3543 24.9197H1.64574V1.65198H29.3543V24.9197Z"
        fill="#AA8A9F"
      />
      <path
        d="M21.9228 12.8139C21.5891 12.5732 21.1414 12.5686 20.8031 12.8011L16.6245 15.6804L11.5205 9.59783C11.3372 9.38001 11.0682 9.25279 10.7847 9.25005C10.5047 9.2473 10.2294 9.36994 10.0425 9.5841L2.58789 15.7518V23.976H28.4136V16.0492L21.9228 12.8139Z"
        fill="#AA8A9F"
      />
      <path
        d="M23.9872 7.25601C24.8674 6.37249 24.8674 4.94001 23.9872 4.05649C23.107 3.17296 21.68 3.17296 20.7998 4.05649C19.9196 4.94001 19.9196 6.37249 20.7998 7.25601C21.68 8.13954 23.107 8.13954 23.9872 7.25601Z"
        fill="#AA8A9F"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="31" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(SvgComponent)
