import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import i18n from 'root/i18n'
import { toStyle } from 'root/constants'

const NewMessages = ({ text }) => (
  <div style={styles.row}>
    <div style={toStyle([styles.flex, !text && styles.divider])} />
    <div style={styles.text}>{text || i18n.value('chat.newMessages')}</div>
    <div style={toStyle([styles.flex, !text && styles.divider])} />
  </div>
)

NewMessages.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default memo(NewMessages)
