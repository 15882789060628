import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'

const ResponsibleSelectorRead = ({ label, isSeller }) => {
  return (
    <>
      {label && <label className="w-56">{i18n.value(label)}</label>}
      <div className="flex ml-8 items-center">
        <label className="text-brown-dim ml-2">
          {isSeller ? i18n.value('common.seller') : i18n.value('common.buyer')}
        </label>
      </div>
    </>
  )
}

ResponsibleSelectorRead.propTypes = {
  label: PropTypes.string.isRequired,
  isSeller: PropTypes.bool.isRequired,
}

export default memo(ResponsibleSelectorRead)
