import React, { memo, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import OrderSubHeader from 'root/components/OrderSubHeader'
import Diamonds, {
  CertificateColumn,
  ChangesColumn,
  IndexColumn,
  LocationColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  RemoveColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import {
  ACTION_CANCEL,
  ACTION_CONFIRM_CHANGES,
  STATE_PRODUCTS_APPROVAL,
} from 'root/modules/orders/constants'
import BlueButton from 'root/components/Buttons/BlueButton'
import BrownButton from 'root/components/Buttons/BrownButton'
import { useAsyncLoad } from 'root/hooks'
import i18n from 'root/i18n'
import OrderTimer from 'root/components/OrderTimer'
import AddressSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/AddressSection'
import AverageR
  from 'root/modules/orders/pages/Order/components/components/AverageR'
import DeliveryPrice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/DeliveryPrice'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import { confirmAlert, promptAlert } from 'root/components/AlertWrapper'
import { doNothing } from 'root/constants'
import { isAdmin, isMiddleman } from 'root/selectors'
import { SITE_URL } from 'root/api/env'

const DealRequestBuyer = ({ showCounterparty, order, doAction }) => {
  const termsChangedBySeller = order.state.key === STATE_PRODUCTS_APPROVAL
  const [loading, submitAction] = useAsyncLoad(doAction)
  const cancelOrder = useCallback(async () => {
    try {
      const reason = await promptAlert(
        i18n.value('order.labels.provideReasonForCancel'))
      if (reason) {
        submitAction(ACTION_CANCEL, { reason })
      }
    } catch (e) {
      doNothing()
    }
  }, [submitAction])
  const confirmOrder = useCallback(
    (e) => {
      e.stopPropagation()
      return confirmAlert(i18n.value('order.labels.doYouConfirmTermsCorrect')).
        then(() => {
          submitAction(ACTION_CONFIRM_CHANGES)
        }).
        catch(doNothing)
    },
    [submitAction],
  )
  const renderCertificate =
    order.sellerCompanyId.id !== '36728dd8-5761-4eb7-b446-18fd2cd12dcf'
      ? null
      : () => <div>certified</div>
  return (
    <>
      <section>
        <GeneralTerms
          order={order}
          showCounterparty={showCounterparty}
          number={order.number}
          date={order.date}
          editable={false}
          seller={order.sellerCompanyId.name}
          asSeller={false}
        >
          <ViewOrderFiles order={order} doAction={doAction}/>
        </GeneralTerms>
      </section>
      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds
          isEditable={false}
          highlightDeleted={termsChangedBySeller}
          showDeleted
          items={order.products}
          doAction={doAction}
        >
          <ChangesColumn/>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn>{renderCertificate}</CertificateColumn>
          <RefPriceColumn/>
          <RapaportColumn/>
          <PriceColumn/>
          <LocationColumn/>
          {termsChangedBySeller && <RemoveColumn batch={false}/>}
        </Diamonds>
        <div className="flex mt-4">
          <BrownButton
            type="button"
            onClick={() => {
              window.open(
                `${SITE_URL}/catalog?s=${order.sellerCompanyId.catalogId}`)
            }}
            slim
            className="bg-brown-dim-light"
          >
            {i18n.value('view_stones_from_supplier')}
          </BrownButton>
          <div className="flex flex-grow justify-between">
            <div className="flex w-7/12">
              <AverageR items={order.products} isEditable={false}/>
            </div>
            <div className="flex w-5/12 pl-20">
              <DeliveryPrice isEditable={false} order={order}/>
            </div>
          </div>
        </div>
      </section>

      <section>
        <AddressSection values={order} isOpened={!termsChangedBySeller}/>
      </section>

      <section>
        <OrderSubHeader label="order.labels.dealRequestStatus"
                        className="mb-4"/>
        <div className="text-gray-500">
          {i18n.value(
            termsChangedBySeller
              ? 'order.labels.statusParametersChanged'
              : 'order.labels.statusWaitingForSellerToConfirm',
          )}
        </div>
      </section>
      {termsChangedBySeller && order.timer.isStarted && (
        <section>
          <OrderTimer timer={order.timer} label={'orders.labels.replyTimer'}/>
        </section>
      )}
      <section className="flex space-x-4">
        {termsChangedBySeller && (
          <BrownButton disabled={loading} onClick={confirmOrder} type="button"
                       label="orders.buttons.confirmChanges"/>
        )}
        <BlueButton disabled={loading} onClick={cancelOrder} type="button"
                    label="orders.buttons.cancelDeal"/>
      </section>
    </>
  )
}

DealRequestBuyer.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  showCounterparty: PropTypes.bool,
}

export default memo(
  connect(
    (state) => ({ showCounterparty: isAdmin(state) || isMiddleman(state) }),
    null)(DealRequestBuyer),
)
