import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.611 14.2914C5.36073 14.2914 0.42342 7.71479 0.213652 7.4351L0 7.14764L0.213652 6.86018C0.419536 6.57661 5.35685 0 11.611 0C17.8613 0 22.8025 6.57661 23.0084 6.8563L23.2221 7.14376L23.0084 7.43122C22.7986 7.71479 17.8613 14.2914 11.611 14.2914ZM1.22365 7.14764C2.24529 8.40236 6.56495 13.3241 11.611 13.3241C16.6688 13.3241 20.9807 8.40625 21.9984 7.14764C20.9768 5.88904 16.661 0.971147 11.611 0.971147C6.56107 0.971147 2.24141 5.88904 1.22365 7.14764Z"
      fill="#80506F"
    />
    <path
      d="M11.6103 11.3006C9.31998 11.3006 7.45605 9.43672 7.45605 7.14642C7.45605 4.85612 9.31998 2.99219 11.6103 2.99219C13.9006 2.99219 15.7645 4.85612 15.7645 7.14642C15.7645 9.43672 13.9006 11.3006 11.6103 11.3006Z"
      fill="#80506F"
    />
  </svg>
)

export default memo(SvgComponent)
