import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="30"
    height="34"
    viewBox="0 0 38 42"
    fill="none"
    className="stroke-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.5813 23.7402L23.8604 33.7837L20.3255 30.1315L16.7906 33.7837L23.8604 41.0881L37.1162 27.3924L33.5813 23.7402Z"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.0465 21.9141V0.914062H4.41855L0.883667 4.56624V41.088H18.5581"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.18595 3.65234V6.39148H3.53479"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9766 12.7832H7.95337"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9766 16.4355H7.95337"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.558 20.0879H7.95337"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
