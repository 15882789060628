import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { useParams } from 'react-router-dom'
import Spinner from 'root/components/Spinner'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import ViewAgreementMemoDocs from './components/ViewAgreementMemoDocs'
import { timestampToDate } from 'root/constants'
import OrderSubHeader from 'root/components/OrderSubHeader'
import ResponsibleSelectorRead from 'root/modules/orders/pages/components/ResponsibleSelector/read'
import InputTermRead from 'root/modules/orders/pages/components/InputTerm/read'
import { RESPONSIBLE_SELLER } from 'root/modules/orders/constants'
import OrderSpecialTermsRead from 'root/modules/orders/pages/components/OrderSpecialTerms/read'
import DealsTable from 'root/modules/memo-agreements/pages/MemoAgreement/components/DealsTable'

const MemoAgreement = ({ data, loadMemoAgreement, isLoading, user }) => {
  const { id } = useParams()
  useEffect(() => {
    loadMemoAgreement({ id })
  }, [id, loadMemoAgreement])

  if (isLoading || !data.created) return <Spinner />

  let heading = i18n.value('memoAgreements.labels.header')
  if (data) heading += ' #' + data.number

  return (
    <>
      <section className="container mx-auto lg:w-screen-lg-min">
        <div className="flex justify-between">
          <OrderHeading label={heading} />
          {data.files?.length > 0 && <ViewAgreementMemoDocs memoAgreement={data} />}
        </div>
      </section>
      <section className="mt-12">
        <div className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
          <OrderSubHeader label={'orders.labels.memoTerms'} />
        </div>

        <div className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
          <div className={'my-2'}>
            <span className="text-gray-500">{i18n.value('common.date')}</span>
            <span className="text-brown-dim ml-2">{timestampToDate(data.created)}</span>
          </div>
        </div>
      </section>
      <section className="mt-12">
        <div className="container mx-auto lg:w-screen-lg-min mb-2 mt-4">
          <div className="text-gray-500 mt-8 flex justify-between">
            <div className="grid grid-cols-2 gap-y-3 auto-rows-min">
              <ResponsibleSelectorRead
                label={'orders.labels.taxResponsible'}
                isSeller={data.taxesResponsible === RESPONSIBLE_SELLER}
              />
              <ResponsibleSelectorRead
                label={'orders.labels.bankResponsible'}
                isSeller={data.bankCommissionResponsible === RESPONSIBLE_SELLER}
              />
              <ResponsibleSelectorRead
                label={'orders.labels.deliveryPaymentResponsible'}
                isSeller={data.deliveryPaymentsResponsible === RESPONSIBLE_SELLER}
              />
              <ResponsibleSelectorRead
                label={'orders.labels.returnPaymentResponsible'}
                isSeller={data.returnPaymentResponsible === RESPONSIBLE_SELLER}
              />
            </div>
            <div className="grid grid-cols-3 gap-y-3 auto-rows-min">
              <InputTermRead
                labelBefore={'orders.labels.memoPaymentTermDays'}
                inputClass={'w-16'}
                labelAfter={'common.days'}
                value={data.paymentTermDays}
              />
              <InputTermRead
                labelBefore={'orders.labels.nonReturnPeriod'}
                inputClass={'w-16'}
                labelAfter={'common.days'}
                value={data.nonReturnDays}
              />
              <InputTermRead
                labelBefore={'orders.labels.returnShipmentPeriod'}
                inputClass={'w-16'}
                labelAfter={'common.days'}
                value={data.returnShipmentDays}
              />
              <InputTermRead
                labelBefore={'orders.labels.memoPenaltyDelayPerDay'}
                inputClass={'w-16'}
                labelAfter={'common.percent'}
                value={data.dailyDelayedPaymentFee}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto lg:w-screen-lg-min mb-2 mt-12">
        <OrderSpecialTermsRead label={'orders.labels.specialTerms'} value={data.specialTerms} />
      </div>
      {data.orders?.length > 0 && (
        <section className="mt-6">
          <div className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
            <OrderSubHeader label={'memoAgreements.labels.deals'} />
          </div>
          <div className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
            <DealsTable orders={data.orders} user={user} />
          </div>
        </section>
      )}
    </>
  )
}

MemoAgreement.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object.isRequired,
  loadMemoAgreement: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default memo(connect(MemoAgreement))
