import * as actions from 'root/actions'
import { getUser, saveUserInfo, saveUserPassword, signOut } from 'root/api'
import { doNothing, errorAlert } from 'root/constants'
import { setCrashUserInfo } from 'root/sentry'
import { loadOrderNotificationsSummary } from 'root/modules/orders/thunks'

export const saveUser = (attributes) => async (dispatch) =>
  saveUserInfo(attributes).then(({ data: { data } }) => dispatch(actions.updateUser(data)))

export const loadUser = () => async (dispatch) => {
  return await getUser().then(({ data: { data: user } }) => {
    if (user) {
      const { id, firstName, lastName } = user
      const info = { id, username: [firstName, lastName].join(' ') }
      setCrashUserInfo(info)
    }
    dispatch(actions.setUser(user))
    if (user.activated) {
      dispatch(loadOrderNotificationsSummary())
    }
    return user
  })
}

export const userLogout = () => (dispatch) => {
  signOut()
    .catch(doNothing)
    .then(() => dispatch(actions.userLogout()))
}

export const savePassword = (data) => async (dispatch, getState) => {
  return saveUserPassword(data)
    .then(({ data: { data: user } }) => {
      if (user) {
        const { id, firstName, lastName } = user
        const info = { id, username: [firstName, lastName].join(' ') }
        setCrashUserInfo(info)
      }
      dispatch(actions.setUser(user))
      return user
    })
    .catch(errorAlert)
}
