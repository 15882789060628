import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import DeliveryAddressInfo from 'root/modules/orders/pages/components/DeliveryAddressInfo'

const ViewAddress = ({ order, onClick }) => {
  return (
    <Modal isFull onClick={onClick}>
      <OrderHeading label={i18n.value('order.labels.deliveryAddress')} />
      <div className="mt-4">
        <DeliveryAddressInfo address={order.address} />
      </div>
    </Modal>
  )
}

ViewAddress.propTypes = {
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(ViewAddress)
