import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    className="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4999 4.38462C13.4999 6.80617 11.4853 8.76923 9.00028 8.76923C6.51522 8.76923 4.50069 6.80617 4.50069 4.38462C4.50069 1.96306 6.51522 0 9.00028 0C11.4853 0 13.4999 1.96306 13.4999 4.38462ZM0 14.6154C2.05228 11.9529 5.31975 10.2308 9 10.2308C12.6803 10.2308 15.9477 11.9529 18 14.6154C15.9477 17.2778 12.6803 19 9 19C5.31975 19 2.05228 17.2778 0 14.6154Z"
    />
  </svg>
)

export default memo(SvgComponent)
