import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import { Formik, Form, ErrorMessage } from 'formik'
import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import {
  ACTION_DELIVERY_RECEIVED,
  ACTION_MAKE_SHIPMENT,
  SHIPMENT_PROVIDERS,
  SHIPMENT_VENDOR_FEDEX,
  SHIPMENT_VENDOR_OTHER,
} from 'root/modules/orders/constants'
import CheckBoxFormik from 'root/modules/login/pages/Login/components/CheckboxInput/formik'
import BrownButton from 'root/components/Buttons/BrownButton'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import RadioGroupFormik from 'root/modules/orders/pages/components/RadioGroup/formik'
import CheckIcon from 'root/icons/CheckIcon'
import { toClassName } from 'root/constants'
import { useAsyncLoad } from 'root/hooks'

const ViewDeliveries = ({ order, asSender = false, readOnly = false, onClick, doAction }) => {
  const [loading, submitAction] = useAsyncLoad(doAction)
  const shipments = order.shipments
  const notShipped = order.products
    .filter((p) => !p.disabled)
    .map((p) => {
      const canShipStone = !shipments.find((s) => s.content.find((s) => s.itemId === p.id && s.hasStone))
      const canShipCertificate = !shipments.find((s) => s.content.find((s) => s.itemId === p.id && s.hasCertificate))
      return {
        itemId: p.id,
        hasStone: canShipStone,
        hasCertificate: canShipCertificate,
        hasOther: false,
        canShipOther: false,
        canShipStone,
        canShipCertificate,
        itemName: p.name,
        certificateNumber: p.certificateNumber,
      }
    })
    .filter((p) => p.canShipCertificate || p.canShipStone)
    .concat(
      order.additionalGoods
        .filter((g) => {
          return !shipments.find((s) => s.content.find((s) => s.itemId === g.id && s.hasOther))
        })
        .map((g) => {
          return {
            hasStone: false,
            hasCertificate: false,
            hasOther: true,
            canShipOther: true,
            canShipStone: false,
            canShipCertificate: false,
            itemName: g.name,
            itemId: g.id,
          }
        }),
    )
  let shipmentProviders = []
  for (let i = 0; i < SHIPMENT_PROVIDERS.length; i++) {
    shipmentProviders.push({
      key: SHIPMENT_PROVIDERS[i],
      label: i18n.value('orders.labels.shipmentProvider.' + SHIPMENT_PROVIDERS[i]),
    })
  }

  return (
    <Modal isFull onClick={onClick}>
      <OrderHeading label={i18n.value('order.labels.deliveries')} />
      <div className="flex flex-col space-y-4">
        <div className="text-brown-dim flex ml-auto">
          <div className="w-20">{i18n.value('order.diamonds.shippedToBuyer')}</div>
          <div className="w-20 text-right">{i18n.value('order.diamonds.deliveredToBuyer')}</div>
          {!asSender && !order.allDelivered && <div className="w-32 ml-auto">&nbsp;</div>}
        </div>
        {order.shipments.map((shipment, index) => (
          <ToggleableSection
            withoutHeader
            labelTop="orders.labels.shipment"
            key={`shipment_${index}`}
            renderTop={() => (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={shipment.url}
                  className="text-blue-skyblue font-bold ml-4 mr-8 hover:underline"
                >{`${shipment.trackNumber} (${shipment.vendor})`}</a>

                <div className="flex ml-auto">
                  <div className="w-40 flex items-center justify-center z-10">
                    <i className="flex items-center justify-center w-6 h-6 border-2 border-white-smoke rounded rounded-full text-white-smoke bg-brown-dim">
                      <CheckIcon />
                    </i>
                    <div className="w-16 border border-brown-dim" />
                    <i
                      className={toClassName([
                        'flex items-center justify-center w-6 h-6 border-2 border-white-smoke rounded rounded-full',
                        shipment.state === 'delivered' && 'text-white-smoke bg-brown-dim',
                        shipment.state !== 'delivered' && 'text-brown-dim bg-white',
                      ])}
                    >
                      <CheckIcon />
                    </i>
                  </div>
                  {!readOnly &&
                    !asSender &&
                    !order.allDelivered &&
                    (shipment.state !== 'delivered' ? (
                      <button
                        type="button"
                        disabled={loading}
                        onClick={(e) => {
                          e.stopPropagation()
                          submitAction(ACTION_DELIVERY_RECEIVED, {
                            shipments: [shipment.id],
                          })
                        }}
                        className="ml-auto bg-brown-dim z-10 text-white hover:shadow-sm active:shadow-none disabled:opacity-50 w-32"
                      >
                        {i18n.value('orders.buttons.confirmDelivery')}
                      </button>
                    ) : (
                      <div className="w-32">&nbsp;</div>
                    ))}
                </div>
              </>
            )}
          >
            {shipment.content.map((item, index) => (
              <>
                {item.hasStone && (
                  <div key={`stone_${index}`} className="text-brown-dim">
                    {item.itemName}
                  </div>
                )}
                {item.hasCertificate ? (
                  <div className="flex items-center">
                    {item.certificateUrl ? (
                      <a
                        href={item.certificateUrl}
                        target="_blank"
                        rel="nofollow noreferrer"
                        className="hover:underline cursor-pointer"
                      >
                        <div key={`cert_${index}`} className="text-sm text-blue-dark">
                          {i18n.value('order.diamonds.certificate')}
                          {item.certificateNumber}
                        </div>
                      </a>
                    ) : (
                      <div key={`cert_${index}`} className="text-sm text-blue-dark">
                        {i18n.value('order.diamonds.certificate')}
                        {item.certificateNumber}
                      </div>
                    )}
                    {item.diamondName ? <div className="text-sm text-gray-500">&nbsp;({item.diamondName})</div> : null}
                  </div>
                ) : null}
                {item.hasOther && (
                  <div key={`other_${index}`} className="text-sm text-blue-dark">
                    {item.itemName}
                  </div>
                )}
              </>
            ))}
          </ToggleableSection>
        ))}
        {notShipped.length > 0 && (
          <Formik
            enableReinitialize
            initialValues={{
              shipmentProvider: SHIPMENT_VENDOR_FEDEX,
              shipmentProviderInput: '',
              trackingNumber: '',
              items: notShipped,
            }}
            validate={(values) => {
              const errors = {}
              if (!values.trackingNumber) {
                errors.trackingNumber = 'Tracking number is required'
              }
              if (values.shipmentProvider === SHIPMENT_VENDOR_OTHER) {
                if (!values.shipmentProviderInput) {
                  errors.shipmentProvider = 'Tracking vendor is required in case of "OTHER"'
                }
              }
              if (values.items.filter((item) => item.hasStone || item.hasCertificate || item.hasOther).length === 0) {
                errors.items = 'Please, select an item to ship'
              }
              return errors
            }}
            onSubmit={async (values) => {
              const serverData = {
                shipments: [
                  {
                    vendor:
                      values.shipmentProvider === SHIPMENT_VENDOR_OTHER
                        ? values.shipmentProviderInput
                        : values.shipmentProvider,
                    trackNumber: values.trackingNumber,
                    items: values.items
                      .filter((item) => item.hasStone || item.hasCertificate || item.hasOther)
                      .map((item) => ({
                        itemId: item.itemId,
                        hasCertificate: item.hasCertificate,
                        hasStone: item.hasStone,
                        hasOther: item.hasOther,
                        itemName: item.itemName,
                      })),
                  },
                ],
              }
              await doAction(ACTION_MAKE_SHIPMENT, serverData)
            }}
          >
            {({ values, isSubmitting, handleSubmit, errors }) => (
              <ToggleableSection
                className="pt-8"
                labelTop="order.diamonds.notShipped"
                withoutHeader
                classNameLine="text-blue-skyblue"
              >
                <Form onSubmit={handleSubmit}>
                  <div>
                    {values.items.map((item, index) => (
                      <div key={`item_${index}`}>
                        {item.canShipStone && (
                          <CheckBoxFormik
                            labelAfter={item.itemName}
                            isEditable={asSender && !readOnly}
                            isChecked={item.hasStone}
                            itemKey={`items[${index}].hasStone`}
                          />
                        )}
                        {item.canShipCertificate && (
                          <CheckBoxFormik
                            isEditable={asSender && !readOnly}
                            labelAfter={i18n.value('order.diamonds.certificate') + ' ' + item.certificateNumber}
                            isChecked={item.hasCertificate}
                            itemKey={`items[${index}].hasCertificate`}
                          />
                        )}
                        {item.canShipOther && (
                          <CheckBoxFormik
                            isEditable={asSender && !readOnly}
                            labelAfter={item.itemName}
                            isChecked={item.hasOther}
                            itemKey={`items[${index}].hasOther`}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  {asSender && !readOnly && (
                    <>
                      <div className="flex mt-4 space-x-8">
                        <div className="text-brown-dim">
                          <InputTermFormik
                            labelBefore="orders.labels.trackingNumber"
                            labelBeforeClass="text-brown-dim"
                            itemKey="trackingNumber"
                            isEditable
                            value={values.trackingNumber}
                            inputClass="w-64"
                          />
                        </div>
                        <div className="text-brown-dim w-32">
                          <RadioGroupFormik
                            className="flex flex-col"
                            value={values.shipmentProvider}
                            itemKey="shipmentProvider"
                            options={shipmentProviders}
                          />
                          {values.shipmentProvider === 'other' ? (
                            <InputTermFormik itemKey="shipmentProviderInput" value={values.shipmentProviderInput} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      {Object.keys(errors).length === 0 ? (
                        ''
                      ) : (
                        <section className="my-4 bg-red-300 text-red-600">
                          <ErrorMessage name="items" component="div" />
                          <ErrorMessage name="trackingNumber" component="div" />
                          <ErrorMessage name="shipmentProvider" component="div" />
                        </section>
                      )}
                      <div className="mt-4">
                        <BrownButton disabled={isSubmitting} label="orders.buttons.createShipment" />
                      </div>
                    </>
                  )}
                </Form>
              </ToggleableSection>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  )
}

ViewDeliveries.propTypes = {
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  asSender: PropTypes.bool,
  readOnly: PropTypes.bool,
  doAction: PropTypes.func,
}

export default memo(ViewDeliveries)
