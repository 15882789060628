import React, { memo, useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import './styles.css'

import i18n from 'root/i18n'

import { errorAlert } from 'root/constants'
import BrownButton from 'root/components/Buttons/BrownButton'
import { infoAlert } from 'root/components/AlertWrapper'

const RESEND_TIMEOUT = 30

const Resend = ({ onResend, onAskForHelp }) => {
  const [timer, setTimer] = useState(RESEND_TIMEOUT)
  const [usedResend, setUsedResend] = useState(false)
  const [usedHelp, setUsedHelp] = useState(false)
  const interval = useRef()

  const tick = useCallback(() => {
    setTimer((timer) => {
      timer--
      if (!timer) clearInterval(interval.current)
      return timer
    })
  }, [])

  const startCountdown = useCallback(() => {
    setTimer(RESEND_TIMEOUT)
    interval.current = setInterval(tick, 1000)
  }, [tick])

  useEffect(() => {
    startCountdown()
    return () => clearInterval(interval.current)
  }, [startCountdown])

  const onResendClick = useCallback(() => {
    setUsedResend(true)
    return onResend().then(startCountdown).catch(errorAlert)
  }, [onResend, startCountdown])

  const doAskForHelp = useCallback(() => {
    return onAskForHelp()
      .then(() => {
        setUsedHelp(true)
        return infoAlert(i18n.value('login.askForHelpConfirmation'))
      })
      .catch(errorAlert)
  }, [onAskForHelp])

  const hasTimer = timer > 0

  return (
    <span className="login-resend-text">
      {i18n.value('login.notReceived')}
      {hasTimer && i18n.value('login.resendTimer')}
      <span
        style={{ cursor: !hasTimer && 'pointer' }}
        className="login-resend-highlighted"
        onClick={!hasTimer ? onResendClick : undefined}
      >
        {hasTimer ? `00:${numeral(timer).format('00')}` : i18n.value('login.resend')}
      </span>
      {usedResend && (
        <div className="flex flex-col space-y-2 whitespace-normal mt-2 border border-gray-300 p-2">
          <div>{i18n.value('login.resendSuccess')}</div>
          {!usedHelp && <BrownButton onClick={doAskForHelp}>{i18n.value('login.askForHelp')}</BrownButton>}
        </div>
      )}
    </span>
  )
}

Resend.propTypes = {
  onResend: PropTypes.func.isRequired,
  onAskForHelp: PropTypes.func.isRequired,
}

export default memo(Resend)
