import { connect } from 'react-redux'
import { getProfileCompanyData, isProfileCompanyLoading } from 'root/modules/profile/selectors'
import { canSupervise, isAdmin } from 'root/selectors'
import { loadCompanyCommonInfo, saveCompanyProfileThunk } from 'root/modules/profile/thunks'

const mapStateToProps = (state) => ({
  company: getProfileCompanyData(state),
  isLoading: isProfileCompanyLoading(state),
  canSupervise: canSupervise(state),
  isAdmin: isAdmin(state),
})

const mapDispatchToProps = {
  loadCompanyCommonInfo,
  saveCompanyProfileThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)
