import instance from './instance'
import { transformUser } from 'root/api/transformers'
import Botd from '@fpjs-incubator/botd-agent'
import { BOTD_PUBLIC_KEY } from 'root/api/env'

export const getApiVersion = () => {
  return instance({
    method: 'get',
    url: '/',
  })
}

export const getUser = () => {
  return instance({
    authServer: true,
    method: 'get',
    url: '/user',
    auth: true,
    transformResponse: transformUser,
  })
}

export const registerFCM = (token) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/fcm',
    data: { token },
    auth: true,
  })
}

export const activationInit = () => {
  return instance({
    authServer: true,
    method: 'get',
    url: '/activation/fast-init',
    auth: true,
  })
}

export const saveUserInfo = (data) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/activation/fast-save',
    data,
    auth: true,
  })
}

const botdPromise = BOTD_PUBLIC_KEY
  ? Botd.load({
      publicKey: BOTD_PUBLIC_KEY,
    })
  : Promise.resolve(null)

export const sessionSign = async (login, password) => {
  if (password) {
    return instance({
      authServer: true,
      method: 'post',
      url: '/',
      data: { login, password },
    })
  }
  const botd = await botdPromise
  const result = botd ? await botd.detect() : { requestId: 'unknown' }

  return instance({
    authServer: true,
    method: 'post',
    url: '/',
    data: { login, password, requestId: result.requestId },
  })
}

export const askHelpWithLogin = (login) => {
  return instance({
    method: 'post',
    url: '/user/help',
    data: { login },
  })
}

export const activateAccount = ({ name, companyName, isSeller }) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/activation/save',
    data: {
      step: 'step0',
      name,
      companyName,
      roles: [isSeller ? 2 : 1],
    },
    auth: true,
  })
}

export const activateFirstStep = (name) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/activation/save',
    data: {
      step: 'step1',
      name,
    },
    auth: true,
  })
}

export const activateSecondStep = (name, country, tin, isSeller) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/activation/save',
    data: {
      step: 'step2',
      companyName: name,
      countryId: country,
      vat: tin,
      roles: [isSeller ? 2 : 1],
    },
    auth: true,
  })
}

export const activateThirdStep = (data) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/activation/save',
    data: {
      step: 'step3',
      ...data,
    },
    auth: true,
  })
}

export const confirmActivationCode = (key, code) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/activation/verify-code',
    data: { oneTimeKey: key, oneTimeCode: code },
    auth: true,
  })
}

export const sendActivationCode = (login) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/activation/send-code',
    data: { login },
    auth: true,
  })
}

export const smsConfirm = (oneTimeKey, oneTimeCode) => {
  return instance({
    authServer: true,
    method: 'post',
    url: '/',
    data: { oneTimeKey, oneTimeCode },
  })
}

export const signOut = () => {
  return instance({
    authServer: true,
    method: 'get',
    auth: true,
    url: '/sign-out',
  })
}

export const saveUserPassword = (data) => {
  return instance({
    authServer: true,
    method: 'post',
    url: `/user`,
    data: { oldPassword: data.oldPassword, newPassword: data.newPassword, newPasswordRepeat: data.newPasswordRepeat },
    auth: true,
    transformResponse: transformUser,
  })
}
