import React, { createRef, useState, memo } from 'react'

import { useEffectOnce } from 'root/hooks'
import AlertModal from './components/AlertModal'

const setModalPropsRef = createRef()

const buildAlert = (getProps) =>
  new Promise((resolve, reject) => setModalPropsRef.current(getProps(resolve, reject))).finally(() =>
    setModalPropsRef.current(undefined),
  )

export const infoAlert = (body) => buildAlert((resolve) => ({ body, onClose: resolve }))

export const confirmAlert = (body) =>
  buildAlert((resolve, reject) => ({
    body,
    buttons: [
      { label: 'login.confirm', onClick: resolve, primary: true },
      { label: 'login.cancel', onClick: reject },
    ],
    onClose: reject,
  }))

export const dialogAlert = (text, buttonOk, buttonCancel = 'login.cancel') =>
  buildAlert((resolve, reject) => ({
    body: text,
    buttons: [
      { label: buttonOk, onClick: resolve, primary: true },
      { label: buttonCancel, onClick: reject },
    ],
    onClose: reject,
  }))

export const promptAlert = (title) => {
  const inputRef = createRef()
  return buildAlert((resolve, reject) => ({
    body: (
      <>
        <div>{title}</div>
        <input ref={inputRef} className="form-input text-brown-dim shadow shadow-inset border-gray-300 mt-2" />
      </>
    ),
    buttons: [
      { label: 'login.confirm', onClick: () => resolve(inputRef.current.value), primary: true },
      { label: 'login.cancel', onClick: reject },
    ],
    onClose: reject,
  }))
}

const AlertWrapper = () => {
  const [props, setModalProps] = useState()

  useEffectOnce(() => {
    setModalPropsRef.current = setModalProps
  })

  return props ? <AlertModal {...props} /> : null
}

export default memo(AlertWrapper)
