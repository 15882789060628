import { handleActions } from 'redux-actions'

import * as actions from '../../../products/actions'
import { getLoadListReducer } from 'root/reducers/common'
import { getProducts } from 'root/api'

export const defaultState = {
  items: [],
  page: 0,
  total: 0,
  hasNext: true,
  loadFunc: getProducts,
  filters: {},
}

const removeEmpty = (obj) =>
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== 0 && !obj[key]) delete obj[key]
  })

export default handleActions(
  {
    [actions.updateProductsFilters]: (state, { payload: filters }) => {
      filters = { ...state.filters, ...filters }
      removeEmpty(filters)
      return { ...state, filters }
    },
    [actions.resetProductsFilters]: (state) => {
      const { s, q } = state.filters
      const filters = { s, q }
      removeEmpty(filters)
      return { ...state, filters }
    },
    [actions.loadProductsListStart]: (state, { payload: { refresh } }) =>
      refresh ? { ...state, page: 0, items: [] } : state,
    [actions.loadProductsListSuccess]: getLoadListReducer(),
    //[actions.loadOrderSuccess]: getPropagateUpdateReducer(),
  },
  defaultState,
)
