import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import OrderTimer from 'root/components/OrderTimer'
import {
  DEAL_TYPE_MEMO,
  SECTION_SALES,
  STAGE_REQUEST,
  STATE_CANCELED,
  STATE_REJECTED,
} from 'root/modules/orders/constants'
import { formatNumberToPrice, getPriceFormatter, toClassName } from 'root/constants'
import i18n from 'root/i18n'
import BlueButton from 'root/components/Buttons/BlueButton'

const OrderListRow = ({ item: order, section, withCounterPart = true }) => {
  const history = useHistory()

  const onClick = () => history.push(`/${section}/${order.id}`)

  const formatter = getPriceFormatter(order.total[0].currency)

  return (
    <div
      onClick={onClick}
      className={toClassName([
        'cursor-pointer h-max-content pl-4 pt-1 pb-1 flex items-center justify-between text-gray-500 container mx-auto lg:w-screen-lg-min hover:bg-gray-100',
        order.notifications > 0 ? 'font-bold' : '',
      ])}
    >
      <div className="w-16 text-center text-brown-dim whitespace-no-wrap">
        <span>{order.date}</span>
      </div>
      <div className="w-32 text-center text-blue-skyblue whitespace-no-wrap">#{order.number}</div>
      <div className="w-32 text-center text-brown-dim">
        {withCounterPart ||
        (order.stage !== STAGE_REQUEST && ![STATE_CANCELED, STATE_REJECTED].includes(order.state.key)) ? (
          <span>{section === SECTION_SALES ? order.buyerCompanyId.name : order.sellerCompanyId.name}</span>
        ) : null}
      </div>
      <div className="w-32 text-center text-blue-dark">
        <span>{order.state.name === 'Completed' ? i18n.value('orders.status.executed') : order.state.name}</span>
      </div>
      <div className="w-32 text-center text-blue-dark">
        <span>{formatNumberToPrice(order.total[0].value, formatter)}</span>
      </div>
      {order.dealType !== DEAL_TYPE_MEMO && (
        <div className="w-24 text-center text-brown-dim">
          <OrderTimer timer={order.timer} />
        </div>
      )}
      <div className="w-24 text-center text-blue-dark">
        <BlueButton slim type="button">
          {i18n.value('order.goToDeal')}
        </BlueButton>
      </div>
    </div>
  )
}

OrderListRow.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  withCounterPart: PropTypes.bool,
}

export default memo(OrderListRow)
