export const ENV = 'prod' //dev | prod | local

const PROTOCOL = 'https' //https for prod/dev
const DOMAINS = {
  local: 'lgdeal.local',
  dev: 'dev.lgdeal.com',
  prod: 'lgdeal.com',
}

export const BASE_DOMAIN = DOMAINS[ENV]

export const WS_URL = `wss://${BASE_DOMAIN}/socket`
export const SITE_URL = `${PROTOCOL}://${BASE_DOMAIN}`
export const APP_URL = `${PROTOCOL}://app.${BASE_DOMAIN}`
export const AUTH_API_URL = `${PROTOCOL}://auth.${BASE_DOMAIN}`
export const CORE_API_URL = `${PROTOCOL}://core-api.${BASE_DOMAIN}`
export const BACKOFFICE_API_URL = `${PROTOCOL}://backoffice-api.${BASE_DOMAIN}`
export const BOTD_PUBLIC_KEY = false // 'Ubqvq344176ah1NzbDSnWjyj'

export const STRIPE_KEY = 'pk_live_51MYpATIJlK8q1VToOVW2XeScpKLPhPrbR6tpk65SWcJfBOHnUj8JLYsMtaU1oqKCzHWzc40UmO7pX07tR2hxinNb00YOQh31kW'

