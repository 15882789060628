import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M26.5365 18.7836V3.91406H5.46338V18.7836"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9756 1.56581V0.783203H7.02441V1.56581"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1462 14.8711H21.8536"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1462 11.7402H21.8536"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1462 8.60938H17.9511"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.2195 35.2178H0.780518V21.1309H10.1464L11.7073 23.4787H20.2927L21.9834 21.1309H31.2195V35.2178Z"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.12198 17.2188L0.780518 20.3492V21.1318"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8782 17.2188L31.2196 20.3492V21.1318"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5122 27.3926H12.4878V31.3056H19.5122V27.3926Z"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
