import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import i18n from 'root/i18n'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import CheckBoxFormik from 'root/modules/login/pages/Login/components/CheckboxInput/formik'
import CheckboxInput from 'root/modules/login/pages/Login/components/CheckboxInput'

const InvoiceItems = ({
  isSender,
  order,
  action,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  values,
  valuesKey,
  readOnly,
}) => {
  const [isSelectAllChecked, setSelectAll] = useState(false)
  let buttons = []

  if (isSender && !readOnly) {
    buttons = [
      {
        color: 'brown',
        label: 'orders.buttons.issueInvoice',
        action: action,
      },
    ]
  }
  const itemsToInvoice = order.metaActions[action]
  let rows = []
  for (let i = 0; i < itemsToInvoice.length; i++) {
    const itemId = itemsToInvoice[i]
    let itemNameField = ''
    for (let i = 0; i < order.products.length; i++) {
      const product = order.products[i]
      if (product.id !== itemId) {
        continue
      }
      itemNameField = isSender ? (
        <div className="mx-auto cursor-pointer">
          <CheckBoxFormik
            isChecked={values[valuesKey][itemId]}
            labelAfter={product.name}
            itemKey={valuesKey + '[' + itemId + ']'}
          />
        </div>
      ) : (
        <label className="text-brown-dim">{product.name}</label>
      )
    }
    rows.push(
      <div key={itemId} className="flex">
        <div>{itemNameField}</div>
      </div>,
    )
  }

  return (
    rows.length > 0 && (
      <div className={'my-8'}>
        <ToggleableSection
          classNameLine="text-blue-skyblue"
          labelTop={'order.diamonds.notInvoiced'}
          labelInside={'common.empty'}
        >
          {rows}
          {isSender && (
            <div className="mt-8">
              <div className="my-3">
                <CheckboxInput
                  value={isSelectAllChecked}
                  labelBefore={i18n.value('common.selectAll')}
                  id={'toggleSelectAll'}
                  onChange={() => {
                    for (let i = 0; i < itemsToInvoice.length; i++) {
                      const itemId = itemsToInvoice[i]
                      setFieldValue(valuesKey + '[' + itemId + ']', !isSelectAllChecked)
                    }
                    setSelectAll(!isSelectAllChecked)
                  }}
                  className={''}
                />
              </div>
              {!readOnly ? (
                <div className="my-3">
                  <FormikSubmittingButtons
                    buttons={buttons}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                  />
                </div>
              ) : null}
            </div>
          )}
        </ToggleableSection>
      </div>
    )
  )
}

InvoiceItems.propTypes = {
  order: PropTypes.object.isRequired,
  isSender: PropTypes.bool,
  values: PropTypes.object.isRequired,
  valuesKey: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(InvoiceItems)
