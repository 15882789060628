import moment from 'moment/min/moment-with-locales'
import numeral from 'numeral'
import { ROLE_OWNER, ROLE_SELLER, ROLE_SUPERVISOR } from 'root/constants'
import {
  SHIPMENT_VENDOR_MALKA,
  STAGE_NEGOTIATION,
  STAGE_PAYMENT_AND_DELIVERY,
  STAGE_REQUEST,
} from 'root/modules/orders/constants'

const TRANSLATIONS = {
  en: {
    'profile.registrationSuccess': 'Successful Registration',
    'profile.registrationSuccessInfo1': 'Thank you! Your account is successfully registered.',
    'profile.registrationSuccessInfo2': 'We need a little more information to activate your account.',
    [`role.${ROLE_SELLER}`]: 'Manager',
    [`role.${ROLE_SUPERVISOR}`]: 'Supervisor',
    [`role.${ROLE_OWNER}`]: 'Owner',
    //common
    'common.role.seller': 'Manager',
    'common.role.supervisor': 'Supervisor',
    'common.role.owner': 'Owner',
    'common.edit': 'Edit',
    'common.editProfile': 'Edit Profile',
    'common.cancel': 'Cancel',
    'common.ok': 'Ok',
    'common.save': 'Save',
    'common.create': 'Create',
    'common.add': 'Add',
    'common.days': 'days',
    'common.archive': 'Archive',
    'common.delete': 'Delete',
    'common.activate': 'Activate',
    'common.search': 'Search',
    'common.selectAll': 'Select all',
    'common.all': 'All',
    'common.percent': '%',
    'common.date': 'Date',
    'common.seller': 'Supplier',
    'common.buyer': 'Customer',
    'common.acceptedFiles': 'Accepted files',
    'common.rejectedFiles': 'Rejected files',
    'common.dragAndDropFiles': 'Drag and drop your file here or <span class="text-brown-dim-light">browse</span>',
    'common.imageOrPDFUpload': '8MB or less PDF and images only',
    'common.yes': 'Yes',
    'common.no': 'No',
    'common.empty': ' ',
    'common.mm': 'mm',
    'common.success': 'Success!',
    //login
    'login.confirm': 'Confirm',
    'login.cancel': 'Cancel',
    'login.error': 'Error during login',
    'login.ok': 'Login success',
    'login.notReceived': 'Didn\'t receive code?\n',
    'login.resendTimer': 'It can be re-sent in ',
    'login.resendSuccess': 'The code was re-sent. If you still have problems logging in, please contact us.',
    'login.resend': 'Resend',
    'login.askForHelp': 'I can\'t get the code',
    'login.askForHelpConfirmation': 'Thank you. Our manager will contact you soon.',
    'login.toGetAccessCode': 'To get access code, \nplease, enter your ',
    'login.enterAccessCode': 'Enter access code that has been sent to your ',
    'login.phoneNumber': 'phone number',
    'login.emailAddress': 'email address',
    'login.getAccessCode': 'Get Access Code',
    'login.logIn': 'Log In',
    'login.email': 'Email',
    'login.phone': 'Phone',
    'login.hasPassword': 'I have a password',
    'login.agreement1': 'By signing in I confirm that I accept terms and\nconditions of ',
    'login.agreement2': 'user agreement',
    'login.agreement3': ' and give my consent to\nprocess my personal data on the terms and conditions\nspecified in ',
    'login.agreement4': 'privacy policy',
    // order
    'order.labels.orderStartedWithInitialTerms':
      'Your last order terms were rejected by the customer. He started the deal with initial terms and conditions.',
    'order.labels.requestKyc': 'Request KYC',
    'order.labels.downloadKyc': 'Download KYC',
    'profile.buttons.kycInfo': 'Provide KYC info',
    'profile.company.labels.viewAndEditKycInformation': 'Edit KYC Information',
    'orders.requestKycMessage': 'Please, provide KYC information in order to proceed with the deal',
    'order.card.dealTermsAgreed': 'Deal terms agreed',
    'order.card.dealClosed': 'Deal closed',
    'order.card.dealPayment': 'Payment',
    'order.card.dealInvoice': 'Invoice',
    'order.card.dealAdditionalInvoice': 'Additional Invoice',
    'order.card.invoiceStatus': 'Status',
    'order.card.dealDelivery': 'Delivery',
    'order.card.supplierConfirmation': 'Supplier confirmation',
    'order.card.customerConfirmation': 'Customer confirmation',
    'order.card.allGoodsShipped': 'All goods shipped',
    'order.card.invoiceIssued': 'Invoice issued',
    'order.card.viewInvoice': 'View invoice',
    'order.card.allGoodsDelivered': 'All goods delivered',
    'order.card.waitingForSeller': 'Waiting for supplier',
    'order.card.waitingForBuyer': 'Waiting for customer',
    'order.card.invoiceConfirmed': 'Confirmed',
    'order.card.invoiceCanceled': 'Rejected',
    'order.card.waitingPayment': 'Waiting for payment',
    'order.card.paymentReceived': 'Money received',
    'order.card.paymentReceivedConfirm': 'Do you confirm that payment is received?',
    'order.card.cancelInvoiceConfirm': 'Do you want a new invoice file to be issued?',
    'order.card.confirmInvoiceConfirm': 'Are you sure invoice file is correct?',
    'order.card.confirmComplete': 'Are you sure you want to complete the deal?',
    'order.card.download': 'Download standard LGDeal invoice',
    'order.card.upload': 'Upload the invoice',
    'order.card.makeShipment': 'Make a shipment',
    'order.card.deliveryStatus': 'Check delivery status',
    'order.card.viewDeliveries': 'View deliveries',
    'order.labels.subTotal': 'Subtotal',
    'order.labels.total': 'Total',
    'order.labels.deliveries': 'Delivery status',
    'orders.labels.canceled.canceled': 'The deal was canceled by the customer',
    'orders.labels.canceled.rejected': 'The deal was rejected by the seller',
    'order.labels.requestChangesViaChat': 'If you disagree with the current deal terms - ',
    'order.labels.requestChangesViaChatButton': 'request changes via chat',
    'order.labels.thisIsFinalTerms': 'This is final terms from the supplier, no further changes are allowed.',
    'order.labels.confirmInitialTerms': 'Do you confirm that you agree with the Initial Deal Terms?',
    'orders.labels.canceled.reason': 'Reason',
    'orders.labels.waitCustomerConfirmMemo': 'Please wait for the NEW MEMO Negotiation to get started.',
    'orders.labels.waitCustomerConfirmMemoSelection': 'Please wait for the Customer to confitm the MEMO selection.',
    'order.labels.newMemoInitiated':
      'The New MEMO Agreement Negotiation has been initiated.\nPlease confirm that you are willing to proceed.',
    'order.labels.existingMemoInitiated':
      'The existing MEMO Agreement Negotiation has been initiated.\nPlease confirm that you are willing to proceed.',
    'order.labels.confirmRemoveProduct': 'Remove this diamond from the list?',
    'order.labels.provideReasonForCancel': 'Why do you want to cancel the Deal?',
    'order.labels.doYouConfirmTermsCorrect': 'Are you sure you want to start the Deal?',
    'order.labels.doYouConfirmRestartDeal': 'Are you sure you want to restart negotiation?',
    'order.labels.doYouConfirmCurrentTerms': 'Do you confirm that you agree with the Current Deal Terms?',
    'order.labels.doYouConfirmExtractStones': 'Are you sure you want to copy stones from this order to your stock?',
    'order.labels.statusWaitingForSellerToConfirm':
      'Waiting for the diamonds parameters to be confirmed by the supplier',
    'orders.buttons.extractOrderStones': 'Copy stones to my stock',
    'order.labels.extractStonesOverprice': 'Please, provide extra charge in percent if needed',
    'order.labels.statusParametersChanged': 'Parameters have been changed',
    'order.labels.type': 'Deal type',
    'order.labels.number': 'Deal #',
    'order.labels.diamonds': 'Diamonds',
    'order.labels.generalTerms': 'General Terms',
    'order.labels.validFor': 'Valid for',
    'order.labels.days': 'days',
    'order.labels.deliveryAddress': 'Delivery Address',
    'order.labels.paymentTerms': 'Payment Terms',
    'order.labels.returnTerms': 'Return Terms',
    'order.labels.dealRequestStatus': 'Deal Request Status',
    'order.labels.dealStatus': 'Deal Status',
    'order.labels.readonlyNotice': 'You can not edit this order. Please, contact responsible manager',
    'order.diamonds.number': '#',
    'order.diamonds.shape': 'Shape',
    'order.diamonds.name': 'Diamond name',
    'profile.company.labels.isSeller': 'I am seller',
    'order.diamonds.certificate': 'Cert #',
    'order.diamonds.referencePrice': 'Ref Price',
    'order.diamonds.price': 'Price',
    'order.diamonds.delivery': 'Delivery',
    'order.diamonds.deliveryDaysFrom': 'Delivery days from',
    'order.diamonds.deliveryDaysTo': 'Delivery days to',
    'order.diamonds.location': 'Location',
    'order.diamonds.rapaport': 'R-',
    'order.diamonds.edit': 'Edit',
    'order.diamonds.remove': 'Remove',
    'order.diamonds.status': 'Status',
    'order.diamonds.timer': 'Timer',
    'order.diamonds.timer.nonReturn': 'Non-return period',
    'order.diamonds.timer.memoPeriod': 'MEMO period',
    'order.diamonds.notShipped': 'To be shipped',
    'order.diamonds.notInvoiced': 'Not invoiced',
    'order.diamonds.shippedToBuyer': 'Shipped',
    'order.diamonds.deliveredToBuyer': 'Delivered',
    'order.diamonds.inCustomerStock': 'Customer stock',
    'order.diamonds.returnRequest': 'Return request',
    'order.diamonds.returned': 'Returned',
    'order.diamonds.inPayment': 'Payment',
    'order.diamonds.paid': 'Paid',
    'order.diamonds.toBeReturned': 'To be returned',
    'order.diamonds.shippedToSeller': 'Return shipped',
    [`order.icon.stage.${STAGE_REQUEST}`]: 'request',
    [`order.icon.stage.${STAGE_NEGOTIATION}`]: 'negotiation',
    [`order.icon.stage.${STAGE_PAYMENT_AND_DELIVERY}`]: 'payment&delivery',
    'order.goToDeal': 'Go to deal',
    view_stones_from_supplier: 'View other diamonds from the supplier',
    send_stones_from_supplier: 'Send my stock list to customer',
    'order.view_my_stock': 'You can view my stock here: ',
    'order.view_my_stock_success': 'Customer will receive link to your stock in Chat.',
    //orders
    'orders.type.invoice': 'Invoice',
    'orders.type.memo': 'MEMO Agreement',
    'orders.type.label.invoice': 'Invoice Orders',
    'orders.type.label.memo': 'MEMO Orders',
    'orders.status.': 'All',
    'orders.status.waiting': 'Waiting',
    'orders.status.requests': 'Requests',
    'orders.status.negotiation': 'Negotiation',
    'orders.status.payment_and_delivery': 'Payment and delivery',
    'orders.status.canceled': 'Canceled',
    'orders.status.executed': 'Completed',
    'orders.list.tableHead.date': 'Date',
    'orders.list.tableHead.number': 'Number',
    'orders.list.tableHead.company.sales': 'Customer',
    'orders.list.tableHead.company.purchases': 'Supplier',
    'orders.list.tableHead.company.contractor': 'Supplier',
    'orders.list.tableHead.status': 'Status',
    'orders.list.tableHead.totalUsd': 'Total USD',
    'orders.list.tableHead.timer': 'Timer',
    'orders.list.tableHead.manager': 'Manager',
    'orders.memo.buttons.cancelMemoRequest': 'Cancel MEMO request',
    'orders.memo.buttons.requestReturn': 'Request Return',
    'orders.memo.buttons.requestPayment': 'Request Payment',
    'orders.memo.buttons.requestInvoice': 'Request Invoice',
    'orders.memo.buttons.return': 'Return',
    'orders.buttons.viewDealTerms': 'View deal terms',
    'orders.buttons.createShipment': 'Create a shipment',
    'orders.buttons.paymentReceived': 'Payment received',
    'orders.buttons.confirmDelivery': 'Confirm delivery',
    'orders.buttons.selectMemo': 'Select a Memo',
    'memoList.date': 'Date',
    'memoList.name': 'Name',
    'memoList.customer': 'Customer',
    'memoList.choose': 'Select',
    //labels
    'order.labels.areYouSureToStartNewMemo': 'Are you sure you want to start new MEMO?',
    'order.labels.addDiamondsHint': 'Please write a Certificate Number of the diamond you want to add',
    'order.labels.addDiamondsNotFound': 'Certificate number was not found in your stock',
    'order.labels.addDiamondsHeader': 'Add diamonds to the deal',
    'order.labels.initialDealTermsHeader': 'Initial Deal Terms',
    'order.labels.DealTermsHeader': 'Deal Terms',
    'order.labels.address.companyName': 'Company name',
    'order.labels.address.countryId': 'Country',
    'order.labels.address.addressLine1': 'Address Line 1',
    'order.labels.address.addressLine2': 'Address Line 2',
    'order.labels.address.cityName': 'City',
    'order.labels.address.regionName': 'State/Province/Region',
    'order.labels.address.zipCode': 'Zip Code',
    'order.labels.address.recipientName': 'Recipient full name',
    'order.card.viewAddress': 'View delivery address',
    'order.labels.address.recipientPhone': 'Recipient phone number',
    'profile.company.labels.connectStripeStarted':
      'Stripe activation is in progress, it may take some time until Stripe will verify your account.\n' +
      'Please, wait until activation is succeeded. \n' +
      'Meanwhile you can follow "Connect Stripe Account" link to review your Stripe account.',
    'orders.labels.chooseMemo': 'Please choose the MEMO agreement for this deal',
    'orders.labels.newMemoNotice':
      'If you want to upload your own MEMO agreement, please set the terms (of your document) above and click\n' +
      ' "Save & Offer MEMO Terms" button. You will be able to upload the document after customer\'s confirmation.',
    'orders.labels.viewMemoDocs': 'View MEMO documents',
    'orders.labels.orderFilesList': 'Uploaded files',
    'orders.labels.viewOrderFiles': 'View order files',
    'order.card.uploadOrderFile': 'Upload files',
    'orders.labels.dealNegotiation': 'Deal negotiation',
    'orders.labels.memoPaymentAndDelivery': 'MEMO Payment & Delivery',
    'orders.labels.memoCompleted': 'MEMO Completed',
    'order.no-diamonds-yet': 'There is no diamonds here yet',
    'orders.labels.viewMemoDealTerms': 'View MEMO deal terms',
    'orders.labels.dealTerms': 'Deal terms',
    'orders.labels.specialTerms': 'Special terms',
    'orders.labels.number': 'Number #',
    'orders.labels.dollarSign': '$',
    'orders.labels.paymentBeforeDelivery': 'Before Delivery',
    'orders.labels.paymentAfterDelivery': 'After Delivery',
    'orders.labels.memoConsignmentAgreement': 'MEMO Consignment Agreement',
    'orders.labels.lgDealMemoTemplate': 'LGDeal Standard MEMO Template',
    'orders.labels.lgDealMemoSupplementaryTemplate': 'LGDeal Supplementary Agreement Template',
    'orders.labels.lgDealInvoiceTemplate': 'LGDeal Invoice Template',
    'orders.labels.memoTerms': 'Memo Terms',
    'orders.labels.memoTerm': 'MEMO Term',
    'orders.labels.deliveryPrice': 'Delivery',
    'orders.labels.averageR': 'Average R-',
    'orders.labels.memoAgreementTerms': 'Memo Agreement Terms',
    'orders.labels.generalTerms': 'General Terms',
    'orders.labels.specialTermsMemo': 'MEMO Agreement Special Terms',
    'orders.labels.newMemoAgreement': 'New MEMO Consignment Agreement',
    'orders.labels.taxResponsible': 'VAT and taxes responsible',
    'orders.labels.bankResponsible': 'Bank commission responsible',
    'orders.labels.hasAdditionalInvoice': 'Can have additional invoice',
    'orders.labels.returnPeriod': 'Return period',
    'orders.labels.sitSpecialTerms': 'Special terms',
    'orders.labels.moneyReturnAfterGoodsReturnPeriod': 'Time limit for money return after goods return',
    'orders.labels.returnAvailability': 'Return Availability',
    'orders.labels.waitForCustomerToConfirmChanges':
      'Please wait for the customer to confirm changes and to start the deal',
    'orders.labels.waitForCustomerToConfirmFinalTerms': 'Please wait for the customer to accept the Initial Deal Terms',
    'orders.labels.replyTimerCustomer': 'Customer Reply Timer',
    'orders.labels.confirmDiamondsAreCorrect':
      'By clicking "OK" you confirm that all the diamonds\' parameters are correct',
    'orders.labels.saveSuccess': 'Order parameters saved successfully',
    'orders.labels.saveMemoSuccess': 'Memo saved & offered successfully',
    'orders.labels.deliveryPaymentResponsible': 'Delivery payments responsible',
    'orders.labels.returnPaymentResponsible': 'Return payments responsible',
    'orders.labels.memoPaymentTermDays': 'MEMO payment term (after issued invoice)',
    'orders.labels.nonReturnPeriod': 'Non-return period (after delivery)',
    'orders.labels.returnShipmentPeriod': 'Return shipment period (after suppliers request)',
    'orders.labels.memoPenaltyDelayPerDay': 'Penalty for delayed payments per day',
    'orders.labels.replyTimer': 'Reply timer',
    'orders.labels.daysLeft': 'days left',
    'orders.labels.waitCreateMemoBySeller':
      'Please wait for the' +
      '&nbsp;<span class=\'text-blue-skyblue\'>MEMO Consignment Agreement Negotiation</span>' +
      '&nbsp;to get started',
    'orders.labels.waitMemoNegotiationStarted':
      'Please wait for the' +
      '&nbsp;<span class=\'text-blue-skyblue\'>New Memo Supplementary Agreement Negotiation</span>' +
      '&nbsp;to get started',
    'orders.labels.waitSupplierUploadDoc': 'Wait for Supplier to upload the signed agreement.',
    'orders.labels.waitCustomerApproveDoc': 'Wait for Customer to confirm the uploaded agreement.',
    'orders.labels.supplierReplyTimer': 'Supplier reply timer',
    'orders.labels.customerReplyTimer': 'Customer reply timer',
    'orders.labels.memoFilesList': 'Uploaded documents for MEMO',
    'orders.labels.memoSupplementaryFilesList': 'Uploaded Supplementary Agreements',
    'orders.labels.finalOfferNotice':
      '<b>Attention!</b><br />The final offer is sent and no further negotiation is possible. After the timer is expired supplier can return the deal into invoice type. Customer may accept final offer terms or get back to invoice flow',
    'orders.labels.supplierUploadNewMemo': 'Please upload a MEMO document that is signed by both sides',
    'orders.labels.supplierUploadNewSupplementary':
      'Please upload a supplementary agreement document that is signed by both sides.<br /> You can choose either LGDeal standard template or your own document.',
    'orders.labels.supplierUploadNewInvoice':
      'Please upload a signed invoice document. You can choose either LGDeal standard template or your own document.',
    //memo payment and delivery
    'orders.labels.memoPaymentAndDeliveryTab.all': 'All',
    'orders.labels.memoPaymentAndDeliveryTab.delivery': 'Delivery',
    'orders.labels.memoPaymentAndDeliveryTab.stock': 'Customer Stock',
    'orders.labels.memoPaymentAndDeliveryTab.myStock': 'My Stock',
    'orders.labels.memoPaymentAndDeliveryTab.returnRequest': 'Return Request',
    'orders.labels.memoPaymentAndDeliveryTab.payment': 'Payment',
    'orders.labels.memoPaymentAndDeliveryTab.return': 'Return',
    'orders.labels.trackingNumber': 'Tracking number',
    'orders.labels.shipment': 'Shipment',
    'orders.labels.invoice': 'Invoice',
    //shipment providers
    'orders.labels.shipmentProvider.fedex': 'FEDEX',
    'orders.labels.shipmentProvider.ups': 'UPS',
    'orders.labels.shipmentProvider.brinks': 'BRINKS',
    ['orders.labels.shipmentProvider.' + SHIPMENT_VENDOR_MALKA]: 'MALKA',
    'orders.labels.shipmentProvider.other': 'OTHER',
    //stages
    'orders.stage.requests': 'Deal Request',
    'orders.stage.negotiation': 'Deal Negotiation',
    'orders.stage.payment_and_delivery': 'Payment & Delivery',
    'orders.stage.executed': 'Completed',
    'orders.stage.canceled': 'Canceled',
    'orders.stage.requests.subheader.buyer':
      'Please, wait till the supplier confirms the availability of the stone(-s) within 24 hours.',
    'orders.stage.requests.subheader.seller':
      'You have a diamond request. Please, confirm the availability and the stone parameters or decline the deal.',
    'orders.stage.negotiation.subheader.buyer':
      'At this stage you may see the deal terms and you may negotiate the price with the supplier via chat. Press the button "Start with current terms" if you agree with the terms.',
    'orders.stage.negotiation.subheader.seller':
      'At this step you may negotiate the terms of the deal via chat. Only you can change the deal terms. Once you edited, press the "Save and Make an offer" button. After the customer agrees to the current conditions, you will proceed to Payment&Delivery stage.',
    'orders.stage.executed.subheader.buyer': 'Thank you for choosing our service! Get the best deals with LGDeal!',
    'orders.stage.executed.subheader.seller': 'Thank you for choosing our service! Let\'s make more deals with us!',
    'orders.stage.canceled.subheader.buyer': 'Thank you for choosing our service! Get the best deals with LGDeal!',
    'orders.stage.canceled.subheader.seller': 'Thank you for choosing our service! Lets make more deals with us',
    'orders.stage.payment_and_delivery.invoice_upload.seller': 'Please, upload your invoice or use LGDeal template.',
    'orders.stage.payment_and_delivery.invoice_upload.buyer': 'Please, wait until the supplier sends you the invoice.',
    'orders.stage.payment_and_delivery.invoice_confirm.seller': 'Please, wait for the buyer to approve the invoice.',
    'orders.stage.payment_and_delivery.invoice_confirm.buyer':
      'Please, check the received invoice and confirm it. Or leave your comment via chat.',
    'orders.stage.payment_and_delivery.payment.seller':
      'Please, wait till you get the payment and then press the button "Money received".',
    'orders.stage.payment_and_delivery.payment.buyer':
      'Please, make the payment according to the invoice and wait for supplier to confirm the money transfer.',
    'orders.stage.payment_and_delivery.shipping.seller':
      'Please, fill in the delivery form. Make sure you have put the correct tracking number.',
    'orders.stage.payment_and_delivery.shipping.buyer':
      'At this stage you can track the status of the shipment by clicking "Delivery status". After receiving the diamonds, please, press "Confirm delivery".',
    'orders.stage.payment_and_delivery.shipped.seller': 'Please, wait until the buyer receives the shipment',
    'orders.stage.payment_and_delivery.shipped.buyer':
      'At this stage you can track the status of the shipment by clicking "Delivery status". After receiving the diamonds, please, press "Confirm delivery".',
    'orders.stage.payment_and_delivery.additional_invoice_upload.seller':
      'At this step you may upload your additional invoice or use LGDeal template. Otherwise, press the button "Complete the Deal".',
    'orders.stage.payment_and_delivery.additional_invoice_upload.buyer':
      'Please, wait until the supplier sends you the additional invoice.',
    'orders.stage.payment_and_delivery.additional_invoice_confirm.seller':
      'Please, wait for the buyer to approve the additional invoice.',
    'orders.stage.payment_and_delivery.additional_invoice_confirm.buyer':
      'Please, check the received additional invoice and confirm it. Or leave your comment via chat.',
    //buttons
    'orders.buttons.addProduct': 'Add',
    'orders.buttons.issueInvoice': 'Issue an invoice',
    'orders.buttons.cancelInvoice': 'Reject Invoice',
    'orders.buttons.confirmInvoice': 'Confirm Invoice',
    'orders.buttons.complete': 'Complete the Deal',
    'orders.buttons.showInitialTerms': 'View Initial Terms',
    'orders.buttons.startWithInitialTerms': 'Start with Initial Terms',
    'orders.buttons.saveAndMakeOffer': 'Save & Make new Offer',
    'orders.buttons.saveAndOfferMemo': 'Save & Offer MEMO terms',
    'orders.buttons.offerMemo': 'Offer MEMO terms',
    'orders.buttons.confirmAndSignMemo': 'Confirm and sign the MEMO',
    'orders.buttons.confirmAndSign': 'Confirm and sign',
    'orders.buttons.cancelMemoRequest': 'Cancel MEMO request',
    'orders.buttons.confirmUpload': 'Confirm document upload',
    'orders.buttons.confirmUploadMemo': 'Upload MEMO Consignment Agreement',
    'orders.buttons.confirmUploadMemoSupplementary': 'Upload MEMO Supplementary Agreement',
    'orders.buttons.confirmUploadInvoice': 'Upload Invoice',
    'orders.buttons.confirm': 'Confirm',
    'orders.buttons.reject': 'Reject',
    'orders.buttons.finalOffer': 'Make final offer',
    'orders.buttons.backToInvoice': 'Return to Invoice deal',
    'orders.buttons.cancelDeal': 'Cancel the Deal',
    'orders.buttons.restartDeal': 'Restart negotiation',
    'orders.buttons.confirmChanges': 'Confirm changes',
    'orders.buttons.confirmCurrentTerms': 'Start with Current Terms',
    'orders.buttons.requestChanges': 'Request Changes',
    'orders.buttons.startTheDeal': 'Start the Deal',
    'orders.buttons.newMemo': 'New Memo',
    'orders.buttons.requestMemo': 'Request Memo',
    'orders.requestMemoMessage': 'I want to start a Memo. Please, contact me.',
    'orders.requestMemoSuccess': 'Memo request was sent to supplier. Please, wait and check his answer in Chat',
    'orders.buttons.requestMelee': 'Request Melee',
    'orders.buttons.requestNotCert': 'Request Non-Certified Diamonds',
    'orders.requestMeleeMessage': 'I want you to add melee to order. Please, contact me for details.',
    'orders.requestMeleeSuccess': 'Melee request was sent to supplier. Please, contact the supplier in Chat',
    'orders.requestNotCertMessage':
      'I want you to add Non-Certified Diamonds to order. Please, contact me for details.',
    'orders.requestNotCertSuccess':
      'Not-Certified Diamonds request was sent to supplier. Please, contact the supplier in Chat',
    'order.labels.additionalItems': 'Additional Goods',
    'order.diamonds.goodsName': 'Name',
    'orders.buttons.requestRelated': 'Request Related Goods',
    'orders.requestRelatedMessage': 'I want you to add related goods to order. Please, contact me for details.',
    'orders.requestRelatedSuccess': 'Related goods request was sent to supplier. Please, contact the supplier in Chat',

    'order.labels.payWithStripe': 'Pay with Stripe',
    'order.card.paidWithStripe': 'Paid with Stripe',

    //files
    'files.list.tableHead.created': 'Created',
    'files.list.tableHead.name': 'File name',

    //chat
    'chat.newMessages': 'Unread messages',
    'chat.listTitle': 'Chats',
    'chat.you': 'You',
    'chat.addManagersTitle': 'Add managers to chat',
    'chat.addManagersPlaceholder': 'Select managers',
    'chat.continue': 'CONTINUE',
    'chat.added': 'Added',
    'chat.removed': 'Removed',
    'chat.joined': 'Joined this chat',
    'chat.empty': 'If you have any questions on your order, you can contact us here. We’ll be happy to assist you',
    'chat.photo': 'Photo',
    'chat.placeholder': 'Send message...',
    'chat.with': 'Chat with ',

    //menu
    'menu.login': 'Login',
    'menu.logout': 'Logout',
    'menu.stock': 'My stock',
    'menu.sales': 'Sales',
    'menu.purchases': 'Purchases',
    'menu.memoAgreements': 'Memo agreements',
    'menu.activation': 'Profile Activation',
    'menu.howItWorks': 'How it works',
    'menu.catalog': 'Catalog',
    'menu.education': 'Education',
    'menu.about': 'About Us',
    'menu.company': 'Company profile',
    'menu.userProfile': 'My profile',
    'menu.password': 'Password',

    //import
    'import.title': 'Bulk Upload',
    'import.chooseFile': 'Choose file',

    //products
    'products.labels.creditInfoRequired': 'You have to provide Company Information before editing stock',
    'orders.labels.creditInfoRequired': 'You have to provide Company Information before starting Deals',
    'products.labels.bulkUpload': 'Bulk upload',
    'products.labels.manualEdit': 'Manual add',
    'products.labels.downloadMyStock': 'Download my stock',
    'products.list.status.': 'All',
    'products.list.status.deal': 'Deals',
    'products.list.status.in_stock': 'Stock',
    'products.list.status.moderation': 'Moderation',
    'products.list.status.archive': 'Archive',

    'products.labels.table.header.shape': 'Shape',
    'products.labels.table.header.carat': 'Carat',
    'products.labels.table.header.color': 'Color',
    'products.labels.table.header.clarity': 'Clarity',
    'products.labels.table.header.cut': 'Cut',
    'products.labels.table.header.certificate': 'Certificate',
    'products.labels.table.header.price': 'Price',
    'products.labels.table.header.status': 'Status',
    'products.labels.table.header.cert': 'Cert.',
    'products.labels.table.header.location': 'Location',

    'products.labels.hoverTableInfo': 'Hover over a diamond to see further details',
    'products.labels.diamondInfo': 'Diamond information',
    'products.labels.noDiamonds': 'No diamonds found by your request',
    'products.labels.changeRequestOrCreate':
      'Change request parameters to search existing items or create a new diamond',
    'labels.nothingFound': 'Nothing found by your request',
    'labels.changeRequest': 'Try change request parameters. But maybe there is nothing to search for yet.',

    'products.labels.field.certificateNumber': 'Certificate number',
    'products.labels.field.certificate': 'Certificate institute',
    'products.labels.field.tableSize': 'Table size',
    'products.labels.field.totalDepth': 'Total depth',
    'products.labels.field.polish': 'Polish',
    'products.labels.field.fluorescence': 'Fluorescence',
    'products.labels.field.symmetry': 'Symmetry',
    'products.labels.field.haa': 'Hearts & Arrows',
    'products.labels.field.measurements': 'Measurements',
    'products.labels.field.technology': 'Technology',
    'products.labels.viewAndEdit': 'View & Edit',

    'product.labels.priceAndDiscount': 'Price and Discount',
    'product.labels.location': 'Location',
    'product.labels.diamondParameters': 'Diamond Parameters',
    'product.labels.mediaMaterials': 'Media Materials',
    'product.labels.status': 'Status',
    'product.labels.price': 'Price',
    'product.labels.referencePrice': 'Reference Price',
    'product.labels.referenceDiscount': 'Reference Price Discount (R-)',
    'product.labels.originCountryId': 'Country (region) of origin',
    'product.labels.zipCodeId': 'ZIP',
    'product.labels.locationCountryId': 'Country',
    'product.labels.locationRegionId': 'Region',
    'product.labels.locationCityId': 'City',
    'product.labels.shape': 'Shape',
    'product.labels.carat': 'Carat',
    'product.labels.clarity': 'Clarity',
    'product.labels.cut': 'Cut',
    'product.labels.polish': 'Polish',
    'product.labels.colorType': 'Color Type',
    'product.labels.white': 'White',
    'product.labels.fancy': 'Fancy',
    'product.labels.symmetry': 'Symmetry',
    'product.labels.measurements': 'Width x Height x Length',
    'product.labels.tableSize': 'Table Size',
    'product.labels.pavilionDepth': 'Pavilion Depth',
    'product.labels.crownHeight': 'Crown Height',
    'product.labels.girdle': 'Girdle',
    'product.labels.culet': 'Culet',
    'product.labels.totalDepth': 'Total Depth',
    'product.labels.fluorescence': 'Fluorescence',
    'product.labels.heartsAndArrows': 'Hearts & Arrows',
    'product.labels.certificate': 'Certificate Institute',
    'product.labels.certificateNumber': 'Certificate Number',
    'product.labels.grownTechnology': 'Technology',
    'product.labels.color.colorValue': 'Color',
    'product.labels.color.fancyIntensity': 'Intensity',
    'product.labels.color.fancyOvertone': 'Overtone',
    'product.labels.color.description': 'Additional Information',
    'product.labels.imageName': 'Image Name',
    'product.labels.videos': 'Product Videos',
    'product.labels.images': 'Images',
    'product.labels.mediaLinkHelp': 'You can add link to media site like view.gem360.in etc.',
    'product.labels.mediaLink': 'External Media Link',
    'product.labels.videosHelp':
      'You can attach links to video files, hosted at youtube.com or vimeo.com. Ex.: https://www.youtube.com/watch?v=XfIMV_Xxisw',
    'product.labels.addVideo': 'Add',
    'product.labels.producerName': 'NAME OF PRODUCER',
    'product.saved': 'Product saved successfully',

    //memo
    'memoAgreements.labels.table.header.date': 'Date',
    'memoAgreements.labels.table.header.number': 'Number',
    'memoAgreements.labels.table.header.seller': 'Supplier',
    'memoAgreements.labels.table.header.buyer': 'Customer',
    'memoAgreements.labels.table.header.status': 'Status',
    'memoAgreements.labels.table.header.timer': 'Timer',
    'memoAgreements.labels.table.header.manager': 'Manager',
    'memoAgreements.labels.header': 'MEMO Consignment Agreement',
    'memoAgreements.labels.deals': 'Deals',

    //profile
    'profile.buttons.creditInfo': 'Company Information',
    'profile.buttons.invoiceTerms': 'Standard Invoice Terms',
    'profile.company.labels.additionalGoods': 'Additional goods for sale',
    'profile.company.labels.saleMelee': 'Sale Melee',
    'profile.company.labels.saleNotCert': 'Sale Non-Certified Diamonds',
    'profile.company.labels.saleRelated': 'Sale Related Goods',
    'profile.company.labels.comment': 'Comment',
    'profile.company.labels.invoiceTerms': 'Standard invoice terms',
    'profile.company.labels.creditInfo': 'Company information',
    'profile.company.labels.catalogIFrameSettingsTitle': 'Catalog IFrame Settings',
    'profile.company.labels.viewAndEditCatalogIFrameSettings': 'Edit Catalog IFrame Settings',
    'profile.company.labels.phone': 'Phone',
    'profile.company.labels.email': 'Email',
    'profile.company.labels.technology': 'Technology',
    'profile.company.labels.site': 'Website',
    'profile.company.labels.country': 'Country',
    'profile.company.labels.exhibitions': 'Exhibitions',
    'profile.company.labels.fullInfo': 'Full information',
    'profile.company.labels.imageLogo': 'Avatar image',
    'profile.company.labels.name': 'Company name',
    'profile.company.labels.KYC': 'Know Your Client',
    'profile.company.labels.requireKycOnInvoice': 'Require KYC on Invoice Order',
    'profile.company.labels.requireKycOnMemo': 'Require KYC on Memo Order',
    'profile.company.labels.allowKYC': 'Allow KYC',
    'profile.company.labels.downloadKycTemplate': 'Download KYC Template',
    'profile.company.labels.changeAvatarHint': 'Click on image to change avatar',
    'profile.company.labels.viewAndEditAddress': 'Edit Shipping Addresses',
    'profile.company.labels.viewAndEditGoods': 'Edit Additional Goods for Sale',
    'profile.company.labels.requestFtpAccess': 'Request FTP/API access',
    'profile.company.labels.requestFtpAccessSent':
      'Thank you. Our manager will contact you as soon as FTP/API access is granted',
    'profile.company.labels.viewAndEditStripeSettings': 'Edit Stripe Payment Settings',
    'profile.company.labels.viewAndEditKycSettings': 'Edit KYC Settings',
    'profile.company.labels.stripeSettingsTitle': 'Stripe Payment Settings',
    'profile.company.labels.stripeStatusTitle': 'Stripe Payment Status',
    'profile.company.labels.stripeAllowed': 'Stripe Payments are allowed for customer',
    'profile.company.labels.stripeEnabled': 'Enable Stripe Payments',
    'profile.company.labels.stripeFeeFromBuyer': 'Charge Stripe Fee from customer',
    'profile.company.labels.canAcceptStripeYes': 'Stripe payments are accepted in deals',
    'profile.company.labels.canAcceptStripeNo': 'Currently Stripe payments are not accepted in deals',
    'profile.company.labels.stripeOnboarded': 'You are connected to stripe account',
    'profile.company.labels.stripeOnboardedNo': 'You are not connected to stripe account',
    'profile.company.labels.connectStripe': 'Connect Stripe Account',
    'profile.company.labels.updateStripe': 'Update Stripe Account',
    'profile.company.labels.requestStripeAccess': 'Please, contact our manager if you want to activate Stripe Account',
    'profile.company.labels.stripeFee': 'Additional Fee for Stripe Payments',

    //credit info
    'profile.company.labels.taxId': 'TIN/VAT/EIN',
    'profile.company.labels.creditCompanyName': 'Company Legal name',
    'profile.company.labels.creditPhoneNumber': 'Company Telephone Number',
    'profile.company.labels.legalAddress': 'Legal address',
    'profile.company.labels.actualAddress': 'Actual address',
    'profile.company.labels.addresses': 'Addresses',
    'profile.company.labels.generalInfo': 'General information',
    'profile.company.labels.bankInfo': 'Bank information',
    'profile.company.labels.additionalInfo': 'Additional information',
    'profile.company.labels.vatApplied': 'Works with VAT',
    'profile.company.labels.bankName': 'Bank name',
    'profile.company.labels.bankAccountName': 'Bank account name',
    'profile.company.labels.bankAccountNumber': 'Bank account number',
    'profile.company.labels.bankRoutingNumber': 'ABA routing number (or analogue)',
    'profile.company.labels.bankSwiftCode': 'SWIFT code',
    'profile.company.labels.bankAddress': 'Bank address',
    'profile.company.labels.corrBankName': 'Correspondent bank',
    'profile.company.labels.corrBankAccountNumber': 'Correspondent bank account number',
    'profile.company.labels.corrBankSwift': 'Correspondent bank SWIFT code',
    'profile.company.labels.corrBankAddress': 'Correspondent bank address',
    'profile.company.labels.supervisorName': 'Supervisor Name',
    'profile.company.labels.supervisorEmail': 'Supervisor Email',
    'profile.company.labels.supervisorTitle': 'Supervisor Title',
    'profile.company.labels.supervisorPhone': 'Supervisor Phone',
    'profile.company.labels.supervisorInfo': 'Supervisor information',
    'profile.company.labels.creditComment': 'Comment',
    'profile.company.labels.catalogFrameEnabled': 'Enabled',
    'profile.company.labels.catalogFrameEmail': 'Email',
    'profile.company.labels.catalogFrameDomain': 'Domain',
    'profile.company.labels.catalogFrameShowPrices': 'Show prices',
    'profile.company.labels.catalogFrameShowCertificate': 'Show certificates',
    'profile.company.labels.catalogFramePriceExtra': 'Extra price (%)',
    'profile.company.labels.catalogFrameAllowOrders': 'Allow Orders',
    'profile.company.labels.catalogFrameAutoOrder': 'Auto LGDeal Order',

    //user
    'profile.user.labels.changePassword': 'Change Your Password',
    'profile.user.labels.oldPassword': 'Old password',
    'profile.user.labels.newPassword': 'New password',
    'profile.user.labels.newPasswordRepeat': 'Confirm new password',
    'profile.user.labels.passwordHint': 'At least 8 characters, but longer is better',
    'profile.user.labels.password': 'Password',
    'profile.user.labels.passwordSaved': 'Password saved',
    'profile.user.labels.noAccessToChangePassword': `You do not have permissions to change the password. Please, contact your supervisor.`,
    no_access: 'You do not have permissions to view this page. Please, contact your supervisor.',

    //team
    'profile.company.team.labels.firstName': 'First name',
    'profile.company.team.labels.lastName': 'Last name',
    'profile.company.team.labels.phone': 'Phone',
    'profile.company.team.labels.email': 'Email',
    'profile.company.team.labels.position': 'Position',
    'profile.company.team.labels.role': 'Role',
    'profile.company.team.labels.password': 'Password',
    'profile.company.team.buttons.addManager': 'Add a new manager',
    'profile.company.team.buttons.inviteUser': 'Create User',
    'profile.company.team.label.userPermissions': 'User permissions',
    'profile.company.team.labels.rights.owner': 'Owner',
    'profile.company.team.labels.rightsDescription.owner': 'Full access',
    'profile.company.team.labels.rights.seniorManager': 'Supervisor',
    'profile.company.team.labels.rightsDescription.seniorManager': 'Extended access',
    'profile.company.team.labels.rights.salesManager': 'Manager',
    'profile.company.team.labels.rightsDescription.salesManager': 'Own deals only',
    'profile.company.invoiceTerms.specialTermsDefault':
      'Advertising: THE PRODUCT SOLD IS “LAB CREATED DIAMOND”. You shall appropriately disclose the product’s man-made nature during resale. Under no circumstance shall supplier be liable for any loss, penalty, or harm in connection with deceptive advertising.',

    //team
    'profile.address.title': 'Company address',
    'profile.addresses.title': 'Shipping addresses',
    'profile.addresses.empty': 'No addresses yet',
    'profile.addresses.delete': 'Are you sure you want to delete this address?',

    'activation.labels.step': 'Step',
    'activation.labels.tellUsAboutYou': 'Tell us about yourself',
    'activation.labels.yourName': 'Your name',
    'activation.labels.yourEmail': 'Your email',
    'activation.labels.yourPhone': 'Your phone',
    'activation.labels.sendCode': 'Send Code',
    'activation.labels.verify': 'Verify',
    'activation.labels.codeIsSent': 'Please, input the code you\'ve received',
    'activation.labels.helpWithLoginText': 'Contact us if you can not get a code',
    'activation.labels.next': 'Next',
    'activation.labels.changeAccount': 'Change account',
    'activation.labels.companyName': 'Company Name',
    'activation.labels.tin': 'TIN / VAT / EIN',
    'activation.labels.country': 'Country',
    'activation.labels.Iam': 'I am a',
    'activation.labels.back': 'Back',
    'activation.labels.finish': 'Finish',

    'profile.company.labels.foundationYear': 'Year of foundation',
    'profile.company.labels.entityType': 'Entity Type',
    'profile.company.labels.businessType': 'Business Type',
    'profile.company.labels.isAml': 'Is your company AML(Anti-Money Laundering) compliant pursuant to the USA Patriot Act?',
    'profile.company.labels.references': 'Name and trade references',
    'profile.company.labels.creditEmail': 'Company Email',
    'orders.labels.kycInfoRequired': 'KYC information required',
    'profile.company.labels.kycInfo': 'KYC Information',
    'orders.subheader.new_memo_request.seller': 'Please wait until customer confirms new MEMO',
    'orders.subheader.new_memo_request.buyer':
      'The New MEMO Agreement Negotiation has been initiated. Please confirm that you are willing to proceed with MEMO or reject back to INVOICE.',
  },
}

numeral.localeData().delimiters.thousands = ' '

const DEFAULT_LANG = 'en'
export const DEVICE_LANG = DEFAULT_LANG

moment.locale(DEVICE_LANG)

const PARAMS_PATTERN = new RegExp('{(.*?)}', 'gi')

export default {
  value: (key, params = null, def = undefined) => {
    const message = TRANSLATIONS[DEVICE_LANG][key] || def

    if (message === undefined) console.error(`Missing translation: ${key}`)
    else if (message && params && PARAMS_PATTERN.test(message))
      return message.replace(PARAMS_PATTERN,
        (match, key) => params?.[key] || match)
    return message
  },
  update: (translations) => Object.assign(TRANSLATIONS[DEVICE_LANG],
    translations),
}
