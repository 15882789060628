import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="16" height="18" viewBox="0 0 16 18" className="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9666 17.5537H1.53332C0.962632 17.5537 0.5 17.0772 0.5 16.4894V5.84655V4.78224L13.9332 0.525084C14.1755 0.458525 14.3746 0.408652 14.5791 0.491804C14.8446 0.599743 14.9666 0.763204 14.9666 1.05711V4.78224C15.5374 4.78224 16 5.25874 16 5.84655V16.4894C16 17.0772 15.5374 17.5537 14.9666 17.5537ZM13.9332 1.58942L3.8583 4.78224H13.9332V1.58942ZM14.9666 11.7C14.9666 11.4063 14.9666 11.567 14.9666 11.1679H12.8999C12.8999 11.4061 12.8999 11.4063 12.8999 11.7C12.8999 11.994 12.8999 11.8882 12.8999 12.2322H14.9666C14.9666 11.8663 14.9666 11.9938 14.9666 11.7ZM1.53332 5.84642L14.45 5.84655C14.7353 5.84655 14.9666 6.08467 14.9666 6.37858V10.1037H12.3832C12.098 10.1037 11.8666 10.342 11.8666 10.6357V12.7644C11.8666 13.0583 12.098 13.2965 12.3832 13.2965H14.9666V15.9572C14.9666 16.2511 14.7353 16.4894 14.45 16.4894H2.04999C1.76458 16.4894 1.53332 16.2511 1.53332 15.9572C1.53332 15.9572 1.53332 6.21229 1.53332 5.84642Z"
    />
  </svg>
)

export default memo(SvgComponent)
