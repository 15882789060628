import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="24" height="31" viewBox="0 0 24 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0837 11.2513C23.0837 13.6034 22.351 15.7844 21.1014 17.5785H21.1045L13.3352 29.7443C12.7125 30.7194 11.2885 30.7192 10.6661 29.7439L2.93377 17.6277C1.66318 15.8244 0.916992 13.625 0.916992 11.2513C0.916992 5.13015 5.87917 0.167969 12.0003 0.167969C18.1215 0.167969 23.0837 5.13015 23.0837 11.2513ZM12.0003 17.5846C15.4981 17.5846 18.3337 14.7491 18.3337 11.2513C18.3337 7.7535 15.4981 4.91797 12.0003 4.91797C8.50252 4.91797 5.66699 7.7535 5.66699 11.2513C5.66699 14.7491 8.50252 17.5846 12.0003 17.5846Z"
      fill="#AA8A9F"
    />
  </svg>
)

export default memo(SvgComponent)
