import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

const NAME_BY_ID = {
  1: 'Round',
  2: 'Princess',
  3: 'Pear',
  4: 'Marquise',
  5: 'Radiant',
  6: 'Emerald',
  7: 'Oval',
  8: 'Heart',
  9: 'Cushion',
  10: 'Asscher',
  11: 'Baguette',
  12: 'Rose',
  99: 'Other',
}

const ShapeFilter = ({ value, updateFilters }) => {
  const valueSet = useMemo(() => new Set(value), [value])
  const onChange = useCallback(
    (e) => {
      const { value: id, checked } = e.target
      if (!checked) valueSet.delete(id)
      else valueSet.add(id)

      updateFilters({ sh: valueSet.size ? Array.from(valueSet) : undefined })
    },
    [updateFilters, valueSet],
  )

  return (
    <div className="flex">
      {Object.entries(NAME_BY_ID).map(([id, name]) => (
        <label key={id} className="relative flex flex-col items-center mr-1 overflow-visible cursor-pointer group">
          <input onChange={onChange} value={id} checked={valueSet.has(id)} type="checkbox" className="hidden" />
          <i className="border rounded text-brown-dim border-brown-dim hover:border-brown-lightest hover:bg-brown-lightest toggled:bg-brown-dim-light toggled:border-brown-dim toggled:text-white">
            <svg width="25" height="25" viewBox="0 0 25 25">
              <use href={`#img-shape-${id}`} />
            </svg>
          </i>
          <span className="mt-1 absolute hidden text-xs top-full text-brown-dim group-hover:block">{name}</span>
        </label>
      ))}
    </div>
  )
}

ShapeFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  updateFilters: PropTypes.func.isRequired,
}

export default memo(ShapeFilter)
