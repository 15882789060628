import React, { createElement, memo } from 'react'
import PropTypes from 'prop-types'

import { textToComponents, toStyle } from 'root/constants'
import styles from './styles'

import './styles.css'

const NBSP = ' '

const URLRegexp =
  /([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|test|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?/gi

const UniversalURLRegexp = new RegExp(`(${URLRegexp.source})(\\s+|$)`, 'gi')

// eslint-disable-next-line react/prop-types
const MatchComponent = (props) =>
  createElement('a', { ...props, className: 'underline text-blue-dark', href: props.children })

const ChatMessageText = ({ hasUserName, message, hasImages, time, isOwned }) => (
  <div style={toStyle([styles.container, !hasUserName && !hasImages && styles.paddingTop])}>
    {textToComponents(UniversalURLRegexp, 1, MatchComponent, message)}
    <span className="unselectable" style={styles.timeHolder}>{` ${NBSP.repeat(isOwned ? 13 : 3)}${time}`}</span>
  </div>
)

ChatMessageText.propTypes = {
  message: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  hasUserName: PropTypes.bool.isRequired,
  hasImages: PropTypes.bool.isRequired,
  isOwned: PropTypes.bool,
}

export default memo(ChatMessageText)
