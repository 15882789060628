import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="#80506F" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM11.0043 9.1C10.5227 9.93268 9.8167 10.6194 8.96908 11.0776C9.33011 10.5701 9.70474 9.91095 9.98008 9.1H11.0043ZM10.2998 7.8C10.3637 7.39386 10.4 6.96052 10.4 6.5C10.4 6.03948 10.3637 5.60614 10.2998 5.2H11.5362C11.6431 5.61551 11.7 6.05111 11.7 6.5C11.7 6.94889 11.6431 7.38449 11.5362 7.8H10.2998ZM8.99546 7.8C9.0621 7.39259 9.1 6.95909 9.1 6.5C9.1 6.04091 9.0621 5.60741 8.99546 5.2H7.15V7.8H8.99546ZM7.15 9.1H8.66584C8.26503 10.2523 7.66289 11.1099 7.19516 11.6539C7.18013 11.6559 7.16507 11.6579 7.15 11.6598V9.1ZM5.85 7.8V5.2H4.00454C3.9379 5.60741 3.9 6.04091 3.9 6.5C3.9 6.95909 3.9379 7.39259 4.00454 7.8H5.85ZM4.33416 9.1H5.85V11.6598C5.83493 11.6579 5.81987 11.6559 5.80484 11.6539C5.33711 11.1099 4.73497 10.2523 4.33416 9.1ZM2.7002 7.8C2.63625 7.39386 2.6 6.96052 2.6 6.5C2.6 6.03948 2.63625 5.60614 2.7002 5.2H1.46382C1.35688 5.61551 1.3 6.05111 1.3 6.5C1.3 6.94889 1.35688 7.38449 1.46382 7.8H2.7002ZM1.99567 9.1H3.01992C3.29526 9.91095 3.66989 10.5701 4.03092 11.0776C3.1833 10.6194 2.47735 9.93268 1.99567 9.1ZM11.0043 3.9H9.98008C9.70474 3.08905 9.33011 2.42986 8.96908 1.92244C9.8167 2.3806 10.5227 3.06732 11.0043 3.9ZM7.15 3.9H8.66584C8.26503 2.7477 7.66288 1.8901 7.19516 1.34606C7.18013 1.34405 7.16507 1.34211 7.15 1.34023V3.9ZM5.85 1.34023V3.9H4.33416C4.73497 2.7477 5.33711 1.8901 5.80484 1.34606C5.81987 1.34405 5.83493 1.34211 5.85 1.34023ZM4.03092 1.92244C3.66989 2.42986 3.29526 3.08905 3.01992 3.9H1.99567C2.47735 3.06732 3.1833 2.3806 4.03092 1.92244Z"
    />
  </svg>
)

export default memo(SvgComponent)
