import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="#80506F" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.9902 3.91967L14.896 3.24766C14.762 2.12128 13.8529 1.23239 12.2662 0.676127C11.0222 0.240109 9.32917 0 7.4991 0C5.68347 0 3.99537 0.235614 2.74572 0.663766C1.11988 1.22078 0.206177 2.11529 0.102166 3.25103L0.00840423 3.91967C-0.0299357 4.19236 0.0535769 4.46769 0.236545 4.67521C0.419894 4.88273 0.684856 5.00184 0.963485 5.00184H3.24717C3.70422 5.00184 4.09862 4.66659 4.16657 4.22121L4.37877 3.06748C4.55794 2.95885 5.28678 2.62809 7.4991 2.62809C9.72661 2.62809 10.4452 2.95885 10.6191 3.06561L10.8316 4.22121C10.9 4.66659 11.2944 5.00184 11.751 5.00184H14.0347C14.3137 5.00184 14.5787 4.88273 14.7617 4.67521C14.945 4.46731 15.0281 4.19199 14.9902 3.91967Z" />
    <path d="M14.0215 7.55875C13.8765 6.75564 13.2008 6.13532 12.379 6.04992L10.8401 5.88997C10.448 5.84914 10.1333 5.55209 10.0752 5.16702L9.92298 4.16126C9.85275 3.69752 9.44164 3.34766 8.96638 3.34766H6.03357C5.55831 3.34766 5.1472 3.69752 5.07697 4.16126L4.92475 5.16702C4.86667 5.55172 4.55198 5.84914 4.15985 5.88997L2.62094 6.04992C1.79872 6.13532 1.12341 6.75564 0.978401 7.55875L0.386601 10.8356C0.289422 11.3739 0.437467 11.9227 0.793535 12.3418C1.14922 12.761 1.6708 13.0011 2.22502 13.0011H12.7753C13.3292 13.0011 13.8511 12.7606 14.2068 12.3418C14.5625 11.9227 14.7105 11.3739 14.6133 10.8356L14.0215 7.55875ZM7.49997 6.47095C8.66308 6.47095 9.60639 7.40142 9.60639 8.54953C9.60639 9.69726 8.66346 10.6281 7.49997 10.6281C6.33649 10.6281 5.39356 9.69763 5.39356 8.54953C5.39394 7.40142 6.33687 6.47095 7.49997 6.47095Z" />
  </svg>
)

export default memo(SvgComponent)
