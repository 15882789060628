import * as actions from '../actions'
import { getActiveProductData, getProductsData, isProductsListLoading } from '../selectors'

import { getProduct, getProducts, productAction, productsAction, productSave } from 'root/api'
import { errorAlert } from 'root/constants'

export const loadProducts = (refresh) => async (dispatch, getState) => {
  refresh = refresh === true
  const state = getState()
  const { page: lastPage, hasNext, filters } = getProductsData(state)

  if (refresh || (hasNext && !isProductsListLoading(state))) {
    dispatch(actions.loadProductsListStart({ refresh }))

    const page = refresh ? 1 : lastPage + 1
    await getProducts(page, undefined, filters)
      .then(({ data: { data: rows, meta } }) => {
        const hasNext = !!meta.paging.next
        const total = meta?.paging?.totalItems || 0
        dispatch(actions.loadProductsListSuccess({ rows, page, hasNext, total }))
      })
      .catch((e) => {
        console.error(e)
        dispatch(actions.loadProductsListFailure())
      })
  }
}

export const loadProduct = (product, refresh) => async (dispatch, getState) => {
  product = product || getActiveProductData(getState())
  const { id } = product
  dispatch(actions.loadProductStart({ item: product, refresh }))
  await getProduct(id)
    .then(({ data: { data } }) => dispatch(actions.loadProductSuccess(data)))
    .catch((e) => {
      console.error(e)
      dispatch(actions.loadProductFailure())
    })
}

export const saveProductThunk = (data) => async (dispatch) => {
  return productSave(data).then(({ data: { data } }) => {
    dispatch(actions.loadProductSuccess(data))
  })
}

export const performProductsAction = (action, data) => async (dispatch, getState) => {
  await productsAction(action, data)
}

export const performProductAction = (action, data) => async (dispatch, getState) => {
  const { id } = getActiveProductData(getState())

  return productAction(id, action, data)
    .then(({ data: { data } }) => {
      dispatch(actions.loadProductSuccess(data))
    })
    .catch(errorAlert)
}
