import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from 'root/constants'

const TextValue = ({ children, className = '' }) => <span className={`text-brown-dim ${className}`}>{children}</span>

TextValue.propTypes = {
  className: PropTypes.string,
  children: childrenPropType,
}

export default memo(TextValue)
