import { createAction } from 'redux-actions'

const NAMESPACE = 'PROFILE::'

//company common info
export const loadCompanyStart = createAction(NAMESPACE + 'LOAD_COMPANY_START')
export const loadCompanySuccess = createAction(
  NAMESPACE + 'LOAD_COMPANY_SUCCESS')
export const loadCompanyFailure = createAction(
  NAMESPACE + 'LOAD_COMPANY_FAILURE')

//company credit info
export const loadCreditInfoStart = createAction(NAMESPACE + 'LOAD_CREDIT_START')
export const loadCreditInfoSuccess = createAction(
  NAMESPACE + 'LOAD_CREDIT_SUCCESS')
export const loadCreditInfoFailure = createAction(
  NAMESPACE + 'LOAD_CREDIT_FAILURE')

export const loadKycInfoStart = createAction(NAMESPACE + 'LOAD_KYC_START')
export const loadKycInfoSuccess = createAction(NAMESPACE + 'LOAD_KYC_SUCCESS')
export const loadKycInfoFailure = createAction(NAMESPACE + 'LOAD_KYC_FAILURE')

//catalog frame info
export const loadCatalogFrameInfoStart = createAction(
  NAMESPACE + 'LOAD_CATALOG_FRAME_START')
export const loadCatalogFrameInfoSuccess = createAction(
  NAMESPACE + 'LOAD_CATALOG_FRAME_SUCCESS')
export const loadCatalogFrameInfoFailure = createAction(
  NAMESPACE + 'LOAD_CATALOG_FRAME_FAILURE')

//company invoice terms
export const loadInvoiceTermsStart = createAction(
  NAMESPACE + 'LOAD_INVOICE_TERMS_START')
export const loadInvoiceTermsSuccess = createAction(
  NAMESPACE + 'LOAD_INVOICE_TERMS_SUCCESS')
export const loadInvoiceTermsFailure = createAction(
  NAMESPACE + 'LOAD_INVOICE_TERMS_FAILURE')

//company team
export const loadCompanyTeamStart = createAction(NAMESPACE + 'LOAD_TEAM_START')
export const loadCompanyTeamSuccess = createAction(
  NAMESPACE + 'LOAD_TEAM_SUCCESS')
export const loadCompanyTeamFailure = createAction(
  NAMESPACE + 'LOAD_TEAM_FAILURE')

//pass
export const loadPasswordResponseSuccess = createAction(
  NAMESPACE + 'LOAD_PASSWORD_SUCCESS')
