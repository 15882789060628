import React, { memo } from 'react'
import PropTypes from 'prop-types'

import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import { deliveryPaymentSchema, getDeliveryPaymentOnChange } from 'root/modules/orders/constants'
import { getValidate } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import CommissionResponsiblesFormik from './components/CommissionResponsiblesFormik'
import CommissionResponsiblesReadonly from './components/CommissionResponsiblesReadonly'
import i18n from 'root/i18n'
import FormikToggle from 'root/components/FormikToggle'
import TextLabel from 'root/components/TextLabel'
import CheckIcon from 'root/icons/CheckIcon'
import TextValue from 'root/modules/orders/pages/Order/components/components/TextValue'

const validate = getValidate(deliveryPaymentSchema)
const onChange = getDeliveryPaymentOnChange('paymentBeforeDelivery', 'paymentAfterDelivery')

const PaymentTermsSection = ({ values, isEditable, isOpened = true }) => {
  const CommissionResponsiblesComponent = isEditable ? CommissionResponsiblesFormik : CommissionResponsiblesReadonly
  return (
    <ToggleableSection labelTop="order.labels.paymentTerms" isOpened={isOpened}>
      <div className="flex">
        <div className="flex flex-col">
          <div className="flex">
            <div className="mr-12 relative">
              {values?.termsChanges?.paymentBeforeDelivery && (
                <i className="absolute left-full ml-1 text-brown-dim">
                  <ExclaimIcon width={15} height={15} />
                </i>
              )}
              <InputTermFormik
                onChange={onChange}
                validate={validate}
                labelBefore="orders.labels.paymentBeforeDelivery"
                inputClass="w-16"
                labelAfter="common.percent"
                itemKey="paymentBeforeDelivery"
                value={values.paymentBeforeDelivery}
                isEditable={isEditable}
              />
            </div>
            <div className="relative">
              {values?.termsChanges?.paymentAfterDelivery && (
                <i className="absolute left-full ml-1 text-brown-dim">
                  <ExclaimIcon width={15} height={15} />
                </i>
              )}
              <InputTermFormik
                onChange={onChange}
                validate={validate}
                labelBefore="orders.labels.paymentAfterDelivery"
                inputClass="w-16"
                labelAfter="common.percent"
                itemKey="paymentAfterDelivery"
                value={values.paymentAfterDelivery}
                isEditable={isEditable}
              />
            </div>
          </div>

          <div className="flex items-center mt-4">
            {isEditable ? (
              <label className="text-gray-500 flex space-x-4">
                <span>{i18n.value('orders.labels.hasAdditionalInvoice')}</span>
                <FormikToggle itemKey="hasAdditionalInvoice" labelOn="common.yes" labelOff="common.no" />
              </label>
            ) : (
              <>
                <TextLabel label="orders.labels.hasAdditionalInvoice" className="mr-4" />
                {values.hasAdditionalInvoice ? <CheckIcon /> : null}
                <span className="relative">
                  <TextValue>{i18n.value(values.hasAdditionalInvoice ? 'common.yes' : 'common.no')}</TextValue>
                  {values?.termsChanges?.hasAdditionalInvoice && (
                    <i className="absolute left-full ml-1 text-brown-dim">
                      <ExclaimIcon width={15} height={15} />
                    </i>
                  )}
                </span>
              </>
            )}
          </div>
        </div>
        <CommissionResponsiblesComponent values={values} />
      </div>
    </ToggleableSection>
  )
}

PaymentTermsSection.propTypes = {
  values: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool,
}

export default memo(PaymentTermsSection)
