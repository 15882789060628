import TextLabel from 'root/components/TextLabel'
import InputTermFormik
  from 'root/modules/orders/pages/components/InputTerm/formik'
import React, { memo } from 'react'
import Price
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Price'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import {
  formatNumberToPrice,
  getPriceFormatter,
  getValidate,
  toClassName,
  trimNumber,
} from 'root/constants'
import i18n from 'root/i18n'
import { calcTotals } from 'root/modules/orders/constants'
import { Field } from 'formik'

const schema = yup.number().
  required('Delivery Price is required').
  positive().
  min(0).
  max(10000000)
const validate = getValidate(schema)

const DeliveryPrice = ({ isEditable, order }) => {
  const { total, subtotal, subtotalAdditional } = isEditable ? {} : calcTotals(
    order)
  const hasAdditionalGoods = subtotalAdditional > 0
  return (
    <div className="flex">
      {/*column 1*/}
      <div className="text-right">
        <div className={toClassName(
          ['space-x-2', isEditable ? 'text-brown-dim' : 'text-gray-500'])}>
          <span>{i18n.value('order.labels.subTotal')}</span>
        </div>
        <div className="">
          {isEditable ? (
            <>
              <div>
                <TextLabel label="orders.labels.deliveryPrice"
                           className="text-brown-dim"/>
              </div>
            </>
          ) : (
            order.deliveryPrice.value > 0 &&
            <TextLabel label="orders.labels.deliveryPrice"/>
          )}
        </div>
        <div className={toClassName(['space-x-2 font-bold', 'text-brown-dim'])}>
          {isEditable ? (
            <Field name="subtotalAdditional">
              {({ field }) => (field.value > 0 ? null : <span>{i18n.value(
                'order.labels.total')}</span>)}
            </Field>
          ) : !hasAdditionalGoods ? (
            <span>{i18n.value('order.labels.total')}</span>
          ) : null}
        </div>
      </div>
      {/*column 2*/}
      <div className={isEditable ? 'ml-8' : 'ml-10'}>
        <div className={toClassName(
          ['flex space-x-2', isEditable ? 'text-brown-dim' : 'text-gray-500'])}>
          {isEditable ? (
            <Field name="subtotal">
              {({ field }) => <span>{formatNumberToPrice(field.value,
                getPriceFormatter('usd'))}</span>}
            </Field>
          ) : (
            <span className="text-brown-dim">{formatNumberToPrice(subtotal,
              getPriceFormatter('usd'))}</span>
          )}
        </div>
        <div>
          {isEditable ? (
            <>
              <div className="flex">
                <span
                  className="text-brown-dim">{order.deliveryPrice.symbol}</span>
                <InputTermFormik
                  validate={validate}
                  inputClass="w-20"
                  onChange={async ({ target }, { form }) => {
                    const value = trimNumber(target.value)
                    form.setFieldValue('deliveryPrice', value)
                    if (await schema.isValid(target.value)) {
                      form.setFieldValue(
                        'total',
                        form.values.subtotal + form.values.subtotalAdditional +
                        Number.parseFloat(value),
                      )
                    }
                  }}
                  itemKey="deliveryPrice"
                  value={order.deliveryPrice.value}
                  isEditable
                />
              </div>
            </>
          ) : (
            order.deliveryPrice.value > 0 && (
              <span className="text-brown-dim">
                <Price {...order.deliveryPrice} />
              </span>
            )
          )}
        </div>
        <div className={toClassName(
          ['flex space-x-2 font-bold', 'text-brown-dim'])}>
          {isEditable ? (
            <Field name="total">
              {({ field, form }) =>
                form.values.subtotalAdditional > 0 ? null : (
                  <span>{formatNumberToPrice(field.value,
                    getPriceFormatter('usd'))}</span>
                )
              }
            </Field>
          ) : !hasAdditionalGoods ? (
            <span className="text-brown-dim">{formatNumberToPrice(total,
              getPriceFormatter('usd'))}</span>
          ) : null}
        </div>
      </div>
    </div>
  )
}

DeliveryPrice.propTypes = {
  order: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default memo(DeliveryPrice)
