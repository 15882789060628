import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.38261 23.9971C3.31176 23.9971 0 20.5817 0 16.382C0 12.1823 3.31176 8.76562 7.38261 8.76562C11.4534 8.76562 14.7652 12.181 14.7652 16.382C14.7652 20.5817 11.4534 23.9971 7.38261 23.9971ZM7.38261 9.71976C3.8228 9.71976 0.926098 12.7082 0.926098 16.3807C0.926098 20.0532 3.8228 23.0417 7.38261 23.0417C10.9424 23.0417 13.8391 20.0532 13.8391 16.3807C13.8391 12.7095 10.9424 9.71976 7.38261 9.71976Z"
      fill="currentColor"
    />
    <path
      d="M6.70201 16.8622C5.7111 16.8622 4.90466 16.0302 4.90466 15.0066C4.90466 13.9843 5.7111 13.1523 6.70201 13.1523C6.95753 13.1523 7.16569 13.3658 7.16569 13.6294C7.16569 13.8943 6.95878 14.1065 6.70201 14.1065C6.22089 14.1065 5.83076 14.5103 5.83076 15.0053C5.83076 15.5017 6.22214 15.9055 6.70201 15.9055C6.95753 15.9055 7.16569 16.1189 7.16569 16.3825C7.16444 16.6487 6.95753 16.8622 6.70201 16.8622Z"
      fill="currentColor"
    />
    <path
      d="M8.06316 19.6135C7.80764 19.6135 7.59949 19.4 7.59949 19.1364C7.59949 18.8715 7.80639 18.6594 8.06316 18.6594C8.54428 18.6594 8.93566 18.2556 8.93566 17.7605C8.93566 17.2642 8.54428 16.8604 8.06316 16.8604C7.80764 16.8604 7.59949 16.6469 7.59949 16.3833C7.59949 16.1184 7.80639 15.9062 8.06316 15.9062C9.05532 15.9062 9.86176 16.7382 9.86176 17.7605C9.86176 18.7815 9.05532 19.6135 8.06316 19.6135Z"
      fill="currentColor"
    />
    <path
      d="M8.06306 16.8604H6.70195C6.44644 16.8604 6.23828 16.6469 6.23828 16.3833C6.23828 16.1184 6.44519 15.9062 6.70195 15.9062H8.0643C8.31982 15.9062 8.52798 16.1197 8.52798 16.3833C8.52673 16.6469 8.31982 16.8604 8.06306 16.8604Z"
      fill="currentColor"
    />
    <path
      d="M8.06314 19.6143H5.36711C5.1116 19.6143 4.90344 19.4008 4.90344 19.1372C4.90344 18.8723 5.11035 18.6602 5.36711 18.6602H8.06439C8.31991 18.6602 8.52806 18.8736 8.52806 19.1372C8.52682 19.4021 8.31991 19.6143 8.06314 19.6143Z"
      fill="currentColor"
    />
    <path
      d="M9.39922 14.1104H6.70195C6.44643 14.1104 6.23828 13.8969 6.23828 13.6333C6.23828 13.3684 6.44519 13.1562 6.70195 13.1562H9.39922C9.65474 13.1562 9.86289 13.3697 9.86289 13.6333C9.86165 13.8969 9.65474 14.1104 9.39922 14.1104Z"
      fill="currentColor"
    />
    <path
      d="M7.38262 14.1054C7.1271 14.1054 6.91895 13.8919 6.91895 13.6283V12.5044C6.91895 12.2395 7.12585 12.0273 7.38262 12.0273C7.63938 12.0273 7.84629 12.2408 7.84629 12.5044V13.6283C7.84629 13.8919 7.63814 14.1054 7.38262 14.1054Z"
      fill="currentColor"
    />
    <path
      d="M7.38262 20.7665C7.1271 20.7665 6.91895 20.553 6.91895 20.2894V19.1372C6.91895 18.8723 7.12585 18.6602 7.38262 18.6602C7.63938 18.6602 7.84629 18.8736 7.84629 19.1372V20.2894C7.84629 20.5543 7.63814 20.7665 7.38262 20.7665Z"
      fill="currentColor"
    />
    <path
      d="M16.4541 5.90099C12.8083 5.90099 8.93567 4.86712 8.93567 2.95114C8.93567 1.01329 12.7186 0 16.4541 0C20.2034 0 24 1.01329 24 2.94985C24 4.86584 20.1124 5.90099 16.4541 5.90099ZM16.4541 0.954136C12.3696 0.954136 9.86177 2.11659 9.86177 2.94985C9.86177 3.76254 12.4294 4.94556 16.4541 4.94556C20.4951 4.94556 23.0739 3.76382 23.0739 2.94985C23.0739 2.13588 20.4951 0.954136 16.4541 0.954136Z"
      fill="currentColor"
    />
    <path
      d="M16.4541 20.7121H15.6639C15.4083 20.7121 15.2002 20.4986 15.2002 20.235C15.2002 19.9714 15.4071 19.7579 15.6639 19.7579H16.4541C20.495 19.7579 23.0739 18.5762 23.0739 17.7622C23.0739 17.4973 23.2808 17.2852 23.5376 17.2852C23.7943 17.2852 24.0012 17.4986 24.0012 17.7622C24 19.6988 20.2046 20.7121 16.4541 20.7121Z"
      fill="currentColor"
    />
    <path
      d="M9.39934 7.72684C9.14382 7.72684 8.93567 7.51338 8.93567 7.24977V2.94972C8.93567 2.68483 9.14258 2.47266 9.39934 2.47266C9.65486 2.47266 9.86301 2.68612 9.86301 2.94972V7.24977C9.86176 7.51466 9.65486 7.72684 9.39934 7.72684Z"
      fill="currentColor"
    />
    <path
      d="M23.5375 18.2391C23.282 18.2391 23.0739 18.0256 23.0739 17.762V2.94972C23.0739 2.68483 23.2808 2.47266 23.5375 2.47266C23.7943 2.47266 24.0012 2.68612 24.0012 2.94972V17.7607C24 18.0256 23.7931 18.2391 23.5375 18.2391Z"
      fill="currentColor"
    />
    <path
      d="M16.4541 13.3761C16.3357 13.3761 16.2173 13.3697 16.0976 13.362C15.9992 13.3556 15.8994 13.3478 15.7997 13.3478C15.5442 13.3478 15.3361 13.1344 15.3361 12.8708C15.3361 12.6072 15.543 12.3937 15.7997 12.3937C15.9181 12.3937 16.0366 12.4001 16.155 12.4079C16.2547 12.4143 16.3544 12.422 16.4529 12.422C20.4938 12.422 23.0726 11.2403 23.0726 10.4263C23.0726 10.1614 23.2795 9.94922 23.5363 9.94922C23.7931 9.94922 24 10.1627 24 10.4263C24 12.3423 20.1124 13.3761 16.4541 13.3761Z"
      fill="currentColor"
    />
    <path
      d="M16.454 17.0558C16.1985 17.0558 15.9904 16.8424 15.9904 16.5788C15.9904 16.3139 16.1973 16.1017 16.454 16.1017C20.495 16.1017 23.0738 14.92 23.0738 14.106C23.0738 13.8411 23.2807 13.6289 23.5375 13.6289C23.7943 13.6289 24.0012 13.8424 24.0012 14.106C23.9999 16.022 20.1123 17.0558 16.454 17.0558Z"
      fill="currentColor"
    />
    <path
      d="M16.4541 9.66649C14.9771 9.66649 13.6958 9.53918 12.6438 9.28843C12.3945 9.22799 12.24 8.9721 12.2973 8.71492C12.3546 8.45774 12.6039 8.30086 12.8532 8.35616C13.8354 8.59148 15.0469 8.71106 16.4541 8.71106C20.4951 8.71106 23.0739 7.52932 23.0739 6.71535C23.0739 6.45045 23.2808 6.23828 23.5376 6.23828C23.7944 6.23828 24.0013 6.45174 24.0013 6.71535C24 8.6532 20.2046 9.66649 16.4541 9.66649ZM9.59004 7.78264C9.44546 7.78264 9.30337 7.71449 9.21238 7.58333C9.02915 7.31844 8.93567 7.02525 8.93567 6.71535C8.93567 6.45045 9.14258 6.23828 9.39934 6.23828C9.65486 6.23828 9.86301 6.45174 9.86301 6.71535C9.86301 6.82594 9.89667 6.92495 9.96771 7.02654C10.116 7.24128 10.0687 7.53961 9.86052 7.69392C9.77701 7.75436 9.68228 7.78264 9.59004 7.78264Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(SvgComponent)
