/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import Select from 'react-select'
import { getExampleNumber, format } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import {
  COUNTRY_INFO,
  COUNTRY_INFO_BY_CODE,
  COUNTRY_INFO_BY_DIAL_CODE,
  handleChange,
  toClassName,
} from 'root/constants'
import PropTypes from 'prop-types'
import { ChevronDownIcon } from 'root/icons'
import './styles.css'
import axios from 'axios'
import { useEffectOnce } from 'root/hooks'

const formatName = 'INTERNATIONAL'

const getLocalPhonePart = (phone) => phone.split(' ').slice(1).join(' ')

const getExample = (countryCode) =>
  !countryCode ? '' : getLocalPhonePart(getExampleNumber(countryCode, examples).format(formatName))

const formatPhone = (phone, code) => getLocalPhonePart(format(phone, code, formatName))

const renderFlag = ({ code, name }, { context }) => {
  const isValue = context === 'value'
  const image = (
    <img
      className="login-country-flag"
      src={`https://hatscripts.github.io/circle-flags/flags/${code.toLowerCase()}.svg`}
      alt={name}
    />
  )
  return isValue ? (
    image
  ) : (
    <div className="login-country-flag-row">
      {image}
      <span>{name}</span>
    </div>
  )
}

// eslint-disable-next-line react/prop-types
const DropdownIndicator = ({ selectProps: { menuIsOpen } }) => (
  <ChevronDownIcon style={{ transform: menuIsOpen && 'rotate(180deg)' }} />
)
const getCode = ({ code }) => code
const getTrue = () => true

const dialRegExp = new RegExp('^\\+?[0-9 ]*$')
const formatDial = (value) => value.replace(/ /g, '')

const getDialScoreFunc = (value) => (dial) => {
  dial = formatDial(dial)
  const score = dial.indexOf(value)
  return score === -1 ? score : score + dial.length - value.length
}
export const getNameScoreFunc = (value) => (name) => name.toLowerCase().indexOf(value)

const selectComponents = { IndicatorSeparator: null, DropdownIndicator }

const Phone = ({ isPhoneValid, phone, countryCode, setCountryCode, setPhone, disabled, onKeyDown, tabIndex }) => {
  const changeCountry = useCallback(
    ({ code, dialCode }) => {
      setExampleNumber(getExample(code))
      setCountryCode(code)
      setDialCode(dialCode)
    },
    [setCountryCode],
  )
  const [exampleNumber, setExampleNumber] = useState(getExample(countryCode))

  useEffectOnce(() => {
    axios.get('https://ipapi.co/json/').then(({ data: { country } }) => {
      if (COUNTRY_INFO_BY_CODE[country]) changeCountry(COUNTRY_INFO_BY_CODE[country])
    })
  })
  const value = COUNTRY_INFO_BY_CODE[countryCode] ?? null
  const [dialCode, setDialCode] = useState(value?.dialCode ?? '')
  const [options, setOptions] = useState(COUNTRY_INFO)
  const selectRef = useRef()

  const onCodeChange = useMemo(
    () =>
      handleChange((text) => {
        const dialCode = '+' + text.replace(/[^0-9]/g, '')
        const code = COUNTRY_INFO_BY_DIAL_CODE[dialCode]?.code ?? null
        changeCountry({ dialCode, code })
      }),
    [changeCountry],
  )
  const onPhoneChange = useMemo(() => handleChange((text) => setPhone(text.replace(/[^0-9]/g, ''))), [setPhone])
  const onInputChange = useCallback((value) => {
    const { menuListRef } = selectRef.current.select
    if (menuListRef) menuListRef.scrollTop = 0

    let data = COUNTRY_INFO
    const isDial = dialRegExp.test(value)
    value = isDial ? formatDial(value) : value.toLowerCase()
    if (value) {
      const getScore = (isDial ? getDialScoreFunc : getNameScoreFunc)(value)
      const scores = data.reduce((obj, { code, dialCode, name }) => {
        obj[code] = getScore(isDial ? dialCode : `${code} ${name}`)
        return obj
      }, {})
      data = data.filter(({ code }) => scores[code] >= 0).sort((r1, r2) => scores[r1.code] - scores[r2.code])
    }

    setOptions(data)
  }, [])

  return (
    <div className="login-phone-container">
      <Select
        ref={selectRef}
        onInputChange={onInputChange}
        components={selectComponents}
        disabled={disabled}
        className="input-form login-country-input"
        classNamePrefix="react-select"
        formatOptionLabel={renderFlag}
        value={value}
        options={options}
        getOptionValue={getCode}
        onChange={changeCountry}
        filterOption={getTrue}
        placeholder={''}
      />
      <input
        disabled={disabled}
        value={dialCode}
        onChange={onCodeChange}
        className={toClassName(['input-form login-code-input', !isPhoneValid && 'phone-component__input--error'])}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        maxLength={5}
      />
      <input
        disabled={disabled}
        value={formatPhone(phone, countryCode) || phone}
        onChange={onPhoneChange}
        className={toClassName(['input-form login-phone-input', !isPhoneValid && 'phone-component__input--error'])}
        placeholder={exampleNumber}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
      />
    </div>
  )
}

Phone.propTypes = {
  isPhoneValid: PropTypes.bool.isRequired,
  phone: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  setPhone: PropTypes.func.isRequired,
  setCountryCode: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
}

export default Phone
