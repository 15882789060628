import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import OrderSubHeader from 'root/components/OrderSubHeader'

const OrderSpecialTermsFormik = ({ label, itemKey, isEditable = true, value, inputClass = '' }) => {
  return (
    <>
      {label ? <OrderSubHeader label={label} /> : ''}
      <Field
        as={'textarea'}
        name={itemKey}
        id={itemKey}
        className={'form-textarea text-brown-dim w-full block shadow shadow-inset border-gray-300 ' + inputClass}
        disabled={!isEditable}
        rows={5}
        maxLength={1000}
      />
    </>
  )
}

OrderSpecialTermsFormik.propTypes = {
  label: PropTypes.string,
  inputClass: PropTypes.string,
  itemKey: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  value: PropTypes.any.isRequired,
}

export default memo(OrderSpecialTermsFormik)
