import React, { memo } from 'react'
import PropTypes from 'prop-types'
import RequiredLabel from 'root/components/RequiredLabel'

const ProductRequiredLabel = ({ className = 'text-brown-dim', ...props }) => (
  <RequiredLabel className={'text-lg mb-2 ' + className} {...props} />
)

ProductRequiredLabel.propTypes = {
  className: PropTypes.string,
}

export default memo(ProductRequiredLabel)
