import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { useToggle } from 'root/hooks'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import Progress from '../../../../Progress'

const TrackingItem = ({ shipment, action, isSubmitting, handleSubmit, setFieldValue, isReceiver }) => {
  const [opened, toggle] = useToggle(true)
  const isDelivered = shipment.state === 'delivered'

  const buttons =
    isReceiver && !isDelivered
      ? [
          {
            color: 'brown',
            label: 'orders.buttons.confirmDelivery',
            action: action,
            params: {
              shipments: [shipment.id],
            },
          },
        ]
      : []

  let rows = []

  if (opened) {
    rows = shipment.content.map(
      ({ hasStone, hasCertificate, itemName, certificateNumber, itemId, certificateUrl, diamondName }) => {
        return (
          <div key={shipment.id + itemId} className="flex">
            <div className="w-1/3">
              {hasStone ? (
                <label className="text-brown-dim">{itemName}</label>
              ) : (
                <label className="text-gray-400 line-through">{diamondName ? `${diamondName}` : <>&mdash;</>}</label>
              )}
            </div>
            <div className="w-1/3">
              {hasCertificate ? (
                certificateUrl ? (
                  <a
                    href={certificateUrl}
                    className="cursor-pointer hover:underline"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <span className="text-blue-skyblue">
                      {i18n.value('order.diamonds.certificate')}
                      {certificateNumber}
                    </span>
                  </a>
                ) : (
                  <label className="text-blue-skyblue">
                    {i18n.value('order.diamonds.certificate')}
                    {certificateNumber}
                  </label>
                )
              ) : (
                <label className="text-brown-dim-light">{diamondName}</label>
              )}
            </div>
          </div>
        )
      },
    )
  }
  const colSpan = isReceiver && !isDelivered ? 'col-span-4' : 'col-span-4'
  return (
    <div className="grid grid-cols-5 pb-4 mb-20 border-b border-brown-dim">
      <div className={colSpan}>
        <div className={'relative flex cursor-pointer text-sm'} onClick={toggle}>
          <a
            href={shipment.url}
            target="_blank"
            rel="noreferrer"
            className={'text-brown-dim font-bold flex space-x-4 cursor-pointer'}
          >
            <span className="text-blue-skyblue uppercase">{i18n.value('orders.labels.shipment')}</span>
            <span>{shipment.trackNumber + ' (' + shipment.vendor + ')'}</span>
          </a>
          <div className="mt-2 ml-4 z-10">
            <svg
              className="inline"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              preserveAspectRatio="xMinYMin"
            >
              <use href={`#${opened ? 'down' : 'up'}`} />
            </svg>
          </div>
          <Progress
            labels={['Shipped', 'Delivered']}
            className="absolute z-10 -mb-6 right-0 "
            values={[true, isDelivered]}
          />
          <hr className="absolute left-0 right-0 bottom-0 mb-2" />
        </div>
        {opened && <div>{rows}</div>}
      </div>
      {isReceiver && !isDelivered && (
        <div className="col-span-1 right-0 absolute">
          <FormikSubmittingButtons
            slim
            setFieldValue={setFieldValue}
            buttons={buttons}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </div>
  )
}
TrackingItem.propTypes = {
  shipment: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isReceiver: PropTypes.bool,
}

export default memo(TrackingItem)
