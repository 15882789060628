import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

export const defaultState = {}

export default handleActions(
  {
    [actions.loadOrderStart]: (state, { payload: { refresh, item } }) => (refresh ? state : item),
    [actions.loadOrderSuccess]: (state, { payload: order }) => (order?.id === state?.id ? order : state),
    [actions.uploadOrderDocumentSuccess]: (state, { payload: { id, data } }) => {
      const documents = [...state.documents]
      const documentIndex = documents.findIndex((document) => document.id === id)
      const document = documents[documentIndex]
      documents[documentIndex] = { ...document, files: [...document.files, data] }
      return { ...state, documents }
    },
  },
  defaultState,
)
