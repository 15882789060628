import React, { memo } from 'react'
import Column from './Column'

const LocationColumn = ({ children, ...props }) => (
  <Column
    label="order.diamonds.location"
    columnClass="w-16 text-center"
    className="w-16 text-center text-blue-dark"
    {...props}
  >
    {children || (({ item }) => item.zipCodeId?.countryId?.name || item.originCountryId?.name || '-')}
  </Column>
)

LocationColumn.propTypes = Column.propTypes

export default memo(LocationColumn)
