import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { GridIcon, DeliveryIcon, StockIcon, ReturnRequestIcon, PaymentIcon, ReturnIcon } from 'root/icons'
import StatusTabItem from 'root/components/StatusTabItem'
import { SECTION_SALES } from 'root/modules/orders/constants'

import AllItemsTab from './components/AllItemsTab'
import DeliveryItemsTab from './components/DeliveryItemsTab'
import StockItemsTab from './components/StockItemsTab'
import PaymentItemsTab from './components/PaymentItemsTab'
import ReturnItemsTab from './components/ReturnItemsTab'

const tabComponents = {
  all: AllItemsTab,
  delivery: DeliveryItemsTab,
  stock: StockItemsTab,
  returnRequest: StockItemsTab,
  payment: PaymentItemsTab,
  return: ReturnItemsTab,
}

const extraProps = {
  returnRequest: { isRequest: true },
}

const DiamondStatusBar = ({ order, readOnly, isSubmitting, handleSubmit, setFieldValue, values, onlyPreview }) => {
  const [activeTab, selectTab] = useState('all')
  const { section } = useParams()
  const isSeller = section === SECTION_SALES

  const allTabs = [{ key: 'all', icon: <GridIcon width={15} height={15} /> }]
  const otherTabs = [
    {
      key: 'delivery',
      icon: <DeliveryIcon />,
      notifications: order.products.reduce(
        (acc, curr) =>
          curr &&
          curr.metaState &&
          !curr.metaState.inCustomerStock &&
          !curr.metaState.paid &&
          !curr.metaState.inPayment &&
          !curr.metaState.returnRequest &&
          !curr.metaState.toBeReturned &&
          !curr.metaState.fullyReceivedBySeller &&
          !curr.metaState.fullyShippedByBuyer
            ? acc + 1
            : acc,
        0,
      ),
    },
    {
      key: 'stock',
      label: isSeller ? 'stock' : 'myStock',
      icon: <StockIcon />,
      notifications: order.products.reduce((acc, curr) => (curr?.metaState?.inCustomerStock ? acc + 1 : acc), 0),
    },
    {
      key: 'returnRequest',
      icon: <ReturnRequestIcon />,
      notifications: order.products.reduce((acc, curr) => (curr?.metaState?.returnRequest ? acc + 1 : acc), 0),
    },
    {
      key: 'payment',
      icon: <PaymentIcon />,
      notifications: order.products.reduce(
        (acc, curr) => (curr?.metaState?.inPayment && !curr?.metaState?.paid ? acc + 1 : acc),
        0,
      ),
    },
    {
      key: 'return',
      icon: <ReturnIcon />,
      notifications: order.products.reduce(
        (acc, curr) =>
          curr?.metaState?.toBeReturned ||
          (curr?.metaState?.fullyShippedByBuyer && !curr?.metaState?.fullyReceivedBySeller)
            ? acc + 1
            : acc,
        0,
      ),
    },
  ]
  const items = onlyPreview ? [...allTabs] : [...allTabs, ...otherTabs]
  const props = { order, isSubmitting, handleSubmit, setFieldValue, values, isSeller }
  const TabComponent = tabComponents[activeTab]
  return (
    <div className="h-max-content container mx-auto mt-5 mb-5">
      <div className="bg-white rounded-md border-b border-gray-300 shadow justify-between flex items-center container mx-auto lg:w-screen-lg-min">
        {items.map((item) => (
          <StatusTabItem
            labelPrefix={'orders.labels.memoPaymentAndDeliveryTab'}
            onClick={() => selectTab(item.key)}
            isActive={item.key === activeTab}
            key={item.key}
            item={item}
            notifications={item.notifications}
          />
        ))}
      </div>
      <div className="py-8">
        {
          <TabComponent
            onDiamondClick={(tab) => selectTab(tab)}
            readOnly={readOnly}
            {...props}
            {...extraProps[activeTab]}
          />
        }
      </div>
    </div>
  )
}

DiamondStatusBar.propTypes = {
  order: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  onlyPreview: PropTypes.bool,
}

export default memo(DiamondStatusBar)
