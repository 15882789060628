import GridIcon from 'root/icons/GridIcon'
import React from 'react'
import * as yup from 'yup'
import { trimInteger } from 'root/constants'
import { COLOR_WHITE } from '../../products/constants'

export const DEAL_TYPE_INVOICE = 'invoice'
export const DEAL_TYPE_MEMO = 'memo'
export const ORDER_DEAL_TYPES = [DEAL_TYPE_INVOICE, DEAL_TYPE_MEMO]
export const RESPONSIBLE_SELLER = 'seller'
export const RESPONSIBLE_BUYER = 'buyer'

export const DOCUMENT_TYPE_INVOICE = 'invoice'
export const DOCUMENT_TYPE_ADDITIONAL_INVOICE = 'additional_invoice'

export const SECTION_SALES = 'sales'
export const SECTION_PURCHASES = 'purchases'

export const INVOICE_ORDER_STATUS_LIST = [
  { key: 'waiting', icon: <GridIcon width={15} height={15} /> },
  { key: '' },
  { key: 'requests' },
  { key: 'negotiation' },
  { key: 'payment_and_delivery' },
  { key: 'executed' },
  { key: 'canceled' },
]
export const MEMO_ORDER_STATUS_LIST = [
  { key: '', icon: <GridIcon width={15} height={15} /> },
  { key: 'negotiation' },
  { key: 'payment_and_delivery' },
  { key: 'executed' },
  { key: 'canceled' },
]
export const STATUS_BY_DEAL_TYPE = {
  invoice: INVOICE_ORDER_STATUS_LIST,
  memo: MEMO_ORDER_STATUS_LIST,
}

export const ITEMS_PER_PAGE = 10
export const UPDATE_INTERVAL = 10000

export const ORDER_FIELD_PADDING = 41

export const ACTION_DEAL_NEGOTIATION = 'deal_negotiation'
export const ACTION_COMPLETE = 'completed'
export const ACTION_DELIVERY_RECEIVED = 'payment_delivery_received'
export const ACTION_INVOICE_CREATED = 'payment_delivery_invoice_created'
export const ACTION_ADDITIONAL_INVOICE_CREATED = 'payment_delivery_additional_invoice_created'
export const ACTION_ORDER_FILE_UPLOAD = 'file'
export const ACTION_INVOICE_CANCELED = 'payment_delivery_invoice_canceled'
export const ACTION_INVOICE_CONFIRMED = 'payment_delivery_invoice_confirmed'
export const ACTION_ADDITIONAL_INVOICE_CANCELED = 'payment_delivery_additional_invoice_canceled'
export const ACTION_ADDITIONAL_INVOICE_CONFIRMED = 'payment_delivery_additional_invoice_confirmed'
export const ACTION_MAKE_SHIPMENT = 'payment_delivery_shipped'
export const ACTION_START_DEAL = 'products_confirmed'
export const ACTION_NEGOTIATION_UPDATE = 'deal_negotiation_update'
export const ACTION_FINAL_OFFER = 'deal_negotiation_final'
export const ACTION_START_WITH_INITIAL_TERMS = 'deal_negotiation_initial_terms'
export const ACTION_REMOVE_PRODUCT = 'remove-product'
export const ACTION_CANCEL = 'canceled'

export const ACTION_MEMO_PAD_EXTRACT_STONES = 'memo_pad_extract_stones'
export const ACTION_REJECT = 'rejected'
export const ACTION_NEW_MEMO_REQUEST = 'new_memo_request'
export const ACTION_EXISTING_MEMO_REQUEST = 'existing_memo_request'
export const ACTION_CANCEL_MEMO_REQUEST = 'memo_request_canceled'
export const ACTION_CONFIRM_CHANGES = 'deal_negotiation'
export const ACTION_CONFIRM_CURRENT_TERMS = 'payment_delivery'
export const ACTION_CONFIRM_NEW_MEMO = 'new_memo_request_confirmed'
export const ACTION_CONFIRM_EXISTING_MEMO = 'existing_memo_request_confirmed'
export const ACTION_INVOICE_PAID = 'payment_delivery_invoice_paid'
export const ACTION_ADDITIONAL_INVOICE_PAID = 'payment_delivery_additional_invoice_paid'
export const ACTION_ADD_PRODUCT = 'add-product'

export const ACTION_MEMO_NEW_CANCELED = 'new_memo_canceled'
export const ACTION_MEMO_NEW_CREATED = 'new_memo_created'
export const ACTION_MEMO_NEW_CONFIRMED = 'new_memo_confirmed'
export const ACTION_MEMO_NEW_DOCS_UPLOADED = 'new_memo_docs_uploaded'

export const STAGE_REQUEST = 'requests'
export const STAGE_NEGOTIATION = 'negotiation'
export const STAGE_PAYMENT_AND_DELIVERY = 'payment_and_delivery'
export const STAGE_EXECUTED = 'executed'
export const STAGE_CANCELED = 'canceled'

export const STATE_COMPLETED = 'completed'
export const STATE_CANCELED = 'canceled'
export const STATE_PRODUCTS_APPROVAL = 'products_approval'
export const STATE_NEGOTIATION_FINAL = 'deal_negotiation_final'
export const STATE_NEW_MEMO_REQUEST = 'new_memo_request'
export const STATE_EXISTING_MEMO_REQUEST = 'existing_memo_request'
export const STATE_INVOICE_PAYMENT_AND_DELIVERY = 'payment_delivery'
export const STATE_INVOICE_CONFIRMED = 'payment_delivery_invoice_confirmed'
export const STATE_INVOICE_CANCELED = 'payment_delivery_invoice_canceled'
export const STATE_INVOICE_CREATED = 'payment_delivery_invoice_created'
export const STATE_ADDITIONAL_INVOICE = 'payment_delivery_additional_invoice'
export const STATE_ADDITIONAL_INVOICE_CONFIRMED = 'payment_delivery_additional_invoice_confirmed'
export const STATE_ADDITIONAL_INVOICE_CANCELED = 'payment_delivery_additional_invoice_canceled'
export const STATE_ADDITIONAL_INVOICE_CREATED = 'payment_delivery_additional_invoice_created'
export const STATE_REJECTED = 'rejected'

export const FINAL_STATES = [STATE_CANCELED, STATE_COMPLETED, STATE_REJECTED]

//memo deal negotiation
export const ACTION_MEMO_NEGOTIATION_UPDATE_TERMS = 'deal_memo_update'
export const ACTION_MEMO_NEGOTIATION_FINAL_TERMS = 'deal_memo_final'
export const ACTION_MEMO_BACK_TO_INVOICE = 'return_to_deal_invoice'
export const ACTION_MEMO_NEGOTIATION_CONFIRM_AND_SIGN = 'memo_waiting_supplementary'
export const ACTION_MEMO_SUPPLEMENTARY_UPLOADED = 'memo_supplementary_uploaded'

//memo payment and delivery
export const ACTION_MEMO_PAD_SHIP_TO_BUYER = 'memo_pad_ship_item_seller'
export const ACTION_MEMO_PAD_RECEIVE_BY_BUYER = 'memo_pad_receive_item_buyer'
export const ACTION_MEMO_PAD_RETURN_TO_SELLER = 'memo_pad_return_item_buyer'
export const ACTION_MEMO_PAD_REQUEST_INVOICE_FROM_SELLER = 'memo_pad_request_invoice_buyer'
export const ACTION_MEMO_PAD_REQUEST_RETURN_FROM_BUYER = 'memo_pad_request_return_seller'
export const ACTION_MEMO_PAD_REQUEST_PAYMENT_FROM_BUYER = 'memo_pad_request_payment_seller'
export const ACTION_MEMO_PAD_ISSUE_INVOICE_FOR_BUYER = 'memo_pad_issue_invoice_seller'
export const ACTION_MEMO_PAD_UPLOAD_INVOICE_FOR_BUYER = 'memo_pad_upload_invoice_seller'
export const ACTION_MEMO_PAD_CONFIRM_PAYMENT_SELLER = 'memo_pad_confirm_payment_seller'
export const ACTION_MEMO_PAD_SHIP_TO_SELLER = 'memo_pad_ship_item_buyer'
export const ACTION_MEMO_PAD_RECEIVE_BY_SELLER = 'memo_pad_receive_item_seller'

export const SHIPMENT_VENDOR_FEDEX = 'fedex'
export const SHIPMENT_VENDOR_UPS = 'ups'
export const SHIPMENT_VENDOR_BRINKS = 'brinks'
export const SHIPMENT_VENDOR_MALKA = 'malka-amit'
export const SHIPMENT_VENDOR_OTHER = 'other'
export const SHIPMENT_PROVIDERS = [
  SHIPMENT_VENDOR_FEDEX,
  SHIPMENT_VENDOR_UPS,
  SHIPMENT_VENDOR_BRINKS,
  SHIPMENT_VENDOR_MALKA,
  SHIPMENT_VENDOR_OTHER,
]

export const getDocumentFileName = (index, number) =>
  `Invoice ${index + 1} (autogenerated) ${number.replace('/', ' ')}.pdf`

export const deliveryPaymentSchema = yup.number().integer().min(0).max(100).required()
export const rapaportSchema = yup.number().positive().min(1).max(99).required()
export const priceSchema = yup.number().positive().min(1).max(10000000).required()

export const getDeliveryPaymentOnChange = (before, after) => {
  return async (e, { field, form }) => {
    const { value } = e.target
    form.setFieldValue(field.name, trimInteger(value))
    if (await deliveryPaymentSchema.isValid(value)) {
      const controlledField = field.name === after ? before : after
      form.setFieldValue(controlledField, 100 - parseInt(value))
    }
  }
}

export const roundTwo = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export const calcAverageR = (all_items) => {
  const items = Object.values(all_items).filter((v) => !v.disabled && v.colorType?.id == COLOR_WHITE)
  if (items.some((v) => !calcRefPriceDiscount(v.price, v.referencePrice))) return 0
  const res = items.reduce(
    (acc, { referencePrice, price }) => ({
      referencePrice: referencePrice + Number.parseFloat(acc.referencePrice),
      price: price + Number.parseFloat(acc.price),
    }),
    { referencePrice: 0, price: 0 },
  )
  if (res.referencePrice === 0) return ''
  return roundTwo(calcRefPriceDiscount(res.price, res.referencePrice))
}

export const calcRefPriceDiscount = (price, referencePrice) => {
  return (1 - price / referencePrice) * 100
}

export const toFixedFloat = (value, digits = 2) => roundTwo(value)

const calcSubtotal = (items) => {
  return roundTwo(
    items.reduce((acc, { disabled, price }) => (disabled ? acc : acc + (Number.parseFloat(price) || 0)), 0),
  )
}

export const calcTotals = (order) => {
  const items = Object.values(order.orderItems || order.products).filter((v) => !v.disabled)
  const subtotal = calcSubtotal(items)
  const subtotalAdditional = order.additionalGoods ? calcSubtotal(order.additionalGoods) : 0
  const deliveryPrice = Number.parseFloat(order.deliveryPrice?.value || order.deliveryPrice) || 0
  const total = subtotal + deliveryPrice + subtotalAdditional
  return { subtotal: roundTwo(subtotal), total: roundTwo(total), subtotalAdditional: roundTwo(subtotalAdditional) }
}
