import { getUserCompanyId } from 'root/selectors'

export const getState = (state) => state.data.orders

const getOrderList = (state) => getState(state).orderList
export const isOrderListLoading = (state) => getOrderList(state).isLoading
export const getOrdersData = (state) => getOrderList(state).data
export const getActiveOrdersTab = (state) => getOrdersData(state).filters.tab
export const getActiveOrdersDealType = (state) => getOrdersData(state).filters.dt

export const getNotificationsSummary = (state) => getState(state).notificationsSummary.data

const getActiveOrder = (state) => getState(state).order
export const getActiveOrderData = (state) => getActiveOrder(state).data
export const getActiveOrderProducts = (state) => getActiveOrderData(state).products
export const getActiveOrderCompany = (state) => getActiveOrderData(state).company
export const getActiveOrderUnreadCount = (state) => getActiveOrderData(state).unreadCount || 0
export const isSeller = (state) => getActiveOrderData(state)?.sellerCompanyId?.id === getUserCompanyId(state)
export const isBuyer = (state) => getActiveOrderData(state)?.buyerCompanyId?.id === getUserCompanyId(state)
export const isActiveOrderLoading = (state) => getActiveOrder(state).isLoading
export const getResponsible = (state) => getActiveOrderData(state)?.responsibleId

export const getOrderInitialTerms = (state) => getState(state).initialTerms
export const getInitialTermsVisible = (state) => getOrderInitialTerms(state).isVisible
export const getInitialTermsLoading = (state) => getOrderInitialTerms(state).isLoading
export const getInitialTermsData = (state) => getOrderInitialTerms(state).data
