import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType, toClassName } from 'root/constants'
import TextLabel from 'root/components/TextLabel'

const CardRow = ({ label, full = false, children }) => (
  <div className="flex flex-wrap">
    {!full && <div className="w-1/2">{label && <TextLabel label={label} />}</div>}
    <div className={toClassName([!full && 'w-1/2'])}>{children}</div>
  </div>
)

CardRow.propTypes = {
  label: PropTypes.string,
  full: PropTypes.bool,
  children: childrenPropType,
}

export default memo(CardRow)
