import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ width = 32, height = 32, ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.0539 20.047C21.3321 20.3252 21.3321 20.7763 21.0539 21.0546C20.7756 21.3328 20.3245 21.3328 20.0462 21.0546L16.6262 17.6346C16.348 17.3563 16.348 16.9052 16.6262 16.627C16.9045 16.3487 17.3556 16.3487 17.6339 16.627L21.0539 20.047ZM10.29 17.8433C6.11892 17.8433 2.73755 14.4619 2.73755 10.2908C2.73755 6.11965 6.11892 2.73828 10.29 2.73828C14.4612 2.73828 17.8425 6.11965 17.8425 10.2908C17.8425 14.4619 14.4612 17.8433 10.29 17.8433ZM10.29 16.4183C13.6742 16.4183 16.4175 13.6749 16.4175 10.2908C16.4175 6.90666 13.6742 4.16328 10.29 4.16328C6.90592 4.16328 4.16255 6.90666 4.16255 10.2908C4.16255 13.6749 6.90592 16.4183 10.29 16.4183ZM6.77505 9.72078C6.77505 9.98312 6.56238 10.1958 6.30005 10.1958C6.03771 10.1958 5.82505 9.98312 5.82505 9.72078C5.82505 7.56963 7.5689 5.82578 9.72005 5.82578C9.98238 5.82578 10.195 6.03845 10.195 6.30078C10.195 6.56312 9.98238 6.77578 9.72005 6.77578C8.09357 6.77578 6.77505 8.0943 6.77505 9.72078Z"
      fill="currentColor"
    />
  </svg>
)
SvgComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default memo(SvgComponent)
