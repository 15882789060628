import React, { memo } from 'react'
import PropTypes from 'prop-types'

const RequiredLabel = ({ label, className, isRequired = true }) => {
  return (
    <div>
      <span className={'text-gray-500 flex ' + className}>
        {label}
        <b className="ml-2 text-brown-dim text-lg">{isRequired ? '*' : <span>&nbsp;</span>}</b>
      </span>
    </div>
  )
}

RequiredLabel.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
}

export default memo(RequiredLabel)
