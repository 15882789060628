import { applyMiddleware, compose, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { sentryMiddleware } from 'root/sentry'
import { createBus, createReduxMiddleware } from 'suber'

let composeEnhancers = compose
if (window && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

const bus = createBus()
bus.applyMiddleware((_) => (channel, message, source) => {
  if (source !== 'redux') {
    store.dispatch({ ...message, type: channel })
  }
})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, createReduxMiddleware(bus), sentryMiddleware)),
)
const persistor = persistStore(store)

export { store, persistor, bus }
