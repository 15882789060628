import { connect } from 'react-redux'

import { loadOrders } from '../../thunks'
import { getNotificationsSummary, getOrdersData } from '../../selectors'
import { updateOrdersFilters } from 'root/modules/orders/actions'
import { getProfileCompanyData } from 'root/modules/profile/selectors'
import { loadCompanyCommonInfo } from 'root/modules/profile/thunks'

const mapStateToProps = (state) => ({
  data: getOrdersData(state),
  notificationSummary: getNotificationsSummary(state),
  company: getProfileCompanyData(state),
})

const mapDispatchToProps = {
  loadData: loadOrders,
  loadCompanyCommonInfo,
  updateFilters: updateOrdersFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)
