import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'
import { getDocumentFile, getMemoFile } from 'root/api'

const UploadedFilesList = ({ files, isMemo, documentId }) => {
  const loadFunc = isMemo ? getMemoFile : getDocumentFile
  return (
    <div className="mx-auto items-center">
      <div className="">
        <div className="bg-gray-100 mt-5">
          <div className="h-8 mb-2 pl-4 flex text-center grid-cols-3 items-center text-gray-500 mx-auto">
            <div className="w-8 text-center">&nbsp;</div>
            <div className="w-64 text-center">
              <span>{i18n.value('files.list.tableHead.created')}</span>
            </div>
            <div className="w-128 text-right">
              <span>{i18n.value('files.list.tableHead.name')}</span>
            </div>
          </div>
        </div>
        {files.map((file, index) => {
          const dt = new Date(file.created * 1000)
          return (
            <div
              onClick={() => loadFunc(documentId, file.id)}
              key={index}
              className="cursor-pointer h-max-content pl-4 pt-1 pb-1 flex text-center grid-cols-3 items-center text-brown-dim mx-auto hover:bg-gray-100"
            >
              <div className="w-8 text-center">
                <DocumentRowIcon />
              </div>
              <div className="w-64 text-center">
                {dt.toLocaleDateString('en-US') + ' ' + dt.toLocaleTimeString('en-US')}
              </div>
              <div className="w-128 text-right">{file.fileName}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

UploadedFilesList.propTypes = {
  files: PropTypes.array.isRequired,
  documentId: PropTypes.string.isRequired,
  isMemo: PropTypes.bool,
}

export default memo(UploadedFilesList)
