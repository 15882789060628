import React, { memo } from 'react'
import i18n from 'root/i18n'
import DownloadIcon from 'root/icons/DownloadIcon'
import * as PropTypes from 'prop-types'

const TemplateDoc = ({ label, onClick }) => {
  return (
    <>
      <span onClick={onClick} className="text-blue-skyblue cursor-pointer flex items-center hover:underline">
        <DownloadIcon width={25} height={25} />
        <span className={'pt-1'}>{i18n.value(label)}</span>
      </span>
    </>
  )
}

TemplateDoc.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(TemplateDoc)
