import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import DeliveryAddressInfo from 'root/modules/orders/pages/components/DeliveryAddressInfo'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DeliveryAddress from 'root/modules/orders/pages/components/DeliveryAddress'

const AddressSection = ({ isEditable = false, values, isOpened = true, withoutNames = false }) => (
  <ToggleableSection labelTop="order.labels.deliveryAddress" isOpened={isOpened}>
    {isEditable ? (
      <DeliveryAddress withoutRecipient={withoutNames} isEditable={isEditable} address={values.address} />
    ) : (
      <DeliveryAddressInfo address={values.address} withoutNames={withoutNames} />
    )}
  </ToggleableSection>
)

AddressSection.propTypes = {
  values: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  isOpened: PropTypes.bool,
  withoutNames: PropTypes.bool,
}

export default memo(AddressSection)
