import React, { memo, useCallback } from 'react'
import i18n from 'root/i18n'
import PropTypes from 'prop-types'
import { childrenPropType } from 'root/constants'

const DiamondsContext = React.createContext({ isEditable: false })

export function useDiamondsContext() {
  const context = React.useContext(DiamondsContext)
  if (!context) throw new Error('Diamonds compound components cannot be rendered outside the <Diamonds> component')
  return context
}

export const Diamonds = ({
  isEditable = false,
  items,
  showDeleted = true,
  highlightDeleted = true,
  children,
  doAction,
  className = '',
}) => {
  const columns = React.Children.toArray(children)
    .flatMap((child) => (child.type === React.Fragment ? child.props.children : child))
    .filter((child) => child)
  const renderLabel = useCallback(({ label }) => (label ? i18n.value(label) : ''), [])
  const itemValues = Array.isArray(items) ? items : Object.values(items)
  const orderItems = showDeleted ? itemValues : itemValues.filter((item) => !item.disabled)
  return (
    <DiamondsContext.Provider value={{ isEditable }}>
      <div className={`text-sm border-b border-gray-200 pb-2 ${className}`}>
        <div className="bg-gray-100 h-10 mb-2 px-2 flex items-center justify-between text-gray-500">
          {columns.map((column, index) => React.cloneElement(column, { key: `label_${index}` }, renderLabel))}
        </div>
        {orderItems.map((item, index) => (
          <div key={`row_${index}`} className="px-2 relative flex items-center justify-between text-gray-500">
            {columns.map((column) => React.cloneElement(column, { item, index, items: itemValues, doAction }))}
            {!!item.disabled && highlightDeleted && (
              <div className="absolute bg-brown-dim top-50 h-px left-0 right-0" />
            )}
          </div>
        ))}
      </div>
    </DiamondsContext.Provider>
  )
}

Diamonds.propTypes = {
  isEditable: PropTypes.bool,
  showDeleted: PropTypes.bool,
  highlightDeleted: PropTypes.bool,
  children: childrenPropType,
  className: PropTypes.string,
  doAction: PropTypes.func,
  items: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
}

export default memo(Diamonds)
