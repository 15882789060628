import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ className = '' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={`fill-current ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.4375 7.08333H16.5625V16.5625C16.5625 17.7706 15.5831 18.75 14.375 18.75H5.625C4.41688 18.75 3.4375 17.7706 3.4375 16.5625V7.08333ZM7.8125 15.1042V10H6.35417V15.1042H7.8125ZM10.7292 15.1042V10H9.27083V15.1042H10.7292ZM13.6458 15.1042V10H12.1875V15.1042H13.6458ZM18.0208 4.16667C18.4235 4.16667 18.75 4.49313 18.75 4.89583C18.75 5.29854 18.4235 5.625 18.0208 5.625H1.97917C1.57646 5.625 1.25 5.29854 1.25 4.89583C1.25 4.49313 1.57646 4.16667 1.97917 4.16667H6.35417V1.97917C6.35417 1.57646 6.68063 1.25 7.08333 1.25H12.9167C13.3194 1.25 13.6458 1.57646 13.6458 1.97917V4.16667H18.0208ZM7.8125 2.70833V4.16667H12.1875V2.70833H7.8125Z" />
  </svg>
)
SvgComponent.propTypes = {
  className: PropTypes.string,
}

export default memo(SvgComponent)
