import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import TextInfo
  from 'root/modules/orders/pages/Order/components/components/TextInfo'
import i18n from 'root/i18n'
import InputTermFormik
  from 'root/modules/orders/pages/components/InputTerm/formik'
import { FormikContext } from 'formik'
import Date
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'

import * as yup from 'yup'
import {
  childrenPropType,
  errorAlert,
  getValidate,
  trimInteger,
} from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import Responsible from '../../../components/Responsible'
import {
  DEAL_TYPE_INVOICE,
  DEAL_TYPE_MEMO,
} from 'root/modules/orders/constants'
import { downloadKycFile, postOrderMessage } from 'root/api'
import { Link } from 'react-router-dom'
import Button from 'root/components/Buttons/Button'
import { connect } from 'react-redux'
import { openChat } from 'root/modules/chat/actions'

const schema = yup.number().required().positive().integer().min(1).max(366)
const validate = getValidate(schema)

const GeneralTerms = ({
  showCounterparty = true,
  number,
  termsChanges,
  seller,
  buyer,
  date,
  validFor,
  editable = false,
  withoutManager = false,
  className = '',
  order,
  asSeller,
  showKYC = true,
  children,
  validDaysId = 'dealValidDays',
  validDaysLabel = 'order.labels.validFor',
}) => {
  const formContext = React.useContext(FormikContext)
  const isEditable = formContext !== undefined && editable

  const kycAllowed = showKYC && order.sellerCompanyId.allowKYC && (
    order.sellerCompanyId.requireKycOnInvoice && order.dealType ===
    DEAL_TYPE_INVOICE ||
    order.sellerCompanyId.requireKycOnMemo && order.dealType ===
    DEAL_TYPE_MEMO)
  const asBuyer = !asSeller
  const needProvideKyc = kycAllowed && asBuyer &&
    !order.buyerCompanyId.hasFullKycInfo
  const canRequestKycInfo = kycAllowed && asSeller &&
    !order.buyerCompanyId.hasFullKycInfo
  const canDownloadKyc = kycAllowed && asSeller &&
    order.buyerCompanyId.hasFullKycInfo

  const requestKyc = useCallback(() => {
    return postOrderMessage(order.id, i18n.value('orders.requestKycMessage')).
      then(() => {
        openChat()
      })
  }, [openChat, order.id])

  const downloadKyc = useCallback(() => {
    downloadKycFile(order.id).catch(errorAlert)
  }, [order.id])

  if (!formContext && editable) {
    console.warn(
      'You tried to use <GeneralTerms> in edit mode outside of form context')
  }
  return (
    <div className={`flex ${className}`}>
      <div>
        <div className="flex">
          {date && <Date value={date}/>}
          {(validFor || isEditable) && (
            <div className="flex ml-10">
              <InputTermFormik
                itemKey={validDaysId}
                value={validFor}
                isEditable={isEditable}
                inputClass="w-12"
                labelBefore={validDaysLabel}
                labelAfter="order.labels.days"
                validate={validate}
                onChange={(e) => {
                  formContext.setFieldValue(validDaysId,
                    trimInteger(e.target.value))
                }}
              />
              {termsChanges && termsChanges[validDaysId] && (
                <i className="text-brown-dim ml-1">
                  <ExclaimIcon width={15} height={15}/>
                </i>
              )}
            </div>
          )}
        </div>
        {showCounterparty && seller ? <TextInfo
          label="common.seller">{seller}</TextInfo> : null}
        {showCounterparty && buyer ? <TextInfo
          label="common.buyer">{buyer}</TextInfo> : null}
        {needProvideKyc ?
          <div>
            <Link to="/company/kyc-info" className="block my-2 flex-0">
              <Button className="bg-blue-skyblue" slim type="button"
                      label={'profile.buttons.kycInfo'}/>
            </Link>
          </div>
          : null}
        {canRequestKycInfo ?
          <Button className="bg-blue-skyblue my-2" slim type="button"
                  label="order.labels.requestKyc" onClick={requestKyc}/>
          : null}
        {canDownloadKyc ?
          <Button className="bg-blue-skyblue my-2" slim type="button"
                  label="order.labels.downloadKyc"
                  onClick={downloadKyc}/>
          : null}
        {children}
      </div>
      <div className="ml-auto">
        <TextInfo label="order.labels.type">{i18n.value(
          `orders.type.${order.dealType}`)}</TextInfo>
        {number && <TextInfo label="order.labels.number">{number}</TextInfo>}
        {withoutManager ? null : <Responsible/>}
      </div>
    </div>
  )
}

GeneralTerms.propTypes = {
  className: PropTypes.string,
  number: PropTypes.string,
  order: PropTypes.object,
  seller: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buyer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  date: PropTypes.string,
  validFor: PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string, PropTypes.bool]),
  editable: PropTypes.bool,
  termsChanges: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showCounterparty: PropTypes.bool,
  withoutManager: PropTypes.bool,
  children: childrenPropType,
  asSeller: PropTypes.bool.isRequired,
  showKYC: PropTypes.bool,
  openChat: PropTypes.func.isRequired,
  validDaysId: PropTypes.string,
  validDaysLabel: PropTypes.string,
}
export default memo(connect(null, { openChat })(GeneralTerms))
