import React, { memo } from 'react'
import PropTypes from 'prop-types'
import InvoiceRow from './components/InvoiceRow'

const InvoiceList = ({ order, action, isSubmitting, handleSubmit, setFieldValue, isSender, readOnly }) => {
  //filter only those to track which are for this particular section
  return (
    <>
      {order.documents.map((document) => {
        return (
          document.type === 'invoice' && (
            <div className={'my-8'} key={document.id}>
              <InvoiceRow
                order={order}
                document={document}
                action={action}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                isSender={isSender}
                readOnly={readOnly}
              />
            </div>
          )
        )
      })}
    </>
  )
}

InvoiceList.propTypes = {
  order: PropTypes.object.isRequired,
  isSender: PropTypes.bool,
  action: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(InvoiceList)
