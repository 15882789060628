import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { ErrorMessage, Form, Formik } from 'formik'
import Input from 'root/modules/activation/pages/Activation/components/Input'
import BlueButton from 'root/components/Buttons/BlueButton'
import BrownButton from 'root/components/Buttons/BrownButton'
import { COUNTRY_INFO, DEVICE_CODE, errorAlert, toClassName } from 'root/constants'
import { activateThirdStep } from 'root/api'
import * as Yup from 'yup'

const Step = ({ nextStep, goBack, user }) => (
  <div className="mt-12">
    <div className="text-brown-dim text-lg">{i18n.value('activation.labels.tellUsAboutYou')}</div>
    <div className="mt-10">
      <Formik
        enableReinitialize
        validationSchema={Yup.object().shape({
          zipCode: Yup.string().trim().required(),
          city: Yup.string().trim().required(),
          region: Yup.string().trim(),
        })}
        onSubmit={(values) => {
          return activateThirdStep({
            countryId: values.country,
            regionId: values.region,
            cityId: values.city,
            zipCodeId: values.zipCode,
          })
            .then(() => {
              window.location.reload()
            })
            .catch(errorAlert)
        }}
        initialValues={{
          country: DEVICE_CODE,
          region: '',
          city: '',
          zipCode: '',
        }}
      >
        {({ values, isValid, submitCount, setFieldValue, isSubmitting, handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit} className="flex flex-col space-y-20">
            <div className="w-1/2 mb:w-full space-y-10">
              <Input itemKey="country" label="order.labels.address.countryId">
                {({ field, textColor, inputClass }) => (
                  <select
                    {...field}
                    className={toClassName([
                      'form-select w-full mb:w-64 text-left  shadow shadow-inset px-2 py-0',
                      inputClass,
                      textColor,
                    ])}
                  >
                    {COUNTRY_INFO.map(({ code, name }) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </select>
                )}
              </Input>
              <Input itemKey="region" label="order.labels.address.regionName" />
              <Input itemKey="city" label="order.labels.address.cityName" />
              <Input itemKey="zipCode" label="order.labels.address.zipCode" />
            </div>
            <div className="mt-32">
              {isValid || submitCount === 0 ? (
                ''
              ) : (
                <section className="w-1/2 mb:w-full my-4 bg-red-300 text-red-600">
                  {Object.keys(errors).map(
                    (key) =>
                      errors[key] && (
                        <div className="flex space-x-4" key={key}>
                          <div>{key}:</div>
                          <ErrorMessage name={key} component="div" />
                        </div>
                      ),
                  )}
                </section>
              )}
              <div className="flex space-x-4 ">
                <BlueButton type="button" label="activation.labels.back" onClick={goBack} />
                <BrownButton disabled={isSubmitting} label="activation.labels.finish" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
)
Step.propTypes = {
  nextStep: PropTypes.func,
  goBack: PropTypes.func,
  user: PropTypes.object.isRequired,
}
export default memo(Step)
