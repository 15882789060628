import React, { memo } from 'react'
import i18n from 'root/i18n'
import UploadedFilesList from 'root/modules/orders/pages/components/UploadedFilesList'
import PropTypes from 'prop-types'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import ViewMemoDocs from 'root/components/ViewMemoDocs'

const ViewAgreementMemoDocs = ({ memoAgreement }) => {
  const supplementaryDocs = []
  memoAgreement.orders.forEach(({ documents, number }) => {
    if (documents?.length > 0) {
      const [{ files, id }] = documents
      if (files?.length > 0) {
        supplementaryDocs.push(
          <div className={'mt-8'} key={id}>
            <OrderHeading label={i18n.value('orders.labels.memoSupplementaryFilesList') + ' #' + number} />
            <UploadedFilesList files={files} documentId={id} />
          </div>,
        )
      }
    }
  })

  return <ViewMemoDocs memo={memoAgreement} supplementaryDocs={supplementaryDocs} />
}

ViewAgreementMemoDocs.propTypes = {
  memoAgreement: PropTypes.object.isRequired,
}

export default memo(ViewAgreementMemoDocs)
