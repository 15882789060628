import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import ReceiptIcon from 'root/icons/ReceiptIcon'

const InitialTermsButton = ({ onClick, className = '' }) => (
  <button
    type="button"
    onClick={onClick}
    className={`cursor-pointer flex items-center justify-center text-blue-skyblue text-lg font-medium ${className}`}
  >
    <ReceiptIcon width={24} height={24} />
    <span className="ml-2">{i18n.value('orders.buttons.showInitialTerms')}</span>
  </button>
)

InitialTermsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default memo(InitialTermsButton)
