import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { toClassName } from 'root/constants'
import i18n from 'root/i18n'
import Balloon from 'root/components/Balloon'

const TabButton = ({ item, isActive, notifications, ...rest }) => (
  <span
    {...rest}
    className={toClassName([
      'relative inline-flex items-center justify-center w-auto px-8 py-2 mb-2 text-xs text-white cursor-pointer hover:bg-brown-dim hover:shadow-sm active:shadow-none',
      isActive ? 'bg-brown-dim' : 'bg-brown-dim-light',
    ])}
  >
    {i18n.value('orders.type.label.' + item)}
    {notifications ? (
      <span className="absolute right-0 top-0 -mr-2">
        <Balloon className="border-white border-2">{notifications}</Balloon>
      </span>
    ) : null}
  </span>
)

TabButton.propTypes = {
  item: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  notifications: PropTypes.string,
}

export default memo(TabButton)
