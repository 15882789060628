import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.80061 6.55078H15.7339L18.5339 10.0508L11.7673 19.0508L5.00061 10.0508L7.80061 6.55078Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00061 10.0508H18.5339"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6006 6.55078L9.20056 10.0508L11.7672 19.0508L14.3339 10.0508L12.9339 6.55078"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.94831 23.0601C9.10987 22.8926 8.27749 22.6247 7.46679 22.2507C1.89343 19.6796 -0.544952 13.0671 2.0204 7.48126C4.58576 1.89543 11.1835 -0.548409 16.7568 2.02269C22.3302 4.59378 24.7686 11.2063 22.2032 16.7921C22.1734 16.8572 22.1461 16.9204 22.115 16.9846"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.0419 13.3672L21.9459 17.4619L26.0005 15.4884"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.567 20.3945C17.7341 22.0549 15.3964 23.0525 12.9506 23.2404"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="16.15 16.15"
    />
  </svg>
)

export default memo(SvgComponent)
