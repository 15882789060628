import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="24"
    height="17"
    viewBox="0 0 24 17"
    className="text-brown-dim"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 0.923077L12 7.07692C12 7.58672 11.4963 8 10.875 8C10.3055 8 9.83476 7.65273 9.76027 7.20218L9.75 7.07692L9.75 4.92212L1.125 4.92308C0.503679 4.92308 0 4.5098 0 4C0 3.53268 0.423231 3.14647 0.972344 3.08535L1.125 3.07692L9.75 3.07597V0.923077C9.75 0.455759 10.1732 0.0695496 10.7223 0.0084266L10.875 0C11.4445 0 11.9152 0.347266 11.9897 0.797821L12 0.923077Z" />
    <path d="M13 13.5C13 14.2594 12.6139 14.887 12.1129 14.9863L11.9737 15L1.02632 15C0.459497 15 0 14.3284 0 13.5C0 12.7406 0.386106 12.113 0.887051 12.0137L1.02632 12L11.9737 12C12.5405 12 13 12.6716 13 13.5Z" />
    <path d="M24 4.5C24 5.25939 23.6238 5.88698 23.1357 5.98631L23 6L15 6C14.4477 6 14 5.32843 14 4.5C14 3.74061 14.3762 3.11302 14.8643 3.01369L15 3L23 3C23.5523 3 24 3.67157 24 4.5Z" />
    <path d="M24 13C24 13.4673 23.6238 13.8535 23.1357 13.9147L23 13.9231L18 13.9221V16.0769C18 16.5442 17.6238 16.9305 17.1357 16.9916L17 17C16.4937 17 16.0753 16.6527 16.0091 16.2022L16 16.0769L16 9.92308C16 9.41328 16.4477 9 17 9C17.5063 9 17.9247 9.34727 17.9909 9.79782L18 9.92308V12.076L23 12.0769C23.5523 12.0769 24 12.4902 24 13Z" />
  </svg>
)

export default memo(SvgComponent)
