import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ClockIcon } from 'root/icons'
import i18n from 'root/i18n'
import { ensureBoolean } from 'root/constants'

const OrderTimer = ({ timer, label = '' }) => {
  // if (!timer) return null

  let timerString = (
    <div className="flex whitespace-no-wrap">
      {label ? <label className="text-brown-dim mr-2">{i18n.value(label, null, label)}</label> : ''}
      <div className="flex justify-center items-center text-sm border px-1 rounded-sm bg-gray-100 text-gray-500 border-gray-300">
        <div className="mr-1 flex">
          <i className={'mr-2'}>
            <ClockIcon width={15} height={15} />
          </i>
        </div>
        <span>--:--:--</span>
      </div>
    </div>
  )

  if (timer?.isStarted) {
    let bgColor = ' bg-brown-lightest'
    let fontColor = ' text-brown-dim'
    let borderColor = ' border-brown-dim'
    if (ensureBoolean(timer.isExpired) || (!timer.daysLeft && timer.hoursLeft > 0 && timer.hoursLeft <= 6)) {
      bgColor = ' bg-red-200'
      fontColor = ' text-red-600'
      borderColor = ' border-red-600'
    }
    timerString = (
      <div className="flex whitespace-no-wrap">
        {label ? <label className="text-brown-dim mr-2">{i18n.value(label, null, label)}</label> : ''}
        <div
          className={
            'flex justify-center items-center text-sm border px-1 rounded-sm ' + bgColor + borderColor + fontColor
          }
        >
          <div className="mr-1 flex">
            <ClockIcon width={15} height={15} />
          </div>
          <span>
            {timer.daysLeft > 0
              ? timer.daysLeft.toString().padStart(2, '0') + ' ' + i18n.value('common.days')
              : timer.hoursLeft.toString().padStart(2, '0') + ':' + timer.minutesLeft.toString().padStart(2, '0')}
          </span>
        </div>
      </div>
    )
  }

  return <span>{timerString}</span>
}

OrderTimer.propTypes = {
  timer: PropTypes.object,
  label: PropTypes.string,
}

export default memo(OrderTimer)
