import React, { memo } from 'react'
import PropTypes from 'prop-types'
import UploadedFilesList
  from 'root/modules/orders/pages/components/UploadedFilesList'
import EmptyFormWithActions
  from 'root/modules/orders/pages/components/EmptyOrderFormWithActions'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import Diamonds, {
  CertificateColumn,
  IndexColumn,
  NameColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'
import ReadonlyMemoTerms
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ReadonlyMemoTerms'
import OrderTimer from 'root/components/OrderTimer'

const NewMemoUploaded = ({ order, performOrderAction, readOnly = false }) => {
  let buttons = [
    {
      color: 'brown',
      label: 'orders.buttons.confirm',
      action: 'deal_memo_negotiation',
    },
    {
      color: 'blue',
      label: 'orders.buttons.reject',
      action: 'new_memo_docs_refused',
    },
  ]

  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          seller={order.sellerCompanyId.name}
          asSeller={false}
        >
          <ViewOrderFiles order={order} doAction={performOrderAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds isEditable={false} showDeleted={false} items={order.products}>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
        </Diamonds>
      </section>

      <section>
        <ReadonlyMemoTerms order={order}/>
      </section>

      <section>
        <div className="flex space-x-4">
          <div className="text-brown-dim">
            <DocumentRowIcon width={50} height={50}/>
          </div>
          <div className="text-lg text-brown-dim">
            <b>Please, review uploaded Consignment Agreement</b>
            <br/>
            If you need to apply any changes, you can Reject the document and
            wait for supplier to upload a new version.
          </div>
        </div>
        <UploadedFilesList files={order.memoId.files}
                           documentId={order.memoId.id} isMemo/>
      </section>

      <section>
        <div className="mb-4">
          {order.timer.isStarted
            ? <OrderTimer timer={order.timer}
                          label={'orders.labels.replyTimer'}/>
            : ''}
        </div>
        <EmptyFormWithActions buttons={buttons}
                              performOrderAction={performOrderAction}/>
      </section>
    </>
  )
}

NewMemoUploaded.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(NewMemoUploaded)
