import React, { memo } from 'react'
import Column from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds/Column'
import OrderTimer from 'root/components/OrderTimer'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import PropTypes from 'prop-types'
import { ACTION_MEMO_PAD_REQUEST_PAYMENT_FROM_BUYER } from 'root/modules/orders/constants'

const NonReturnColumn = ({ children, isSubmitting, handleSubmit, setFieldValue, ...other }) => (
  <Column
    label={'order.diamonds.timer.memoPeriod'}
    columnClass="w-32 text-center"
    className="w-32 text-center text-brown-dim flex justify-center items-center"
    {...other}
  >
    {children ||
      (({ item }) => {
        return item.timer?.memoPeriod?.isExpired ? (
          <FormikSubmittingButtons
            slim
            buttons={[
              {
                color: 'brown',
                label: 'orders.memo.buttons.requestPayment',
                action: ACTION_MEMO_PAD_REQUEST_PAYMENT_FROM_BUYER,
                params: {
                  items: [item.id],
                },
                className: 'm-2 py-1 whitespace-no-wrap',
                style: { paddingLeft: 10, paddingRight: 10 },
              },
            ]}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
          />
        ) : item.timer.memoPeriod ? (
          <OrderTimer timer={item.timer.memoPeriod} />
        ) : (
          ''
        )
      })}
  </Column>
)
NonReturnColumn.propTypes = {
  ...Column.propTypes,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}
export default memo(NonReturnColumn)
