import { COLORS } from 'root/constants'

export default {
  badge: {
    display: 'flex',
    minWidth: 15,
    height: 15,
    borderRadius: 100,
    paddingRight: 4,
    paddingLeft: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.SEA,
  },
  count: {
    textAlign: 'center',
    fontSize: 8,
    color: COLORS.WHITE,
  },
}
