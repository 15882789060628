import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="text-gray-400"
    {...props}
  >
    <path
      d="M16.1579 1H3.84211C2.27245 1 1 2.27245 1 3.84211V16.1579C1 17.7275 2.27245 19 3.84211 19H16.1579C17.7275 19 19 17.7275 19 16.1579V3.84211C19 2.27245 17.7275 1 16.1579 1Z"
      fill="white"
    />
    <path
      d="M16.1578 1.47266H3.84205C2.53401 1.47266 1.47363 2.53303 1.47363 3.84108V16.1569C1.47363 17.4649 2.53401 18.5253 3.84205 18.5253H16.1578C17.4659 18.5253 18.5263 17.4649 18.5263 16.1569V3.84108C18.5263 2.53303 17.4659 1.47266 16.1578 1.47266Z"
      stroke="currentColor"
    />
  </svg>
)

export default memo(SvgComponent)
