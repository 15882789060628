import axios from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'
import { version } from 'root/../package.json'
import { AUTH_API_URL, BACKOFFICE_API_URL, CORE_API_URL } from 'root/api/env'
import FileDownload from 'js-file-download'
import mime from 'mime-types'
import { getStatusCode, getDeviceId } from 'root/constants'
import { DEVICE_LANG } from 'root/i18n'

const baseHeaders = {
  'AUTH-TOKEN-TYPE': 'api',
  'API-VERSION': version,
  'API-LANGUAGE': DEVICE_LANG,
  'API-CLIENT-APP': 'LGDEAL-WEB-APP',
  'API-CLIENT-ID': getDeviceId(),
  'API-CLIENT-USER-AGENT': navigator.userAgent,
  'API-CLIENT-DEVICE-NAME': navigator.platform,
}

const localInstance = axios.create({ timeout: 10000000, headers: baseHeaders })

axiosRetry(localInstance, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => Math.min(retryCount, 5) * 500,
  retryCondition: (error) => isNetworkOrIdempotentRequestError(error) && getStatusCode(error) !== 500,
})

const handleFileDownload = ({ data, headers }) => {
  let fileName
  if (headers['content-disposition']) {
    fileName = headers['content-disposition'].split('; ')[1].split('=')[1]
  } else {
    const mimeType = headers['content-type']
    let extension = mime.lookup(mimeType)
    if (!extension) {
      //TODO: fulfill JSON for MS from here - https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
      if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        extension = 'docx'
      } else if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        extension = 'xlsx'
      }
    }
    fileName = 'file.' + extension
  }
  return FileDownload(data, fileName)
}

const getInstance = ({ authServer, coreServer, auth, upload, downloadFile, ...config }) => {
  //todo: make a dictionary and pass string constant in here
  let baseURL = BACKOFFICE_API_URL
  if (authServer) baseURL = AUTH_API_URL
  else if (coreServer) baseURL = CORE_API_URL
  config = { headers: [], ...config, baseURL, withCredentials: true }

  if (upload) {
    config.headers['content-type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('fd-file', upload)
    config.data = formData
  } else if (downloadFile) config.responseType = 'blob'

  let instance = localInstance(config)
  if (downloadFile) instance = instance.then(handleFileDownload)
  return instance
}

export default getInstance
