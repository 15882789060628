import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import OrderSubHeader from 'root/components/OrderSubHeader'
import OrderTimer from 'root/components/OrderTimer'
import Diamonds, {
  CertificateColumn,
  ChangesColumn,
  DeliveryColumn,
  EditColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  RemoveColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import UpdateTermsForm
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/UpdateTermsForm'
import PaymentTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/PaymentTermsSection'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'
import { useAsyncLoad } from 'root/hooks'
import i18n from 'root/i18n'
import {
  ACTION_REJECT,
  ACTION_START_DEAL,
  STATE_PRODUCTS_APPROVAL,
} from 'root/modules/orders/constants'
import { ErrorMessage, Form } from 'formik'
import DeliveryPrice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/DeliveryPrice'
import AddressSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/AddressSection'
import ReturnTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/ReturnTermsSection'
import SpecialTermsSection
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/SpecialTermsSection'
import AverageR
  from 'root/modules/orders/pages/Order/components/components/AverageR'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import { promptAlert } from 'root/components/AlertWrapper'
import { doNothing } from 'root/constants'
import { connect } from 'react-redux'
import { isAdmin, isMiddleman } from 'root/selectors'

const DealRequestSeller = ({
  showCounterparty,
  order,
  doAction,
  readOnly = false,
}) => {
  const requestApprovedBySeller = order.state.key === STATE_PRODUCTS_APPROVAL
  const canReject = !readOnly &&
    order.actions.some((action) => action.url === ACTION_REJECT)
  const canConfirm = !readOnly &&
    order.actions.some((action) => action.url === ACTION_START_DEAL)
  const [loading, submitAction] = useAsyncLoad(doAction)
  const rejectOrder = useCallback(async () => {
    try {
      const reason = await promptAlert(
        i18n.value('order.labels.provideReasonForCancel'))
      if (reason) {
        submitAction(ACTION_REJECT, { reason })
      }
    } catch (e) {
      doNothing()
    }
  }, [submitAction])
  return (
    <UpdateTermsForm order={order} doAction={doAction}
                     actionName={ACTION_START_DEAL}>
      {({ values, isSubmitting, handleSubmit, errors }) => (
        <Form onSubmit={handleSubmit} className="flex flex-col space-y-12">
          {!requestApprovedBySeller && (
            <section className="flex">
              <OrderSubHeader label="order.labels.generalTerms"/>
              <div className="ml-auto">
                {order.timer.isStarted && <OrderTimer timer={order.timer}
                                                      label={'orders.labels.replyTimer'}/>}
              </div>
            </section>
          )}
          <section>
            <GeneralTerms
              order={order}
              showCounterparty={showCounterparty}
              number={order.number}
              date={order.date}
              editable={canConfirm}
              validFor={canConfirm ? values.dealValidDays : undefined}
              buyer={order.buyerCompanyId.name}
              showKYC={false}
              asSeller
            >
              <ViewOrderFiles order={order} doAction={doAction}/>
            </GeneralTerms>
          </section>
          <section>
            <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
            <Diamonds
              isEditable={canConfirm}
              highlightDeleted
              showDeleted
              items={values.orderItems}
              doAction={doAction}
            >
              <ChangesColumn/>
              <IndexColumn/>
              <ShapeColumn/>
              <NameColumn/>
              <CertificateColumn/>
              <RefPriceColumn isEditable={false}/>
              <RapaportColumn isEditable={false}/>
              <PriceColumn isEditable={false}/>
              <DeliveryColumn/>
              {canConfirm && <EditColumn/>}
              {canConfirm && <RemoveColumn/>}
            </Diamonds>
            <div className="flex justify-between mt-4">
              <div className="flex w-1/2 ml-24">
                <AverageR items={order.products} isEditable={false}/>
              </div>
              <div className="flex w-1/2 pl-12">
                <DeliveryPrice isEditable={canConfirm} order={order}/>
              </div>
            </div>
          </section>
          {canConfirm && (
            <>
              <section>
                <PaymentTermsSection isEditable values={values}/>
              </section>
              <section>
                <AddressSection values={values} withoutNames/>
              </section>
              <section>
                <ReturnTermsSection isEditable values={values}/>
              </section>
              <section>
                <SpecialTermsSection isEditable values={values}/>
              </section>
            </>
          )}
          {requestApprovedBySeller && (
            <section>
              <OrderSubHeader label="order.labels.dealRequestStatus"
                              className="mb-4"/>
              <div className="text-gray-500">{i18n.value(
                'orders.labels.waitForCustomerToConfirmChanges')}</div>
              {order.timer.isStarted && (
                <div className="mt-8">
                  <OrderTimer timer={order.timer}
                              label={'orders.labels.replyTimerCustomer'}/>
                </div>
              )}
            </section>
          )}
          {Object.keys(errors).length === 0 ? (
            ''
          ) : (
            <section className="my-4 bg-red-300 text-red-600">
              {Object.keys(errors).map((key) => (
                <div className="flex space-x-4" key={key}>
                  <div>{key}:</div>
                  {Array.isArray(errors[key]) &&
                    <ErrorMessage name={key} component="div"/>}
                </div>
              ))}
            </section>
          )}
          <section className="flex space-x-4">
            {canConfirm && <BrownButton disabled={loading || isSubmitting}
                                        label="orders.buttons.startTheDeal"/>}
            {readOnly ? null : (
              <BlueButton
                disabled={loading || isSubmitting || !canReject}
                onClick={rejectOrder}
                type="button"
                label="orders.buttons.cancelDeal"
              />
            )}
          </section>
        </Form>
      )}
    </UpdateTermsForm>
  )
}

DealRequestSeller.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showCounterparty: PropTypes.bool,
}

export default memo(
  connect(
    (state) => ({ showCounterparty: isAdmin(state) || isMiddleman(state) }),
    null)(DealRequestSeller),
)
