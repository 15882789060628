import React, { memo } from 'react'
import Column from './Column'
import Price from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Price'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import { calcAverageR, calcRefPriceDiscount, priceSchema, roundTwo } from 'root/modules/orders/constants'
import { trimNumber } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import { COLOR_WHITE } from 'root/modules/products/constants'

const validatePrice = (price, refPrice) => {
  let error
  if (!price) {
    error = 'Price value is required'
  } else if (!refPrice) {
    error = 'Reference price value is required'
  } else if (price > refPrice) {
    error = `Price can\'t be more than reference price`
  }
  return error
}

const PriceColumn = ({ children, itemKey = 'orderItems', averageRKey = 'averageR', ...props }) => (
  <Column
    label="order.diamonds.price"
    columnClass="w-24 text-center"
    className="w-24 pl-6 flex text-blue-dark whitespace-no-wrap"
    {...props}
  >
    {children ||
      (({ item, isEditable, formContext }) =>
        isEditable ? (
          <div className="flex items-center">
            {item.unitPrice?.symbol || '$'}
            <InputTermFormik
              itemKey={`${itemKey}['${item.id}'].price`}
              value={item.price}
              isEditable={isEditable}
              inputClass="w-16 text-sm"
              onChange={async (e) => {
                const { values, setFieldValue } = formContext
                setFieldValue(`${itemKey}['${item.id}'].price`, trimNumber(e.target.value))
                const price = Number.parseFloat(e.target.value)
                // eslint-disable-next-line eqeqeq
                if (item.colorType?.id == COLOR_WHITE && (await priceSchema.isValid(price))) {
                  const { orderItems } = values
                  const referencePrice = Number.parseFloat(orderItems[item.id].referencePrice)
                  if (!referencePrice) return

                  const referenceDiscount = roundTwo(calcRefPriceDiscount(price, referencePrice))
                  orderItems[item.id].referenceDiscount = referenceDiscount
                  setFieldValue(`${itemKey}['${item.id}'].referenceDiscount`, referenceDiscount)
                  setFieldValue(averageRKey, calcAverageR(orderItems))
                }
              }}
              validate={() => {
                const { values } = formContext
                const orderItems = values[itemKey]
                if (!orderItems || !orderItems[item.id]) {
                  return
                }
                const price = Number.parseFloat(orderItems[item.id].price)
                // eslint-disable-next-line eqeqeq
                const isWhite = item.colorType?.id == COLOR_WHITE
                const referencePrice = isWhite ? Number.parseFloat(orderItems[item.id].referencePrice) : price

                return validatePrice(price, referencePrice)
              }}
            />
          </div>
        ) : (
          <div className="flex">
            <Price value={item.price} currency={item.currency || 'USD'} />
            {item?.termsChanges?.unitPrice && (
              <i className="ml-1 text-brown-dim">
                <ExclaimIcon width={12} height={12} />
              </i>
            )}
          </div>
        ))}
  </Column>
)

PriceColumn.propTypes = Column.propTypes

export default memo(PriceColumn)
