import React, { memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import i18n from 'root/i18n'
import { useEffectOnce } from 'root/hooks'
import Modal from 'root/components/Modal'
import Spinner from 'root/components/Spinner'

import { getAddresses, removeAddress } from 'root/api'
import AddressCard from './components/AddressCard'
import LocationIcon from 'root/icons/LocationIcon'
import { doNothing } from 'root/constants'
import { confirmAlert } from 'root/components/AlertWrapper'
import BlueButton from 'root/components/Buttons/BlueButton'

const CompanyAddressesModal = () => {
  const [addresses, setAddresses] = useState()

  useEffectOnce(() => {
    getAddresses().then(({ data: { data } }) => setAddresses(data))
  }, [])

  const onDelete = useCallback(
    (id) => (e) => {
      e.stopPropagation()
      return confirmAlert(i18n.value('profile.addresses.delete'))
        .then(() => removeAddress(id))
        .then(() =>
          setAddresses((addresses) => {
            addresses = [...addresses]
            addresses.splice(
              addresses.findIndex((address) => address.id === id),
              1,
            )
            return addresses
          }),
        )
        .catch(doNothing)
    },
    [],
  )
  const history = useHistory()

  const onClick = useCallback((id) => () => history.push(`/company/addresses/${id}`), [history])

  return (
    <Modal>
      <div className="profile-my-team-modal flex flex-1 flex-col">
        <div className="profile-my-team items-baseline">
          <LocationIcon />
          <span>{i18n.value('profile.addresses.title')}</span>
        </div>
        {!addresses ? (
          <div className="flex flex-1 items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex-1 my-6 text-sm text-gray-500 overflow-y-auto" style={{ maxHeight: 500 }}>
            {addresses.length > 0
              ? addresses.map((address) => (
                  <AddressCard key={address.id} address={address} onDelete={onDelete} onClick={onClick} />
                ))
              : i18n.value('profile.addresses.empty')}
          </div>
        )}

        <div className="flex justify-between">
          <BlueButton onClick={onClick('create')} type={'button'} label={'common.add'} />
        </div>
      </div>
    </Modal>
  )
}

CompanyAddressesModal.propTypes = {}

export default memo(CompanyAddressesModal)
