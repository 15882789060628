import React, { memo } from 'react'
import { TeamIcon } from 'root/icons'
import './styles.css'
import PropTypes from 'prop-types'

const MyTeam = ({ className, ...props }) => (
  <div className={'profile-my-team ' + (className || '')} {...props}>
    <TeamIcon />
    <span>My team</span>
  </div>
)

MyTeam.propTypes = {
  className: PropTypes.string,
}

export default memo(MyTeam)
