import React, { memo, useCallback, useEffect, useState } from 'react'
import connect from './connect'
import PropTypes from 'prop-types'
import Spinner from 'root/components/Spinner'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'
import * as Yup from 'yup'
import i18n from 'root/i18n'
import { Form, Formik } from 'formik'
import { ACTION_SAVE_CREDIT_INFO } from 'root/modules/profile/constants'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import DeliveryAddress, { getAddressValidationSchema } from 'root/modules/orders/pages/components/DeliveryAddress'
import RequiredLabel from 'root/components/RequiredLabel'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import OrderSubHeader from 'root/components/OrderSubHeader'
import ToggleableSection from 'root/modules/orders/pages/components/ToggleableSection'
import { UpDownSprite } from 'root/icons'
import FormikToggle from 'root/components/FormikToggle'
import TextLabel from 'root/components/TextLabel'
import CheckIcon from 'root/icons/CheckIcon'
import OrderSpecialTermsFormik from 'root/modules/orders/pages/components/OrderSpecialTerms/formik'
import { Link, useHistory } from 'react-router-dom'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import { ensureBoolean } from 'root/constants'
import MessageBox from 'root/components/MessageBox'

const FIELD_LABELS = {
  taxId: i18n.value('profile.company.labels.taxId'),
  creditCompanyName: i18n.value('profile.company.labels.creditCompanyName'),
  creditPhoneNumber: i18n.value('profile.company.labels.creditPhoneNumber'),
  bankName: i18n.value('profile.company.labels.bankName'),
  bankAccountName: i18n.value('profile.company.labels.bankAccountName'),
  bankAccountNumber: i18n.value('profile.company.labels.bankAccountNumber'),
  bankRoutingNumber: i18n.value('profile.company.labels.bankRoutingNumber'),
  bankSwiftCode: i18n.value('profile.company.labels.bankSwiftCode'),
  bankAddress: i18n.value('profile.company.labels.bankAddress'),
  corrBankName: i18n.value('profile.company.labels.corrBankName'),
  corrBankAccountNumber: i18n.value('profile.company.labels.corrBankAccountNumber'),
  corrBankSwift: i18n.value('profile.company.labels.corrBankSwift'),
  corrBankAddress: i18n.value('profile.company.labels.corrBankAddress'),
  supervisorName: i18n.value('profile.company.labels.supervisorName'),
  supervisorEmail: i18n.value('profile.company.labels.supervisorEmail'),
  supervisorTitle: i18n.value('profile.company.labels.supervisorTitle'),
  supervisorPhone: i18n.value('profile.company.labels.supervisorPhone'),
  creditComment: i18n.value('profile.company.labels.creditComment'),
}

const CreditInformationSchemaBuyer = Yup.object().shape({
  creditComment: Yup.string().max(500),
  taxId: Yup.string().trim().required('TIN/VAT/EIN is required'),
  creditCompanyName: Yup.string().max(100),
  legalAddress: getAddressValidationSchema(true, false),
  actualAddress: getAddressValidationSchema(true, false),
  creditPhoneNumber: Yup.string().max(100),
  bankName: Yup.string().max(100),
  bankAccountName: Yup.string().max(100),
  bankAccountNumber: Yup.string().max(100),
  bankSwiftCode: Yup.string().max(100),
  supervisorName: Yup.string().max(100),
  supervisorEmail: Yup.string().max(100),
  supervisorTitle: Yup.string().max(100),
  supervisorPhone: Yup.string().max(100),
})

const CreditInformationSchemaSeller = Yup.object().shape({
  creditComment: Yup.string().max(500),
  taxId: Yup.string().trim().required('TIN/VAT/EIN is required'),
  creditCompanyName: Yup.string()
    .required(FIELD_LABELS['creditCompanyName'] + ' is required')
    .max(100),
  legalAddress: getAddressValidationSchema(true),
  actualAddress: getAddressValidationSchema(true, false),
  creditPhoneNumber: Yup.string()
    .required(FIELD_LABELS['creditPhoneNumber'] + ' is required')
    .max(100),
  bankName: Yup.string()
    .required(FIELD_LABELS['bankName'] + ' is required')
    .max(100),
  bankAccountName: Yup.string()
    .required(FIELD_LABELS['bankAccountName'] + ' is required')
    .max(100),
  bankAccountNumber: Yup.string()
    .required(FIELD_LABELS['bankAccountNumber'] + ' is required')
    .max(100),
  bankSwiftCode: Yup.string()
    .required(FIELD_LABELS['bankSwiftCode'] + ' is required')
    .max(100),
  supervisorName: Yup.string()
    .required(FIELD_LABELS['supervisorName'] + ' is required')
    .max(100),
  supervisorEmail: Yup.string()
    .required(FIELD_LABELS['supervisorEmail'] + ' is required')
    .max(100),
  supervisorTitle: Yup.string()
    .required(FIELD_LABELS['supervisorTitle'] + ' is required')
    .max(100),
  supervisorPhone: Yup.string()
    .required(FIELD_LABELS['supervisorPhone'] + ' is required')
    .max(100),
})
const submittingButtons = [
  { color: 'brown', label: 'common.save', action: ACTION_SAVE_CREDIT_INFO, className: 'profile-button' },
]

const bankFields = [
  { key: 'bankAccountName', isRequired: true },
  { key: 'bankName', isRequired: true },
  { key: 'bankAccountNumber', isRequired: true },
  { key: 'bankRoutingNumber', isRequired: false },
  { key: 'bankSwiftCode', isRequired: true },
  { key: 'bankAddress', isRequired: false },
]

const corrBankFields = [
  { key: 'corrBankName', isRequired: false },
  { key: 'corrBankAccountNumber', isRequired: false },
  { key: 'corrBankSwift', isRequired: false },
  { key: 'corrBankAddress', isRequired: false },
]

const supervisorFields = [
  { key: 'supervisorName', isRequired: true },
  { key: 'supervisorEmail', isRequired: true },
  { key: 'supervisorPhone', isRequired: true },
  { key: 'supervisorTitle', isRequired: true },
]

const CreditInformation = ({
  data,
  isLoading,
  loadCreditInfo,
  saveCreditInfo,
  loadCompanyCommonInfo,
  canSupervise,
}) => {
  useEffect(() => {
    loadCreditInfo()
  }, [loadCreditInfo])

  const history = useHistory()

  const [editing, setEditing] = useState(false)
  const toggleEditing = useCallback(() => setEditing((editing) => !editing), [])

  if (isLoading || !data.id) return <Spinner />

  const initialValues = {
    ...data,
    creditComment: data.creditComment || '',
    creditCompanyName: data.creditCompanyName || data.name || '',
    creditPhoneNumber: data.creditPhoneNumber || '',
    taxId: data.taxId || '',
    bankAccountName: data.bankAccountName || '',
    bankName: data.bankName || '',
    bankAccountNumber: data.bankAccountNumber || '',
    bankRoutingNumber: data.bankRoutingNumber || '',
    bankSwiftCode: data.bankSwiftCode || '',
    bankAddress: data.bankAddress || '',
    corrBankName: data.corrBankName || '',
    corrBankAccountNumber: data.corrBankAccountNumber || '',
    corrBankSwift: data.corrBankSwift || '',
    corrBankAddress: data.corrBankAddress || '',
    supervisorName: data.supervisorName || '',
    supervisorEmail: data.supervisorEmail || '',
    supervisorTitle: data.supervisorTitle || '',
    supervisorPhone: data.supervisorPhone || '',
    legalAddress: {
      ...data.legalAddress,
      companyName: data.legalAddress.companyName || data.name || '',
      countryId: data.legalAddress.countryId || data.countryId?.code || '',
      cityName: data.legalAddress.cityName || data.cityId?.name || '',
      regionName: data.legalAddress.regionName || data.regionId?.name || '',
      zipCode: data.legalAddress.zipCode || data.zipCodeId?.zipCode || '',
      addressLine1: data.legalAddress.addressLine1 || '',
      addressLine2: data.legalAddress.addressLine2 || '',
    },
    actualAddress: {
      ...data.actualAddress,
      companyName: data.actualAddress.companyName || data.name || '',
      countryId: data.actualAddress.countryId || data.countryId?.code || '',
      cityName: data.actualAddress.cityName || data.cityId?.name || '',
      regionName: data.actualAddress.regionName || data.regionId?.name || '',
      zipCode: data.actualAddress.zipCode || data.zipCodeId?.zipCode || '',
      addressLine1: data.actualAddress.addressLine1 || '',
      addressLine2: data.actualAddress.addressLine2 || '',
    },
    vatApplied: ensureBoolean(data.vatApplied), //yes I need here cast typing for string/int from server
    action: '',
  }
  // for now everybody can edit credit info
  const canEditCreditInfo = true

  return (
    <>
      <UpDownSprite />
      {!data.isValid ? (
        <MessageBox className="mb-10">
          <Link to={`/company/credit-info`}>
            <div className="text-gray-500">{i18n.value('orders.labels.creditInfoRequired')}</div>
          </Link>
        </MessageBox>
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={data.isSeller ? CreditInformationSchemaSeller : CreditInformationSchemaBuyer}
        onSubmit={async (values) => {
          toggleEditing()
          await saveCreditInfo(values)
          await loadCompanyCommonInfo(true)
        }}
      >
        {({ values, isSubmitting, setFieldValue, handleSubmit, resetForm, setSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className="container mb:p-4 mb:mb-10 sm:w-screen-lg-min">
              <div className="container mx-auto lg:w-screen-lg-min mb-2 flex">
                <OrderHeading label={i18n.value('profile.company.labels.creditInfo')} />
              </div>
              <ToggleableSection className="mt-4" labelTop={'profile.company.labels.generalInfo'} withoutHeader>
                <div className="grid grid-cols-2 mb:grid-cols-1">
                  <div>
                    <div className="mt-2">
                      <RequiredLabel label={FIELD_LABELS['creditCompanyName']} />
                      <div>
                        <InputFormik
                          isEditable={editing}
                          itemKey={'creditCompanyName'}
                          inputClass={'w-full ml-0'}
                          value={values.creditCompanyName}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <RequiredLabel isRequired={data.isSeller} label={FIELD_LABELS['creditPhoneNumber']} />
                      <div>
                        <InputFormik
                          isEditable={editing}
                          itemKey={'creditPhoneNumber'}
                          inputClass={'w-full'}
                          value={values.creditPhoneNumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ml-6 mb:ml-0">
                    <div className="mt-2">
                      <RequiredLabel label={FIELD_LABELS['taxId']} />
                      <div>
                        <InputFormik
                          isEditable={editing}
                          itemKey={'taxId'}
                          inputClass={'w-full ml-0'}
                          value={values.taxId}
                        />
                      </div>
                    </div>
                    <div className="mt-2 flex items-left items-center text-brown-dim space-x-4">
                      {editing ? (
                        <label className="text-gray-500 flex space-x-4">
                          <span>{i18n.value('profile.company.labels.vatApplied')}</span>
                          <FormikToggle itemKey="vatApplied" labelOn="common.yes" labelOff="common.no" />
                        </label>
                      ) : (
                        <>
                          <TextLabel label="profile.company.labels.vatApplied" className="mr-4" />
                          {values.vatApplied && <CheckIcon />}
                          <span>{i18n.value(values.vatApplied ? 'common.yes' : 'common.no')}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened={data.isSeller}
                className="mt-12"
                labelTop={'profile.company.labels.addresses'}
                withoutHeader
              >
                <div className="grid grid-cols-2 ">
                  <div className="mt-2">
                    <OrderSubHeader label={'profile.company.labels.legalAddress'} className={'mb-4'} />
                    <DeliveryAddress
                      withoutRecipient
                      itemKey={'legalAddress'}
                      isEditable={editing}
                      address={values.legalAddress}
                    />
                  </div>
                  <div className="ml-6 mt-2">
                    <OrderSubHeader label={'profile.company.labels.actualAddress'} className={'mb-4'} />
                    <DeliveryAddress
                      withoutRecipient
                      isOptional
                      itemKey={'actualAddress'}
                      isEditable={editing}
                      address={values.actualAddress}
                    />
                  </div>
                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened={data.isSeller}
                className="mt-12"
                labelTop={'profile.company.labels.bankInfo'}
                withoutHeader
              >
                <div className="grid grid-cols-2">
                  <div className="mt-2">
                    {bankFields.map(({ key, isRequired }) => (
                      <div className="mt-2" key={key}>
                        <RequiredLabel label={FIELD_LABELS[key]} isRequired={isRequired} />
                        <InputFormik isEditable={editing} itemKey={key} value={values[key]} inputClass={'w-full'} />
                      </div>
                    ))}
                  </div>
                  <div className="ml-6 mt-2">
                    <div className="mt-2">
                      {corrBankFields.map(({ key, isRequired }) => (
                        <div className="mt-2" key={key}>
                          <RequiredLabel label={FIELD_LABELS[key]} isRequired={isRequired} />
                          <InputFormik isEditable={editing} itemKey={key} value={values[key]} inputClass={'w-full'} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened={data.isSeller}
                className="mt-12"
                labelTop={'profile.company.labels.supervisorInfo'}
                withoutHeader
              >
                <div className="mt-2">
                  {supervisorFields.map(({ key, isRequired }) => (
                    <div className="mt-2" key={key}>
                      <RequiredLabel label={FIELD_LABELS[key]} isRequired={isRequired} />
                      <InputFormik isEditable={editing} itemKey={key} value={values[key]} inputClass={'w-full'} />
                    </div>
                  ))}
                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened={data.isSeller}
                className="mt-12"
                labelTop={'profile.company.labels.comment'}
                withoutHeader
              >
                <div className="mt-2">
                  {editing ? (
                    <OrderSpecialTermsFormik itemKey="creditComment" isEditable value={values.creditComment} />
                  ) : (
                    <pre className="text-gray-500">{values.creditComment}</pre>
                  )}
                </div>
              </ToggleableSection>
              {isSubmitting ? (
                <Spinner />
              ) : (
                <div className="flex mt-12">
                  {editing ? (
                    <div className="flex">
                      <FormikSubmittingButtons
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        buttons={submittingButtons}
                      />
                      <BlueButton
                        onClick={() => {
                          toggleEditing()
                          setSubmitting(false)
                          resetForm()
                        }}
                        type={'button'}
                        label={'common.cancel'}
                        className={'profile-button ml-12'}
                      />
                    </div>
                  ) : (
                    <div className="flex space-x-4">
                      <BlueButton type="button" onClick={() => history.goBack()}>
                        Back
                      </BlueButton>
                      {canEditCreditInfo && (
                        <BrownButton
                          onClick={toggleEditing}
                          type={'button'}
                          label={'common.edit'}
                          className={'profile-button'}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

CreditInformation.propTypes = {
  data: PropTypes.object.isRequired,
  loadCreditInfo: PropTypes.func.isRequired,
  saveCreditInfo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
  canSupervise: PropTypes.bool,
}

export default memo(connect(CreditInformation))
