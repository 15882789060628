import { connect } from 'react-redux'

import { confirmSms, loginByPassword } from 'root/modules/login/thunks'
import { getUserData } from 'root/selectors'

const mapDispatchToProps = {
  confirmSms,
  loginByPassword,
}

const mapStateToProps = (state) => ({
  user: getUserData(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
