import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="text-brown-dim"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1579 1H3.84211C2.27245 1 1 2.27245 1 3.84211V16.1579C1 17.7275 2.27245 19 3.84211 19H16.1579C17.7275 19 19 17.7275 19 16.1579V3.84211C19 2.27245 17.7275 1 16.1579 1Z"
      fill="currentColor"
    />
    <path
      d="M16.1578 1.47363H3.84205C2.53401 1.47363 1.47363 2.53401 1.47363 3.84205V16.1578C1.47363 17.4659 2.53401 18.5263 3.84205 18.5263H16.1578C17.4659 18.5263 18.5263 17.4659 18.5263 16.1578V3.84205C18.5263 2.53401 17.4659 1.47363 16.1578 1.47363Z"
      stroke="currentColor"
    />
    <path
      d="M5.42773 9.36133L8.72073 12.4506L14.6604 6.87834"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
