import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TrackingItems from '../components/TrackingItems'
import ShipmentItems from '../components/ShipmentItems'
import { ACTION_MEMO_PAD_RECEIVE_BY_BUYER, ACTION_MEMO_PAD_SHIP_TO_BUYER } from 'root/modules/orders/constants'

const DeliveryItemsTab = ({ order, readOnly = false, isSubmitting, handleSubmit, setFieldValue, values, isSeller }) => {
  return (
    <>
      <TrackingItems
        shipments={order.shipments}
        action={ACTION_MEMO_PAD_RECEIVE_BY_BUYER}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        isReceiver={!isSeller}
        senderId={order.sellerCompanyId.id}
      />
      {readOnly ? null : (
        <ShipmentItems
          order={order}
          action={ACTION_MEMO_PAD_SHIP_TO_BUYER}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          values={values}
          valuesKey={'orderItemsForShipToBuyer'}
          isSender={isSeller}
        />
      )}
    </>
  )
}

DeliveryItemsTab.propTypes = {
  order: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSeller: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
}

export default memo(DeliveryItemsTab)
