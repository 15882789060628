import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import { toStyle } from 'root/constants'

const rowLengths = [[1], [2], [1, 2], [1, 3], [2, 3], [3, 3], [2, 2, 3], [2, 3, 3], [3, 3, 3], [3, 4, 3]]

const splitByLengths = (arr, lengthsArr) => {
  let start = 0
  return (
    lengthsArr?.map((len) => {
      const result = arr.slice(start, start + len)
      start = start + len
      return result
    }) || []
  )
}

const ChatMessageVideos = ({ hasUserName, hasMessage, videos }) => {
  videos = videos?.map((image, index) => ({ index, ...image }))
  const groupedImages = videos ? splitByLengths(videos, rowLengths[videos.length - 1]) : []

  return (
    <div style={toStyle([styles.flex, hasUserName && styles.paddingTop, hasMessage && styles.paddingBottom])}>
      {groupedImages.map((imageRow, row) => {
        return (
          <div key={row} style={toStyle([styles.flex, styles.row])}>
            {imageRow.map(({ previewUrlDesktop, originalUrl, id, videoUrl }, column) => {
              const isFirst = column === 0
              const isLast = column === imageRow.length - 1
              const isFirstRow = row === 0
              const isLastRow = row === groupedImages.length - 1
              return (
                <a key={id} target="_blank" style={styles.flex} href={videoUrl} rel="noreferrer">
                  <video
                    controls
                    autoPlay
                    style={toStyle([
                      styles.image,
                      !hasUserName && isFirstRow && isFirst && styles.topLeft,
                      !hasUserName && isFirstRow && isLast && styles.topRight,
                      !hasMessage && isLastRow && isFirst && styles.bottomLeft,
                      !hasMessage && isLastRow && isLast && styles.bottomRight,
                    ])}
                    src={videoUrl}
                  />
                </a>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

ChatMessageVideos.propTypes = {
  hasUserName: PropTypes.bool.isRequired,
  hasMessage: PropTypes.bool.isRequired,
  videos: PropTypes.array.isRequired,
}

export default memo(ChatMessageVideos)
