import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = (props) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    viewBox="0 0 32 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5365 18.7826V3.91309H5.46338V18.7826"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9756 1.56532V0.782715H7.02441V1.56532"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1462 14.8696H21.8536"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1462 11.7393H21.8536"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1462 8.60889H17.9511"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.2195 35.2173H0.780518V21.1304H10.1464L11.7073 23.4782H20.2927L21.9834 21.1304H31.2195V35.2173Z"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.12198 17.2173L0.780518 20.3477V21.1303"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8782 17.2173L31.2196 20.3477V21.1303"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5122 27.3916H12.4878V31.3046H19.5122V27.3916Z"
      stroke="#90B9D7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
SvgComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default memo(SvgComponent)
