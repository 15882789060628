import { handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import * as actions from '../../actions'

export default combineReducers({
  data: handleActions(
    {
      [actions.loadNotificationsSummarySuccess]: (state, payload) => payload.payload,
    },
    {},
  ),
})
