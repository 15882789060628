import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

const RadioGroupFormik = ({ labelBefore, labelAfter, itemKey, value, className = '', options }) => {
  return (
    <div className={className}>
      {labelBefore ? <span className="mr-2">{labelAfter}</span> : ''}
      {options.map((item) => (
        <label key={itemKey + item.key} className="ml-2 cursor-pointer">
          <Field
            type="radio"
            id={itemKey + item.key}
            name={itemKey}
            value={item.key}
            className="form-radio text-brown-dim"
          />
          <label htmlFor={itemKey + item.key} className="ml-2">
            {item.label}
          </label>
        </label>
      ))}
      {labelAfter ? <span className="ml-2">{labelAfter}</span> : ''}
    </div>
  )
}

RadioGroupFormik.propTypes = {
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  itemKey: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default memo(RadioGroupFormik)
