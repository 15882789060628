import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useParams } from 'react-router-dom'
import Spinner from 'root/components/Spinner'
import connect from './connect'
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik'
import { DiamondBigIcon, EyeIcon, LocationBigIcon, MediaBigIcon, PercentBigIcon, RemoveIcon } from 'root/icons'
import SubSection from 'root/modules/products/pages/Product/components/SubSection'
import i18n from 'root/i18n'
import TextInput from 'root/modules/products/pages/Product/components/TextInput'
import SelectInput from 'root/modules/products/pages/Product/components/SelectInput'
import ProductRequiredLabel from 'root/modules/products/pages/Product/components/ProductRequiredLabel'
import FormikToggle from 'root/components/FormikToggle'
import {
  ACTION_ACTIVATE,
  ACTION_ARCHIVE,
  COLOR_FANCY,
  COLOR_WHITE,
  CURRENCY_DOLLAR,
} from 'root/modules/products/constants'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import BrownButton from 'root/components/Buttons/BrownButton'
import { clearLocalFiles, errorAlert, getLocalFiles, toClassName, trimNumber } from 'root/constants'
import * as Yup from 'yup'
import UploadDocumentInsideFormik from 'root/modules/orders/pages/components/UploadDocument/inside_formik'
import { uploadProductImage } from 'root/api'
import { useAsyncLoad } from 'root/hooks'
import BlueButton from 'root/components/Buttons/BlueButton'
import Button from 'root/components/Buttons/Button'
import { infoAlert } from 'root/components/AlertWrapper'
import { calcRefPriceDiscount, roundTwo } from 'root/modules/orders/constants'

const FIELD_LABELS = {
  price: i18n.value('product.labels.price'),
  referenceDiscount: i18n.value('product.labels.referenceDiscount'),
  referencePrice: i18n.value('product.labels.referencePrice'),
  originCountryId: i18n.value('product.labels.originCountryId'),
  zipCodeId: i18n.value('product.labels.zipCodeId'),
  mediaLink: i18n.value('product.labels.mediaLink'),
  locationCountryId: i18n.value('product.labels.locationCountryId'),
  locationRegionId: i18n.value('product.labels.locationRegionId'),
  locationCityId: i18n.value('product.labels.locationCityId'),
  shape: i18n.value('product.labels.shape'),
  carat: i18n.value('product.labels.carat'),
  clarity: i18n.value('product.labels.clarity'),
  cut: i18n.value('product.labels.cut'),
  polish: i18n.value('product.labels.polish'),
  symmetry: i18n.value('product.labels.symmetry'),
  tableSize: i18n.value('product.labels.tableSize'),
  crownHeight: i18n.value('product.labels.crownHeight'),
  pavilionDepth: i18n.value('product.labels.pavilionDepth'),
  girdle: i18n.value('product.labels.girdle'),
  culet: i18n.value('product.labels.culet'),
  totalDepth: i18n.value('product.labels.totalDepth'),
  fluorescence: i18n.value('product.labels.fluorescence'),
  heartsAndArrows: i18n.value('product.labels.heartsAndArrows'),
  certificate: i18n.value('product.labels.certificate'),
  certificateNumber: i18n.value('product.labels.certificateNumber'),
  grownTechnology: i18n.value('product.labels.grownTechnology'),
  colorValue: i18n.value('product.labels.color.colorValue'),
  fancyIntensity: i18n.value('product.labels.color.fancyIntensity'),
  fancyOvertone: i18n.value('product.labels.color.fancyOvertone'),
  description: i18n.value('product.labels.color.description'),
}

const validationSchema = Yup.object().shape({
  price: Yup.number().min(1).max(10000000).required(),
  referenceDiscount: Yup.number().min(0.01).max(99.99).required(),
  referencePrice: Yup.number().min(1).max(10000000).required(),
  originCountryId: Yup.string().required(),
  zipCodeId: Yup.string().max(200).required(),
  mediaLink: Yup.string().max(200),
  locationCountryId: Yup.string().required(),
  locationCityId: Yup.string().min(1).max(200).required(),
  locationRegionId: Yup.string().max(200),
  shape: Yup.number().required(),
  carat: Yup.number().min(0).max(255).required(),
  clarity: Yup.number().required(),
  cut: Yup.number().required(),
  polish: Yup.number().required(),
  symmetry: Yup.number().required(),
  tableSize: Yup.number().min(0).max(100).required(),
  crownHeight: Yup.number().min(0).max(255).required(),
  pavilionDepth: Yup.number().min(0).max(255).required(),
  girdle: Yup.number().required(),
  culet: Yup.number().required(),
  totalDepth: Yup.number().min(0).max(100).required(),
  fluorescence: Yup.number().required(),
  certificate: Yup.number().required(),
  certificateNumber: Yup.string().min(1).max(200).required(),
  grownTechnology: Yup.number().required(),
  colorValue: Yup.number().required(),
  fancyIntensity: Yup.number().required(),
  fancyOvertone: Yup.number().required(),
  measurementsX: Yup.number().min(0).max(255).required(),
  measurementsY: Yup.number().min(0).max(255).required(),
  measurementsZ: Yup.number().min(0).max(255).required(),
  description: Yup.string().max(1000),
})

const Product = ({ data, loadProduct, isLoading, saveProductThunk, performProductAction }) => {
  const { id } = useParams()
  const history = useHistory()
  useEffect(() => {
    clearLocalFiles()
    loadProduct({ id })
  }, [id, loadProduct])
  const [selectedImage, setSelectedImage] = useState(undefined)
  const [selectedImageId, setSelectedImageId] = useState(undefined)
  const [loading, doAction] = useAsyncLoad(performProductAction)

  const calcRefDiscount = useCallback((values, errors, setFieldValue) => {
    if (errors.referencePrice || errors.price) return
    const referencePrice = Number.parseFloat(values.referencePrice)
    const price = Number.parseFloat(values.price)
    if (!referencePrice || !price) return

    let referenceDiscount = roundTwo(calcRefPriceDiscount(price, referencePrice))
    referenceDiscount = Math.min(Math.max(referenceDiscount, 0), 100)
    setFieldValue('referenceDiscount', referenceDiscount)
  }, [])

  const calcPrice = useCallback((values, errors, setFieldValue) => {
    if (errors.referenceDiscount || errors.referencePrice) return
    const referenceDiscount = Number.parseFloat(values.referenceDiscount)
    const referencePrice = Number.parseFloat(values.referencePrice)
    if (!referencePrice || !referenceDiscount) return

    const price = roundTwo(((100 - referenceDiscount) * referencePrice) / 100)
    setFieldValue('price', price)
  }, [])

  const calcRefPrice = useCallback((values, errors, setFieldValue) => {
    if (errors.price || errors.referenceDiscount) return

    let referencePrice = Number.parseFloat(values.referencePrice)
    if (referencePrice) return

    const price = Number.parseFloat(values.price)
    const referenceDiscount = Number.parseFloat(values.referenceDiscount)

    referencePrice = roundTwo((100 * price) / (100 - referenceDiscount))
    setFieldValue('referencePrice', referencePrice)
  }, [])

  const trimNumberValue = useCallback((itemKey, value, setFieldValue) => {
    setFieldValue(itemKey, trimNumber(value))
  }, [])

  if (isLoading || !data?.id) {
    return <Spinner />
  }
  const isNew = id === 'new'
  const isArchived = data.status.key === 'archive'

  const canEdit = !data.dealConfirmed

  return (
    <Formik
      validateOnChange
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        ...data,
        price: data?.price || '',
        mediaLink: data?.mediaLink || '',
        referenceDiscount: data?.referenceDiscount || '',
        referencePrice: data?.referencePrice || '',
        carat: data?.carat?.toString() || '',
        description: data?.description || '',
        measurementsX: data?.measurementsX || '',
        measurementsY: data?.measurementsY || '',
        measurementsZ: data?.measurementsZ || '',
        tableSize: data?.tableSize || '',
        crownHeight: data?.crownHeight || '',
        pavilionDepth: data?.pavilionDepth || '',
        totalDepth: data?.totalDepth || '',
        originCountryId: data?.originCountryId?.code || '',
        zipCodeId: data?.zipCodeId?.zipCode || '',
        locationCountryId: data?.zipCodeId?.cityId?.countryId?.code || data?.originCountryId?.code,
        locationRegionId: data?.zipCodeId?.cityId?.regionId?.name || '',
        locationCityId: data?.zipCodeId?.cityId?.name || '',
        shape: data?.shape?.id?.toString() || data?.meta.options.shape[0].id,
        clarity: data?.clarity?.id?.toString() || data.meta.options.clarity[0].id,
        cut: data?.cut?.id?.toString() || data.meta.options.cut[0].id,
        polish: data?.polish?.id?.toString() || data?.meta.options.polish[0].id,
        girdle: data?.girdle?.id?.toString() || data?.meta.options.girdle[0].id,
        symmetry: data?.symmetry?.id?.toString() || data?.meta.options.symmetry[0].id,
        culet: data?.culet?.id?.toString() || data?.meta.options.culet[0].id,
        certificate: data?.certificate?.id?.toString() || data?.meta.options.certificate[0].id,
        certificateNumber: data?.certificateNumber?.value?.toString() || data?.certificateNumber || '',
        fluorescence: data?.fluorescence?.id?.toString() || data?.meta.options.fluorescence[0].id,
        grownTechnology: data?.grownTechnology?.id?.toString() || data?.meta.options.grownTechnology[0].id,
        colorValue: data?.colorValue?.id?.toString() || '1',
        fancyOvertone: data?.fancyOvertone?.id?.toString() || data?.meta.options.fancyOvertone[0].id,
        fancyIntensity: data?.fancyIntensity?.id?.toString() || data?.meta.options.fancyIntensity[0].id,
        colorTypeWhite: data?.colorType?.id?.toString() !== COLOR_FANCY.toString(),
        video: data?.video?.map((v) => v.videoUrl),
      }}
      onSubmit={(values) => {
        const localFiles = getLocalFiles()
        const serverData = {
          ...values,
          currencyId: CURRENCY_DOLLAR,
          colorType: values.colorTypeWhite ? COLOR_WHITE : COLOR_FANCY,
          heartsAndArrows: values.heartsAndArrows ? 1 : 0,
          video: values.video.filter((v) => v.trim() !== ''),
          images: values.images
            .map(({ originalName, id }, index) => ({
              orderNumber: index + 1,
              originalName,
              id,
            }))
            .concat(localFiles),
        }
        return saveProductThunk(serverData)
          .then(() => {
            clearLocalFiles()
            infoAlert(i18n.value('product.saved'))
            //history.replace(`/products`)
            //window.scrollTo(0, 0)
          })
          .catch(errorAlert)
      }}
    >
      {({ values, isSubmitting, setFieldValue, handleSubmit, errors }) => (
        <div className="container lg:w-screen-lg-min mt-10">
          {!isNew && (
            <section className="w-full flex text-xl mb-10">
              <button
                className="text-blue-skyblue hover:text-blue-dark flex items-center space-x-2"
                type="button"
                onClick={history.goBack}
              >
                &lt; Back to My Stock
              </button>
              <div className="ml-auto flex items-center">
                <div className="text-gray-500 mr-4 items-center justify-center">
                  {i18n.value('product.labels.status')}
                </div>
                {data.orderId ? (
                  <Link
                    className="px-8 flex items-center justify-center h-10 font-bold text-white transition duration-200 hover:shadow-sm active:shadow-none disabled:opacity-50 focus:outline-none bg-blue-skyblue"
                    to={`/sales/${data.orderId}`}
                  >
                    {values.status.name}
                  </Link>
                ) : (
                  <div className="text-blue-skyblue">{values.status.name}</div>
                )}
              </div>
            </section>
          )}
          <Form onSubmit={handleSubmit} className="flex flex-col space-y-20">
            <SubSection label="product.labels.priceAndDiscount" Icon={PercentBigIcon}>
              <div className="grid grid-cols-3">
                <TextInput
                  label={FIELD_LABELS.price}
                  labelBefore="$"
                  isEditable={canEdit}
                  itemKey="price"
                  inputClass={'w-2/3 ml-0'}
                  value={values.price}
                  onChange={({ target: { value } }) => trimNumberValue('price', value, setFieldValue)}
                  onBlur={() => calcRefDiscount(values, errors, setFieldValue)}
                />
                <TextInput
                  label={FIELD_LABELS.referenceDiscount}
                  labelBefore="%"
                  isEditable={canEdit}
                  itemKey="referenceDiscount"
                  inputClass={'w-2/3 ml-0'}
                  value={values.referenceDiscount}
                  onChange={({ target: { value } }) => trimNumberValue('referenceDiscount', value, setFieldValue)}
                  onBlur={() => {
                    calcRefPrice(values, errors, setFieldValue)
                    calcPrice(values, errors, setFieldValue)
                  }}
                />
                <TextInput
                  label={FIELD_LABELS.referencePrice}
                  labelBefore="$"
                  isEditable={canEdit}
                  itemKey="referencePrice"
                  inputClass={'w-2/3 ml-0'}
                  value={values.referencePrice}
                  onChange={({ target: { value } }) => trimNumberValue('referencePrice', value, setFieldValue)}
                  onBlur={() => calcRefDiscount(values, errors, setFieldValue)}
                />
              </div>
            </SubSection>
            <SubSection label="product.labels.location" Icon={LocationBigIcon}>
              <div className="grid grid-cols-3 gap-y-8">
                <SelectInput
                  label={FIELD_LABELS.locationCountryId}
                  options={[{ id: 0, text: 'N/A' }].concat(
                    data.meta.options.countryId.map(({ code, name }) => ({ id: code, text: name })),
                  )}
                  isEditable={canEdit}
                  itemKey="locationCountryId"
                  inputClass={'w-2/3 ml-0'}
                  value={data.zipCodeId?.cityId?.countryId?.name}
                />
                <TextInput
                  label={FIELD_LABELS.locationRegionId}
                  isRequired={false}
                  isEditable={canEdit}
                  itemKey="locationRegionId"
                  inputClass={'w-2/3 ml-0'}
                  value={values.locationRegionId}
                />
                <TextInput
                  label={FIELD_LABELS.locationCityId}
                  isEditable={canEdit}
                  itemKey="locationCityId"
                  inputClass={'w-2/3 ml-0'}
                  value={values.locationCityId}
                />
                <TextInput
                  label={FIELD_LABELS.zipCodeId}
                  isEditable={canEdit}
                  itemKey="zipCodeId"
                  inputClass={'w-2/3 ml-0'}
                  value={values.zipCodeId}
                />
              </div>
            </SubSection>
            <SubSection label="product.labels.diamondParameters" Icon={DiamondBigIcon}>
              <div className="grid grid-cols-3 gap-y-8">
                <SelectInput
                  options={data.meta.options.shape}
                  label={FIELD_LABELS.shape}
                  isEditable={canEdit}
                  itemKey="shape"
                  inputClass={'w-2/3 ml-0'}
                  value={data.shape?.text}
                />
                <TextInput
                  label={FIELD_LABELS.carat}
                  isEditable={canEdit}
                  itemKey="carat"
                  inputClass={'w-2/3 ml-0'}
                  value={data.carat}
                  onChange={({ target: { value } }) => trimNumberValue('carat', value, setFieldValue)}
                />
                <div>
                  <ProductRequiredLabel label={i18n.value('product.labels.colorType')} />
                  <FormikToggle
                    disabled={!canEdit}
                    itemKey="colorTypeWhite"
                    labelOn="product.labels.white"
                    labelOff="product.labels.fancy"
                  />
                </div>
                {values.colorTypeWhite ? (
                  <>
                    <SelectInput
                      options={data.meta.options.whiteColors}
                      label={FIELD_LABELS.colorValue}
                      isEditable={canEdit}
                      itemKey="colorValue"
                      inputClass={'w-2/3 ml-0'}
                      value={data.colorValue?.text}
                    />
                    <div />
                    <div />
                  </>
                ) : (
                  <>
                    <SelectInput
                      options={data.meta.options.fancyColors}
                      label={FIELD_LABELS.colorValue}
                      isEditable={canEdit}
                      itemKey="colorValue"
                      inputClass={'w-2/3 ml-0'}
                      value={data.colorValue?.text}
                    />
                    <SelectInput
                      options={data.meta.options.fancyIntensity}
                      label={FIELD_LABELS.fancyIntensity}
                      isEditable={canEdit}
                      itemKey="fancyIntensity"
                      inputClass={'w-2/3 ml-0'}
                      value={data.fancyIntensity?.text}
                    />
                    <SelectInput
                      options={data.meta.options.fancyOvertone}
                      label={FIELD_LABELS.fancyOvertone}
                      isEditable={canEdit}
                      itemKey="fancyOvertone"
                      inputClass={'w-2/3 ml-0'}
                      value={data.fancyOvertone?.text}
                    />
                  </>
                )}
                <SelectInput
                  options={data.meta.options.clarity}
                  label={FIELD_LABELS.clarity}
                  isEditable={canEdit}
                  itemKey="clarity"
                  inputClass={'w-2/3 ml-0'}
                  value={data.clarity?.text}
                />
                <SelectInput
                  options={data.meta.options.cut}
                  label={FIELD_LABELS.cut}
                  isEditable={canEdit}
                  itemKey="cut"
                  inputClass={'w-2/3 ml-0'}
                  value={data.cut?.text}
                />
                <SelectInput
                  options={data.meta.options.polish}
                  label={FIELD_LABELS.polish}
                  isEditable={canEdit}
                  itemKey="polish"
                  inputClass={'w-2/3 ml-0'}
                  value={data.polish?.text}
                />
                <SelectInput
                  options={data.meta.options.symmetry}
                  label={FIELD_LABELS.symmetry}
                  isEditable={canEdit}
                  itemKey="symmetry"
                  inputClass={'w-2/3 ml-0'}
                  value={data.symmetry?.text}
                />
                <div>
                  <ProductRequiredLabel label={i18n.value('product.labels.measurements')} />
                  <div className="flex text-gray-500">
                    <InputFormik
                      isEditable={canEdit}
                      itemKey="measurementsX"
                      value={data.measurementsX}
                      inputClass="w-12 mx-0"
                      textColor="text-blue-dark"
                      onChange={({ target: { value } }) => trimNumberValue('measurementsX', value, setFieldValue)}
                    />
                    <span className="mx-2">x</span>
                    <InputFormik
                      isEditable={canEdit}
                      itemKey="measurementsY"
                      value={data.measurementsY}
                      inputClass="w-12 mx-0"
                      textColor="text-blue-dark"
                      onChange={({ target: { value } }) => trimNumberValue('measurementsY', value, setFieldValue)}
                    />
                    <span className="mx-2">x</span>
                    <InputFormik
                      isEditable={canEdit}
                      itemKey="measurementsZ"
                      value={data.measurementsZ}
                      inputClass="w-12 mx-0"
                      textColor="text-blue-dark"
                      onChange={({ target: { value } }) => trimNumberValue('measurementsZ', value, setFieldValue)}
                    />
                    <span className="mx-2 text-blue-dark">{i18n.value('common.mm')}</span>
                  </div>
                </div>
                <TextInput
                  label={FIELD_LABELS.tableSize}
                  labelBefore="%"
                  isEditable={canEdit}
                  itemKey="tableSize"
                  inputClass={'w-2/3 ml-0'}
                  value={data.tableSize}
                  onChange={({ target: { value } }) => trimNumberValue('tableSize', value, setFieldValue)}
                />
                <TextInput
                  label={FIELD_LABELS.crownHeight}
                  labelBefore="%"
                  isEditable={canEdit}
                  itemKey="crownHeight"
                  inputClass={'w-2/3 ml-0'}
                  value={data.crownHeight}
                  onChange={({ target: { value } }) => trimNumberValue('crownHeight', value, setFieldValue)}
                />
                <TextInput
                  label={FIELD_LABELS.pavilionDepth}
                  labelBefore="%"
                  isEditable={canEdit}
                  itemKey="pavilionDepth"
                  inputClass={'w-2/3 ml-0'}
                  value={data.pavilionDepth}
                  onChange={({ target: { value } }) => trimNumberValue('pavilionDepth', value, setFieldValue)}
                />
                <SelectInput
                  options={data.meta.options.girdle}
                  label={FIELD_LABELS.girdle}
                  isEditable={canEdit}
                  itemKey="girdle"
                  inputClass={'w-2/3 ml-0'}
                  value={data.girdle?.text}
                />
                <SelectInput
                  options={data.meta.options.culet}
                  label={FIELD_LABELS.culet}
                  isEditable={canEdit}
                  itemKey="culet"
                  inputClass={'w-2/3 ml-0'}
                  value={data.culet?.text}
                />
                <TextInput
                  label={FIELD_LABELS.totalDepth}
                  labelBefore="%"
                  isEditable={canEdit}
                  itemKey="totalDepth"
                  inputClass={'w-2/3 ml-0'}
                  value={data.totalDepth}
                  onChange={({ target: { value } }) => trimNumberValue('totalDepth', value, setFieldValue)}
                />
                <SelectInput
                  options={data.meta.options.fluorescence}
                  label={FIELD_LABELS.fluorescence}
                  isEditable={canEdit}
                  itemKey="fluorescence"
                  inputClass={'w-2/3 ml-0'}
                  value={data.fluorescence?.text}
                />
                <TextInput
                  label={FIELD_LABELS.certificateNumber}
                  isEditable={canEdit}
                  itemKey="certificateNumber"
                  inputClass={'w-2/3 ml-0'}
                  value={values.certificateNumber}
                />
                <SelectInput
                  options={data.meta.options.certificate}
                  label={FIELD_LABELS.certificate}
                  isEditable={canEdit}
                  itemKey="certificate"
                  inputClass={'w-2/3 ml-0'}
                  value={data.certificate?.text}
                />
                <div>
                  <ProductRequiredLabel label={i18n.value('product.labels.heartsAndArrows')} isRequired={false} />
                  <div className="ml-2">
                    <FormikToggle
                      labelInside={false}
                      itemKey="heartsAndArrows"
                      labelOn="common.yes"
                      labelOff="common.no"
                    />
                  </div>
                </div>
                <SelectInput
                  options={[{ id: 0, text: 'N/A' }].concat(
                    data.meta.options.countryId.map(({ code, name }) => ({ id: code, text: name })),
                  )}
                  label={FIELD_LABELS.originCountryId}
                  isEditable={canEdit}
                  itemKey="originCountryId"
                  inputClass={'w-2/3 ml-0'}
                  value={data.originCountryId.name}
                />
                <SelectInput
                  options={data.meta.options.grownTechnology}
                  label={FIELD_LABELS.grownTechnology}
                  isEditable={canEdit}
                  itemKey="grownTechnology"
                  inputClass={'w-2/3 ml-0'}
                  value={data.grownTechnology?.text}
                />
              </div>
              <div className="mt-24">
                <ProductRequiredLabel
                  className="text-brown-dim-light"
                  label={i18n.value('product.labels.color.description')}
                  isRequired={false}
                />
                <Field
                  as="textarea"
                  name="description"
                  id="description"
                  className="mt-4 form-textarea text-brown-dim w-full block shadow shadow-inset border-gray-300"
                  disabled={!canEdit}
                  rows={5}
                />
              </div>
            </SubSection>
            <SubSection label="product.labels.mediaMaterials" Icon={MediaBigIcon}>
              <div className="mt-4">
                <div className="text-gray-500 text-sm mb-4">{i18n.value('product.labels.mediaLinkHelp')}</div>
                <TextInput
                  label={FIELD_LABELS.mediaLink}
                  isRequired={false}
                  isEditable={canEdit}
                  itemKey="mediaLink"
                  inputClass={'w-full ml-0'}
                  value={values.mediaLink}
                />
              </div>
              <div className="mt-12">
                <div className="text-brown-dim text-xl mb-4">{i18n.value('product.labels.images')}</div>
                <div className="flex space-x-8">
                  {values.images.length > 0 && (
                    <div className="w-1/2">
                      <div className="bg-gray-100 h-10 mb-2 px-2 flex items-center justify-between text-gray-500">
                        <div className="w-8">#</div>
                        <div className="">{i18n.value('product.labels.imageName')}</div>
                        <div className="w-8" />
                      </div>
                      {values.images.map(({ id, originalName, previewUrlMobile }, index) => (
                        <div
                          onMouseEnter={() => {
                            setSelectedImage(previewUrlMobile)
                            setSelectedImageId(id)
                          }}
                          onMouseLeave={() => {
                            setSelectedImage(undefined)
                            setSelectedImageId(undefined)
                          }}
                          className={toClassName([
                            'flex items-center space-x-2 leading-tight place-items-stretch break-all',
                            selectedImageId === id && 'bg-gray-300',
                          ])}
                          key={`image${id}`}
                        >
                          <div className="w-8">{index + 1}</div>
                          <div className="w-102 flex-grow">{originalName}</div>
                          <div className="w-8">
                            {canEdit && (
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedImage(undefined)
                                  setSelectedImageId(undefined)
                                  values.images.splice(index, 1)
                                }}
                              >
                                <RemoveIcon className="text-blue-dark cursor-pointer" />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className={values.images.length > 0 ? 'w-1/2 flex justify-center' : 'w-full'}>
                    {selectedImage && <img className="object-contain h-64 w-102" src={selectedImage} alt="" />}
                    {canEdit && (
                      <div className={toClassName(['-mt-4 w-full', selectedImage && 'hidden'])}>
                        <UploadDocumentInsideFormik
                          accept="image/jpeg,image/png,image/gif,image/bmp"
                          maxFiles={10}
                          buttons={[]}
                          uploadDocumentFunction={(file) => uploadProductImage(data.id, file)}
                          isSubmitting={isSubmitting}
                          handleSubmit={() => {}}
                          setFieldValue={() => {}}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-12">
                <div className="text-brown-dim text-xl mb-4">{i18n.value('product.labels.videos')}</div>
                <div className="text-gray-500 text-sm mb-4">{i18n.value('product.labels.videosHelp')}</div>
                <FieldArray name="video">
                  {({ remove, push }) => (
                    <div>
                      <div className="mb-4 space-y-2">
                        {values.video.map((video, index) => (
                          <div key={`video${index}`} className="flex w-full space-x-2">
                            {video ? (
                              <a
                                className="flex items-center justify-center"
                                href={video}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <EyeIcon />
                              </a>
                            ) : (
                              <div className="w-6" />
                            )}
                            <div className="w-1/2">
                              <InputFormik inputClass="w-full" isEditable={canEdit} itemKey={`video[${index}]`} />
                            </div>
                            {canEdit && (
                              <button type="button" onClick={() => remove(index)}>
                                <RemoveIcon className="text-blue-dark cursor-pointer" />
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                      {canEdit && (
                        <Button
                          onClick={() => push('')}
                          type="button"
                          label="product.labels.addVideo"
                          className="bg-brown-dim-light"
                        />
                      )}
                    </div>
                  )}
                </FieldArray>
              </div>
            </SubSection>
            <section>
              <div>
                {Object.keys(errors).length > 0 && (
                  <section className="my-4 bg-red-300 text-red-600">
                    {Object.keys(errors).map((key) => (
                      <div className="flex space-x-4" key={key}>
                        <div>{key}:</div>
                        {(Array.isArray(errors[key]) || typeof errors[key] === 'string') && (
                          <ErrorMessage name={key} component="div" />
                        )}
                      </div>
                    ))}
                  </section>
                )}
              </div>
              <div className="flex space-x-4">
                {canEdit && <BrownButton disabled={isSubmitting} label="common.save" />}
                {!isNew && (
                  <BlueButton
                    disabled={loading}
                    onClick={() => doAction(isArchived ? ACTION_ACTIVATE : ACTION_ARCHIVE)}
                    label={`common.${isArchived ? 'activate' : 'archive'}`}
                    type="button"
                  />
                )}
                <button
                  className="text-blue-skyblue hover:text-blue-dark flex items-center space-x-2"
                  type="button"
                  onClick={history.goBack}
                >
                  <span>{i18n.value('common.cancel')}</span>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 2.75C6.4625 2.75 2.75 6.4625 2.75 11C2.75 15.5375 6.4625 19.25 11 19.25C15.5375 19.25 19.25 15.5375 19.25 11C19.25 6.4625 15.5375 2.75 11 2.75ZM14.6094 13.1656L13.1656 14.6094L11 12.4438L8.83438 14.6094L7.39062 13.1656L9.55625 11L7.39062 8.83438L8.83438 7.39062L11 9.55625L13.1656 7.39062L14.6094 8.83438L12.4438 11L14.6094 13.1656Z"
                      fill="#ECEBED"
                    />
                  </svg>
                </button>
              </div>
            </section>
          </Form>
        </div>
      )}
    </Formik>
  )
}

Product.propTypes = {
  data: PropTypes.object.isRequired,
  loadProduct: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  saveProductThunk: PropTypes.func.isRequired,
  performProductAction: PropTypes.func.isRequired,
}

export default memo(connect(Product))
