import React, { memo } from 'react'
import Column from './Column'

const ShapeColumn = ({ children, ...props }) => (
  <Column
    label="order.diamonds.shape"
    columnClass="w-12 text-center"
    className="w-12 text-center text-brown-dim flex justify-center items-center"
    {...props}
  >
    {children ||
      (({ item }) => (
        <svg
          className="inline"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          preserveAspectRatio="xMinYMin"
        >
          <use href={`#img-shape-${item.shape.id}`} />
        </svg>
      ))}
  </Column>
)

ShapeColumn.propTypes = Column.propTypes

export default memo(ShapeColumn)
