import React, { memo } from 'react'
import Column from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds/Column'
import OrderTimer from 'root/components/OrderTimer'
import PropTypes from 'prop-types'

const TimerColumn = ({ children, timerKey, timerLabel, ...other }) => (
  <Column
    label={timerLabel ? timerLabel : 'order.diamonds.timer'}
    columnClass="w-32 text-center"
    className="w-32 text-center text-brown-dim flex justify-center items-center"
    {...other}
  >
    {children || (({ item }) => <OrderTimer timer={item.timer[timerKey]} />)}
  </Column>
)
TimerColumn.propTypes = { ...Column.propTypes, timerKey: PropTypes.string.isRequired, timerLabel: PropTypes.string }
export default memo(TimerColumn)
