import React, { memo } from 'react'
import PropTypes from 'prop-types'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import OrderSubHeader from 'root/components/OrderSubHeader'
import Diamonds, {
  CertificateColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import i18n from 'root/i18n'
import { connect } from 'react-redux'
import { isAdmin, isMiddleman } from 'root/selectors'

const DealCanceled = ({ order, asSeller, isAdmin }) => (
  <>
    <section>
      <GeneralTerms
        order={order}
        number={order.number}
        date={order.date}
        editable={false}
        buyer={asSeller && order.buyerCompanyId.name}
        seller={!asSeller && order.sellerCompanyId.name}
        showCounterparty={isAdmin}
        asSeller={asSeller}
        showKYC={false}
      />
    </section>

    <section>
      <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
      <Diamonds isEditable={false} highlightDeleted showDeleted
                items={order.products}>
        <IndexColumn/>
        <ShapeColumn/>
        <NameColumn/>
        <CertificateColumn/>
        <RefPriceColumn/>
        <RapaportColumn/>
        <PriceColumn/>
      </Diamonds>
    </section>

    <section>
      <OrderSubHeader label="order.labels.dealStatus" className="mb-4"/>
      <div className="text-gray-500">
        {i18n.value(`orders.labels.canceled.${order.state.key}`)}.
        <div>
          {i18n.value('orders.labels.canceled.reason')}:{' '}
          <span
            className="font-bold break-all">&quot;{order.reason}&quot;</span>
        </div>
      </div>
    </section>
  </>
)

DealCanceled.propTypes = {
  order: PropTypes.object.isRequired,
  asSeller: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
}

export default memo(
  connect((state) => ({ isAdmin: isAdmin(state) || isMiddleman(state) }), null)(
    DealCanceled))
