import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="44"
    height="28"
    viewBox="0 0 48 32"
    fill="none"
    className="stroke-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31 8H38.94C39.6 8 40.22 8.33 40.59 8.87L44 15H45C46.12 15 47 15.74 47 17.21V24C47 25.1 46.1 26 45 26H43"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M14 1H31V26H16" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M8 26H3" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M43 27.0095C43 29.2095 41.21 30.9995 38.99 30.9995C36.78 30.9995 34.98 29.2095 34.98 27.0095C34.98 24.8095 36.77 23.0195 38.99 23.0195C41.21 23.0195 43 24.7995 43 27.0095Z"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16.02 27.0095C16.02 29.2095 14.23 30.9995 12.01 30.9995C9.79 30.9995 8 29.2095 8 27.0095C8 24.8095 9.79 23.0195 12.01 23.0195C14.23 23.0195 16.02 24.7995 16.02 27.0095Z"
      stroke="current-color"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M31 26H35" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M33 16H40V15L38 12" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M8 7H23" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M6 7H4" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M12 1H10" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M-3 13H14" stroke="current-color" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M16 14.0471L18.0184 12H19.8941L19.2999 12.6014H18.2938L16.8377 14.0785L20.1529 18.0326L21.0939 16.9094L18.6929 14.0471L20.7126 12H24.9816L27 14.0471L22.8484 19L21.8844 17.8478L22.2899 17.3635L22.8484 18.0326L26.1623 14.0785L24.7062 12.6014H22.8815L24.3058 14.0471L20.1529 19L16 14.0471ZM19.5319 14.0785L21.4994 16.4251L23.4668 14.0785L22.0119 12.6014H20.9893L19.5319 14.0785Z"
      fill="#80506F"
    />
  </svg>
)

export default memo(SvgComponent)
