import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import OrderSubHeader from 'root/components/OrderSubHeader'
import Diamonds, {
  CertificateColumn,
  DeliveryColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import DeliveryPrice from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/DeliveryPrice'
import PaymentTermsSection from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/PaymentTermsSection'
import AddressSection from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/AddressSection'
import ReturnTermsSection from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/ReturnTermsSection'
import SpecialTermsSection from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/SpecialTermsSection'
import { formatNumberToPrice, getPriceFormatter, toClassName } from 'root/constants'
import { calcTotals } from 'root/modules/orders/constants'

const ViewTermsButton = ({ order }) => {
  const [opened, setOpened] = useState(false)
  const { total, subtotalAdditional } = calcTotals(order)
  const additionalGoods = order.additionalGoods
  const hasAdditionalGoods = additionalGoods && additionalGoods.length > 0

  return (
    <>
      <button
        onClick={() => {
          setOpened(true)
        }}
        className="text-brown-dim"
        type="button"
      >
        {i18n.value('orders.buttons.viewDealTerms')}
      </button>
      {opened && (
        <Modal
          onClick={() => {
            setOpened(false)
          }}
          isFull
        >
          <div className="container flex flex-col space-y-8">
            <section>
              <OrderHeading label={i18n.value('order.labels.DealTermsHeader')} />
            </section>
            <section>
              <OrderSubHeader label="order.labels.diamonds" className="mb-4" />
              <Diamonds isEditable={false} showDeleted={false} items={order.products}>
                <IndexColumn />
                <ShapeColumn />
                <NameColumn />
                <CertificateColumn />
                <RefPriceColumn />
                <RapaportColumn />
                <PriceColumn />
                <DeliveryColumn />
              </Diamonds>
              <div className="flex mt-4">
                <div className="flex mr-40 pr-2 ml-auto">
                  <DeliveryPrice isEditable={false} order={order} />
                </div>
              </div>
            </section>
            {hasAdditionalGoods ? (
              <section>
                <OrderSubHeader label="order.labels.additionalItems" className="mb-4" />
                <Diamonds isEditable={false} showDeleted={false} items={order.additionalGoods}>
                  <IndexColumn />
                  <NameColumn label="order.diamonds.goodsName" grow />
                  <PriceColumn itemKey="additionalGoods" />
                </Diamonds>
                <div className="flex mt-4">
                  <div className="ml-auto mr-6">
                    <div className="flex">
                      <div className="text-right">
                        <div className={toClassName(['space-x-2', 'text-gray-500'])}>
                          <span>{i18n.value('order.labels.subTotal')}</span>
                        </div>
                        <div className={toClassName(['space-x-2 font-bold', 'text-brown-dim'])}>
                          <span>{i18n.value('order.labels.total')}</span>
                        </div>
                      </div>
                      <div className="ml-10">
                        <div className={toClassName(['flex space-x-2', 'text-brown-dim'])}>
                          <span className="text-brown-dim">
                            {formatNumberToPrice(subtotalAdditional, getPriceFormatter('usd'))}
                          </span>
                        </div>

                        <div className={toClassName(['flex space-x-2 font-bold', 'text-brown-dim'])}>
                          <span className="text-brown-dim">{formatNumberToPrice(total, getPriceFormatter('usd'))}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            <section>
              <PaymentTermsSection isEditable={false} values={order} />
            </section>
            <section>
              <AddressSection values={order} />
            </section>
            <section>
              <ReturnTermsSection isEditable={false} values={order} />
            </section>
            {order.specialTerms && (
              <section>
                <SpecialTermsSection isEditable={false} values={order} />
              </section>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}
ViewTermsButton.propTypes = {
  order: PropTypes.object.isRequired,
}
export default memo(ViewTermsButton)
