import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.71728 3.2H8.00524V0H5.37173C1.64398 0 1.73298 2.80427 1.73298 3.22443V5.33496H0V8.53496H1.73298C1.73298 12.0476 1.73298 16 1.73298 16H5.1466C5.1466 16 5.1466 11.9744 5.1466 8.53496H7.43456L8.00524 5.33496H5.1466V3.73252C5.1466 3.38565 5.35602 3.2 5.71728 3.2Z"
      fill="#AA8A9F"
    />
  </svg>
)

export default memo(SvgComponent)
