import React, { memo } from 'react'
import Column from './Column'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import { toClassName } from 'root/constants'

const NameColumn = ({
  children,
  itemKey,
  isEditable = false,
  grow = false,
  label = 'order.diamonds.name',
  ...props
}) => (
  <Column
    label={label}
    columnClass={toClassName(['text-center', isEditable ? 'w-128' : grow ? 'flex-grow' : 'w-64'])}
    className={toClassName([isEditable ? 'w-128' : grow ? 'flex-grow' : 'w-64', 'text-center text-brown-late'])}
    {...props}
  >
    {children ||
      (({ item }) =>
        isEditable ? (
          <InputTermFormik
            itemKey={`${itemKey}['${item.id}'].name`}
            value={item.name}
            isEditable={isEditable}
            inputClass="w-full text-sm"
            validate={(value) => {
              if (!value.trim()) return 'Name is required'
            }}
          />
        ) : (
          <a href={item.productUrl} target="_blank" className="hover:underline" rel="noreferrer">
            {item.name}
          </a>
        ))}
  </Column>
)

NameColumn.propTypes = Column.propTypes

export default memo(NameColumn)
