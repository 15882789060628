import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ACTION_CANCEL_MEMO_REQUEST,
  ACTION_CONFIRM_EXISTING_MEMO,
  ACTION_CONFIRM_NEW_MEMO,
  RESPONSIBLE_SELLER,
} from 'root/modules/orders/constants'
import OrderSubHeader from 'root/components/OrderSubHeader'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import Diamonds, {
  CertificateColumn,
  DeliveryColumn,
  IndexColumn,
  NameColumn,
  PriceColumn,
  RapaportColumn,
  RefPriceColumn,
  ShapeColumn,
} from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Diamonds'
import i18n from 'root/i18n'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'
import { timestampToDate } from 'root/constants'
import ResponsibleSelectorFormik
  from 'root/modules/orders/pages/components/ResponsibleSelector/formik'
import InputTermFormik
  from 'root/modules/orders/pages/components/InputTerm/formik'
import ViewOrderFiles from 'root/components/ViewOrderFiles'

const MemoRequestBuyer = ({ doAction, order }) => {
  const canConfirmMemo = order.actions.some(
    (action) => action.url === ACTION_CONFIRM_NEW_MEMO)
  const canConfirmExisting = order.actions.some(
    (action) => action.url === ACTION_CONFIRM_EXISTING_MEMO)
  const canRejectMemo = order.actions.some(
    (action) => action.url === ACTION_CANCEL_MEMO_REQUEST)

  const [loading, setLoading] = useState(false)

  const doMemoAction = useCallback(
    async (action) => {
      setLoading(true)
      try {
        return await doAction(action)
      } catch (e) {
        setLoading(false)
      }
    },
    [doAction],
  )

  return (
    <>
      <section>
        <OrderSubHeader label="order.labels.generalTerms" className="mb-4"/>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          validFor={order.dealValidDays}
          seller={order.sellerCompanyId.name}
          asSeller={false}
        >
          <ViewOrderFiles order={order} doAction={doAction}/>
        </GeneralTerms>
      </section>

      <section>
        <OrderSubHeader label="order.labels.diamonds" className="mb-4"/>
        <Diamonds isEditable={false} showDeleted={false} items={order.products}>
          <IndexColumn/>
          <ShapeColumn/>
          <NameColumn/>
          <CertificateColumn/>
          <RefPriceColumn/>
          <RapaportColumn/>
          <PriceColumn/>
          <DeliveryColumn/>
        </Diamonds>
      </section>

      <section>
        <OrderSubHeader label="order.labels.dealRequestStatus"
                        className="mb-4"/>
        <pre className="text-gray-500 font-bold">
          {i18n.value(order.memoId
            ? 'order.labels.existingMemoInitiated'
            : 'order.labels.newMemoInitiated')}
        </pre>
      </section>

      {order.memoId && (
        <section>
          <div
            className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
            <OrderSubHeader label={'orders.labels.memoAgreementTerms'}/>
          </div>
          <div className="container bg-white-smoke px-8 py-8">
            <div className="container flex justify-between">
              <div className={'my-2'}>
                <span className="text-gray-500">{i18n.value(
                  'common.date')}</span>
                <span className="text-brown-dim ml-2">
                  {order.memoId
                    ? timestampToDate(order.memoId.created)
                    : order.date}
                </span>
              </div>
              <div className={'my-2'}>
                <span className="text-gray-500">{i18n.value(
                  'orders.labels.memoConsignmentAgreement')}</span>
                <span
                  className="text-brown-dim ml-2">#{order.memoId.number}</span>
              </div>
            </div>
          </div>

          <div className="container mx-auto lg:w-screen-lg-min mb-2 mt-4">
            <div className="text-gray-500 mt-8 flex justify-between">
              <div className="grid grid-cols-2 gap-y-3 auto-rows-min">
                <ResponsibleSelectorFormik
                  label={'orders.labels.taxResponsible'}
                  itemKey={'isTaxesResponsibleSeller'}
                  isSeller={order.memoId.taxesResponsible ===
                    RESPONSIBLE_SELLER}
                  isEditable={false}
                />
                <ResponsibleSelectorFormik
                  label={'orders.labels.bankResponsible'}
                  itemKey={'isBankResponsibleSeller'}
                  isSeller={order.memoId.bankCommissionResponsible ===
                    RESPONSIBLE_SELLER}
                  isEditable={false}
                />
                <ResponsibleSelectorFormik
                  label={'orders.labels.deliveryPaymentResponsible'}
                  itemKey={'isDeliveryResponsibleSeller'}
                  isSeller={order.memoId.deliveryPaymentsResponsible ===
                    RESPONSIBLE_SELLER}
                  isEditable={false}
                />
                <ResponsibleSelectorFormik
                  label={'orders.labels.returnPaymentResponsible'}
                  itemKey={'isReturnResponsibleSeller'}
                  isSeller={order.memoId.returnPaymentResponsible ===
                    RESPONSIBLE_SELLER}
                  isEditable={false}
                />
              </div>

              <div className="grid grid-cols-3 gap-y-3 auto-rows-min">
                <InputTermFormik
                  labelBefore={'orders.labels.memoPaymentTermDays'}
                  inputClass={'w-16'}
                  labelAfter={'common.days'}
                  itemKey={'memoPaymentTermDays'}
                  value={order.memoId.paymentTermDays}
                  isEditable={false}
                />
                <InputTermFormik
                  labelBefore={'orders.labels.nonReturnPeriod'}
                  inputClass={'w-16'}
                  labelAfter={'common.days'}
                  itemKey={'nonReturnPeriod'}
                  value={order.memoId.nonReturnDays}
                  isEditable={false}
                />
                <InputTermFormik
                  labelBefore={'orders.labels.returnShipmentPeriod'}
                  inputClass={'w-16'}
                  labelAfter={'common.days'}
                  itemKey={'returnShipmentPeriod'}
                  value={order.memoId.returnShipmentDays}
                  isEditable={false}
                />
                <InputTermFormik
                  labelBefore={'orders.labels.memoPenaltyDelayPerDay'}
                  inputClass={'w-16'}
                  labelAfter={'common.percent'}
                  itemKey={'memoPenaltyDelayPerDay'}
                  value={order.memoId.dailyDelayedPaymentFee}
                  isEditable={false}
                />
              </div>
            </div>
            {order.memoId.specialTerms && (
              <div
                className="container mx-auto mb-2 mt-4 text-gray-500 p-2 border border-gray-300">
                <pre
                  className="break-all whitespace-pre-wrap">{order.memoId.specialTerms}</pre>
              </div>
            )}
          </div>
        </section>
      )}

      <section className="flex items-center space-x-4">
        {canConfirmMemo && (
          <BrownButton
            type="button"
            className="mr-4"
            onClick={async () => {
              await doMemoAction(ACTION_CONFIRM_NEW_MEMO)
            }}
            disabled={loading}
            label="orders.buttons.confirm"
          />
        )}
        {canConfirmExisting && (
          <BrownButton
            type="button"
            className="mr-4"
            onClick={async () => {
              await doMemoAction(ACTION_CONFIRM_EXISTING_MEMO)
            }}
            disabled={loading}
            label="orders.buttons.confirm"
          />
        )}
        {canRejectMemo && (
          <BlueButton
            type="button"
            className="mr-4"
            onClick={async () => {
              await doMemoAction(ACTION_CANCEL_MEMO_REQUEST)
            }}
            disabled={loading}
            label="orders.buttons.reject"
          />
        )}
      </section>
    </>
  )
}

MemoRequestBuyer.propTypes = {
  doAction: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
}
export default memo(MemoRequestBuyer)
