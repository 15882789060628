import { connect } from 'react-redux'
import {
  getInitialTermsData,
  getOrderInitialTerms,
  getInitialTermsLoading,
  getInitialTermsVisible,
} from 'root/modules/orders/selectors'
import { hideInitialTerms } from 'root/modules/orders/thunks'

const mapStateToProps = (state) => ({
  withoutNames: getOrderInitialTerms(state).withoutNames,
  isVisible: getInitialTermsVisible(state),
  isLoading: getInitialTermsLoading(state),
  terms: getInitialTermsData(state),
})

const mapDispatchToProps = {
  close: hideInitialTerms,
}

export default connect(mapStateToProps, mapDispatchToProps)
