import { connect } from 'react-redux'

import { getActiveOrderData, isActiveOrderLoading, isBuyer, isSeller } from 'root/modules/orders/selectors'
import { loadOrder, performOrderAction } from 'root/modules/orders/thunks'
import { getProfileCompanyData, isProfileCompanyLoading } from 'root/modules/profile/selectors'
import { loadCompanyCommonInfo } from 'root/modules/profile/thunks'
import { getUserData } from '../../../../selectors'

const mapStateToProps = (state) => ({
  data: getActiveOrderData(state),
  companyData: getProfileCompanyData(state),
  isProfileCompanyLoading: isProfileCompanyLoading(state),
  isLoading: isActiveOrderLoading(state),
  isSeller: isSeller(state),
  isBuyer: isBuyer(state),
  user: getUserData(state),
})

const mapDispatchToProps = {
  loadOrder,
  loadCompanyCommonInfo,
  performOrderAction,
}

export default connect(mapStateToProps, mapDispatchToProps)
