import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

import './styles.css'
import connect from './connect'
import CodeInput from './components/CodeInput'
import Resend from './components/Resend'
import {
  COUNTRY_INFO_BY_CODE,
  doNothing,
  errorAlert,
  getErrorMessage,
  isValidPhone,
  validateEmail,
  handleChange,
  toClassName,
} from 'root/constants'
import { CODE_LENGTH } from 'root/modules/login/constants'
import Phone from './components/Phone'
import { askHelpWithLogin, sessionSign } from 'root/api'
import LoginTabs from 'root/modules/login/pages/Login/components/LoginTabs'
import i18n from 'root/i18n'
import { confirmAlert, infoAlert } from 'root/components/AlertWrapper'
import { SITE_URL } from 'root/api/env'
import CheckboxInput from './components/CheckboxInput'

const getFullPhone = (countryCode, phone) =>
  !countryCode ? '' : `${COUNTRY_INFO_BY_CODE[countryCode].dialCode}-${phone}`

const checkPassword = (password) => !!password?.trim()?.length

function Login({ confirmSms, loginByPassword, isSignUp, user }) {
  const [locked, setLocked] = useState(false)
  const [isPhone, setIsPhone] = useState(true)
  const [hasPassword, setHasPassword] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [countryCode, setCountryCode] = useState(null)

  const [oneTimeKey, setOneTimeKey] = useState()
  const codeInputMode = !!oneTimeKey

  const isPhoneValid = useMemo(() => isValidPhone(countryCode, phone), [countryCode, phone])
  const isPasswordValid = useMemo(() => checkPassword(password), [password])
  const isEmailValid = useMemo(() => validateEmail(email), [email])

  const isLoginValid = !isPhone ? isEmailValid : isPhoneValid
  const canSend = isLoginValid && (!hasPassword || isPasswordValid)

  const { search } = useLocation()

  const { replace, location } = useHistory()
  const redirect = useCallback(() => {
    const params = new URLSearchParams(search)
    if (params.has('return') && user?.activated) {
      window.location = SITE_URL + decodeURI(params.get('return'))
    } else return replace({ pathname: location.state?.from || '/company', search: search })
  }, [search, replace, location.state?.from, user])
  const login = isPhone ? getFullPhone(countryCode, phone) : email

  const changeType = useCallback(
    (isPhone) => {
      if (!codeInputMode) setIsPhone(isPhone)
      else
        confirmAlert('Are you sure that you want to reset login type?')
          .then(() => {
            setIsPhone(isPhone)
            setOneTimeKey(undefined)
          })
          .catch(doNothing)
    },
    [codeInputMode],
  )
  const requestCode = useCallback(
    () =>
      sessionSign(login)
        .then(
          ({
            data: {
              data: { oneTimeKey },
            },
          }) => setOneTimeKey(oneTimeKey),
        )
        .finally(() => setLocked(false)),
    [login],
  )

  const askForHelpWithLogin = useCallback(() => askHelpWithLogin(login), [login])

  const sendConfirmation = useCallback(
    (code) => {
      setLocked(true)
      return confirmSms(oneTimeKey, code)
        .then(redirect)
        .finally(() => setLocked(false))
    },
    [confirmSms, redirect, oneTimeKey],
  )

  const onLoginByPassword = useCallback(() => {
    loginByPassword(login, password)
      .then(redirect)
      .catch((e) => infoAlert(getErrorMessage(e)))
      .finally(() => setLocked(false))
  }, [login, loginByPassword, password, redirect])

  const logInPress = useCallback(() => {
    setLocked(true)
    if (hasPassword) onLoginByPassword()
    else requestCode().catch(errorAlert)
  }, [hasPassword, onLoginByPassword, requestCode])

  const onKeyDown = useCallback(
    ({ code }) => {
      if (code === 'Enter' && canSend) logInPress()
    },
    [canSend, logInPress],
  )

  return (
    <div className="login-container mb:px-4 mb:w-84">
      <LoginTabs changeType={changeType} isPhone={isPhone} />

      {isSignUp && (
        <div className="login-title">
          {codeInputMode ? (
            <>
              {i18n.value('login.enterAccessCode')}
              {i18n.value('login.' + (isPhone ? 'phone' : 'email')).toLowerCase()}
            </>
          ) : (
            <>
              {i18n.value('login.toGetAccessCode')}
              {i18n.value('login.' + (isPhone ? 'phoneNumber' : 'emailAddress')).toLowerCase()}
            </>
          )}
        </div>
      )}

      {codeInputMode ? (
        <>
          <CodeInput count={CODE_LENGTH} disabled={locked} onValid={sendConfirmation} />
          <Resend onResend={requestCode} onAskForHelp={askForHelpWithLogin} />
        </>
      ) : (
        <>
          {isPhone ? (
            <Phone
              phone={phone}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              setPhone={setPhone}
              isPhoneValid={isPhoneValid}
              disabled={locked}
              onKeyDown={onKeyDown}
              tabIndex={1}
            />
          ) : (
            <input
              value={email}
              onKeyDown={onKeyDown}
              tabIndex={1}
              placeholder="login@lgdeal.com"
              className={toClassName(['input-form', !isEmailValid && 'auth-form__input--error'])}
              onChange={handleChange(setEmail)}
            />
          )}
          {!isSignUp && (
            <CheckboxInput
              id="login-has-password"
              labelAfter={i18n.value('login.hasPassword')}
              onChange={setHasPassword}
              value={hasPassword}
              className="login-has-password-input"
            />
          )}
          <div className={toClassName(['login-password-input', !hasPassword && 'transition-hidden'])}>
            <input
              placeholder={'Enter password'}
              onKeyDown={onKeyDown}
              type="password"
              disabled={!hasPassword}
              className={toClassName(['input-form w-full', !isPasswordValid && 'auth-form__input--error'])}
              tabIndex={hasPassword ? 2 : -1}
              value={password}
              onChange={handleChange(setPassword)}
            />
          </div>
          <button className="login-button default-button" onClick={logInPress} disabled={!canSend}>
            {i18n.value('login.' + (hasPassword ? 'logIn' : 'getAccessCode'))}
          </button>
          {isSignUp && (
            <div className="login-agreement">
              {i18n.value('login.agreement1')}
              <a href={SITE_URL + '/static/lgdeal-docs/terms-of-use.pdf'}>{i18n.value('login.agreement2')}</a>
              {i18n.value('login.agreement3')}
              <a href={SITE_URL + '/privacy-policy'}>{i18n.value('login.agreement4')}</a>
            </div>
          )}
        </>
      )}
    </div>
  )
}

Login.propTypes = {
  confirmSms: PropTypes.func.isRequired,
  loginByPassword: PropTypes.func.isRequired,
  isSignUp: PropTypes.bool,
  user: PropTypes.object,
}

export default connect(Login)
