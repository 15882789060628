import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { handleChange, toClassName } from 'root/constants'
import { CheckBoxCheckedIcon, CheckBoxIcon } from 'root/icons'
import './styles.css'

function CheckboxInput({ value, labelAfter, labelBefore, onChange, id, className = '', ...rest }) {
  const Icon = value ? CheckBoxCheckedIcon : CheckBoxIcon
  const onValueChange = useMemo(() => handleChange(onChange), [onChange])
  return (
    <div className={'checkbox-input ' + className}>
      <input type="checkbox" id={id} value={value} onChange={onValueChange} {...rest} />
      <label htmlFor={id} className={toClassName(['text-gray-500 hover:text-brown-dim', value && 'text-brown-dim'])}>
        {labelBefore && <span className="mr-2">{labelBefore}</span>}
        <Icon className={null} />
        {labelAfter && <span className="ml-2">{labelAfter}</span>}
      </label>
    </div>
  )
}

CheckboxInput.propTypes = {
  value: PropTypes.bool,
  labelAfter: PropTypes.string,
  labelBefore: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default CheckboxInput
