import React, { memo } from 'react'
import Column from './Column'
import Price from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Price'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import { calcAverageR, calcRefPriceDiscount, priceSchema, roundTwo } from 'root/modules/orders/constants'
import { trimNumber } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'
import { COLOR_FANCY } from '../../../../../../../../products/constants'

const validateRefPrice = (refPrice, price) => {
  let error
  if (!refPrice) {
    error = 'Reference price value is required'
  } else if (!price) {
    error = 'Price value is required'
  } else if (refPrice < price) {
    error = `Reference price can\'t be less than price`
  }
  return error
}

const RefPriceColumn = ({ children, ...props }) => (
  <Column
    label="order.diamonds.referencePrice"
    columnClass="w-24 text-center"
    className="w-24 text-center text-blue-dark flex items-center justify-center"
    {...props}
  >
    {children ||
      (({ item, isEditable, formContext }) => {
        if (item.colorType.id == COLOR_FANCY) {
          return '-'
        }
        return isEditable ? (
          <div className="flex items-center">
            {item.unitReferencePrice.symbol}
            <InputTermFormik
              itemKey={`orderItems['${item.id}'].referencePrice`}
              value={item.unitReferencePrice.value}
              isEditable={isEditable}
              inputClass="w-16 text-sm"
              onChange={async (e) => {
                const { values, setFieldValue } = formContext
                setFieldValue(`orderItems['${item.id}'].referencePrice`, trimNumber(e.target.value))
                const referencePrice = Number.parseFloat(e.target.value)
                if (await priceSchema.isValid(referencePrice)) {
                  const { orderItems } = values
                  const price = Number.parseFloat(orderItems[item.id].price)
                  if (Number.isNaN(price) || !referencePrice) return

                  const referenceDiscount = roundTwo(calcRefPriceDiscount(price, referencePrice))
                  orderItems[item.id].referenceDiscount = referenceDiscount
                  setFieldValue(`orderItems['${item.id}'].referenceDiscount`, referenceDiscount)
                  setFieldValue(`averageR`, calcAverageR(orderItems))
                }
              }}
              validate={() => {
                const { values } = formContext
                const { orderItems } = values
                const referencePrice = Number.parseFloat(orderItems[item.id].referencePrice)
                const price = Number.parseFloat(orderItems[item.id].price)

                return validateRefPrice(referencePrice, price)
              }}
            />
          </div>
        ) : (
          <div className="flex">
            <Price {...item.unitReferencePrice} />
            {item?.termsChanges?.unitReferencePrice && (
              <i className="ml-1 text-brown-dim">
                <ExclaimIcon width={12} height={12} />
              </i>
            )}
          </div>
        )
      })}
  </Column>
)

RefPriceColumn.propTypes = Column.propTypes

export default memo(RefPriceColumn)
