import { connect } from 'react-redux'

import { getCompanyTeamData, isCompanyTeamLoading } from 'root/modules/profile/selectors'
import { loadCompanyTeam, saveCompanyTeamThunk } from 'root/modules/profile/thunks'
import { canSupervise } from 'root/selectors'

const mapStateToProps = (state) => ({
  data: getCompanyTeamData(state),
  isLoading: isCompanyTeamLoading(state),
  canSupervise: canSupervise(state),
})

const mapDispatchToProps = {
  loadCompanyTeam,
  saveCompanyTeamThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)
