import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'

import connect from './connect'
import TabButton from './components/TabButton'
import OrderListTable from './components/OrderListTable'
import './styles.css'
import { ORDER_DEAL_TYPES, STATUS_BY_DEAL_TYPE } from 'root/modules/orders/constants'
import { Link, useParams } from 'react-router-dom'
import Spinner from 'root/components/Spinner'
import { CURRENCY_CODES, formatNumberToPrice, formatPrice, getPriceFormatter, handleChange } from 'root/constants'
import StatusTabItem from 'root/components/StatusTabItem'
import MessageBox from 'root/components/MessageBox'
import { loadCompanyCommonInfo } from 'root/modules/profile/thunks'
import BrownButton from 'root/components/Buttons/BrownButton'
import i18n from 'root/i18n'
import Button from 'root/components/Buttons/Button'

const FOOTER_HEIGHT = 258
const paddingBottom = 175
const fromBottom = FOOTER_HEIGHT + paddingBottom + 100

const OrderList = ({ data, loadData, loadCompanyCommonInfo, notificationSummary, updateFilters, company }) => {
  const { section } = useParams()
  const { items: orders, filters, page } = data
  const isLoading = page === 0

  useEffect(() => {
    loadData(true, section)
    loadCompanyCommonInfo()
  }, [loadData, section, filters])

  useEffect(() => {
    const handleScroll = () => {
      const fromEnd = document.body.offsetHeight - window.scrollY - window.innerHeight
      if (fromBottom >= fromEnd) loadData(false, section)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [loadData, section])

  const { dt: activeDealType, s: activeTab, q: query } = filters
  const setQuery = (q) => updateFilters({ q })
  const selectTab = (s) => updateFilters({ s })
  const selectDealType = (dt) => updateFilters({ dt, s: '', q: '' })

  return (
    <main>
      {company.paymentAmount > 0 ? (
        <MessageBox className="mb-10">
          <div>
            Your current debt is <strong>{formatNumberToPrice(company.paymentAmount, getPriceFormatter('usd'))}</strong>
          </div>
          {company.paymentLink ? (
            <div>
              You should&nbsp;
              <a
                target="_blank"
                className="hover:underline text-blue-skyblue font-bold"
                href={company.paymentLink}
                rel="noreferrer"
              >
                pay it
              </a>
              &nbsp; as soon as you can.
            </div>
          ) : null}
          <div className="pt-4 text-sm">
            If you have already paid the debt or current debt is incorrect, please contact our{' '}
            <a href="mailto:info@lgdeal.com" className="underline text-sm">
              manager
            </a>
            .
          </div>
        </MessageBox>
      ) : null}
      {!company.hasFullCreditInfo ? (
        <MessageBox className="mb-10">
          <Link to={`/company/credit-info`}>
            <div className="text-gray-500 mb-2">{i18n.value('orders.labels.creditInfoRequired')}</div>
            <Button className="bg-brown-dim-light" slim type="button" label={'profile.buttons.creditInfo'} />
          </Link>
        </MessageBox>
      ) : null}
      <div className="h-max-content flex items-left text-gray-500 container mx-auto lg:w-screen-lg-min space-x-4">
        {ORDER_DEAL_TYPES.map((item, index) => (
          <TabButton
            notifications={notificationSummary[section] && notificationSummary[section][item]?.all}
            onClick={() => selectDealType(item)}
            isActive={item === activeDealType}
            key={index}
            item={item}
          />
        ))}
      </div>
      <div className="h-max-content container mx-auto mt-5 mb-5">
        <div className="bg-white rounded-md border-b border-gray-300 shadow justify-between flex items-center container mx-auto lg:w-screen-lg-min">
          {STATUS_BY_DEAL_TYPE[activeDealType].map((item) => (
            <StatusTabItem
              labelPrefix={'orders.status'}
              notifications={
                notificationSummary[section] &&
                notificationSummary[section][activeDealType] &&
                notificationSummary[section][activeDealType][item.key || 'all']
              }
              onClick={() => selectTab(item.key)}
              isActive={item.key === activeTab}
              key={item.key}
              item={item}
            />
          ))}
        </div>
      </div>
      <div className="h-max-content container mx-auto mt-8 mb-8">
        <input
          type="text"
          className="bg-white shadow-inner border mx-auto lg:w-screen-lg-min p-2 text-gray-500"
          placeholder="2.08 ct white"
          value={query}
          onChange={handleChange(setQuery)}
        />
      </div>
      {isLoading ? (
        <div className="container mx-auto mt-8 flex justify-center">
          <Spinner />
        </div>
      ) : (
        <OrderListTable orders={orders} section={section} activeDealType={activeDealType} />
      )}
    </main>
  )
}

OrderList.propTypes = {
  data: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  notificationSummary: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
}

export default memo(connect(OrderList))
