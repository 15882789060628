import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ width = 21, height = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4518 23H1.38941C0.622056 23 0 22.3593 0 21.5689V7.25858V5.8275L18.0624 0.103321C18.3882 0.0138256 18.6558 -0.0532332 18.9308 0.0585736C19.2878 0.203709 19.4518 0.423499 19.4518 0.818684V5.8275C20.2193 5.8275 20.8414 6.46821 20.8414 7.25858V21.5689C20.8414 22.3593 20.2193 23 19.4518 23ZM18.0624 1.53442L4.51558 5.8275H18.0624V1.53442ZM19.4518 15.1292C19.4518 14.7342 19.4518 14.9504 19.4518 14.4137H16.6729C16.6729 14.734 16.6729 14.7342 16.6729 15.1292C16.6729 15.5244 16.6729 15.3821 16.6729 15.8447H19.4518C19.4518 15.3528 19.4518 15.5242 19.4518 15.1292ZM1.38941 7.2584L18.7572 7.25858C19.1408 7.25858 19.4518 7.57876 19.4518 7.97394V12.9828H15.9782C15.5946 12.9828 15.2835 13.3031 15.2835 13.6981V16.5603C15.2835 16.9555 15.5946 17.2758 15.9782 17.2758H19.4518V20.8534C19.4518 21.2485 19.1408 21.5689 18.7572 21.5689H2.08412C1.70036 21.5689 1.38941 21.2485 1.38941 20.8534C1.38941 20.8534 1.38941 7.75035 1.38941 7.2584Z"
      fill="currentColor"
    />
  </svg>
)

SvgComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}
export default memo(SvgComponent)
