import React, { memo } from 'react'

import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import PropTypes from 'prop-types'
import connect from './connect'
import Spinner from 'root/components/Spinner'
import { Form, Formik } from 'formik'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import { ACTION_SAVE_COMPANY_PROFILE } from 'root/modules/profile/constants'
import FormikToggle from 'root/components/FormikToggle'
import { ensureBoolean, errorAlert } from 'root/constants'

const submittingButtons = [
  { color: 'brown', label: 'common.save', action: ACTION_SAVE_COMPANY_PROFILE, className: 'profile-button' },
]
const GoodsModal = ({ company, isLoading, loadCompanyCommonInfo, saveCompanyProfileThunk, canSupervise }) => {
  if (isLoading || !company.id) return <Spinner />

  if (!canSupervise) {
    return (
      <Modal>
        <div>{i18n.value('no_access')}</div>
      </Modal>
    )
  }

  return (
    <Modal>
      <div>
        <OrderHeading label={i18n.value('profile.company.labels.additionalGoods')} />

        <Formik
          enableReinitialize
          initialValues={{
            saleNotCert: ensureBoolean(company.saleNotCert),
            saleMelee: ensureBoolean(company.saleMelee),
            saleRelated: ensureBoolean(company.saleRelated),
          }}
          onSubmit={async (values) => {
            let serverData = { ...values }
            return saveCompanyProfileThunk(serverData).then(loadCompanyCommonInfo).catch(errorAlert)
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, handleSubmit, resetForm, setSubmitting, setValues }) => (
            <Form onSubmit={handleSubmit}>
              <div className="container mb:p-4">
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <div className="flex-col mb:flex-col mb:space-x-0 space-y-4 my-10">
                    <div className="mt-2 flex items-left items-center text-brown-dim space-x-4">
                      <label className="text-gray-500 flex space-x-4">
                        <span>{i18n.value('profile.company.labels.saleMelee')}</span>
                        <FormikToggle itemKey="saleMelee" labelOn="common.yes" labelOff="common.no" />
                      </label>
                    </div>

                    <div className="mt-2 flex items-left items-center text-brown-dim space-x-4">
                      <label className="text-gray-500 flex space-x-4">
                        <span>{i18n.value('profile.company.labels.saleNotCert')}</span>
                        <FormikToggle itemKey="saleNotCert" labelOn="common.yes" labelOff="common.no" />
                      </label>
                    </div>

                    <div className="mt-2 flex items-left items-center text-brown-dim space-x-4">
                      <label className="text-gray-500 flex space-x-4">
                        <span>{i18n.value('profile.company.labels.saleRelated')}</span>
                        <FormikToggle itemKey="saleRelated" labelOn="common.yes" labelOff="common.no" />
                      </label>
                    </div>

                    <div>
                      <FormikSubmittingButtons
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        buttons={submittingButtons}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

GoodsModal.propTypes = {
  company: PropTypes.object,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
  saveCompanyProfileThunk: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  canSupervise: PropTypes.bool,
}

export default memo(connect(GoodsModal))
