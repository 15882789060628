import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import BrownButton from 'root/components/Buttons/BrownButton'
import Modal from 'root/components/Modal'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import i18n from 'root/i18n'
import { Form, Formik } from 'formik'
import InputTermFormik
  from 'root/modules/orders/pages/components/InputTerm/formik'
import { findDiamondByCert } from 'root/api'
import { ACTION_ADD_PRODUCT } from 'root/modules/orders/constants'
import Button from 'root/components/Buttons/Button'

const AddProductButton = ({ doAction }) => {
  const [opened, setOpened] = useState(false)
  const [notFound, setNotFound] = useState(false)
  return (
    <>
      <Button
        className="bg-brown-dim-light"
        type="button"
        slim
        onClick={() => setOpened(true)}
        label="orders.buttons.addProduct"
      />
      {opened && (
        <Modal onClick={() => setOpened(false)}>
          <OrderHeading label={i18n.value('order.labels.addDiamondsHeader')}/>
          <Formik
            initialValues={{ search: '' }}
            onSubmit={async (values) => {
              setNotFound(false)
              if (values.search) {
                const {
                  data: { data },
                } = await findDiamondByCert(values.search)
                const notFound = data.length !== 1
                setNotFound(notFound)
                if (!notFound) {
                  const [{ id }] = data
                  await doAction(ACTION_ADD_PRODUCT, {
                    items: [id],
                  })
                  setOpened(false)
                }
              } else {
                setNotFound(true)
              }
            }}
          >
            {({ values, isSubmitting, handleSubmit, errors }) => (
              <Form onSubmit={handleSubmit}
                    className="flex flex-col justify-center">
                <InputTermFormik
                  inputClass="w-full mt-2"
                  labelBefore="order.labels.addDiamondsHint"
                  itemKey="search"
                  isEditable
                />
                {notFound && (
                  <div className="text-brown-dim flex justify-center mt-4">
                    {i18n.value('order.labels.addDiamondsNotFound')}
                  </div>
                )}
                <div className="flex mt-4 justify-center">
                  <BrownButton disabled={isSubmitting}
                               label="orders.buttons.addProduct"/>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  )
}
AddProductButton.propTypes = {
  doAction: PropTypes.func.isRequired,
}
export default memo(AddProductButton)
