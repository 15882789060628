import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ACTION_MEMO_NEGOTIATION_FINAL_TERMS,
  ACTION_MEMO_NEGOTIATION_UPDATE_TERMS,
} from 'root/modules/orders/constants'
import DealNegotiationMemoForm
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/DealNegotiationMemo/DealNegotiationMemoForm'

const NewNegotiationStarted = ({
  order,
  performOrderAction,
  readOnly = false,
}) => {
  let buttons = [
    {
      color: 'brown',
      label: 'orders.buttons.offerMemo',
      action: ACTION_MEMO_NEGOTIATION_UPDATE_TERMS,
    },
    {
      color: 'blue',
      label: 'orders.buttons.finalOffer',
      action: ACTION_MEMO_NEGOTIATION_FINAL_TERMS,
    },
  ]
  if (readOnly) buttons = []
  return (
    <DealNegotiationMemoForm
      isEditable={!readOnly}
      order={order}
      performOrderAction={performOrderAction}
      buttons={buttons}
      asSeller
    />
  )
}

NewNegotiationStarted.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default memo(NewNegotiationStarted)
