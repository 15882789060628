import { handleActions } from 'redux-actions'
import * as actions from './actions'

const defaultState = {
  opened: false,
}

export default handleActions(
  {
    [actions.openChat]: (state) => ({ ...state, opened: true }),
    [actions.toggleIsOpen]: (state) => ({ ...state, opened: !state.opened }),
  },
  defaultState,
)
