import * as actions from 'root/actions'
import { loadUser } from 'root/thunks/user'
import { cookieExists, doOrDie, isAuthError, LGDEAL_COOKIE_INFO } from 'root/constants'

export const initApp = () => async (dispatch) => {
  dispatch(actions.appInitStart())
  if (cookieExists(LGDEAL_COOKIE_INFO.name)) {
    await dispatch(initAuthData()).catch(() => dispatch(actions.userLogout()))
  } else dispatch(actions.userLogout())
  dispatch(actions.appInitSuccess())
}

export const initAuthData = () => async (dispatch) => {
  await doOrDie(() => dispatch(loadUser()), isAuthError)
}
