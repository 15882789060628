import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TrackingItem from './components/TrackingItem'

const TrackingItems = ({ shipments, action, isSubmitting, handleSubmit, setFieldValue, isReceiver, senderId }) => {
  //filter only those to track which are for this particular section
  return (
    <>
      {shipments.map((shipment) => {
        return (
          senderId === shipment.senderCompany.id && (
            <div className={'my-8'} key={shipment.id}>
              <TrackingItem
                shipment={shipment}
                action={action}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                isReceiver={isReceiver}
              />
            </div>
          )
        )
      })}
    </>
  )
}

TrackingItems.propTypes = {
  shipments: PropTypes.array.isRequired,
  senderId: PropTypes.string.isRequired,
  isReceiver: PropTypes.bool,
  action: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default memo(TrackingItems)
