import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { ErrorMessage, Form, Formik } from 'formik'
import {
  ACTION_MEMO_PAD_CONFIRM_PAYMENT_SELLER,
  ACTION_MEMO_PAD_EXTRACT_STONES,
  ACTION_MEMO_PAD_ISSUE_INVOICE_FOR_BUYER,
  ACTION_MEMO_PAD_RECEIVE_BY_BUYER,
  ACTION_MEMO_PAD_RECEIVE_BY_SELLER,
  ACTION_MEMO_PAD_REQUEST_INVOICE_FROM_SELLER,
  ACTION_MEMO_PAD_REQUEST_PAYMENT_FROM_BUYER,
  ACTION_MEMO_PAD_REQUEST_RETURN_FROM_BUYER,
  ACTION_MEMO_PAD_RETURN_TO_SELLER,
  ACTION_MEMO_PAD_SHIP_TO_BUYER,
  ACTION_MEMO_PAD_SHIP_TO_SELLER,
  ACTION_MEMO_PAD_UPLOAD_INVOICE_FOR_BUYER,
  DEAL_TYPE_INVOICE,
  DEAL_TYPE_MEMO,
  SECTION_SALES,
  SHIPMENT_VENDOR_FEDEX,
  SHIPMENT_VENDOR_OTHER,
} from 'root/modules/orders/constants'
import { Link, useParams } from 'react-router-dom'
import ViewMemoDealTerms from './components/ViewMemoDealTerms'
import DiamondStatusBar from './components/DiamondStatusBar'
import { doNothing, errorAlert, getLocalFiles } from 'root/constants'
import ViewOrderMemoDocs
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ViewOrderMemoDocs'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import {
  confirmAlert,
  infoAlert,
  promptAlert,
} from 'root/components/AlertWrapper'
import BlueButton from 'root/components/Buttons/BlueButton'
import OrderTimer from 'root/components/OrderTimer'
import OrderSubHeader from 'root/components/OrderSubHeader'
import { connect } from 'react-redux'
import { openChat } from 'root/modules/chat/actions'
import { downloadKycFile, postOrderMessage } from 'root/api'
import Button from 'root/components/Buttons/Button'

const FormMemoPaymentAndDelivery = ({
  order,
  performOrderAction,
  isEditable = true,
  readOnly,
  asSeller,
  openChat,
}) => {
  const { section } = useParams()
  const heading =
    order.state.key === 'completed'
      ? i18n.value('orders.labels.memoCompleted')
      : i18n.value('orders.labels.memoPaymentAndDelivery')
  let buyerOrSupplierName = ''
  if (section === SECTION_SALES) {
    buyerOrSupplierName = order.buyerCompanyId.name
  } else {
    buyerOrSupplierName = order.sellerCompanyId.name
  }

  const kycAllowed = order.sellerCompanyId.allowKYC && (
    order.sellerCompanyId.requireKycOnInvoice && order.dealType ===
    DEAL_TYPE_INVOICE ||
    order.sellerCompanyId.requireKycOnMemo && order.dealType ===
    DEAL_TYPE_MEMO)
  const asBuyer = !asSeller
  const needProvideKyc = kycAllowed && asBuyer &&
    !order.buyerCompanyId.hasFullKycInfo
  const canRequestKycInfo = kycAllowed && asSeller &&
    !order.buyerCompanyId.hasFullKycInfo
  const canDownloadKyc = kycAllowed && asSeller &&
    order.buyerCompanyId.hasFullKycInfo

  const requestKyc = useCallback(() => {
    return postOrderMessage(order.id, i18n.value('orders.requestKycMessage')).
      then(() => {
        openChat()
      })
  }, [openChat, order.id])

  const downloadKyc = useCallback(() => {
    downloadKycFile(order.id).catch(errorAlert)
  }, [order.id])

  let itemsSelectedInTable = {}
  let orderItemsForInvoice = {}
  if (isEditable) {
    for (let i = 0; i < order.products.length; i++) {
      itemsSelectedInTable[order.products[i].id] = false
    }

    for (let i = 0; i <
    order.metaActions[ACTION_MEMO_PAD_ISSUE_INVOICE_FOR_BUYER].length; i++) {
      orderItemsForInvoice[order.metaActions[ACTION_MEMO_PAD_ISSUE_INVOICE_FOR_BUYER][i]] = false
    }
  }

  const canExtract = order.actions.some(
    (action) => action.url === ACTION_MEMO_PAD_EXTRACT_STONES)
  const extractStonesFromOrder = useCallback(async () => {
    try {
      const reason = await confirmAlert(
        i18n.value('order.labels.doYouConfirmExtractStones'))
      if (reason) {
        const overprice = await promptAlert(
          i18n.value('order.labels.extractStonesOverprice'))
        await performOrderAction(ACTION_MEMO_PAD_EXTRACT_STONES,
          { overprice: overprice || 0 })
        return infoAlert(i18n.value('common.success'))
      }
    } catch (e) {
      doNothing()
    }
  }, [performOrderAction])

  return (
    <Formik
      enableReinitialize
      initialValues={
        isEditable
          ? {
            action: '',
            trackingNumber: '',
            shipmentProvider: SHIPMENT_VENDOR_FEDEX,
            shipmentProviderInput: '',
            orderItemsForShipToBuyer: order.metaActions[ACTION_MEMO_PAD_SHIP_TO_BUYER],
            orderItemsForShipToSeller: order.metaActions[ACTION_MEMO_PAD_SHIP_TO_SELLER],
            orderItemsForInvoice: orderItemsForInvoice,
            itemsSelectedInTable: itemsSelectedInTable,
            buttonSubmitParams: '',
          }
          : {}
      }
      validate={(values) => {
        const errors = {}
        if (!values.action) {
          errors.action = 'Action is not set'
        }
        switch (values.action) {
          case ACTION_MEMO_PAD_SHIP_TO_SELLER:
          case ACTION_MEMO_PAD_SHIP_TO_BUYER:
            if (!values.trackingNumber) {
              errors.trackingNumber = 'Tracking number is required'
            }
            if (values.shipmentProvider === SHIPMENT_VENDOR_OTHER) {
              if (!values.shipmentProviderInput) {
                errors.shipmentProvider = 'Tracking vendor is required in case of "OTHER"'
              }
            }
            break
          default:
            break
        }

        return errors
      }}
      onSubmit={async (values) => {
        let serverValues = {}
        let shipmentProvider,
          shipments = []
        switch (values.action) {
          case ACTION_MEMO_PAD_SHIP_TO_SELLER:
          case ACTION_MEMO_PAD_SHIP_TO_BUYER:
            shipmentProvider = values.shipmentProvider
            if (values.shipmentProvider === SHIPMENT_VENDOR_OTHER) {
              shipmentProvider = values.shipmentProviderInput
            }

            // eslint-disable-next-line no-case-declarations
            let tmpItems =
              values.action === ACTION_MEMO_PAD_SHIP_TO_SELLER
                ? values.orderItemsForShipToSeller
                : values.orderItemsForShipToBuyer

            // eslint-disable-next-line no-case-declarations
            let finalItems = []
            Object.keys(tmpItems).forEach(function (itemId) {
              const tmpItem = tmpItems[itemId]
              if (tmpItem.hasStone || tmpItem.hasCertificate) {
                finalItems.push(tmpItem)
              }
            })

            shipments.push({
              vendor: shipmentProvider,
              trackNumber: values.trackingNumber,
              items: finalItems,
            })
            serverValues = {
              shipments: shipments,
            }
            break
          case ACTION_MEMO_PAD_RECEIVE_BY_BUYER:
          case ACTION_MEMO_PAD_RECEIVE_BY_SELLER:
          case ACTION_MEMO_PAD_REQUEST_PAYMENT_FROM_BUYER:
          case ACTION_MEMO_PAD_REQUEST_RETURN_FROM_BUYER:
          case ACTION_MEMO_PAD_CONFIRM_PAYMENT_SELLER:
            if (values.buttonSubmitParams) {
              serverValues = values.buttonSubmitParams
            }
            break
          case ACTION_MEMO_PAD_REQUEST_INVOICE_FROM_SELLER:
          case ACTION_MEMO_PAD_RETURN_TO_SELLER:
            serverValues['items'] = []
            Object.keys(values.itemsSelectedInTable).forEach(function (itemId) {
              if (values.itemsSelectedInTable[itemId]) {
                serverValues['items'].push(itemId)
              }
            })
            break
          case ACTION_MEMO_PAD_ISSUE_INVOICE_FOR_BUYER:
            serverValues['items'] = []
            Object.keys(values.orderItemsForInvoice).forEach(function (itemId) {
              if (values.orderItemsForInvoice[itemId]) {
                serverValues['items'].push(itemId)
              }
            })
            if (serverValues['items'].length === 0) {
              return infoAlert('Please, select diamonds for invoice')
            }
            break
          case ACTION_MEMO_PAD_UPLOAD_INVOICE_FOR_BUYER:
            if (values.buttonSubmitParams) {
              serverValues = values.buttonSubmitParams
              serverValues.files = getLocalFiles()
            }
            break
          default:
            return infoAlert('Unsupported action -> ' + values.action)
        }
        await performOrderAction(values.action, serverValues)
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            {Object.keys(errors).length > 0 && (
              <section className="my-4 bg-red-300 text-red-600">
                <ErrorMessage name="trackingNumber" component="div"/>
                <ErrorMessage name="shipmentProvider" component="div"/>
              </section>
            )}
            <section className="">
              <OrderSubHeader className="mb-4"
                              label={'orders.labels.generalTerms'}/>
              <div className="flex justify-between">
                <div>
                  <div className="flex">
                    <span className="text-gray-500">{i18n.value(
                      'order.labels.number')}</span>
                    <span className="text-brown-dim ml-2">{order.number}</span>
                  </div>
                  <div className="flex">
                    <span className="text-gray-500">{i18n.value(
                      'orders.list.tableHead.company.' + section)}</span>
                    <span
                      className="text-brown-dim ml-2">{buyerOrSupplierName}</span>
                  </div>
                  <div className="flex">
                    <span className="text-gray-500">{i18n.value(
                      'orders.labels.memoTerm')}</span>
                    <span className="text-brown-dim ml-2">
                      {order.memoTermsDays}&nbsp;{i18n.value('common.days')}
                    </span>
                  </div>
                  <OrderTimer label="Timer" timer={order.timer}/>
                </div>
                <div>
                  <ViewMemoDealTerms order={order} asSeller={asSeller}/>
                  <ViewOrderMemoDocs order={order}/>
                  <ViewOrderFiles order={order} doAction={performOrderAction}/>

                  <div>
                    {needProvideKyc ?
                      <div>
                        <Link to="/company/kyc-info"
                              className="block my-2 flex-0">
                          <Button className="bg-blue-skyblue" slim
                                  type="button"
                                  label={'profile.buttons.kycInfo'}/>
                        </Link>
                      </div>
                      : null}
                    {canRequestKycInfo ?
                      <Button className="bg-blue-skyblue my-2" slim
                              type="button"
                              label="order.labels.requestKyc"
                              onClick={requestKyc}/>
                      : null}
                    {canDownloadKyc ?
                      <Button className="bg-blue-skyblue my-2" slim
                              type="button"
                              label="order.labels.downloadKyc"
                              onClick={downloadKyc}/>
                      : null}
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="my-12">
                <DiamondStatusBar
                  order={order}
                  readOnly={!isEditable}
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  values={values}
                  onlyPreview={readOnly}
                />
              </div>
            </section>
            {canExtract ? (
              <section className="flex">
                <BlueButton onClick={extractStonesFromOrder} type="button"
                            label="orders.buttons.extractOrderStones"/>
              </section>
            ) : null}
          </Form>
        )
      }}
    </Formik>
  )
}

FormMemoPaymentAndDelivery.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  readOnly: PropTypes.bool,
  asSeller: PropTypes.bool.isRequired,
  openChat: PropTypes.func,
}

export default memo(connect(null, { openChat })(FormMemoPaymentAndDelivery))
