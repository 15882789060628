import React, { memo } from 'react'
import PropTypes from 'prop-types'
import CheckboxInput from 'root/modules/login/pages/Login/components/CheckboxInput'

const CheckboxGroup = ({ items, values, onChange, ...props }) => (
  <div {...props}>
    {items.map((item, index) => {
      const valueIndex = values?.findIndex((i) => i === index + 1)
      const value = !values || valueIndex > -1
      return (
        <CheckboxInput
          value={value}
          onChange={() => {
            let newValue
            if (value) {
              newValue = values
                ? [...values]
                : Array(items.length)
                    .fill()
                    .map((item, index) => index + 1)
              newValue.splice(valueIndex > -1 ? valueIndex : index, 1)
            } else newValue = values.concat(index + 1)
            onChange(newValue.length === items.length ? undefined : newValue)
          }}
          key={item}
          labelBefore={item}
          id={item}
          className="inline-block text-xs mr-2"
        />
      )
    })}
  </div>
)

CheckboxGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
}

export default memo(CheckboxGroup)
