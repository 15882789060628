import * as actions from '../actions'

import {
  getCatalogFrameInfo,
  getCompanyCommonInfo,
  getCompanyCreditInfo,
  getCompanyInvoiceTerms,
  getCompanyKycInfo,
  getCompanyTeam,
  saveCompanyCreditInfo,
  saveCompanyFrameInfo,
  saveCompanyInvoiceTerms,
  saveCompanyKycInfo,
  saveCompanyProfile,
  saveCompanyTeam,
} from 'root/api'
import {
  getCatalogFrameInfoData,
  getCompanyTeamData,
  getCreditInfoData,
  getInvoiceTermsData,
  getKycInfoData,
  getProfileCompanyData,
} from 'root/modules/profile/selectors'
import { errorAlert } from 'root/constants'

export const loadCompanyCommonInfo = (refresh) => async (
  dispatch, getState) => {
  const company = getProfileCompanyData(getState())
  dispatch(actions.loadCompanyStart({ item: company, refresh }))
  await getCompanyCommonInfo().
    then(({ data: { data } }) => dispatch(actions.loadCompanySuccess(data))).
    catch((e) => {
      console.error(e)
      dispatch(actions.loadCompanyFailure())
    })
}

export const saveCompanyProfileThunk = (data) => async (dispatch, getState) => {
  return saveCompanyProfile(data).then(({ data: { data } }) => {
    dispatch(actions.loadCompanySuccess(data))
  }).catch(errorAlert)
}

export const loadInvoiceTerms = (refresh) => async (dispatch, getState) => {
  const company = getInvoiceTermsData(getState())
  dispatch(actions.loadInvoiceTermsStart({ item: company, refresh }))
  await getCompanyInvoiceTerms().
    then(
      ({ data: { data } }) => dispatch(actions.loadInvoiceTermsSuccess(data))).
    catch((e) => {
      console.error(e)
      dispatch(actions.loadInvoiceTermsFailure())
    })
}

export const saveInvoiceTerms = (data) => async (dispatch, getState) => {
  return saveCompanyInvoiceTerms(data).then(({ data: { data } }) => {
    dispatch(actions.loadInvoiceTermsSuccess(data))
  }).catch(errorAlert)
}

export const loadCatalogFrameInfo = (refresh) => async (dispatch, getState) => {
  dispatch(actions.loadCatalogFrameInfoStart(
    { item: getCatalogFrameInfoData(getState()), refresh }))
  await getCatalogFrameInfo().
    then(({ data: { data } }) => dispatch(
      actions.loadCatalogFrameInfoSuccess(data))).
    catch((e) => {
      console.error(e)
      dispatch(actions.loadCatalogFrameInfoFailure())
    })
}

export const saveCatalogFrameInfo = (data) => async (dispatch) => {
  return saveCompanyFrameInfo(data).then(({ data: { data } }) => {
    dispatch(actions.loadCatalogFrameInfoSuccess(data))
  }).catch(errorAlert)
}

export const loadCreditInfo = (refresh) => async (dispatch, getState) => {
  const company = getCreditInfoData(getState())
  dispatch(actions.loadCreditInfoStart({ item: company, refresh }))
  await getCompanyCreditInfo().
    then(({ data: { data } }) => dispatch(actions.loadCreditInfoSuccess(data))).
    catch((e) => {
      console.error(e)
      dispatch(actions.loadCreditInfoFailure())
    })
}

export const loadKycInfo = (refresh) => async (dispatch, getState) => {
  const company = getKycInfoData(getState())
  dispatch(actions.loadKycInfoStart({ item: company, refresh }))
  await getCompanyKycInfo().
    then(({ data: { data } }) => dispatch(actions.loadKycInfoSuccess(data))).
    catch((e) => {
      console.error(e)
      dispatch(actions.loadKycInfoFailure())
    })
}

export const saveCreditInfo = (data) => async (dispatch, getState) => {
  return saveCompanyCreditInfo(data).then(({ data: { data } }) => {
    dispatch(actions.loadCreditInfoSuccess(data))
  }).catch(errorAlert)
}

export const saveKycInfo = (data) => async (dispatch, getState) => {
  return saveCompanyKycInfo(data).then(({ data: { data } }) => {
    dispatch(actions.loadKycInfoSuccess(data))
  }).catch(errorAlert)
}

export const loadCompanyTeam = (refresh) => async (dispatch, getState) => {
  const companyTeam = getCompanyTeamData(getState())
  dispatch(actions.loadCompanyTeamStart({ item: companyTeam, refresh }))
  await getCompanyTeam().
    then(
      ({ data: { data } }) => dispatch(actions.loadCompanyTeamSuccess(data))).
    catch((e) => {
      console.error(e)
      dispatch(actions.loadCompanyTeamFailure())
    })
}

export const saveCompanyTeamThunk = (data) => (dispatch) =>
  saveCompanyTeam(data).
    then(({ data: { data } }) => dispatch(actions.loadCompanyTeamSuccess(data)))
