import { addressFields, calcTotal, ensureBoolean, timestampToDate, timestampToDateString } from 'root/constants'
import axios from 'axios'
import { RESPONSIBLE_SELLER } from 'root/modules/orders/constants'

const getTransformResponse = (processFunc) =>
  axios.defaults.transformResponse.concat((response) => {
    const { data, meta } = response || {}
    if (data)
      response.data = Array.isArray(data) ? data.map((data) => processFunc(data, meta)) : processFunc(data, meta)
    return response
  })

export const getUserFullName = (user) => {
  if (user) {
    const { firstName, lastName } = user.attributes
    return [firstName, lastName].filter((name) => name).join(' ')
  }
}

const processDocument = (statusHistory) => {
  const { id, attributes, relationships } = statusHistory
  const { actions } = relationships
  return { id, ...attributes, actions }
}

const processMemoAgreement = (memoAgreement, meta) => {
  return memoAgreement
}

const processCatalogFrame = (frame, meta) => {
  return {
    enabled: false,
    showPrices: false,
    priceExtra: 0,
    showCertificate: false,
    allowOrders: false,
    autoOrder: false,
    email: null,
    domain: null,
    ...frame,
  }
}

const processCompany = (company, meta) => {
  return company
}

const processCompanyTeam = (team, meta) => {
  return team
}

const processUser = (user, meta) => {
  return {
    ...user,
    emailConfirmed: ensureBoolean(user?.emailConfirmed),
    phoneConfirmed: ensureBoolean(user?.phoneConfirmed),
  }
}

const processOrderMemos = (memo, meta) => {
  return memo
}

const processNotificationSummary = (data) => {
  return data
}

export const processOrder = (order, meta) => {
  const { created, items, ...attributes } = order
  const { shipments, deliveryPrice, sellerCompanyId } = attributes
  const actions = meta?.actions
  attributes.returnAvailability = ensureBoolean(attributes.returnAvailability)

  const products = items.map(getProcessOrderItem(sellerCompanyId, meta))
  const additionalGoods = (order.additionalGoods || []).map((e, i) => ({ ...e, id: i }))

  let metaActions = {}
  if (meta && meta.state && meta.state.actionsHelper) {
    metaActions = meta.state.actionsHelper
  }

  const productsTotal = calcTotal(products)
  const deliveryTotal = deliveryPrice.value ? [deliveryPrice] : undefined
  const productsTotalChanged = products?.some(({ unitChanges }) => unitChanges?.price)
  const total = calcTotal([...productsTotal, attributes.deliveryPrice])
  const date = timestampToDate(created)
  const address = {}
  addressFields.forEach((field) => {
    const orderField = 'delivery' + field.charAt(0).toUpperCase() + field.slice(1)
    address[field] = attributes[orderField]
    delete attributes[orderField]
  }, {})

  let deliveredNum = 0
  let lastShippedTimestamp = 0
  let lastDeliveredTimestamp = 0

  const productsShipments = products.reduce((acc, { id, disabled }) => {
    if (!disabled) acc[id] = { hasStone: false, hasCertificate: false }
    return acc
  }, {})
  let additionalLength = additionalGoods.length
  shipments?.forEach(({ state, created, modified, content }, index) => {
    shipments[index].size = 0
    content.forEach(({ itemId, hasStone, hasCertificate, hasOther }) => {
      const itemInfo = productsShipments[itemId]
      if (hasOther) {
        additionalLength = additionalLength - 1
      }
      if (!itemInfo) return
      if (hasStone) {
        shipments[index].size++
        itemInfo.hasStone = true
      }
      if (hasCertificate) {
        shipments[index].size++
        itemInfo.hasCertificate = true
      }
      if (itemInfo.hasStone && itemInfo.hasCertificate) delete productsShipments[itemId]
    })
    if (created > lastShippedTimestamp) lastShippedTimestamp = created
    if (state === 'delivered') {
      deliveredNum++
      if (modified > lastDeliveredTimestamp) lastDeliveredTimestamp = modified
    }
  })

  attributes.notShippedProductsNum = Object.keys(productsShipments).length + additionalLength

  const allShipped = !attributes.notShippedProductsNum
  const allDelivered = allShipped && shipments?.length === deliveredNum

  if (allShipped) attributes.allShippedDate = timestampToDateString(lastShippedTimestamp, true)
  if (allDelivered) attributes.allDeliveredDate = timestampToDateString(lastDeliveredTimestamp, true)
  attributes.allDelivered = allDelivered ? lastDeliveredTimestamp : 0
  attributes.allShipped = allShipped ? lastShippedTimestamp : 0

  return {
    ...attributes,
    responsible: { name: undefined, phone: undefined },
    creator: { name: undefined, phone: undefined },
    address: processAddress(address),
    products,
    productsTotal,
    productsTotalChanged,
    deliveryTotal,
    total,
    date,
    actions,
    metaActions,
    additionalGoods,
    isTaxesResponsibleSeller: order.taxesResponsible === RESPONSIBLE_SELLER,
    isBankResponsibleSeller: order.bankCommissionResponsible === RESPONSIBLE_SELLER,
    isDeliveryResponsibleSeller: order.returnDeliveryResponsible === RESPONSIBLE_SELLER,
  }
}

const getReferenceDiscount = (price, refPrice) => (refPrice ? ((1 - price / refPrice) * 100).toFixed(2) : 0)
const getDelivery = (from, to) => `${from}-${to}`

const getMergedChanges = (getValue, arr1, arr2, val1, val2) => {
  arr1 = arr1 || []
  arr2 = arr2 || []
  const merged = []
  let index1 = 0
  let index2 = 0
  let isArr1Depleted = index1 === arr1.length
  let isArr2Depleted = index2 === arr2.length

  val1 = arr1[0]?.old || val1
  val2 = arr2[0]?.old || val2

  let old = getValue(val1, val2)

  while (!isArr1Depleted || !isArr2Depleted) {
    const timestamp1 = arr1[index1]?.timestamp
    const timestamp2 = arr2[index2]?.timestamp
    let timestamp

    if (!isArr1Depleted && (isArr2Depleted || timestamp1 < timestamp2)) {
      val1 = arr1[index1].new
      timestamp = timestamp1
      index1++
    } else if (!isArr1Depleted && !isArr2Depleted && timestamp1 === timestamp2) {
      val1 = arr1[index1].new
      val2 = arr2[index2].new
      timestamp = timestamp1
      index1++
      index2++
    } else {
      val2 = arr2[index2].new
      timestamp = timestamp2
      index2++
    }
    const newValue = getValue(val1, val2)
    if (newValue !== old) merged.push({ timestamp, old, new: newValue })
    old = newValue

    isArr1Depleted = index1 === arr1.length
    isArr2Depleted = index2 === arr2.length
  }

  return merged.length ? merged : undefined
}

const getProcessOrderItem = (company, meta) => (orderItem) => {
  const { product, unitPrice, unitChanges, ...props } = orderItem
  const { unitReferencePrice, deliveryDaysFrom, deliveryDaysTo } = props
  const { value, currency } = unitPrice
  const refPrice = unitReferencePrice.value
  const price = parseFloat(value)
  const referenceDiscount = getReferenceDiscount(price, refPrice)
  const delivery = getDelivery(deliveryDaysFrom, deliveryDaysTo)

  let metaState = {}

  if (meta && meta.state && meta.state.itemState) {
    metaState = meta.state.itemState[orderItem.product.id]
  }

  if (unitChanges) {
    unitChanges.delivery = getMergedChanges(
      getDelivery,
      unitChanges.deliveryDaysFrom,
      unitChanges.deliveryDaysTo,
      deliveryDaysFrom,
      deliveryDaysTo,
    )
    unitChanges.referenceDiscount = getMergedChanges(
      getReferenceDiscount,
      unitChanges.price,
      unitChanges.unitReferencePrice,
      price,
      refPrice,
    )
  }

  return {
    ...props,
    ...product,
    referenceDiscount,
    referencePrice: refPrice,
    company,
    price,
    currency,
    unitPrice,
    delivery,
    unitChanges,
    metaState,
  }
}

const processChat = (chat) => {
  const { id, relationships, attributes } = chat
  const { ChatEvent, Participant } = relationships
  const lastReadById = Participant.reduce((acc, participant) => {
    acc[participant.relationships.User.id] = participant.attributes.lastRead
    return acc
  }, {})
  const lastEvent = ChatEvent ? processChatEvent(ChatEvent) : undefined
  const messages = ChatEvent ? [lastEvent] : []
  return { id, ...attributes, lastEvent, messages, lastReadById }
}

const findItemsById = (items, ids) => ids.map((id) => items.find((item) => item.id === id))

const processChatEvent = (chatEvent) => {
  const { id, attributes, relationships } = chatEvent
  const { created, text: message, created_by: userId, connected_user_id, images } = attributes
  const { User } = relationships
  const [userName, connectedName] = findItemsById(User, [userId, connected_user_id]).map(getUserFullName)
  let { type } = attributes
  if (type === 'join' && userId === connected_user_id) type = 'self_join'
  return { id, created, message, userId, type, userName, connectedName, images }
}

const processChatManagers = (user) => {
  const { id, attributes, relationships } = user
  const { firstName, lastName } = attributes
  const companyName = relationships.Company.attributes.name
  const isManager = false // attributes.companyId === FRIME_ID
  return { id, firstName, lastName, name: getUserFullName(user), companyName, isManager }
}

export const processProduct = (product) => {
  const { id, companyId: company, currencyId, inReserve, dealConfirmed, heartsAndArrows, ...attributes } = product
  const currency = currencyId.code

  return {
    id,
    company,
    currency,
    inReserve: ensureBoolean(inReserve),
    dealConfirmed: ensureBoolean(dealConfirmed),
    heartsAndArrows: ensureBoolean(heartsAndArrows),
    ...attributes,
  }
}

export const processProductForEdit = (product, meta) => {
  return { ...processProduct(product), meta }
}

const processLegacyProduct = (item) => {
  const { fields: cardFields, order, kilos, ...rest } = item
  const { unit, minOrder: min, maxOrder: max, unitPrice } = order
  const { value: price, currency } = unitPrice
  return { cardFields, price, basePrice: price, currency, unit, min, max, kilos: kilos || 1, ...rest }
}

const processLegacyProductData = (data) =>
  data.items ? { ...data, items: data.items.map(processLegacyProduct) } : processLegacyProduct(data)

const processAddress = ({ recipientPhone, ...item }) => {
  item.isMain = ensureBoolean(item.isMain)
  item.countryId = item.countryId?.code
  item.recipientPhone = recipientPhone
  return item
}

const processOrderChat = (item) => {
  const { events } = item

  return { ...item, events: events.map(processOrderChatMessage) }
}

const processOrderChatMessage = (item) => {
  const { creatorId, text: message, ...rest } = item
  const { name: userName, id: userId } = creatorId
  return { ...rest, userName, userId, message }
}

export const transformProducts = getTransformResponse(processProduct)
export const transformProductsWithMeta = getTransformResponse(processProductForEdit)
export const transformMemoAgreements = getTransformResponse(processMemoAgreement)
export const transformOrders = getTransformResponse(processOrder)
export const transformOrderMemos = getTransformResponse(processOrderMemos)
export const transformChats = getTransformResponse(processChat)
export const transformChatEvents = getTransformResponse(processChatEvent)
export const transformOrderChat = getTransformResponse(processOrderChat)
export const transformOrderChatMessage = getTransformResponse(processOrderChatMessage)
export const transformChatManagers = getTransformResponse(processChatManagers)
export const transformDocuments = getTransformResponse(processDocument)
export const transformSupplierProducts = getTransformResponse(processLegacyProductData)
export const transformAddresses = getTransformResponse(processAddress)
export const transformCompany = getTransformResponse(processCompany)
export const transformCatalogFrame = getTransformResponse(processCatalogFrame)
export const transformCompanyTeam = getTransformResponse(processCompanyTeam)
export const transformUser = getTransformResponse(processUser)

export const transformNotificationSummary = getTransformResponse(processNotificationSummary)
