import { handleActions } from 'redux-actions'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as actions from '../actions'

export const settingsPersistKey = 'settings'

const persistConfig = {
  key: settingsPersistKey,
  storage,
}

const defaultState = {
  productsListMode: false,
}

const settings = handleActions(
  {
    [actions.setProductsListMode]: (state, { payload: productsListMode }) => ({ ...state, productsListMode }),
  },
  defaultState,
)
export default persistReducer(persistConfig, settings)
