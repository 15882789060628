import { connect } from 'react-redux'

import { saveCompanyTeamThunk } from 'root/modules/profile/thunks'
import { canSupervise } from 'root/selectors'

const mapDispatchToProps = {
  saveCompanyTeamThunk,
}

const mapStateToProps = (state) => ({
  canSupervise: canSupervise(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
