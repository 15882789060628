import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import CheckBoxFormik from 'root/modules/login/pages/Login/components/CheckboxInput/formik'

const ShipmentItem = ({ hasStone, hasCertificate, isSender, values, valuesKey, product }) => {
  const certificateLabel = i18n.value('order.diamonds.certificate') + product.certificateNumber
  return (
    <div className="flex">
      <div className="w-1/2">
        {hasStone ? (
          isSender ? (
            <div className="mx-auto cursor-pointer">
              {!values[valuesKey] ? null : (
                <CheckBoxFormik
                  isChecked={values[valuesKey][product.id].hasStone}
                  labelAfter={product.name}
                  itemKey={`${valuesKey}[${product.id}][hasStone]`}
                />
              )}
            </div>
          ) : (
            <label className="text-gray-500">{product.name}</label>
          )
        ) : (
          ' - '
        )}
      </div>
      <div className="w-1/2">
        {hasCertificate ? (
          isSender ? (
            <div className="mx-auto cursor-pointer">
              {!values[valuesKey] ? null : (
                <CheckBoxFormik
                  isChecked={values[valuesKey][product.id].hasCertificate}
                  labelAfter={certificateLabel}
                  itemKey={`${valuesKey}[${product.id}][hasCertificate]`}
                />
              )}
            </div>
          ) : (
            <label className="text-gray-500">{certificateLabel}</label>
          )
        ) : (
          <>&mdash;</>
        )}
      </div>
    </div>
  )
}

ShipmentItem.propTypes = {
  product: PropTypes.object.isRequired,
  hasStone: PropTypes.bool,
  hasCertificate: PropTypes.bool,
  isSender: PropTypes.bool,
  values: PropTypes.object.isRequired,
  valuesKey: PropTypes.string.isRequired,
}

export default memo(ShipmentItem)
