import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ width = 32, height = 32, ...props }) => (
  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.4459 22.4625V27.9275C19.4459 28.11 19.2947 28.2588 19.1072 28.2588H6.92193H2.07233C1.88859 28.2588 1.73986 28.1075 1.73986 27.9263V2.0725C1.73986 1.89 1.88859 1.74125 2.07233 1.74125H14.0338V6.78125C14.0338 7.26125 14.4238 7.65 14.905 7.65H19.4422V13.6038L21.1958 11.85C21.192 10.4775 21.187 9.5925 21.182 9.5775V6.40875L15.285 0H2.07233C0.929923 0 0 0.93 0 2.0725V27.9275C0 29.0712 0.929923 30 2.07233 30H13.9576H19.1084C20.2721 30 21.2195 29.07 21.2195 27.9275C21.2195 27.9275 21.217 24.5525 21.212 20.6975L19.4459 22.4625ZM15.7737 3.10125L18.3585 5.91125H15.7737V3.10125Z"
      fill="currentColor"
    />
    <path
      d="M15.5539 14.0703H5.04854C4.58233 14.0703 4.20361 14.4491 4.20361 14.9153C4.20361 15.3803 4.58108 15.7591 5.04854 15.7591H15.5539C16.0201 15.7591 16.3989 15.3803 16.3989 14.9153C16.3976 14.4491 16.0201 14.0703 15.5539 14.0703Z"
      fill="currentColor"
    />
    <path
      d="M11.6791 18.3203H5.17342C4.70721 18.3203 4.32849 18.6991 4.32849 19.1653C4.32849 19.6303 4.70596 20.0091 5.17342 20.0091H11.6791C12.1453 20.0091 12.5241 19.6303 12.5241 19.1653C12.5228 18.6991 12.1453 18.3203 11.6791 18.3203Z"
      fill="currentColor"
    />
    <path
      d="M15.5576 10.1289H5.04476C4.57855 10.1289 4.19983 10.5064 4.19983 10.9739C4.19983 11.4402 4.57855 11.8189 5.04476 11.8189H15.5576C16.0251 11.8189 16.4026 11.4402 16.4026 10.9739C16.4026 10.5077 16.0239 10.1289 15.5576 10.1289Z"
      fill="currentColor"
    />
    <path
      d="M22.465 12.258L25.121 14.9142L26.4496 13.5855C27.1833 12.8517 27.1833 11.663 26.4496 10.9292C25.7159 10.1955 24.5273 10.1955 23.7936 10.9292L22.465 12.258Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M25.121 14.9159L17.5328 22.5059L13.7381 24.7822L12.5995 23.6447L14.8668 19.8597L22.4524 12.2734L25.121 14.9159Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path d="M11.616 25.7634L13.1696 24.2109" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" />
    <path d="M15.2556 19.4688L17.9216 22.115" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" />
    <path
      d="M25.1212 14.9141L25.8799 15.6728C26.2986 16.0916 26.2986 16.7716 25.8799 17.1903L23.2239 19.8466"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
)
SvgComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default memo(SvgComponent)
