import { combineReducers } from 'redux'

import company from './company'
import invoiceTerms from './invoice-terms'
import creditInfo from './credit-info'
import kycInfo from './kyc-info'
import companyTeam from './company-team'
import catalogFrame from './catalog-frame'

export default combineReducers({
  company,
  invoiceTerms,
  creditInfo,
  kycInfo,
  companyTeam,
  catalogFrame,
})
