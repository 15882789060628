import { createAction } from 'redux-actions'

const NAMESPACE = 'MEMO_AGREEMENTS::'

export const loadMemoAgreementListStart = createAction(NAMESPACE + 'LOAD_MEMO_AGREEMENTS_LIST_START')
export const loadMemoAgreementListSuccess = createAction(NAMESPACE + 'LOAD_MEMO_AGREEMENTS_LIST_SUCCESS')
export const loadMemoAgreementListFailure = createAction(NAMESPACE + 'LOAD_MEMO_AGREEMENTS_LIST_FAILURE')

export const loadMemoAgreementStart = createAction(NAMESPACE + 'LOAD_MEMO_AGREEMENT_START')
export const loadMemoAgreementSuccess = createAction(NAMESPACE + 'LOAD_MEMO_AGREEMENT_SUCCESS')
export const loadMemoAgreementFailure = createAction(NAMESPACE + 'LOAD_MEMO_AGREEMENT_FAILURE')

export const updateMemoAgreementFilters = createAction(NAMESPACE + 'UPDATE_MEMO_AGREEMENT_FILTERS')
