import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import CardDealAgreed
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealAgreed'
import CardDealClosed
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealClosed'
import CardDealPayment
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealPayment'
import CardDealDelivery
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealDelivery'
import OrderTimer from 'root/components/OrderTimer'
import i18n from 'root/i18n'
import {
  ACTION_ADDITIONAL_INVOICE_PAID,
  ACTION_INVOICE_PAID,
  STATE_ADDITIONAL_INVOICE_CANCELED,
  STATE_ADDITIONAL_INVOICE_CONFIRMED,
  STATE_ADDITIONAL_INVOICE_CREATED,
} from 'root/modules/orders/constants'
import BrownButton from 'root/components/Buttons/BrownButton'
import { useAsyncLoad, useEffectOnce } from 'root/hooks'
import CardDealCreateInvoice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealCreateInvoice'
import ViewOrderFiles from 'root/components/ViewOrderFiles'
import { doNothing } from 'root/constants'
import { confirmAlert, dialogAlert } from 'root/components/AlertWrapper'
import { connect } from 'react-redux'
import { showInitialTerms } from 'root/modules/orders/thunks'
import CardDealCreateAdditionalInvoice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealCreateAdditionalInvoice'
import CardDealAdditionalPayment
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealAdditionalPayment'
import PaymentIcon from 'root/icons/PaymentIcon'
import TextLabel from 'root/components/TextLabel'
import Date
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Date'
import CardDealInvoice
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealInvoice'

const mapDispatchToProps = {
  showInitialTerms,
}

const DealInProgressSeller = ({
  help,
  order,
  doAction,
  showInitialTerms,
  readOnly = false,
}) => {
  const [loading, submitAction] = useAsyncLoad(doAction)
  const canPayed = !readOnly &&
    order.actions.some((action) => action.url === ACTION_INVOICE_PAID)
  const canPayedAdditional = !readOnly && order.actions.some(
    (action) => action.url === ACTION_ADDITIONAL_INVOICE_PAID)
  const additionalInvoiceIssued = [
    STATE_ADDITIONAL_INVOICE_CONFIRMED,
    STATE_ADDITIONAL_INVOICE_CREATED,
    STATE_ADDITIONAL_INVOICE_CANCELED,
  ].includes(order.state.key)
  const renderAdditionalPaymentCard = useCallback(
    ({ invoice, invoiceIssued }) => (
      <div className="flex flex-col space-y-2">
        <OrderTimer timer={order.timer}/>
        {canPayedAdditional && (
          <BrownButton
            disabled={loading}
            onClick={(e) => {
              e.stopPropagation()
              return confirmAlert(
                i18n.value('order.card.paymentReceivedConfirm')).then(() => {
                submitAction(ACTION_ADDITIONAL_INVOICE_PAID)
              }).catch(doNothing)
            }}
            type="button"
            label="order.card.paymentReceived"
          />
        )}
      </div>
    ),
    [canPayedAdditional, loading, order.timer, submitAction],
  )
  const renderPaymentCard = useCallback(
    ({ paidWithStripe }) => (
      <div className="flex flex-col space-y-2">
        <OrderTimer timer={order.timer}/>
        {paidWithStripe ? (
          <div
            className="border border-brown-dim p-2 mt-2 flex text-brown-dim items-center">
            <PaymentIcon/>
            <div className="flex flex-col items-center flex-grow">
              <TextLabel label={'order.card.paidWithStripe'}/>
              <Date value={paidWithStripe}/>
            </div>
          </div>
        ) : null}
        {canPayed && (
          <BrownButton
            disabled={loading}
            onClick={(e) => {
              e.stopPropagation()
              return confirmAlert(
                i18n.value('order.card.paymentReceivedConfirm')).then(() => {
                submitAction(ACTION_INVOICE_PAID)
              }).catch(doNothing)
            }}
            type="button"
            label="order.card.paymentReceived"
          />
        )}
      </div>
    ),
    [canPayed, loading, order.timer, submitAction],
  )
  useEffectOnce(() => {
    const showedOrders = JSON.parse(
      localStorage.getItem('orderWarningsStartedWithInitialTerms') || '[]')
    if (order.startedWithInitialTerms && !showedOrders.includes(order.id)) {
      dialogAlert(
        i18n.value('order.labels.orderStartedWithInitialTerms'),
        'orders.buttons.showInitialTerms',
        'common.ok',
      ).then(showInitialTerms).finally(() => {
        localStorage.setItem('orderWarningsStartedWithInitialTerms',
          JSON.stringify(showedOrders.concat([order.id])))
      })
    }
  })

  const renderDeliveryCard = useCallback(
    ({ allShipped, showDeliveries, showDeliveryAddress }) => {
      return !allShipped && !readOnly ? (
        <div>
          <BrownButton type="button" label="order.card.makeShipment"
                       onClick={showDeliveries}/>
          <button onClick={showDeliveryAddress} className="text-brown-dim mt-4">
            {i18n.value('order.card.viewAddress')}
          </button>
        </div>
      ) : (
        <button onClick={showDeliveries} className="text-brown-dim">
          {i18n.value('order.card.viewDeliveries')}
        </button>
      )
    },
    [readOnly],
  )

  return (
    <>
      <section>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          buyer={order.buyerCompanyId.name}
          asSeller
        >
          <ViewOrderFiles order={order} doAction={doAction}/>
        </GeneralTerms>
      </section>
      <div className="flex flex-col items-center justify-center">
        <CardDealAgreed help={help} order={order}/>
        {!readOnly ? (
          <CardDealCreateInvoice help={help} order={order} doAction={doAction}/>
        ) : (
          <CardDealInvoice help={help} order={order}/>
        )}
        {order.paymentBeforeDelivery > 0 && (
          <CardDealPayment help={help} order={order} beforeDelivery>
            {renderPaymentCard}
          </CardDealPayment>
        )}
        <CardDealDelivery help={help} order={order} asSeller doAction={doAction}
                          readOnly={readOnly}>
          {renderDeliveryCard}
        </CardDealDelivery>
        {order.paymentAfterDelivery > 0 && (
          <CardDealPayment help={help} order={order} beforeDelivery={false}>
            {renderPaymentCard}
          </CardDealPayment>
        )}
        {order.hasAdditionalInvoice ? (
          <CardDealCreateAdditionalInvoice help={help} order={order}
                                           doAction={doAction}/>
        ) : null}
        {additionalInvoiceIssued ? (
          <CardDealAdditionalPayment help={help} order={order}>
            {renderAdditionalPaymentCard}
          </CardDealAdditionalPayment>
        ) : null}
        <CardDealClosed help={help} order={order}/>
      </div>
    </>
  )
}

DealInProgressSeller.propTypes = {
  order: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  help: PropTypes.string,
  showInitialTerms: PropTypes.func,
}

export default memo(connect(null, mapDispatchToProps)(DealInProgressSeller))
