import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { timestampToTime, toStyle } from 'root/constants'
import styles from './styles'
import ChatMessageImages from './components/ChatMessageImages'
import ChatMessageVideos from './components/ChatMessageVideos'
import ChatMessageText from './components/ChatMessageText'
import { MessageReadIcon } from 'root/icons'
import ChatMessageDocuments from 'root/modules/chat/components/OrderChat/components/Chat/components/ChatMessage/components/ChatMessageDocuments'

const ChatMessage = ({ userName, message, images, videos, documents, created, isOwned, isRead }) => {
  const time = timestampToTime(created)
  const hasImages = !!images?.length
  const hasVideos = !!videos?.length
  const hasDocuments = !!documents?.length
  const hasUserName = !!userName
  const hasMessage = !!message

  return (
    <div style={toStyle([styles.row, isOwned && styles.flexEnd])}>
      <div style={toStyle([styles.container, isOwned && styles.owned])}>
        {hasUserName && <div style={styles.username}>{userName}</div>}
        {hasImages && <ChatMessageImages images={images} hasMessage={hasMessage} hasUserName={hasUserName} />}
        {hasVideos && <ChatMessageVideos videos={videos} hasMessage={hasMessage} hasUserName={hasUserName} />}
        {hasDocuments && (
          <ChatMessageDocuments documents={documents} hasMessage={hasMessage} hasUserName={hasUserName} />
        )}
        {hasMessage && (
          <ChatMessageText
            isOwned={isOwned}
            message={message}
            time={time}
            hasUserName={hasUserName}
            hasImages={hasImages}
          />
        )}
        <div style={toStyle([styles.timeContainer, !hasMessage && styles.timeContainerOnImage])}>
          <div style={toStyle([styles.time, isOwned && styles.timeOwned, !hasMessage && styles.timeOnImage])}>
            {time}
            {isOwned && <MessageReadIcon isRead={isRead} className="ml-1" />}
          </div>
        </div>
      </div>
    </div>
  )
}

ChatMessage.propTypes = {
  userName: PropTypes.string,
  message: PropTypes.string.isRequired,
  created: PropTypes.number.isRequired,
  isOwned: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  images: PropTypes.array,
  videos: PropTypes.array,
  documents: PropTypes.array,
}

export default memo(ChatMessage)
