import { connect } from 'react-redux'

import {
  getKycInfoData,
  isKycInfoLoading,
} from 'root/modules/profile/selectors'
import {
  loadCompanyCommonInfo,
  loadKycInfo,
  saveKycInfo,
} from 'root/modules/profile/thunks'
import { canSupervise } from 'root/selectors'

const mapStateToProps = (state) => ({
  data: getKycInfoData(state),
  isLoading: isKycInfoLoading(state),
  canSupervise: canSupervise(state),
})

const mapDispatchToProps = {
  loadKycInfo,
  saveKycInfo,
  loadCompanyCommonInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)
