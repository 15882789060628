import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import TemplateDoc from 'root/modules/orders/pages/components/TemplateDoc'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import { ErrorMessage, Form, Formik } from 'formik'
import ResponsibleSelectorFormik
  from 'root/modules/orders/pages/components/ResponsibleSelector/formik'
import InputTermFormik
  from 'root/modules/orders/pages/components/InputTerm/formik'
import OrderSpecialTermsFormik
  from 'root/modules/orders/pages/components/OrderSpecialTerms/formik'
import * as Yup from 'yup'
import FormikSubmittingButtons
  from 'root/components/Buttons/FormikSubmittingButtons'
import {
  ACTION_MEMO_NEW_CREATED,
  RESPONSIBLE_BUYER,
  RESPONSIBLE_SELLER,
} from 'root/modules/orders/constants'
import OrderTimer from 'root/components/OrderTimer'
import { getMemoDocument } from 'root/api'
import { timestampToDate, trimInteger, trimNumber } from 'root/constants'
import OrderSubHeader from 'root/components/OrderSubHeader'
import { infoAlert } from 'root/components/AlertWrapper'
import ViewOrderMemoDocs
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ViewOrderMemoDocs'

const FIELD_LABELS = {
  number: i18n.value('orders.labels.number'),
  memoPaymentTermDays: i18n.value('orders.labels.memoPaymentTermDays'),
  nonReturnPeriod: i18n.value('orders.labels.nonReturnPeriod'),
  returnShipmentPeriod: i18n.value('orders.labels.returnShipmentPeriod'),
  memoPenaltyDelayPerDay: i18n.value('orders.labels.memoPenaltyDelayPerDay'),
  specialTerms: i18n.value('orders.labels.sitSpecialTerms'),
}

const NewMemoForm = ({
  order,
  performOrderAction,
  buttons,
  isEditable = true,
}) => {
  let heading = i18n.value('orders.labels.newMemoAgreement')
  if (order.memoId) {
    heading += ' #' + order.memoId.number
  }
  const NewMemoSchema = Yup.object().shape({
    number: Yup.string().
      required(FIELD_LABELS['number'] + ' value is required'),
    memoPaymentTermDays: Yup.number().
      min(3, FIELD_LABELS['memoPaymentTermDays'] + ' too short').
      max(100, FIELD_LABELS['memoPaymentTermDays'] + ' too long').
      required(FIELD_LABELS['memoPaymentTermDays'] + ' value is required'),
    nonReturnPeriod: Yup.number().
      min(0, FIELD_LABELS['nonReturnPeriod'] + ' too short').
      max(100, FIELD_LABELS['nonReturnPeriod'] + ' too long').
      required(FIELD_LABELS['nonReturnPeriod'] + ' value is required'),
    returnShipmentPeriod: Yup.number().
      min(3, FIELD_LABELS['returnShipmentPeriod'] + ' too short').
      max(100, FIELD_LABELS['returnShipmentPeriod'] + ' too long').
      required(FIELD_LABELS['returnShipmentPeriod'] + ' value is required'),
    memoPenaltyDelayPerDay: Yup.number().
      min(0, FIELD_LABELS['memoPenaltyDelayPerDay'] + ' is too low').
      max(20, FIELD_LABELS['memoPenaltyDelayPerDay'] + ' is too large').
      required(FIELD_LABELS['memoPenaltyDelayPerDay'] + ' is required'),
    specialTerms: Yup.string().
      max(1000, FIELD_LABELS['specialTerms'] + ' must be less then 1000'),
  })

  return (
    <Formik
      initialValues={{
        number: order.memoId ? order.memoId.number : order.number,
        memoPaymentTermDays: order.memoId ? order.memoId.paymentTermDays : 30,
        nonReturnPeriod: order.memoId ? order.memoId.nonReturnDays : 30,
        returnShipmentPeriod: order.memoId
          ? order.memoId.returnShipmentDays
          : 30,
        memoPenaltyDelayPerDay: order.memoId
          ? order.memoId.dailyDelayedPaymentFee
          : 0.03,
        isTaxesResponsibleSeller: order.memoId
          ? order.memoId.taxesResponsible === RESPONSIBLE_SELLER
          : true,
        isBankResponsibleSeller: order.memoId
          ? order.memoId.bankCommissionResponsible === RESPONSIBLE_SELLER
          : true,
        isDeliveryResponsibleSeller: order.memoId
          ? order.memoId.deliveryPaymentsResponsible === RESPONSIBLE_SELLER
          : true,
        isReturnResponsibleSeller: order.memoId
          ? order.memoId.returnPaymentResponsible === RESPONSIBLE_SELLER
          : true,
        specialTerms: order.memoId
          ? order.memoId.specialTerms
          : order.specialTerms || '',
        action: '',
      }}
      validationSchema={NewMemoSchema}
      onSubmit={async (values) => {
        let serverData = {}
        if (values.action === ACTION_MEMO_NEW_CREATED) {
          serverData = {
            number: values.number,
            taxesResponsible: values.isTaxesResponsibleSeller
              ? RESPONSIBLE_SELLER
              : RESPONSIBLE_BUYER,
            bankCommissionResponsible: values.isBankResponsibleSeller
              ? RESPONSIBLE_SELLER
              : RESPONSIBLE_BUYER,
            deliveryPaymentsResponsible: values.isDeliveryResponsibleSeller
              ? RESPONSIBLE_SELLER
              : RESPONSIBLE_BUYER,
            returnPaymentResponsible: values.isReturnResponsibleSeller
              ? RESPONSIBLE_SELLER
              : RESPONSIBLE_BUYER,
            paymentTermDays: values.memoPaymentTermDays,
            returnShipmentDays: values.returnShipmentPeriod,
            dailyDelayedPaymentFee: values.memoPenaltyDelayPerDay,
            nonReturnDays: values.nonReturnPeriod,
            specialTerms: values.specialTerms,
          }
        }
        await performOrderAction(values.action, serverData)
        if (values.action === ACTION_MEMO_NEW_CREATED) {
          await infoAlert(i18n.value('orders.labels.saveMemoSuccess'))
        }
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        setFieldValue,
        handleSubmit,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit}>
          <section className="container mx-auto lg:w-screen-lg-min">
            <div className="flex justify-between">
              <div>
                <OrderHeading label={heading}/>
              </div>
              {order.memoId && order.memoId.files.length > 0 ?
                <ViewOrderMemoDocs order={order}/> : ''}
            </div>
          </section>
          <section className="mt-12">
            <div
              className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
              <OrderSubHeader label={'orders.labels.memoTerms'}/>
              {order.memoId && (
                <TemplateDoc
                  label={'orders.labels.lgDealMemoTemplate'}
                  onClick={() => {
                    getMemoDocument(order.memoId.id)
                  }}
                />
              )}
            </div>

            <div
              className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
              <div className={'my-2'}>
                <span className="text-gray-500">{i18n.value(
                  'common.date')}</span>
                <span className="text-brown-dim ml-2">
                  {order.memoId
                    ? timestampToDate(order.memoId.created)
                    : order.date}
                </span>
              </div>
              {order.timer.isStarted
                ? <OrderTimer timer={order.timer}
                              label={'orders.labels.replyTimer'}/>
                : ''}
            </div>

            <div className="container mx-auto lg:w-screen-lg-min my-2">
              <div className={'my-2'}>
                <span className="text-gray-500">{i18n.value(
                  'orders.labels.memoConsignmentAgreement')}</span>
              </div>
              <div className={'my-2 flex'}>
                <InputTermFormik
                  labelBefore={'orders.labels.number'}
                  inputClass={'w-32'}
                  itemKey={'number'}
                  value={values.number}
                  isEditable={isEditable}
                />
              </div>
            </div>
          </section>
          <section className="mt-12">
            <div className="container mx-auto lg:w-screen-lg-min mb-2 mt-4">
              <div className="text-gray-500 mt-8 flex justify-between">
                <div className="grid grid-cols-2 gap-y-3 auto-rows-min">
                  <ResponsibleSelectorFormik
                    label={'orders.labels.taxResponsible'}
                    itemKey={'isTaxesResponsibleSeller'}
                    isSeller={values.isTaxesResponsibleSeller}
                    isEditable={isEditable}
                  />
                  <ResponsibleSelectorFormik
                    label={'orders.labels.bankResponsible'}
                    itemKey={'isBankResponsibleSeller'}
                    isSeller={values.isBankResponsibleSeller}
                    isEditable={isEditable}
                  />
                  <ResponsibleSelectorFormik
                    label={'orders.labels.deliveryPaymentResponsible'}
                    itemKey={'isDeliveryResponsibleSeller'}
                    isSeller={values.isDeliveryResponsibleSeller}
                    isEditable={isEditable}
                  />
                  <ResponsibleSelectorFormik
                    label={'orders.labels.returnPaymentResponsible'}
                    itemKey={'isReturnResponsibleSeller'}
                    isSeller={values.isReturnResponsibleSeller}
                    isEditable={isEditable}
                  />
                </div>
                <div className="grid grid-cols-3 gap-y-3 auto-rows-min">
                  <InputTermFormik
                    labelBefore={'orders.labels.memoPaymentTermDays'}
                    inputClass={'w-16'}
                    labelAfter={'common.days'}
                    itemKey={'memoPaymentTermDays'}
                    value={values.memoPaymentTermDays}
                    isEditable={isEditable}
                    onChange={(e) => {
                      setFieldValue(`memoPaymentTermDays`,
                        trimInteger(e.target.value))
                    }}
                  />
                  <InputTermFormik
                    labelBefore={'orders.labels.nonReturnPeriod'}
                    inputClass={'w-16'}
                    labelAfter={'common.days'}
                    itemKey={'nonReturnPeriod'}
                    value={values.nonReturnPeriod}
                    isEditable={isEditable}
                    onChange={(e) => {
                      setFieldValue(`nonReturnPeriod`,
                        trimInteger(e.target.value))
                    }}
                  />
                  <InputTermFormik
                    labelBefore={'orders.labels.returnShipmentPeriod'}
                    inputClass={'w-16'}
                    labelAfter={'common.days'}
                    itemKey={'returnShipmentPeriod'}
                    value={values.returnShipmentPeriod}
                    isEditable={isEditable}
                    onChange={(e) => {
                      setFieldValue(`returnShipmentPeriod`,
                        trimInteger(e.target.value))
                    }}
                  />
                  <InputTermFormik
                    labelBefore={'orders.labels.memoPenaltyDelayPerDay'}
                    inputClass={'w-16'}
                    labelAfter={'common.percent'}
                    itemKey={'memoPenaltyDelayPerDay'}
                    value={values.memoPenaltyDelayPerDay}
                    isEditable={isEditable}
                    onChange={(e) => {
                      setFieldValue(`memoPenaltyDelayPerDay`,
                        trimNumber(e.target.value))
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="container mx-auto lg:w-screen-lg-min mb-2 mt-12">
            <OrderSpecialTermsFormik
              label={'orders.labels.specialTerms'}
              itemKey={'specialTerms'}
              isEditable={isEditable}
              value={values.specialTerms}
            />
          </div>
          {isEditable ? (
            <div className="container mx-auto lg:w-screen-lg-min mb-2 mt-5">
              <label className="text-blue-skyblue text-sm">{i18n.value(
                'orders.labels.newMemoNotice')}</label>
            </div>
          ) : (
            ''
          )}
          {Object.keys(errors).length === 0 ? (
            ''
          ) : (
            <section className="my-4 bg-red-300 text-red-600">
              <ErrorMessage name="number" component="div"/>
              <ErrorMessage name="memoPaymentTermDays" component="div"/>
              <ErrorMessage name="nonReturnPeriod" component="div"/>
              <ErrorMessage name="returnShipmentPeriod" component="div"/>
              <ErrorMessage name="memoPenaltyDelayPerDay" component="div"/>
              <ErrorMessage name="specialTerms" component="div"/>
            </section>
          )}
          <div className="mt-8">
            {order.timer.isStarted
              ? <OrderTimer timer={order.timer}
                            label={'orders.labels.replyTimer'}/>
              : ''}
          </div>
          <div
            className="container mx-auto lg:w-screen-lg-min mt-4 flex justify-between">
            {buttons?.length > 0 && (
              <FormikSubmittingButtons
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                buttons={buttons}
                dirty={dirty}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

NewMemoForm.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func,
  buttons: PropTypes.array,
  isEditable: PropTypes.bool,
}

export default memo(NewMemoForm)
