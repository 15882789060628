import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import './styles.css'
import { useHistory } from 'react-router-dom'
import { CloseIcon } from 'root/icons'
import { doNothing } from 'root/constants'
import { confirmAlert } from 'root/components/AlertWrapper'

const Modal = ({ children, onClick, confirm, isFull, ...props }) => {
  const { replace, location } = useHistory()
  const { pathname } = location
  const goBack = useCallback(() => {
    const action = () => replace(pathname.split('/').slice(0, -1).join('/'))
    if (confirm) confirmAlert('Are you sure you want to leave this page?').then(action).catch(doNothing)
    else action()
  }, [replace, pathname, confirm])
  const onClickAction = onClick ?? goBack

  const onBackgroundClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (e.target === e.currentTarget) onClickAction()
    },
    [onClickAction],
  )

  return createPortal(
    <div className={isFull ? 'modal pt-6 pb-2 z-50' : 'modal z-50'} onClick={onBackgroundClick} {...props}>
      <div className={isFull ? 'overflow-y-auto' : ''} style={isFull ? { maxHeight: '90vh' } : {}}>
        <div className="text-brown-dim m-2 p-2 cursor-pointer top-0 right-0 absolute" onClick={onClickAction}>
          <CloseIcon width={20} height={20} />
        </div>
        <div>{children}</div>
      </div>
    </div>,
    document.getElementById('modal_root'),
  )
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClick: PropTypes.func,
  isFull: PropTypes.bool,
  confirm: PropTypes.bool,
}

export default Modal
