import { handleActions } from 'redux-actions'

const defaultTransformItems = (oldItems, newItems) => oldItems.concat(newItems)

export const getLoadListReducer =
  (transformItems = defaultTransformItems) =>
  (state, { payload: { rows, ...props } }) => {
    const { page } = props
    const isFirstPage = page === 1
    const data = state
    if (isFirstPage || data.page + 1 === page) {
      const items = isFirstPage ? rows : transformItems(data.items, rows)
      state = Array.isArray(state) ? [...state] : { ...state }
      state = { ...data, ...props, items }
    }
    return state
  }

export const getPropagateUpdateReducer =
  (CardElements, getDestinationTab, shouldMoveToTop) =>
  (state, { payload: item }) => {
    let updatedState = false

    const destinationTab = getDestinationTab?.(item)
    state.forEach((tab, tabIndex) => {
      if (CardElements && !CardElements.includes(tab.CardElement)) return
      let { items } = tab
      const itemIndex = items.findIndex(({ id }) => id === item.id)
      const isDestinationTab = destinationTab === tabIndex
      const isItemFound = itemIndex !== -1

      if (isItemFound || isDestinationTab) {
        if (!updatedState) {
          state = [...state]
          updatedState = true
        }
        items = [...items]

        const moveToTop = itemIndex > 0 && isDestinationTab && shouldMoveToTop?.(items[itemIndex], item)
        if (isItemFound) {
          const removeFromTab = destinationTab !== undefined && !isDestinationTab
          if (moveToTop || removeFromTab) items.splice(itemIndex, 1)
          else items[itemIndex] = item
        }
        if (moveToTop || !isItemFound) items = [item, ...items]

        state[tabIndex] = { ...state[tabIndex], items }
      }
    })
    return state
  }
export const getSetIsLoadingReducer =
  (value) =>
  (state, { payload }) => {
    if (typeof state === 'boolean') return value
    const { key } = payload || {}
    if (key !== undefined) {
      state = Array.isArray(state) ? [...state] : { ...state }
      state[payload.key] = value
      return state
    }
  }

export const getIsLoadingReducer = (actions, actionName, defaultState = false, extraHandlers) => {
  return handleActions(
    {
      [actions[actionName + 'Start']]: getSetIsLoadingReducer(true),
      [actions[actionName + 'Success']]: getSetIsLoadingReducer(false),
      [actions[actionName + 'Failure']]: getSetIsLoadingReducer(false),
      ...extraHandlers,
    },
    defaultState,
  )
}
