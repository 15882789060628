import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import { Field } from 'formik'

const ProfileInfoField = ({ Icon, label, itemKey, value, isEditing, children = undefined }) => {
  return (
    <div className="profile-info-field mb:flex-wrap">
      <Icon className="text-brown-dim" />
      <span>{label}</span>
      {isEditing ? (
        <Field
          id={itemKey}
          name={itemKey}
          className="block form-input text-brown-dim border-gray-300 shadow shadow-inset px-2 py-0 w-128 mt-1 mb-3"
        >
          {children}
        </Field>
      ) : (
        <div>{value}</div>
      )}
    </div>
  )
}

ProfileInfoField.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  value: PropTypes.string,
  children: PropTypes.func,
}

export default memo(ProfileInfoField)
