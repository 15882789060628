import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="20"
    height="20"
    className="fill-current"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.0001 7.49999C10.0001 7.03975 10.3732 6.66666 10.8334 6.66666C11.2937 6.66666 11.6667 7.03975 11.6667 7.49999V10.8333C11.6667 11.2936 11.2937 11.6667 10.8334 11.6667H7.50008C7.03984 11.6667 6.66675 11.2936 6.66675 10.8333C6.66675 10.3731 7.03984 9.99999 7.50008 9.99999H10.0001V7.49999Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3334 9.99999C18.3334 14.6024 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6024 1.66675 9.99999C1.66675 5.39762 5.39771 1.66666 10.0001 1.66666C14.6025 1.66666 18.3334 5.39762 18.3334 9.99999ZM16.6667 9.99999C16.6667 13.6819 13.682 16.6667 10.0001 16.6667C6.31818 16.6667 3.33341 13.6819 3.33341 9.99999C3.33341 6.31809 6.31818 3.33332 10.0001 3.33332C13.682 3.33332 16.6667 6.31809 16.6667 9.99999Z"
    />
  </svg>
)

export default memo(SvgComponent)
