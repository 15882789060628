import React, { memo } from 'react'
import PropTypes from 'prop-types'
import OrderSubHeader from 'root/components/OrderSubHeader'
import i18n from 'root/i18n'
import { timestampToDate } from 'root/constants'
import ResponsibleSelectorFormik from 'root/modules/orders/pages/components/ResponsibleSelector/formik'
import InputTermFormik from 'root/modules/orders/pages/components/InputTerm/formik'
import OrderSpecialTermsFormik from 'root/modules/orders/pages/components/OrderSpecialTerms/formik'
import { RESPONSIBLE_SELLER } from 'root/modules/orders/constants'
import { Formik } from 'formik'
import ViewOrderMemoDocs from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ViewOrderMemoDocs'

const ReadonlyMemoTerms = ({ order }) => {
  return (
    <Formik
      initialValues={{
        memoPaymentTermDays: order.memoId ? order.memoId.paymentTermDays : 30,
        nonReturnPeriod: order.memoId ? order.memoId.nonReturnDays : 30,
        returnShipmentPeriod: order.memoId ? order.memoId.returnShipmentDays : 30,
        memoPenaltyDelayPerDay: order.memoId ? order.memoId.dailyDelayedPaymentFee : 0.03,
        isTaxesResponsibleSeller: order.memoId ? order.memoId.taxesResponsible === RESPONSIBLE_SELLER : true,
        isBankResponsibleSeller: order.memoId ? order.memoId.bankCommissionResponsible === RESPONSIBLE_SELLER : true,
        isDeliveryResponsibleSeller: order.memoId
          ? order.memoId.deliveryPaymentsResponsible === RESPONSIBLE_SELLER
          : true,
        isReturnResponsibleSeller: order.memoId ? order.memoId.returnPaymentResponsible === RESPONSIBLE_SELLER : true,
        specialTermsMemo: order.memoId.specialTerms || '',
      }}
      onSubmit={null}
    >
      {({ values }) => (
        <>
          <div className="container mx-auto lg:w-screen-lg-min mb-2 flex justify-between">
            <OrderSubHeader label={'orders.labels.memoAgreementTerms'} />
          </div>
          <div className="container bg-white-smoke px-8 py-8">
            <div className="container flex justify-between">
              <div className={'my-2'}>
                <span className="text-gray-500">{i18n.value('common.date')}</span>
                <span className="text-brown-dim ml-2">
                  {order.memoId ? timestampToDate(order.memoId.created) : order.date}
                </span>
                {order.memoId && order.memoId.files.length > 0 ? <ViewOrderMemoDocs order={order} /> : ''}
              </div>
              <div className={'my-2'}>
                <span className="text-gray-500">{i18n.value('orders.labels.memoConsignmentAgreement')}</span>
                <span className="text-brown-dim ml-2">#{order.memoId.number}</span>
              </div>
            </div>
            <div className="container mx-auto lg:w-screen-lg-min mb-2 mt-4">
              <div className="text-gray-500 mt-8 flex justify-between">
                <div className="grid grid-cols-2 gap-y-3 auto-rows-min">
                  <ResponsibleSelectorFormik
                    label={'orders.labels.taxResponsible'}
                    itemKey={'isTaxesResponsibleSeller'}
                    isSeller={values.isTaxesResponsibleSeller}
                    isEditable={false}
                  />
                  <ResponsibleSelectorFormik
                    label={'orders.labels.bankResponsible'}
                    itemKey={'isBankResponsibleSeller'}
                    isSeller={values.isBankResponsibleSeller}
                    isEditable={false}
                  />
                  <ResponsibleSelectorFormik
                    label={'orders.labels.deliveryPaymentResponsible'}
                    itemKey={'isDeliveryResponsibleSeller'}
                    isSeller={values.isDeliveryResponsibleSeller}
                    isEditable={false}
                  />
                  <ResponsibleSelectorFormik
                    label={'orders.labels.returnPaymentResponsible'}
                    itemKey={'isReturnResponsibleSeller'}
                    isSeller={values.isReturnResponsibleSeller}
                    isEditable={false}
                  />
                </div>
                <div className="grid grid-cols-3 gap-y-3 auto-rows-min">
                  <InputTermFormik
                    labelBefore={'orders.labels.memoPaymentTermDays'}
                    inputClass={'w-16'}
                    labelAfter={'common.days'}
                    itemKey={'memoPaymentTermDays'}
                    value={values.memoPaymentTermDays}
                    isEditable={false}
                  />
                  <InputTermFormik
                    labelBefore={'orders.labels.nonReturnPeriod'}
                    inputClass={'w-16'}
                    labelAfter={'common.days'}
                    itemKey={'nonReturnPeriod'}
                    value={values.nonReturnPeriod}
                    isEditable={false}
                  />
                  <InputTermFormik
                    labelBefore={'orders.labels.returnShipmentPeriod'}
                    inputClass={'w-16'}
                    labelAfter={'common.days'}
                    itemKey={'returnShipmentPeriod'}
                    value={values.returnShipmentPeriod}
                    isEditable={false}
                  />
                  <InputTermFormik
                    labelBefore={'orders.labels.memoPenaltyDelayPerDay'}
                    inputClass={'w-16'}
                    labelAfter={'common.percent'}
                    itemKey={'memoPenaltyDelayPerDay'}
                    value={values.memoPenaltyDelayPerDay}
                    isEditable={false}
                  />
                </div>
              </div>
            </div>
            {values.specialTermsMemo ? (
              <div className="container mx-auto mb-2 mt-12">
                <OrderSpecialTermsFormik
                  inputClass={'bg-white-smoke'}
                  label={'orders.labels.specialTermsMemo'}
                  itemKey={'specialTermsMemo'}
                  isEditable={false}
                  value={values.specialTermsMemo}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </Formik>
  )
}

ReadonlyMemoTerms.propTypes = {
  order: PropTypes.object.isRequired,
}

export default memo(ReadonlyMemoTerms)
