import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.68865 3.8435L7.28865 1.3435C7.50196 1.1078 7.55637 0.768554 7.42746 0.477972C7.29855 0.187391 7.01054 4.63475e-05 6.69265 0H1.30165C0.984775 0.000932948 0.698005 0.187898 0.56931 0.477461C0.440616 0.767024 0.494003 1.10517 0.705648 1.341L3.30565 3.8435C3.48284 4.0382 3.73389 4.14917 3.99715 4.14917C4.2604 4.14917 4.51146 4.0382 4.68865 3.8435Z"
      fill="#171717"
    />
  </svg>
)

export default memo(SvgComponent)
