import React, { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { stopPropagation, handleChange } from 'root/constants'

const width = 46

const SliderHandleLabel = ({ index, value, onChange, prefix, postfix }) => {
  const inputRef = useRef()
  const [input, setInput] = useState(value)
  useEffect(() => {
    setInput(value)
  }, [value])
  return (
    <div
      className="absolute cursor-text flex bottom-full mb-2 text-brown-dim text-xxs px-1 border rounded"
      style={{ left: (12 - width) / 2, width, backgroundColor: '#F2EDF0' }}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      onClick={(e) => {
        inputRef.current.focus()
        e.stopPropagation()
      }}
    >
      <span>{prefix}</span>
      <input
        onBlur={({ target: { value } }) => {
          value = parseFloat(value)
          onChange(index ? [undefined, value] : [value, undefined])
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') inputRef.current.blur()
        }}
        onChange={handleChange(setInput)}
        ref={inputRef}
        value={input}
        type="text"
        className="box-content w-full focus:outline-none bg-transparent text-center"
      />
      <span>{postfix}</span>
    </div>
  )
}

SliderHandleLabel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default memo(SliderHandleLabel)
