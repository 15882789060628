import React, { memo } from 'react'
import PropTypes from 'prop-types'
import LocationIcon from 'root/icons/LocationIcon'
import i18n from 'root/i18n'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'
import { useHistory } from 'react-router-dom'
import { ACTION_ACTIVATE, ACTION_ARCHIVE, ACTION_REMOVE, COLOR_FANCY } from 'root/modules/products/constants'
import { ensureBoolean, formatNumberToPrice, getPriceFormatter } from 'root/constants'

const ProductPreview = ({ isCatalog, product, performItemAction }) => {
  const history = useHistory()
  const formatter = getPriceFormatter(product.currency)

  const removeItem = () => performItemAction(ACTION_REMOVE, product.id)
  const onButtonClick = () => performItemAction(isArchived ? ACTION_ACTIVATE : ACTION_ARCHIVE, product.id)

  const openProductForm = () => history.push(`/products/${product.id}`)

  const isArchived = product.status.key === 'archive'

  return (
    <div className="absolute top-0 right-0 pt-6 pl-8 mt-6 mx-2 text-left w-102 group-hover:block hover:block">
      <div className="w-102">
        <div className="mb-8 w-102">
          {product.mainImage && (
            <img className="object-contain h-64 w-102" src={product.mainImage} alt={product.name} />
          )}
        </div>
        <a
          onClick={isCatalog ? undefined : openProductForm}
          href={product.url}
          className="block mb-4 text-base underline text-brown-late decoration-color-blue-silver font-damascus cursor-pointer"
        >
          {product.name}
        </a>
        <div className="text-xl whitespace-no-wrap font-lugansky">
          {product.colorType.id != COLOR_FANCY ? (
            <span className="text-brown-dim mr-3">R - {product.referenceDiscount}%</span>
          ) : null}
          <span className="text-blue-dark">{formatNumberToPrice(product.price, formatter)}</span>
        </div>
        <div className="text-base">
          <LocationIcon className="inline mr-2 text-brown-dim" width={20} height={20} />
          <span className="text-gray-600">{i18n.value('product.labels.location')}: </span>
          <span className="text-gray-500">
            {product.zipCodeId?.countryId?.name ? product.zipCodeId.countryId.name : '-'}
          </span>
        </div>
        {isCatalog && (
          <div className="mt-4">
            <div className="font-semibold uppercase text-blue-dark font-base">
              {i18n.value('product.labels.producerName')}
            </div>
            <div className="flex space-x-4 text-base text-gray-600">
              <span className="font-semibold">{product.company.name}</span>
              <LocationIcon className="inline mr-2 text-brown-dim" width={20} height={20} />
              <span>{[product.company.countryId.name, product.company.countryId.code].join(', ')}</span>
            </div>
          </div>
        )}
        <div className="mt-4 text-sm grid grid-cols-2">
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.certificateNumber')}: </span>
            <br />
            <span className="text-brown-dim">
              {isCatalog ? (
                product.certificateNumber.value
              ) : (
                <a
                  href={product.certificateNumber.url}
                  className="text-sm underline text-blue-dark"
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  {product.certificateNumber.value}
                </a>
              )}
            </span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.tableSize')}: </span>
            <span className="text-brown-dim">{`${product.tableSize} %`}</span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.certificate')}: </span>
            <span className="text-brown-dim">{product.certificate.text}</span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.totalDepth')}: </span>
            <span className="text-brown-dim">{`${product.totalDepth} ${i18n.value('common.mm')}`}</span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.polish')}: </span>
            <span className="text-brown-dim">{product.polish.text}</span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.fluorescence')}: </span>
            <span className="text-brown-dim">{product.fluorescence.text}</span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.symmetry')}: </span>
            <span className="text-brown-dim">{product.symmetry.text}</span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.haa')}: </span>
            <span className="text-brown-dim">
              {i18n.value(`common.${ensureBoolean(product.heartsAndArrows) ? 'yes' : 'no'}`)}
            </span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.measurements')}: </span>
            <br />
            <span className="text-brown-dim">
              {product.measurementsX ? (
                <p>
                  {product.measurementsX} x {product.measurementsY} x {product.measurementsZ} {i18n.value('common.mm')}
                </p>
              ) : (
                '---'
              )}
            </span>
          </div>
          <div>
            <span className="text-gray-500">{i18n.value('products.labels.field.technology')}: </span>
            <span className="text-brown-dim">{product.grownTechnology.text}</span>
          </div>
        </div>
        {!isCatalog && (
          <div className="flex mt-6">
            <BrownButton onClick={onButtonClick} label={'common.' + (isArchived ? 'activate' : 'archive')} />
            {!product.inReserve && <BlueButton className="ml-4" label={'common.delete'} onClick={removeItem} />}
            <div
              onClick={openProductForm}
              className="cursor-pointer text-center text-blue-skyblue items-center flex ml-4 hover:text-blue-300"
            >
              {i18n.value('products.labels.viewAndEdit')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ProductPreview.propTypes = {
  product: PropTypes.object,
  performItemAction: PropTypes.func.isRequired,
  isCatalog: PropTypes.bool,
}

export default memo(ProductPreview)
