import React, { memo } from 'react'
import PropTypes from 'prop-types'
import GeneralTerms
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/GeneralTerms'
import CardDealAgreed
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealAgreed'
import CardDealPayment
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealPayment'
import CardDealDelivery
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealDelivery'
import CardDealClosed
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealClosed'
import CardDealAdditionalPayment
  from 'root/modules/orders/pages/Order/components/InvoiceOrder/components/components/Card/CardDealAdditionalPayment'
import { DOCUMENT_TYPE_ADDITIONAL_INVOICE } from 'root/modules/orders/constants'

const DealCompleted = ({ help, order, asSeller }) => {
  const invoice = order.documents.find(
    (document) => document.type === DOCUMENT_TYPE_ADDITIONAL_INVOICE)
  const invoicePaidDate = invoice?.specification.paymentReceived
  const invoicePaid = invoicePaidDate > 0
  const hadAdditionalPayment = invoice && invoicePaid
  return (
    <>
      <section>
        <GeneralTerms
          order={order}
          number={order.number}
          date={order.date}
          editable={false}
          buyer={asSeller && order.buyerCompanyId.name}
          seller={!asSeller && order.sellerCompanyId.name}
          showKYC={false}
          asSeller={asSeller}
        />
      </section>
      <div className="flex flex-col items-center justify-center">
        <CardDealAgreed help={help} order={order}/>
        {order.paymentBeforeDelivery ? <CardDealPayment help={help}
                                                        order={order}
                                                        beforeDelivery/> : ''}
        <CardDealDelivery help={help} order={order}/>
        {order.paymentAfterDelivery
          ? <CardDealPayment help={help} order={order} beforeDelivery={false}/>
          : ''}
        {hadAdditionalPayment
          ? <CardDealAdditionalPayment help={help} order={order}/>
          : null}
        <CardDealClosed help={help} order={order}/>
      </div>
    </>
  )
}

DealCompleted.propTypes = {
  order: PropTypes.object.isRequired,
  help: PropTypes.string,
  asSeller: PropTypes.bool.isRequired,
}

export default memo(DealCompleted)
