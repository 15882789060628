import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { toClassName } from 'root/constants'
import ExclaimIcon from 'root/icons/ExclaimIcon'

const InputFormik = ({
  inputClass = 'w-32',
  textColor = 'text-brown-dim',
  paddingClass = 'px-2',
  itemKey,
  value,
  isEditable = true,
  children,
  onBlur,
  ...other
}) => (
  <div>
    {isEditable ? (
      <Field
        id={itemKey}
        name={itemKey}
        className={'form-input text-left text-brown-dim border-gray-300 shadow shadow-inset px-2 py-0 ' +
          inputClass}
        {...other}
      >
        {({ field, form, meta, ...props }) => {
          const showError = meta.touched && meta.error
          const onChange = other.onChange
            ? (...args) => {
              field.onChange(...args)
              other.onChange?.(...args, props)
            }
            : field.onChange
          return (
            <span className="group relative flex-inline flex-col">
              {showError && (
                <div
                  className="z-10 bg-red-300 text-white p-1 text-xs absolute hidden group-hover:block top-full left-0 whitespace-no-wrap ml-2 mt-2">
                  {meta.error}
                </div>
              )}
              {children ? (
                children({ field, form, meta, textColor, inputClass, ...props })
              ) : (
                <input
                  type="text"
                  {...field}
                  onChange={onChange}
                  onBlur={(...args) => {
                    onBlur?.(...args)
                    field.onBlur(...args)
                  }}
                  className={toClassName([
                    'form-input text-left  shadow shadow-inset py-0 ',
                    paddingClass,
                    textColor,
                    inputClass,
                    showError ? 'border-red-500' : 'border-gray-300',
                  ])}
                />
              )}
              {showError && (
                <i
                  className="text-red-700 absolute right-0 transform translate-x-1/2 -translate-y-1/2">
                  <ExclaimIcon width={15} height={15}/>
                </i>
              )}
            </span>
          )
        }}
      </Field>
    ) : (
      <label className="text-brown-dim ">{value}</label>
    )}
  </div>
)

InputFormik.propTypes = {
  inputClass: PropTypes.string,
  textColor: PropTypes.string,
  paddingClass: PropTypes.string,
  isEditable: PropTypes.bool,
  value: PropTypes.any,
  itemKey: PropTypes.string.isRequired,
  children: PropTypes.func,
  onBlur: PropTypes.func,
}

export default memo(InputFormik)
