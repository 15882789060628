import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { COLORS } from 'root/constants'

const SvgComponent = ({ isRead, ...props }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#E5FDCB"
    strokeWidth={20}
    style={{ display: 'inline', bottom: 4, position: 'relative' }}
    {...props}
  >
    {isRead && (
      <path
        d="m509.226 266.755-48.047-48.047-48.045 48.045-71.524 61.524-77.629 87.629-107.261-107.261-48.047 48.047 49.216 59.216 58.048 48.048 48.044 48.044z"
        fill={COLORS.CLOUD}
        transform="translate(30,0)"
      />
    )}
    <path
      d="m413.136 266.755-48.047-48.047-197.199 197.198-107.261-107.261-48.047 48.047 155.308 155.308z"
      fill={COLORS.CLOUD}
    />
  </svg>
)

SvgComponent.propTypes = {
  isRead: PropTypes.bool,
}
export default memo(SvgComponent)
