import { combineReducers } from 'redux'

import order from './order'
import orderList from './orderList'
import initialTerms from './initialTerms'
import notificationsSummary from './notificationsSummary'

export default combineReducers({
  order,
  orderList,
  initialTerms,
  notificationsSummary,
})
