import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="27" height="31" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.80521 23.0089C1.27389 20.8428 0.375977 18.1915 0.375977 15.3222C0.375977 8.0012 6.25069 2.05859 13.5036 2.05859C16.434 2.05859 19.2669 2.88142 21.4525 4.52003"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0071 7.375C25.6498 9.59029 26.6243 12.3401 26.6243 15.3219C26.6243 22.6429 20.7496 28.5855 13.4967 28.5855C10.5524 28.5855 7.76813 27.7064 5.58252 26.0538"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9561 5.21035L21.4665 4.52115L19.3714 0.378906"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2599 25.2734L5.58936 26.0541L7.96987 30.6183"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0626 14.8086V19.0282L13.8375 22.6782L6.16699 19.0282V14.8578"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.16699 10.8242L13.8375 14.017L21.0626 10.8242"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8379 14.0156V22.6799"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8379 14.017L11.5826 17.4349L3.91211 13.792L6.16733 10.8242"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.16699 10.8232L13.6148 8.3125L21.0626 10.8232"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.063 10.8242L23.0885 13.792L16.3228 17.2099L13.8379 14.017"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6152 8.3125V11.5053"
      stroke="currentColor"
      strokeWidth="1.075"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(SvgComponent)
