import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="103" height="22" viewBox="0 0 103 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 6.15897L5.75154 0H11.0962L9.40303 1.80954H6.53634L2.38709 6.25345L11.8337 18.1499L14.5151 14.7706L7.67357 6.15897L13.4287 0H25.5931L31.3447 6.15897L19.5146 21.0604L16.7678 17.5939L17.9232 16.1369L19.5146 18.1499L28.9576 6.25345L24.8083 1.80954H19.609L23.6674 6.15897L11.8337 21.0604L0 6.15897ZM10.0643 6.25345L15.6705 13.3136L21.2767 6.25345L17.1311 1.80954H14.2172L10.0643 6.25345Z"
      fill="#4A2E4D"
    />
    <path d="M38.2833 15.7617H44.1549V16.8986H37V4.30472H38.2833V15.7617Z" fill="#323D4D" />
    <path
      d="M55.0951 6.58442C54.6557 6.12145 54.1341 5.76397 53.5306 5.50611C52.927 5.25412 52.2297 5.12519 51.4503 5.12519C50.6416 5.12519 49.915 5.2717 49.2704 5.57058C48.6258 5.8636 48.075 6.26796 47.6238 6.77195C47.1726 7.27594 46.8268 7.85612 46.5807 8.51834C46.3346 9.18056 46.2174 9.87794 46.2174 10.6164C46.2174 11.3489 46.3405 12.0521 46.5807 12.7144C46.821 13.3766 47.1726 13.9568 47.6238 14.4549C48.075 14.953 48.62 15.3515 49.2704 15.6445C49.915 15.9376 50.6416 16.0899 51.4503 16.0899C52.1593 16.0899 52.8156 16.0137 53.4075 15.8614C53.9993 15.709 54.4799 15.5098 54.849 15.2753V11.4485H51.9132V10.3116H56.1265V16.002C55.4409 16.4181 54.6967 16.7287 53.9056 16.928C53.1086 17.1272 52.2883 17.2327 51.4327 17.2327C50.4834 17.2327 49.6044 17.0686 48.8016 16.7346C47.993 16.4005 47.3015 15.9434 46.7214 15.3574C46.1412 14.7713 45.6842 14.074 45.3619 13.2652C45.0337 12.4565 44.8755 11.5774 44.8755 10.6164C44.8755 9.65525 45.0396 8.77033 45.3736 7.96746C45.7076 7.15873 46.1647 6.46135 46.7507 5.87531C47.3366 5.28928 48.034 4.82631 48.8309 4.49813C49.6337 4.16409 50.501 4 51.4386 4C52.4465 4 53.3255 4.15237 54.0697 4.45125C54.8139 4.75012 55.4585 5.17207 55.9917 5.70536L55.0951 6.58442Z"
      fill="#323D4D"
    />
    <path
      d="M59.3965 4.30472H63.5746C64.3598 4.30472 65.145 4.43365 65.9303 4.69736C66.7214 4.96108 67.4304 5.35373 68.0633 5.88116C68.6961 6.40859 69.2118 7.06495 69.6044 7.8561C69.997 8.64725 70.1904 9.56147 70.1904 10.6046C70.1904 11.6595 69.997 12.5796 69.6044 13.3648C69.2118 14.1501 68.702 14.8006 68.0633 15.3281C67.4304 15.8555 66.7155 16.2481 65.9303 16.5119C65.1392 16.7756 64.354 16.9045 63.5746 16.9045H59.3965V4.30472ZM60.6798 15.7617H63.223C64.1723 15.7617 64.9985 15.6152 65.7017 15.3163C66.4049 15.0175 66.9909 14.6307 67.4538 14.1501C67.9168 13.6696 68.2625 13.1187 68.4969 12.5034C68.7254 11.888 68.8426 11.2551 68.8426 10.5988C68.8426 9.94825 68.7254 9.30947 68.4969 8.69413C68.2684 8.07879 67.9168 7.52792 67.4538 7.04737C66.9909 6.56682 66.4049 6.18004 65.7017 5.88116C64.9985 5.58228 64.1664 5.43577 63.223 5.43577H60.6798V15.7617Z"
      fill="#323D4D"
    />
    <path
      d="M74.1461 15.7617H80.8908V16.8986H72.8628V4.30472H80.674V5.44163H74.1461V9.76658H80.2463V10.9035H74.1461V15.7617Z"
      fill="#80506F"
    />
    <path
      d="M83.3048 16.8986H81.8984L87.3423 4.30472H88.5846L93.9933 16.8986H92.5693L91.1805 13.5875H84.676L83.3048 16.8986ZM85.1565 12.4155H90.7059L87.9283 5.72879L85.1565 12.4155Z"
      fill="#80506F"
    />
    <path d="M97.1285 15.7617H103V16.8986H95.8511V4.30472H97.1344V15.7617H97.1285Z" fill="#80506F" />
  </svg>
)

export default memo(SvgComponent)
