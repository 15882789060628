import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import {
  ACTION_MEMO_SUPPLEMENTARY_UPLOADED,
  SECTION_SALES,
} from 'root/modules/orders/constants'
import WaitResponseScreen
  from 'root/modules/orders/pages/components/WaitResponseScreen'
import UploadDocument from 'root/modules/orders/pages/components/UploadDocument'
import i18n from 'root/i18n'
import { getDocument, uploadOrderDocument } from 'root/api'
import DealNegotiationMemoForm
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/DealNegotiationMemo/DealNegotiationMemoForm'

import TemplateDoc from 'root/modules/orders/pages/components/TemplateDoc'
import ViewOrderMemoDocs
  from 'root/modules/orders/pages/Order/components/MemoOrder/components/components/ViewOrderMemoDocs'
import DocumentRowIcon from 'root/icons/DocumentRowIcon'
import OrderTimer from 'root/components/OrderTimer'

const WaitingMemoSupplementary = ({
  order,
  performOrderAction,
  readOnly = false,
  asSeller,
}) => {
  const { section } = useParams()
  if (section === SECTION_SALES) {
    const buttons = readOnly
      ? []
      : [
        {
          color: 'brown',
          label: 'orders.buttons.confirmUploadMemoSupplementary',
          action: ACTION_MEMO_SUPPLEMENTARY_UPLOADED,
          params: {
            documentId: order.documents[0].id,
          },
        },
      ]

    const uploadSupplementary = (file) => uploadOrderDocument(order.id, file)

    return (
      <>
        <DealNegotiationMemoForm order={order} isEditable={false}
                                 asSeller={asSeller}/>

        <section className="flex space-x-4">
          <div className="text-brown-dim">
            <DocumentRowIcon width={50} height={50}/>
          </div>
          <div className="flex-grow">
            <div className="text-lg text-brown-dim">
              <b>Memo terms agreed by customer</b>
            </div>
            <div
              className="text-brown-dim"
              dangerouslySetInnerHTML={{
                __html: i18n.value(
                  'orders.labels.supplierUploadNewSupplementary'),
              }}
            />
            <div className="flex">
              <TemplateDoc
                label={'orders.labels.lgDealMemoSupplementaryTemplate'}
                onClick={() => {
                  getDocument(order.documents[0].id)
                }}
              />
              <div className="ml-auto">
                <ViewOrderMemoDocs order={order}/>
              </div>
            </div>
            <div className="mt-4">
              {order.timer.isStarted
                ? <OrderTimer timer={order.timer}
                              label={'orders.labels.replyTimer'}/>
                : ''}
            </div>
            {readOnly ? null : (
              <UploadDocument
                accept={'image/*, application/pdf'}
                buttons={buttons}
                uploadDocumentFunction={uploadSupplementary}
                performOrderAction={performOrderAction}
              />
            )}
          </div>
        </section>
      </>
    )
  } else {
    let buttons = order.timer.isExpired
      ? [
        {
          color: 'blue',
          label: 'orders.buttons.backToInvoice',
          action: 'return_to_deal_invoice',
        }]
      : []
    const labels = {
      heading: 'Memo terms agreed',
      headingBeforeTimer: 'orders.labels.waitSupplierUploadDoc',
      timer: 'orders.labels.supplierReplyTimer',
    }
    return (
      <div className="container mx-auto items-center">
        <DealNegotiationMemoForm
          order={order}
          performOrderAction={performOrderAction}
          buttons={[]}
          isEditable={false}
          asSeller={asSeller}
        />
        <WaitResponseScreen order={order}
                            performOrderAction={performOrderAction}
                            buttons={buttons} labels={labels}/>
      </div>
    )
  }
}

WaitingMemoSupplementary.propTypes = {
  order: PropTypes.object.isRequired,
  performOrderAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  asSeller: PropTypes.bool.isRequired,
}

export default memo(WaitingMemoSupplementary)
