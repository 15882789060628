import React, { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import Spinner from 'root/components/Spinner'
import { errorAlert, storeLocalFiles } from 'root/constants'
import i18n from 'root/i18n'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'
import DropFilesIcon from 'root/icons/DropFilesIcon'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const UploadDocumentInsideFormik = ({
  accept,
  maxFiles = 1,
  buttons,
  uploadDocumentFunction,
  isSubmitting,
  handleSubmit,
  setFieldValue,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  let settings = {
    maxFiles: maxFiles,
    onDropAccepted: (files) => {
      setIsLoading(true)
      Promise.all(files.map(uploadDocumentFunction))
        .then(storeLocalFiles)
        .finally(() => setIsLoading(false))
    },
  }

  if (accept) {
    settings.accept = accept
  }
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone(settings)
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {(file.size / 1024 / 1024).toFixed(2)} MB
    </li>
  ))
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {(file.size / 1024 / 1024).toFixed(2)} MB
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <>
      <section className="container my-4">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p dangerouslySetInnerHTML={{ __html: i18n.value('common.dragAndDropFiles') }} />
          <div className="text-center my-4">
            <DropFilesIcon />
          </div>
          <p>{i18n.value('common.imageOrPDFUpload')}</p>
        </div>
        <div className="ml-6 my-2">
          {acceptedFileItems.length > 0 ? (
            <>
              <div className="flex justify-between">
                <div>
                  <h2 className="text-brown-dim text-lg">{i18n.value('common.acceptedFiles')}</h2>
                  <ul className="list-inside list-disc">{acceptedFileItems}</ul>
                </div>
                {isLoading ? (
                  <Spinner />
                ) : buttons.length < 1 ? (
                  ''
                ) : (
                  <div>
                    <FormikSubmittingButtons
                      isSubmitting={isSubmitting}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      buttons={buttons}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            ''
          )}
          {fileRejectionItems.length > 0 ? (
            <>
              <h4 className="text-red-500 text-lg">{i18n.value('common.rejectedFiles')}</h4>
              <ul>{fileRejectionItems}</ul>
            </>
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  )
}

UploadDocumentInsideFormik.propTypes = {
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  buttons: PropTypes.array,
  uploadDocumentFunction: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default memo(UploadDocumentInsideFormik)
