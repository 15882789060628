import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import * as Yup from 'yup'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import RequiredLabel from 'root/components/RequiredLabel'
import { COUNTRY_INFO, toClassName } from 'root/constants'

const FIELD_LABELS = {
  countryId: i18n.value('order.labels.address.countryId'),
  companyName: i18n.value('order.labels.address.companyName'),
  addressLine1: i18n.value('order.labels.address.addressLine1'),
  cityName: i18n.value('order.labels.address.cityName'),
  zipCode: i18n.value('order.labels.address.zipCode'),
  recipientName: i18n.value('order.labels.address.recipientName'),
  recipientPhone: i18n.value('order.labels.address.recipientPhone'),
}

export const getAddressValidationSchema = (withoutRecipient, required = true) => {
  return Yup.object().shape({
    ...(required
      ? {
          companyName: Yup.string()
            .required(FIELD_LABELS['companyName'] + ' value is required')
            .max(100),
          countryId: Yup.string().required(FIELD_LABELS['countryId'] + ' value is required'),
          addressLine1: Yup.string()
            .required(FIELD_LABELS['addressLine1'] + ' value is required')
            .max(500),
          cityName: Yup.string()
            .required(FIELD_LABELS['cityName'] + ' value is required')
            .max(100),
          zipCode: Yup.string()
            .required(FIELD_LABELS['zipCode'] + ' value is required')
            .max(100),
        }
      : {
          companyName: Yup.string().max(100),
          countryId: Yup.string(),
          addressLine1: Yup.string().max(500),
          cityName: Yup.string().max(100),
          zipCode: Yup.string().max(100),
        }),
    ...(withoutRecipient
      ? {}
      : {
          recipientName: Yup.string()
            .required(FIELD_LABELS['recipientName'] + ' value is required')
            .max(100),
          recipientPhone: Yup.string()
            .required(FIELD_LABELS['recipientPhone'] + ' value is required')
            .max(100),
        }),
  })
}

const addressFields = [
  { key: 'countryId', isRequired: true },
  { key: 'addressLine1', isRequired: true },
  { key: 'addressLine2', isRequired: false },
  { key: 'cityName', isRequired: true },
  { key: 'regionName', isRequired: false },
  { key: 'zipCode', isRequired: true },
]

const DeliveryAddress = ({ address, isEditable, itemKey = 'address', withoutRecipient, isOptional = false }) => {
  const fields = withoutRecipient
    ? addressFields
    : addressFields.concat([
        { key: 'companyName', isRequired: true },
        { key: 'recipientName', isRequired: true },
        { key: 'recipientPhone', isRequired: true },
      ])

  return (
    <>
      {fields.map(({ key, isRequired }) => {
        const label = i18n.value(`order.labels.address.${key}`)

        return (
          <div className="mt-2" key={key}>
            <RequiredLabel label={label} isRequired={isRequired && !isOptional} />
            <InputFormik
              isEditable={isEditable}
              itemKey={`${itemKey}["${key}"]`}
              value={address[key]}
              inputClass={'w-full'}
            >
              {key === 'countryId'
                ? ({ field, textColor, inputClass }) => (
                    <select
                      {...field}
                      className={toClassName([
                        'form-select text-left  shadow shadow-inset px-2 py-0',
                        inputClass,
                        textColor,
                      ])}
                    >
                      {COUNTRY_INFO.map(({ code, name }) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                    </select>
                  )
                : undefined}
            </InputFormik>
          </div>
        )
      })}
    </>
  )
}

DeliveryAddress.propTypes = {
  address: PropTypes.object.isRequired,
  itemKey: PropTypes.string,
  isEditable: PropTypes.bool,
  withoutRecipient: PropTypes.bool,
  isOptional: PropTypes.bool,
}

export default memo(DeliveryAddress)
