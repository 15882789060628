import React, { memo } from 'react'
import Column from './Column'
import { RemoveIcon } from 'root/icons'
import { useAsyncLoad } from 'root/hooks'
import i18n from 'root/i18n'
import { ACTION_REMOVE_PRODUCT, calcAverageR } from 'root/modules/orders/constants'
import { confirmAlert } from 'root/components/AlertWrapper'
import { doNothing } from 'root/constants'

const RemoveColumn = ({
  children,
  doAction,
  batch = true,
  itemKey = 'orderItems',
  allowDeleteAll = false,
  recalculateAverageR = true,
  ...props
}) => {
  const [loading, removeProduct] = useAsyncLoad((id) => doAction(ACTION_REMOVE_PRODUCT, { items: [id] }))

  return (
    <Column
      label="order.diamonds.remove"
      columnClass="w-16 text-center"
      className="w-16 text-center flex items-center justify-center cursor-pointer text-blue-silver hover:text-blue-dark"
      {...props}
    >
      {children ||
        (({ item, items, formContext }) =>
          !item.disabled &&
          (allowDeleteAll || items.filter(({ disabled }) => !disabled).length > 1) && (
            <button
              disabled={loading}
              type="button"
              onClick={() => {
                confirmAlert(i18n.value('order.labels.confirmRemoveProduct'))
                  .then(() => {
                    if (batch) {
                      formContext.setFieldValue(`${itemKey}['${item.id}'].disabled`, true)
                      if (recalculateAverageR) {
                        formContext.setFieldValue(`averageR`, calcAverageR(items.filter((v) => v.id !== item.id)))
                      }
                    } else {
                      removeProduct(item.id)
                    }
                  })
                  .catch(doNothing)
              }}
            >
              <RemoveIcon className={loading ? 'animate-ping' : ''} />
            </button>
          ))}
    </Column>
  )
}

RemoveColumn.propTypes = Column.propTypes

export default memo(RemoveColumn)
