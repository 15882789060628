import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import FormikSubmittingButtons from 'root/components/Buttons/FormikSubmittingButtons'

const EmptyFormWithActions = ({ buttons, performOrderAction }) => {
  return (
    <Formik
      initialValues={{
        action: '',
      }}
      onSubmit={async (values) => {
        await performOrderAction(values.action, values)
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          {buttons.length > 0 ? (
            <FormikSubmittingButtons
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              buttons={buttons}
            />
          ) : null}
        </Form>
      )}
    </Formik>
  )
}

EmptyFormWithActions.propTypes = {
  buttons: PropTypes.array.isRequired,
  performOrderAction: PropTypes.func.isRequired,
}

export default memo(EmptyFormWithActions)
