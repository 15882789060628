import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'root/i18n'
import { Field } from 'formik'

const Input = ({
  label,
  itemKey,
  className = 'form-input w-full text-lg text-left text-brown-dim border-t-0 border-l-0 border-r-0 border-b border-blue-silver p-0 rounded-none focus:shadow-none focus:border-brown-dim',
  ...other
}) => (
  <div>
    <div className="text-gray-500">{i18n.value(label)}</div>
    <div>
      <Field id={itemKey} name={itemKey} className={className} {...other} />
    </div>
  </div>
)
Input.propTypes = {
  label: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  className: PropTypes.string,
}
export default memo(Input)
