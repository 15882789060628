import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TextLabel from 'root/components/TextLabel'
import TextValue from 'root/modules/orders/pages/Order/components/components/TextValue'
import { childrenPropType } from 'root/constants'

const TextInfo = ({ label, children, className = '' }) => (
  <div className={className}>
    <TextLabel label={label} />
    <TextValue className="ml-2">{children}</TextValue>
  </div>
)

TextInfo.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: childrenPropType,
}

export default memo(TextInfo)
