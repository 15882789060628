import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg width="93" height="101" viewBox="0 0 93 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.11621 13.9376L22.1318 0H34.2265L30.395 4.09493H23.9077L14.5181 14.1513L35.8955 41.0726L41.9634 33.4255L26.4813 13.9376L39.505 0H67.0326L80.0481 13.9376L53.277 47.659L47.0611 39.8145L49.6758 36.5172L53.277 41.0726L74.6462 14.1513L65.2566 4.09493H53.4908L62.6749 13.9376L35.8955 47.659L9.11621 13.9376ZM31.8914 14.1513L44.5781 30.1281L57.2647 14.1513L47.8833 4.09493H41.2892L31.8914 14.1513Z"
      fill="#4A2E4D"
    />
    <path
      d="M3.83149 98.7465V97.8584H3.81505C3.65883 98.1709 3.42039 98.4094 3.10795 98.5985C2.79551 98.7794 2.44196 98.8698 2.0473 98.8698C1.44709 98.8698 0.953762 98.6478 0.575546 98.212C0.19733 97.768 0 97.1677 0 96.3866C0 95.5972 0.205552 94.9558 0.624878 94.4707C1.0442 93.9773 1.57864 93.7388 2.24463 93.7388C2.97639 93.7388 3.50261 94.0431 3.81505 94.6433H3.83149V91.5023H4.27548V98.7547H3.83149V98.7465ZM3.83149 95.7616C3.83149 95.3094 3.68349 94.9229 3.39572 94.6022C3.10795 94.2815 2.72151 94.1253 2.22818 94.1253C1.71842 94.1253 1.29087 94.3227 0.953762 94.7174C0.616656 95.112 0.452215 95.6547 0.452215 96.3372C0.452215 97.0115 0.600212 97.5378 0.904429 97.8996C1.20865 98.2696 1.59508 98.4505 2.07197 98.4505C2.63107 98.4505 3.05862 98.2778 3.37105 97.9324C3.67527 97.5871 3.83149 97.1677 3.83149 96.6661V95.7616Z"
      fill="#585551"
    />
    <path
      d="M6.05147 92.6128C5.9528 92.6128 5.87058 92.5799 5.79658 92.5059C5.72259 92.4319 5.6897 92.3497 5.6897 92.2346C5.6897 92.1277 5.73081 92.0454 5.80481 91.9797C5.8788 91.9139 5.96103 91.881 6.05969 91.881C6.15836 91.881 6.2488 91.9139 6.3228 91.9797C6.3968 92.0454 6.43791 92.1277 6.43791 92.2346C6.43791 92.3332 6.40502 92.4237 6.33102 92.4977C6.24058 92.5799 6.15836 92.6128 6.05147 92.6128ZM5.83769 98.747V93.8462H6.27346V98.747H5.83769Z"
      fill="#585551"
    />
    <path
      d="M10.7709 98.7469V97.7684H10.7545C10.5982 98.1056 10.368 98.3687 10.072 98.566C9.77603 98.7634 9.43892 98.8621 9.07715 98.8621C8.60849 98.8621 8.24672 98.7305 7.97539 98.4674C7.70406 98.2042 7.57251 97.8753 7.57251 97.4806C7.57251 96.6255 8.13983 96.1074 9.26626 95.9348L10.7709 95.7127C10.7709 94.6602 10.3762 94.134 9.58692 94.134C9.04426 94.134 8.52627 94.3478 8.0165 94.7836V94.2491C8.19739 94.1093 8.44405 93.986 8.75649 93.8791C9.06893 93.7804 9.3567 93.7228 9.63625 93.7228C10.1378 93.7228 10.5325 93.8791 10.8038 94.1915C11.0833 94.504 11.2149 94.9562 11.2149 95.5565V98.7387H10.7709V98.7469ZM9.42248 96.3048C8.91271 96.3788 8.55094 96.4939 8.34539 96.6584C8.13983 96.8228 8.03295 97.0859 8.03295 97.456C8.03295 97.7438 8.13161 97.9822 8.32072 98.1713C8.50983 98.3605 8.78116 98.4509 9.12648 98.4509C9.59514 98.4509 9.9898 98.2782 10.3022 97.9247C10.6147 97.5793 10.7709 97.1271 10.7709 96.5761V96.0992L9.42248 96.3048Z"
      fill="#585551"
    />
    <path
      d="M18.8285 98.747V95.8032C18.8285 95.1865 18.7298 94.7589 18.5407 94.504C18.3516 94.2573 18.0392 94.134 17.6281 94.134C17.2416 94.134 16.9128 94.2985 16.625 94.6274C16.3454 94.9563 16.2057 95.3756 16.2057 95.8772V98.747H15.7617V95.7703C15.7617 94.6767 15.3506 94.134 14.5201 94.134C14.1255 94.134 13.7884 94.2984 13.5253 94.6356C13.2621 94.9727 13.1306 95.4003 13.1306 95.9266V98.747H12.6948V93.8462H13.1306V94.6603H13.147C13.4841 94.0353 13.9857 93.7311 14.6435 93.7311C14.997 93.7311 15.3094 93.8298 15.5561 94.0435C15.811 94.2491 15.9837 94.5205 16.0659 94.8576C16.2221 94.504 16.4359 94.2244 16.7236 94.0353C17.0114 93.838 17.3156 93.7393 17.6527 93.7393C18.7216 93.7393 19.256 94.4053 19.256 95.7292V98.7552H18.8285V98.747Z"
      fill="#585551"
    />
    <path
      d="M22.7012 98.8621C21.9859 98.8621 21.4185 98.6236 21.0074 98.1549C20.5881 97.6862 20.3826 97.0695 20.3826 96.3212C20.3826 95.5072 20.5963 94.874 21.0321 94.4135C21.4679 93.9531 22.0434 93.7228 22.7505 93.7228C23.4494 93.7228 24.0003 93.9531 24.4032 94.4053C24.806 94.8576 25.0116 95.4907 25.0116 96.2966C25.0116 97.0613 24.806 97.678 24.3949 98.1467C23.9838 98.6318 23.4165 98.8621 22.7012 98.8621ZM22.7341 94.1258C22.1585 94.1258 21.6981 94.3231 21.3528 94.7096C21.0074 95.096 20.843 95.6387 20.843 96.3295C20.843 96.9708 21.0074 97.4889 21.3446 97.8753C21.6817 98.2618 22.1339 98.4591 22.7176 98.4591C23.3096 98.4591 23.7618 98.27 24.0825 97.8836C24.4032 97.4971 24.5594 96.9708 24.5594 96.2883C24.5594 95.5894 24.4032 95.0549 24.0825 94.6849C23.7618 94.3149 23.3179 94.1258 22.7341 94.1258Z"
      fill="#585551"
    />
    <path
      d="M29.5585 98.747V95.8937C29.5585 94.7178 29.1309 94.1258 28.284 94.1258C27.8154 94.1258 27.4289 94.2985 27.1247 94.6438C26.8205 94.9892 26.6725 95.4168 26.6725 95.9266V98.7388H26.2285V93.838H26.6643V94.7261H26.6807C27.0343 94.0518 27.5934 93.7147 28.358 93.7147C28.8843 93.7147 29.2954 93.8873 29.5749 94.2409C29.8545 94.5945 29.9942 95.0961 29.9942 95.7457V98.7223H29.5585V98.747Z"
      fill="#585551"
    />
    <path
      d="M34.9521 98.7469V97.8589H34.9357C34.7794 98.1713 34.541 98.4098 34.2286 98.5989C33.9161 98.7798 33.5626 98.8703 33.1679 98.8703C32.5677 98.8703 32.0744 98.6483 31.6962 98.2124C31.3179 97.7684 31.1206 97.1682 31.1206 96.387C31.1206 95.5976 31.3262 94.9562 31.7455 94.4711C32.1648 93.9777 32.6992 93.7393 33.3652 93.7393C34.097 93.7393 34.6232 94.0435 34.9357 94.6438H34.9521V91.5027H35.3961V98.7551H34.9521V98.7469ZM34.9521 95.7621C34.9521 95.3098 34.8041 94.9234 34.5163 94.6027C34.2286 94.282 33.8421 94.1257 33.3488 94.1257C32.839 94.1257 32.4115 94.3231 32.0744 94.7178C31.7373 95.1125 31.5728 95.6552 31.5728 96.3377C31.5728 97.0119 31.7208 97.5382 32.025 97.9C32.3293 98.27 32.7157 98.4509 33.1926 98.4509C33.7517 98.4509 34.1792 98.2782 34.4917 97.9329C34.7959 97.5875 34.9521 97.1682 34.9521 96.6666V95.7621Z"
      fill="#585551"
    />
    <path
      d="M36.7034 98.5332V97.9987C36.8678 98.1385 37.0734 98.2536 37.3036 98.3358C37.542 98.4181 37.7476 98.4592 37.912 98.4592C38.6767 98.4592 39.0631 98.1467 39.0631 97.5136C39.0631 97.2998 38.9809 97.1107 38.8082 96.9626C38.6356 96.8064 38.3642 96.6502 37.986 96.4857C37.5174 96.2802 37.1885 96.0746 36.9994 95.8526C36.8103 95.6306 36.7116 95.3674 36.7116 95.055C36.7116 94.6603 36.8678 94.3314 37.172 94.0847C37.4762 93.838 37.8545 93.7147 38.2985 93.7147C38.7178 93.7147 39.0796 93.7969 39.3755 93.9696V94.4629C39.0138 94.2245 38.6356 94.1093 38.2491 94.1093C37.9285 94.1093 37.6654 94.1916 37.468 94.3643C37.2707 94.5287 37.172 94.7507 37.172 95.0139C37.172 95.2441 37.2378 95.4332 37.3611 95.5812C37.4845 95.7292 37.7558 95.8937 38.1833 96.0828C38.6931 96.313 39.0467 96.5268 39.2358 96.7242C39.4249 96.9215 39.5235 97.1847 39.5235 97.5053C39.5235 97.8918 39.3755 98.2125 39.0878 98.4674C38.8 98.7223 38.3971 98.8457 37.8791 98.8457C37.4105 98.8621 37.0158 98.7552 36.7034 98.5332Z"
      fill="#585551"
    />
    <path
      d="M49.5873 98.747V95.8032C49.5873 95.1865 49.4886 94.7589 49.2995 94.504C49.1104 94.2573 48.798 94.134 48.3869 94.134C48.0004 94.134 47.6715 94.2985 47.3838 94.6274C47.1042 94.9563 46.9644 95.3756 46.9644 95.8772V98.747H46.5205V95.7703C46.5205 94.6767 46.1093 94.134 45.2789 94.134C44.8843 94.134 44.5472 94.2984 44.284 94.6356C44.0209 94.9727 43.8894 95.4003 43.8894 95.9266V98.747H43.4536V93.8462H43.8894V94.6603H43.9058C44.2429 94.0353 44.7445 93.7311 45.4023 93.7311C45.7558 93.7311 46.0682 93.8298 46.3149 94.0435C46.5698 94.2491 46.7425 94.5205 46.8247 94.8576C46.9809 94.504 47.1947 94.2244 47.4824 94.0353C47.7702 93.838 48.0744 93.7393 48.4115 93.7393C49.4804 93.7393 50.0148 94.4053 50.0148 95.7292V98.7552H49.5873V98.747Z"
      fill="#585551"
    />
    <path
      d="M54.422 98.7469V97.7684H54.4056C54.2494 98.1056 54.0191 98.3687 53.7231 98.566C53.4271 98.7634 53.09 98.8621 52.7283 98.8621C52.2596 98.8621 51.8978 98.7305 51.6265 98.4674C51.3552 98.2042 51.2236 97.8753 51.2236 97.4806C51.2236 96.6255 51.791 96.1074 52.9174 95.9348L54.422 95.7127C54.422 94.6602 54.0274 94.134 53.238 94.134C52.6954 94.134 52.1774 94.3478 51.6676 94.7836V94.2491C51.8485 94.1093 52.0952 93.986 52.4076 93.8791C52.7201 93.7804 53.0078 93.7228 53.2874 93.7228C53.7889 93.7228 54.1836 93.8791 54.4549 94.1915C54.7345 94.504 54.866 94.9562 54.866 95.5565V98.7387H54.422V98.7469ZM53.0736 96.3048C52.5638 96.3788 52.2021 96.4939 51.9965 96.6584C51.791 96.8228 51.6841 97.0859 51.6841 97.456C51.6841 97.7438 51.7827 97.9822 51.9718 98.1713C52.161 98.3605 52.4323 98.4509 52.7776 98.4509C53.2463 98.4509 53.6409 98.2782 53.9534 97.9247C54.2658 97.5793 54.422 97.1271 54.422 96.5761V96.0992L53.0736 96.3048Z"
      fill="#585551"
    />
    <path
      d="M58.64 94.3066C58.492 94.1998 58.3193 94.1504 58.122 94.1504C57.7356 94.1504 57.4149 94.3478 57.1518 94.7507C56.8887 95.1454 56.7571 95.7127 56.7571 96.4446V98.7469H56.3296V93.8462H56.7571V94.9316H56.7736C56.8887 94.5615 57.0614 94.2655 57.308 94.06C57.5465 93.8544 57.8342 93.7475 58.1467 93.7475C58.3276 93.7475 58.492 93.7722 58.6318 93.8297V94.3066H58.64Z"
      fill="#585551"
    />
    <path
      d="M62.3646 98.747L60.0213 96.3213H60.0049V98.7387H59.5691V91.4863H60.0049V96.1568H60.0213L62.2413 93.838H62.8086L60.4817 96.2061L62.9895 98.7305H62.3646V98.747Z"
      fill="#585551"
    />
    <path
      d="M64.0171 96.313C64.0171 96.9873 64.1733 97.5135 64.4857 97.8918C64.7982 98.27 65.2257 98.4591 65.7684 98.4591C66.3193 98.4591 66.8537 98.2536 67.3717 97.8507V98.3194C66.8619 98.6812 66.3028 98.8621 65.6697 98.8621C65.0366 98.8621 64.5268 98.6318 64.1404 98.1796C63.754 97.7273 63.5566 97.0859 63.5566 96.2637C63.5566 95.5483 63.7622 94.948 64.1651 94.4547C64.568 93.9613 65.1024 93.7228 65.7519 93.7228C66.3604 93.7228 66.829 93.9366 67.1497 94.3642C67.4786 94.7918 67.6348 95.3756 67.6348 96.1157V96.313H64.0171ZM67.1826 95.9101C67.1661 95.3427 67.0264 94.9069 66.7797 94.5944C66.5248 94.282 66.1795 94.1258 65.7355 94.1258C65.2751 94.1258 64.8968 94.282 64.5926 94.5862C64.2884 94.8905 64.1075 95.3345 64.0335 95.9101H67.1826Z"
      fill="#585551"
    />
    <path
      d="M70.7921 98.6812C70.5619 98.7881 70.3481 98.8456 70.1426 98.8456C69.419 98.8456 69.0573 98.4098 69.0573 97.5464V94.2573H68.1775V93.8544H69.0655V92.5799C69.1313 92.5552 69.2053 92.5306 69.2875 92.5059C69.3615 92.4812 69.4355 92.4566 69.5095 92.4319V93.8544H70.8003V94.2573H69.5095V97.4889C69.5095 97.826 69.5588 98.0727 69.6657 98.2207C69.7726 98.3687 69.9535 98.4509 70.2083 98.4509C70.3892 98.4509 70.5866 98.3934 70.8003 98.27V98.6812H70.7921Z"
      fill="#585551"
    />
    <path
      d="M72.2227 97.8753H72.2063V101H71.7705V93.8462H72.2063V94.8576H72.2227C72.3954 94.4958 72.6503 94.2162 72.9792 94.0271C73.308 93.8298 73.6616 93.7311 74.0563 93.7311C74.6811 93.7311 75.1662 93.9449 75.5116 94.3725C75.8569 94.8 76.0378 95.3839 76.0378 96.1239C76.0378 96.9462 75.8404 97.604 75.4376 98.1138C75.0347 98.6154 74.5085 98.8703 73.8507 98.8703C73.1189 98.8621 72.5763 98.5332 72.2227 97.8753ZM72.2063 96.0499V96.6666C72.2063 97.16 72.3625 97.5793 72.6667 97.9329C72.9709 98.2865 73.382 98.4592 73.8836 98.4592C74.3851 98.4592 74.788 98.2454 75.1005 97.8178C75.4129 97.3902 75.5691 96.8228 75.5691 96.1239C75.5691 95.5154 75.4293 95.0221 75.1416 94.6685C74.8538 94.3067 74.4756 94.1258 73.9905 94.1258C73.4231 94.1258 72.9792 94.3149 72.6667 94.7014C72.3625 95.0878 72.2063 95.5319 72.2063 96.0499Z"
      fill="#585551"
    />
    <path d="M77.2466 98.747V91.4945H77.6824V98.747H77.2466Z" fill="#585551" />
    <path
      d="M82.1879 98.7469V97.7684H82.1714C82.0152 98.1056 81.785 98.3687 81.489 98.566C81.193 98.7634 80.8559 98.8621 80.4941 98.8621C80.0255 98.8621 79.6637 98.7305 79.3924 98.4674C79.1211 98.2042 78.9895 97.8753 78.9895 97.4806C78.9895 96.6255 79.5568 96.1074 80.6832 95.9348L82.1879 95.7127C82.1879 94.6602 81.7932 94.134 81.0039 94.134C80.4613 94.134 79.9433 94.3478 79.4335 94.7836V94.2491C79.6144 94.1093 79.861 93.986 80.1735 93.8791C80.4859 93.7804 80.7737 93.7228 81.0532 93.7228C81.5548 93.7228 81.9494 93.8791 82.2208 94.1915C82.5003 94.504 82.6319 94.9562 82.6319 95.5565V98.7387H82.1879V98.7469ZM80.8395 96.3048C80.3297 96.3788 79.9679 96.4939 79.7542 96.6584C79.5486 96.8228 79.4417 97.0859 79.4417 97.456C79.4417 97.7438 79.5404 97.9822 79.7295 98.1713C79.9186 98.3605 80.1899 98.4509 80.5352 98.4509C81.0039 98.4509 81.3986 98.2782 81.711 97.9247C82.0234 97.5793 82.1797 97.1271 82.1797 96.5761V96.0992L80.8395 96.3048Z"
      fill="#585551"
    />
    <path
      d="M87.2693 98.5331C86.9075 98.7551 86.4718 98.8703 85.9702 98.8703C85.3042 98.8703 84.7698 98.64 84.3587 98.1878C83.9476 97.7273 83.7502 97.1353 83.7502 96.3952C83.7502 95.6141 83.9805 94.9809 84.4409 94.4875C84.9013 93.9942 85.4933 93.7475 86.2086 93.7475C86.5786 93.7475 86.9404 93.8215 87.294 93.9777V94.4793C86.9404 94.2655 86.5622 94.1504 86.1429 94.1504C85.5673 94.1504 85.1069 94.356 84.7451 94.7671C84.3916 95.1783 84.2107 95.7127 84.2107 96.3705C84.2107 97.0037 84.3751 97.5135 84.704 97.9C85.0329 98.2864 85.4604 98.4756 85.9949 98.4756C86.4882 98.4756 86.9157 98.344 87.2775 98.0809V98.5331H87.2693Z"
      fill="#585551"
    />
    <path
      d="M88.5517 96.313C88.5517 96.9873 88.708 97.5135 89.0204 97.8918C89.3328 98.27 89.7604 98.4591 90.303 98.4591C90.8539 98.4591 91.3884 98.2536 91.9064 97.8507V98.3194C91.3966 98.6812 90.8375 98.8621 90.2044 98.8621C89.5713 98.8621 89.0615 98.6318 88.6751 98.1796C88.2886 97.7273 88.0913 97.0859 88.0913 96.2637C88.0913 95.5483 88.2969 94.948 88.6997 94.4547C89.1026 93.9613 89.6371 93.7228 90.2866 93.7228C90.895 93.7228 91.3637 93.9366 91.6844 94.3642C92.0132 94.7918 92.1695 95.3756 92.1695 96.1157V96.313H88.5517ZM91.7172 95.9101C91.7008 95.3427 91.561 94.9069 91.3144 94.5944C91.0595 94.282 90.7142 94.1258 90.2702 94.1258C89.8097 94.1258 89.4315 94.282 89.1273 94.5862C88.8231 94.8905 88.6422 95.3345 88.5682 95.9101H91.7172Z"
      fill="#585551"
    />
    <path d="M2.20347 81.2079H10.442V82.8031H0.402832V65.1324H2.20347V81.2079Z" fill="#2E476E" />
    <path
      d="M25.7927 68.3311C25.176 67.6815 24.4443 67.1799 23.5974 66.8181C22.7505 66.4645 21.7721 66.2836 20.6785 66.2836C19.5439 66.2836 18.5244 66.4892 17.6199 66.9085C16.7155 67.3197 15.9426 67.887 15.3095 68.5942C14.6764 69.3013 14.1913 70.1154 13.846 71.0446C13.5007 71.9737 13.3362 72.9522 13.3362 73.9883C13.3362 75.0162 13.5089 76.0029 13.846 76.932C14.1831 77.8612 14.6764 78.6753 15.3095 79.3742C15.9426 80.0731 16.7073 80.6323 17.6199 81.0434C18.5244 81.4546 19.5439 81.6684 20.6785 81.6684C21.6734 81.6684 22.5943 81.5615 23.4247 81.3477C24.2552 81.1339 24.9294 80.8543 25.4474 80.5254V75.1559H21.3281V73.5607H27.2398V81.545C26.2778 82.1288 25.2336 82.5646 24.1236 82.8442C23.0054 83.1238 21.8543 83.2718 20.6539 83.2718C19.3219 83.2718 18.0886 83.0415 16.9622 82.5729C15.8275 82.1042 14.8573 81.4628 14.0433 80.6405C13.2293 79.8182 12.588 78.8397 12.1358 77.705C11.6754 76.5702 11.4534 75.3368 11.4534 73.9883C11.4534 72.6398 11.6836 71.3981 12.1522 70.2716C12.6209 69.1369 13.2622 68.1584 14.0844 67.3361C14.9066 66.5138 15.8851 65.8642 17.0033 65.4038C18.1297 64.9351 19.3466 64.7048 20.6621 64.7048C22.0763 64.7048 23.3096 64.9186 24.3538 65.338C25.398 65.7573 26.3025 66.3494 27.0507 67.0976L25.7927 68.3311Z"
      fill="#2E476E"
    />
    <path
      d="M31.8276 65.1324H37.69C38.7917 65.1324 39.8935 65.3133 40.9953 65.6834C42.1052 66.0534 43.1001 66.6043 43.9881 67.3444C44.8761 68.0844 45.5996 69.0054 46.1505 70.1154C46.7014 71.2255 46.9727 72.5082 46.9727 73.9719C46.9727 75.452 46.7014 76.743 46.1505 77.8448C45.5996 78.9467 44.8843 79.8594 43.9881 80.5994C43.1001 81.3395 42.097 81.8904 40.9953 82.2604C39.8853 82.6304 38.7835 82.8113 37.69 82.8113H31.8276V65.1324ZM33.6283 81.2079H37.1967C38.5286 81.2079 39.688 81.0023 40.6746 80.583C41.6613 80.1636 42.4835 79.6209 43.133 78.9467C43.7825 78.2724 44.2677 77.4995 44.5965 76.6361C44.9172 75.7727 45.0816 74.8846 45.0816 73.9637C45.0816 73.0509 44.9172 72.1547 44.5965 71.2913C44.2759 70.4279 43.7825 69.655 43.133 68.9807C42.4835 68.3064 41.6613 67.7637 40.6746 67.3444C39.688 66.925 38.5204 66.7194 37.1967 66.7194H33.6283V81.2079Z"
      fill="#2E476E"
    />
    <path
      d="M52.5226 81.2079H61.9862V82.8031H50.7219V65.1324H61.682V66.7277H52.5226V72.796H61.0818V74.3913H52.5226V81.2079Z"
      fill="#80506F"
    />
    <path
      d="M65.3737 82.8031H63.4004L71.0387 65.1324H72.7818L80.3708 82.8031H78.3728L76.4242 78.1573H67.2977L65.3737 82.8031ZM67.9719 76.5127H75.7582L71.8609 67.1306L67.9719 76.5127Z"
      fill="#80506F"
    />
    <path d="M84.7697 81.2079H93.0082V82.8031H82.9773V65.1324H84.7779V81.2079H84.7697Z" fill="#80506F" />
  </svg>
)

export default memo(SvgComponent)
