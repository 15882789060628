import React, { memo, useCallback, useEffect, useState } from 'react'
import connect from './connect'
import PropTypes from 'prop-types'
import Spinner from 'root/components/Spinner'
import BrownButton from 'root/components/Buttons/BrownButton'
import BlueButton from 'root/components/Buttons/BlueButton'
import * as Yup from 'yup'
import i18n from 'root/i18n'
import { Form, Formik } from 'formik'
import FormikSubmittingButtons
  from 'root/components/Buttons/FormikSubmittingButtons'
import DeliveryAddress, {
  getAddressValidationSchema,
} from 'root/modules/orders/pages/components/DeliveryAddress'
import RequiredLabel from 'root/components/RequiredLabel'
import InputFormik from 'root/modules/orders/pages/components/InputTerm/input'
import OrderSubHeader from 'root/components/OrderSubHeader'
import ToggleableSection
  from 'root/modules/orders/pages/components/ToggleableSection'
import { UpDownSprite } from 'root/icons'
import { Link, useHistory } from 'react-router-dom'
import OrderHeading from 'root/modules/orders/pages/components/OrderHeading'
import MessageBox from 'root/components/MessageBox'
import { ACTION_SAVE_KYC_INFO } from 'root/modules/profile/constants'

const FIELD_LABELS = {
  taxId: i18n.value('profile.company.labels.taxId'),
  creditCompanyName: i18n.value('profile.company.labels.creditCompanyName'),
  creditPhoneNumber: i18n.value('profile.company.labels.creditPhoneNumber'),
  bankName: i18n.value('profile.company.labels.bankName'),
  bankAccountName: i18n.value('profile.company.labels.bankAccountName'),
  bankAccountNumber: i18n.value('profile.company.labels.bankAccountNumber'),
  bankRoutingNumber: i18n.value('profile.company.labels.bankRoutingNumber'),
  bankSwiftCode: i18n.value('profile.company.labels.bankSwiftCode'),
  bankAddress: i18n.value('profile.company.labels.bankAddress'),
  corrBankName: i18n.value('profile.company.labels.corrBankName'),
  corrBankAccountNumber: i18n.value(
    'profile.company.labels.corrBankAccountNumber'),
  corrBankSwift: i18n.value('profile.company.labels.corrBankSwift'),
  corrBankAddress: i18n.value('profile.company.labels.corrBankAddress'),
  supervisorName: i18n.value('profile.company.labels.supervisorName'),
  supervisorPhone: i18n.value('profile.company.labels.supervisorPhone'),
  foundationYear: i18n.value('profile.company.labels.foundationYear'),
  entityType: i18n.value('profile.company.labels.entityType'),
  businessType: i18n.value('profile.company.labels.businessType'),
  isAml: i18n.value('profile.company.labels.isAml'),
  references: i18n.value('profile.company.labels.references'),
  creditEmail: i18n.value('profile.company.labels.creditEmail'),
  site: i18n.value('profile.company.labels.site'),
}

const KycSchema = Yup.object().shape({
  foundationYear: Yup.string().
    required(FIELD_LABELS['foundationYear'] + ' is required').
    max(100),
  entityType: Yup.string().
    required(FIELD_LABELS['entityType'] + ' is required').
    max(100),
  businessType: Yup.string().
    required(FIELD_LABELS['businessType'] + ' is required').
    max(100),
  isAml: Yup.string().required(FIELD_LABELS['isAml'] + ' is required').max(100),
  references: Yup.string().
    required(FIELD_LABELS['references'] + ' is required').
    max(1000),
  creditEmail: Yup.string().
    required(FIELD_LABELS['creditEmail'] + ' is required').
    max(100),
  site: Yup.string().required(FIELD_LABELS['site'] + ' is required').max(100),
  taxId: Yup.string().trim().required('TIN/VAT/EIN is required'),
  creditCompanyName: Yup.string().
    required('Credit Company Name is required').
    max(100),
  legalAddress: getAddressValidationSchema(true, true),
  creditPhoneNumber: Yup.string().
    required(FIELD_LABELS['creditPhoneNumber'] + ' is required').
    max(100),
  bankName: Yup.string().
    required(FIELD_LABELS['bankName'] + ' is required').
    max(100),
  bankAddress: Yup.string().
    required(FIELD_LABELS['bankAddress'] + ' is required').
    max(100),
  bankSwiftCode: Yup.string().
    required(FIELD_LABELS['bankSwiftCode'] + ' is required').
    max(100),
  supervisorName: Yup.string().
    required(FIELD_LABELS['supervisorName'] + ' is required').
    max(100),
  supervisorPhone: Yup.string().
    required(FIELD_LABELS['supervisorPhone'] + ' is required').
    max(100),
})
const submittingButtons = [
  {
    color: 'brown',
    label: 'common.save',
    action: ACTION_SAVE_KYC_INFO,
    className: 'profile-button',
  },
]

const bankFields = [
  { key: 'bankName', isRequired: true },
  { key: 'bankRoutingNumber', isRequired: false },
  { key: 'bankSwiftCode', isRequired: true },
  { key: 'bankAddress', isRequired: true },
]

const supervisorFields = [
  { key: 'supervisorName', isRequired: true },
  { key: 'supervisorPhone', isRequired: true },
]

const KycInformation = ({
  data,
  isLoading,
  loadKycInfo,
  saveKycInfo,
  loadCompanyCommonInfo,
  canSupervise,
}) => {
  useEffect(() => {
    loadKycInfo()
  }, [loadKycInfo])

  const history = useHistory()

  const [editing, setEditing] = useState(false)
  const toggleEditing = useCallback(() => setEditing((editing) => !editing), [])

  if (isLoading || !data.id) return <Spinner/>

  const initialValues = {
    ...data,
    references: data.references || '',
    foundationYear: data.foundationYear || '',
    entityType: data.entityType || '',
    businessType: data.businessType || '',
    isAml: data.isAml || '',
    creditEmail: data.creditEmail || '',
    site: data.site || '',

    creditCompanyName: data.creditCompanyName || data.name || '',
    creditPhoneNumber: data.creditPhoneNumber || '',
    taxId: data.taxId || '',
    bankName: data.bankName || '',
    bankRoutingNumber: data.bankRoutingNumber || '',
    bankSwiftCode: data.bankSwiftCode || '',
    bankAddress: data.bankAddress || '',
    supervisorName: data.supervisorName || '',
    supervisorPhone: data.supervisorPhone || '',
    legalAddress: {
      ...data.legalAddress,
      companyName: data.legalAddress.companyName || data.name || '',
      countryId: data.legalAddress.countryId || data.countryId?.code || '',
      cityName: data.legalAddress.cityName || data.cityId?.name || '',
      regionName: data.legalAddress.regionName || data.regionId?.name || '',
      zipCode: data.legalAddress.zipCode || data.zipCodeId?.zipCode || '',
      addressLine1: data.legalAddress.addressLine1 || '',
      addressLine2: data.legalAddress.addressLine2 || '',
    },
    action: '',
  }
  // for now everybody can edit credit info
  const canEditCreditInfo = true

  return (
    <>
      <UpDownSprite/>
      {!data.isValid ? (
        <MessageBox className="mb-10">
          <Link to={`/company/kyc-info`}>
            <div className="text-gray-500">{i18n.value(
              'orders.labels.kycInfoRequired')}</div>
          </Link>
        </MessageBox>
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={KycSchema}
        onSubmit={async (values) => {
          toggleEditing()
          await saveKycInfo(values)
          await loadCompanyCommonInfo(true)
        }}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          handleSubmit,
          resetForm,
          setSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="container mb:p-4 mb:mb-10 sm:w-screen-lg-min">
              <div className="container mx-auto lg:w-screen-lg-min mb-2 flex">
                <OrderHeading
                  label={i18n.value('profile.company.labels.kycInfo')}/>
              </div>
              <ToggleableSection className="mt-4"
                                 labelTop={'profile.company.labels.generalInfo'}
                                 withoutHeader>
                <div className="grid grid-cols-2 mb:grid-cols-1">
                  <div>
                    <div className="mt-2">
                      <RequiredLabel label={FIELD_LABELS['creditCompanyName']}/>
                      <div>
                        <InputFormik
                          isEditable={editing}
                          itemKey={'creditCompanyName'}
                          inputClass={'w-full ml-0'}
                          value={values.creditCompanyName}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <RequiredLabel label={FIELD_LABELS['creditPhoneNumber']}/>
                      <div>
                        <InputFormik
                          isEditable={editing}
                          itemKey={'creditPhoneNumber'}
                          inputClass={'w-full'}
                          value={values.creditPhoneNumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ml-6 mb:ml-0">
                    <div className="mt-2">
                      <RequiredLabel label={FIELD_LABELS['taxId']}/>
                      <div>
                        <InputFormik
                          isEditable={editing}
                          itemKey={'taxId'}
                          inputClass={'w-full ml-0'}
                          value={values.taxId}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <RequiredLabel label={FIELD_LABELS['creditEmail']}/>
                      <div>
                        <InputFormik
                          isEditable={editing}
                          itemKey={'creditEmail'}
                          inputClass={'w-full'}
                          value={values.creditEmail}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened
                className="mt-12"
                labelTop={'profile.company.labels.addresses'}
                withoutHeader
              >
                <div className="grid grid-cols-2 ">
                  <div className="mt-2">
                    <OrderSubHeader
                      label={'profile.company.labels.legalAddress'}
                      className={'mb-4'}/>
                    <DeliveryAddress
                      withoutRecipient
                      itemKey={'legalAddress'}
                      isEditable={editing}
                      address={values.legalAddress}
                    />
                  </div>
                  <div className="ml-6 mt-2">
                    <OrderSubHeader
                      label={'profile.company.labels.bankInfo'}
                      className={'mb-4'}/>

                    <div className="mt-2">
                      {bankFields.map(({ key, isRequired }) => (
                        <div className="mt-2" key={key}>
                          <RequiredLabel label={FIELD_LABELS[key]}
                                         isRequired={isRequired}/>
                          <InputFormik isEditable={editing} itemKey={key}
                                       value={values[key]}
                                       inputClass={'w-full'}/>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened
                className="mt-12"
                labelTop={'profile.company.labels.additionalInfo'}
                withoutHeader
              >

                <div className="grid grid-cols-2 mb:grid-cols-1">

                  <div className="mt-2">
                    <RequiredLabel label={FIELD_LABELS['businessType']}/>
                    <div>
                      <InputFormik
                        isEditable={editing}
                        itemKey={'businessType'}
                        inputClass={'w-full ml-0'}
                        value={values.businessType}
                      />
                    </div>
                  </div>

                  <div className="ml-6 mt-2">
                    <RequiredLabel label={FIELD_LABELS['site']}/>
                    <div>
                      <InputFormik
                        isEditable={editing}
                        itemKey={'site'}
                        inputClass={'w-full ml-0'}
                        value={values.site}
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <RequiredLabel label={FIELD_LABELS['entityType']}/>
                    <div>
                      <InputFormik
                        isEditable={editing}
                        itemKey={'entityType'}
                        inputClass={'w-full ml-0'}
                        value={values.entityType}
                      />
                    </div>
                  </div>

                  <div className="ml-6 mt-2">
                    <RequiredLabel label={FIELD_LABELS['foundationYear']}/>
                    <div>
                      <InputFormik
                        isEditable={editing}
                        itemKey={'foundationYear'}
                        inputClass={'w-full ml-0'}
                        value={values.foundationYear}
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <RequiredLabel label={FIELD_LABELS['isAml']}/>
                    <div>
                      <InputFormik
                        isEditable={editing}
                        itemKey={'isAml'}
                        inputClass={'w-full ml-0'}
                        value={values.isAml}
                      />
                    </div>
                  </div>

                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened={data.isSeller}
                className="mt-12"
                labelTop={'profile.company.labels.supervisorInfo'}
                withoutHeader
              >
                <div className="mt-2">
                  {supervisorFields.map(({ key, isRequired }) => (
                    <div className="mt-2" key={key}>
                      <RequiredLabel label={FIELD_LABELS[key]}
                                     isRequired={isRequired}/>
                      <InputFormik isEditable={editing} itemKey={key}
                                   value={values[key]} inputClass={'w-full'}/>
                    </div>
                  ))}
                </div>
              </ToggleableSection>
              <ToggleableSection
                isOpened
                className="mt-12"
                labelTop={'profile.company.labels.references'}
                withoutHeader
              >
                <div className="mt-2">

                  <InputFormik
                    isEditable={editing}
                    itemKey={'references'}
                    inputClass={'w-full ml-0 form-textarea text-brown-dim block shadow shadow-inset border-gray-300'}
                    value={values.references}
                  >
                    {({ field }) => {
                      return (<textarea rows={5}
                                        onChange={field.onChange}
                                        defaultValue={field.value}
                                        name={field.name}
                                        onBlur={field.onBlur}
                                        className={'w-full ml-0 form-textarea text-brown-dim block shadow shadow-inset border-gray-300'}
                                        maxLength={1000}></textarea>)
                    }}
                  </InputFormik>
                </div>
              </ToggleableSection>
              {isSubmitting ? (
                <Spinner/>
              ) : (
                <div className="flex mt-12">
                  {editing ? (
                    <div className="flex">
                      <FormikSubmittingButtons
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        buttons={submittingButtons}
                      />
                      <BlueButton
                        onClick={() => {
                          toggleEditing()
                          setSubmitting(false)
                          resetForm()
                        }}
                        type={'button'}
                        label={'common.cancel'}
                        className={'profile-button ml-12'}
                      />
                    </div>
                  ) : (
                    <div className="flex space-x-4">
                      <BlueButton type="button"
                                  onClick={() => history.goBack()}>
                        Back
                      </BlueButton>
                      {canEditCreditInfo && (
                        <BrownButton
                          onClick={toggleEditing}
                          type={'button'}
                          label={'common.edit'}
                          className={'profile-button'}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

KycInformation.propTypes = {
  data: PropTypes.object.isRequired,
  loadKycInfo: PropTypes.func.isRequired,
  saveKycInfo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadCompanyCommonInfo: PropTypes.func.isRequired,
  canSupervise: PropTypes.bool,
}

export default memo(connect(KycInformation))
