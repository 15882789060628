export default {
  container: {
    padding: '0 10px 5px',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  paddingTop: {
    paddingTop: 5,
  },
  timeHolder: {
    fontSize: 10,
    color: 'transparent',
  },
}
