import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import DealInProgressSeller from './components/DealInProgressSeller'
import DealInProgressBuyer from './components/DealInProgressBuyer'
import BlueButton from 'root/components/Buttons/BlueButton'
import { ACTION_CANCEL, ACTION_DEAL_NEGOTIATION, ACTION_REJECT } from 'root/modules/orders/constants'
import { confirmAlert, promptAlert } from 'root/components/AlertWrapper'
import i18n from 'root/i18n'
import { doNothing } from 'root/constants'
import { useAsyncLoad } from 'root/hooks'
import ReadonlyNotice from 'root/modules/orders/pages/Order/components/components/ReadonlyNotice'

const DealInProgress = ({ asSeller, ...other }) => {
  const order = other.order
  const readOnly = other.readOnly
  const doAction = other.doAction
  const [loading, submitAction] = useAsyncLoad(doAction)
  const canRestart = order.actions.some((action) => action.url === ACTION_DEAL_NEGOTIATION)
  const restartOrder = useCallback(async () => {
    try {
      const reason = await confirmAlert(i18n.value('order.labels.doYouConfirmRestartDeal'))
      if (reason) {
        submitAction(ACTION_DEAL_NEGOTIATION)
      }
    } catch (e) {
      doNothing()
    }
  }, [submitAction])
  const canCancel = order.actions.some((action) => action.url === ACTION_CANCEL)
  const cancelOrder = useCallback(async () => {
    try {
      const reason = await promptAlert(i18n.value('order.labels.provideReasonForCancel'))
      if (reason) {
        submitAction(ACTION_CANCEL, { reason })
      }
    } catch (e) {
      doNothing()
    }
  }, [submitAction])
  const canReject = !readOnly && order.actions.some((action) => action.url === ACTION_REJECT)
  const rejectOrder = useCallback(async () => {
    try {
      const reason = await promptAlert(i18n.value('order.labels.provideReasonForCancel'))
      if (reason) {
        submitAction(ACTION_REJECT, { reason })
      }
    } catch (e) {
      doNothing()
    }
  }, [submitAction])
  return (
    <>
      {other.readOnly ? <ReadonlyNotice order={other.order} /> : null}
      {asSeller ? <DealInProgressSeller {...other} /> : <DealInProgressBuyer {...other} />}
      {other.readOnly ? <ReadonlyNotice order={other.order} /> : null}
      <section className="flex">
        {canReject && (
          <BlueButton disabled={loading} onClick={rejectOrder} type="button" label="orders.buttons.cancelDeal" />
        )}
        {canCancel && (
          <BlueButton
            className="ml-4"
            disabled={loading}
            onClick={cancelOrder}
            type="button"
            label="orders.buttons.cancelDeal"
          />
        )}
        {canRestart && (
          <BlueButton
            className="ml-4"
            disabled={loading}
            onClick={restartOrder}
            type="button"
            label="orders.buttons.restartDeal"
          />
        )}
      </section>
    </>
  )
}

DealInProgress.propTypes = {
  asSeller: PropTypes.bool.isRequired,
}

export default memo(DealInProgress)
