import React, { memo } from 'react'

const SvgComponent = (props) => (
  <svg
    width="32"
    height="32"
    className="fill-current"
    viewBox="0 0 22 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 4.32283L4.03687 0H7.78813L6.59976 1.27007H4.5877L1.67544 4.38913L8.30581 12.7389L10.1878 10.3671L5.3859 4.32283L9.42532 0H17.9632L22.0001 4.32283L13.6968 14.7818L11.7689 12.3487L12.5798 11.3261L13.6968 12.7389L20.3246 4.38913L17.4124 1.27007H13.7631L16.6116 4.32283L8.30581 14.7818L0 4.32283ZM7.06389 4.38913L10.9988 9.34444L14.9336 4.38913L12.0239 1.27007H9.9787L7.06389 4.38913Z" />
  </svg>
)

export default memo(SvgComponent)
