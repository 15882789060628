import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import { toStyle } from 'root/constants'

const rowLengths = [[1], [2], [1, 2], [1, 3], [2, 3], [3, 3], [2, 2, 3], [2, 3, 3], [3, 3, 3], [3, 4, 3]]

const splitByLengths = (arr, lengthsArr) => {
  let start = 0
  return (
    lengthsArr?.map((len) => {
      const result = arr.slice(start, start + len)
      start = start + len
      return result
    }) || []
  )
}

const WINDOW_HEIGHT = 570

const ChatMessageImages = ({ hasUserName, hasMessage, images }) => {
  images = images?.map((image, index) => ({ index, ...image }))
  const groupedImages = images ? splitByLengths(images, rowLengths[images.length - 1]) : []

  return (
    <div style={toStyle([styles.flex, hasUserName && styles.paddingTop, hasMessage && styles.paddingBottom])}>
      {groupedImages.map((imageRow, row) => {
        const heightDivider = [3, 5, 6][imageRow.length - 1] || 7
        const imageHeightStyle = { height: WINDOW_HEIGHT / heightDivider }

        return (
          <div key={row} style={toStyle([styles.flex, styles.row])}>
            {imageRow.map(({ previewUrlDesktop, originalUrl, id }, column) => {
              const isFirst = column === 0
              const isLast = column === imageRow.length - 1
              const isFirstRow = row === 0
              const isLastRow = row === groupedImages.length - 1
              return (
                <a key={id} style={styles.flex} href={originalUrl} data-attribute="SRL">
                  <img
                    alt=""
                    style={toStyle([
                      styles.image,
                      imageHeightStyle,
                      !hasUserName && isFirstRow && isFirst && styles.topLeft,
                      !hasUserName && isFirstRow && isLast && styles.topRight,
                      !hasMessage && isLastRow && isFirst && styles.bottomLeft,
                      !hasMessage && isLastRow && isLast && styles.bottomRight,
                    ])}
                    src={previewUrlDesktop}
                  />
                </a>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

ChatMessageImages.propTypes = {
  hasUserName: PropTypes.bool.isRequired,
  hasMessage: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
}

export default memo(ChatMessageImages)
